import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { SiMicrosoftteams } from "react-icons/si";
import axios from "axios";
import moment from "moment";
import { motion } from "framer-motion";
import { BaseUrl, emailservice } from "../../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { BsChatSquareText } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import word from "../../../../Page/EmailComponents/Resources/images/word.png";
import pdf from "../../../../Page/EmailComponents/Resources/images/pdf.png";
import excel from "../../../../Page/EmailComponents/Resources/images/excel.png";
import {
  setselectedemail,
  setselectedmobile,
} from "../../../../redux/actions/actions";
import parse from "html-react-parser";
import { errorhandelApi } from "../../../../ApiCall/ErrorHandelApi";
import "../../Chat-component/Chat-Style/chat.css";
import {
  Grid,
  Paper,
  IconButton,
  Divider,
  Typography,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

const mapStateToProps = (state) => {
  const { data } = state;
  //
  return {
    selectedemail: data.selectedemail,
    selectedmobile: data.selectedmobile,
  };
};

const Interactiontab = (props) => {
  const [smShow, setSmShow] = useState(false);
  const [sessionVal, setsessionVal] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [summaryArrivalDate, setSummaryArrivalDate] = useState("");
  const [summaryChannel, setSummaryChannel] = useState("");
  const [summaryAgent, setSummaryAgent] = useState("");
  const [summaryStartedDate, setSummaryStartedDate] = useState("");
  const [summaryEndDate, setSummaryEndDate] = useState(undefined);
  const [comments, setcomments] = useState("");

  const [show, setShow] = useState(false);
  const [chatmodal, setChatmodal] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [sessionvalueforemail, setSessionvalforemail] = useState([]);
  const [ticketid, setTicketid] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlechatClose = () => setChatmodal(false);

  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  const [getsessionsbydate, setGetsessionsbydate] = useState(defaultValue);

  const imageFormat = [
    "png",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "svg",
    "gif",
  ];
  useEffect(() => {
    if (props.selectedemail) {
      getSessionsDetails(defaultValue);
    }
  }, [props.selectedemail]);

  const getSessionsDetails = async (value) => {
    try {
      const access_token = localStorage.getItem("access_token");
      const tenantId = localStorage.getItem("TenantId");

      await axios
        .post(
          BaseUrl + "/message/getSessions/",
          {
            email: props.customerDetailSearch
              ? props.email
              : props.selectedemail,
            phonenumber: props.customerDetailSearch
              ? props.phoneNumber
              : props.selectedmobile,
            date: value,
          },
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: tenantId,
            },
          }
        )
        .then((res) => {
          //
          if (res.data.success) {
            setsessionVal(res.data.data);
          } else {
            setsessionVal([]);
          }
        });
    } catch (error) {
      errorhandelApi(error, "/message/getSessions/");
    }
  };

  const handleShowDetails = async (session_id) => {
    try {
      const access_token = localStorage.getItem("access_token");
      setSessionId(session_id);
      let data = {
        sessionId: session_id,
      };
      const sessions = await axios.post(
        BaseUrl + "/message/getSummery/",
        data,
        {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );
      if (sessions.data.success) {
        let datas = sessions.data.data;
        setSummaryArrivalDate(datas[0].arrival_at);
        setSummaryChannel(datas[0].channel);
        let agent_one = datas[0].agent.username;
        let agent_two = datas[1].confUser;
        setSummaryAgent(agent_one + agent_two);
        setSummaryStartedDate(datas[0].chat_started_at);
        setSummaryEndDate(datas[0].chat_ended_at);
        setTicketid(datas[0].ticketID);
        setcomments(datas[0].comments);
      }
      setShow(true);
    } catch (error) {
      errorhandelApi(error, "/message/getSummery/");
    }
  };

  const handlechatShow = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      let data = {
        sessionId: sessionId,
      };
      const chatHistory = await axios.post(
        BaseUrl + "/message/chatHistories/",
        data,
        {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );

      if (chatHistory.data.status) {
        setChatHistory(chatHistory.data.data);
        setChatmodal(true);
      }
    } catch (error) {
      errorhandelApi(error, "/message/chatHistories/");
    }
  };

  const getSessionsDetailsForEmail = async (value) => {
    try {
      const clientJSON = localStorage.getItem("client");
      const clientObj = JSON.parse(clientJSON);
      const access_token = localStorage.getItem("access_token");
      const tenantId = localStorage.getItem("TenantId");

      await axios
        .post(
          emailservice + "/email/fetchEmailSummary",
          {
            ticketID: ticketid,
          },
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: tenantId,
            },
          }
        )
        .then((res) => {
          setChatmodal(true);
          if (res.data.Data) {
            setSessionvalforemail(res.data.Data);
          } else {
            setSessionvalforemail([]);
          }
        });
    } catch (error) {
      errorhandelApi(error, "/message/getSessions/");
    }
  };

  const handleClickHover = (item, event) => {
    setOpenselectedurl(item);
    setAnchorEl(event.currentTarget);
  };

  const openDocument = (item) => {
    console.log("clicked", item);
    window.open(item.url, "_blank");
  };

  const handleCloseHover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="d-flex justify-content-end m-2">
          <input
            type="Date"
            max={defaultValue}
            className="form-control form-control-sm"
            style={{
              cursor: "pointer",
              boxShadow: "1px 2px 2px gray",
              border: "0px",
              borderRadius: "7px",
              height: "10px",
              width: "120px",
            }}
            onChange={(e) => {
              getSessionsDetails(e.target.value);
              setGetsessionsbydate(e.target.value);
            }}
            value={getsessionsbydate}
          />
        </div>
        <div className="historynew m-2">
          {sessionVal.length > 0 ? (
            sessionVal.map((item) => {
              return (
                <motion.div
                  key={item.id}
                  className="card-chat pt-2 pb-2 mb-2"
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="container">
                    <div className="row">
                      {item.channel == "webchat" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center sms-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-brands fa-rocketchat"></i>
                        </div>
                      ) : item.channel == "from_whatsapp" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center what-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      ) : item.channel == "from_facebook" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center fb-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </div>
                      ) : item.channel == "from_twitter" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center twit-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-brands fa-twitter"></i>
                        </div>
                      ) : item.channel == "from_teams" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center teams-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-brands fa-teams"></i>
                          <SiMicrosoftteams
                            className="btn_hover"
                            size={20}
                            color="white"
                          />
                        </div>
                      ) : item.channel == "videochat" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center twit-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-solid fa-video"></i>
                        </div>
                      ) : item.channel == "voice" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center twit-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-solid fa-phone"></i>
                        </div>
                      ) : item.channel == "from_purple" ? (
                        <div
                          className="col-md-1  box d-flex align-items-center justify-content-center twit-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-solid fa-robot"></i>
                        </div>
                      ) : (
                        <div
                          className="col-md-1  box d-flex  align-items-center justify-content-center call-bg"
                          style={{ boxShadow: "2px 3px 10px gray" }}
                        >
                          <i className="fa-solid fa-envelope"></i>
                        </div>
                      )}

                      <div className="col-md-11 d-flex ps-3 flex-wrap m-0 p-0 no-gutter">
                        <div className="box2 me-2 badge-bg d-flex align-items-center justify-content-center">
                          <strong className="change_font_size">
                            <i className="fa-solid fa-user-tie me-1 "></i>
                            Agent :
                          </strong>

                          <div>
                            <p className="mb-0 ms-1 ">{item.agent?.username}</p>
                          </div>
                        </div>
                        <div className="box2 me-2 badge-bg d-flex align-items-center justify-content-center">
                          <strong className="change_font_size">
                            <i className="fa-solid fa-hourglass-half me-1"></i>
                            Language :
                          </strong>
                          <div>
                            <p className="mb-0 ms-1">{item.language}</p>
                          </div>
                        </div>
                        <div className="box2 me-2 badge-bg d-flex align-items-center justify-content-center">
                          <strong className="change_font_size">
                            <i className="fa-solid fa-calendar-days me-1"></i>
                            Date :
                          </strong>
                          <div>
                            <p className="mb-0 ms-1">
                              {moment(item.chat_started_at).format("L")}
                            </p>
                          </div>
                        </div>
                        <div className="box2 me-2 badge-bg d-flex align-items-center justify-content-center">
                          <strong className="change_font_size">
                            <i className="fa-solid fa-suitcase me-1"></i>
                            Skill :
                          </strong>
                          <div>
                            <p className="mb-0 ms-1">{item.skillset}</p>
                          </div>
                        </div>
                        <div className="box2 me-2 badge-bg d-flex align-items-center justify-content-center">
                          <strong className="change_font_size">
                            <i className="fa-solid fa-lightbulb me-1"></i>
                            Reason :
                          </strong>
                          <div>
                            <p className="mb-0 ms-1">{item.reason}</p>
                          </div>
                        </div>
                        {item.channel == "voice" ? (
                          ""
                        ) : (
                          <div className="box2 me-2   badge-bg d-flex align-items-center justify-content-center">
                            <strong className="change_font_size">
                              <i
                                className="fa-solid fa-eye"
                                id="pills-eye"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleShowDetails(item.chat_session_id)
                                }
                              ></i>
                            </strong>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            })
          ) : (
            <div className="d-flex justify-content-center">
              <div
                className="text-muted d-flex"
                style={{
                  flexDirection: "column",
                  marginTop: "20%",
                  width: "400px",
                  height: "100px",
                }}
              >
                <span className="mx-auto">
                  <RiErrorWarningLine size={30} />
                </span>
                <span className="mx-auto">
                  <h6
                    className="text-muted mt-2"
                    style={{ fontFamily: "poppins" }}
                  >
                    No Interaction History Found
                  </h6>
                </span>
              </div>
            </div>
          )}
        </div>

        {/* {Interaction Modal} */}

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          style={{ outline: "none" }}
          centered
        >
          <Modal.Header
            style={{
              height: "42px",
              color: "white",
              position: "relative",
              top: "-1px",
              backgroundColor: "#0b3363",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Modal.Title style={{ color: "#fff", fontSize: "16px" }}>
              Summary
            </Modal.Title>
            <Modal.Title>
              <AiOutlineCloseCircle
                size={20}
                id="close_circle"
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={handleClose}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex justify-content-between"
              style={{ height: "5rem" }}
            >
              <div className="text-primary font-weight-bold">
                Chat Arrival At
                <div className="mt-4  font-weight-normal text-dark">
                  {moment(summaryArrivalDate).format("lll")}
                </div>
              </div>
              <div className="text-primary font-weight-bold">
                Chat Started At
                <div className="mt-4  font-weight-normal text-dark">
                  {moment(summaryStartedDate).format("lll")}
                </div>
              </div>
              <div className="text-primary font-weight-bold">
                Comments
                <div className="mt-4 ms-1 font-weight-normal text-dark">
                  {comments ? comments : ""}
                </div>
              </div>
              <div className="text-primary font-weight-bold">
                Chat End At
                <div className="mt-4  font-weight-normal text-dark">
                  {summaryEndDate ? moment(summaryEndDate).format("lll") : "--"}
                </div>
              </div>

              <div className="text-primary font-weight-bold">
                Channel
                <div className="mt-4 font-weight-normal text-dark">
                  {summaryChannel}
                </div>
              </div>
              <div className="text-primary font-weight-bold">
                Agent
                <div className="mt-4  font-weight-normal text-dark">
                  {summaryAgent}
                </div>
              </div>

              <div className="text-primary font-weight-bold">
                View
                <div className="mt-4 d-flex mx-2">
                  <BsChatSquareText
                    onClick={() => {
                      if (summaryChannel == "email") {
                        getSessionsDetailsForEmail();
                      } else {
                        handlechatShow();
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    id="chat-icon-mail"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="d-flex p-2  justify-content-end">
            <Button onClick={handleClose} variant="primary" id="close_pills">
              Close
            </Button>
          </div>
        </Modal>
        {/* {Interaction Modal} */}

        {/* Interaction preview msg modal */}
        <Modal
          size="md"
          show={chatmodal}
          onHide={handlechatClose}
          style={{ outline: "none", overflow: "hidden" }}
          className="interaction_modal"
          centered
        >
          <Modal.Header
            style={{
              backgroundColor: "#0b3363",
              color: "#fff",
              height: "30px",
            }}
          >
            <Modal.Title
              id="example-modal-sizes-title-sm"
              style={{ color: "#fff", fontSize: "16px" }}
            >
              Chat Review
            </Modal.Title>

            <Modal.Title>
              <AiOutlineCloseCircle
                size={20}
                id="close-circle"
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={handlechatClose}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="custom-scroll"
            style={{
              overflowY: "auto",
              height: "24rem",
            }}
          >
            {summaryChannel == "email" ? (
              <div className="">
                {sessionvalueforemail.map((item) => {
                  return (
                    <div className="w-100">
                      <div className="user-chat-main d-flex flex-column ">
                        <div
                          // key={index}
                          className="p-1 h-75"
                        >
                          <div style={{ width: "100%" }}>
                            <div className="email-desc-wrapper">
                              <div className="title-subject">
                                <h5>{item.Email.subject}</h5>
                              </div>
                              <Accordion
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    <div className="email-header d-flex w-100">
                                      <div className="address mx-2 my-2 ">
                                        <p className="recipient" id="from"></p>
                                        <p className="recipient fs-6" id="to">
                                          <span>
                                            To:
                                            {item.Email.toList}
                                          </span>
                                        </p>
                                        {item.Email.ccList ? (
                                          <p className="recipient fs-6" id="cc">
                                            <span>
                                              Cc:
                                              {item.Email.ccList}
                                            </span>
                                          </p>
                                        ) : null}
                                        <p className="recipient fs-6" id="date">
                                          <span>
                                            {moment(
                                              item.Email.receivedTime
                                            ).format("MMMM Do YYYY, h:mm A")}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </Typography>
                                </AccordionSummary>

                                {item.Email.attachments.map((item, index) => (
                                  <>
                                    <div className="p-2">
                                      <div>
                                        <div
                                          key={index}
                                          aria-describedby
                                          variant="contained"
                                        >
                                          {item.filename.includes(".pdf") && (
                                            <div>
                                              <div
                                                className="card d-flex w-50"
                                                onClick={(event) => {
                                                  openDocument(item, event);
                                                }}
                                              >
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={pdf}
                                                    height="25px"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {item.filename.includes(".xlsx") && (
                                            <div
                                              className="card d-flex w-50"
                                              onClick={(event) => {
                                                openDocument(item, event);
                                              }}
                                            >
                                              <div className="card d-flex w-100">
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={excel}
                                                    height="25px"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {(item.filename.includes(".docx") ||
                                            item.filename.includes(".doc")) && (
                                            <div className="card d-flex w-50">
                                              <div
                                                className="card d-flex"
                                                onClick={(event) => {
                                                  openDocument(item, event);
                                                }}
                                              >
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={word}
                                                    height="25px"
                                                    alt=".docx or .doc"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {item.filename.includes(".png") && (
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                              onClick={(event) => {
                                                openDocument(item, event);
                                              }}
                                            />
                                          )}
                                          {item.filename.includes(".jpg") && (
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                              onClick={(event) => {
                                                openDocument(item, event);
                                              }}
                                            />
                                          )}
                                          {item.filename.includes(".jpeg") && (
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                              onClick={(event) => {
                                                openDocument(item, event);
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}

                                <Typography>
                                  <div className="mailcontent">
                                    <div className="email-body">
                                      {item.EmailBody
                                        ? parse(
                                            item.EmailBody.replace(
                                              /\s+/g,
                                              " "
                                            ).trim()
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                </Typography>

                                {/* <AccordionDetails>
                                  {!_.isEmpty(item.Email.attachments) ? (
                                    <>
                                      <Grid container spacing={1}>
                                        {_.map(
                                          _.filter(item.Email.attachments, {
                                            location: "attachment",
                                          }),
                                          (val, i) => {
                                            if (
                                              imageFormat.includes(
                                                val.extension
                                              )
                                            ) {
                                              return (
                                                <Grid item xs={3} key={i}>
                                                  <div
                                                    className="attachment_container"
                                                    id="open-mail"
                                                    onClick={(e) =>
                                                      openActionView(e, val)
                                                    }
                                                  >
                                                    <img
                                                      src={val.url}
                                                      alt="Avatar"
                                                      className="image_attachment"
                                                    />
                                                    <div className="attachment_overlay">
                                                      <div className="attachment_text">
                                                        {val.filename}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              );
                                            }
                                            return null;
                                          }
                                        )}
                                      </Grid>

                                      <Grid container spacing={1}>
                                        {_.map(
                                          _.filter(item.Email.attachments, {
                                            location: "attachment",
                                          }),
                                          (val, i) => {
                                            if (
                                              !imageFormat.includes(
                                                val.extension
                                              )
                                            ) {
                                              return (
                                                <Grid item xl={2} key={i}>
                                                  <Paper>
                                                    <MenuItem>
                                                      <ListItemIcon className="attachment_Preview_image">
                                                        <img
                                                          src={
                                                            val.extension
                                                          }
                                                          height="20px"
                                                          weight="20px"
                                                          alt="Icon"
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                        <span className="attachment_el_text">
                                                          {val.filename}
                                                        </span>
                                                      </ListItemText>
                                                      <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        id="open-mail"
                                                      >
                                                        <IconButton
                                                          onClick={(e) =>
                                                            openActionView(
                                                              e,
                                                              val
                                                            )
                                                          }
                                                        >
                                                          <ExpandMoreIcon />
                                                        </IconButton>
                                                      </Typography>
                                                    </MenuItem>
                                                  </Paper>
                                                </Grid>
                                              );
                                            }
                                            return null;
                                          }
                                        )}
                                      </Grid>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Typography>
                                    <div className="mailcontent">
                                      <div className="email-body">
                                        {item.EmailBody
                                          ? parse(
                                              item.EmailBody.replace(
                                                /\s+/g,
                                                " "
                                              ).trim()
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </Typography>
                                </AccordionDetails> */}
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div
                  className="chat-box-body"
                  style={{ backgroundColor: "transparent !important" }}
                >
                  <div className="chat-logs" style={{ height: "60vh" }}>
                    {chatHistory.map((item) => {
                      if (item.msg_sent_type == "NOTIFICATIONS") {
                        if (item.message == "Customer Ended the Chat") {
                          return (
                            <div
                              className="times"
                              style={{ background: "#d93d32" }}
                            >
                              {item.message}
                            </div>
                          );
                        } else {
                          return <div className="times">{item.message}</div>;
                        }
                      } else if (item.fromSelf == true) {
                        return (
                          <div className="chat-r">
                            <div className="user-chat-main d-flex justify-content-end flex-column align-items-end">
                              {item.msg_sent_type == "TEXT" ? (
                                <div className="user-chats text-break">
                                  {item.message}
                                </div>
                              ) : (
                                ""
                              )}

                              {item.msg_sent_type == "IMAGE" ? (
                                <div
                                  className="card p-2"
                                  style={{ borderRadius: "10px 10px 0 10px #" }}
                                >
                                  <img
                                    src={item.message}
                                    height="50px"
                                    width={150}
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              {item.msg_sent_type == "VIDEO" ? (
                                <div
                                  className="card p-2"
                                  style={{ borderRadius: "10px 10px 0 10px" }}
                                >
                                  <video width="150" controls>
                                    <source
                                      src={item.message}
                                      type="video/mp4"
                                    ></source>
                                  </video>
                                </div>
                              ) : (
                                ""
                              )}

                              {item.msg_sent_type == "AUDIO" ? (
                                <div
                                  className="user-chats"
                                  style={{ padding: "5px 5px" }}
                                >
                                  <audio className="msg_audio" controls>
                                    <source src={item.message}></source>
                                  </audio>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="chat-time-r">
                              {moment(item.time).format("h:mm A")} -
                              <div
                                className="mx-1"
                                style={{ fontSize: "10px" }}
                              >
                                {item.senderName}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (item.fromSelf == false) {
                        return (
                          <div className="chat-l">
                            <div className="bot-chat-main d-flex justify-content-start">
                              <div className="d-flex justify-content-start flex-column align-items-start w-100">
                                {item.msg_sent_type == "TEXT" ? (
                                  <div className="bot-chats text-break">
                                    {item.message}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {item.msg_sent_type == "IMAGE" ? (
                                  <div
                                    className="card p-2"
                                    style={{
                                      borderRadius: "10px 10px 10px 0px",
                                    }}
                                  >
                                    <img
                                      src={item.message}
                                      height="50px"
                                      width={150}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {item.msg_sent_type == "VIDEO" ? (
                                  <div
                                    className="card p-2"
                                    style={{
                                      borderRadius: "10px 10px 10px 0px",
                                    }}
                                  >
                                    <video width="150" controls>
                                      <source
                                        src={item.message}
                                        type="video/mp4"
                                      ></source>
                                    </video>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {item.msg_sent_type == "AUDIO" ? (
                                  <div
                                    className="bot-chats"
                                    style={{ padding: "5px 5px" }}
                                  >
                                    <audio className="msg_audio" controls>
                                      <source src={item.message}></source>
                                    </audio>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="chat-time-l">
                              {moment(item.time).format("h:mm A")} -
                              <div
                                className="mx-1"
                                style={{ fontSize: "10px" }}
                              >
                                {item.senderName}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
        {/* Interaction preview msg modal */}
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedemail,
  setselectedmobile,
})(Interactiontab);
