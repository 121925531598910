import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setChatRefresh,
  endInteraction,
} from "../../../../redux/actions/actions";
import FormControl from "@mui/material/FormControl";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
} from "@mui/material";

import { GitMerge } from "@phosphor-icons/react";

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Conferancesupervisormodel = (props) => {
  const { show, close, chatid, chat, OpenDialog, handleClose } = props;
  const [availableAgent, setAvailableAgent] = useState([]);
  const [agentTransferId, setAgentTransferId] = useState("agent");
  const [showTransferMod, setShowTransferMod] = useState(false);
  const [checkWhichCall, setCheckWhichCall] = useState("");
  const [showfiltersupervisor, setShowfiltersupervisor] = useState(false);
  const [supervisorValidation, setSupervisorValidation] = useState(false);

  const transferAgent = (val) => {
    setCheckWhichCall(val);
    setShowTransferMod(true);
  };

  const handleTransferClose = () => {
    setAgentTransferId("agent");
    setShowTransferMod(false);
  };

  const handleTransfersupervisorClose = () => setShowfiltersupervisor(false);
  const handleTransfersupervisorOpen = (val) => {
    setShowfiltersupervisor(true);
    setCheckWhichCall(val);
  };

  useEffect(() => {
    getAvailableAgents();
  }, []);

  const getAvailableAgents = async () => {
    const access_token = localStorage.getItem("access_token");

    try {
      let userId = JSON.parse(localStorage.getItem("tokenAgent"));
      let passUserId = userId.user_id;
      const data = await axios.post(
        BaseUrl + "/users/listSupervisor",
        {},
        {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );
      if (data.data.status) {
        // props.setavailagent(data.data.data);
        setAvailableAgent(data.data.data);
      }
    } catch (error) {
      // errorHandel(error, "/users/listSupervisor")
    }
  };

  const transferAgentSubmit = async () => {
    localStorage.removeItem("client");

    try {
      if (chat.is_customer_disconnected) {
        toast.error("Customer is disconnected", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        const access_token = localStorage.getItem("access_token");
        let selected_name =
          agentTransferId.options[agentTransferId.selectedIndex].text;
        let selected_val = agentTransferId.value;

        if (selected_name === "agent") {
          alert("select agent");
          return false;
        }

        let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));

        let dataToPass = {
          sessionId: chatid,
          agentId: selected_val,
          oldAgentId: old_agent_id.id,
        };

        let message_to_pass;

        message_to_pass =
          "Interaction Conferenced from " +
          old_agent_id.username +
          " to " +
          selected_name;
        const data = await axios.post(
          BaseUrl + "/users/conferenceAgent",
          dataToPass,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              // tenantId: tenantId,
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );

        if (data.data.status) {
          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: old_agent_id.id,
              to: chat.id,
              message: message_to_pass,
              senderName: old_agent_id.username,
              receiverName: chat.username,
              messageFrom: "fromAgent",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: "NOTIFICATIONS",
            },
            {
              headers: {
                // tenantId: tenantId,
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          );

          toast.success("Conferenced to supervisor success", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          props.setChatRefresh(true);
          handleTransferClose();
          props.endInteraction();
        } else {
          toast.warn(data.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }

        // }
      }
    } catch (error) {
      // errorHandel(error, "/users/conferenceAgent");
      props.setChatRefresh(true);
    }
  };

  return (
    <>
      <Dialog
        open={OpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          Conference To Supervisor
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack>
              <Stack direction={"row"}>
                <FormControl
                  sx={{ m: 1, minWidth: 170 }}
                  size="small"
                ></FormControl>
                <FormControl
                  sx={{ m: 1, minWidth: 180 }}
                  size="small"
                ></FormControl>
              </Stack>
              <select
                className={`form-select form-select-sm ${
                  supervisorValidation ? "is-invalid" : ""
                }`}
                name="availableAgent"
                onChange={(e) => setAgentTransferId(e.target)}
              >
                <option value="agent" selected>
                  Available Supervisor
                </option>
                {availableAgent.map((agents) => {
                  return (
                    <option value={agents.id}>
                      {agents.username ? agents.username : agents.firstName}
                    </option>
                  );
                })}
              </select>
              {supervisorValidation && (
                <div className="invalid-feedback">
                  Please select an Supervisor
                </div>
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (agentTransferId === "agent") {
                setSupervisorValidation(true);
              } else {
                transferAgentSubmit();
                handleClose();
              }
            }}
            variant="contained"
            size="small"
            id="agent-conf"
            endIcon={<GitMerge size={20} />}
          >
            {checkWhichCall}
            CONFERANCE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setChatRefresh,
  endInteraction,
})(Conferancesupervisormodel);
