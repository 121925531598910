import { Stack } from "@mui/material";
import React, { useState } from "react";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";

const AddCustomer = (props) => {
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [address, setaddress] = useState("");
  const [company, setcompany] = useState("");
  const [secondaryNumber, setSecondaryNumber] = useState("");


  const AddContact = (e) => {
    e.preventDefault();
    axios
      .post(
        BaseUrl + "/users/createcustomer",
        {
          username: username,
          email: email,
          phonenumber: phonenumber,
          address: address,
          company: company,
          secondaryNumber: secondaryNumber,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        props.listContact();
        if (res.data.status) {
        }
      })
      .catch((err) => {});
  };

  return (
    <nav
      className={props.showAddCustomer}
      style={{ top: "50px", width: "38%", right: "0px" }}
    >
      <div className="filter col-md-12 shadow p-4 ">
        <div className="d-flex align-items-center">
          <p className="mb-0">CREATE CUSTOMER</p>
          <div className="dismiss ">
            <span
              id="session_filter_close_btn"
              className="material-symbols-outlined"
              onClick={() => {
                props.setshowAddCustomer("");
              }}
            >
              cancel
            </span>
          </div>
        </div>

        <div className="filter_form">
          <form
            className=" fillter_form2 mt-2"
            style={{ height: "68vh" }}
            onSubmit={(e) => {
              AddContact(e);
            }}
            id="form1"
          >
            <div className="custom_div">
              <Stack sapcing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>
                      UserName <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      required
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control form-select-sm"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      required
                    />
                  </div>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control form-select-sm"
                      required
                      value={phonenumber}
                      onChange={(e) => {
                        setphonenumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                  </div>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Secondary Number</label>
                    <input
                      type="number"
                      className="form-control form-select-sm"
                      value={secondaryNumber}
                      onChange={(e) => {
                        setSecondaryNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      value={company}
                      onChange={(e) => {
                        setcompany(e.target.value);
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </div>
          </form>

          <div className="filter_submit">
            <div className="d-flex">
              <div className="col-md-12 ps-2">
                <button
                  type="submit"
                  form="form1"
                  value="Submit"
                  id="add_customer_Submit"
                  className="btn btn-primary w-100 text-white"
                >
                  CREATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AddCustomer;
