import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { setDialedNumberInc } from "../../redux/actions/actions";
import voice from "../../assets/assets/images/voice.png";
import { toast } from "react-toastify";
import axios from "axios";
import { AvcUrl, BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { Pagination, Skeleton, Stack } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

const mapStateToProps = (state) => {
  return { skillset: state.data.skillset };
};

const AgentContact = (props) => {
  const agent = JSON.parse(localStorage.getItem("tokenAgent"));

  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);

  const [pagination2, setPagination2] = useState(0);
  const [page2, setPage2] = useState(1);

  const [pagination3, setPagination3] = useState(0);
  const [page3, setPage3] = useState(1);

  const [buttontype1, setbuttontype1] = useState("contained");
  const [buttontype2, setbuttontype2] = useState("outlined");
  const [buttontype3, setbuttontype3] = useState("outlined");

  const [serviceReportList, setServiceReportList] = useState([]);
  const [salesReport, setSalesReport] = useState([]);
  const [loadingAgentService, setLoadingAgentService] = useState(false);
  const [loadingAgentSales, setLoadingAgentSales] = useState(false);
  const [loadingAgentreport, setLoadingAgentreport] = useState(false);
  const [tableListType, settableListType] = useState("table1");

  const [agentCount, setagentCount] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleChange2 = (event, value) => {
    setPage2(value);
  };

  const handleChange3 = (event, value) => {
    setPage2(value);
  };

  const ServiceReport = async () => {
    setLoadingAgentService(true);
    await axios
      .post(
        BaseUrl + "/contact/listAgentservicereport",
        {
          offset: page == 1 ? 0 : (page - 1) * 10,
          limit: 10,
          agent_id: agent.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setServiceReportList(res.data.data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingAgentService(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setServiceReportList([]);
          setTimeout(() => {
            setLoadingAgentService(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("AgentContact", error);
        setTimeout(() => {
          setLoadingAgentSales(false);
        }, 1000);
      });
  };

  const SalesReport = async () => {
    setLoadingAgentSales(true);
    await axios
      .post(
        BaseUrl + "/contact/listAgentreport",
        {
          offset: page2 == 1 ? 0 : (page2 - 1) * 10,
          limit: 10,
          agent_id: agent.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSalesReport(res.data.data);
          setPagination2(res.data.count);
          setTimeout(() => {
            setLoadingAgentSales(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setSalesReport([]);
          setTimeout(() => {
            setLoadingAgentSales(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("AgentContact", error);
        setTimeout(() => {
          setLoadingAgentSales(false);
        }, 1000);
      });
  };

  const agentCallcount = async () => {
    setLoadingAgentreport(true);
    let body = {
      offset: page3 == 1 ? 0 : (page3 - 1) * 10,
      limit: 10,
      agent_id: agent.agent_id,
    };
    await axios
      .post(BaseUrl + "/contact/callcontactcount", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("agentCallcount", res.data.Data);
        if (res.data.success == true) {
          setagentCount(res.data.Data);
          setPagination3(res.data.count);
          setTimeout(() => {
            setLoadingAgentreport(false);
          }, 1000);
        } else {
          setagentCount([]);
          setPagination3(0);
          setTimeout(() => {
            setLoadingAgentreport(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const makeCall = (calledNo, type, id, reg_no) => {
    localStorage.setItem("callActivate", "true");
    localStorage.setItem("CallMadeFromDcc", "true");
    let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");
    props.setDialedNumberInc(calledNo);
    localStorage.setItem("ClickeToCallPhoneNum", calledNo);
    localStorage.setItem("dailedNumber", calledNo);

    axios
      .post(
        AvcUrl +
          `/voice/terminal/${agentLoginTerminalId}/createContact/sip:${agentLoginTerminalId}@demoaccs.com/sip:${calledNo}@${localStorage.getItem(
            "AvayaDomain"
          )}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 500) {
          toast.warn(res.data.status, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else if (res.data.status == "OK") {
          localStorage.setItem("type", type);
          localStorage.setItem("ContactVoiceId", id);
          localStorage.setItem("Contactreg_no", reg_no);
          localStorage.setItem("ClickToCall", "true");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    ServiceReport();
  }, [page]);
  useEffect(() => {
    SalesReport();
  }, [page2]);

  useEffect(() => {
    agentCallcount();
  }, [page3]);

  return (
    <>
      <div className="sessiontable">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="w-100">
            <div className="d-flex align-item-center justify-content-between align-items-center">
              <Stack className="d-flex flex-row gap-1">
                <Button
                  size="small"
                  id="primary-btn"
                  variant={buttontype1}
                  onClick={() => {
                    setbuttontype1("contained");
                    setbuttontype2("outlined");
                    setbuttontype3("outlined");
                    settableListType("table1");
                    localStorage.setItem("surveyType", "Service");
                  }}
                >
                  Service
                </Button>
                <Button
                  size="small"
                  id="primary-btn"
                  variant={buttontype2}
                  onClick={() => {
                    setbuttontype1("outlined");
                    setbuttontype2("contained");
                    setbuttontype3("outlined");
                    settableListType("table2");

                    localStorage.setItem("surveyType", "Sales");
                  }}
                >
                  Sales
                </Button>
                <Button
                  size="small"
                  id="agent-primary-btn"
                  variant={buttontype3}
                  onClick={() => {
                    setbuttontype1("outlined");
                    setbuttontype2("outlined");
                    setbuttontype3("contained");
                    settableListType("table3");

                    localStorage.setItem("surveyType", "AgentReport");
                  }}
                >
                  Agent Report
                </Button>
              </Stack>
            </div>
          </div>
        </nav>

        <div className="container-fluid  p-2">
          {(() => {
            switch (tableListType) {
              case "table1":
                return (
                  <div className="card fixed-table-card vh-79 ">
                    <div className="card-body agentContactbody">
                      <div className="table-responsive vhm-68">
                        <table
                          className="table table-sm table-hover align-middle table-fixeded"
                          style={{ width: "140%" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">Action</th>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Registration No</th>
                              <th scope="col">Model Description</th>
                              <th scope="col">Chassis No</th>
                              <th scope="col">Job Card</th>
                              <th scope="col">Email</th>
                              <th scope="col">Address2</th>
                              <th scope="col">Brand</th>
                              <th scope="col">Location</th>

                              <th scope="col">Invoice No</th>
                              <th scope="col">Invoice Date</th>
                              <th scope="col">Year</th>
                              <th scope="col">External Color</th>
                              <th scope="col">Customer Category</th>
                              <th scope="col">Service Advisor</th>
                              <th scope="col">Mileage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadingAgentService ? (
                              [...Array(8)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {[...Array(17)].map((_, colIndex) => (
                                    <td key={colIndex}>
                                      <Skeleton
                                        variant="text"
                                        width={80}
                                        height={30}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : serviceReportList.length > 0 ? (
                              serviceReportList.map((rowData, rowIndex) => (
                                <tr
                                  key={rowIndex.id}
                                  className="text-break"
                                  id="service-btn"
                                >
                                  <td>
                                    <img
                                      className="icons-size"
                                      src={voice}
                                      alt="webchat"
                                      onClick={() => {
                                        makeCall(
                                          rowData.MOBILE,
                                          "service",
                                          rowData.id,
                                          rowData.REG_NO
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{rowData.CUST_NAME}</td>
                                  <td>{rowData.MOBILE}</td>
                                  <td>{rowData.REG_NO}</td>
                                  <td>{rowData.Model_Description}</td>
                                  <td>{rowData.CHASSIS_NO}</td>
                                  <td>{rowData.Job_Card}</td>
                                  <td>{rowData.EMAIL}</td>
                                  <td>{rowData.Address2}</td>
                                  <td>{rowData.Brand}</td>
                                  <td>{rowData.Location}</td>

                                  <td>{rowData.Invoice_No}</td>
                                  <td>{rowData.Invoice_Date}</td>
                                  <td>{rowData.YEAR}</td>
                                  <td>{rowData.External_Color}</td>
                                  <td>{rowData.CUSTOMER_CATEGORY}</td>
                                  <td>{rowData.ADVISOR}</td>
                                  <td>{rowData.MILEAGE}</td>
                                </tr>
                              ))
                            ) : (
                              <tr key="">
                                <td colSpan="15">
                                  <div
                                    className="text-muted"
                                    style={{
                                      flexDirection: "column",
                                      marginTop: "20%",
                                      display: "flex",
                                      position: "absolute",
                                      left: "45%",
                                      bottom: "40%",
                                    }}
                                  >
                                    <span className="mx-auto">
                                      <RiErrorWarningLine size={30} />
                                    </span>
                                    <span className="mx-auto">
                                      <h5
                                        className="text-muted mt-2"
                                        style={{ fontFamily: "poppins" }}
                                      >
                                        No Data Found
                                      </h5>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
                      <Pagination
                        count={Math.ceil(pagination / 10)}
                        page={page}
                        color="primary"
                        size="small"
                        style={{ float: "right" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                );
              case "table2":
                return (
                  <div className="card fixed-table-card vh-79 ">
                    <div className="card-body agentContactbody">
                      <div className="table-responsive vhm-68">
                        <table
                          className="table table-sm table-hover align-middle table-fixeded"
                          style={{ width: "140%" }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">Action</th>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Registration No</th>
                              <th scope="col">Model Description</th>
                              <th scope="col">Chassis No</th>
                              <th scope="col">Email</th>
                              <th scope="col">Address2</th>
                              <th scope="col">Brand</th>
                              <th scope="col">Location</th>
                              <th scope="col">Invoice Branch</th>
                              <th scope="col">Invoice No</th>
                              <th scope="col">Invoice Date</th>
                              <th scope="col">Year</th>
                              <th scope="col">External Color</th>
                              <th scope="col">Customer Category</th>
                              <th scope="col">Salesman</th>
                              <th scope="col">Delivary Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadingAgentSales ? (
                              [...Array(8)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {[...Array(17)].map((_, colIndex) => (
                                    <td key={colIndex}>
                                      <Skeleton
                                        variant="text"
                                        width={80}
                                        height={30}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : salesReport.length > 0 ? (
                              salesReport.map((rowData, rowIndex) => (
                                <tr
                                  key={rowIndex.id}
                                  className="text-break"
                                  id="sales-btn"
                                >
                                  <td>
                                    <img
                                      className="icons-size"
                                      src={voice}
                                      alt="webchat"
                                      onClick={() => {
                                        makeCall(
                                          rowData.Mobile,
                                          "sales",
                                          rowData.id,
                                          rowData.Registration_No
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>{rowData.Customer_Name}</td>
                                  <td>{rowData.Mobile}</td>
                                  <td>{rowData.Registration_No}</td>
                                  <td>{rowData.Model_Description}</td>
                                  <td>{rowData.Chassis_No}</td>
                                  <td>{rowData.Email}</td>
                                  <td>{rowData.Address2}</td>
                                  <td>{rowData.Brand}</td>
                                  <td>{rowData.Location}</td>
                                  <td>{rowData.Inv_Brch}</td>
                                  <td>{rowData.Invoice_No}</td>
                                  <td>{rowData.Inv_Date}</td>
                                  <td>{rowData.Year}</td>
                                  <td>{rowData.External_Color}</td>
                                  <td>{rowData.Customer_Category}</td>
                                  <td>{rowData.Salesman}</td>
                                  <td>{rowData.Delivery_Date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr key="">
                                <td colSpan="15">
                                  <div
                                    className="text-muted"
                                    style={{
                                      flexDirection: "column",
                                      marginTop: "20%",
                                      display: "flex",
                                      position: "absolute",
                                      left: "45%",
                                      bottom: "40%",
                                    }}
                                  >
                                    <span className="mx-auto">
                                      <RiErrorWarningLine size={30} />
                                    </span>
                                    <span className="mx-auto">
                                      <h5
                                        className="text-muted mt-2"
                                        style={{ fontFamily: "poppins" }}
                                      >
                                        No Data Found
                                      </h5>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
                      <Pagination
                        count={Math.ceil(pagination2 / 10)}
                        page={page2}
                        color="primary"
                        size="small"
                        style={{ float: "right" }}
                        onChange={handleChange2}
                      />
                    </div>
                  </div>
                );
              case "table3":
                return (
                  <div className="card fixed-table-card vh-79 ">
                    <div className="card-body agentContactbody">
                      <div className="table-responsive vhm-68">
                        <table
                          className="table table-sm table-hover align-middle table-fixeded"
                          style={{ width: "130%" }}
                        >
                          <thead>
                            <tr className="">
                              <th scope="col" style={{ width: "30%" }}>
                              Total Assign Call
                              </th>
                              <th scope="col" style={{ width: "30%" }}>
                                Total Pending Call
                              </th>
                              <th scope="col" style={{ width: "30%" }}>
                                Total Complete Call
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadingAgentreport ? (
                              [...Array(3)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                  {[...Array(3)].map((_, colIndex) => (
                                    <td key={colIndex}>
                                      <Skeleton
                                        variant="text"
                                        width={50}
                                        height={30}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            ) : agentCount.length > 0 ? (
                              agentCount.map((rowData, rowIndex) => (
                                <tr key={rowIndex.id} className="" id="">
                                  <td>{rowData.total}</td>
                                  <td>{rowData.totalpendingcall}</td>
                                  <td>{rowData.totalcompletecall}</td>
                                </tr>
                              ))
                            ) : (
                              <tr key="">
                                <td colSpan="15">
                                  <div
                                    className="text-muted"
                                    style={{
                                      flexDirection: "column",
                                      marginTop: "20%",
                                      display: "flex",
                                      position: "absolute",
                                      left: "45%",
                                      bottom: "40%",
                                    }}
                                  >
                                    <span className="mx-auto">
                                      <RiErrorWarningLine size={30} />
                                    </span>
                                    <span className="mx-auto">
                                      <h5
                                        className="text-muted mt-2"
                                        style={{ fontFamily: "poppins" }}
                                      >
                                        No Data Found
                                      </h5>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
                      <Pagination
                        count={Math.ceil(pagination3 / 10)}
                        page={page3}
                        color="primary"
                        size="small"
                        style={{ float: "right" }}
                        onChange={handleChange3}
                      />
                    </div>
                  </div>
                );
              default:
                return <></>;
            }
          })()}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, { setDialedNumberInc })(AgentContact);
