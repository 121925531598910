import React, { useState } from "react";
import facebook from "../../assets/assets/images/facebook.png";
import whatsapp from "../../assets/assets/images/whatsapp.png";
import webchat from "../../assets/assets/images/chat-icon.png";
import twitter from "../../assets/assets/images/twitter.png";
import email from "../../assets/assets/images/email.png";
import voice from "../../assets/assets/images/voice.png";
import chatbot from "../../assets/assets/images/chatbot.png";
import videochat from "../../assets/assets/images/videochat.png";
import teams from "../../assets/assets/images/teams.png";
import moment from "moment";
import { Badge } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { Skeleton, Stack } from "@mui/material";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { Eye, GitPullRequest } from "@phosphor-icons/react";
import ViewMoreDialogBox from "../../Page/SessionReports/ViewMoreDialogBox";

const sessiontable = (props) => {
  const [userdetails, setuserdetails] = useState();
  const [openDialogbox, setopenDialogbox] = useState(false);
  const ViewMore = (id) => {
    axios
      .post(
        BaseUrl + "/reports/viewmore",
        {
          id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setuserdetails(res.data.Data[0]);
          setopenDialogbox(true);
        }
      })
      .catch((err) => {});
  };

  const ChatPull = (value) => {
    axios
      .post(
        BaseUrl + "/users/chatpull",
        {
          chat_session_id: "session3516e2f4c18e5",
          agent_id: "6544cb439559771b12e4d8b8",
          user_id: "22344816-c5e6-4a8a-b90d-275c0a564f0c",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const handleClose = () => {
    setopenDialogbox(false);
  };
  return (
    <>
      <div className="container-fluid p-2">
        <div className="card fixed-table-card vh-79 sessioncardbody">
          <div className="card-body session2table">
            <div className="table-responsive vhm-68">
              <table className="table table-sm table-hover align-middle table-fixeded">
                <thead>
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Channel</th>
                    <th scope="col">Direction</th>
                    <th scope="col">Status</th>
                    <th scope="col">Skillset</th>
                    <th scope="col">Language</th>
                    <th scope="col">Agent Name</th>
                    <th scope="col">Initiated At</th>
                    <th scope="col">Started At</th>
                    <th scope="col">Ended At</th>
                    <th scope="col">Transferred Agent Name</th>
                    <th scope="col">Conference Agent Name</th>
                    <th scope="col">Call Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {props.loadingreports ? (
                    [...Array(8)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {[...Array(14)].map((_, colIndex) => (
                          <td key={colIndex}>
                            <Skeleton variant="text" width={60} height={30} />
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : props.sessionreport.length > 0 ? (
                    props.sessionreport.map((rowData, rowIndex) => (
                      <tr key={rowIndex._id} className="text-break">
                        <td>{rowData.name}</td>
                        <td>{rowData.phone_number}</td>
                        <td>{rowData.email}</td>
                        <td>
                          {rowData.channel == "webchat" ? (
                            <img
                              className="icons-size"
                              src={webchat}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_whatsapp" ? (
                            <img
                              className="icons-size"
                              src={whatsapp}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_twitter" ? (
                            <img
                              className="icons-size"
                              src={twitter}
                              alt="webchat"
                            />
                          ) : rowData.channel == "voice" ? (
                            <img
                              className="icons-size"
                              src={voice}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_facebook" ? (
                            (
                              <img
                                className="icons-size"
                                src={facebook}
                                alt="webchat"
                              />
                            ) || rowData.channel == "from_teams"
                          ) : (
                            <img
                              className="icons-size"
                              src={email}
                              alt="webchat"
                            />
                          )}
                        </td>
                        <td>{rowData.direction}</td>
                        <td>
                          {rowData.status == "chatEnded" ? (
                            <Badge variant="secondary">Completed</Badge>
                          ) : (
                            <Badge variant="success">Active </Badge>
                          )}
                        </td>

                        <td>{rowData.skillset}</td>
                        <td>{rowData.language}</td>
                        <td>{rowData.agent_name ? rowData.agent_name : ""}</td>
                        <td>
                          {moment(rowData.chat_arrival_at).format(
                            "L hh:mm:ss A"
                          )}
                        </td>
                        <td>
                          {moment(rowData.chat_started_at).format(
                            "L hh:mm:ss A"
                          )}
                        </td>
                        <td>
                          {rowData.chat_ended_at == null ||
                          rowData.chat_ended_at == undefined ||
                          rowData.chat_ended_at == ""
                            ? "--"
                            : moment(rowData.chat_ended_at).format(
                                "L hh:mm:ss A"
                              )}
                        </td>

                        <td>
                          {rowData.reason == null ||
                          rowData.reason == undefined ||
                          rowData.reason == ""
                            ? "--"
                            : rowData.reason}
                        </td>

                        <td>
                          <Stack direction={"row"} spacing={2}>
                            <Eye
                              weight="duotone"
                              size={22}
                              onClick={() => {
                                ViewMore(rowData._id);
                              }}
                              cursor={"pointer"}
                            />

                            {rowData.channel == "voice" ||
                            rowData.channel == "from_whatsapp" ? (
                              <GitPullRequest
                                size={20}
                                weight="bold"
                                cursor={"pointer"}
                                onClick={() => {
                                  ChatPull(rowData);
                                }}
                              />
                            ) : null}
                          </Stack>
                        </td>
                        <td>
                          <td>
                            {rowData.chat_started_at && rowData.chat_ended_at
                              ? moment
                                  .utc(
                                    moment(rowData.chat_ended_at).diff(
                                      moment(rowData.chat_started_at)
                                    )
                                  )
                                  .format("HH:mm:ss")
                              : "--"}
                          </td>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key="">
                      <td colSpan="15">
                        <div
                          className="text-muted"
                          style={{
                            flexDirection: "column",
                            marginTop: "20%",
                            display: "flex",
                            position: "absolute",
                            left: "45%",
                            bottom: "40%",
                          }}
                        >
                          <span className="mx-auto">
                            <RiErrorWarningLine size={30} />
                          </span>
                          <span className="mx-auto">
                            <h5
                              className="text-muted mt-2"
                              style={{ fontFamily: "poppins" }}
                            >
                              No Data Found
                            </h5>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ViewMoreDialogBox
        openDialogbox={openDialogbox}
        handleClose={handleClose}
        data={userdetails}
      />
    </>
  );
};

export default sessiontable;
