import React, { useState, useEffect } from "react";
import { HiExternalLink, HiOutlineLink } from "react-icons/hi";
import { BaseUrlSurvey } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
} from "../../../../redux/actions/actions";
import { motion } from "framer-motion";
import { Box, Stack, Typography } from "@mui/material";
import { Copy, Link } from "@phosphor-icons/react";
const mapStateToProps = (state) => {
  const { data } = state;
  return {
    selectedusername: data.selectedusername,
    selectedemail: data.selectedemail,
    selectedmobile: data.selectedmobile,
    chat: state.data.chat,
    selectedContactId: data.selectedContactId,
  };
};

const SurveyTab = (props) => {
  let permission = JSON.parse(localStorage.getItem("permission"));

  const [surveyListUrl, setSurveyListUrl] = useState([]);
  const [copied, setCopied] = useState(false);
  const [opensidebarsurvey, setOpensidebarsurvey] = useState(false);
  const [surveyUrl, setsurveyUrl] = useState("");
  const [tinySurveyUrl, settinySurveyUrl] = useState("");

  const agentEmail = JSON.stringify(localStorage.getItem("emailDisplay"));

  const handelopenSidebar = () => {
    setOpensidebarsurvey(true);
  };
  const closeopenSidebar = () => {
    setOpensidebarsurvey(false);
  };

  const surveyListApi = async () => {
    await axios
      .get(
        BaseUrlSurvey +
          `/organizations/58?cust_phone=${props.selectedmobile}&cust_name=${props.selectedusername}&cust_email=${props.selectedemail}&cust_callType=${props.chat.direction}&cust_contactId=${props.chat.contactId}&agent_email=${agentEmail}`,
        {}
      )
      .then((res) => {
        setSurveyListUrl(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    {
      permission.map((module) => {
        if (module.moduleName === "Chat") {
          const value = module.screen.find(
            (item) => item.screenId === "SURVEY" && item.read && item.write
          );
          if (value) {
            surveyListApi();
          }
        }
      });
    }
  }, []);

  const copyToClipboard = (newTinyURL) => {
    if (navigator.clipboard && newTinyURL) {
      navigator.clipboard.writeText(newTinyURL);
    }
  };

  const tinyUrlCopyLinkApi = (survey) => {
    const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    const body = {
      url: `${survey.url}&contactId=${
        props.selectedContactId
      }&tenentId=${localStorage.getItem(
        "TenantId"
      )}&type=${localStorage.getItem("surveyType")}`,
      domain: "tinyurl.com",
      alias: "Inaipis" + randomNumber,
      tags: " ",
      expires_at: " ",
      description: "string",
    };
    axios
      .post(
        "https://api.tinyurl.com/create?api_token=4hYPbXJbmSkt0KRkzWB1p4DHleUaS7Tm3YIPxw0Z86cvYlAAaMsRySiuWi0x",
        body
      )
      .then((res) => {
        const newTinyURL = res.data.data.tiny_url;
        if (res.status === 200 && newTinyURL) {
          if (navigator.clipboard) {
            navigator.clipboard
              .writeText(newTinyURL)
              .then(() => {
                toast.success("URL copied to clipboard", {
                  position: "top-right",
                  autoClose: 500,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                });
              })
              .catch((error) => {
                console.error("Failed to copy text:", error);
                toast.error("Failed to copy URL to clipboard", {
                  position: "top-right",
                  autoClose: 500,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                });
              });
          }
        }
      })
      .catch((error) => {
        toast.warning("URL not copied in API", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const copySurveyUrl = (text) => {
    const regex = /^(.*?id=\d+)/;
    const match = text.match(regex);
    if (match && match[1]) {
      const extractedUrl = match[1];
      settinySurveyUrl(extractedUrl);
      navigator.clipboard
        .writeText(extractedUrl)
        .then(() => {
          setCopied(true);
        })
        .catch((err) => {
          console.error("Unable to copy:", err);
          toast.error("Url failed to copy", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
    }
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="time-line-main m-2 p-2">
          {surveyListUrl.length > 0 ? (
            surveyListUrl.map((survey) => {
              return (
                <motion.div
                  className="card my-2"
                  key={survey.id}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    p={1}
                  >
                    <Stack sx={{ flex: 1 }}>
                      <Typography variant="subtitle1">
                        {survey.survey}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={0.5}>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          cursor: "pointer",
                          transition:
                            "background-color 0.5s, color 0.5s, fill 0.5s",
                          "&:hover": {
                            background: "#595858e8",
                            color: "white",
                          },
                        }}
                        onClick={() => {
                          window.open(
                            `${survey.url}`,
                            "_blank",
                            "height=600,width=1200"
                          );
                        }}
                      >
                        <Link 
                          size={20}
                          cursor={"pointer"}
                          weight="bold"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          cursor: "pointer",
                          transition:
                            "background-color 0.5s, color 0.5s, fill 0.5s",
                          "&:hover": {
                            background: "#595858e8",
                            color: "white",
                          },
                        }}
                        onClick={() => {
                          copySurveyUrl(survey.url);
                          tinyUrlCopyLinkApi(survey);
                          copyToClipboard();
                        }}
                      >
                        <Copy size={20} weight="bold" />
                      </Box>
                    </Stack>
                  </Stack>
                </motion.div>
              );
            })
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center m-2">
                <div className="tickt-name">SurveyTab</div>
              </div>
              <div className="time-line-main m-2 p-2">
                <div>
                  <div
                    className="text-muted d-flex"
                    style={{
                      flexDirection: "column",
                      marginTop: "25%",
                    }}
                  >
                    <span className="mx-auto">
                      <RiErrorWarningLine size={30} />
                    </span>
                    <span className="mx-auto">
                      <h6
                        className="text-muted mt-2"
                        style={{ fontFamily: "poppins" }}
                      >
                        No Data Found
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {opensidebarsurvey && (
        <nav
          className="sidebar_filter"
          style={{
            position: "absolute",
            zIndex: "1",
            left: "14rem",
            width: "66rem",
            top: "48px",
          }}
        >
          <div className="filter col-md-3 shadow p-2">
            <div className="d-flex justify-content-end align-item-end text-end">
              <p className="mb-0">
                <IoCloseCircleOutline
                  id="close_circle"
                  onClick={() => {
                    closeopenSidebar();
                  }}
                />
              </p>
            </div>
            {/* {surveyListUrl && (
              <iframe
                target="_blank"
                src={surveyUrl}
                width="100%"
                height="100%"
                title="Embedded Content"
              />
            )} */}
          </div>
        </nav>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
})(SurveyTab);
