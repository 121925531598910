import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { setDialedNumberInc } from "../../redux/actions/actions";
import voice from "../../assets/assets/images/voice.png";
import { toast } from "react-toastify";
import axios from "axios";
import { AvcUrl, BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { Pagination, Skeleton, Stack } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Chip } from "@mui/material";
import { Eye } from "@phosphor-icons/react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
}));

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const mapStateToProps = (state) => {
  return { skillset: state.data.skillset };
};

const AgentContactNew = (props) => {
  const agent = JSON.parse(localStorage.getItem("tokenAgent"));

  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);

  const [pagination2, setPagination2] = useState(0);
  const [page2, setPage2] = useState(1);

  const [pagination3, setPagination3] = useState(0);
  const [page3, setPage3] = useState(1);

  const [buttontype1, setbuttontype1] = useState("contained");
  const [buttontype2, setbuttontype2] = useState("outlined");
  const [buttontype3, setbuttontype3] = useState("outlined");

  const [serviceReportList, setServiceReportList] = useState([]);
  const [salesReport, setSalesReport] = useState([]);
  const [loadingAgentService, setLoadingAgentService] = useState(false);
  const [loadingAgentSales, setLoadingAgentSales] = useState(false);
  const [loadingAgentreport, setLoadingAgentreport] = useState(false);
  const [tableListType, settableListType] = useState("table1");

  const [agentCount, setagentCount] = useState([]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleChange2 = (event, value) => {
    setPage2(value);
    SalesReport();
  };

  const handleChange3 = (event, value) => {
    setPage3(value);
    agentCallcount();
  };

  const ServiceReport = async () => {
    setLoadingAgentService(true);
    await axios
      .post(
        BaseUrl + "/contact/listAgentservicereport",
        {
          offset: page == 1 ? 0 : (page - 1) * 10,
          limit: 10,
          agent_id: agent.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setServiceReportList(res.data.data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingAgentService(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setServiceReportList([]);
          setTimeout(() => {
            setLoadingAgentService(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("AgentContact", error);
        setTimeout(() => {
          setLoadingAgentSales(false);
        }, 1000);
      });
  };

  const SalesReport = async () => {
    setLoadingAgentSales(true);
    await axios
      .post(
        BaseUrl + "/contact/listAgentreport",
        {
          offset: page2 == 1 ? 0 : (page2 - 1) * 10,
          limit: 10,
          agent_id: agent.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSalesReport(res.data.data);
          setPagination2(res.data.count);
          setTimeout(() => {
            setLoadingAgentSales(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setSalesReport([]);
          setTimeout(() => {
            setLoadingAgentSales(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("AgentContact", error);
        setTimeout(() => {
          setLoadingAgentSales(false);
        }, 1000);
      });
  };

  const agentCallcount = async () => {
    setLoadingAgentreport(true);
    let body = {
      offset: page3 == 1 ? 0 : (page3 - 1) * 10,
      limit: 10,
      agent_id: agent.agent_id,
    };
    await axios
      .post(BaseUrl + "/contact/callcontactcount", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("agentCallcount", res.data.Data);
        if (res.data.success == true) {
          setagentCount(res.data.Data);
          setPagination3(res.data.count);
          setTimeout(() => {
            setLoadingAgentreport(false);
          }, 1000);
        } else {
          setagentCount([]);
          setPagination3(0);
          setTimeout(() => {
            setLoadingAgentreport(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const makeCall = (calledNo, type, id, reg_no) => {
    localStorage.setItem("callActivate", "true");
    localStorage.setItem("CallMadeFromDcc", "true");
    let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");
    props.setDialedNumberInc(calledNo);
    localStorage.setItem("ClickeToCallPhoneNum", calledNo);
    localStorage.setItem("dailedNumber", calledNo);

    axios
      .post(
        AvcUrl +
          `/voice/terminal/${agentLoginTerminalId}/createContact/sip:${agentLoginTerminalId}@demoaccs.com/sip:${calledNo}@${localStorage.getItem(
            "AvayaDomain"
          )}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 500) {
          toast.warn(res.data.status, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else if (res.data.status == "OK") {
          localStorage.setItem("type", type);
          localStorage.setItem("ContactVoiceId", id);
          localStorage.setItem("Contactreg_no", reg_no);
          localStorage.setItem("ClickToCall", "true");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    ServiceReport();
  }, [page]);

  return (
    <>
      <StyledPaper elevation={0}>
        <Stack spacing={1}>
          <Stack className="d-flex flex-row gap-1">
            <Button
              size="small"
              id="primary-btn"
              variant={buttontype1}
              onClick={() => {
                setbuttontype1("contained");
                setbuttontype2("outlined");
                setbuttontype3("outlined");
                settableListType("table1");
                ServiceReport();
                localStorage.setItem("surveyType", "Service");
              }}
            >
              Service
            </Button>
            <Button
              size="small"
              id="primary-btn"
              variant={buttontype2}
              onClick={() => {
                setbuttontype1("outlined");
                setbuttontype2("contained");
                setbuttontype3("outlined");
                settableListType("table2");
                SalesReport();
                localStorage.setItem("surveyType", "Sales");
              }}
            >
              Sales
            </Button>
            <Button
              size="small"
              id="agent-primary-btn"
              variant={buttontype3}
              onClick={() => {
                setbuttontype1("outlined");
                setbuttontype2("outlined");
                setbuttontype3("contained");
                settableListType("table3");
                agentCallcount();
                localStorage.setItem("surveyType", "AgentReport");
              }}
            >
              Agent Report
            </Button>
          </Stack>

          {(() => {
            switch (tableListType) {
              case "table1":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Action", minWidth: 30 },
                                { label: "Customer Name", minWidth: 130 },
                                { label: "Mobile", minWidth: 100 },
                                { label: "Registration No", minWidth: 120 },
                                { label: "Model Description", minWidth: 140 },
                                { label: "Chassis No", minWidth: 100 },
                                { label: "Job Card", minWidth: 100 },
                                { label: "Email", minWidth: 100 },
                                { label: "Address2", minWidth: 140 },
                                { label: "Brand", minWidth: 50 },
                                { label: "Location", minWidth: 50 },
                                { label: "Invoice No", minWidth: 90 },
                                { label: "Invoice Date", minWidth: 100 },
                                { label: "Year", minWidth: 40 },
                                { label: "External Color", minWidth: 140 },
                                { label: "Customer Category", minWidth: 150 },
                                { label: "Service Advisor", minWidth: 140 },
                                { label: "Mileage", minWidth: 40 },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingAgentService
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(18)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={60}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : serviceReportList.length > 0
                              ? serviceReportList.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowIndex.id}
                                  >
                                    <StyledTableCell2>
                                      {" "}
                                      <img
                                        className="icons-size"
                                        src={voice}
                                        alt="webchat"
                                        onClick={() => {
                                          makeCall(
                                            rowData.MOBILE,
                                            "service",
                                            rowData.id,
                                            rowData.REG_NO
                                          );
                                        }}
                                      />
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CUST_NAME}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.MOBILE}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.REG_NO}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Model_Description}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CHASSIS_NO}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Job_Card}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.EMAIL}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Address2}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Brand}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Location}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_Date}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.YEAR}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.External_Color}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CUSTOMER_CATEGORY}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.ADVISOR}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.MILEAGE}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        count={Math.ceil(pagination / 10)}
                        page={page}
                        onChange={handleChange}
                      />
                    </Stack>
                  </>
                );
              case "table2":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Action", minWidth: 30 },
                                { label: "Customer Name", minWidth: 130 },
                                { label: "Mobile", minWidth: 100 },
                                { label: "Registration No", minWidth: 130 },
                                { label: "Model Description", minWidth: 140 },
                                { label: "Chassis No", minWidth: 100 },
                                { label: "Email", minWidth: 120 },
                                { label: "Address2", minWidth: 40 },
                                { label: "Brand", minWidth: 40 },
                                { label: "Location", minWidth: 40 },
                                { label: "Invoice Branch", minWidth: 130 },
                                { label: "Invoice No", minWidth: 100 },
                                { label: "Invoice Date", minWidth: 100 },
                                { label: "Year", minWidth: 40 },
                                { label: "External Color", minWidth: 140 },
                                { label: "Customer Category", minWidth: 150 },
                                { label: "Salesman", minWidth: 40 },
                                { label: "Delivary Date", minWidth: 130 },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingAgentService
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(18)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={60}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : serviceReportList.length > 0
                              ? serviceReportList.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowIndex.id}
                                  >
                                    <StyledTableCell2>
                                      <img
                                        className="icons-size"
                                        src={voice}
                                        alt="webchat"
                                        onClick={() => {
                                          makeCall(
                                            rowData.Mobile,
                                            "sales",
                                            rowData.id,
                                            rowData.Registration_No
                                          );
                                        }}
                                      />
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Customer_Name}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Mobile}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Registration_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Model_Description}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Chassis_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Email}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.Address2}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Brand}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Location}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Inv_Brch}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Inv_Date}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Year}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.External_Color}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Customer_Category}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Salesman}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Delivery_Date}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        count={Math.ceil(pagination2 / 10)}
                        page={page2}
                        onChange={handleChange2}
                      />
                    </Stack>
                  </>
                );
              case "table3":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Total Assign Call" },
                                { label: "Total Pending Call" },
                                { label: "Total Complete Call" },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingAgentService
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(3)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={100}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : serviceReportList.length > 0
                              ? serviceReportList.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowIndex.id}
                                  >
                                    <StyledTableCell2>
                                      {rowData.total}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.totalpendingcall}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.totalcompletecall}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        size="small"
                        count={Math.ceil(pagination3 / 10)}
                        page={page3}
                        onChange={handleChange3}
                      />
                    </Stack>
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </Stack>
      </StyledPaper>
    </>
  );
};

export default connect(mapStateToProps, { setDialedNumberInc })(
  AgentContactNew
);
