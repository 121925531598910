import React, { useEffect, useState } from "react";
import FilterTicket from "./Ticket-history-child-component/Filter-Ticket";
import CreateTicket from "./Ticket-history-child-component/Create-Ticket";
import { connect } from "react-redux";
import { setselectedemail } from "../../../../redux/actions/actions";
import { BaseUrl, ticketUrl } from "../../../../container/BaseUrl/BaseUrl";
import { FiEye } from "react-icons/fi";
import { RiErrorWarningLine } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import axios from "axios";
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    selectedemail: state.data.selectedemail,
    chat: state.data.chat,
  };
};

const Tickethistory = (props) => {
  const [opensidebarticket, setOpensidebarticket] = useState(false);
  const [opensidebarfilter, setOpenSidebarfilter] = useState(false);
  const [ticketlist, setTicketlist] = useState([]);

  const handelopenSidebar = () => {
    setOpensidebarticket(true);
  };
  const closeopenSidebar = () => {
    setOpensidebarticket(false);
  };

  const handelopenSidebarfilter = () => {
    setOpenSidebarfilter(true);
  };
  const closeopenSidebarfilter = () => {
    setOpenSidebarfilter(false);
  };

  const TicketHistoryData2 = async () => {
    let search_by = "";
    let search_name = "";
    if (props.chat.channel == "webchat" || props.chat.channel == "voice") {
      search_by = "mobile";
      search_name = props.chat.unique_id.phonenumber;
    } else {
      search_by = props.chat.channel;
      search_name = props.chat.unique_id.email;
    }
    await axios
      .post(
        BaseUrl + "/users/ticketsearchapi",
        {
          search_by,
          search_name,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status == true) {
          setTicketlist(res.data.data.ticket_list);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (props.chat.id) {
      TicketHistoryData2();
    }
  }, [props.chat.id]);

  const handleOpenURL = (item) => {
    const url = `https://inaipi.ae/v2/Tickets/ticket_view/${item.id}`;
    window.open(url, "_blank");
  };

  const handleOpenURLCreateTicket = () => {
    const randomNumber =
      Math.floor(Math.random() * (10000000 - 9999999 + 1)) + 9999999;
    const url = `https://inaipi.ae/v2/screenpopup?number=${props.chat.phonenumber}&skillset=${props.chat.skillset}&callid=${randomNumber}&survey_opt=1&type=${props.chat.chat_type}&direction=INBOUND`;
    window.open(url, "_blank");
  };

  const TicketCrmScreenpopupApi = async () => {
    let body = {
      phonenumber: props.chat.phonenumber,
      skillset: props.chat.skillset,
      channel: props.chat.channel,
    };
    await axios
      .post(BaseUrl + "/ticket/crmscreenpop", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        window.open(res.data.data);
        toast.success("screen pop url generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("screen pop url not generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="d-flex justify-content-between align-items-center m-2">
          <div className="tickt-name">TICKET HISTORY</div>
          <div className="col-md-4 ps-2 me-4">
            <button
              onClick={() => {
                // handleOpenURLCreateTicket();
                // setOpensidebarticket(true);
                TicketCrmScreenpopupApi();
              }}
              id="crm-ticket-screen"
              type="button"
              className="btn-sm btn-primary apply-btn"
              style={{ marginLeft: "-10px", width: "28vh" }}
            >
              <i
                className="fa-solid fa-eye"
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              ></i>
              View more Tickets
            </button>
          </div>
        </div>

        <div className="time-line-main m-2 p-2">
          <ul className="timeline">
            {ticketlist.length > 0 ? (
              ticketlist.map((item) => {
                return (
                  <motion.li
                    className="timeline-item"
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="timeline-content">
                      <div className="container-fluid m-0 p-0">
                        <div className="row">
                          <div className="d-flex flex-column">
                            <div
                              className="fw-bold d-flex me-1"
                              style={{ fontSize: ".8rem", color: "#0b3363" }}
                            >
                              <p>
                                <strong className="me-1">Agent :</strong>
                                {item.username}
                              </p>
                            </div>
                            <div
                              className="fw-bold d-flex me-1"
                              style={{ fontSize: ".8rem", color: "#0b3363" }}
                            >
                              <p>
                                <strong className="me-1">Ticket ID :</strong>
                                {item.ticket_id}
                              </p>
                            </div>
                            <div
                              className="fw-bold d-flex me-1"
                              style={{ fontSize: ".8rem", color: "#0b3363" }}
                            >
                              <p>
                                <strong className="me-1">Created Time :</strong>
                                {moment(item.created_at).format("ll")}
                              </p>
                            </div>
                            <div
                              className="fw-bold d-flex me-3"
                              style={{ fontSize: ".8rem", color: "#0b3363" }}
                            >
                              <p>
                                <strong className="me-1">Priority :</strong>
                                {item.priority}
                              </p>
                            </div>
                            <div className="d-flex">
                              <strong>Status:</strong>
                              {item.status == "Open" ? (
                                <p
                                  className="mb-0 ms-1 text-success fw-bold"
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.status}
                                  <FiEye
                                    className="ms-1"
                                    onClick={() => handleOpenURL(item)}
                                    size={12}
                                    id="eye-open"
                                  />
                                </p>
                              ) : item.status == "Closed" ? (
                                <p
                                  className="mb-0 ms-1 text-secondary fw-bold"
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.status}
                                  <FiEye
                                    className="ms-1"
                                    onClick={() => handleOpenURL(item)}
                                    size={12}
                                    id="eye-open"
                                  />
                                </p>
                              ) : item.status == "Onhold" ? (
                                <p
                                  className="mb-0 ms-1 text-warning fw-bold"
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.status}
                                  <FiEye
                                    className="ms-1"
                                    onClick={() => handleOpenURL(item)}
                                    size={12}
                                    id="eye-open"
                                  />
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.li>
                );
              })
            ) : (
              <div className="d-flex justify-content-center" >
                <div
                  className="text-muted d-flex"
                  style={{
                    flexDirection: "column",
                    marginTop: "20%",
                    width: "400px",
                    height: "100px",
                  }}
                >
                  <span className="mx-auto">
                    <RiErrorWarningLine size={30} />
                  </span>
                  <span className="mx-auto">
                    <h6
                      className="text-muted mt-2"
                      style={{ fontFamily: "poppins" }}
                    >
                      No Ticket Found
                    </h6>
                  </span>
                </div>
              </div>
            )}
          </ul>
        </div>
      </div>

      <CreateTicket
        opensidebarticket={opensidebarticket}
        closeopenSidebar={closeopenSidebar}
        // iframeUrl={iframeUrl}
      />

      <FilterTicket
        opensidebarfilter={opensidebarfilter}
        closeopenSidebarfilter={closeopenSidebarfilter}
      />
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedemail,
})(Tickethistory);
