import React from "react";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  Stack,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ViewMoreDialogBox = ({ openDialogbox, handleClose, data }) => {
  console.log("data", data);
  return (
    <Dialog
      open={openDialogbox}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 5,
          padding: 1,
          top: -100,
        },
        backgroundColor: "rgb(0 0 0 / 65%)",
      }}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{ fontSize: "16px", fontWeight: 600 }}
      >
        USER INFO
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Box sx={{ width: "400px" }}>
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              <Avatar sx={{ width: 56, height: 56 }}>
                {data
                  ? data.customerdetails.username.slice(0, 2).toUpperCase()
                  : ""}
              </Avatar>
              <Stack spacing={0.5}>
                <Typography variant="subtitle2">
                  Name : {data ? data.customerdetails.username : ""}
                </Typography>
                <Typography variant="subtitle2">
                  Email : {data ? data.customerdetails.email : ""}
                </Typography>
                <Typography variant="subtitle2">
                  Phone : {data ? data.customerdetails.phonenumber : ""}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ViewMoreDialogBox;
