import React, { Component } from "react";
import { Link } from "react-router-dom";
// import shortid from "shortid";
import Button from "react-bootstrap/Button";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  Grid,
  Container,
  Box,
  Tabs,
  Tab,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Autocomplete,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Popover,
  TextField,
  Stack,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Fade,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  ToggleButton,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Email from "../Resources/images/firstemail.png";
import Mail_write from "../Resources/images/new_email.png";
import inbox from "../Resources/images/inbox_second.png";
import drafts from "../Resources/images/draft_new.jpg";
import sentitems from "../Resources/images/dm.png";
import deleteitems from "../Resources/images/trash.png";
import junkemail from "../Resources/images/error.png";
import bold from "../Resources/images/bold1.png";
import italic from "../Resources/images/italic.png";
import underline from "../Resources/images/line.png";
import avatar from "../Resources/images/imageicon1.png";
import highlight from "../Resources/images/highlight.png";
import fontcolour from "../Resources/images/fontcolour.png";
import bullets from "../Resources/images/bullet.png";
import numberBullets from "../Resources/images/number.png";
import leftIndent from "../Resources/images/leftindent.png";
import rightIndent from "../Resources/images/rightindent.png";
import moreOptions from "../Resources/images/moreoptions.png";
import search from "../Resources/images/search.png";
import reply from "../Resources/images/reply2.png";
import replyall from "../Resources/images/replyall.png";
import forward from "../Resources/images/forward.png";
import pdf from "../Resources/images/pdf.png";
import txt from "../Resources/images/txt.png";
import word from "../Resources/images/word.png";
import "../Resources/css/email.scss";
import { CTooltip } from "@coreui/react";
import SidebarComp from "./SidebarComp";

import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  convertFromRaw,
  ContentState,
  AtomicBlockUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { ChromePicker } from "react-color";
import draftToHtml from "draftjs-to-html";

export default class NewEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameObj: { first: "Belvin" },
      name: "Bell ",
      emails: [],
      from: { mailId: "Acct.user@gmail.com" },
      to: [],
      toInputValue: "",
      cc: [],
      ccInputValue: "",
      bcc: [],
      bccInputValue: "",
      emailSubject: "",
      toError: false,
      ccError: false,
      bccError: false,
      inputText: "",
      formattedText: "",
      color: "#000000",
      iscolor: false,
      editorState: EditorState.createEmpty(),
      selectedtempFile: [],
      selectedFile: [],
    };
    this.inputRef = React.createRef();
    // this.editorState=EditorState.createEmpty();
  }

  handleChangeColor = (newColor) => {
    this.setState({ color: newColor.hex });
    
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "BACKGROUND-COLOR",
        newColor.hex
      ),
    });
  };

  handleTextColorChange = (color) => {
    this.setState({ color: color.hex });
    const selection = this.state.editorState.getSelection();
    const contentState = this.state.editorState.getCurrentContent();

    // Apply the text color to the selected text
    const contentStateWithColor = Modifier.applyInlineStyle(
      contentState,
      selection,
      "red"
    );
    
    // Update the editor state with the new content
    const newEditorState = EditorState.push(
      this.state.editorState,
      contentStateWithColor,
      "change-inline-style"
    );

    this.setState({ editorState: newEditorState });
  };

  handleFontSizeChange = (fontSize) => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const nextContentState = RichUtils.toggleInlineStyle(
      editorState.getCurrentContent(),
      `FONT-SIZE-${fontSize}`
    );
    const nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );
    this.setState({
      editorState: RichUtils.toggleInlineStyle(nextEditorState, fontSize),
    });
  };

  handleEditorChange = (newEditorState) => {
    this.setState({ editorState: newEditorState });
  };

  handleBoldClick = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"),
    });
   
    
  };

  handleItalicClick = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "ITALIC"
      ),
    });
  };

  handlePasetImage = (files) => {
    
    const file = files[0];
    const reader = new FileReader();
    const { editorState } = this.state;
    reader.onload = (e) => {
      const contentState = editorState.getCurrentContent();

      const contentStateWithEntity = contentState.createEntity(
        "IMAGE",
        "IMMUTABLE",
        { src: e.target.result }
      );

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = AtomicBlockUtils.insertAtomicBlock(
        contentStateWithEntity,
        entityKey,
        " "
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );
      this.handleEditorChange(newEditorState);
    };
    reader.readAsDataURL(file);
  };
  insertImage = (url) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, "");
  };

  handleButtonClickUnderline = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "UNDERLINE"
      ),
    });
  };
  onToChange = (e, value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ toInputValue: errorEmail, toError: true });
    } else {
      this.setState({ toError: false });
    }

    this.setState({ to: value.filter((email) => regex.test(email)) });
  };
  onCcChange = (e, value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ ccInputValue: errorEmail, ccError: true });
    } else {
      this.setState({ ccError: false });
    }
    this.setState({ cc: value.filter((email) => regex.test(email)) });
  };
  onBccChange = (e, value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ bccInputValue: errorEmail, bccError: true });
    } else {
      this.setState({ bccError: false });
    }
    this.setState({ bcc: value.filter((email) => regex.test(email)) });
  };
  onToInputChange = (e, newValue) => {
    this.setState({ toInputValue: newValue });
  };
  onCcInputChange = (e, newValue) => {
    this.setState({ ccInputValue: newValue });
  };
  onBccInputChange = (e, newValue) => {
    this.setState({ bccInputValue: newValue });
  };
  handleEmailSubject = (e) => {
    this.setState({ emailSubject: e.target.value });
  };
  openActionView = (e, value) => {
    this.setState({
      actionView: true,
      anchorEl: e.currentTarget,
      valueIndex: value,
    });
  };
  closeActionView = () => {
    this.setState({ actionView: false, anchorEl: null, valueIndex: {} });
  };

  filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  FileInputChange = (e) => {
    let images = [];
    let tempDoc = this.state.selectedtempFile;
    _.map(e.target.files, (val, i) => {
      images.push(val);
      let reader = new FileReader();
      let file = val;
      reader.onloadend = () => {
        let tempValue = {
          // id: shortid.generate(),
          filename: val.name,
          filetype: val.type,
          fileimage: reader.result,
          datetime: val.lastModifiedDate.toLocaleString("en-IN"),
          filesize: this.filesizes(val.size),
        };
        tempDoc.push(tempValue);
        this.setState({ selectedtempFile: tempDoc });
      };
      if (val) {
        reader.readAsDataURL(file);
      }
    });
    this.setState({ selectedFile: e.target.files });
    // this.setState({ selectedtempFile : tempDoc });
  };
  DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = this.state.selectedtempFile.filter(
        (data) => data.id !== id
      );
      this.setState({ selectedtempFile: result });
    } else {
      // alert('No');
    }
  };
  handleSendEmail = () => {
    const { selectedFile, editorState, to, cc, bcc, emailSubject } = this.state;
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    if (html) {
      let formdata = new FormData();
      formdata.append("files", selectedFile);
      formdata.append("email", to.toString());
      formdata.append("subject", emailSubject);
      formdata.append("ccData", cc.toString());
      formdata.append("bccData", bcc.toString());
      formdata.append("html", html);

      // let obj ={
      //     'files' : selectedFile,
      //     'email' : to.toString(),
      //     'subject' : emailSubject,
      //     'ccData' : cc.toString(),
      //     'bccData' :  bcc.toString(),
      //     'html' : html,
      // }
      this.props.SendEmail(formdata);
    }
  };

  EmailAttachmentContent(data) {
    const imageFormat = [
      "png",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "svg",
      "gif",
    ];
    if (!_.isEmpty(data)) {
      return (
        <>
          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              if (val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <Grid item xs={3}>
                    <div className="attachment_container">
                      <img
                        src={val.fileimage}
                        alt="Avatar"
                        className="image_attachment"
                      />
                      <div className="attachment_overlay">
                        <div className="attachment_text">
                          {val.filename}
                          <span className="float-r">
                            {" "}
                            <IconButton
                              className="text-white"
                              id="mail"
                              onClick={(e) => this.openActionView(e, val)}
                            >
                              {" "}
                              <ExpandMoreIcon />{" "}
                            </IconButton>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              }
            })}
          </Grid>

          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              if (!val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <Grid item xs={4}>
                    <Paper>
                      <MenuItem>
                        <ListItemIcon className="attachment_Preview_image">
                          <img
                            src={
                              val.filename.match(/.(pdf)$/i)
                                ? pdf
                                : val.filename.match(/.(docx)$/i)
                                ? word
                                : val.filename.match(/.(xlsx)$/i)
                                ? word
                                : txt
                            }
                            height="20px"
                            weight="20px"
                          />
                        </ListItemIcon>
                        <ListItemText className="attachment_el_text">
                          {val.filename}
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                          <IconButton
                          id="mail"
                            onClick={(e) => this.openActionView(e, val)}
                          >
                            {" "}
                            <ExpandMoreIcon />{" "}
                          </IconButton>
                        </Typography>
                      </MenuItem>
                    </Paper>
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      );
    }
  }

  render() {
    const {
      from,
      to,
      toInputValue,
      toError,
      cc,
      ccInputValue,
      ccError,
      bcc,
      bccInputValue,
      bccError,
      selectedFile,
      selectedtempFile,
      actionView,
      anchorEl,
      emailSubject,
    } = this.state;
    const mailAddress = [
      {
        title: "rangitha.s@cognicx.com",
        mailid: "rangitha.s@cognicx.com",
      },
      { title: "belvin.d@cognicx.com" },
      { title: "vinoth.s@cognicx.com" },
      { title: "test@gmail.com" },
      { title: "nandhini.e@cognicx.com" },
      { title: "indumathi.p@cognicx.com" },
      { title: "hinduja.d@cognicx.com" },
    ];

    
    

    return (
      <>
        <div>
          {/* <div className="email-app card-margin">
                        <div className="email-toolbars-wrapper sidenav" id="mySidenav" style={{ width: '7rem' }}>
                            <div className="toolbar-header">

                                <input type="text" placeholder="Search.." name="search2" className="form-control rounded-pill" />

                            </div>
                            <div className="toolbar-body ml-2">

                                <ul className="toolbar-menu">


                                    <li className="active">

                                        <Link to='/mail'>
                                            <CTooltip content="Mail" placement="right">
                                                <img src={Email} />
                                            </CTooltip>
                                        </Link>


                                    </li>
                                    <li>



                                        <Link to='/newemail'>
                                            <CTooltip content="New Mail" placement="right">
                                                <img src={Mail_write} />
                                            </CTooltip>
                                        </Link>



                                    </li>
                                    <li>

                                        <Link to='/inbox'>
                                            <CTooltip content="Inbox" placement="right">

                                                <img src={inbox} />
                                            </CTooltip>

                                        </Link>
                                        <span className='my-1'>4</span>



                                    </li>
                                    <li>

                                        <Link to='/drafts'>
                                            <CTooltip content="Drafts" placement="right">

                                                <img src={drafts} />
                                            </CTooltip>

                                        </Link>



                                    </li>
                                    <li>

                                        <Link to='/sentitems'>
                                            <CTooltip content="Sent Items" placement="right">
                                                <img src={sentitems} />
                                            </CTooltip>


                                        </Link>



                                    </li>
                                    <li>

                                        <Link to='/deleteitems'>
                                            <CTooltip content="Delete Items" placement="right">

                                                <img src={deleteitems} />
                                            </CTooltip>

                                        </Link>



                                    </li>
                                    <li>

                                        <Link to='/junkemail'>
                                            <CTooltip content="Junk Emails" placement="right">

                                                <img src={junkemail} />
                                            </CTooltip>

                                        </Link>
                                    </li>


                                </ul>

                            </div>
                        </div>
                    </div> */}

          <div className="row mx-5">
            <div className="col-md-1">
              <SidebarComp />
            </div>
            <div className="col-md-11">
              <div className="side-margin">
                <div className="rowtwo" id="rowtwo">
                  <div
                    className="email-list-wrapper"
                    style={{ width: "20rem" }}
                  >
                    <div
                      id="email-app-body"
                      className="email-list-scroll-container ps ps--active-y"
                    >
                      <div className="focused">
                        <div className="circle">
                          <div className="checkmark"></div>
                        </div>

                        <div>
                          <h6>Focused &nbsp; &nbsp;</h6>
                        </div>
                        <div>
                          <h6>Other &nbsp; &nbsp;</h6>
                        </div>
                        <div>
                          <h6>Filter &nbsp; &nbsp;</h6>
                        </div>
                      </div>

                      <div className="email-list-cont">
                        <ul
                          className="email-list"
                          style={{ marginBottom: "5rem" }}
                        >
                          <li className="email-list-item">
                            {/* <img src={avatar} alt="Profile Picture" /> */}
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  Pepper
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                          <li className="email-list-item active">
                            {/* <img src={avatar} alt="Profile Picture" /> */}
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  Poul Smith
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                          <li className="email-list-item">
                            <img src={avatar} alt="Profile Picture" />
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  Edwin Jarvis
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                          <li className="email-list-item">
                            <img src={avatar} alt="Profile Picture" />
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  Jim Ward
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                          <li className="email-list-item">
                            <img src={avatar} alt="Profile Picture" />
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  D Jango
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                          <li className="email-list-item">
                            <img src={avatar} alt="Profile Picture" />
                            <div className="email-content">
                              <div className="recipient">
                                <a href="#" className="recipient-name">
                                  Tom Harward
                                </a>
                              </div>
                              <a href="#" className="email-subject">
                                Hi all the document that are missing is from the
                                same &nbsp;
                              </a>
                            </div>
                            <div className="email-footer">
                              <div className="email-action">
                                <a href="#" className="important"></a>
                                <a href="#" className="starred"></a>
                                <a href="#" className="attachment"></a>
                              </div>
                              <span className="email-time">11:50 AM</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="email-desc-wrapper ">
                    <div className="mailcontent" style={{ width: "95%" }}>
                      <>
                        <InputGroup size="sm" className="mb-3">
                          <InputGroup.Text id="inputGroup-sizing-sm">
                            To
                          </InputGroup.Text>

                          <Stack spacing={{}} sx={{ width: 740 }}>
                            <Autocomplete
                              multiple
                              size="small"
                              onChange={this.onToChange}
                              value={to}
                              inputValue={toInputValue}
                              onInputChange={this.onToInputChange}
                              options={mailAddress.map(
                                (option) => option.title
                              )}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="toAddr"
                                  variant="standard"
                                  type="email"
                                  error={toError}
                                />
                              )}
                            />
                          </Stack>
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                          <InputGroup.Text id="inputGroup-sizing-sm">
                            Cc
                          </InputGroup.Text>

                          <Stack spacing={{}} sx={{ width: 740 }}>
                            <Autocomplete
                              multiple
                              size="small"
                              onChange={this.onCcChange}
                              value={cc}
                              inputValue={ccInputValue}
                              onInputChange={this.onCcInputChange}
                              options={mailAddress.map(
                                (option) => option.title
                              )}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="ccAddr"
                                  variant="standard"
                                  type="email"
                                  // error={ccError}
                                />
                              )}
                            />
                          </Stack>
                        </InputGroup>
                        <InputGroup size="sm" className="mb-3">
                          <InputGroup.Text id="inputGroup-sizing-sm">
                            Bcc
                          </InputGroup.Text>

                          <Stack spacing={{}} sx={{ width: 740 }}>
                            <Autocomplete
                              multiple
                              size="small"
                              onChange={this.onBccChange}
                              value={bcc}
                              inputValue={bccInputValue}
                              onInputChange={this.onBccInputChange}
                              options={mailAddress.map(
                                (option) => option.title
                              )}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="bccAddr"
                                  variant="standard"
                                  type="email"
                                  error={bccError}
                                />
                              )}
                            />
                          </Stack>
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Add a Subject"
                            aria-label="Add a Subject"
                            aria-describedby="basic-addon2"
                            value={emailSubject}
                            onChange={this.handleEmailSubject}
                          />
                        </InputGroup>
                        <br />
                      </>

                      <div className="mb-3" style={{ height: "300px" }}>
                        {/* <div dangerouslySetInnerHTML={{ __html: this.state.inputText }} /> */}
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1"> */}
                        {/* <Form.Control as="textarea" rows={10}

                                                        type="text"
                                                        value={this.state.inputText}
                                                        onChange={this.handleInputChange}
                                                        ref={this.inputRef}
                                                        onMouseUp={this.handleMouseUp}

                                                    /> */}
                        {!_.isEmpty(selectedtempFile)
                          ? this.EmailAttachmentContent(selectedtempFile)
                          : null}

                        {/* </Form.Group> */}
                        <Editor
                          editorState={this.state.editorState}
                          onChange={this.handleEditorChange}
                          customStyleMap={customStyleMap}
                          handlePastedFiles={this.handlePasetImage}
                        />
                      </div>

                      <div className="email-action-newEmail">
                        <SplitButton
                        
                          onClick={this.handleSendEmail}
                          variant="primary"
                          title="Send"
                          id="segmented-button-dropdown-1"
                        >
                          <Dropdown.Item href="#">Send</Dropdown.Item>
                          <Dropdown.Item href="#">Schedule Send</Dropdown.Item>
                        </SplitButton>
                        &nbsp;
                        <button variant="light" className="btn btn-warning">
                          Discard
                        </button>
                        &nbsp;
                        <div className="palette-option">
                          <label className="attachment">
                            <input
                              type="file"
                              id="fileupload"
                              className="file-upload-input"
                              onChange={this.FileInputChange}
                              multiple
                            />
                            <AttachFileIcon />
                          </label>
                        </div>
                        &nbsp;
                        <div className="palette-option">
                          <label className="attachment">
                            <input type="file" />
                            <InsertPhotoIcon />
                          </label>
                        </div>
                        <div className="palette-option1">
                          <select className="font-list">
                            <option>Arial</option>
                            <option>Calibri</option>
                            <option>Times New Roman</option>
                          </select>
                        </div>
                        <div className="palette-option1">
                          <select className="font-list">
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                          </select>
                        </div>
                        <button id="primary-btn" onClick={() => this.handleFontSizeChange("30")}>
                          Small
                        </button>
                        <div className="palette-option1">
                          <ToggleButton
                            value="bold"
                            aria-label="bold"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatBoldIcon id="primary-btn" onClick={this.handleBoldClick} />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="italic"
                            aria-label="italic"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatItalicIcon
                            id="outside-click"
                              onClick={this.handleItalicClick}
                            />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="underlined"
                            aria-label="underlined"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatUnderlinedIcon
                            id="btn-click"
                              onClick={this.handleButtonClickUnderline}
                            />
                          </ToggleButton>
                        </div>
                        {this.state.iscolor ? (
                          <ChromePicker
                            color={this.state.color}
                            onChange={this.handleTextColorChange}
                          />
                        ) : (
                          ""
                        )}
                        <div className="palette-option1">
                          <ToggleButton
                            value="highlight"
                            aria-label="highlight"
                            size="small"
                            id="fnt-style"
                          >
                            <BorderColorIcon
                            id="btn-click"
                              onClick={() => {
                                this.setState({ iscolor: !this.state.iscolor });
                              }}
                            />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="colortext"
                            aria-label="colortext"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatColorTextIcon
                            id="btn-click"
                              onClick={() => {
                                this.setState({ iscolor: !this.state.iscolor });
                              }}
                            />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="listbulleted"
                            aria-label="listbulleted"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatListBulletedIcon />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="listnumbered"
                            aria-label="listnumbered"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatListNumberedIcon />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="indentincrease"
                            aria-label="indentincrease"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatIndentIncreaseIcon />
                          </ToggleButton>
                        </div>
                        <div className="palette-option1">
                          <ToggleButton
                            value="indentdecrease"
                            aria-label="indentdecrease"
                            size="small"
                            id="fnt-style"
                          >
                            <FormatIndentDecreaseIcon />
                          </ToggleButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popover
          id="simple-popover"
          open={actionView}
          anchorEl={anchorEl}
          onClose={this.closeActionView}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper sx={{ width: 200, maxWidth: "100%" }}>
            <MenuList>
              <MenuItem id="open-doc" onClick={this.openDocument}>
                <ListItemIcon>
                  <VisibilityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Preview</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem id="open-doc" onClick={this.downloadDoc}>
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download</ListItemText>
              </MenuItem>
              <Divider />
              {/* <MenuItem>
                        <ListItemIcon>
                            <ClearIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Remove Attachment</ListItemText>
                        </MenuItem> */}
            </MenuList>
          </Paper>
        </Popover>
      </>
    );
  }
}
const customStyleMap = {
  FONT_SIZE_40: {
    fontSize: "40px",
  },
};
