import React, { useState, useEffect } from "react";
import "@avaya/neo-react/avaya-neo-react.css";
import Sdk from "../DialerComponent/Sdk";
import "../../assets/assets/dist/css/neo/neo.css";
import "./navbar.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setcontactlist,
  setcontactlist1,
  closeNotify,
  clearnotifyAll,
  setShowNotification,
  setShowUserProfile,
  setshowphonedialer,
  setVoiceRefresh,
  setsidebarVal,
  setShowingStatusClass,
  setShowingStatus,
} from "../../redux/actions/actions";
import {
  BaseUrl,
  frontendBaseurl,
  errorApi,
} from "../../container/BaseUrl/BaseUrl";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WifiHigh, WifiX } from "@phosphor-icons/react";
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import InternetError from "../InternetError-component/useOnlineStatus ";

const mapStateToProps = (state) => {
  return {
    contactList: state.contactListData.contactList,
    notification: state.notification.notification,
    showNotification: state.data.showNotification,
    showUserProfile: state.data.showUserProfile,
    showphonedialer: state.data.showphonedialer,
    VoiceRefresh: state.data.VoiceRefresh,
    showingStatus: state.data.showingStatus,
    showingStatusClass: state.data.showingStatusClass,
  };
};

const Navbar = (props) => {
  const { showingStatus, showingStatusClass, referance } = props;
  const navigate = useNavigate();
  const AvcUrl = localStorage.getItem("AvcUrl");
  const [showReadyButton, setReadyButton] = useState(true);
  const [showFinishButton, setFinishButton] = useState(false);
  const [showConnectedStatus, setConnectedStatus] = useState(true);
  const [showReadyStatus, setReadyStatus] = useState(false);
  const [hideLogout, setHideLogout] = useState(true);
  const [rmState, setRmState] = useState(true);
  const [showStatusChannel, setShowStatusChannel] = useState("");
  const [voiceEnable, setvoiceEnable] = useState(false);
  const [userstatus, setUserstatus] = useState([]);
  const [channel, setchannel] = useState(
    JSON.parse(localStorage.getItem("tokenAgent"))
  );
  const filteredChannels = channel.channel.filter(
    (item) => !item.parentClientChannelId
  );

  const { isOnline, showNotification } = InternetError();

  useEffect(() => {
    if (localStorage.getItem("statusValue") == "Ready") {
      goReady("Ready");
      updateStatus("Ready");
      setShowStatusChannel("Ready");
      localStorage.setItem("statusValue", "Ready");
      userStatus();
    }
    filteredChannels.map((item) => {
      if (item.channelName == "Voice") {
        setvoiceEnable(true);
      }
    });
  }, [localStorage.getItem("statusValue")]);

  useEffect(() => {
    if (localStorage.getItem("VoiceActivate")) {
      if (
        props.externalChatData
          ? props.externalChatData.direction == "Outbound" &&
            localStorage.getItem("statusValue2") == "Outbound"
          : ""
      ) {
        changeStatusnew("Not Ready");
        updateStatus("Outbound");
        props.setShowingStatus("Outbound");
        props.setShowingStatusClass("showStatusRed");
      } else {
        updateStatus("Ready");
        changeStatusnew("Ready");
        props.setShowingStatus("Ready");
        props.setShowingStatusClass("showStatus");
      }
    }
  }, [localStorage.getItem("VoiceActivate")]);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let timeout;
    const resetTimeout = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setIsActive(false);
      }, 15 * 60 * 1000);
    };

    const handleUserActivity = () => {
      setIsActive(true);
      resetTimeout();
    };

    const handleBeforeUnload = (event) => {
      console.log("Browser is refreshing", event);
      event.returnValue = "";
    };

    const handleUnload = (event) => {
      console.log("Browser is closed", event);
      forceLogout();
    };

    resetTimeout();
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    if (!isActive) {
      forceLogout();
    }
  }, [isActive]);

  const show_notification = () => {
    props.setShowNotification(!props.showNotification);
    props.setShowUserProfile(false);
    props.setshowphonedialer(false);
  };

  const close_notification = () => {
    props.setShowNotification(false);
  };

  const show_userprofile = () => {
    props.setShowUserProfile(!props.showUserProfile);
    props.setShowNotification(false);
  };

  const close_userprofile = () => {
    props.setShowUserProfile(false);
  };

  const errorHandel = async (data) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      errorhandelApi();
      if (data.status) {
      }
    } catch (error) {}
  };

  //complete
  const updateStatus = async (value) => {
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      if (data) {
        await axios
          .post(
            BaseUrl + "/users/updateAgentStatus/",
            {
              status: value,
              userId: data.user_id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
                "Content-Type": "application/json",
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          )
          .then((res) => {});
      }
    } catch (error) {
      errorHandel(error, "/users/updateAgentStatus/");
    }
  };

  const updateStatusWithReasonCode = async (value) => {
    if (!value) return;
    await axios
      .post(
        AvcUrl +
          `/voice/cct/changeAgentStatusReasonCode?agentId=${localStorage.getItem(
            "agentLoginTerminalId"
          )}&status=Not Ready&reason=${
            value.statusCode == null ? "" : value.statusCode
          }`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goReady = async (val) => {
    if (val === "Ready") {
      navigate("/main/Chat");
      props.setsidebarVal("Chat");
    }
    setFinishButton(true);
    setReadyButton(false);
    setReadyStatus(true);
    setConnectedStatus(false);
    setHideLogout(false);
    props.setShowingStatusClass("showStatus");
    props.setShowingStatus(val);
  };

  const goFinish = () => {
    setShowStatusChannel("Logged Out");
    updateStatus("Connected");
    localStorage.removeItem("readystatus");
    setHideLogout(true);
    setTimeout(() => {
      setFinishButton(false);
      setReadyButton(true);
      setReadyStatus(false);
      setConnectedStatus(true);
    }, 1000);
  };

  let name = localStorage.getItem("NameDisplay");
  let email = localStorage.getItem("emailDisplay");
  let firstTwoletter;
  if (name) {
    firstTwoletter = name.substring(0, 2).toLocaleUpperCase();
  }

  const logout = async () => {
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    let userID = data.user_id;
    if (data) {
      await axios
        .post(
          BaseUrl + "/users/logoutnew/" + userID,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            localStorage.removeItem("AvayaUsername");
            localStorage.removeItem("tab");
            localStorage.removeItem("timer_connect_sec");
            localStorage.removeItem("AvayaPassword");
            localStorage.removeItem("AvayaDomain");
            localStorage.removeItem("client");
            localStorage.removeItem("statusValue");
            localStorage.removeItem("emailDisplay");
            localStorage.removeItem("timer_connect_min");
            localStorage.removeItem("tokenAgent");
            localStorage.removeItem("timer_status");
            localStorage.removeItem("NameDisplay");
            localStorage.removeItem("access_token");
            localStorage.removeItem("timer_connect_hour");
            localStorage.removeItem("EnableWEBRTC");
            localStorage.removeItem("ticketcrm");
            if (!localStorage.getItem("TenantId")) {
              window.location.href = "error.html";
            } else {
              const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
                localStorage.getItem("TenantId")
              )}`;
              window.location.href = loginUrl;
            }

            //navigate("/");
          } else {
            toast.warn("Please Close all Active Sessions", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {});
    }
  };

  //completed

  const loginbyDomain = async () => {
    if (voiceEnable) {
      await axios
        .post(
          AvcUrl +
            `/voice/cct/login?username=${localStorage.getItem(
              "AvayaUsername"
            )}&password=${localStorage.getItem(
              "AvayaPassword"
            )}&domain=${localStorage.getItem("AvayaDomain")}`,
          {},
          {
            headers: {
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            localStorage.setItem("ssoToken", res.data.data.ssoToken);
            localStorage.setItem(
              "agentLoginTerminalId",
              res.data.data.agents[0].agentLoginId
            );
            localStorage.setItem(
              "agentUsername",
              res.data.data.agents[0].agentUsername
            );

            // DCC Agent Status Update API
            let data = JSON.parse(localStorage.getItem("tokenAgent"));
            await axios
              .post(
                BaseUrl + "/users/updateAgentStatus/",
                {
                  status: "Ready",
                  userId: data.user_id,
                  agentLoginTerminalId: localStorage.getItem(
                    "agentLoginTerminalId"
                  )
                    ? localStorage.getItem("agentLoginTerminalId")
                    : "",
                  agentUsername: localStorage.getItem("agentUsername")
                    ? localStorage.getItem("agentUsername")
                    : "",
                },
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantId: localStorage.getItem("TenantId"),
                  },
                }
              )
              .then((res) => {})
              .catch((err) => {});
            // DCC Agent Status Update API

            if (res.data.data.agents[0].loggedIn == true) {
            } else {
              loginbyDomainforAgent();
            }
          }
        })
        .catch((res) => {
          // if ((res.data.status = "INTERNAL_SERVER_ERROR")) {
          toast.error("Avaya CCT Login Error", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          // }
        });
    }
  };
  const loginbyDomainforAgent = async () => {
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${localStorage.getItem(
            "agentLoginTerminalId"
          )}/login/${localStorage.getItem(
            "agentLoginTerminalId"
          )}?status=Ready`,
        {},

        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if ((res.data.status = "OK")) {
          window.location.reload(true);
        }
      })
      .catch((error) => {
        errorHandel(error, "agent/login/status");
        toast.error("Avaya Status Change Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const logoutByDomainFirst = async () => {
    if (voiceEnable) {
      await axios
        .post(
          AvcUrl +
            `/voice/cct/agent/${localStorage.getItem(
              "agentLoginTerminalId"
            )}/logout/${localStorage.getItem("agentLoginTerminalId")}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (res.data.statusCode == 500) {
              toast.warn(res.data.status, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
            } else {
              logoutByDomainSecond();
            }
          }
        })
        .catch((error) => {
          errorHandel(error, "/cct/agent/logout");
          toast.error("Avaya Logout Error", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };
  const logoutByDomainSecond = async () => {
    const agentUsername = localStorage.getItem("agentUsername");
    await axios
      .post(
        AvcUrl + "/voice/cct/logout?username=" + agentUsername,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if ((res.data.status = "OK")) {
        }
      })
      .catch((err) => {
        toast.error("Avaya Logout Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const changeStatusnew = async (status) => {
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${localStorage.getItem(
            "agentLoginTerminalId"
          )}/status?status=${status}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken")
              ? localStorage.getItem("ssoToken")
              : "",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status == "OK") {
          if (status == "Ready") {
            props.setVoiceRefresh(false);
            localStorage.removeItem("VoiceActivate");
          }
        }
      })
      .catch((error) => {
        errorHandel(error, "agent/status");
        toast.error("Avaya Status Change Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const showBreaks = () => {
    setRmState(false);
  };

  const showOldScreen = () => {
    setRmState(true);
  };

  const mealUpdate = (item) => {
    goReady(item);
    props.setShowingStatusClass("showStatusRed");
    updateStatus(item);
    setRmState(true);
  };

  const userStatus = async () => {
    try {
      await axios
        .post(
          BaseUrl + "/userstatus/userstatuslist",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then(function (response) {
          setUserstatus(response.data.data);
        });
    } catch (error) {
      errorHandel(error, "/userstatus/userstatuslist");
    }
  };

  const toggle = () => {
    props.setshowphonedialer(!props.showphonedialer);
    props.setShowNotification(false);
    props.setShowUserProfile(false);
  };

  const forceLogout = () => {
    const agent = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      axios
        .post(
          BaseUrl + `/users/logout/${agent.user_id}`,
          {
            type: "forcelogout",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.success == true) {
            localStorage.removeItem("AvayaUsername");
            localStorage.removeItem("tab");
            localStorage.removeItem("timer_connect_sec");
            localStorage.removeItem("AvayaPassword");
            localStorage.removeItem("AvayaDomain");
            localStorage.removeItem("client");
            localStorage.removeItem("statusValue");
            localStorage.removeItem("emailDisplay");
            localStorage.removeItem("timer_connect_min");
            localStorage.removeItem("tokenAgent");
            localStorage.removeItem("timer_status");
            localStorage.removeItem("NameDisplay");
            localStorage.removeItem("access_token");
            localStorage.removeItem("timer_connect_hour");
            localStorage.removeItem("EnableWEBRTC");
            localStorage.removeItem("ticketcrm");
            localStorage.removeItem("agentLoginTerminalId");
            if (!localStorage.getItem("TenantId")) {
              window.location.href = "error.html";
            } else {
              const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
                localStorage.getItem("TenantId")
              )}`;
              window.location.href = loginUrl;
            }
            navigate("/");
          } else {
            toast.warn("Please Close all Active Sessions", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log("LogoutAgent", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="header neo-navbar--sticky">
        <nav
          className="neo-navbar animate__animated animate__fadeInDown"
          style={{
            boxShadow: "gray -2px -8px 16px,",
            minHeight: "49px",
            height: "49px",
          }}
        >
          <div className="neo-nav">
            <div className="neo-badge__navbutton">
              {voiceEnable ? (
                <span
                  className="material-symbols-outlined"
                  onClick={() => {
                    toggle();
                  }}
                  style={{
                    display: "block",
                    cursor: "pointer",
                    fontSize: "21px",
                  }}
                >
                  dialpad
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="neo-badge__navbutton ">
              <button
                className="neo-badge__navbutton--content neo-btn neo-icon-notifications-on"
                onClick={show_notification}
              ></button>
              <span
                className="neo-badge__icon"
                data-badge={props.notification ? props.notification.length : 0}
              ></span>
            </div>

            {showReadyStatus && (
              <div
                className="neo-nav-status neo-nav-status--ready"
                tabIndex="0"
                onClick={show_userprofile}
              >
                <div className="neo-nav-status-info">
                  <p className="mx-1 font-weight-bold1">
                    {localStorage.getItem("NameDisplay")}
                  </p>

                  <span
                    className={`neo-label timer-ready ${showingStatusClass}`}
                    style={{ fontSize: "9px", padding: "0px 13px" }}
                  >
                    {showingStatus}
                  </span>
                </div>
                <figure
                  className="neo-avatar"
                  data-initials={firstTwoletter}
                ></figure>
              </div>
            )}

            {showConnectedStatus && (
              <div
                className={`neo-nav-status ${
                  localStorage.getItem("statusValue") == "Connected" ||
                  localStorage.getItem("statusValue") == "" ||
                  localStorage.getItem("statusValue") == null ||
                  localStorage.getItem("statusValue") == undefined
                    ? `neo-nav-status--connected`
                    : `neo-nav-status--ready`
                } `}
                tabIndex="0"
                onClick={show_userprofile}
              >
                <div className="neo-nav-status-info ">
                  <p className="mx-1 font-weight-bold1">
                    {localStorage.getItem("NameDisplay")}
                  </p>
                  {localStorage.getItem("statusValue") == "Connected" ||
                  localStorage.getItem("statusValue") == null ||
                  localStorage.getItem("statusValue") == undefined ||
                  localStorage.getItem("statusValue") == "" ? (
                    <>
                      <span className="neo-label neo-label--connected timer-connected">
                        Connected
                      </span>
                    </>
                  ) : (
                    <span
                      className={`${localStorage.getItem(
                        "statusValue" == "Ready"
                      )}`}
                    >
                      <span
                        className={`${
                          localStorage.getItem("statusValue") == "Ready" ||
                          localStorage.getItem("statusValue") == "Connected"
                            ? "neo-label neo-label--ready"
                            : "neo-label neo-label--not-ready"
                        }`}
                      >
                        {localStorage.getItem("statusValue")}
                      </span>
                    </span>
                  )}
                </div>
                <figure
                  className="neo-avatar"
                  data-initials={firstTwoletter}
                ></figure>
              </div>
            )}
          </div>
        </nav>
        {!isOnline && (
          <div className="offline-message">
            <span>
              <WifiX size={18} />
            </span>
            <span className="ms-2">
              You're offline, Please check the connection error !
            </span>
          </div>
        )}
        {isOnline && showNotification && (
          <div className="online-message">
            <span>
              <WifiHigh size={18} />
            </span>
            <span className="ms-2">You're Online! Connection is back.</span>
          </div>
        )}
      </div>

      {props.showNotification && (
        <div className="main-notify-cont" style={{ zIndex: 10 }}>
          <div className="content ">
            <span className="head">Notifications</span>
            <span
              className="neo-icon-close"
              onClick={close_notification}
              aria-label="close notification"
            ></span>
            {!props.notification && (
              <div className="notify-body">
                <span
                  className="neo-icon-info"
                  aria-label="info notification"
                ></span>
                <span>No notifications to display</span>
              </div>
            )}

            <div className="notify-content-data mt-2">
              {props.notification &&
                props.notification
                  .slice(0)
                  .reverse()
                  .map((data, index) => {
                    return (
                      <div key={index} className="notifications-div">
                        <span
                          className="notify-close"
                          onClick={() => {
                            props.closeNotify(data.id, props.notification);
                          }}
                        >
                          X
                        </span>
                        <div className="noti-username">
                          {data.username} {`(${data.chatType})`}
                        </div>
                        <div className="noti-msg">
                          <span
                            className="notify-message"
                            title={data.client_msg}
                          >
                            {data.client_msg}
                          </span>
                          <span className="notify-duration">
                            {data.msg_time}
                          </span>
                        </div>
                        <div
                          className=""
                          style={{ position: "fixed", bottom: 5, right: 35 }}
                        >
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => {
                              props.clearnotifyAll(data.id, props.notification);
                              setTimeout(() => {
                                close_notification();
                              }, 1000);
                            }}
                          >
                            Clear all
                          </button>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}

      {props.showUserProfile && (
        <div className="main-notify-cont" style={{ zIndex: 10 }}>
          {/* <div className="content"> */}
          {rmState && (
            <div className="content">
              <span className="head">Agent Status</span>
              <span className="head_agent-details">Agent Id - {email}</span>
              <span
                className="neo-icon-close user-close"
                onClick={close_userprofile}
                aria-label="close notification"
              ></span>

              <div className="user-body">
                {showReadyButton && (
                  <button
                    id="go_ready"
                    className="btn btn-success work-start"
                    style={{ fontFamily: "Noto-Sans" }}
                    onClick={() => {
                      setShowStatusChannel("Ready");
                      localStorage.setItem("statusValue", "Ready");
                      updateStatus("Ready");
                      goReady("Ready");
                      setTimeout(() => {
                        loginbyDomain();
                      }, 1000);
                    }}
                  >
                    {" "}
                    <span
                      className="neo-icon-work-start"
                      aria-label="start work"
                    ></span>{" "}
                    Go Ready
                  </button>
                )}

                {showFinishButton && (
                  <button
                    id="finish_work"
                    className="btn btn-primary"
                    style={{ fontFamily: "Noto-Sans" }}
                    onClick={() => {
                      logoutByDomainFirst();
                      goFinish();
                      setShowStatusChannel("Connected");
                      localStorage.setItem("statusValue", "Connected");
                    }}
                  >
                    {" "}
                    <span
                      className="neo-icon-work-end"
                      aria-label="end work"
                    ></span>{" "}
                    Finish Work
                  </button>
                )}

                {showFinishButton && (
                  <div className="status">
                    <div className="status-head">Status</div>
                    <div className="go-not-ready" onClick={showBreaks}>
                      <span
                        className="neo-icon-do-not-disturb"
                        aria-label="end work"
                      ></span>
                      Go Not Ready{" "}
                      <span
                        className="neo-icon-chevron-right"
                        aria-label="end work"
                      ></span>
                    </div>
                    <div
                      className="go-ready"
                      style={{ fontFamily: "Noto-Sans" }}
                      onClick={() => {
                        goReady("Ready");
                        updateStatus("Ready");
                        setShowStatusChannel("Ready");
                        changeStatusnew("Ready");
                        localStorage.setItem("statusValue", "Ready");
                        localStorage.removeItem("statusValue2");
                      }}
                    >
                      <span
                        className="neo-icon-go-ready"
                        aria-label="end work"
                      ></span>
                      Go Ready
                    </div>

                    <div
                      className="go-ready"
                      style={{ fontFamily: "Noto-Sans" }}
                      onClick={() => {
                        mealUpdate("Not Ready");
                        localStorage.setItem("statusValue", "Not Ready");
                        changeStatusnew("Not Ready");
                        localStorage.removeItem("statusValue2");
                      }}
                    >
                      <span
                        className="neo-icon-go-ready"
                        aria-label="end work"
                      ></span>
                      Not Ready
                    </div>
                  </div>
                )}

                <div className="status">
                  <div className="status-head">Channel</div>
                  {filteredChannels.map((item, index) => {
                    return (
                      <div key={index} className="go-not-ready">
                        {item.channelName} -{" "}
                        {localStorage.getItem("statusValue")}
                      </div>
                    );
                  })}
                </div>
              </div>
              <a>
                {" "}
                {hideLogout && (
                  <div className="sign-out" onClick={logout}>
                    <span
                      className="neo-icon-exit-left"
                      aria-label="sign out"
                    ></span>
                    <span
                      className="signout-text"
                      style={{ fontFamily: "Noto-Sans" }}
                    >
                      Sign Out
                    </span>
                  </div>
                )}
              </a>
            </div>
          )}
          {!rmState && (
            <div className="contents">
              <div className="status">
                <div className="reason-head">
                  {" "}
                  <span
                    className="neo-icon-chevron-left"
                    aria-label="end work"
                    onClick={showOldScreen}
                  ></span>
                  Reason Codes
                </div>
                <div className="content1">
                  {userstatus.map((item, index) => (
                    <div
                      key={index}
                      className="go-not-ready"
                      onClick={() => {
                        mealUpdate(item.statusName);
                        localStorage.setItem("statusValue", item.statusName);
                        updateStatusWithReasonCode(item);
                        if (item.statusName == "Outbound") {
                          localStorage.setItem("statusValue2", "Outbound");
                        }
                      }}
                    >
                      {item.statusName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {voiceEnable ? (
        <Sdk
          referance={referance}
          showStatusChannel={showStatusChannel}
          updateStatus={updateStatus}
          changeStatusnew={changeStatusnew}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist,
  setcontactlist1,
  closeNotify,
  clearnotifyAll,
  setShowNotification,
  setShowUserProfile,
  setshowphonedialer,
  setVoiceRefresh,
  setShowingStatusClass,
  setShowingStatus,
  setsidebarVal,
})(Navbar);
