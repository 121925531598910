import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {rootReducer} from "../reducers/index.js";
import { logger } from "./middleware/logger";


const presistConfig = {
  key: "root",
  storage,
  blacklist: ["data"],
};

const presistReducer = persistReducer(presistConfig, rootReducer);

export const store = createStore(
  presistReducer,
  compose(applyMiddleware(thunk, logger))
);

export const persistor = persistStore(store);
