import backgroundImg from "../../assets/assets_clientpage/images/bg.png";
import ChatForClient from "../../components/clientChat/index";
function Bank() {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw", // Adjust this to your needs
      }}
    >
      <ChatForClient />
    </div>
  );
}

export default Bank;
