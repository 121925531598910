import React from "react";
import "../../assets/chatasset/assets/styles/chat.css";
import "../../assets/chatasset/assets/styles/style.css";
import { MdChat } from "react-icons/md";
// Component
import Contactlist from "../../components/chat-components/Contact-list-component/Contact-list.component";
import { connect } from "react-redux";
import { setselectedusername } from "../../redux/actions/actions";
import CustomerDetailSearch from "../../components/chat-components/User-list-component/Customer-Detail-Search/Customer-Detail-Search";
import ChatComponent from "../../components/chat-components/Chat-component/Chat.component";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  width: "100%",
}));
const mapStateToProps = (state) => {
  return {
    chat: state.data.chat,
    CustomerDetailsShow: state.data.CustomerDetailsShow,
    userlistshow: state.data.userlistshow,
    showExternalChat: state.data.showExternalChat,
  };
};

const ChatNew = (props) => {
  const { CustomerDetailsShow } = props;

  return (
    <>
      <StyledPaper elevation={0}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "0px 3px 3px 3px",
          }}
        >
          <Grid container direction="row" sx={{ height: "100%" }}>
            <Grid
              item
              xs={3}
              md={3}
              sm={3}
              sx={{ borderRight: "3px solid #dedede" }}
            >
              <Contactlist />
            </Grid>

            <ChatComponent />
          </Grid>
        </Box>
      </StyledPaper>
      {CustomerDetailsShow && <CustomerDetailSearch />}
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
})(ChatNew);
