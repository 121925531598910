import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import logo from "../../assets/assets/images/Inaipi_Logo-1.2.png";
import { Badge } from "@mui/material";
import { connect } from "react-redux";
import {
  clearnotify,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setshowExternalChat,
  setExternalChatData,
  setExternalChatActive,
  setshowphonedialer,
} from "../../redux/actions/actions";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const mapStateToProps = (state) => {
  return {
    notification: state.notification.notification,
    sidebarVal: state.contactListData.sidebarVal,
  };
};

const Sidebar = (props) => {
  let agentRole = JSON.parse(localStorage.getItem("tokenAgent"));
  let permission = JSON.parse(localStorage.getItem("permission"));
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      {agentRole.role == "Supervisor" ? (
        <aside
          className="aside_left animate__animated animate__fadeInLeft"
          style={{ zIndex: 1 }}
        >
          <div className="logo-aside">
            <Link
              id="logo-aside"
              to={"/main/Dashboard"}
              onClick={() => {
                props.setsidebarVal("Dashboard");
                props.setShowNotification(false);
                props.setShowUserProfile(false);
                props.setshowExternalChat(false);
                props.clearnotify();
                props.setshowphonedialer(false);
              }}
            >
              <img src={logo} alt="" style={{ width: "40px" }} />
            </Link>
          </div>
          <nav
            className="navbar navbar-nav side-nav  pt-0 w-100"
            style={{ paddingRight: "0px" }}
          >
            <ul
              className="navbar-nav w-100 CONTACT-LIST-AREA"
              style={{
                marginLeft: "0px",
                overflowY: "scroll",
                height: "85vh",
              }}
            >
              {permission.find(
                (element) => element.moduleName === "Dashboard"
              ) ? (
                <>
                  <Link to={"/main/Dashboard"}>
                    <BootstrapTooltip
                      title={"Dashboard"}
                      arrow
                      placement="right"
                    >
                      <li
                        className={
                          props.sidebarVal == "Dashboard"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="dashboard_chat_supervisor"
                        onClick={() => {
                          props.setsidebarVal("Dashboard");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          grid_view
                        </i>
                      </li>
                    </BootstrapTooltip>
                  </Link>
                </>
              ) : null}

              {permission.find((element) => element.moduleName === "Chat") ? (
                <>
                  <BootstrapTooltip
                    title={"Interaction"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/Chat"}>
                      <li
                        className={
                          props.sidebarVal == "Chat"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="chat_chat_supervisor"
                        onClick={() => {
                          props.setsidebarVal("Chat");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          document.title = "Inaipi Digital • Chat";
                        }}
                      >
                        {/* <Badge
                          color="warning"
                          badgeContent={
                            props.notification ? props.notification.length : 0
                          }
                          sx={{ top: "3px" }}
                        /> */}

                        <StyledBadge
                          badgeContent={
                            props.notification ? props.notification.length : 0
                          }
                          color="primary"
                          invisible={
                            props.notification.length == 0 ? true : false
                          }
                        >
                          <i
                            className="material-symbols-outlined"
                            style={{ color: "#ffff", fontSize: "19px" }}
                          >
                            sms
                          </i>
                        </StyledBadge>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : null}

              {permission.find((element) => element.moduleName === "Report") ? (
                <>
                  <BootstrapTooltip
                    title={"Session Report"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/SessioReports"}>
                      <li
                        className={
                          props.sidebarVal == "Sessionreport"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="sessionreports_chat_supervisor"
                        onClick={() => {
                          props.setsidebarVal("Sessionreport");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          leaderboard
                        </i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : null}

              {permission.find((element) => element.moduleName === "Report") ? (
                <>
                  <BootstrapTooltip
                    title={"Agent Report"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/AgentReports"}>
                      <li
                        className={
                          props.sidebarVal == "Agentreport"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="agentreports_chat_supervisor"
                        onClick={() => {
                          props.setsidebarVal("Agentreport");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          table_chart
                        </i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : null}

              {/* CMS */}
              {permission.map((module) => {
                if (module.moduleName === "Crm") {
                  const crmScreen = module.screen.find(
                    (item) =>
                      item.screenId === "CONTACT" && item.read && item.write
                  );

                  if (crmScreen) {
                    return (
                      <BootstrapTooltip title={"CMS"} arrow placement="right">
                        <Link to={"/main/CMS"}>
                          <li
                            className={
                              props.sidebarVal == "CMS"
                                ? "nav-item sideBaractive"
                                : "nav-item"
                            }
                            id="sessionreports_chat_agent"
                            onClick={() => {
                              props.setsidebarVal("CMS");
                              props.setShowNotification(false);
                              props.setShowUserProfile(false);
                              props.setshowExternalChat(false);
                              props.setExternalChatData("");
                              props.setExternalChatActive("");
                              props.setshowphonedialer(false);
                              document.title = "Inaipi Digital • Contact";
                            }}
                          >
                            <i
                              className="material-symbols-outlined"
                              style={{ color: "#ffff", fontSize: "19px" }}
                            >
                              backup_table
                            </i>
                          </li>
                        </Link>
                      </BootstrapTooltip>
                    );
                  } else {
                    return <></>;
                  }
                }
              })}

              {permission.find(
                (element) => element.moduleName === "Multimedia"
              ) ? (
                <BootstrapTooltip
                  title={"Multimedia Search"}
                  arrow
                  placement="right"
                >
                  <Link to={"/main/multimediasearch"}>
                    <li
                      className={
                        props.sidebarVal == "Multimedia"
                          ? "nav-item sideBaractive"
                          : "nav-item"
                      }
                      id="multimedia_chat_agent"
                      onClick={() => {
                        props.setsidebarVal("Multimedia");
                        document.title = "Inaipi Digital • Multimedia";
                      }}
                    >
                      <i
                        className="fa-solid fa-magnifying-glass"
                        style={{ color: "#ffff", fontSize: "18x" }}
                      ></i>
                    </li>
                  </Link>
                </BootstrapTooltip>
              ) : null}

              {permission.map((module) => {
                if (module.moduleName === "Crm") {
                  const crmScreen = module.screen.find(
                    (item) =>
                      item.screenId === "CONTACTSALES" &&
                      item.read &&
                      item.write
                  );

                  if (crmScreen) {
                    return (
                      <>
                        <BootstrapTooltip
                          title={"Supervisor-Contact"}
                          arrow
                          placement="right"
                        >
                          <Link to={"/main/supervisorContact"}>
                            <li
                              className={
                                props.sidebarVal == "Contact"
                                  ? "nav-item sideBaractive"
                                  : "nav-item"
                              }
                              id="Contact_chat_supervisor"
                              onClick={() => {
                                props.setsidebarVal("Contact");
                                props.setShowNotification(false);
                                props.setShowUserProfile(false);
                                props.setshowExternalChat(false);
                                props.setExternalChatData("");
                                props.setshowphonedialer(false);
                                document.title =
                                  "Inaipi Digital • Supervisor-Contact";
                              }}
                            >
                              <i
                                className="material-symbols-outlined"
                                style={{ color: "#ffff", fontSize: "19px" }}
                              >
                                perm_contact_calendar
                              </i>
                            </li>
                          </Link>
                        </BootstrapTooltip>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                }
              })}

              {permission.find(
                (element) => element.moduleName === "Calender"
              ) ? (
                <BootstrapTooltip title={"Calender"} arrow placement="right">
                  <Link to={"/main/Calenders"}>
                    <li
                      className={
                        props.sidebarVal == "Calender"
                          ? "nav-item sideBaractive"
                          : "nav-item"
                      }
                      id="Calender_chat_supervisor"
                      onClick={() => {
                        props.setsidebarVal("Calender");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setshowphonedialer(false);
                        document.title = "Inaipi Digital • Calender";
                      }}
                    >
                      <i
                        className="material-symbols-outlined"
                        style={{ color: "#ffff", fontSize: "19px" }}
                      >
                        calendar_month
                      </i>
                    </li>
                  </Link>
                </BootstrapTooltip>
              ) : null}

              <BootstrapTooltip title={"Web-Statistic"} arrow placement="right">
                <Link to={"/main/statistic"}>
                  <li
                    className={
                      props.sidebarVal == "Statistic"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    // className="nav-item"
                    id="Calender_chat_agent"
                    onClick={() => {
                      props.setsidebarVal("Statistic");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setExternalChatActive("");
                      props.setshowphonedialer(false);
                      document.title = "Inaipi Digital • Web-Statistic";
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "19px" }}
                    >
                      pie_chart
                    </span>
                  </li>
                </Link>
              </BootstrapTooltip>

              <BootstrapTooltip title={"Agent-Status"} arrow placement="right">
                <Link to={"/main/AgentStatus"}>
                  <li
                    className={
                      props.sidebarVal == "AgentStatus"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    // className="nav-item"
                    id="Calender_chat_agent"
                    onClick={() => {
                      props.setsidebarVal("AgentStatus");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setExternalChatActive("");
                      props.setshowphonedialer(false);
                      document.title = "Inaipi Digital • Agent-Status";
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "20px" }}
                    >
                      groups
                    </span>
                  </li>
                </Link>
              </BootstrapTooltip>

              <BootstrapTooltip title={"powerBi v1"} arrow placement="right">
                <Link to={"/main/powerBi"}>
                  <li
                    className={
                      props.sidebarVal == "powerBiv1"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    id="powerBi_chat_supervisor"
                    onClick={() => {
                      props.setsidebarVal("powerBiv1");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setshowphonedialer(false);
                    }}
                  >
                    <i
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "19px" }}
                    >
                      signal_cellular_alt
                    </i>
                  </li>
                </Link>
              </BootstrapTooltip>
              <BootstrapTooltip title={"powerBi v2"} arrow placement="right">
                <Link to={"/main/powerBiv2"}>
                  <li
                    className={
                      props.sidebarVal == "powerBiv2"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    id="powerBi_chat_supervisor"
                    onClick={() => {
                      props.setsidebarVal("powerBiv2");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setshowphonedialer(false);
                    }}
                  >
                    <i
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "19px" }}
                    >
                      align_vertical_bottom
                    </i>
                  </li>
                </Link>
              </BootstrapTooltip>

              <BootstrapTooltip title={"Queue Chat"} arrow placement="right">
                <Link to={"/main/Queue-Chat"}>
                  <li
                    className={
                      props.sidebarVal == "QueueChat"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    id="powerBi_chat_supervisor"
                    onClick={() => {
                      props.setsidebarVal("QueueChat");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setshowphonedialer(false);
                    }}
                  >
                    <i
                      class="fa-solid fa-list"
                      style={{ color: "#ffff", fontSize: "17px" }}
                    ></i>
                  </li>
                </Link>
              </BootstrapTooltip>


              <BootstrapTooltip title={"Reports"} arrow placement="right">
                <Link to={"/main/reports"}>
                  <li
                    className={
                      props.sidebarVal == "Reports"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    id="reports_chat_supervisor"
                    onClick={() => {
                      props.setsidebarVal("Reports");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setshowphonedialer(false);
                    }}
                  >
                    {/* <i
                      class="fa-solid fa-list"
                      style={{ color: "#ffff", fontSize: "17px" }}
                    ></i> */}
                    <i class="fa-solid fa-chart-gantt" style={{ color: "#ffff", fontSize: "17px" }}></i>
                  </li>
                </Link>
              </BootstrapTooltip>
            </ul>
          </nav>
        </aside>
      ) : (
        <aside
          className="aside_left animate__animated animate__fadeInLeft"
          style={{ zIndex: 1 }}
        >
          <div className="logo-aside">
            <NavLink
              id="logo-aside"
              to={"/main/Dashboard"}
              onClick={() => {
                props.setsidebarVal("Dashboard");
                props.setShowNotification(false);
                props.setShowUserProfile(false);
                props.setshowExternalChat(false);
                props.setExternalChatData("");
                props.setExternalChatActive("");
                props.setshowphonedialer(false);
              }}
            >
              <img src={logo} alt="" style={{ width: "35px" }} />
            </NavLink>
          </div>
          <nav
            className="navbar navbar-nav side-nav pt-0 w-100"
            style={{ paddingRight: "0px" }}
          >
            <ul
              className="navbar-nav w-100 CONTACT-LIST-AREA"
              style={{
                marginLeft: "0px",
                overflowY: "scroll",
                height: "90vh",
              }}
            >
              {permission.find(
                (element) => element.moduleName === "Dashboard"
              ) ? (
                <>
                  <BootstrapTooltip title={"Dashboard"} arrow placement="right">
                    <Link to={"/main/Dashboard"}>
                      <li
                        className={
                          props.sidebarVal == "Dashboard"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="dashboard_chat_agent"
                        onClick={() => {
                          props.setsidebarVal("Dashboard");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setExternalChatActive("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital";
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          grid_view
                        </i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : (
                ""
              )}

              {permission.find((element) => element.moduleName === "Chat") ? (
                <>
                  <BootstrapTooltip
                    title={"Interaction"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/Chat"}>
                      <li
                        className={
                          props.sidebarVal == "Chat"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="chat_chat_agent"
                        onClick={() => {
                          props.setsidebarVal("Chat");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setExternalChatActive("");
                          document.title = "Inaipi Digital • Chat";
                        }}
                      >
                        <StyledBadge
                          badgeContent={
                            props.notification ? props.notification.length : 0
                          }
                          color="primary"
                          invisible={
                            props.notification.length == 0 ? true : false
                          }
                        >
                          <i
                            className="material-symbols-outlined"
                            style={{ color: "#ffff", fontSize: "19px" }}
                          >
                            sms
                          </i>
                        </StyledBadge>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : (
                <></>
              )}

              {permission.find((element) => element.moduleName === "Report") ? (
                <>
                  <BootstrapTooltip
                    title={"Session Report"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/SessioReports"}>
                      <li
                        className={
                          props.sidebarVal == "Report"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="sessionreports_chat_agent"
                        onClick={() => {
                          props.setsidebarVal("Report");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setExternalChatActive("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          leaderboard
                        </i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : (
                <></>
              )}

              {/* CMS */}
              {permission.map((module) => {
                if (module.moduleName === "Crm") {
                  const crmScreen = module.screen.find(
                    (item) =>
                      item.screenId === "CONTACT" && item.read && item.write
                  );

                  if (crmScreen) {
                    return (
                      <BootstrapTooltip title={"CMS"} arrow placement="right">
                        <Link to={"/main/CMS"}>
                          <li
                            className={
                              props.sidebarVal == "CMS"
                                ? "nav-item sideBaractive"
                                : "nav-item"
                            }
                            id="sessionreports_chat_agent"
                            onClick={() => {
                              props.setsidebarVal("CMS");
                              props.setShowNotification(false);
                              props.setShowUserProfile(false);
                              props.setshowExternalChat(false);
                              props.setExternalChatData("");
                              props.setExternalChatActive("");
                              props.setshowphonedialer(false);
                              document.title = "Inaipi Digital • CMS";
                            }}
                          >
                            <i
                              className="material-symbols-outlined"
                              style={{ color: "#ffff", fontSize: "19px" }}
                            >
                              backup_table
                            </i>
                          </li>
                        </Link>
                      </BootstrapTooltip>
                    );
                  } else {
                    return <></>;
                  }
                }
              })}

              {/* Agent Contact */}
              {permission.map((module) => {
                if (module.moduleName === "Crm") {
                  const crmScreen = module.screen.find(
                    (item) =>
                      item.screenId === "CONTACTSALES" &&
                      item.read &&
                      item.write
                  );

                  if (crmScreen) {
                    return (
                      <>
                        
                        <BootstrapTooltip
                          title={"Contact"}
                          arrow
                          placement="right"
                        >
                          <Link to={"/main/Agent-Contact"}>
                            <li
                              className={
                                props.sidebarVal == "AgentContact"
                                  ? "nav-item sideBaractive"
                                  : "nav-item"
                              }
                              id="sessionreports_chat_agent"
                              onClick={() => {
                                props.setsidebarVal("AgentContact");
                                props.setShowNotification(false);
                                props.setShowUserProfile(false);
                                props.setshowExternalChat(false);
                                props.setExternalChatData("");
                                props.setExternalChatActive("");
                                props.setshowphonedialer(false);
                                document.title = "Inaipi Digital • Contact";
                              }}
                            >
                              <i
                                className="material-symbols-outlined"
                                style={{ color: "#ffff", fontSize: "19px" }}
                              >
                                perm_contact_calendar
                              </i>
                            </li>
                          </Link>
                        </BootstrapTooltip>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                }
              })}

              {permission.find(
                (element) => element.moduleName === "Multimedia"
              ) ? (
                <>
                  <BootstrapTooltip
                    title={"Multimedia Search"}
                    arrow
                    placement="right"
                  >
                    <Link to={"/main/multimediasearch"}>
                      <li
                        className={
                          props.sidebarVal == "Multimedia"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="multimedia_chat_agent"
                        onClick={() => {
                          props.setsidebarVal("Multimedia");
                          document.title = "Inaipi Digital • Multimedia";
                        }}
                      >
                        <i
                          className="fa-solid fa-magnifying-glass"
                          style={{ color: "#ffff", fontSize: "18x" }}
                        ></i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : (
                <></>
              )}

              {permission.find(
                (element) => element.moduleName === "Calender"
              ) ? (
                <>
                  <BootstrapTooltip title={"Calender"} arrow placement="right">
                    <Link to={"/main/Calenders"}>
                      <li
                        className={
                          props.sidebarVal == "Calender"
                            ? "nav-item sideBaractive"
                            : "nav-item"
                        }
                        id="Calender_chat_agent"
                        onClick={() => {
                          props.setsidebarVal("Calender");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setExternalChatActive("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Calender";
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{ color: "#ffff", fontSize: "19px" }}
                        >
                          calendar_month
                        </i>
                      </li>
                    </Link>
                  </BootstrapTooltip>
                </>
              ) : (
                <></>
              )}

              <BootstrapTooltip title={"Web-Statistic"} arrow placement="right">
                <Link to={"/main/statistic"}>
                  <li
                    className={
                      props.sidebarVal == "Statistic"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    // className="nav-item"
                    id="Calender_chat_agent"
                    onClick={() => {
                      props.setsidebarVal("Statistic");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setExternalChatActive("");
                      props.setshowphonedialer(false);
                      document.title = "Inaipi Digital • Web Statistic";
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "19px" }}
                    >
                      pie_chart
                    </span>
                  </li>
                </Link>
              </BootstrapTooltip>

              <BootstrapTooltip title={"CMS Contacts"} arrow placement="right">
                <Link to={"/main/Contacts"}>
                  <li
                    className={
                      props.sidebarVal == "Contacts"
                        ? "nav-item sideBaractive"
                        : "nav-item"
                    }
                    id="sessionreports_chat_agent"
                    onClick={() => {
                      props.setsidebarVal("Contacts");
                      props.setShowNotification(false);
                      props.setShowUserProfile(false);
                      props.setshowExternalChat(false);
                      props.setExternalChatData("");
                      props.setExternalChatActive("");
                      props.setshowphonedialer(false);
                      document.title = "Inaipi Digital • Contacts";
                    }}
                  >
                    <i
                      className="material-symbols-outlined"
                      style={{ color: "#ffff", fontSize: "19px" }}
                    >
                      account_circle
                    </i>
                  </li>
                </Link>
              </BootstrapTooltip>
            </ul>
          </nav>
        </aside>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  clearnotify,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setshowExternalChat,
  setExternalChatData,
  setExternalChatActive,
  setshowphonedialer,
})(Sidebar);
