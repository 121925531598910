//const context_port = 'https://gway.release.inaipi.ae/email'
import { emailservice } from "../../container/BaseUrl/BaseUrl";
const context_port = emailservice + 'email'


// const context_port = 'https://gway.release.inaipi.ae/routeemail/email'




/** INBOX STATUS LIST */
export const FETCH_INBOX_LIST = context_port + '/fetchEmails';
export const FETCH_MAIL_CONTENET = context_port + '/fetchEmail';
export const DOWNLOAD_MAIL_ATTACHMENT = context_port + '/downloadAttachment';

/** SEND EMAIL */
export const SEND_MAIL_CONTENT = context_port + '/sendEmail';
export const SEND_MAIL_CONTENT_SUPERVISOR = context_port + '/sendEmailApproval';

export const FORWARD_MAIL_CONTENT = context_port + '/forwardEmail';

/** SEND EMAIL */
export const COMPLETED_MAIL_LIST = context_port + '/agentsCompletedList';
