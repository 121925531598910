import React, { useRef, useEffect } from 'react';

const Foroutsideclick = (props) => {
  const ref = useRef(null);
  const { onClickOutside, children } = props;

  const handelClickOutside = (event) => {
    const isModal = event.target.closest('MuiDialog-root') || event.target.closest('.MuiDialog-root')|| event.target.closest('.modal');

    if (ref.current && !ref.current.contains(event.target) && !isModal) {
     // event.stopPropagation();
      onClickOutside && onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handelClickOutside, true);
    return () => {
      document.removeEventListener('click', handelClickOutside, true);
    };
  }, []);

  if (!children) {
    return null;
  }

  return <div ref={ref}>{children}</div>;
};

export default Foroutsideclick;
