import axios from "axios";
import { BaseUrl, errorApi } from "../container/BaseUrl/BaseUrl";

export const errorHandel = async (error, endpoint) => {
  const tenantID = localStorage.getItem("TenantId");
  try {
    const payload = {
      servicename: "DCCCHAT",
      logs: error,
      description: endpoint,
    };

    const header = {
      headers: {
        tenantid: tenantID,
      },
    };
    const { data } = await axios.post(
      errorApi + "/UIlogs/createLogs",
      payload,
      header
    );
    if (data.status) {
    }
  } catch (error) {}
};

export const logoutApi = async (userid) => {
  try {
    const response = await axios.post(BaseUrl + "/users/logout/" + userid);
    return response;
  } catch (error) {
    errorHandel(error, "/users/logoutnew/");
  }
};
