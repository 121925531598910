import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { RiSurveyLine } from "react-icons/ri";

import {
  XCircle,
  MagnifyingGlass,
  ArrowCircleLeft,
} from "@phosphor-icons/react";
import { AiFillInteraction } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import { AiOutlineFile } from "react-icons/ai";
import { HiOutlineTicket } from "react-icons/hi";
import "./Customer-Detail-Search.style.css";
import Draggable from "react-draggable";
import axios from "axios";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setCustomerDetailsShow,
} from "../../../../redux/actions/actions";

import Interactiontab from "../user-list-child-component/Interaction-tab.component";
import SmartXTab from "../user-list-child-component/SmartX-tab.component";
import Tickethistory from "../user-list-child-component/Ticket-history.component";
import FilesTab from "../user-list-child-component/Files-tab.component";
import SurveyTab from "../user-list-child-component/Survey-tab.component";

import { SiMicrosoftteams } from "react-icons/si";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    selectedusername: data.selectedusername,
    selectedemail: data.selectedemail,
    selectedmobile: data.selectedmobile,
    selectedwhatsapp: data.selectedwhatsapp,
    selectedfacebook: data.selectedfacebook,
    selectedtwitter: data.selectedtwitter,
    selectedteams: data.selectedteams,
    selectedcompany: data.selectedcompany,
    selectedaddress: data.selectedaddress,
    selectedid: data.selectedid,
    externalChatData: data.externalChatData,
  };
};
const CustomerDetailSearch = (props) => {
  const [customerlist, setCustomerlist] = useState([]);
  let permission = JSON.parse(localStorage.getItem("permission"));

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [InteractionHistory, setInteractionHistory] =
    useState("nav-link active");
  const [InteractionHistorytab, setInteractionHistorytab] = useState(
    "tab-pane fade show active"
  );
  const [SessionHistory, setSessionHistory] = useState("nav-link");
  const [SessionHistorytab, setSessionHistorytab] =
    useState("tab-pane fade show");
  const [SmartX, setSmartX] = useState("nav-link");
  const [SmartXtab, setSmartXtab] = useState("tab-pane fade show");
  const [Files, setFiles] = useState("nav-link");
  const [Filestab, setFilestab] = useState("tab-pane fade show");
  const [Ticket, setTicket] = useState("nav-link");
  const [TicketTab, setTicketTab] = useState("tab-pane fade show");

  const [sms, setSms] = useState("nav-link");
  const [smsTab, setSmsTab] = useState("tab-pane fade show");
  const [survey, setSurvey] = useState("nav-link");
  const [surveyTab, setSurveyTab] = useState("tab-pane fade show");

  const [searchValue, setSearchValue] = useState("");
  const [inputSearchClear, setInputSearchClear] = useState(false);

  const tabonchangehandler = (value) => {
    switch (value) {
      case "InteractionHistory":
        setInteractionHistory("nav-link active");
        setInteractionHistorytab("tab-pane fade show active");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link");
        setSmartXtab("tab-pane fade");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link");
        setTicketTab("tab-pane fade");

        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");

        break;
      case "SessionHistory":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link active");
        setSessionHistorytab("tab-pane fade show active");
        setSmartX("nav-link");
        setSmartXtab("tab-pane fade");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link");
        setTicketTab("tab-pane fade");

        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");

        break;
      case "SmartX":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link active");
        setSmartXtab("tab-pane fade show active");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link");
        setTicketTab("tab-pane fade");

        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");
        break;
      case "Files":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link ");
        setSmartXtab("tab-pane fade show ");
        setFiles("nav-link active");
        setFilestab("tab-pane fade show active");
        setTicket("nav-link ");
        setTicketTab("tab-pane fade show ");

        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");

        break;
      case "Ticket":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link ");
        setSmartXtab("tab-pane fade show ");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link active");
        setTicketTab("tab-pane fade show active");

        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");

        break;
      case "sms":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link ");
        setSmartXtab("tab-pane fade show ");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link ");
        setTicketTab("tab-pane fade show ");

        setSurvey("nav-link ");
        setSurveyTab("tab-pane fade show ");

        break;
      case "survey":
        setInteractionHistory("nav-link ");
        setInteractionHistorytab("tab-pane fade");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade");
        setSmartX("nav-link ");
        setSmartXtab("tab-pane fade show ");
        setFiles("nav-link");
        setFilestab("tab-pane fade");
        setTicket("nav-link ");
        setTicketTab("tab-pane fade show ");
        setSurvey("nav-link active ");
        setSurveyTab("tab-pane fade show active");

        break;
      default:
        setInteractionHistory("nav-link active");
        setInteractionHistorytab("tab-pane fade show active");
        setSessionHistory("nav-link");
        setSessionHistorytab("tab-pane fade show ");
        setSmartX("nav-link");
        setSmartXtab("tab-pane fade");
        setFiles("nav-link ");
        setFilestab("tab-pane fade show ");
        setTicket("nav-link");
        setTicketTab("tab-pane fade");
        setSurvey("nav-link  ");
        setSurveyTab("tab-pane fade show ");
    }
  };

  const customerListApi = (value) => {
    let body = {
      emailId: value,
      phonenumber: value,
    };
    axios
      .post(BaseUrl + "/users/getcustomerdetails", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setCustomerlist(res.data.data);
      })

      .catch((error) => {});
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.trim() !== "" && value.trim() !== " ") {
      customerListApi(value);
    } else {
      setCustomerlist("");
    }
  };

  const handleInputFocus = () => {
    if (inputSearchClear) {
      setInputSearchClear(false);
      setCustomerlist("");
    }
  };

  useEffect(() => {
    if (inputSearchClear) {
      setCustomerlist("");
      setCustomerlist(false);
    }
  }, [inputSearchClear]);

  const [userId, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");

  const [facebookId, setFaceBookId] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [teamsId, setTeamsId] = useState("");
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");

  const UpdateCustomer = (value) => {
    setkey(1);
    setUserID(value.id);
    setUserName(value.username);
    setEmail(value.email);
    setphoneNumber(value.phonenumber);
    setFaceBookId(value.facebookId);
    setTwitterId(value.twitterId);
    setWhatsappNumber(value.whatsappnumber);
    setTeamsId(value.teamsId);
    setCompany(value.company);
    setAddress(value.address);
    setInputSearchClear(true);
  };

  const [key, setkey] = useState(0);

  return (
    <Draggable handle=".handle">
      {(() => {
        switch (key) {
          case 0:
            return (
              <Box
                sx={{
                  width: "35%",
                  height: "90%",
                  zIndex: "9",
                  position: "absolute",
                  background: "#F6F7F9",
                  borderRadius: "10px",
                  top: "50px",
                  left: "65%",
                  boxShadow: "0px 0px 5px 0px #c9c9c9",
                }}
                p={1}
              >
                <Stack spacing={2}>
                  <Box
                    className="handle"
                    sx={{
                      width: "100%",
                      height: "20px",
                      background: "#F6F7F9",
                      cursor: "pointer",
                    }}
                    p={1}
                  >
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="right"
                    >
                      <XCircle
                        size={22}
                        color="black"
                        cursor="pointer"
                        id="close_customer_details"
                        onClick={() => {
                          props.setCustomerDetailsShow(false);
                        }}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "80vh",
                      background: "#F6F7F9",
                      borderRadius: 2,
                    }}
                    p={1}
                  >
                    <div className="search-box">
                      <div className="row2222">
                        <input
                          className="no-outline"
                          type="text"
                          id="input-box"
                          placeholder="Search Detail Customer"
                          autoComplete="off"
                          style={{
                            outline: "unset",
                            outlineOffset: "unset",
                            height: "30px",
                          }}
                          onChange={handleInputChange}
                          onFocus={handleInputFocus}
                        />
                        <button className="searchCustomers" id="search_customer">
                          <MagnifyingGlass size={20} color="black" />
                        </button>
                      </div>

                      <div className="result-box">
                        <ul>
                          {searchValue.trim() === "" ||
                          !customerlist ||
                          customerlist.length === 0 ? (
                            <li>No Data</li>
                          ) : (
                            customerlist.map((el) => (
                              <li
                                key={el.id}
                                id="update_customer"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  UpdateCustomer(el);
                                }}
                              >
                                {el.email}
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                  </Box>
                </Stack>
              </Box>
            );

          case 1:
            return (
              <Box
                sx={{
                  width: "35%",
                  height: "100%",
                  zIndex: "9",
                  position: "absolute",
                  background: "#F6F7F9",
                  borderRadius: "10px",
                  top: "0px",
                  left: "65%",
                  boxShadow: "0px 0px 5px 0px #c9c9c9",
                }}
                p={1}
              >
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Box
                    className="handle"
                    sx={{
                      width: "100%",
                      height: "20px",
                      background: "#F6F7F9",
                      cursor: "pointer",
                    }}
                    p={1}
                  >
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="right"
                    >
                      <ArrowCircleLeft
                        size={22}
                        color="black"
                        cursor="pointer"
                        id="left_arrow"
                        onClick={() => {
                          setkey(0);
                          setInputSearchClear(true);
                        }}
                      />
                    </Stack>
                  </Box>

                  <>
                    <div className="px-0">
                      <div className="details-user-chat borde r-bottom p-0">
                        <div className="chat-profile-userlist d-flex justify-content-between align-items-center p-2">
                          <>
                            <div className="d-flex ">
                              <div className="details-user-all mx-2">
                                <div className="d-flex me-1">
                                  <div
                                    className="col-2 me-2 photo position-relative"
                                    style={{
                                      background: "#E1F2FB",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  >
                                    <p
                                      className=" chat_name"
                                      style={{
                                        color: "#7e7272",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        marginTop: "13px",
                                      }}
                                    >
                                      <b
                                        className="h-100"
                                        style={{
                                          color: "#7e7272",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {userName
                                          ? userName.slice(0, 2).toUpperCase()
                                          : ""}
                                      </b>
                                    </p>
                                  </div>
                                  <div
                                    className="d-flex align-items-center mb-2 w-75 text-break"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {userName
                                      ? userName[0].toUpperCase() +
                                        userName.slice(1, userName.length)
                                      : ""}
                                  </div>
                                </div>

                                <div className="row row-cols-2 details-user-sub">
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-solid fa-envelope me-2"
                                          style={{
                                            color: "#0c87ef",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>

                                      <p className="w-100 text-break">
                                        {email === "" || email === "Empty"
                                          ? ""
                                          : email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-solid fa-mobile me-2"
                                          style={{
                                            color: "#0c87ef",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {" "}
                                        {phoneNumber === "" ||
                                        phoneNumber === "Empty"
                                          ? " "
                                          : phoneNumber}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-brands fa-whatsapp me-2"
                                          style={{
                                            color: "#4cc659",
                                            fontSize: "16px",
                                            marginTop: "5px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {" "}
                                        {whatsappNumber === "" ||
                                        whatsappNumber === "Empty"
                                          ? ""
                                          : whatsappNumber}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-brands fa-facebook-f"
                                          style={{
                                            color: "#0c87ef",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {" "}
                                        {facebookId === "" ||
                                        facebookId === "Empty"
                                          ? ""
                                          : facebookId}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <SiMicrosoftteams
                                          style={{
                                            color: "#7980e5",
                                            marginTop: "5px",
                                          }}
                                          className="me-1"
                                        />
                                      </div>
                                      <p className="w-100 text-break">
                                        {" "}
                                        {teamsId === "" || teamsId === "Empty"
                                          ? ""
                                          : teamsId}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-brands fa-twitter "
                                          style={{
                                            color: "#1da1f2",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {" "}
                                        {twitterId === "" ||
                                        twitterId === "Empty"
                                          ? ""
                                          : twitterId}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-solid fa-building"
                                          style={{
                                            color: "#dd4440",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {company === "" || company === "Empty"
                                          ? ""
                                          : company}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col use-cont d-flex">
                                    <div className="d-flex align-items-start">
                                      <div>
                                        <i
                                          className="fa-solid fa-location-dot me-2 "
                                          style={{
                                            color: "#dd4440",
                                            marginTop: "8px",
                                          }}
                                        ></i>
                                      </div>
                                      <p className="w-100 text-break">
                                        {address === "" || address === "Empty"
                                          ? ""
                                          : address}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>

                      <div className="chat-tabs">
                        <ul
                          className="nav nav-pills border-bottom border-top p-2 "
                          id="pills-tab"
                          role="tablist"
                          style={{ justifyContent: "space-around" }}
                        >
                          <BootstrapTooltip
                            title="Interaction History"
                            arrow
                            placement="top"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className={InteractionHistory}
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  tabonchangehandler("InteractionHistory");
                                }}
                                style={{
                                  boxShadow: "1px 1px 2px gray",
                                  fontWeight: 600,
                                }}
                              >
                                <AiFillInteraction size={17} />
                              </button>
                            </li>
                          </BootstrapTooltip>

                          {permission.map((module) => {
                            if (module.moduleName === "Chat") {
                              const value = module.screen.find(
                                (item) =>
                                  item.screenId === "SMART_X" &&
                                  item.read &&
                                  item.write
                              );
                              if (value) {
                                return (
                                  <BootstrapTooltip
                                    title="SmartX"
                                    arrow
                                    placement="top"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className={SmartX}
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        onClick={() => {
                                          tabonchangehandler("SmartX");
                                        }}
                                        style={{
                                          boxShadow: "1px 1px 2px gray",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <TbListDetails size={17} />
                                      </button>
                                    </li>
                                  </BootstrapTooltip>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          })}

                          <BootstrapTooltip title="Files" arrow placement="top">
                            <li className="nav-item" role="presentation">
                              <button
                                className={Files}
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  tabonchangehandler("Files");
                                }}
                                style={{
                                  boxShadow: "1px 1px 2px gray",
                                  fontWeight: 600,
                                }}
                              >
                                <AiOutlineFile size={17} />
                              </button>
                            </li>
                          </BootstrapTooltip>

                          {permission.map((module) => {
                            if (module.moduleName === "Chat") {
                              const value = module.screen.find(
                                (item) =>
                                  item.screenId === "TICKET" &&
                                  item.read &&
                                  item.write
                              );
                              if (value) {
                                return (
                                  <BootstrapTooltip
                                    title="Ticket"
                                    arrow
                                    placement="top"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className={Ticket}
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        onClick={() => {
                                          tabonchangehandler("Ticket");
                                        }}
                                        style={{
                                          boxShadow: "1px 1px 2px gray",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <HiOutlineTicket size={17} />
                                      </button>
                                    </li>
                                  </BootstrapTooltip>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          })}

                          {permission.map((module) => {
                            if (module.moduleName === "Chat") {
                              const value = module.screen.find(
                                (item) =>
                                  item.screenId === "SURVEY" &&
                                  item.read &&
                                  item.write
                              );
                              if (value) {
                                return (
                                  <BootstrapTooltip
                                    title="Survey"
                                    arrow
                                    placement="top"
                                  >
                                    <li
                                      className="nav-item"
                                      role="presentation"
                                    >
                                      <button
                                        className={survey}
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                        onClick={() => {
                                          tabonchangehandler("survey");
                                        }}
                                        style={{
                                          boxShadow: "1px 1px 2px gray",
                                          fontWeight: 600,
                                        }}
                                      >
                                        <RiSurveyLine size={17} />
                                      </button>
                                    </li>
                                  </BootstrapTooltip>
                                );
                              } else {
                                return <></>;
                              }
                            }
                          })}
                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                          <Interactiontab
                            InteractionHistorytab={InteractionHistorytab}
                            email={email}
                            phoneNumber={phoneNumber}
                            customerDetailSearch={true}
                          />
                          <SmartXTab SmartXTab={SmartXtab} />
                          <FilesTab
                            FilesTab={Filestab}
                            userId={userId}
                            customerDetailSearch={true}
                          />
                          <Tickethistory
                            TicketTab={TicketTab}
                            phone={phoneNumber}
                            email={email}
                            customerDetailSearch={true}
                          />
                          <SurveyTab surveyTab={surveyTab} />
                        </div>
                      </div>
                    </div>
                  </>
                </Stack>
              </Box>
            );
          default:
            return <></>;
        }
      })()}
    </Draggable>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setCustomerDetailsShow,
})(CustomerDetailSearch);
