import { Stack } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";

const EditCustomer = (props) => {
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [address, setaddress] = useState("");
  const [company, setcompany] = useState("");
  const [secondaryNumber, setSecondaryNumber] = useState("");

  const updateEditvalue = async (e) => {
    e.preventDefault();
    let body = {
      username: username,
      email: email,
      phonenumber: phonenumber,
      // whatsappnumber: secondaryNumber,
      address: address,
      company: company,
      id: props.selectedUser.id,
    };

    axios
      .post(BaseUrl + "/users/updateClient", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {})
      .catch((error) => {});
  };
  return (
    <nav
      className={props.showEditCustomer}
      style={{ top: "50px", width: "38%", right: "0px" }}
    >
      <div className="filter col-md-12 shadow p-4 ">
        <div className="d-flex align-items-center">
          <p className="mb-0">EDIT CUSTOMER</p>
          <div className="dismiss">
            <span
              id="session_filter_close_btn"
              className="material-symbols-outlined"
              onClick={() => {
                props.setsetshowEditCustomer("");
              }}
            >
              cancel
            </span>
          </div>
        </div>

        <div className="filter_form">
          <form
            className=" fillter_form2 mt-2"
            style={{ height: "68vh" }}
            onSubmit={(e) => {
              updateEditvalue(e);
            }}
            id="form1"
          >
            <div className="custom_div">
              <Stack sapcing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>UserName</label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      required
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control form-select-sm"
                      value={email}
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                  </div>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      className="form-control form-select-sm"
                      required
                      value={phonenumber}
                      onChange={(e) => {
                        setphonenumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      value={address}
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                    />
                  </div>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Secondary Number</label>
                    <input
                      type="number"
                      className="form-control form-select-sm"
                      value={secondaryNumber}
                      onChange={(e) => {
                        setSecondaryNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3 d-flex flex-column text-start">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control form-select-sm"
                      value={company}
                      onChange={(e) => {
                        setcompany(e.target.value);
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </div>
          </form>

          <div className="filter_submit">
            <div className="d-flex">
              <div className="col-md-12 ps-2">
                <button
                  type="submit"
                  form="form1"
                  value="Submit"
                  id="add_customer_Submit"
                  className="btn btn-primary w-100 text-white"
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default EditCustomer;
