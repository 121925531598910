import React, { useState, useEffect } from "react";
import { BaseUrl, excelDownloadUrl } from "../../container/BaseUrl/BaseUrl";
import FileSaver from "file-saver";
import axios from "axios";
import facebook from "../../assets/assets/images/facebook.png";
import whatsapp from "../../assets/assets/images/whatsapp.png";
import webchat from "../../assets/assets/images/chat-icon.png";
import twitter from "../../assets/assets/images/twitter.png";
import email from "../../assets/assets/images/email.png";
import voice from "../../assets/assets/images/voice.png";
import chatbot from "../../assets/assets/images/chatbot.png";
import videochat from "../../assets/assets/images/videochat.png";
import teams from "../../assets/assets/images/teams.png";
import { Badge } from "react-bootstrap";
import { GitPullRequest, XCircle } from "@phosphor-icons/react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Button,
  Drawer,
  IconButton,
  Pagination,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Chip, Stack } from "@mui/material";
import { Eye } from "@phosphor-icons/react";

// Component Imported
import ViewMoreDialogBox from "./ViewMoreDialogBox";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
}));

const DrawerContent = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100vh",
  width: "25vw",
  padding: "10px",
}));

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const SessionReportsNew = (props) => {
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const isToDateValid = () => {
    return new Date(props.todate) >= new Date(props.fromdate);
  };

  const tenantId = localStorage.getItem("TenantId");
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [status, setStatus] = useState("");
  const [agentid, setAgentid] = useState("");
  const [sessionreport, setSessionreport] = useState([]);
  const [agentlist, setAgentlist] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadingreports, setLoadingreports] = useState(false);

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));

  useEffect(() => {
    if (basedOnRole.role == "Supervisor") {
      agentListdropdown();
    }else{
      sessionReports();
    }
  }, [page]);



  const sessionReports = async () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const isCurrentDate = fromdate === currentDate && todate === currentDate;
    setLoadingreports(true);
    await axios
      .post(
        BaseUrl + "/reports/listDetails",
        {
          agent_id: basedOnRole.role == "Supervisor" ? agentid : basedOnRole.id,
          agent_supervisor: basedOnRole.id,
          status: status,
          from_date: fromdate ? fromdate : "",
          to_date: todate ? todate : "",
          limit: 10,
          offset: page == 1 ? 0 : (page - 1) * 10,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          setSessionreport(res.data.Data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingreports(false);
          }, 1000);
        } else {
          setSessionreport([]);
          setPagination(0);
          setTimeout(() => {
            setLoadingreports(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setShow(true);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const agentListdropdown = async () => {
    await axios
      .post(
        BaseUrl + "/users/listagentList",
        {
          user_id: basedOnRole.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        //
        //
        setAgentlist(res.data.data);
      })
      .catch((err) => {
        setShow(true);
      });
  };

  const DownloadSessionReports = () => {
    axios
      .post(
        BaseUrl + "/reports/listDetailsExcel",
        {
          agent_id: basedOnRole.role == "Supervisor" ? agentid : basedOnRole.id,
          agent_supervisor: basedOnRole.id,
          status: status,
          from_date: fromdate,
          to_date: todate,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        },
        {}
      )
      .then((res) => {
        if (res.data.status == true) {
          let file = excelDownloadUrl + res.data.message;
          FileSaver.saveAs(file);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {});
  };

  const [openDialogbox, setopenDialogbox] = useState(false);
  const [userdetails, setuserdetails] = useState();

  const handleClose2 = () => {
    setopenDialogbox(false);
  };

  const ViewMore = (id) => {
    if(!id)return
    axios
      .post(
        BaseUrl + "/reports/viewmore",
        {
          id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setuserdetails(res.data.Data[0]);
          setopenDialogbox(true);
        }
      })
      .catch((err) => {});
  };

  const [showDrawer, setshowDrawer] = useState({ right: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setshowDrawer({ ...showDrawer, [anchor]: open });
  };

  return (
    <>
      <StyledPaper elevation={0}>
        <Stack spacing={1}>
          <Stack className="d-flex flex-row gap-1" justifyContent={"end"}>
            <Button size="small" id="Download-btn" variant="contained">
              Download
            </Button>
            <Button
              size="small"
              id="Filter-btn"
              variant="contained"
              onClick={() => {
                setshowDrawer({ ...showDrawer, ["right"]: true });
              }}
            >
              Filter
            </Button>
          </Stack>
          <StyledPaper2 elevation={4}>
            <TableContainer sx={{ height: 400 }} className="CONTACT-LIST-AREA">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      { key: 1, label: "Customer Name", minWidth: 130 },
                      { key: 2, label: "Phone Number", minWidth: 130 },
                      { key: 3, label: "Email", minWidth: 130 },
                      { key: 4, label: "Channel", minWidth: 40 },
                      { key: 5, label: "Direction", minWidth: 40 },
                      { key: 6, label: "Status", minWidth: 40 },
                      { key: 7, label: "Skillset", minWidth: 40 },
                      { key: 8, label: "Language", minWidth: 40 },
                      { key: 9, label: "Agent Name", minWidth: 140 },
                      { key: 10, label: "Initiated At", minWidth: 100 },
                      { key: 11, label: "Started At", minWidth: 100 },
                      { key: 12, label: "Ended At", minWidth: 100 },
                      { key: 13, label: "Work code", minWidth: 100 },
                      { key: 14, label: "Action", minWidth: 40 },
                    ].map((item) => (
                      <StyledTableCell
                        style={{ minWidth: item.minWidth }}
                        key={item.key}
                      >
                        {item.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ position: "relative" }}>
                  {loadingreports ? (
                    [...Array(10)].map((_, rowIndex) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        {[...Array(14)].map((_, colIndex) => (
                          <StyledTableCell2 key={colIndex}>
                            <Skeleton variant="text" width={60} height={30} />
                          </StyledTableCell2>
                        ))}
                      </TableRow>
                    ))
                  ) : sessionreport.length > 0 ? (
                    sessionreport.map((rowData) => (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <StyledTableCell2>{rowData.name}</StyledTableCell2>
                        <StyledTableCell2>
                          {rowData.phone_number}
                        </StyledTableCell2>
                        <StyledTableCell2>{rowData.email}</StyledTableCell2>
                        <StyledTableCell2>
                          {rowData.channel == "webchat" ? (
                            <img
                              className="icons-size"
                              src={webchat}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_whatsapp" ? (
                            <img
                              className="icons-size"
                              src={whatsapp}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_twitter" ? (
                            <img
                              className="icons-size"
                              src={twitter}
                              alt="webchat"
                            />
                          ) : rowData.channel == "voice" ? (
                            <img
                              className="icons-size"
                              src={voice}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_facebook" ? (
                            (
                              <img
                                className="icons-size"
                                src={facebook}
                                alt="webchat"
                              />
                            ) || rowData.channel == "from_teams"
                          ) : (
                            <img
                              className="icons-size"
                              src={email}
                              alt="webchat"
                            />
                          )}
                        </StyledTableCell2>
                        <StyledTableCell2>{rowData.direction}</StyledTableCell2>
                        <StyledTableCell2>
                          {rowData.status == "chatEnded" ? (
                            <Badge variant="secondary">Completed</Badge>
                          ) : (
                            <Badge variant="success">Active </Badge>
                          )}
                        </StyledTableCell2>
                        <StyledTableCell2>{rowData.skillset}</StyledTableCell2>
                        <StyledTableCell2>{rowData.language}</StyledTableCell2>
                        <StyledTableCell2>
                          {rowData.agent_name ? rowData.agent_name : ""}
                        </StyledTableCell2>
                        <StyledTableCell2>
                          {moment(rowData.chat_arrival_at).format(
                            "L hh:mm:ss A"
                          )}
                        </StyledTableCell2>{" "}
                        <StyledTableCell2>
                          {moment(rowData.chat_started_at).format(
                            "L hh:mm:ss A"
                          )}
                        </StyledTableCell2>{" "}
                        <StyledTableCell2>
                          {rowData.chat_ended_at == null ||
                          rowData.chat_ended_at == undefined ||
                          rowData.chat_ended_at == ""
                            ? "--"
                            : moment(rowData.chat_ended_at).format(
                                "L hh:mm:ss A"
                              )}
                        </StyledTableCell2>{" "}
                        <StyledTableCell2>
                          {rowData.reason == null ||
                          rowData.reason == undefined ||
                          rowData.reason == ""
                            ? "--"
                            : rowData.reason}
                        </StyledTableCell2>{" "}
                        <StyledTableCell2>
                          <Stack direction={"row"} spacing={2}>
                            <Eye
                              weight="duotone"
                              size={22}
                              onClick={() => {
                                ViewMore(rowData._id);
                              }}
                              cursor={"pointer"}
                            />

                            {rowData.channel == "voice" ||
                            rowData.channel == "from_whatsapp" ? (
                              <GitPullRequest
                                size={20}
                                weight="bold"
                                cursor={"pointer"}
                                onClick={() => {
                                  ChatPull(rowData);
                                }}
                              />
                            ) : null}
                          </Stack>
                        </StyledTableCell2>{" "}
                      </TableRow>
                    ))
                  ) : (
                    <Box
                      sx={{
                        position: "fixed",
                        width: 1210,
                        height: 300,
                      }}
                    >
                      <Stack
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        No Data Found
                      </Stack>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper2>
          <Stack className="d-flex flex-row" justifyContent={"end"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              color="primary"
              size="small"
              count={Math.ceil(pagination / 10)}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </Stack>
      </StyledPaper>

      <ViewMoreDialogBox
        openDialogbox={openDialogbox}
        handleClose={handleClose2}
        data={userdetails}
      />

      <SwipeableDrawer
        anchor={"right"}
        open={showDrawer.right}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <DrawerContent>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Stack sx={{ width: "100%", height: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "6%",
                  borderBottom: "2px solid #b1b1b1ab",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", height: "100%" }}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{ fontWeight: 600, color: "#1976d2", fontSize: "18px" }}
                  >
                    FILTER
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setshowDrawer({ ...showDrawer, ["right"]: false });
                    }}
                  >
                    <XCircle size={22} />
                  </IconButton>
                </Stack>
              </Box>
              <Box sx={{ width: "100%", height: "88%" }} p={1.5}>
                <Stack sx={{ width: "100%", height: "100%" }} spacing={2}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        FROM
                      </Typography>
                      <input
                        type="date"
                        className="form-control"
                        id=""
                        aria-describedby="date"
                        value={fromdate}
                        onChange={(e) => setFromdate(e.target.value)}
                        max={defaultValue}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        TO
                      </Typography>
                      <input
                        type="date"
                        className="form-control"
                        id=""
                        aria-describedby="date"
                        value={props.todate}
                        onChange={(e) => setTodate(e.target.value)}
                        max={defaultValue}
                      />
                      {!isToDateValid() && (
                        <span
                          className="text-danger fw-bold"
                          style={{ fontSize: "10px" }}
                        >
                          To Date cannot be less than From Date*
                        </span>
                      )}
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        STATUS
                      </Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="Accept">Chat Accepted</option>
                        <option value="chatEnded">Chat Ended</option>
                        <option value="queued">Queued</option>
                        <option value="customerdisconnect">
                          Customer Disconnected
                        </option>
                      </select>
                    </Stack>
                  </Box>
                  {basedOnRole.role == "Supervisor" && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "max-content",
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          sx={{
                            fontSize: "13px",
                          }}
                        >
                          AGENT NAME :
                        </Typography>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setAgentid(e.target.value)}
                        >
                          <option value="">Select Agent Name</option>
                          {agentlist.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.username}
                              </option>
                            );
                          })}
                        </select>
                      </Stack>
                    </Box>
                  )}
                </Stack>
              </Box>
              <Box sx={{ width: "100%", height: "6%" }}>
                <Button
                  size="small"
                  id="submit-btn"
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    if (isToDateValid()) {
                      setPage(1);
                      sessionReports();
                      setshowfilter("sidebar_filter");
                    }
                  }}
                >
                  submit
                </Button>
              </Box>
            </Stack>
          </Box>
        </DrawerContent>
      </SwipeableDrawer>
    </>
  );
};

export default SessionReportsNew;
