export const BaseUrl = "https://gateway.inaipi.ae/v1";
export const excelDownloadUrl = "https://dcc.inaipi.ae/files";
export const ticketUrl = "https://gway.release.inaipi.ae/ticketing";
export const errorApi = "https://gway.release.inaipi.ae/logs/v1";
export const frontendBaseurl = "https://dcc.inaipi.ae";
export const SocketUrl = "https://office.edayaapp.com";
export const AvcUrl = "https://gway.release.inaipi.ae/voice/voicechat";
export const CoBroswseUrl =
  "https://surfly.com/v2/sessions/?api_key=3ea557f1ea1a4814ae0184a5dafb121b";
export const newBaseUrlLang = "https://gateway.inaipi.ae/";
export const TenantID = "123456";
export const fileservice = "https://qacc.inaipi.ae/v1";
export const emailservice = "https://gway.release.inaipi.ae/routeemail/";
export const baseUrlEmail = "https://gway.release.inaipi.ae/routeemail/";
export const BaseUrlSurvey = "https://cms.inaipiapp.com/DccSurvey/public/api";
export const BaseUrlReports = "https://gway.release.inaipi.ae/ccreport/";

