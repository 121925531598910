import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import "./contact.css";
import axios from "axios";
import { connect } from "react-redux";
import { setDialedNumberInc } from "../../redux/actions/actions";
import { AvcUrl, BaseUrl } from "../../container/BaseUrl/BaseUrl";
import {
  Stack,
  Button,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Pagination,
  Paper,
} from "@mui/material";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import NoSelectContact from "../../assets/svgtopng/Screenshot.png";
import { toast } from "react-toastify";
import { randomColors } from "../../components/chat-components/colorpallet";
import { useNavigate } from "react-router-dom";
import TicketTable from "./TicketTable";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
  marginLeft:'45px'
}));

const mapStateToProps = (state) => {
  return { skillset: state.data.skillset };
};
const Contacts = () => {
  const navigate = useNavigate();
  const [searchvalue, setsearchvalue] = useState("");
  const [contactList, setContactList] = useState([]);
  const [showAddCustomer, setshowAddCustomer] = useState("");
  const [showEditCustomer, setsetshowEditCustomer] = useState("");
  const [selectedUser, setselectedUser] = useState([]);
  const [selectedIndex, setselectedIndex] = useState();
  const [showTab, setshowTab] = useState("Overview");
  const [showUserContactDetail, setshowUserContactDetail] = useState(false);
  const [deleteModalDialog, setDeleteModalDialog] = useState(false);

  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const DeleteDialogClose = () => {
    setDeleteModalDialog(false);
  };
  useEffect(() => {
    listContact();
  }, [page]);

  const listContact = () => {
    axios
      .post(
        BaseUrl + "/users/listcustomer",
        { offset: page == 1 ? 0 : (page - 1) * 7, limit: 7 },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setContactList(res.data.data);
          setPagination(res.data.count);
        }
      })
      .catch((err) => {});
  };

  const makeCall = (calledNo, type, id) => {
    localStorage.setItem("callActivate", "true");
    localStorage.setItem("CallMadeFromDcc", "true");
    let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");
    props.setDialedNumberInc(calledNo);
    localStorage.setItem("ClickeToCallPhoneNum", calledNo);
    localStorage.setItem("dailedNumber", calledNo);
    axios
      .post(
        AvcUrl +
          `/voice/terminal/${agentLoginTerminalId}/createContact/sip:${agentLoginTerminalId}@demoaccs.com/sip:${calledNo}@${localStorage.getItem(
            "AvayaDomain"
          )}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 500) {
          toast.warn(res.data.status, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else if (res.data.status == "OK") {
          localStorage.setItem("type", type);
          localStorage.setItem("ContactVoiceId", id);
          localStorage.setItem("ClickToCall", "true");
        }
      })
      .catch((err) => {});
  };
  const ClickToWhatsapp = (phonenumber, username, email) => {
    axios
      .post(
        BaseUrl + "/users/initatewhatsapp",
        {
          phonenumber: phonenumber,
          customername: username,
          agent_email: email,
          type: "whatsapp",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        navigate("/main/Chat");
      })
      .catch((err) => {});
  };

  const CrmPopup = (phonenumber, userid) => {
    axios
      .post(
        BaseUrl + "/ticket/crmscreenpop",
        {
          phonenumber: phonenumber,
          skillset: "English",
          channel: "webchat",
          callid: userid,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        window.open(res.data.data);
      })
      .catch((err) => {});
  };

  return (
    <>
      <StyledPaper elevation={0}>
        <Stack
          direction="row"
          justifyContent="end"
          sx={{ paddingRight: "11px" }}
        >
          <Button
            size="small"
            variant="contained"
            startIcon={<PersonIcon />}
            onClick={() => {
              setshowAddCustomer("sidebar_filter active-r");
            }}
          >
            Add Customer
          </Button>
        </Stack>
        <div className="container-fluid">
          <div className="">
            <div className="sub_wapper">
              <div className="container-fluid card mt-1 border-0 fixed-table-card vh-85">
                <div className="row classic-view">
                  <div className="col-3 customer_main newcust-main pe-0 ps-0">
                    <div className="cust_hed-new pt-1 pb-5 border-end">
                      <div className="cont-search p-2  mb-1 border-bottom">
                        <div className="nav-item w-100 min-33h ">
                          <form className="rounded position-relative">
                            <input
                              autocomplete="off"
                              className="rounded-0 form-control fs-14 ps-5 bg-light"
                              type="search"
                              name="content"
                              placeholder="Search Customer"
                              aria-label="Search"
                              onChange={(e) => {
                                setsearchvalue(e.target.value);
                              }}
                            />
                            <button
                              className="d-flex-p btn bg-transparent px-2 py-0 position-absolute top-50 start-0 min-33h border-0 translate-middle-y"
                              type="submit"
                            >
                              <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                          </form>
                        </div>
                      </div>

                      <div className="contacts-list mt-1 p-1">
                        {contactList.length > 0
                          ? contactList
                              .filter((value) => {
                                return value.username
                                  .toLowerCase()
                                  .includes(searchvalue.toLowerCase());
                              })

                              .map((user, index) => {
                                return (
                                  <div
                                    key={user.id}
                                    className={
                                      selectedUser.id == user.id
                                        ? "contact active"
                                        : "contact"
                                    }
                                    onClick={() => {
                                      setselectedUser(user);
                                      setshowUserContactDetail(true);
                                      setselectedIndex(index);
                                    }}
                                  >
                                    <div className="d-flex p-1 justify-content-start align-items-center">
                                      <div
                                        className="animate__animated animate__slideInUp ticket_round rounded-circle me-3"
                                        style={{
                                          backgroundColor: randomColors[index],
                                        }}
                                      >
                                        {user.username
                                          .slice(0, 2)
                                          .toUpperCase()}
                                      </div>
                                      <div className="animate__animated animate__slideInUp">
                                        <div className="cont-name">
                                          {user.username.toUpperCase()}
                                        </div>
                                        <div className="text-muted cont-email">
                                          <i className="fa-solid fa-phone me-1 fs-10"></i>
                                          {user.phonenumber}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                          : "No Contact"}
                      </div>
                      <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
                        <Pagination
                          count={Math.ceil(pagination / 7)}
                          page={page}
                          onChange={handleChange}
                          hidePrevButton
                          hideNextButton
                          color="primary"
                        />
                      </div>
                    </div>
                  </div>

                  {showUserContactDetail ? (
                    <div className="col-9 px-0">
                      <div className="cont-details-main">
                        <div className="cont-details-hed border-bottom py-0">
                          <div className="d-flex p-1 justify-content-start align-items-center">
                            <div>
                              <div
                                className="ticket_round rounded-circle me-3"
                                style={{
                                  backgroundColor: randomColors[selectedIndex],
                                  fontSize: "18px",
                                }}
                              >
                                {selectedUser.username
                                  .slice(0, 2)
                                  .toUpperCase()}
                              </div>
                            </div>
                            <div className="cont-details-n cont-details-hed w-100">
                              <div className="d-flex justify-content-between">
                                <div className="fw-bold cont-name">
                                  {selectedUser.username.toUpperCase()}
                                </div>

                                <div className="r_action">
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-select btn-sm round-0"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      data-bs-title="Edit"
                                      style={{
                                        borderRadius: "8px",
                                        backgroundColor: "#1473E6",
                                        color: "#FFFF",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.4) -6px 8px 6px -3px inset",
                                      }}
                                      onClick={() => {
                                        makeCall(selectedUser.phonenumber);
                                      }}
                                    >
                                      <i className="fa-solid fa-phone"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-outline-select btn-sm round-0 mx-1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      data-bs-title="Edit"
                                      onClick={() => {
                                        ClickToWhatsapp(
                                          selectedUser.phonenumber,
                                          selectedUser.username,
                                          selectedUser.email
                                        );
                                      }}
                                      style={{
                                        borderRadius: "8px",
                                        backgroundColor: "#25D366",
                                        color: "#FFFF",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.4) -6px 8px 6px -3px inset",
                                      }}
                                    >
                                      <i className="fa-brands fa-whatsapp"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-outline-select btn-sm round-0"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      data-bs-title="Edit"
                                      onClick={() => {
                                        CrmPopup(
                                          selectedUser.phonenumber,
                                          selectedUser.id
                                        );
                                      }}
                                      style={{
                                        borderRadius: "8px",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.4) -6px 8px 6px -3px inset",
                                      }}
                                    >
                                      <i className="fa-regular fa-circle-up"></i>
                                    </button>

                                    <button
                                      type="button"
                                      className="btn btn-outline-select btn-sm round-0 mx-1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      data-bs-title="Edit"
                                      onClick={() => {
                                        setsetshowEditCustomer(
                                          "sidebar_filter active-r"
                                        );
                                      }}
                                      style={{
                                        borderRadius: "8px",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.4) -6px 8px 6px -3px inset",
                                      }}
                                    >
                                      <i className="fa-solid fa-pen-to-square"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex">
                                <div className="text-muted cont-email me-3 fs-13">
                                  <i className="fa-solid fa-envelope me-2 text-muted"></i>
                                  {selectedUser.email}
                                </div>
                                <div className="text-muted cont-email fs-13">
                                  <i className="fa-solid fa-phone me-2"></i>
                                  {selectedUser.phonenumber}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cont-detail-sub p-3">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="rounded-0 nav-link"
                                style={{
                                  backgroundColor:
                                    showTab == "Overview"
                                      ? randomColors[selectedIndex]
                                      : "",
                                  color:
                                    showTab == "Overview" ? "#ffff" : "#1976D2",
                                }}
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  setshowTab("Overview");
                                }}
                              >
                                Overview
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="rounded-0 nav-link"
                                style={{
                                  backgroundColor:
                                    showTab == "Ticket"
                                      ? randomColors[selectedIndex]
                                      : "",
                                  color:
                                    showTab == "Ticket" ? "#ffff" : "#1976D2",
                                }}
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                aria-controls="pills-profile"
                                type="button"
                                role="tab"
                                aria-selected="false"
                                onClick={() => {
                                  setshowTab("Ticket");
                                }}
                              >
                                Ticket details
                              </button>
                            </li>
                          </ul>

                          <div className="tab-content" id="pills-tabContent">
                            {(() => {
                              switch (showTab) {
                                case "Overview":
                                  return (
                                    <div
                                      className="tab-pane fade show active"
                                      id="pills-home"
                                      role="tabpanel"
                                      aria-labelledby="pills-home-tab"
                                    >
                                      <div className="cust_view_modal">
                                        <div className="row row-cols-3 p-3">
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-user color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                First Name
                                              </p>
                                              <p className="text-break mb-0">
                                                {selectedUser.username}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-mobile color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                Mobile Number
                                              </p>
                                              <p className="text-break mb-0">
                                                {selectedUser.phonenumber}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-earth-americas color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                Country
                                              </p>
                                              <p className="text-break mb-0">
                                                {selectedUser.address}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-phone  color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                Secondary Number
                                              </p>
                                              <p className="text-break mb-0">
                                                {"-"}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-envelope color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                Email
                                              </p>
                                              <p className="text-break mb-0">
                                                {selectedUser.email}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="mb-3 col d-flex align-items-center justify-content-start animate__animated animate__slideInUp">
                                            <div className="cust_icon col-3 bg-white">
                                              <i className="fa-solid fa-address-book  color_b"></i>
                                            </div>
                                            <div className="cust_details_new ps-3 col-9">
                                              <p className="form-label small text-break">
                                                Customer Address
                                              </p>
                                              <p className="text-break mb-0">
                                                {selectedUser.address}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                case "Ticket":
                                  return <TicketTable />;
                                default:
                                  return <></>;
                              }
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-9 px-0">
                        <div
                          className="cont-details-main"
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            src={NoSelectContact}
                            alt="pic"
                            style={{ width: "450px" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledPaper>

      <AddCustomer
        showAddCustomer={showAddCustomer}
        setshowAddCustomer={setshowAddCustomer}
        listContact={listContact}
      />
      <EditCustomer
        showEditCustomer={showEditCustomer}
        setsetshowEditCustomer={setsetshowEditCustomer}
        listContact={listContact}
        selectedUser={selectedUser}
      />

      <Dialog
        open={deleteModalDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={DeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          CONFERMATION
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="subtitle1">
              Are You Sure You Want To Delete This Contact
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small">
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, { setDialedNumberInc })(Contacts);
