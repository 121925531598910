import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import "../../assets/assets/assets_newchat/library/bootstrap-5.1.3/css/bootstrap.min.css";
import "../../assets/assets/assets_newchat/assets/fonts/fontawesome-free-6.1.1-web/css/all.css";
import "../../assets/assets/assets_newchat/library/animate/animate.min.css";
import "../../assets/assets/assets_newchat/library/slimselect/slimselect.min.css";
import MultimediaFilter from "../../components/multimediaComponent/MultimediaFilter";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import facebook from "../../assets/assets/images/facebook.png";
import whatsapp from "../../assets/assets/images/whatsapp.png";
import webchat from "../../assets/assets/images/chat-icon.png";
import twitter from "../../assets/assets/images/twitter.png";
import email from "../../assets/assets/images/email.png";
import voice from "../../assets/assets/images/voice.png";
import "./multimedia.css";
import MultimediaFilterButton from "../../components/agent-report-component/multimediaFilterButton";
import { useNavigate } from "react-router-dom";

import {
  setPullContact,
  setChatRefresh,
  setsidebarVal,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Drawer,
  IconButton,
  Pagination,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Chip, Stack } from "@mui/material";
import { Eye, XCircle } from "@phosphor-icons/react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
}));

const DrawerContent = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100vh",
  width: "25vw",
  padding: "10px",
}));

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const mapStateToProps = (state) => {
  return {
    pullcontact: state.data.pullcontact,
  };
};

const MultimediaSearchNew = (props) => {
  const navigate = useNavigate();
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  var agentRole = JSON.parse(localStorage.getItem("tokenAgent"));
  var roleOfUser = agentRole.role;
  const [showfilter, setshowfilter] = useState("sidebar_filter");
  const [mediaSearchDatas, setMediaSearchDatas] = useState([]);
  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [name, setName] = useState("");
  const [emails, setEmail] = useState("");
  const [searchbysubject, setsearchBySubject] = useState("");
  const [searchbybody, setsearchByBody] = useState("");
  const [channel, setChannel] = useState("webchat");
  const [status, setStatus] = useState("");
  const [agent, seAagent] = useState("");

  const [skillSet, setSkillset] = useState("");
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMultimedia, setloadingMultimedia] = useState(false);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const multiMediaSearch = () => {
    setloadingMultimedia(true);
    const requestData = {
      customerName: name ? name : "",
      email: emails ? emails : "",
      fromDate: fromdate ? fromdate : defaultValue,
      toDate: todate ? todate : defaultValue,
      category: skillSet ? skillSet : "",
      limit: 10,
      offset: page == 1 ? 0 : (page - 1) * 10,
      subject: searchbysubject ? searchbysubject : "",
      email_body: searchbybody ? searchbybody : "",
      agent: roleOfUser == "Supervisor" ? agent : agentRole.agent_id,
      channel: channel,
      status: status,
    };

    const headers = {
      "Content-Type": "application/json",
      Tenantid: localStorage.getItem("TenantId"),
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    axios
      .post(BaseUrl + "/reports/multiMediaSearch", requestData, { headers })
      .then((response) => {
        console.log("multiMediaSearch", response);
        if (response.data.status == true) {
          setMediaSearchDatas(response.data.Data);
          setPagination(response.data.count);
          setTimeout(() => {
            setloadingMultimedia(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setMediaSearchDatas([]);
          setTimeout(() => {
            setloadingMultimedia(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const PullContactApi = (item) => {
    const tokenAgentValue = JSON.parse(localStorage.getItem("tokenAgent"));
    const clientValue = JSON.parse(localStorage.getItem("client"));
    const requestData = {
      chat_session_id: item.chat_session_id,
      agent_id: tokenAgentValue.agent_id,
      user_id: tokenAgentValue.user_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Tenantid: localStorage.getItem("TenantId"),
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    axios
      .post(BaseUrl + "/email/emailpullcontact", requestData, { headers })
      .then((response) => {
        if (response.status) {
          toast.success("Email Pull Successfully", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            let permission = JSON.parse(localStorage.getItem("permission"));
            props.setsidebarVal("Chat");
            props.setChatRefresh(true);
            navigate("/main/Chat");
            permission.find((element, index) => {
              if (element.moduleName == "Chat")
                localStorage.setItem("indexOf", index);
            });
          }, 2000);
        } else {
          props.setPullContact(false);
        }
        //
        // multiMediaSearch()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    multiMediaSearch();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const [showDrawer, setshowDrawer] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setshowDrawer({ ...showDrawer, [anchor]: open });
  };
  return (
    <>
      <StyledPaper elevation={0}>
        <Stack spacing={1}>
          <Stack className="d-flex flex-row gap-1" justifyContent={"end"}>
            <Button
              size="small"
              id="Filter-btn"
              variant="contained"
              onClick={() => {
                setshowDrawer({ ...showDrawer, ["right"]: true });
              }}
            >
              Filter
            </Button>
          </Stack>
          <StyledPaper2 elevation={4}>
            <TableContainer sx={{ height: 400 }} className="CONTACT-LIST-AREA">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      { label: "Customer Name", minWidth: 130 },
                      { label: "Phone Number", minWidth: 130 },
                      { label: "Email", minWidth: 130 },
                      { label: "Channel", minWidth: 40 },
                      { label: "Status", minWidth: 40 },
                      { label: "Skillset", minWidth: 40 },
                      { label: "Language", minWidth: 40 },
                      { label: "Action", minWidth: 40 },
                    ].map((item) => (
                      <StyledTableCell>{item.label}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingMultimedia
                    ? [...Array(10)].map((_, rowIndex) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          {[...Array(8)].map((_, colIndex) => (
                            <StyledTableCell2 key={colIndex}>
                              <Skeleton variant="text" width={80} height={30} />
                            </StyledTableCell2>
                          ))}
                        </TableRow>
                      ))
                    : Array.isArray(mediaSearchDatas) &&
                      mediaSearchDatas.length > 0
                    ? mediaSearchDatas.map((rowData) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowData._id}
                        >
                          <StyledTableCell2>
                            {rowData.unique_id
                              ? rowData.unique_id.username
                              : ""}
                          </StyledTableCell2>
                          <StyledTableCell2>{""}</StyledTableCell2>
                          <StyledTableCell2>{rowData.email}</StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.channel == "webchat" ? (
                              <img
                                className="icons-size"
                                src={webchat}
                                alt="webchat"
                              />
                            ) : rowData.channel == "from_whatsapp" ? (
                              <img
                                className="icons-size"
                                src={whatsapp}
                                alt="webchat"
                              />
                            ) : rowData.channel == "from_twitter" ? (
                              <img
                                className="icons-size"
                                src={twitter}
                                alt="webchat"
                              />
                            ) : rowData.channel == "voice" ? (
                              <img
                                className="icons-size"
                                src={voice}
                                alt="webchat"
                              />
                            ) : rowData.channel == "from_facebook" ? (
                              (
                                <img
                                  className="icons-size"
                                  src={facebook}
                                  alt="webchat"
                                />
                              ) || rowData.channel == "from_teams"
                            ) : (
                              <img
                                className="icons-size"
                                src={email}
                                alt="webchat"
                              />
                            )}
                          </StyledTableCell2>
                          <StyledTableCell2>{rowData.status}</StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.skillset}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.language}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.channel == "email" ? (
                              <BootstrapTooltip
                                title={"Pull"}
                                arrow
                                placement="top"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  id="contact-mail"
                                  onClick={() => {
                                    PullContactApi(rowData);
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-code-pull-request"
                                    color="blue"
                                  ></i>
                                </button>
                              </BootstrapTooltip>
                            ) : (
                              ""
                            )}
                          </StyledTableCell2>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper2>
          <Stack className="d-flex flex-row" justifyContent={"end"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              color="primary"
              size="small"
              count={Math.ceil(pagination / 10)}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </Stack>
      </StyledPaper>

      <SwipeableDrawer
        anchor={"right"}
        open={showDrawer.right}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <DrawerContent>
          <Box sx={{ width: "100%", height: "100%" }}>
            <Stack sx={{ width: "100%", height: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "6%",
                  borderBottom: "2px solid #b1b1b1ab",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{ width: "100%", height: "100%" }}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{ fontWeight: 600, color: "#1976d2", fontSize: "18px" }}
                  >
                    FILTER
                  </Typography>
                  <IconButton>
                    <XCircle size={22} />
                  </IconButton>
                </Stack>
              </Box>
              <Box
                className="CONTACT-LIST-AREA "
                sx={{ width: "100%", height: "88%", overflowY: "scroll" }}
                p={2}
              >
                <Stack sx={{ width: "100%", height: "100%" }} spacing={2}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        NAME
                      </Typography>
                      <input
                        type="text"
                        className="form-control form-select-sm"
                        // value={props.name}
                        // onChange={(e) => props.setName(e.target.value)}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        EMAIL
                      </Typography>
                      <input
                        type="email"
                        className="form-control form-select-sm"
                        // value={props.name}
                        // onChange={(e) => props.setName(e.target.value)}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        FROM
                      </Typography>
                      <input
                        type="date"
                        className="form-control"
                        id=""
                        aria-describedby="date"
                        // value={props.fromdate}
                        // onChange={(e) => props.setFromdate(e.target.value)}
                        // max={defaultValue}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        TO
                      </Typography>
                      <input
                        type="date"
                        className="form-control"
                        id=""
                        aria-describedby="date"
                        // value={props.fromdate}
                        // onChange={(e) => props.setFromdate(e.target.value)}
                        // max={defaultValue}
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        CHANNEL
                      </Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // onChange={(e) => props.setStatus(e.target.value)}
                      >
                        <option value="">All Channel</option>
                        <option value="webchat">Webchat</option>
                        <option value="email">Email</option>
                        <option value="videochat">Videochat</option>
                        <option value="voice">Voice</option>
                        <option value="from_whatsapp">Whatsapp</option>
                      </select>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        CATEGORY
                      </Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // onChange={(e) => props.setStatus(e.target.value)}
                      >
                        <option value="">Select Skillset</option>
                        <option value="Toner Issue">Toner Issue</option>
                        <option value="Network Problems">
                          Network Problems
                        </option>
                        <option value="Network Resolved">
                          Network Resolved
                        </option>
                        <option value="Password Reset"> Password Reset</option>
                        <option value="Software Installation">
                          Software Installation
                        </option>
                        <option value="Hardware Issues">Hardware Issues</option>
                        <option value="Communication">Communication</option>
                        <option value="Customer-care">CustomerCare</option>
                      </select>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        STATUS
                      </Typography>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // onChange={(e) => props.setStatus(e.target.value)}
                      >
                        <option value="">Status</option>
                        <option value="Accept">Accept</option>
                        <option value="chatEnded"> ChatEnded</option>
                        <option value="newjoin"> New Join</option>
                      </select>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "max-content",
                    }}
                  >
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                        }}
                      >
                        SEARCH
                      </Typography>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter Subject..."
                      />
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search By Keywords..."
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box className="mt-3" sx={{ width: "100%", height: "6%" }}>
                <Button
                  size="small"
                  id="submit-btn"
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                >
                  submit
                </Button>
              </Box>
            </Stack>
          </Box>
        </DrawerContent>
      </SwipeableDrawer>
    </>
  );
};

export default connect(mapStateToProps, {
  setPullContact,
  setChatRefresh,
  setsidebarVal,
})(MultimediaSearchNew);
