import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CSpinner } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { BaseUrl, emailservice } from "../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import { MdChat } from "react-icons/md";
import {
  setselectedusername,
  setInternalChatActive,
  setselectedemail,
  setselectedmobile,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setselecteduserimage,
  setchatdata,
  setchattype,
  setchatid,
  setchatduration,
  setrefreshtogglechat,
  setonloadContactRefresh,
  setconfchatrefresh,
  setinternalexternal,
  settogglechat,
  setinternalchatnotify,
  setinternalchatrefresh,
  setSelectedColor,
  setloadmore,
  setAddTopicInput,
  setAddTopicToggle,
  setExternalChatData,
  setSelectedchanneldata,
  setUpdatechanneldata,
  setChatSpinner,
  setInternalChatData,
  setshowExternalChat,
  setshowInternalChat,
  setSearchterm,
  setChatRefresh,
  setShowCard,
  setAcceptChatActive,
  setUserChatID,
  setseletedVoiceSessionId,
  setAgentList,
  setcontactlist,
  setExternalChatActive,
  setPullContact,
  setLoadmoreSpinner,
  setCustomerDetailsShow,
  setCannedmessagediv,
  closeNotify,
  setUserlistShow,
  setSelectedContactID,
  setSelectedvip,
  setValue,
  setIndex,
  setIndex2,
} from "../../../redux/actions/actions";
import axios from "axios";

// Component
import ExternalContactList from "./Contact-list-child-component/External-ContactList.component";
import InternalAgentList from "./Contact-list-child-component/Internal-AgentList.component";
import ChatComponent from "../Chat-component/Chat.component";
import { toast } from "react-toastify";
import { Grid, Stack } from "@mui/material";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    notification: state.notification.notification,
    selectedusername: data.selectedusername,
    pullcontact: state.data.pullcontact,
    chatduration: data.chatduration,
    chatid: data.chatid,
    chat: data.chat,
    onloadContactRefresh: data.onloadContactRefresh,
    confchatrefresh: data.confchatrefresh,
    internalExternal: data.internalExternal,
    agentList: data.agentList,
    internalchatrefresh: data.internalchatrefresh,
    internalchatnotify: data.internalchatnotify,
    usercolor: state.data.usercolor,
    loadmoretoggle: state.data.loadmoretoggle,
    externalChatData: state.data.externalChatData,
    refreshContactlist: state.data.refreshContactlist,
    internalChatData: state.data.internalChatData,
    showExternalChat: state.data.showExternalChat,
    showInternalChat: state.data.showInternalChat,
    searchterm: state.data.searchterm,
    chatRefresh: state.data.chatRefresh,
    acceptChatActive: state.data.acceptChatActive,
    contactList: state.contactListData.contactList,
    agentList: state.data.agentList,
    selectedisvip: data.selectedisvip,
    value: data.value,
  };
};

const Contactlist = (props) => {
  const [internalspinner, setInternalspinner] = useState(false);
  let permission = JSON.parse(localStorage.getItem("permission"));

  const navigate = useNavigate();
  const [oldId, setOldId] = useState("");
  const [agentId, setAgentId] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("tokenAgent")) {
      navigate("/");
    } else {
      getAllInternalAgents();
    }
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("tokenAgent")) {
      navigate("/");
    } else {
      getAllInternalAgents();
    }
  }, []);

  useEffect(() => {
    if (props.chatRefresh == true) {
      getDataOnload();
    }
  }, [props.chatRefresh]);

  useEffect(() => {
    if (props.searchterm === "") {
      getAllInternalAgents();
    }
  }, [props.searchterm]);

  useEffect(() => {
    if (props.acceptChatActive === true) {
      if (props.externalChatData.length === undefined) {
        onclickhandlechangeexternalContact(props.externalChatData);
      }
    }
  }, [props.acceptChatActive]);

  const getDataOnload = async () => {
    props.setChatRefresh(false);
    try {
      const datas = JSON.parse(localStorage.getItem("tokenAgent"));
      setAgentId(datas.id);
      await axios
        .post(
          `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            props.setcontactlist(res.data);
            props.setExternalChatData(res.data[0]);
            props.setAcceptChatActive(true);
          } else {
            props.setcontactlist([]);
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const updateCount = (chat_session_id) => {
    const newcontactList = props.contactList;
    const newcontactlist1 = [];
    newcontactList.map((contact) => {
      if (contact.chat_session_id === chat_session_id) {
        contact.unreadcount = 0;
      }
      newcontactlist1.push(contact);
    });

    props.setcontactlist(newcontactlist1);
  };

  const updateCount1 = (chat_session_id) => {
    props.agentList.map((contact) => {
      if (contact.chat_session_id === chat_session_id) {
        contact.unreadcount = 0;
      }
    });
  };

  const getAllInternalAgents = async (val) => {
    setInternalspinner(true);
    props.setinternalchatrefresh(false);
    const datas = JSON.parse(localStorage.getItem("tokenAgent"));
    await axios
      .post(
        BaseUrl + `/users/allInternalUsers?agent_id=${datas.id}`,
        {
          create: val,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          props.setAgentList(res.data.data);
          setInternalspinner(false);
          props.setChatSpinner(false);
          props.setinternalchatrefresh(false);
        } else {
          setInternalspinner(false);
          props.setAgentList([]);
          props.setChatSpinner(false);
          props.setinternalchatrefresh(false);
        }
      })
      .catch((err) => {});
  };
  const SearchInternalAgent = async (val) => {
    props.setinternalchatrefresh(false);
    const datas = JSON.parse(localStorage.getItem("tokenAgent"));
    await axios
      .post(
        BaseUrl + `/users/allInternalUsersListBysearch?agent_id=${datas.id}`,
        {
          username: val,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const newData = res.data.data;
          const currentState = props.agentList;

          const existingUsernames = new Set(
            currentState.map((item) => item.username)
          );
          const uniqueData = newData.filter(
            (item) => !existingUsernames.has(item.username)
          );

          props.setAgentList([...currentState, ...uniqueData]);
        } else {
        }
      })
      .catch((err) => {});
  };
  const createInternalChat = async (UserId) => {
    const getAgent = await JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      await axios
        .post(
          `${BaseUrl}/users/createInternalAgentsSession`,
          {
            userId: UserId,
            agentId: getAgent.id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            props.setInternalChatActive(res.data.session.id);
            let details = res.data.session;
            props.setUserChatID(details.chat_session_id);
            let arr = {
              _id: details.id,
              id: details.id,
              phonenumber: details.phonenumber,
              email: details.email,
              channel: details.channel,
              chat_type: "internal",
              chat_session_id: details.chat_session_id,
              user_id: details.user_id,
              agent: details.agent,
              reciverDetails: [
                {
                  _id: details.receiver_id,
                  username: "",
                },
              ],
              senderDetails: [
                {
                  _id: details.sender_id,
                  username: "",
                },
              ],
            };
          }
        });
    } catch (error) {}
  };
  const fetchSingleEmail = () => {
    const clientJSON = localStorage.getItem("client");
    const clientObj = JSON.parse(clientJSON);
    axios
      .post(
        emailservice + "email/fetchEmailNew",
        {
          ticketID: clientObj.ticketID,
          agentID: clientObj.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          console.log("fetchEmailNewfetchEmailNewfetchEmailNew", response);
          props.setSelectedchanneldata(response.data.Data);
          props.setUpdatechanneldata(response.data.EmailBody);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onclickhandlechangeexternalContact = (contact, index) => {
    props.setIndex(index);
    props.closeNotify(contact.id, props.notification);
    if (contact.unreadcount != 0) {
      updateCount(contact.chat_session_id);
    }
    props.setExternalChatActive(contact.id);
    props.setseletedVoiceSessionId(contact.chat_session_id);
    props.setUserChatID(contact.chat_session_id);
    props.setChatSpinner(true);
    if (props.acceptChatActive === true) {
      props.setAcceptChatActive(false);
    }

    props.setShowCard(false);
    props.setChatRefresh(false);
    localStorage.setItem("client", JSON.stringify(contact));
    if (contact.channel == "email") {
      props.setExternalChatActive(contact.id);
      props.setselectedemail(contact.email);
      fetchSingleEmail();
    }
    props.setshowExternalChat(true);
    props.setExternalChatData(contact);
    setOldId(contact.id);
    props.setchatid(contact.chat_session_id);
    props.setselectedusername(
      contact.unique_id.username == ""
        ? contact.unique_id.phonenumber
        : contact.unique_id.username
    );
    props.setSelectedvip(contact.unique_id ? contact.unique_id.isvip : false);
    props.setchatdata(contact);
    props.setchattype("WEBCHAT");
    props.setselectedmobile(
      contact.unique_id.phonenumber == ""
        ? "Empty"
        : contact.unique_id.phonenumber
    );
    props.setselectedemail(
      contact.unique_id.email == "" ? "Empty" : contact.unique_id.email
    );
    props.setselectedwhatsapp(
      contact.unique_id.whatsappnumber == ""
        ? "Empty"
        : contact.unique_id.whatsappnumber
    );
    props.setselectedfacebook(
      contact.unique_id.facebookId == ""
        ? "Empty"
        : contact.unique_id.facebookId
    );
    props.setselectedtwitter(
      contact.unique_id.twitterId == "" ? "Empty" : contact.unique_id.twitterId
    );
    props.setselectedteams(
      contact.unique_id.teamsId == "" ? "Empty" : contact.unique_id.teamsId
    );
    props.setselectedcompany(
      contact.unique_id.company == "" ? "Empty" : contact.unique_id.company
    );
    props.setselectedadress(
      contact.unique_id.address == "" ? "Empty" : contact.unique_id.address
    );
    props.setselectedid(
      contact.unique_id.id == "" ? "Empty" : contact.unique_id.id
    );
    props.setAddTopicInput("");
    props.setAddTopicToggle("");
    props.setinternalchatnotify(false);
    props.setrefreshtogglechat(true);
    props.setloadmore(false);
    props.setLoadmoreSpinner(false);
    props.setSelectedContactID(contact.voice_contactId);
  };

  const onclickhandlechangeinternalContact = (val, index) => {
    props.setIndex2(index);
    props.closeNotify(val.id, props.notification);
    updateCount1(val.chat_session_id);
    props.setUserChatID(val.chat_session_id);
    props.setInternalChatData(val);
    props.setChatSpinner(true);
    props.setshowInternalChat(true);
    setOldId(val.id);
    props.setExternalChatData(val);
    props.setchatdata(val);
    props.setinternalchatnotify(false);
    props.setchatid(val.chat_session_id);
    props.setInternalChatActive(val.id);
    props.setrefreshtogglechat(true);
    props.setselectedmobile(val.phonenumber);
    props.setselectedemail(val.email);

    const agentId = JSON.parse(localStorage.getItem("tokenAgent"));
    props.setselectedusername(
      val.reciverDetails
        ? val.reciverDetails[0]._id !== agentId.id
          ? val.reciverDetails[0].username
          : val.senderDetails[0].username
        : val.username
    );
    props.setloadmore(false);
  };

  const onclickhandlechangeinternalContactCreateSession = (val) => {
    onclickhandlechangeinternalContact(val);
    props.setSearchterm("");
    props.setshowInternalChat(false);
    setTimeout(() => {
      getAllInternalAgents();
    }, 3000);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    props.setValue(newValue);
    if (newValue == 1) {
      props.setSearchterm("");
      props.setshowExternalChat(false);
      if (props.internalChatData.length === undefined) {
        onclickhandlechangeinternalContact(props.internalChatData);
      }
    } else {
      if (props.externalChatData.length === undefined) {
        onclickhandlechangeexternalContact(props.externalChatData);
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack spacing={0.5}>
        <Box
          sx={{
            
            borderRadius: "3px 3px 0px 0px",
            boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
            background: "#FFFF",
          }}
        >
          <Tabs value={props.value} onChange={handleChange}>
            {permission.map((module) => {
              if (module.moduleName === "Chat") {
                const crmScreen = module.screen.find(
                  (item) =>
                    item.screenId === "EXT_CHAT" && item.read && item.write
                );

                if (crmScreen) {
                  return (
                    <Tab
                      key={0}
                      id="pills-tabs"
                      label="External"
                      onClick={() => {
                        props.setCustomerDetailsShow(false);
                        props.setCannedmessagediv(false);
                      }}
                      sx={{
                        width: "50%",
                        fontWeight: 400,
                        color: "black",
                        fontSize: 13,
                        fontFamily: "Sen, sans-serif",
                        "&:hover": {
                          backgroundColor: "lightgrey",
                        },
                      }}
                    />
                  );
                } else {
                  return (
                    <Tab
                      key={0}
                      id="pills-tabs"
                      label="External"
                      disabled
                      onClick={() => {
                        props.setCustomerDetailsShow(false);
                        props.setCannedmessagediv(false);
                      }}
                      sx={{
                        width: "50%",
                        fontWeight: 400,
                        color: "black",
                        fontSize: 13,
                        fontFamily: "Sen, sans-serif",
                        "&:hover": {
                          backgroundColor: "lightgrey",
                        },
                      }}
                    />
                  );
                }
              }
            })}
            {permission.map((module) => {
              if (module.moduleName === "Chat") {
                const crmScreen = module.screen.find(
                  (item) =>
                    item.screenId === "IN_CHAT" && item.read && item.write
                );

                if (crmScreen) {
                  return (
                    <Tab
                      key={1}
                      id="pills-tabs"
                      label="Internal"
                      onClick={() => {
                        props.setCustomerDetailsShow(false);
                        props.setCannedmessagediv(false);
                      }}
                      sx={{
                        width: "50%",

                        fontWeight: 400,
                        color: "black",
                        fontSize: 13,
                        fontFamily: "Sen, sans-serif",
                        "&:hover": {
                          backgroundColor: "lightgrey",
                        },
                      }}
                    />
                  );
                } else {
                  return (
                    <Tab
                      key={1}
                      id="pills-tabs"
                      label="Internal"
                      disabled
                      onClick={() => {
                        props.setCustomerDetailsShow(false);
                        props.setCannedmessagediv(false);
                      }}
                      sx={{
                        width: "50%",
                        fontWeight: 400,
                        color: "black",
                        fontSize: 13,
                        fontFamily: "Sen, sans-serif",
                        "&:hover": {
                          backgroundColor: "lightgrey",
                        },
                      }}
                    />
                  );
                }
              }
            })}
          </Tabs>
        </Box>

        {(() => {
          switch (props.value) {
            case 0:
              return (
                <div className="chat-users" style={{ height: "79vh" }}>
                  <ExternalContactList
                    onclickhandlechangeexternalContact={
                      onclickhandlechangeexternalContact
                    }
                  />
                </div>
              );
            case 1:
              return (
                <>
                  {internalspinner && (
                    <div
                      className="d-flex justify-content-center position-absolute h-100"
                      style={{
                        zIndex: 999,
                        width: "290px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: "60px",
                          top: "33%",
                          right: "30px",
                        }}
                      >
                        <CSpinner
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "15px",
                            color: "#0b3363",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            bottom: "0",
                            fontFamily: "monospace",
                            color: "#0b3363",
                          }}
                        >
                          LOADING...
                        </span>
                      </div>
                    </div>
                  )}
                  <Box style={{ height: "90%" }}>
                    <Stack sx={{ height: "100%" }}>
                      <div className="chat-search rounded position-relative w-100 p-1">
                        <input
                          className="form-control color_b"
                          type="search"
                          style={{
                            height: "35px",
                            borderRadius: "10px",
                          }}
                          value={props.searchterm}
                          placeholder="Search Agent"
                          aria-label="Search Agent"
                          onChange={(e) => {
                            props.setSearchterm(e.target.value);
                            if (e.target.value.length >= 4) {
                              SearchInternalAgent(e.target.value);
                            } else {
                              SearchInternalAgent();
                            }
                          }}
                        />
                      </div>
                      <div className="cardinternal chat-users">
                        {!internalspinner && (
                          <InternalAgentList
                            onclickhandlechangeinternalContact={
                              onclickhandlechangeinternalContact
                            }
                            createInternalChat={createInternalChat}
                            onclickhandlechangeinternalContactCreateSession={
                              onclickhandlechangeinternalContactCreateSession
                            }
                          />
                        )}
                      </div>
                    </Stack>
                  </Box>
                </>
              );
            default:
              return <></>;
          }
        })()}
      </Stack>
    </Box>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setselectedemail,
  setselectedmobile,
  setrefreshtogglechat,
  setselecteduserimage,
  setchatdata,
  setchatid,
  setchatduration,
  setchattype,
  setonloadContactRefresh,
  setconfchatrefresh,
  setinternalexternal,
  settogglechat,
  setinternalchatrefresh,
  setinternalchatnotify,
  setSelectedColor,
  setloadmore,
  setAddTopicInput,
  setAddTopicToggle,
  setExternalChatData,
  setSelectedchanneldata,
  setUpdatechanneldata,
  setInternalChatActive,
  setChatSpinner,
  setInternalChatData,
  setshowExternalChat,
  setshowInternalChat,
  setSearchterm,
  setChatRefresh,
  setShowCard,
  setAcceptChatActive,
  setUserChatID,
  setseletedVoiceSessionId,
  setAgentList,
  setcontactlist,
  setExternalChatActive,
  setPullContact,
  setLoadmoreSpinner,
  setCustomerDetailsShow,
  setCannedmessagediv,
  setSelectedContactID,
  closeNotify,
  setUserlistShow,
  setSelectedvip,
  setValue,
  setIndex,
  setIndex2,
})(Contactlist);
