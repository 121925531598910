import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose, AiOutlineCloseCircle } from "react-icons/ai";
import {
  BaseUrl,
  emailservice,
  newBaseUrlLang,
} from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setrefreshContactlist,
  setChatRefresh,
  setshowExternalChat,
  setSelectedchanneldata,
} from "../../../../redux/actions/actions";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
} from "@mui/material";

import { PaperPlaneTilt } from "@phosphor-icons/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
    selectedchanneldata: state.data.selectedchanneldata,
  };
};

const TransferSkillset = (props) => {
  const { show, close, chatid, chat, OpenDialog, handleClose } = props;
  const [skilldropdown, setSkilldropdown] = useState([]);
  const [languagedropdown, setLanguagedropdown] = useState([]);
  const [skilldropdownid, setSkilldropdownid] = useState("");
  const [languagedropdownid, setLanguagedropdownid] = useState("");
  const [agentbasedonfilter, setAgentbasedonfilter] = useState([]);
  const [agentTransferId, setAgentTransferId] = useState("agent");
  const [checkWhichCall, setCheckWhichCall] = useState("");
  const [showTransferMod, setShowTransferMod] = useState(false);
  const [selectskillsetformail, setSelectSkillsetforMail] = useState("");

  const [skillvalid, setskillvalid] = useState(false);
  const [languagevalid, setLanguagevalid] = useState(false);
  const [agentvalid, setAgentvalid] = useState(false);

  const handelAllErrorMessage = () => {
    setskillvalid(false);
    setLanguagevalid(false);
    setAgentvalid(false);
  };

  const handleTransferClose = () => {
    setAgentTransferId("agent");
    setShowTransferMod(false);
  };

  const transferAgent = (val) => {
    setCheckWhichCall(val);
    setShowTransferMod(true);
  };

  const skillsetDropdown = () => {
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/skills/list", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setSkilldropdown(res.data.dataList);
      })
      .catch((error) => {});
  };

  const transferEmailtoAgent = () => {
    let data = {
      sessionId: chatid,
      skillset: selectskillsetformail,
    };
    axios
      .post(emailservice + "email/transferEmail", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setSkilldropdown(res.data.dataList);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // skillsetDropdown();
  }, []);

  return (
    // <Modal show={show} size="md" onHide={close}>
    //   <Modal.Header style={{ backgroundColor: "#0b3363", color: "#fff" }}>
    //     <Modal.Title
    //       style={{
    //         fontSize: 15,
    //         margin: "6px 0 0 0",
    //         textTransform: "capitalize",
    //       }}
    //     >
    //       Transfer Agent
    //     </Modal.Title>
    //     <AiOutlineCloseCircle
    //       onClick={() => {
    //         close();
    //         handelAllErrorMessage();
    //       }}
    //       style={{ cursor: "pointer" }}
    //     />
    //   </Modal.Header>
    //   <Modal.Body>
    //     <div className="d-flex justify-content-between">
    //       <div className="mx-1 w-100">
    //         <select
    //           className={`form-select form-select-sm ${
    //             skillvalid ? "is-invalid" : ""
    //           }`}
    //           aria-label="Default select example"
    //           onChange={(e) => {
    //             setSelectSkillsetforMail(e.target.value);
    //           }}
    //         >
    //           <option selected disabled value="">
    //             Choose Skillset
    //           </option>
    //           <option value="Toner Issue">Toner Issue</option>
    //           <option value="Network Problem">Network Problem</option>

    //         </select>
    //         {skillvalid && (
    //           <div className="invalid-feedback">Please select a Skillset</div>
    //         )}
    //       </div>
    //     </div>

    //   </Modal.Body>
    //   <Modal.Footer style={{ borderTop: "0px" }}>
    //     <Button
    //       size="sm"
    //       variant="secondary"
    //       onClick={close}
    //       style={{ fontFamily: "monospace" }}
    //     >
    //       CLOSE
    //     </Button>
    //     <Button
    //       size="sm"
    //       variant="primary"
    //       // onClick={() => {
    //       //   transferAgentSubmit();
    //       //   setrefreshContactlist('transfercompleted')
    //       //   close();
    //       // }}
    //       onClick={() => {
    //         transferEmailtoAgent();
    //         close();
    //       }}
    //       style={{ fontFamily: "monospace" }}
    //     >
    //       {checkWhichCall}
    //       TRANSFER
    //     </Button>
    //   </Modal.Footer>
    // </Modal>

    <Dialog
      open={OpenDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 5,
          padding: 1,
          top: -100,
        },

        backgroundColor: "rgb(0 0 0 / 65%)",
      }}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{ fontSize: "16px", fontWeight: 600 }}
      >
        Transfer Agent
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Stack>
            <Stack direction={"row"}>
              <FormControl
                sx={{ m: 1, minWidth: 170 }}
                size="small"
              ></FormControl>
              <FormControl
                sx={{ m: 1, minWidth: 180 }}
                size="small"
              ></FormControl>
            </Stack>
            <select
              className={`form-select form-select-sm ${
                skillvalid ? "is-invalid" : ""
              }`}
              aria-label="Default select example"
              onChange={(e) => {
                setSelectSkillsetforMail(e.target.value);
              }}
            >
              <option selected disabled value="">
                Choose Skillset
              </option>
              <option value="Toner Issue">Toner Issue</option>
              <option value="Network Problem">Network Problem</option>
            </select>
            {skillvalid && (
              <div className="invalid-feedback">Please select a Skillset</div>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            transferEmailtoAgent();
            handleClose();
          }}
          variant="contained"
          size="small"
          id="agent-transfer"
          endIcon={<PaperPlaneTilt size={15} />}
        >
          {checkWhichCall}
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setrefreshContactlist,
  setChatRefresh,
  setshowExternalChat,
  setSelectedchanneldata,
})(TransferSkillset);
