import React, { useRef, useState } from "react";
import { BiTransferAlt, BiTransfer, BiGitMerge } from "react-icons/bi";
import { MdOutlineCallMerge } from "react-icons/md";
import { BsWhatsapp, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import {
  setExternalChatData,
  setUserlistShow,
} from "../../../redux/actions/actions";
import { MdOutlineNotificationsNone } from "react-icons/md";

// Component
import WhatsappModal from "./chat-component-model/Whatsapp-model";
import Transferagentmodel from "./chat-component-model/Transfer-agent-model";
import Transfersupervisormodel from "./chat-component-model/Transfer-supervisor-model";
import Conferanceagentmodel from "./chat-component-model/Conferance-agent-model";
import Conferancesupervisormodel from "./chat-component-model/Conferance-supervisor-model";
import Notification from "./chat-component-model/Notification";
import Endchatmodel from "./chat-component-model/End-chat-model";
import Foroutsideclick from "../../outside-click/Foroutsideclick";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    externalChatData: data.externalChatData,
    userlistshow: data.userlistshow,
  };
};
const ChatMenu = (props) => {
  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
  let permission = JSON.parse(localStorage.getItem("permission"));
  const { toggleCard } = props;

  // Tooltip
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [transferagentmodalDialog, settransferagentmodalDialog] =
    useState(false);
  const [transferSupervisorModalDialog, settransferSupervisorModalDialog] =
    useState(false);
  const [whatsappModalDialogOpen, setwhatsappModalDialogOpen] = useState(false);
  const [conferenceagentModalDialog, setconferenceagentModalDialog] =
    useState(false);
  const [conferenceSupervisorModalDialog, setconferenceSupervisorModalDialog] =
    useState(false);
  const [notificationDialog, setnotificationDialog] = useState(false);
  const [endchatModalDialog, setendchatModalDialog] = useState(false);

  // Dialog //
  const transferToAgentDialogClose = () => {
    settransferagentmodalDialog(false);
  };

  const transferToSupervisorDialogClose = () => {
    settransferSupervisorModalDialog(false);
  };

  const transferToWhatsappDialogClose = () => {
    setwhatsappModalDialogOpen(false);
  };

  const ConfrenceToAgentDialogClose = () => {
    setconferenceagentModalDialog(false);
  };

  const ConfrenceToSupervisorDialogClose = () => {
    setconferenceSupervisorModalDialog(false);
  };

  const NotificationHandleDialogClose = () => {
    setnotificationDialog(false);
  };

  const EndChatDialogClose = () => {
    setendchatModalDialog(false);
  };

  return (
    <>
      <Foroutsideclick id="outside-click" onClickOutside={toggleCard}>
        <div
          className="menucard"
          style={{
            right: props.userlistshow ? "30.7rem" : "5rem",
            width:
              basedOnRole.role == "Supervisor" ? "max-content" : "max-content",
          }}
        >
          <BootstrapTooltip title="Close Menu" arrow placement="top">
            <button
              type="button"
              className="btn border-0 btn-sm closeMenucard"
              data-tippy-content=" Close Menucard"
              id="pills-togglecard"
              onClick={toggleCard}
            ></button>
          </BootstrapTooltip>

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) =>
                  item.screenId === "TRANSFER_WHATSAPP" &&
                  item.read &&
                  item.write
              );

              if (value) {
                return (
                  <>
                    <BootstrapTooltip
                      title="Transfer to Whatsapp"
                      arrow
                      placement="bottom"
                    >
                      <button
                        type="button"
                        id="whatsappmodal-open"
                        className="btn btn-outline-secondary border-0 btn-sm"
                        data-tippy-content=" Screen Share"
                        onClick={() => {
                          setwhatsappModalDialogOpen(true);
                        }}
                      >
                        <BsWhatsapp className="icon_top-btn_small" size={20} />
                      </button>
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) =>
                  item.screenId === "TRANSFER" && item.read && item.write
              );

              if (value) {
                return (
                  <>
                    {" "}
                    <BootstrapTooltip
                      title="Transfer to Agent"
                      arrow
                      placement="bottom"
                    >
                      <button
                        type="button"
                        id="agent-transfer"
                        className="btn btn-outline-secondary border-0 btn-sm"
                        data-tippy-content=" Screen Share"
                        onClick={() => {
                          settransferagentmodalDialog(true);
                        }}
                      >
                        <BiTransfer className="icon_top-btn_small" size={20} />
                      </button>
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) =>
                  item.screenId === "TRANSFER" && item.read && item.write
              );

              if (value) {
                return (
                  <>
                    <BootstrapTooltip
                      title="Transfer to Supervisor"
                      arrow
                      placement="bottom"
                    >
                      {basedOnRole.role == "Supervisor" ? (
                        <></>
                      ) : (
                        <button
                          type="button"
                          id="supervisor-transfer"
                          className="btn btn-outline-secondary border-0 btn-sm"
                          data-tippy-content=" Screen Share"
                          onClick={() => {
                            settransferSupervisorModalDialog(true);
                          }}
                        >
                          <BiTransferAlt
                            className="icon_top-btn_small"
                            size={20}
                          />
                        </button>
                      )}
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "CONF" && item.read && item.write
              );

              if (value) {
                return (
                  <>
                    <BootstrapTooltip
                      title="Conference to Agent"
                      arrow
                      placement="bottom"
                    >
                      <button
                        type="button"
                        id="conf-agent-transfer"
                        className="btn btn-outline-secondary border-0 btn-sm"
                        data-tippy-content=" Screen Share"
                        onClick={() => {
                          setconferenceagentModalDialog(true);
                        }}
                      >
                        <BiGitMerge className="icon_top-btn_small" size={20} />
                      </button>
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "CONF" && item.read && item.write
              );

              if (value) {
                return (
                  <>
                    <BootstrapTooltip
                      title="Conference to Supervisor"
                      arrow
                      placement="bottom"
                    >
                      {basedOnRole.role == "Supervisor" ? (
                        <></>
                      ) : (
                        <button
                          type="button"
                          id="conf-supervisor-transfer"
                          className="btn btn-outline-secondary border-0 btn-sm"
                          data-tippy-content=" Screen Share"
                          onClick={() => {
                            setconferenceSupervisorModalDialog(true);
                          }}
                        >
                          <MdOutlineCallMerge
                            className="icon_top-btn_small"
                            size={20}
                          />
                        </button>
                      )}
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) =>
                  item.screenId === "NOTIFICATION" && item.read && item.write
              );

              if (value) {
                return (
                  <>
                    <BootstrapTooltip
                      title="Notification"
                      arrow
                      placement="bottom"
                    >
                      <button
                        type="button"
                        id="notification-dialog"
                        className="btn btn-outline-secondary border-0 btn-sm"
                        data-tippy-content=" Screen Share"
                        onClick={() => {
                          setnotificationDialog(true);
                        }}
                      >
                        <MdOutlineNotificationsNone
                          className="icon_top-btn_small"
                          size={20}
                        />
                      </button>
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          <BootstrapTooltip title="End Interaction" arrow placement="bottom">
            <button
              type="button"
              id="end-chatdialog"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                setendchatModalDialog(true);
              }}
            >
              <AiOutlineCloseCircle className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>
        </div>
      </Foroutsideclick>

      {whatsappModalDialogOpen}
      <WhatsappModal
        OpenDialog={whatsappModalDialogOpen}
        handleClose={transferToWhatsappDialogClose}
      />
      {transferagentmodalDialog && (
        <Transferagentmodel
          OpenDialog={transferagentmodalDialog}
          handleClose={transferToAgentDialogClose}
        />
      )}

      {transferSupervisorModalDialog && (
        <Transfersupervisormodel
          OpenDialog={transferSupervisorModalDialog}
          handleClose={transferToSupervisorDialogClose}
        />
      )}

      {conferenceagentModalDialog && (
        <Conferanceagentmodel
          OpenDialog={conferenceagentModalDialog}
          handleClose={ConfrenceToAgentDialogClose}
        />
      )}

      {conferenceSupervisorModalDialog && (
        <Conferancesupervisormodel
          OpenDialog={conferenceSupervisorModalDialog}
          handleClose={ConfrenceToSupervisorDialogClose}
        />
      )}

      {notificationDialog && (
        <Notification
          OpenDialog={notificationDialog}
          handleClose={NotificationHandleDialogClose}
        />
      )}

      {endchatModalDialog && (
        <Endchatmodel
          OpenDialog={endchatModalDialog}
          handleClose={EndChatDialogClose}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setExternalChatData,
  setUserlistShow,
})(ChatMenu);
