import React from "react";

const SupervisorAgentreports = (props) => {
  return (
    <>
      <nav style={{ top: "59px" }} className={props.showSupervisorAgentreports}>
        <div className="filter col-md-12 shadow p-4 ">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">Agent reports</p>
            <div className="dismiss">
              <span
                id="session_filter_close_btn"
                className="material-symbols-outlined"
                onClick={() => {
                  props.setShowSupervisorAgentreports("sidebar_filter");
                }}
              >
                cancel
              </span>
            </div>
          </div>

          <div className="filter_form">
            <form className=" fillter_form2 " style={{ height: "68vh" }}>
              <div className="custom_div">
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="fromdate" className="form-label">
                    From
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    aria-describedby="date"
                    value=""
                  />
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="todate" className="form-label">
                    To
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    aria-describedby="date"
                    value=""
                  />
                </div>
              </div>
            </form>
            <div className="clearbutton d-flex justify-content-end align-items-end">
              <button type="button" className="btn btn-outlined">
                <span>clear</span>
              </button>
            </div>

            <div className="filter_submit">
              <div className="d-flex">
                <div className="col-md-12 ps-2">
                  <button
                    type="button"
                    id="session_filter_Submit"
                    className="btn btn-primary w-100 text-white"
                    onclick={() => {
                      props.setShowSupervisorAgentreports("sidebar_filter");
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SupervisorAgentreports;
