import React from "react";
import { motion } from "framer-motion";
const SmartXTab = (props) => {
  return (
    <>
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="time-line-main m-2 p-2 h-75">
          <ul className="timeline">
            <div className="m-2 file-main-loan">
              <div className="chat-r-file container-fluid h-50">
                <div>
                  <h6
                    className="mt-1 text-center"
                    style={{ fontFamily: "poppins" }}
                  >
                    Loan Eligibility
                  </h6>
                </div>
                <div className="d-flex justify-content-between mt-2 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    userId
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    true
                  </span>
                </div>

                <div className="d-flex justify-content-between mt-2 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    userId
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    true
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    userName
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    --
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    domainId
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    0
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    buId
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    true
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    channel
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    62
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    RuleName
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Loan Eligibility
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Reason
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Success
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Status
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Success
                  </span>
                </div>
                <div className="d-flex justify-content-between mt-1 ml-2">
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    ActionType
                  </span>
                  <span
                    className="mr-2"
                    style={{
                      fontSize: "12px",
                      fontFamily: "poppins",
                    }}
                  >
                    Static Value
                  </span>
                </div>
              </div>
            </div>
          </ul>
        </div>

        {/* <div className="d-flex justify-content-between align-items-center m-2">
          <div className="tickt-name">SmartXtab</div>
        </div>
        <div className="time-line-main m-2 p-2">
          <div>
            <div
              className="text-muted d-flex"
              style={{
                flexDirection: "column",
                marginTop: "25%",
              }}
            >
              <span className="mx-auto">
                <RiErrorWarningLine size={30} />
              </span>
              <span className="mx-auto">
                <h6
                  className="text-muted mt-2"
                  style={{ fontFamily: "poppins" }}
                >
                  No Data Found
                </h6>
              </span>
            </div>
          </div>
        </div> */}
      </motion.div>
    </>
  );
};

export default SmartXTab;
