import FileSaver from "file-saver";
import {
  SET_USERLIST_SHOW,
  SET_SELECTED_COLOR,
  SEND_MESSAGE,
  SHOW_ERROR_ALERT,
  SET_ERROR_DATA,
  SET_UPLOADED_FILE,
  SET_SELECTED_USERNAME,
  SET_SELECTED_EMAIL,
  SET_SELECTED_MOBILE,
  SET_CHAT_ID,
  SET_CHAT_DURATION,
  SET_SELECTED_USERIMAGE,
  TOOGLE_CHAT,
  REFRESH_TOOGLE_CHAT,
  CONF_CHAT_REFRESH,
  INTERNAL_CHAT_REFRESH,
  CHAT_DATA,
  CHAT_DATA_FOR_INTERNAL,
  INTERNAL_CHAT_NOTIFY,
  ONLOAD_CONTACT_REFRESH,
  TYPE_CHAT,
  CHAT_MESSAGE,
  SET_CONTACT_LIST,
  SET_CONTACT_LIST1,
  SET_INTERNAL_EXTERNAL,
  SET_CONTACT_LIST2,
  SET_AGENT_LIST,
  SET_CONF_NOTIFI,
  SET_CONF_NOTIFI_VAL,
  SET_AGENT_AVAIL,
  SET_LOADMORE,
  SET_ADD_TOPIC_INPUT,
  SET_ADD_TOPIC_TOGGLE,
  SET_SELECTED_WHATSAPP,
  SET_SELECTED_FACEBOOK,
  SET_SELECTED_TWITTER,
  SET_SELECTED_TEAMS,
  SET_SELECTED_COMPANY,
  SET_SELECTED_ADDRESS,
  SET_SELECTED_ID,
  SET_EXTERNAL_CHAT_DATA,
  SET_MESSAGE,
  SET_CUSTOMER_TYPING,
  SELECTED_CHANNEL_DATA,
  UPDATED_CHANNEL_DATA,
  REFRESH_CONTACT_LIST,
  SET_INTENAL_CHAT_ACTIVE,
  SET_CONTACT_LISTUPDATED,
  SET_CONTACT_LIST_UPDATED1,
  FORWARD_MAIL,
  CLEAR_ALL_NOTIFICATION,
  SET_CHAT_SPINNER,
  SET_INTERNAL_CHAT_DATA,
  SET_SHOW_EXTENAL_CHAT,
  SET_SHOW_INTENAL_CHAT,
  SET_SEARCH_TERM,
  SET_CHAT_REFRESH,
  SET_MSG_TONE,
  SET_MSG_TONE2,
  SET_SHOW_CARD,
  SET_ACCEPT_CHAT_ACTIVE,
  SET_USER_CHAT_ID,
  SET_SKILLSET,
  SET_DIALED_NUMBER_INC,
  SET_AGENT_DIALED_NUMBER,
  SET_SHOW_DIALED_NUMBER,
  SET_SHOW_DIALED_NUMBER2,
  SET_SHOW_DIALED_NUMBER3,
  SET_SHOW_DIALED_NUMBER4,
  SET_SHOW_INCOMING_SCREEN,
  SET_CALL_SCREEN,
  SET_SHOW_INCOMING_NXT_SCREEN,
  SET_CALLED_AGENT_SCREEN,
  SET_CONFERANCE_SCREEN,
  SET_SHOW_MUTE,
  SET_SHOW_UNMUTE,
  SET_AGENT_SHOW_MUTE,
  SET_AGENT_SHOW_UNMUTE,
  SET_SHOW_HOLD,
  SET_SHOW_UNHOLD,
  SET_AGENT_CALL_HOLD,
  SET_AGENT_CALL_UNHOLD,
  SET_TRANSFER_CALL,
  SET_MAKING_TRANSFER_CALL,
  SET_CONFERANCE_CALL,
  SET_MERGE_CALL,
  SET_CALL_CONTACTID,
  SET_CONSULTED_CONTACT_ID,
  SET_CALL_ID,
  SET_VOICE_SECONDS,
  SET_VOICE_MINUTES,
  SET_VOICE_HOURS,
  SET_VOICE_SESSION_ID,
  SET_LOADMORE_USER,
  SET_SELETED_VOICE_SESSION_ID,
  SET_SIDEBAR_VAL,
  SET_SHOW_USER_PROFILE,
  SET_NOTIFICATION,
  SET_EXTERNAL_CHAT_ACTIVE,
  SET_PULL_CONTACT,
  SET_SHOW_VOICE_CALL,
  SET_CUSTOMER_DETAILS_SHOW,
  SET_CANNEDMESSAGE_DIV,
  SET_INCOMING_REQUEST_REFRESH,
  SET_ISWRITE,
  SET_COUPLE_EMAIL,
  SET_LOAD_MORE_SPINNER,
  SET_VOICE_REFRESH,
  SET_SELECTED_CONTACT_ID,
  SET_IS_VIP,
  SET_VALUE,
  SET_INDEX,
  SET_INDEX2,
  SET_SUPERVISOR_LIST_REFRESH,
  SET_UPDATE_AGENT_STATUS_LIST,
  SET_SHOWING_STATUS,
  SET_SHOWING_STATUS_CLASS,
  SET_SEND_FILE_FROM_FILES,
  SET_SEND_FILE_FROM_FILES_TYPE,
  SET_SEND_FILE_FROM_FILES_NAME,
  SET_AGENTSTATUSLIST,
  SET_AGENTSTATUS_REFREASH,
} from "./type";

import {
  FETCH_INBOX_LIST,
  FETCH_MAIL_CONTENET,
  DOWNLOAD_MAIL_ATTACHMENT,
  SEND_MAIL_CONTENT,
  COMPLETED_MAIL_LIST,
  FORWARD_MAIL_CONTENT,
} from "../../Page/EmailComponents/appList";
import _ from "lodash";
import { BaseUrl, emailservice } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";

export const setAgentStatusRefreash = (val) => (dispatch) => {
  dispatch({ type: SET_AGENTSTATUS_REFREASH, val });
};




export const setAgentStatusListInSupervisor = (val) => (dispatch) => {
  dispatch({ type: SET_AGENTSTATUSLIST, val });
};

export const setIswrite = (val) => (dispatch) => {
  dispatch({ type: SET_ISWRITE, val });
};
export const setValue = (val) => (dispatch) => {
  dispatch({ type: SET_VALUE, val });
};

export const setsendfilefromfiles = (val) => (dispatch) => {
  dispatch({ type: SET_SEND_FILE_FROM_FILES, val });
};
export const setsendfilefromfilesType = (val) => (dispatch) => {
  dispatch({ type: SET_SEND_FILE_FROM_FILES_TYPE, val });
};export const setsendfilefromfilesName = (val) => (dispatch) => {
  dispatch({ type: SET_SEND_FILE_FROM_FILES_NAME, val });
};


export const setIndex = (val) => (dispatch) => {
  dispatch({ type: SET_INDEX, val });
};
export const setIndex2 = (val) => (dispatch) => {
  dispatch({ type: SET_INDEX2, val });
};

export const setSelectedvip = (val) => (dispatch) => {
  dispatch({ type: SET_IS_VIP, val });
};
export const setTeamactivitydashboardcount = (val) => (dispatch) => {
  dispatch({ type: SET_UPDATE_AGENT_STATUS_LIST, val });
};


export const setShowingStatus = (val) => (dispatch) => {
  dispatch({ type: SET_SHOWING_STATUS, val });
};

export const setShowingStatusClass = (val) => (dispatch) => {
  dispatch({ type: SET_SHOWING_STATUS_CLASS, val });
};

export const setSupervisorlistrefresh = (val) => (dispatch) => {
  dispatch({ type: SET_SUPERVISOR_LIST_REFRESH, val });
};


export const setUserlistShow = (val) => (dispatch) => {
  dispatch({ type: SET_USERLIST_SHOW, val });
};

export const setVoiceRefresh = (val) => (dispatch) => {
  dispatch({ type: SET_VOICE_REFRESH, val });
};
export const setLoadmoreSpinner = (val) => (dispatch) => {
  dispatch({ type: SET_LOAD_MORE_SPINNER, val });
};

export const setcouplemail = (val) => (dispatch) => {
  dispatch({ type: SET_COUPLE_EMAIL, val });
};

export const setSelectedContactID = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_CONTACT_ID, val });
};

export const setCustomerDetailsShow = (val) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_DETAILS_SHOW, val });
};
export const setIncomingRequestRefresh = (val) => (dispatch) => {
  dispatch({ type: SET_INCOMING_REQUEST_REFRESH, val });
};
export const setShowVoiceCall = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_VOICE_CALL, val });
};
export const setShowUserProfile = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_USER_PROFILE, val });
};

export const setCannedmessagediv = (val) => (dispatch) => {
  dispatch({ type: SET_CANNEDMESSAGE_DIV, val });
};

export const setPullContact = (val) => (dispatch) => {
  dispatch({ type: SET_PULL_CONTACT, val });
};
export const setShowNotification = (val) => (dispatch) => {
  dispatch({ type: SET_NOTIFICATION, val });
};
export const setsidebarVal = (val) => (dispatch) => {
  dispatch({ type: SET_SIDEBAR_VAL, val });
};
export const setAcceptChatActive = (val) => (dispatch) => {
  dispatch({ type: SET_ACCEPT_CHAT_ACTIVE, val });
};

export const setUserChatID = (val) => (dispatch) => {
  dispatch({ type: SET_USER_CHAT_ID, val });
};
export const setloadmoreUser = (val) => (dispatch) => {
  dispatch({ type: SET_LOADMORE_USER, val });
};

export const setavailagent = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_AVAIL, val });
};
export const setShowCard = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_CARD, val });
};
export const setMsgTone = (val) => (dispatch) => {
  dispatch({ type: SET_MSG_TONE, val });
};

export const setMsgTone2 = (val) => (dispatch) => {
  dispatch({ type: SET_MSG_TONE2, val });
};
export const setChatRefresh = (val) => (dispatch) => {
  dispatch({ type: SET_CHAT_REFRESH, val });
};

export const setForwardmail = (val) => (dispatch) => {
  dispatch({ type: FORWARD_MAIL, val });
};

export const settogglechat = (val) => (dispatch) => {
  dispatch({ type: TOOGLE_CHAT, val });
};
export const setloadmore = (val) => (dispatch) => {
  dispatch({ type: SET_LOADMORE, val });
};
export const setrefreshtogglechat = (val) => (dispatch) => {
  dispatch({ type: REFRESH_TOOGLE_CHAT, val });
};
export const setconfchatrefresh = (val) => (dispatch) => {
  dispatch({ type: CONF_CHAT_REFRESH, val });
};
export const setinternalchatrefresh = (val) => (dispatch) => {
  dispatch({ type: INTERNAL_CHAT_REFRESH, val });
};
export const setchatdata = (val) => (dispatch) => {
  dispatch({ type: CHAT_DATA, val });
};
export const setchatdataforinternal = (val) => (dispatch) => {
  dispatch({ type: CHAT_DATA_FOR_INTERNAL, val });
};
export const setinternalchatnotify = (val) => (dispatch) => {
  dispatch({ type: INTERNAL_CHAT_NOTIFY, val });
};
export const setonloadContactRefresh = (val) => (dispatch) => {
  dispatch({ type: ONLOAD_CONTACT_REFRESH, val });
};

export const setchatid = (val) => (dispatch) => {
  dispatch({ type: SET_CHAT_ID, val });
};
export const setcontactlist = (val) => (dispatch) => {
  dispatch({ type: SET_CONTACT_LIST, val });
};
export const setAgentList = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_LIST, val });
};
export const setConferenceNotification = (val) => (dispatch) => {
  dispatch({ type: SET_CONF_NOTIFI, val });
};
export const setConferenceNotificationVal = (val) => (dispatch) => {
  dispatch({ type: SET_CONF_NOTIFI_VAL, val });
};
export const setinternalexternal = (val) => (dispatch) => {
  dispatch({ type: SET_INTERNAL_EXTERNAL, val });
};
export const setcontactlist1 = (val) => (dispatch) => {
  dispatch({ type: SET_CONTACT_LIST1, val });
};
export const setcontactlist2 = (val) => (dispatch) => {
  dispatch({ type: SET_CONTACT_LIST2, val });
};
export const setchatduration = (val) => (dispatch) => {
  dispatch({ type: SET_CHAT_DURATION, val });
};
export const setchatmessage = (val) => (dispatch) => {
  dispatch({ type: CHAT_MESSAGE, val });
};
export const setchattype = (val) => (dispatch) => {
  dispatch({ type: TYPE_CHAT, val });
};
export const setselectedusername = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_USERNAME, val });
};
export const setselectedemail = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_EMAIL, val });
};
export const setselectedmobile = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_MOBILE, val });
};
export const setselecteduserimage = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_USERIMAGE, val });
};
export const sendMessage = (val) => (dispatch) => {
  dispatch({ type: SEND_MESSAGE, val });
};
export const toggleErrorAlert = (val) => (dispatch) => {
  dispatch({ type: SHOW_ERROR_ALERT, val });
};
export const setErrorData = (val) => (dispatch) => {
  dispatch({ type: SET_ERROR_DATA, val });
};
export const setUploadedFile = (val) => (dispatch) => {
  dispatch({ type: SET_UPLOADED_FILE, val });
};
export const setSelectedColor = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_COLOR, val });
};
export const setAddTopicInput = (val) => (dispatch) => {
  dispatch({ type: SET_ADD_TOPIC_INPUT, val });
};
export const setAddTopicToggle = (val) => (dispatch) => {
  dispatch({ type: SET_ADD_TOPIC_TOGGLE, val });
};
export const setselectedwhatsapp = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_WHATSAPP, val });
};
export const setselectedfacebook = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_FACEBOOK, val });
};
export const setselectedtwitter = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_TWITTER, val });
};
export const setselectedteams = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_TEAMS, val });
};
export const setselectedcompany = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_COMPANY, val });
};
export const setselectedadress = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_ADDRESS, val });
};
export const setselectedid = (val) => (dispatch) => {
  dispatch({ type: SET_SELECTED_ID, val });
};
export const setExternalChatData = (val) => (dispatch) => {
  dispatch({ type: SET_EXTERNAL_CHAT_DATA, val });
};
export const setInternalChatData = (val) => (dispatch) => {
  dispatch({ type: SET_INTERNAL_CHAT_DATA, val });
};
export const setSearchterm = (val) => (dispatch) => {
  dispatch({ type: SET_SEARCH_TERM, val });
};

export const closeNotify = (id, notification) => (dispatch) => {
  let filteredArray = notification.filter((item) => {
    return item.id !== id;
  });
  dispatch({ type: SET_CONTACT_LISTUPDATED, val: filteredArray });
};

export const clearnotifyAll = () => (dispatch) => {
  const emptyNotification = [];
  dispatch({ type: SET_CONTACT_LIST_UPDATED1, emptyNotification });
};

export const clearnotify = () => (dispatch) => {
  dispatch({ type: SET_CONTACT_LIST_UPDATED1, val: [] });
};

export const updateNotification = (msg) => (dispatch) => {
  dispatch({ type: SET_CONTACT_LIST1, val: msg });
};

/** Chat Component Redux Variable **/
export const setMessage = (val) => (dispatch) => {
  dispatch({ type: SET_MESSAGE, val });
};
export const setCustomerTyping = (val) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_TYPING, val });
};

export const setSelectedchanneldata = (val) => (dispatch) => {
  dispatch({ type: SELECTED_CHANNEL_DATA, val });
};
export const setUpdatechanneldata = (val) => (dispatch) => {
  dispatch({ type: UPDATED_CHANNEL_DATA, val });
};

export const setrefreshContactlist = (val) => (dispatch) => {
  dispatch({ type: REFRESH_CONTACT_LIST, val });
};

export const setInternalChatActive = (val) => (dispatch) => {
  dispatch({ type: SET_INTENAL_CHAT_ACTIVE, val });
};
export const setExternalChatActive = (val) => (dispatch) => {
  dispatch({ type: SET_EXTERNAL_CHAT_ACTIVE, val });
};
export const setChatSpinner = (val) => (dispatch) => {
  dispatch({ type: SET_CHAT_SPINNER, val });
};

export const setshowExternalChat = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_EXTENAL_CHAT, val });
};

export const setshowInternalChat = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_INTENAL_CHAT, val });
};

// SDK-----------------------------------------------------------------

export const setSkillSet = (val) => (dispatch) => {
  dispatch({ type: SET_SKILLSET, val });
};

export const setDialedNumberInc = (val) => (dispatch) => {
  dispatch({ type: SET_DIALED_NUMBER_INC, val });
};
export const setagentDialedNumber = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_DIALED_NUMBER, val });
};
export const setshowphonedialer = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_DIALED_NUMBER, val });
};
export const setShowphonedialer2 = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_DIALED_NUMBER2, val });
};
export const setShowphonedialer3 = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_DIALED_NUMBER3, val });
};
export const setShowphonedialer4 = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_DIALED_NUMBER4, val });
};
export const setshowIncommingscreen = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_INCOMING_SCREEN, val });
};
export const setcallscreen = (val) => (dispatch) => {
  dispatch({ type: SET_CALL_SCREEN, val });
};
export const setshowIncommingNxtscreen = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_INCOMING_NXT_SCREEN, val });
};
export const setcalledagentscreen = (val) => (dispatch) => {
  dispatch({ type: SET_CALLED_AGENT_SCREEN, val });
};
export const setconfrencescreen = (val) => (dispatch) => {
  dispatch({ type: SET_CONFERANCE_SCREEN, val });
};
export const setShowMute = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_MUTE, val });
};
export const setShowUnMute = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_UNMUTE, val });
};
export const setAgentShowMute = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_SHOW_MUTE, val });
};
export const setAgentShowUnMute = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_SHOW_UNMUTE, val });
};
export const setShowHold = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_HOLD, val });
};
export const setShowUnHold = (val) => (dispatch) => {
  dispatch({ type: SET_SHOW_UNHOLD, val });
};
export const setagentCallHold = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_CALL_HOLD, val });
};
export const setagentCallUnHold = (val) => (dispatch) => {
  dispatch({ type: SET_AGENT_CALL_UNHOLD, val });
};
export const setTransferCall = (val) => (dispatch) => {
  dispatch({ type: SET_TRANSFER_CALL, val });
};
export const setmakingTransferCall = (val) => (dispatch) => {
  dispatch({ type: SET_MAKING_TRANSFER_CALL, val });
};
export const setConfrence = (val) => (dispatch) => {
  dispatch({ type: SET_CONFERANCE_CALL, val });
};
export const setMergeCall = (val) => (dispatch) => {
  dispatch({ type: SET_MERGE_CALL, val });
};
export const setcontactId = (val) => (dispatch) => {
  dispatch({ type: SET_CALL_CONTACTID, val });
};
export const setconsultedContactId = (val) => (dispatch) => {
  dispatch({ type: SET_CONSULTED_CONTACT_ID, val });
};
export const setCallId = (val) => (dispatch) => {
  dispatch({ type: SET_CALL_ID, val });
};

export const setvoiceseconds = (val) => (dispatch) => {
  dispatch({ type: SET_VOICE_SECONDS, val });
};
export const setvoiceminutes = (val) => (dispatch) => {
  dispatch({ type: SET_VOICE_MINUTES, val });
};
export const setvoicehours = (val) => (dispatch) => {
  dispatch({ type: SET_VOICE_HOURS, val });
};

export const setVoiceSessionId = (val) => (dispatch) => {
  dispatch({ type: SET_VOICE_SESSION_ID, val });
};

export const setseletedVoiceSessionId = (val) => (dispatch) => {
  dispatch({ type: SET_SELETED_VOICE_SESSION_ID, val });
};

/**Email Channel Actions  */
export function InboxList() {
  const agentId = JSON.parse(localStorage.getItem("tokenAgent"));
  const agentID = agentId.user_id;
  const token = localStorage.getItem("access_token");

  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    let payload = {
      agentID: agentID,
    };

    //myHeaders.append('Access-Control-Allow-Origin', '*');
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    };
    fetch(FETCH_INBOX_LIST, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        dispatch(InboxListSuccess(json));
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function InboxListSuccess(data) {
  return {
    type: "INBOX_MAIL_LIST_GET_SUCCESS",
    InboxDataList: data.Email,
    emaiIsPending: false,
  };
}
export function InboxMailShow(obj) {
  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    //myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    let data = JSON.stringify(obj);
    let requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      body: data,
      headers: myHeaders,
      // redirect: 'follow'
    };
    fetch(FETCH_MAIL_CONTENET, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        dispatch(InboxMailSuccess(json));
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function InboxMailSuccess(data) {
  return {
    type: "INBOX_MAIL_CONTENT_SUCCESS",
    InboxEmailContent: data.Email,
    InboxEmailBody: data.EmailBody,
    emaiIsPending: false,
  };
}
export function DownloadAttachment(obj, fileName) {
  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    // myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    let data = JSON.stringify(obj);
    let requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      body: data,
      headers: myHeaders,
      // redirect: 'follow'
    };
    fetch(DOWNLOAD_MAIL_ATTACHMENT, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status == true) {
          setTimeout(() => {
            let file = `https://gway.release.inaipi.ae/email/files${json.downloadFile}`;
            FileSaver.saveAs(file.toString(), fileName);
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function EmailIsPending() {
  return {
    type: "INBOX_PENDING",
    emaiIsPending: true,
  };
}
export function InboxErrorMessage(data, color) {
  return {
    type: "INBOX_FAILED",
    emailMessage: !_.isEmpty(data) ? data.message : "",
    isPending: false,
    emailShowMessage: true,
    emailShowColor: typeof color !== "undefined" ? color : "green",
  };
}
export function InboxErrorMessageClose() {
  return {
    type: "INBOX_FAILED_CLOSE",
    emailShowMessage: false,
    emailMessage: "",
  };
}
export function CompletedMailList(obj) {
  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    //myHeaders.append('Access-Control-Allow-Origin', '*');
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    let data = JSON.stringify(obj);
    let requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      body: data,
      headers: myHeaders,
      // redirect: 'follow'
    };
    fetch(COMPLETED_MAIL_LIST, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        dispatch(CompletedMailSuccess(json));
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function CompletedMailSuccess(data) {
  return {
    type: "INBOX_MAIL_COMPLETE_SUCCESS",
    completedDataList: data.Email,
    emaiIsPending: false,
  };
}
export function SendEmail(obj) {
  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);
    // myHeaders.append('Access-Control-Allow-Origin', '*');
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Accept", "application/json");
    let data = obj;
    let requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      body: data,
      headers: myHeaders,
      // redirect: 'follow'
    };
    fetch(SEND_MAIL_CONTENT, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //dispatch(InboxMailSuccess(json))
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function ForwardEmail(obj) {
  return (dispatch) => {
    dispatch(EmailIsPending());
    let myHeaders = new Headers();
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);
    // myHeaders.append('Access-Control-Allow-Origin', '*');
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Accept", "application/json");
    let data = obj;
    let requestOptions = {
      method: "POST",
      // mode: 'no-cors',
      body: data,
      headers: myHeaders,
      // redirect: 'follow'
    };
    fetch(FORWARD_MAIL_CONTENT, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //dispatch(InboxMailSuccess(json))
      })
      .catch((error) => {
        dispatch(InboxErrorMessage(error, "red"));
      });
  };
}
export function openWriteEmail() {
  return {
    type: "INBOX_WRITE_MAIL",
    writeEmail: true,
    isReplyEmail: false,
  };
}
export function openReplyEmail() {
  return {
    type: "INBOX_WRITE_MAIL",
    writeEmail: true,
    isReplyEmail: true,
  };
}
export function closeWriteEmail() {
  return {
    type: "INBOX_WRITE_MAIL",
    writeEmail: false,
    isReplyEmail: false,
  };
}

export function endInteraction() {
  return async (dispatch) => {
    try {
      const datas = JSON.parse(localStorage.getItem("tokenAgent"));
      // setAgentId(datas.id);
      await axios
        .post(
          `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.length > 0 && res.data) {
            dispatch({ type: SET_USER_CHAT_ID, val: "" });
            dispatch({ type: SET_EXTERNAL_CHAT_DATA, val: [] });
            dispatch({ type: SET_CONTACT_LIST, val: res.data });
          } else {
            dispatch({ type: SET_USER_CHAT_ID, val: "" });
            dispatch({ type: SET_EXTERNAL_CHAT_DATA, val: [] });
            dispatch({ type: SET_CONTACT_LIST, val: res.data });
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };
}

/***************************** */
