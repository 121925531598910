import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { randomColors } from "../colorpallet";
import { BsFillSendFill, BsThreeDotsVertical } from "react-icons/bs";
import "./Chat-Style/chat.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import { connect } from "react-redux";
import { FiEdit } from "react-icons/fi";
import EditIcon from "@mui/icons-material/Edit";
import FileSaver from "file-saver";
import InputLabel from "@mui/material/InputLabel";
// import word from "../Resources/images/word.png";
import word from "../../../Page/EmailComponents/Resources/images/word.png";
import pdf from "../../../Page/EmailComponents/Resources/images/pdf.png";
import excel from "../../../Page/EmailComponents/Resources/images/excel.png";
import { RiDownloadCloud2Fill } from "react-icons/ri";
import { TiDownload } from "react-icons/ti";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  TextField,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Stack,
  Alert,
} from "@mui/material";

import {
  setselectedusername,
  setExternalChatData,
  setcontactlist,
  openReplyEmail,
  setSelectedchanneldata,
  setUpdatechanneldata,
  setMessage,
  setCustomerTyping,
  setForwardmail,
  setShowCard,
  setloadmore,
  setloadmoreUser,
  setIswrite,
  setcouplemail,
  setChatRefresh,
  setshowExternalChat,
  setUserlistShow,
} from "../../../redux/actions/actions";
import parse from "html-react-parser";
import replynew from "../../../assets/assets/images/replynew.png";
import replyallnew from "../../../assets/assets/images/replyallnew.png";
import forwardnew from "../../../assets/assets/images/forwardnew.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";
import {
  FORWARD_MAIL_CONTENT,
  SEND_MAIL_CONTENT,
  SEND_MAIL_CONTENT_SUPERVISOR,
} from "../../../Page/EmailComponents/appList";
import {
  Grid,
  Paper,
  IconButton,
  Divider,
  Typography,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  BaseUrl,
  CoBroswseUrl,
  emailservice,
} from "../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { MdChat, MdOpenInBrowser } from "react-icons/md";
import { BiArrowFromRight } from "react-icons/bi";
import { IoVideocamOutline } from "react-icons/io5";
import { ImDownload } from "react-icons/im";
import { FaBusinessTime } from "react-icons/fa";
import AlarmIcon from "@mui/icons-material/Alarm";
import { yellow } from "@mui/material/colors";

// Component
import VoiceMenu from "./Voice-menu.component";
import EmailMenu from "./Email-menu.component";
import ChatMenu from "./Chat-Menu.component";
import Message from "./Message.component";
import NewEmail from "../../../Page/EmailComponents/Inbox/NewEmail";
import SdkCallScreen from "../../DialerComponent/SdkCallScreen";
import Cobrowser from "./chat-component-model/Co-browser";
import InternetError from "../../InternetError-component/useOnlineStatus ";
import Endchatmodel from "./chat-component-model/End-chat-model";
import EndChatModel from "./chat-component-model/End-chat-model";
import { PlusSquare } from "@phosphor-icons/react";
import AddTopic from "./chat-component-model/add-topic-model";
import UserListComponent from "../User-list-component/User-list.component";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  const { data, contactListData } = state;
  return {
    selectedusername: data.selectedusername,
    chatdata: data.chatdata,
    contactList: contactListData.contactList,
    externalChatData: data.externalChatData,
    selectedchanneldata: data.selectedchanneldata,
    updatechanneldata: data.updatechanneldata,
    setselectedemail: data.setselectedemail,
    forwardmail: data.forwardmail,
    showExternalChat: state.data.showExternalChat,
    showInternalChat: state.data.showInternalChat,
    showCard: state.data.showCard,
    loadmoretoggle: state.data.loadmoretoggle,
    loadmoreUser: state.data.loadmoreUser,
    voiceSessionId: state.data.voiceSessionId,
    seletedVoiceSessionId: state.data.seletedVoiceSessionId,
    calledagentscreen: state.data.calledagentscreen,
    showVoiceCall: state.data.showVoiceCall,
    iswrite: state.data.iswrite,
    couplemail: state.data.couplemail,
    editButton: state.data.editButton,
    userlistshow: state.data.userlistshow,
    value: data.value,
    index: contactListData.index,
    index2: contactListData.index2,
  };
};
const ChatComponent = (props) => {
  const {
    handleTyping,
    data,
    rendermsgcomp,
    setrendermsgcomp,
    OpenDialog,
    handleClose,
    value,
  } = props;

  // Tooltip
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const isDisabled = () => {
    if (!selectedtime || !selecteddate) return true;

    const currentDate = new Date(selecteddate);
    const currentTime = new Date();
    const selectedDateTime = new Date(currentDate);

    const [hours, minutes] = selectedtime.split(":");
    const selectedHours = parseInt(hours, 10);
    const selectedMinutes = parseInt(minutes, 10);

    if (selectedtime.includes("PM")) {
      selectedDateTime.setHours(selectedHours + 12);
    } else if (selectedtime.includes("AM")) {
      selectedDateTime.setHours(selectedHours);
    }

    selectedDateTime.setMinutes(selectedMinutes);
    return selectedDateTime < currentTime;
  };

  const handleClosemodal = () => {
    setShow(false);
  };

  const handleReschedule = () => {
    if (isDisabled()) {
      setTimeerror(true);
    } else {
      setTimeerror(false);
      rescheduleMailApi();
    }
  };

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
  const chatSessionID = JSON.parse(localStorage.getItem("client"));

  const [outsideclickclosecard, setOutsideclickclosecard] = useState(false);
  let permission = JSON.parse(localStorage.getItem("permission"));

  const [timeerror, setTimeerror] = useState(false);
  const [selectedtime, setSelectedtime] = useState("");
  const [selecteddate, setSelecteddate] = useState("");

  const [actionView, setActionView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueIndex, setValueIndex] = useState({});
  const [isReplyEmail, setIsReplyEmail] = useState(false);
  const [toSendlist, setToSendlist] = useState([]);
  const [toCCSendlist, setToCCSendlist] = useState([]);
  const [toBCCSendlist, setToBCCSendlist] = useState([]);
  const [showcobrowse, setShowcobrowse] = useState(false);
  const [cobrowswerinput, setCobrowserInput] = useState([]);
  const [emailbodymsg, setEmailbodymsg] = useState("");
  const [cobrowserdata, setCobrowserdata] = useState("");
  const [videoCalldata, setvideoCalldata] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [originalattachments, setOriginalAttachments] = useState([]);
  const [forwardmaillistdata, setForwardmaillistdata] = useState([]);

  const [emailfetch, setemailfetch] = useState(false);
  const [UrlvalidationError, setUrlValidationError] = useState(false);
  const [endchatModalDialog, setendchatModalDialog] = useState(false);
  const [resheduleemail, setResheduleemail] = useState(false);

  const [openselectedurl, setOpenselectedurl] = useState("");
  const [show, setShow] = useState(false);
  const [showAddTopic, setShowAddTopic] = useState(false);

  // const handleClosemodal = () => setShow(false);
  const handleShowmodal = () => setShow(true);

  const handleClickHover = (item, event) => {
    setOpenselectedurl(item);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const EndChatDialogClose = () => {
    setendchatModalDialog(false);
  };
  const isOnline = InternetError();
  const handleAddTopicOpen = () => setShowAddTopic(true);
  const handleAddTopicClose = () => setShowAddTopic(false);

  const handleCobrowseShow = () => setShowcobrowse(true);
  const ActivateVideoCallChat = async () => {
    await axios
      .post(
        BaseUrl + "/users/videourl",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          let url = res.data.leader_link;
          var width = 500;
          var height = 400;
          var left = (window.innerWidth - width) / 2;
          var top = (window.innerHeight - height) / 2;
          window.open(
            url,
            "Popup",
            "width=" +
              width +
              ", height=" +
              height +
              ", left=" +
              left +
              ", top=" +
              top
          );
          setvideoCalldata(res.data.follower_link);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.log("AgentContact", error);
      });
  };

  const handleCobrowseClose = () => {
    if (!UrlvalidationError) {
      setShowcobrowse(false);
    } else {
      setShowcobrowse(false);
      setUrlValidationError("");
    }
  };

  const toggleCard = () => {
    props.setShowCard(!props.showCard);
  };
  const closeuserNotification = () => {
    setOutsideclickclosecard(false);
  };
  const showallbuttondiv = () => {
    setOutsideclickclosecard(true);
  };

  const showUserlist = () => {
    props.setUserlistShow(!props.userlistshow);
    props.setloadmoreUser(!props.loadmoreUser);
  };

  const openActionView = (e, value) => {
    setActionView(true);
    setAnchorEl(e.currentTarget);
    setValueIndex(value);
  };

  const closeActionView = () => {
    setActionView(false);
    setAnchorEl(null);
    setValueIndex({});
  };

  const openDocument = (item) => {
    window.open(openselectedurl.url, "_blank");
  };

  const handelOpenReplayEmail = () => {
    props.setcouplemail(false);
    props.setIswrite(true);
  };

  const handelOpenForwardEmail = () => {
    props.setForwardmail(true);
  };

  const handelCloseReplayEmail = () => {
    props.setIswrite(false);
    props.setcouplemail(true);
  };
  const [mail, setmail] = useState();
  const SendEmail = (obj) => {
    let myHeaders = new Headers();
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);

    let data = obj;
    let requestOptions = {
      method: "POST",
      body: data,
      headers: myHeaders,
    };
    fetch(SEND_MAIL_CONTENT, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        props.setChatRefresh(true);
        fetchSingleEmail();
      })
      .catch((error) => {
        // dispatch(InboxErrorMessage(error, 'red'))
      });
  };

  const forwordMail = (obj) => {
    let myHeaders = new Headers();
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);

    let data = obj;
    let requestOptions = {
      method: "POST",
      body: data,
      headers: myHeaders,
    };
    fetch(FORWARD_MAIL_CONTENT, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        props.setChatRefresh(true);
        fetchSingleEmail();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        // dispatch(InboxErrorMessage(error, 'red'))
      });
  };

  const fetchSingleEmail = () => {
    const clientJSON = localStorage.getItem("client");
    const clientObj = JSON.parse(clientJSON);
    axios
      .post(
        emailservice + "email/fetchEmailNew",
        {
          ticketID: clientObj.ticketID,
          agentID: clientObj.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          props.setSelectedchanneldata(response.data.Data);
          props.setUpdatechanneldata(response.data.EmailBody);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickReply = (mail) => {
    props.setForwardmail(false);
    setForwardmaillistdata([]);
    setEmailbodymsg("");
    setAttachment([]);
    setmail(mail);
    const temp = [];
    const tempCC = [];
    const tempBCC = [];

    const emailArray = Array.isArray(props.selectedchanneldata)
      ? props.selectedchanneldata
      : [props.selectedchanneldata];

    emailArray.forEach((emailData) => {
      const tempStr = emailData.Email.fromList;
      const tempArr = tempStr.split(",");

      tempArr.forEach((val) => {
        const startIndex = val.indexOf("<");
        const endIndex = val.lastIndexOf(">");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          const fromEmail = val.slice(startIndex + 1, endIndex);
          temp.push(fromEmail);
        }
      });
    });

    const temp2 = new Set([...temp]);
    const temp3 = [...temp2];
    setToSendlist(temp3);
    setToCCSendlist(tempCC);
    setToBCCSendlist(tempBCC);
    props.openReplyEmail();
    setIsReplyEmail(true);
    handelOpenReplayEmail();
  };

  const endSupervisorMailApi = () => {
    const clientJSON = localStorage.getItem("tokenAgent");
    const clientObj = JSON.parse(clientJSON);
    axios
      .post(
        BaseUrl + "/email/endSupervisorMail",
        {
          ticketID: props.selectedchanneldata[0].Email.ticketID,
          emailId: props.selectedchanneldata[0].Email._id,
          user_id: clientObj.user_id,
          agent_id: clientObj.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          localStorage.removeItem("client");
          props.setshowExternalChat(false);
          props.setcontactlist(response.data.data);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickEdit = (mail) => {
    props.setForwardmail(false);
    setmail(mail);
    setEmailbodymsg(mail.Email.emailBody);
    const originalAttachments = mail.Email.attachments;
    setOriginalAttachments(originalAttachments);
    const editedAttachments = [...originalAttachments];
    setAttachment(editedAttachments);

    const temp = [];
    const tempCC = [];
    const tempBCC = [];

    const emailArray = Array.isArray(props.selectedchanneldata)
      ? props.selectedchanneldata
      : [props.selectedchanneldata];

    emailArray.forEach((emailData) => {
      const tempStr = emailData.Email.fromList;
      const tempArr = tempStr.split(",");

      tempArr.forEach((val) => {
        const startIndex = val.indexOf("<");
        const endIndex = val.lastIndexOf(">");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          const fromEmail = val.slice(startIndex + 1, endIndex);
          temp.push(fromEmail);
        }
      });

      setAttachment(editedAttachments);
    });

    const temp2 = new Set([...temp]);
    const temp3 = [...temp2];
    setToSendlist(temp3);
    setToCCSendlist(tempCC);
    setToBCCSendlist(tempBCC);
    props.openReplyEmail();
    setIsReplyEmail(true);
    handelOpenReplayEmail();
  };

  const SendEmailSupervisor = (obj) => {
    let myHeaders = new Headers();
    const access_token = localStorage.getItem("access_token");
    myHeaders.append("Authorization", "Bearer " + access_token);

    let data = obj;
    let requestOptions = {
      method: "POST",
      body: data,
      headers: myHeaders,
    };
    fetch(SEND_MAIL_CONTENT_SUPERVISOR, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          toast.success(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(json.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        fetchSingleEmail();
      })
      .catch((error) => {
        console.log("error><><><><><><><><", error);
      });
  };

  const onClickApproveMail = async (mail) => {
    props.setForwardmail(false);
    setmail(mail);
    const temp = [];
    const tempCC = [];
    const tempBCC = [];

    const emailArray = Array.isArray(props.selectedchanneldata)
      ? props.selectedchanneldata
      : [props.selectedchanneldata];

    emailArray.forEach((emailData) => {
      const tempStr = emailData.Email.fromList;
      const tempArr = tempStr.split(",");

      tempArr.forEach((val) => {
        const startIndex = val.indexOf("<");
        const endIndex = val.lastIndexOf(">");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          const fromEmail = val.slice(startIndex + 1, endIndex);
          temp.push(fromEmail);
        }
      });
    });

    const temp2 = new Set([...temp]);
    const temp3 = [...temp2];
    setToSendlist(temp3);
    setToCCSendlist(tempCC);
    setToBCCSendlist(tempBCC);

    // Call the API
    const apiUrl = emailservice + "email/sendEmailApproval";

    // Add a check for mail.Email and mail.Email.attachments
    const attachments = mail?.Email?.attachments || [];

    const payload = {
      files: attachments,
      email: mail.Email.toList || "",
      subject: mail.Email.subject || "",
      ccData: mail.Email.ccList || "",
      bccData: mail.Email.bccList || "",
      html: mail.EmailBody || "",
      ticketID: mail.Email.ticketID || "",
      types: "Approve",
    };

    try {
      const response = await axios.post(apiUrl, payload);

      // Handle success
      if (response.status === 200) {
        // props.setEditbutton(false)
        endSupervisorMailApi();
        setTimeout(() => {
          setChatRefresh();
        }, 1000);
        toast.success("Email Sent Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Handle error
        toast.error("Email Sent Failed", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error sending email approval request:", error);
    }

    // props.openReplyEmail();
    // setIsReplyEmail(true);
  };

  const onClickReplyAll = (mail) => {
    props.setForwardmail(false);
    setForwardmaillistdata([]);
    setEmailbodymsg("");
    setmail(mail);

    const temp = [];
    const tempCC = mail.Email.ccList || [];
    const tempBCC = mail.Email.bccList || [];

    // Parse 'To' recipients from the clicked mail
    const emailArray = Array.isArray(props.selectedchanneldata)
      ? props.selectedchanneldata
      : [props.selectedchanneldata];

    emailArray.forEach((emailData) => {
      const tempStr = emailData.Email.fromList;
      const tempArr = tempStr.split(",");

      tempArr.forEach((val) => {
        const startIndex = val.indexOf("<");
        const endIndex = val.lastIndexOf(">");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          const fromEmail = val.slice(startIndex + 1, endIndex);
          temp.push(fromEmail);
        }
      });
    });

    const temp2 = new Set([...temp]);
    const temp3 = [...temp2];
    setToSendlist(temp3);

    // Set 'CC' recipients from the clicked mail

    if (mail.Email.ccList && mail.Email.ccList.length > 0) {
      const tempCCSet = new Set([...mail.Email.ccList]);
      const tempCCArr = [...tempCCSet];
      setToCCSendlist(tempCCArr);
    } else {
      setToCCSendlist([]);
    }

    // Set 'BCC' recipients from the clicked mail

    if (mail.Email.bccList && mail.Email.bccList.length > 0) {
      const tempBCCSet = new Set([...mail.Email.bccList]);
      const tempBCCArr = [...tempBCCSet];
      setToBCCSendlist(tempBCCArr);
    } else {
      setToBCCSendlist([]);
    }

    props.openReplyEmail();
    setIsReplyEmail(true);
    handelOpenReplayEmail();
  };

  const OnClickForward = (mail) => {
    props.setForwardmail(true);
    const apiUrl = emailservice + "email/listEmail";

    const payload = {
      id: mail.Email._id,
    };

    axios
      .post(apiUrl, payload)
      .then((response) => {
        setForwardmaillistdata(response.data.EmailList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setmail(mail);
    setEmailbodymsg(mail.Email.emailBody);
    const originalAttachments = mail.Email.attachments;
    setOriginalAttachments(originalAttachments);
    const editedAttachments = [...originalAttachments];
    setAttachment(editedAttachments);

    const temp = [];
    const tempCC = [];
    const tempBCC = [];

    const emailArray = Array.isArray(props.selectedchanneldata)
      ? props.selectedchanneldata
      : [props.selectedchanneldata];

    emailArray.forEach((emailData) => {
      const tempStr = emailData.Email.fromList;
      const tempArr = tempStr.split(",");

      tempArr.forEach((val) => {
        const startIndex = val.indexOf("<");
        const endIndex = val.lastIndexOf(">");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          const fromEmail = val.slice(startIndex + 1, endIndex);
          temp.push(fromEmail);
        }
      });

      setAttachment(editedAttachments);
    });

    const temp2 = new Set([...temp]);
    const temp3 = [...temp2];
    setToSendlist([]);
    setToCCSendlist([]);
    setToBCCSendlist([]);
    // setToCCSendlist(tempCC);
    // setToBCCSendlist(tempBCC);
    props.openReplyEmail();
    setIsReplyEmail(true);
    handelOpenReplayEmail();
    // endSupervisorMailApi()
  };

  const CobrosweData = async () => {
    if (cobrowswerinput == "") {
      setUrlValidationError("Please enter a valid URL before proceding");
      return;
    }
    try {
      let data = {
        url: cobrowswerinput,
        agent_id: "313007",
        tags: ["tag1", "tag2"],
        hide_selector:
          "#input1,#input2,#emailID,#empAadhaar,#uan,#cust_PAN, #mobileinfo_Pan",
      };
      const response = await axios.post(CoBroswseUrl, data);
      setCobrowserdata(response.data.follower_link);
      window.open(response.data.leader_link);
      setCobrowserInput("");
      setShowcobrowse(false);
    } catch (error) {}
  };

  const emailDownloadAsPdf = (mail) => {
    axios
      .post(
        emailservice + "/email/emailDownloadAsPdf",
        {
          id: mail.Email._id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          let downloadEmailasPdf = emailservice + "files" + response.data.data;
          FileSaver.saveAs(downloadEmailasPdf);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const rescheduleMailApi = () => {
    const agent_Id = JSON.parse(localStorage.getItem("tokenAgent"));
    const session_id = JSON.parse(localStorage.getItem("client"));

    axios
      .post(
        BaseUrl + "/email/Reschduleemail",
        {
          session_id: session_id.chat_session_id,
          redate: selecteddate,
          time: selectedtime,
          agent_id: agent_Id.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            Tenantid: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setSelecteddate("");
          setSelectedtime(null);
          props.setChatRefresh(true);
          props.setshowExternalChat(false);
          props.setUserlistShow(false);
          handleClosemodal();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSelecteddate("");
          setSelectedtime("");
          handleClosemodal();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleTimeChange = (e) => {
    const rawTime = e.target.value;
    const formattedTime = moment(rawTime, "HH:mm").format("LT");
    setSelectedtime(formattedTime);
  };

  const selectedTimeForInput = moment(selectedtime, "h:mm A").format("HH:mm");

  return (
    <>
      {(() => {
        switch (value) {
          case 1:
            switch (
              props.externalChatData ? props.externalChatData.chat_type : ""
            ) {
              case "internal":
                return (
                  <Grid item xs={9} md={9}>
                    <div className="border-bottom p-0">
                      <div className="chat-profile d-flex justify-content-between align-items-center p-2">
                        <div className="d-flex me-1">
                          <div
                            className="col-2 me-2 position-relative"
                            style={{
                              background: "#0B3363",
                              display: "block",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              border: "2px solid white",
                              boxShadow:
                                "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
                            }}
                          >
                            <p
                              className=" chat_name"
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                marginTop: "8px",
                              }}
                            >
                              <b
                                className="h-100"
                                style={{
                                  color: "#FFFF",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {props.selectedusername
                                  ? props.selectedusername
                                      .slice(0, 2)
                                      .toUpperCase()
                                  : ""}
                              </b>
                            </p>
                          </div>
                          <div className="chat-user-main d-flex">
                            <div className="chat-details me-1">
                              <div className="d-flex align-items-center">
                                <div
                                  className="name me-1 my-2"
                                  style={{ fontWeight: "700" }}
                                >
                                  {props.selectedusername}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isOnline && (
                      <div
                        style={{
                          backgroundColor: "#FF5733",
                          textAlign: "center",
                          fontSize: "20px",
                        }}
                      >
                        <p>
                          Please connect the internet while using chat page!
                        </p>
                      </div>
                    )}
                    <Message
                      component={true}
                      cobrowserdata={cobrowserdata}
                      value={value}
                      showinput={true}
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid item xs={9} md={9}>
                    <div className="d-flex justify-content-between p-2">
                      <div
                        className="d-flex align-item-center mt-3"
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "150px",
                      }}
                    >
                      <MdChat style={{ fontSize: "4rem" }} color="gray" />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Select Interaction
                      </span>
                    </div>
                  </Grid>
                );
            }
          case 0:
            switch (
              props.externalChatData ? props.externalChatData.channel : ""
            ) {
              case "email" || props.forwardmail:
                return (
                  <Grid item xs={9} md={9}>
                    <Grid container direction="row" sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={props.userlistshow ? 7 : 12}
                        md={props.userlistshow ? 7 : 12}
                        sx={{
                          borderRight: props.userlistshow
                            ? "3px solid #dedede"
                            : "",
                        }}
                      >
                        <div className="border-bottom p-0">
                          <div className="chat-profile d-flex justify-content-between align-items-center p-2">
                            <div>
                              <div className="d-flex me-1">
                                <div
                                  className="col-2 me-2  position-relative"
                                  style={{
                                    background: "#0B3363",
                                    display: "block",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: "2px solid white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
                                  }}
                                >
                                  <p
                                    className=" chat_name"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <b
                                      className="h-100"
                                      style={{
                                        color: "#FFFF",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {props.selectedusername
                                        ? props.selectedusername
                                            .slice(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </b>
                                  </p>
                                </div>

                                <div className="chat-user-main d-flex">
                                  <div className="chat-details me-1">
                                    <div className="d-flex align-items-center">
                                      <div className="name d-inline-block text-truncate me-1">
                                        {props.externalChatData
                                          ? props.externalChatData.unique_id
                                              .username
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              {/* Menu Dot */}
                              {props.externalChatData
                                .is_customer_disconnected == false ? (
                                <>
                                  {/* Reshedule Email Modal  */}
                                  <Dialog
                                    open={show}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClosemodal}
                                    aria-describedby="alert-dialog-slide-description"
                                    sx={{
                                      "& .MuiPaper-root": {
                                        borderRadius: 5,
                                        padding: 1,
                                        top: -100,
                                        width: 400,
                                      },

                                      backgroundColor: "rgb(0 0 0 / 65%)",
                                    }}
                                  >
                                    <DialogTitle
                                      variant="subtitle2"
                                      sx={{ fontSize: "16px", fontWeight: 600 }}
                                    >
                                      Reschedule
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-slide-description">
                                        <input
                                          value={selecteddate}
                                          className="form-control"
                                          onChange={(e) =>
                                            setSelecteddate(e.target.value)
                                          }
                                          type="date"
                                          style={{ marginBottom: "13px" }}
                                          min={
                                            new Date()
                                              .toISOString()
                                              .split("T")[0]
                                          }
                                        />
                                        <input
                                          value={selectedTimeForInput}
                                          className="form-control"
                                          onChange={handleTimeChange}
                                          type="time"
                                          style={{ marginBottom: "0px" }}
                                          min={new Date()
                                            .toLocaleTimeString([], {
                                              hour12: false,
                                            })
                                            .slice(0, 5)}
                                        />

                                        {timeerror ? (
                                          <span
                                            className="text-danger"
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Can Not Reschedule Before Current
                                            Time *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        variant="contained"
                                        onClick={
                                          handleReschedule

                                          // rescheduleMailApi
                                        }
                                        disabled={isDisabled()}
                                        endIcon={<AlarmIcon />}
                                        size="small"
                                      >
                                        Reschedule
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                  {/* Reshedule Email Modal  */}

                                  <BootstrapTooltip
                                    title="Reschedule"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      onClick={() => {
                                        handleShowmodal();
                                      }}
                                    >
                                      <FaBusinessTime
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                  </BootstrapTooltip>

                                  <button
                                    type="button"
                                    id="threedot-togglecard"
                                    className="btn btn-outline-secondary border-0 btn-sm"
                                    data-tippy-content=" Screen Share"
                                    onClick={() => {
                                      toggleCard();
                                    }}
                                  >
                                    <BsThreeDotsVertical
                                      className="icon_top-btn_small"
                                      size={20}
                                    />
                                  </button>

                                  <BootstrapTooltip
                                    title="More Details"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      id="more_details"
                                      onClick={() => showUserlist()}
                                    >
                                      {props.userlistshow ? (
                                        <BsFillArrowRightCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      ) : (
                                        <BsFillArrowLeftCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      )}
                                    </button>
                                  </BootstrapTooltip>
                                </>
                              ) : (
                                <>
                                  <BootstrapTooltip
                                    title="End Interaction"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      id="close-circle"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        setendchatModalDialog(true);
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    title="More Details"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      id="more_details"
                                      onClick={() => showUserlist()}
                                    >
                                      {props.userlistshow ? (
                                        <BsFillArrowRightCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      ) : (
                                        <BsFillArrowLeftCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      )}
                                    </button>
                                  </BootstrapTooltip>
                                </>
                              )}
                              {props.showCard && (
                                <EmailMenu
                                  toggleCard={toggleCard}
                                  // userlistshow={userlistshow}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {!isOnline && (
                          <div
                            style={{
                              backgroundColor: "#FF5733",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            <p>
                              Please connect the internet while using email
                              page!
                            </p>
                          </div>
                        )}
                        <div
                          className="couple-email"
                          style={{ overflow: "scroll", height: "75vh" }}
                        >
                          {props.couplemail ? (
                            <>
                              {props.selectedchanneldata ? (
                                props.selectedchanneldata.map((mail, index) => {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className="p-1 "
                                        style={{
                                          width: props.userlistshow
                                            ? "36.8em"
                                            : "66em",
                                          paddingLeft: props.userlistshow
                                            ? "20px"
                                            : "",
                                        }}
                                      >
                                        <div style={{ width: "" }}>
                                          {mail.length != 0 ? (
                                            <>
                                              <div
                                                className="email-desc-wrapper"
                                                style={{
                                                  minWidth: props.userlistshow
                                                    ? "28.8em"
                                                    : "60em",
                                                }}
                                              >
                                                <div className="title-subject">
                                                  <h5>{mail.Email.subject}</h5>
                                                </div>
                                                <Accordion
                                                  style={{
                                                    marginTop: "15px",
                                                  }}
                                                >
                                                  <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                  >
                                                    <Typography>
                                                      <div className="d-flex">
                                                        <div className="email-header d-flex w-100">
                                                          <div
                                                            className="avatar-initials d-flex my-2"
                                                            style={{
                                                              background:
                                                                randomColors[
                                                                  index
                                                                ],
                                                              width: "38px",
                                                              height: "38px",
                                                              borderRadius:
                                                                "50%",
                                                              color: "gray",
                                                              fontSize: "16px",
                                                              fontWeight:
                                                                "bold",
                                                              position:
                                                                "relative",
                                                              boxShadow:
                                                                "rgb(0 0 0 / 0%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 2px",
                                                            }}
                                                          >
                                                            <span className="d-flex justify-content-center align-items-center mx-auto">
                                                              {mail.Email.fromList
                                                                .substring(0, 2)
                                                                .toUpperCase()}
                                                            </span>
                                                          </div>

                                                          <div className="address mx-2 my-2 ">
                                                            {/* <p
                                                  className="recipient"
                                                  id="from"
                                                ></p> */}
                                                            <p
                                                              className="recipient fs-6 text-break"
                                                              id="to"
                                                              style={{
                                                                width: "24rem",
                                                              }}
                                                            >
                                                              <span>
                                                                To:
                                                                <span className="text-break w-100">
                                                                  {
                                                                    mail.Email
                                                                      .toList
                                                                  }
                                                                </span>
                                                              </span>
                                                            </p>
                                                            {mail.ccList ? (
                                                              <p
                                                                className="recipient fs-6"
                                                                id="cc"
                                                              >
                                                                <span>
                                                                  Cc:
                                                                  {
                                                                    mail.Email
                                                                      .ccList
                                                                  }
                                                                </span>
                                                              </p>
                                                            ) : null}

                                                            <p
                                                              className="recipient fs-6"
                                                              id="date"
                                                            >
                                                              <span>
                                                                {moment(
                                                                  mail.Email
                                                                    .receivedTime
                                                                ).format(
                                                                  "MMMM Do YYYY, h:mm A"
                                                                )}
                                                              </span>
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <BootstrapTooltip
                                                          title="Download"
                                                          arrow
                                                          placement="top"
                                                        >
                                                          <div className="d-flex justify-content-end h-0">
                                                            <ImDownload
                                                              id="mail"
                                                              size={20}
                                                              color="gray"
                                                              onClick={() =>
                                                                emailDownloadAsPdf(
                                                                  mail
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </BootstrapTooltip>
                                                      </div>
                                                    </Typography>
                                                  </AccordionSummary>

                                                  <AccordionDetails>
                                                    {mail.Email.attachments
                                                      .length > 0 ? (
                                                      <>
                                                        {mail.Email.attachments.map(
                                                          (item, index) => (
                                                            <div className="p-1">
                                                              <div>
                                                                <div
                                                                  key={index}
                                                                  aria-describedby={
                                                                    id
                                                                  }
                                                                  variant="contained"
                                                                >
                                                                  {item.filename.includes(
                                                                    ".pdf"
                                                                  ) && (
                                                                    <div
                                                                      className={`card d-flex ${
                                                                        props.userlistshow
                                                                          ? "w-50"
                                                                          : "w-25"
                                                                      }`}
                                                                    >
                                                                      <div
                                                                        className="card d-flex"
                                                                        id="mail"
                                                                        onClick={(
                                                                          event
                                                                        ) => {
                                                                          handleClickHover(
                                                                            item,
                                                                            event
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="card-body d-flex">
                                                                          <img
                                                                            src={
                                                                              pdf
                                                                            }
                                                                            height="25px"
                                                                          />
                                                                          <b className="text-truncate mx-1">
                                                                            {
                                                                              item.filename
                                                                            }
                                                                          </b>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                  {item.filename.includes(
                                                                    ".xlsx"
                                                                  ) && (
                                                                    <div
                                                                      className={`card d-flex ${
                                                                        props.userlistshow
                                                                          ? "w-50"
                                                                          : "w-25"
                                                                      }`}
                                                                      onClick={(
                                                                        event
                                                                      ) => {
                                                                        handleClickHover(
                                                                          item,
                                                                          event
                                                                        );
                                                                      }}
                                                                    >
                                                                      <div className="card d-flex">
                                                                        <div className="card-body d-flex">
                                                                          <img
                                                                            src={
                                                                              excel
                                                                            }
                                                                            height="25px"
                                                                          />
                                                                          <b className="text-truncate mx-1">
                                                                            {
                                                                              item.filename
                                                                            }
                                                                          </b>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}

                                                                  {(item.filename.includes(
                                                                    ".docx"
                                                                  ) ||
                                                                    item.filename.includes(
                                                                      ".doc"
                                                                    )) && (
                                                                    <div
                                                                      className={`card d-flex ${
                                                                        props.userlistshow
                                                                          ? "w-50"
                                                                          : "w-25"
                                                                      }`}
                                                                    >
                                                                      <div
                                                                        className="card d-flex"
                                                                        id="mail"
                                                                        onClick={(
                                                                          event
                                                                        ) => {
                                                                          handleClickHover(
                                                                            item,
                                                                            event
                                                                          );
                                                                        }}
                                                                      >
                                                                        <div className="card-body d-flex">
                                                                          <img
                                                                            src={
                                                                              word
                                                                            }
                                                                            height="25px"
                                                                            alt=".docx or .doc"
                                                                          />
                                                                          <b className="text-truncate mx-1">
                                                                            {
                                                                              item.filename
                                                                            }
                                                                          </b>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}

                                                                  {item.filename.includes(
                                                                    ".png"
                                                                  ) && (
                                                                    <img
                                                                      src={
                                                                        item.url
                                                                      }
                                                                      id="mail"
                                                                      height="80px"
                                                                      width="auto"
                                                                      onClick={(
                                                                        event
                                                                      ) => {
                                                                        handleClickHover(
                                                                          item,
                                                                          event
                                                                        );
                                                                      }}
                                                                    />
                                                                  )}
                                                                  {item.filename.includes(
                                                                    ".jpg"
                                                                  ) && (
                                                                    <img
                                                                      src={
                                                                        item.url
                                                                      }
                                                                      id="mail"
                                                                      height="80px"
                                                                      width="auto"
                                                                      onClick={(
                                                                        event
                                                                      ) => {
                                                                        handleClickHover(
                                                                          item,
                                                                          event
                                                                        );
                                                                      }}
                                                                    />
                                                                  )}
                                                                  {item.filename.includes(
                                                                    ".jpeg"
                                                                  ) && (
                                                                    <img
                                                                      src={
                                                                        item.url
                                                                      }
                                                                      id="mail"
                                                                      height="80px"
                                                                      width="auto"
                                                                      onClick={(
                                                                        event
                                                                      ) => {
                                                                        handleClickHover(
                                                                          item,
                                                                          event
                                                                        );
                                                                      }}
                                                                    />
                                                                  )}

                                                                  <Popover
                                                                    id={id}
                                                                    open={open}
                                                                    anchorEl={
                                                                      anchorEl
                                                                    }
                                                                    onClose={
                                                                      handleCloseHover
                                                                    }
                                                                    anchorOrigin={{
                                                                      vertical:
                                                                        "bottom",
                                                                      horizontal:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    <Paper
                                                                      sx={{
                                                                        width: 200,
                                                                        maxWidth:
                                                                          "100%",
                                                                      }}
                                                                    >
                                                                      {openselectedurl ? (
                                                                        openselectedurl.filename.includes(
                                                                          ".png"
                                                                        ) ||
                                                                        openselectedurl.filename.includes(
                                                                          ".jpeg"
                                                                        ) ||
                                                                        openselectedurl.filename.includes(
                                                                          ".jpg"
                                                                        ) ? (
                                                                          <MenuList>
                                                                            <MenuItem
                                                                              id="open-doc"
                                                                              onClick={() =>
                                                                                openDocument(
                                                                                  item
                                                                                )
                                                                              }
                                                                            >
                                                                              <ListItemIcon>
                                                                                <VisibilityIcon fontSize="small" />
                                                                              </ListItemIcon>
                                                                              <ListItemText>
                                                                                Preview
                                                                              </ListItemText>
                                                                            </MenuItem>
                                                                            {/* <MenuItem>
                                                          <ListItemIcon>
                                                            <DownloadIcon fontSize="small" />
                                                          </ListItemIcon>
                                                          <ListItemText>
                                                            Download
                                                          </ListItemText>
                                                        </MenuItem> */}
                                                                          </MenuList>
                                                                        ) : (
                                                                          ""
                                                                        )
                                                                      ) : (
                                                                        ""
                                                                      )}

                                                                      {openselectedurl ? (
                                                                        openselectedurl.filename.includes(
                                                                          ".xlsx"
                                                                        ) ||
                                                                        openselectedurl.filename.includes(
                                                                          ".docx"
                                                                        ) ||
                                                                        openselectedurl.filename.includes(
                                                                          ".doc"
                                                                        ) ||
                                                                        openselectedurl.filename.includes(
                                                                          ".pdf"
                                                                        ) ? (
                                                                          <MenuList>
                                                                            {/* <MenuItem
                                                     onClick={() =>openDocument(item)                                                                                                                                       }
                                                   >
                                                
                                                     <ListItemIcon>
                                                       <VisibilityIcon fontSize="small" />
                                                     </ListItemIcon>
                                                     <ListItemText>
                                                       Preview
                                                     </ListItemText>
                                                 
                                                   </MenuItem> */}
                                                                            <MenuItem
                                                                              onClick={() =>
                                                                                openDocument(
                                                                                  item
                                                                                )
                                                                              }
                                                                            >
                                                                              <ListItemIcon>
                                                                                <DownloadIcon fontSize="small" />
                                                                              </ListItemIcon>
                                                                              <ListItemText>
                                                                                Download
                                                                              </ListItemText>
                                                                            </MenuItem>
                                                                          </MenuList>
                                                                        ) : (
                                                                          ""
                                                                        )
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </Paper>
                                                                  </Popover>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}

                                                    <Typography>
                                                      <div className="mailcontent">
                                                        <div className="email-body">
                                                          {mail.EmailBody
                                                            ? parse(
                                                                mail.EmailBody.replace(
                                                                  /\s+/g,
                                                                  " "
                                                                ).trim()
                                                              )
                                                            : ""}
                                                        </div>

                                                        {basedOnRole.role ==
                                                          "Supervisor" &&
                                                        mail.Email
                                                          .supervisorFlag ? (
                                                          <>
                                                            <button
                                                              className="btn btn-secondary btn-sm"
                                                              id="edit-mail"
                                                              onClick={() => {
                                                                // props.setEditbutton(true)
                                                                onClickEdit(
                                                                  mail,
                                                                  "editsupervisorMail"
                                                                );
                                                                localStorage.setItem(
                                                                  "editsupervisorMail",
                                                                  "true"
                                                                );
                                                              }}
                                                            >
                                                              <div className="d-flex justify-content-center align-items-center">
                                                                <span className="">
                                                                  <FiEdit />
                                                                </span>
                                                                <span className="mt-1 mx-1">
                                                                  Edit
                                                                </span>
                                                              </div>
                                                            </button>
                                                            <button
                                                              className="btn btn-base btn-primary btn-sm mx-2"
                                                              id="mail"
                                                              onClick={() => {
                                                                onClickApproveMail(
                                                                  mail
                                                                );
                                                              }}
                                                            >
                                                              <div className="d-flex justify-content-center align-items-center">
                                                                <span className="">
                                                                  <BsFillSendFill />
                                                                </span>
                                                                <span className="mx-1">
                                                                  Approve
                                                                </span>
                                                              </div>
                                                            </button>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <button
                                                              className="btn btn-outline-secondary btn-sm mx-1"
                                                              id="mail"
                                                              onClick={() => {
                                                                onClickReply(
                                                                  mail
                                                                );
                                                              }}
                                                            >
                                                              {" "}
                                                              <img
                                                                src={replynew}
                                                                height="15px"
                                                              />
                                                              <span className="mx-1">
                                                                Reply
                                                              </span>
                                                              &nbsp;
                                                            </button>
                                                            <button
                                                              id="mail"
                                                              className="btn btn-outline-secondary btn-sm mx-1"
                                                              onClick={() => {
                                                                onClickReplyAll(
                                                                  mail
                                                                );
                                                              }}
                                                            >
                                                              {" "}
                                                              <img
                                                                src={
                                                                  replyallnew
                                                                }
                                                                height="20px"
                                                              />{" "}
                                                              Reply All &nbsp;
                                                            </button>
                                                            <button
                                                              className="btn btn-outline-secondary btn-sm mx-1"
                                                              id="mail"
                                                              onClick={() => {
                                                                OnClickForward(
                                                                  mail
                                                                );
                                                              }}
                                                            >
                                                              {" "}
                                                              <img
                                                                src={forwardnew}
                                                                height="15px"
                                                              />{" "}
                                                              Forward
                                                            </button>
                                                          </>
                                                        )}
                                                      </div>
                                                    </Typography>
                                                  </AccordionDetails>
                                                </Accordion>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              className="recive-hed"
                                              style={{ width: "100%" }}
                                            ></div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {props.iswrite ? (
                            <>
                              <div className="">
                                <div className="float-right my-1">
                                  <BiArrowFromRight
                                    size={25}
                                    id="close-replaymail"
                                    onClick={handelCloseReplayEmail}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <NewEmail
                                action={props}
                                inboxContent={isReplyEmail ? mail.Email : null}
                                agentId={mail ? mail.Email.agentID : []}
                                toSendlist={isReplyEmail ? toSendlist : []}
                                toCCSendlist={isReplyEmail ? toCCSendlist : []}
                                toBCCSendlist={
                                  isReplyEmail ? toBCCSendlist : []
                                }
                                attachment={attachment ? attachment : []}
                                setAttachment={setAttachment}
                                sendMail={SendEmail}
                                closeReplaypage={handelCloseReplayEmail}
                                // userlistshow={userlistshow}
                                forwardMail={forwordMail}
                                forwardemailtoggle={props.forwardmail}
                                emailbodymsg={emailbodymsg ? emailbodymsg : ""}
                                // attachment={attachment?attachment:[]}
                                onClickApproveMail={onClickApproveMail}
                                // onClickApproveMail={onClickApproveMail}
                                SendEmailSupervisor={SendEmailSupervisor}
                                forwardmaillistdata={forwardmaillistdata}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={props.userlistshow ? 5 : 0}
                        md={props.userlistshow ? 5 : 0}
                      >
                        {(() => {
                          switch (props.userlistshow) {
                            case true:
                              return <UserListComponent />;
                            default:
                              return <>hello</>;
                          }
                        })()}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              case "voice":
                return (
                  <Grid item xs={9} md={9}>
                    <Grid container direction="row" sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={props.userlistshow ? 7 : 12}
                        md={props.userlistshow ? 7 : 12}
                        sx={{
                          borderRight: props.userlistshow
                            ? "3px solid #dedede"
                            : "",
                        }}
                      >
                        {props.showVoiceCall &&
                        props.seletedVoiceSessionId == props.voiceSessionId ? (
                          <div
                            className="border-bottom p-0"
                            style={{
                              height: props.userlistshow
                                ? props.calledagentscreen == true
                                  ? "140px"
                                  : "75px"
                                : "75px",
                            }}
                          >
                            <div className="d-flex justify-content-between p-2">
                              <div className="me-1">
                                {props.seletedVoiceSessionId ==
                                  props.voiceSessionId && <SdkCallScreen />}
                              </div>

                              <div
                                className="d-flex align-item-center mt-3"
                                style={{ height: "30px" }}
                              >
                                {props.externalChatData
                                  .is_customer_disconnected == false ? (
                                  <>
                                    <button
                                      type="button"
                                      id="pills-togglecard"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        toggleCard();
                                      }}
                                    >
                                      <BsThreeDotsVertical
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                    {props.userlistshow ? (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="right-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    ) : (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="left-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <BootstrapTooltip
                                      title="End Interaction"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="end-modaldialog"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        data-tippy-content=" Screen Share"
                                        onClick={() => {
                                          setendchatModalDialog(true);
                                        }}
                                      >
                                        <AiOutlineCloseCircle
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                    {props.userlistshow ? (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="right-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    ) : (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="left-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    )}
                                  </>
                                )}
                                {props.showCard && (
                                  <VoiceMenu
                                    toggleCard={toggleCard}
                                    // userlistshow={userlistshow}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="border-bottom p-0">
                            <div className="chat-profile d-flex justify-content-between align-items-center p-2">
                              <div className="d-flex me-1">
                                <div
                                  className="col-2 me-2 position-relative"
                                  style={{
                                    background: "#0B3363",
                                    display: "block",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: "2px solid white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
                                  }}
                                >
                                  <p
                                    className=" chat_name"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <b
                                      className="h-100"
                                      style={{
                                        color: "#ffff",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {props.selectedusername
                                        ? props.selectedusername
                                            .slice(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </b>
                                  </p>
                                </div>

                                <div className="chat-user-main d-flex">
                                  <div className="chat-details me-1">
                                    <div className="d-flex align-items-center">
                                      <div className="name d-inline-block text-truncate me-1">
                                        {
                                          props.externalChatData.unique_id
                                            .username
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div>
                                {/* Menu Dot */}
                                {props.externalChatData
                                  .is_customer_disconnected == false ? (
                                  <>
                                    <button
                                      type="button"
                                      id="pills-togglecard"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        toggleCard();
                                      }}
                                    >
                                      <BsThreeDotsVertical
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                    {props.userlistshow ? (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="right-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    ) : (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="left-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <BootstrapTooltip
                                      title="End Interaction"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="end-modaldialog"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        data-tippy-content=" Screen Share"
                                        onClick={() => {
                                          setendchatModalDialog(true);
                                        }}
                                      >
                                        <AiOutlineCloseCircle
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                    {props.userlistshow ? (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="right-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    ) : (
                                      <BootstrapTooltip
                                        title="More Details"
                                        arrow
                                        placement="bottom"
                                      >
                                        <button
                                          type="button"
                                          id="left-arrow"
                                          className="btn btn-outline-secondary border-0 btn-sm"
                                          onClick={() => showUserlist()}
                                        >
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        </button>
                                      </BootstrapTooltip>
                                    )}
                                  </>
                                )}
                                {props.showCard && (
                                  <VoiceMenu
                                    toggleCard={toggleCard}
                                    // userlistshow={userlistshow}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {!isOnline && (
                          <div
                            style={{
                              backgroundColor: "#FF5733",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            <p>
                              Please connect the internet while using voicechat
                              page!
                            </p>
                          </div>
                        )}
                        <Message hideInput={true} component={true} />
                      </Grid>
                      <Grid
                        item
                        xs={props.userlistshow ? 5 : 0}
                        md={props.userlistshow ? 5 : 0}
                      >
                        {(() => {
                          switch (props.userlistshow) {
                            case true:
                              return <UserListComponent />;
                            default:
                              return <></>;
                          }
                        })()}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              case "videochat":
                return (
                  <Grid item xs={9} md={9}>
                    <Grid container direction="row" sx={{ height: "100%" }}>
                      <Grid
                        item
                        xs={props.userlistshow ? 7 : 12}
                        md={props.userlistshow ? 7 : 12}
                        sx={{
                          borderRight: props.userlistshow
                            ? "3px solid #dedede"
                            : "",
                        }}
                      >
                        <div className="border-bottom p-0">
                          <div className="chat-profile d-flex justify-content-between align-items-center p-2">
                            <div>
                              <div className="d-flex me-1">
                                <div
                                  className="col-2 me-2 position-relative"
                                  style={{
                                    background: "#0B3363",
                                    display: "block",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: "2px solid white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
                                  }}
                                >
                                  <p
                                    className=" chat_name"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <b
                                      className="h-100"
                                      style={{
                                        color: "#ffff",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {props.selectedusername
                                        ? props.selectedusername
                                            .slice(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </b>
                                  </p>
                                </div>
                                <div className="chat-user-main d-flex">
                                  <div className="chat-details me-1">
                                    <div className="d-flex align-items-center">
                                      <div className="name d-inline-block text-truncate me-1">
                                        {
                                          props.externalChatData.unique_id
                                            .username
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              {/* Menu Dot */}
                              {props.externalChatData
                                .is_customer_disconnected == false ? (
                                <>
                                  <BootstrapTooltip
                                    title="End Interaction"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      id="end-chatdialog"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        setendchatModalDialog(true);
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                  </BootstrapTooltip>
                                  {props.userlistshow ? (
                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="arrow-right"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        onClick={() => showUserlist()}
                                      >
                                        <BsFillArrowRightCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                  ) : (
                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="left-arrow"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        onClick={() => showUserlist()}
                                      >
                                        <BsFillArrowLeftCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                  )}
                                </>
                              ) : (
                                <>
                                  <BootstrapTooltip
                                    title="End Interaction"
                                    arrow
                                    placement="bottom"
                                  >
                                    <button
                                      type="button"
                                      id="end-chatdialog"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        setendchatModalDialog(true);
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>
                                  </BootstrapTooltip>
                                  {props.userlistshow ? (
                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="right-arrow"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        onClick={() => showUserlist()}
                                      >
                                        <BsFillArrowRightCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                  ) : (
                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="left-arrow"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        onClick={() => showUserlist()}
                                      >
                                        <BsFillArrowLeftCircleFill
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {!isOnline && (
                          <div
                            style={{
                              backgroundColor: "#FF5733",
                              textAlign: "center",
                              fontSize: "20px",
                            }}
                          >
                            <p>
                              Please connect the internet while using videochat
                              page!
                            </p>
                          </div>
                        )}
                        <div className="mx-5 mt-2  ">
                          <iframe
                            src={props.externalChatData.leader_link}
                            title="My iframe"
                            width={props.userlistshow ? "400" : "800"} // Adjust the width and height as needed
                            height="400"
                            frameBorder="0" // Remove iframe border if not needed
                            allowFullScreen // Add this attribute if you want to allow full-screen mode
                            allow="camera; microphone"
                          ></iframe>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={props.userlistshow ? 5 : 0}
                        md={props.userlistshow ? 5 : 0}
                      >
                        {(() => {
                          switch (props.userlistshow) {
                            case true:
                              return <UserListComponent />;
                            default:
                              return <>hello</>;
                          }
                        })()}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              case "webchat":
                return (
                  <>
                    <Grid item xs={9} md={9}>
                      <Grid container direction="row" sx={{ height: "100%" }}>
                        <Grid
                          item
                          xs={props.userlistshow ? 7 : 12}
                          md={props.userlistshow ? 7 : 12}
                          sx={{
                            borderRight: props.userlistshow
                              ? "3px solid #dedede"
                              : "",
                          }}
                        >
                          <div className="border-bottom p-0">
                            <div className="chat-profile d-flex justify-content-between align-items-center p-2">
                              <div className="d-flex me-1">
                                <div
                                  className="col-2 me-2 position-relative"
                                  style={{
                                    background: "#0B3363",
                                    display: "block",
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    border: "2px solid white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
                                  }}
                                >
                                  <p
                                    className=" chat_name"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "16px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <b
                                      className="h-100"
                                      style={{
                                        color: "#ffff",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {props.selectedusername
                                        ? props.selectedusername
                                            .slice(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </b>
                                  </p>
                                </div>

                                <div className="chat-user-main d-flex">
                                  <div className="chat-details me-1">
                                    <div className="d-flex align-items-center">
                                      <div className="name d-inline-block text-truncate me-1">
                                        {props.selectedusername}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {/* Menu Dot */}
                                {props.externalChatData
                                  .is_customer_disconnected == false ? (
                                  <>
                                    <BootstrapTooltip
                                      title="Add Topic"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="pills-togglecard-open"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        data-tippy-content=""
                                        onClick={() => {
                                          handleAddTopicOpen();
                                        }}
                                      >
                                        <PlusSquare
                                          className="icon_top-btn_small"
                                          size={22}
                                        />
                                      </button>
                                    </BootstrapTooltip>

                                    {permission.map((module) => {
                                      if (module.moduleName === "Chat") {
                                        const value = module.screen.find(
                                          (item) =>
                                            item.screenId === "COBROWSE" &&
                                            item.read &&
                                            item.write
                                        );

                                        if (value) {
                                          return (
                                            <BootstrapTooltip
                                              title="Co-Browser"
                                              arrow
                                              placement="bottom"
                                            >
                                              <button
                                                type="button"
                                                id="pills-togglecard-open"
                                                className="btn btn-outline-secondary border-0 btn-sm"
                                                data-tippy-content=""
                                                onClick={() => {
                                                  handleCobrowseShow();
                                                }}
                                              >
                                                <MdOpenInBrowser
                                                  className="icon_top-btn_small"
                                                  size={23}
                                                />
                                              </button>
                                            </BootstrapTooltip>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }
                                    })}

                                    {permission.map((module) => {
                                      if (module.moduleName === "Chat") {
                                        const value = module.screen.find(
                                          (item) =>
                                            item.screenId === "VIDEO" &&
                                            item.read &&
                                            item.write
                                        );

                                        if (value) {
                                          return (
                                            <BootstrapTooltip
                                              title="Video Call"
                                              arrow
                                              placement="bottom"
                                            >
                                              <button
                                                type="button"
                                                id="pills-videocall-chat"
                                                className="btn btn-outline-secondary border-0 btn-sm"
                                                data-tippy-content=""
                                                onClick={() => {
                                                  ActivateVideoCallChat();
                                                }}
                                              >
                                                <IoVideocamOutline
                                                  className="icon_top-btn_small"
                                                  size={23}
                                                />
                                              </button>
                                            </BootstrapTooltip>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }
                                    })}

                                    <BootstrapTooltip
                                      title="End Interaction"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="end-chatdialog"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        data-tippy-content=" Screen Share"
                                        onClick={() => {
                                          setendchatModalDialog(true);
                                        }}
                                      >
                                        <AiOutlineCloseCircle
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>

                                    <button
                                      type="button"
                                      id="pills-togglecard"
                                      className="btn btn-outline-secondary border-0 btn-sm"
                                      data-tippy-content=" Screen Share"
                                      onClick={() => {
                                        toggleCard();
                                      }}
                                    >
                                      <BsThreeDotsVertical
                                        className="icon_top-btn_small"
                                        size={20}
                                      />
                                    </button>

                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        id="more_details"
                                        onClick={() => showUserlist()}
                                      >
                                        {props.userlistshow ? (
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        ) : (
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        )}
                                      </button>
                                    </BootstrapTooltip>

                                    {showcobrowse && (
                                      <Cobrowser
                                        OpenDialog={showcobrowse}
                                        handleClose={handleCobrowseClose}
                                        CobrosweData={CobrosweData}
                                        cobrowswerinput={cobrowswerinput}
                                        setCobrowserInput={setCobrowserInput}
                                        setUrlValidationError={
                                          setUrlValidationError
                                        }
                                        UrlvalidationError={UrlvalidationError}
                                      />
                                    )}
                                    {showAddTopic && (
                                      <AddTopic
                                        OpenDialog={showAddTopic}
                                        handleClose={handleAddTopicClose}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <BootstrapTooltip
                                      title="End Interaction"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        id="end-chatdialog"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        data-tippy-content=" Screen Share"
                                        onClick={() => {
                                          setendchatModalDialog(true);
                                        }}
                                      >
                                        <AiOutlineCloseCircle
                                          className="icon_top-btn_small"
                                          size={20}
                                        />
                                      </button>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip
                                      title="More Details"
                                      arrow
                                      placement="bottom"
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary border-0 btn-sm"
                                        id="more_details"
                                        onClick={() => showUserlist()}
                                      >
                                        {props.userlistshow ? (
                                          <BsFillArrowRightCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        ) : (
                                          <BsFillArrowLeftCircleFill
                                            className="icon_top-btn_small"
                                            size={20}
                                          />
                                        )}
                                      </button>
                                    </BootstrapTooltip>
                                  </>
                                )}
                                {props.showCard && (
                                  <ChatMenu toggleCard={toggleCard} />
                                )}
                              </div>
                            </div>
                          </div>
                          {!isOnline && (
                            <div
                              style={{
                                backgroundColor: "#FF5733",
                                textAlign: "center",
                                fontSize: "20px",
                              }}
                            >
                              <p>
                                Please connect the internet while using chat
                                page!
                              </p>
                            </div>
                          )}
                          <Message
                            component={true}
                            videoCalldata={videoCalldata}
                            cobrowserdata={cobrowserdata}
                            setvideoCalldata={setvideoCalldata}
                            setCobrowserdata={setCobrowserdata}
                            value={value}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={props.userlistshow ? 5 : 0}
                          md={props.userlistshow ? 5 : 0}
                        >
                          {(() => {
                            switch (props.userlistshow) {
                              case true:
                                return <UserListComponent />;
                              default:
                                return <></>;
                            }
                          })()}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              default:
                return (
                  <Grid item xs={9} md={9}>
                    <div className="d-flex justify-content-between p-2">
                      <div
                        className="d-flex align-item-center mt-3"
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "150px",
                      }}
                    >
                      <MdChat style={{ fontSize: "4rem" }} color="gray" />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Select Interaction
                      </span>
                    </div>

                    <Message
                      component={false}
                      videoCalldata={videoCalldata}
                      cobrowserdata={cobrowserdata}
                      setvideoCalldata={setvideoCalldata}
                      setCobrowserdata={setCobrowserdata}
                      value={value}
                    />
                  </Grid>
                );
            }
          default:
            return (
              <>
                <div className={"col-md-9 px-0 border-end"}>
                  <div>
                    <div className="d-flex justify-content-between p-2">
                      <div
                        className="d-flex align-item-center mt-3"
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "150px",
                    }}
                  >
                    <MdChat style={{ fontSize: "4rem" }} color="gray" />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Select Interaction
                    </span>
                  </div>
                </div>
                <Message
                  component={false}
                  videoCalldata={videoCalldata}
                  cobrowserdata={cobrowserdata}
                  setvideoCalldata={setvideoCalldata}
                  setCobrowserdata={setCobrowserdata}
                  value={value}
                />
              </>
            );
        }
      })()}

      {endchatModalDialog && (
        <EndChatModel
          OpenDialog={endchatModalDialog}
          handleClose={EndChatDialogClose}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist,
  setselectedusername,
  setExternalChatData,
  openReplyEmail,
  setSelectedchanneldata,
  setUpdatechanneldata,
  setForwardmail,
  setShowCard,
  setMessage,
  setCustomerTyping,
  setloadmore,
  setloadmoreUser,
  setIswrite,
  setcouplemail,
  setChatRefresh,
  setshowExternalChat,
  setUserlistShow,
})(ChatComponent);
