import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { AiOutlineFileExcel } from "react-icons/ai";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core";
import AudioPlayer from "material-ui-audio-player";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { PlusCircle } from "@phosphor-icons/react";
import axios from "axios";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import { BaseUrl } from "../../../container/BaseUrl/BaseUrl";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    volumeSlider: {
      color: "black",
    },
    progressTime: {
      color: "rgba(0, 0, 0, 0.54)",
    },
    mainSlider: {
      color: "#3f51b5",
      "& .MuiSlider-rail": {
        color: "#7986cb",
      },
      "& .MuiSlider-track": {
        color: "#3f51b5",
      },
      "& .MuiSlider-thumb": {
        color: "#303f9f",
      },
    },
  };
});

const MessageComponent = (props) => {
  const { message } = props;
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [storeTagValue, setStoreTagValue] = useState('');
  const [validationError, setValidationError] = useState(false);
  const muiTheme = createTheme({});
  const inputValueRef = useRef(null);
  const logedinAgent = JSON.parse(localStorage.getItem("tokenAgent"));

  const handleAddTagOpen = () => setShowAddTag(true);
  const handleAddTagClose = () =>{
    setShowAddTag(false);
    setValidationError('');
  } 

  const openImagePreview = () => {
    setImagePreviewOpen(true);
  };

  const closeImagePreview = () => {
    setImagePreviewOpen(false);
  };

  const downloadImage = () => {
    <></>;
  };

  useEffect(() => {
    const savedTagValue = localStorage.getItem(`storeTagValue_${message.messages_id}`);
    if (savedTagValue) {
      setStoreTagValue(savedTagValue);
    }
  }, [message.messages_id]);

  const handleOnChange = (event) => {
    inputValueRef.current = event.target.value;
  };
  const handlePassAddValue = () => {
    if (inputValueRef.current) {
      const inputValue = inputValueRef.current.trim();
      if (inputValue) {
        setStoreTagValue(inputValue);
        localStorage.setItem(`storeTagValue_${message.messages_id}`, inputValue);
        handleAddTagClose();
        setValidationError('');
      } else {
        setValidationError("Input cannot be empty");
      }
    } else {
      setValidationError("Input cannot be empty");
    }
  };

  const getAddTagValue = (value) => {
    axios
      .post(
        BaseUrl + "/message/addTagTopic",
        {
          type: "tag",
          session_id: value.session_id,
          tag: storeTagValue,
          message_id: value.messages_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        console.log("addTagTopic", res);
      });
  };

  return (
    <>
      {message.msg_sent_type === "NOTIFICATIONS" ? (
        message.message === "Customer Ended the Chat" ? (
          <div className="times" style={{ background: "#d93d32" }}>
            {message.message}
          </div>
        ) : (
          <div className="times">{message.message}</div>
        )
      ) : message.senderName == logedinAgent.username ? (
        <div className="chat-r">
          <div className="user-chat-main d-flex justify-content-end flex-column align-items-end">
            {message.msg_sent_type === "TEXT" ||
            message.msg_sent_type == "text" ? (
              <div className="user-chats text-break">
                {message.message.includes("http://") ||
                message.message.includes("www.") ||
                message.message.includes("https://")
                  ? message.message.split(" ").map((word, index) => {
                      if (
                        word.includes("http://") ||
                        word.includes("https://") ||
                        word.includes("www.")
                      ) {
                        return (
                          <a
                            href={word}
                            key={index}
                            style={{
                              color: "white",
                              borderBottom: "1px solid grey",
                              textDecoration: "underline",
                            }}
                            target="_blank"
                          >
                            {word}
                          </a>
                        );
                      } else {
                        return word + " ";
                      }
                    })
                  : message.message}
              </div>
            ) : null}

            {message.msg_sent_type === "APPLICATION" ? (
              <div className="user-chats-file algin-text">
                <a
                  href={message.message}
                  target="_blank"
                  className="text-white"
                >
                  <AiOutlineFileExcel size={20} className="excel-icon" />{" "}
                  {message.file_name}
                </a>
              </div>
            ) : null}

            {message.msg_sent_type === "IMAGE" ? (
              <div className="card p-2" onClick={openImagePreview}>
                <a target="_blank">
                  <img
                    src={message.message}
                    height="130px"
                    width={150}
                    alt="message"
                  />
                </a>
              </div>
            ) : null}

            {message.msg_sent_type === "VIDEO" ? (
              <div
                className="card p-2"
                style={{ borderRadius: "10px 10px 0 10px" }}
              >
                <a href={message.message} target="_blank">
                  <video width="150" controls>
                    <source src={message.message} type="video/mp4" />
                  </video>
                </a>
              </div>
            ) : null}

            {message.msg_sent_type === "AUDIO" ? (
              <div className="user-chats-audio">
                <ThemeProvider theme={muiTheme}>
                  <AudioPlayer src={message.message} useStyles={useStyles} />
                </ThemeProvider>
              </div>
            ) : null}
          </div>
          <div className="chat-time-r">
            <span className="pluscircleCursor">
            {storeTagValue}
              <PlusCircle
                size={17}
                onClick={() => {
                  handleAddTagOpen();
                }}
              />
            </span>
            {moment(message.time).format("h:mm A")} -
            <div className="mx-1" style={{ fontSize: "10px" }}>
              {message.senderName}
            </div>
           
          </div>
        </div>
      ) : message.senderName !== logedinAgent.username ? (
        <div className="chat-l">
          <div className="bot-chat-main d-flex justify-content-start">
            <div className="d-flex justify-content-start flex-column align-items-start w-100">
              {message.msg_sent_type === "TEXT" ||
              message.msg_sent_type === "text" ? (
                <div className="bot-chats text-break"> {message.message} </div>
              ) : null}
              {message.msg_sent_type === "APPLICATION" ? (
                <div className="user-chats-file text-break">
                  <a
                    href={message.message}
                    target="_blank"
                    className="text-white"
                  >
                    <AiOutlineFileExcel size={20} className="excel-icon" />{" "}
                    {message.file_name}
                  </a>
                </div>
              ) : null}

              {message.msg_sent_type === "IMAGE" ? (
                <div
                  className="card p-2"
                  style={{ height: "150px" }}
                  onClick={openImagePreview}
                >
                  <a target="_blank">
                    <img
                      src={message.message}
                      height="130px"
                      width={150}
                      alt="message"
                    />
                  </a>
                </div>
              ) : null}

              {message.msg_sent_type === "VIDEO" ? (
                <div
                  className="card p-2"
                  style={{ borderRadius: "10px 10px 10px 0px" }}
                >
                  <a href={message.message} target="_blank">
                    <video width="150" controls>
                      <source src={message.message} type="video/mp4" />
                    </video>
                  </a>
                </div>
              ) : null}

              {message.msg_sent_type === "AUDIO" ? (
                <div className="user-chats-audio">
                  <ThemeProvider theme={muiTheme}>
                    <AudioPlayer src={message.message} useStyles={useStyles} />
                  </ThemeProvider>
                </div>
              ) : null}
            </div>
          </div>
          <div className="chat-time-l">
            {moment(message.time).format("h:mm A")} -
            <div className="mx-1" style={{ fontSize: "10px" }}>
              {message.senderName}
              <span className="pluscircleCursor">
                <PlusCircle
                  size={17}
                  onClick={() => {
                    handleAddTagOpen();
                  }}
                  
                />
              </span>
              {storeTagValue}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {imagePreviewOpen && (
        <Lightbox
          mainSrc={message.message}
          onCloseRequest={closeImagePreview}
          toolbarButtons={[
            <button className="btn-sm" key="download" onClick={downloadImage}>
              Image
              <span
                className="material-symbols-outlined"
                style={{
                  position: "relative",
                  top: "5px",
                  marginRight: "10px",
                }}
              >
                download
              </span>
            </button>,
          ]}
        />
      )}
      {showAddTag && (
        <Dialog
          open={showAddTag}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAddTagClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 5,
              padding: 1,
              top: -100,
            },
            backgroundColor: "rgb(0 0 0 / 65%)",
          }}
        >
          <DialogTitle
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: 600 }}
          >
            Add Tag
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Stack spacing={0.5}>
                <input
                  className="form-control"
                  onChange={handleOnChange}
                  placeholder="Add Tag"
                  style={{ height: "40px", width: "450px" }}
                />
                {validationError && (
              <Typography variant="body2" sx={{ color: "red" }}>
                {validationError}
              </Typography>
            )}
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handlePassAddValue(message.messages_id);
                getAddTagValue(message);
              }}
              variant="contained"
              size="small"
              id="paperplane"
            >
              Tag
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MessageComponent;
