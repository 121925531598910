import React from "react";
import "../../assets/assets/css/tablestyles/table.scss";
import "../../Page/SupervisorContact/supervisor.css";
const filterbutton = (props) => {
  return (
    <>
    
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="w-100">
            <div className="d-flex" style={{ float: "right" }}>
              <div className="d-flex flex-row gap-1">
                <button
                  type="button"
                  id="seesionreports_download_btn"
                  onClick={props.DownloadSessionReports}
                  className="btn btn-primary-1 me-2 d-flex-p btn-sm open-filter"
                  style={{
                    background: "#1473e6",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span className="material-symbols-outlined">download</span>
                  Download
                </button>
                <button
                  type="button"
                  id="seesionreports_filter_btn"
                  className="btn btn-primary-1 me-2 d-flex-p btn-sm open-filter"
                  style={{
                    background: "#1473e6",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    props.setshowfilter("sidebar_filter active-r");
                  }}
                >
                  <span className="material-symbols-outlined me-1">
                    {" "}
                    filter_list
                  </span>
                  Filters
                </button>
              </div>
            </div>
          </div>
        </nav>
     
    </>
  );
};

export default filterbutton;
