import React from "react";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";

const agenttable = (props) => {
  return (
    <>
      <div className="container-fluid  p-2">
        <div className="card fixed-table-card vh-79 agenttable">
          <div className="card-body">
            <div className="table-responsive vhm-68">
              <table className="table table-sm  table-hover align-middle table-fixeded">
                <thead>
                  <tr>
                    <th scope="col">Agent Name</th>
                    <th scope="col">Break Time</th>
                    <th scope="col">Active Time</th>
                    <th scope="col">Outbound</th>
                    <th scope="col">Inbound</th>
                    <th scope="col">Active Chat</th>
                    <th scope="col">Date</th>
                    <th scope="col">Transferred Out</th>
                    <th scope="col">Transferred In</th>
                  </tr>
                </thead>
                <tbody>
                  {props.loadingAgentreports ? (
                    [...Array(8)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {[...Array(10)].map((_, colIndex) => (
                          <td key={colIndex}>
                            <Skeleton variant="text" width={90} height={30} />
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : props.agentdata.length > 0 ? (
                    props.agentdata.map((rowData, rowIndex) => {
                      var seconds = rowData.total_break_time;
                      var duration = moment.duration(seconds, "seconds");
                      var total_break_time = moment
                        .utc(duration.asMilliseconds())
                        .format("HH:mm:ss");

                      var seconds_active = rowData.total_active_time;
                      var duration_active = moment.duration(
                        seconds_active,
                        "seconds"
                      );
                      var total_active_time = moment
                        .utc(duration_active.asMilliseconds())
                        .format("HH:mm:ss");

                      return (
                        <tr key={rowData.id}>
                          <td>
                            {rowData.agent_id == null ||
                            rowData.agent_id == undefined ||
                            rowData.agent_id == ""
                              ? "--"
                              : rowData.agent_id.username}
                          </td>
                          <td>{total_break_time}</td>
                          <td>{total_active_time}</td>
                          <td>{rowData.total_completed_outbound}</td>
                          <td>{rowData.total_completed_inbound}</td>

                          <td>
                            {rowData.total_active_chat_count == null ||
                            rowData.total_active_chat_count == undefined ||
                            rowData.total_active_chat_count == "" ||
                            rowData.total_active_chat_count == 0
                              ? 0
                              : rowData.total_active_chat_count}
                          </td>
                          <td>{moment(rowData.created_at).format("ll")}</td>
                          <td>
                            {rowData.total_transfered_out == null ||
                            rowData.total_transfered_out == undefined ||
                            rowData.total_transfered_out == "" ||
                            rowData.total_transfered_out == 0
                              ? 0
                              : rowData.total_transfered_out}
                          </td>
                          <td>
                            {rowData.total_transfered_in == null ||
                            rowData.total_transfered_in == undefined ||
                            rowData.total_transfered_in == "" ||
                            rowData.total_transfered_in == 0
                              ? 0
                              : rowData.total_transfered_in}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8">
                        <div
                          className="text-muted"
                          style={{
                            flexDirection: "column",
                            marginTop: "20%",
                            display: "flex",
                            position: "absolute",
                            left: "45%",
                            bottom: "40%",
                          }}
                        >
                          <span className="mx-auto">
                            <RiErrorWarningLine size={30} />
                          </span>
                          <span className="mx-auto">
                            <h5
                              className="text-muted mt-2"
                              style={{ fontFamily: "poppins" }}
                            >
                              No Data Found
                            </h5>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default agenttable;
