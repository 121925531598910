import React, { useState } from "react";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import {
  setExternalChatData,
} from "../../../../redux/actions/actions";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    externalChatData: state.data.externalChatData,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AddTopic = (props) => {
  const [validationError, setValidationError] = useState(false);
  const [topic, settopic] = useState("");
  const { OpenDialog, handleClose } = props;

  const getAddTagTopic = () => {
    try {
      axios
        .post(
          BaseUrl + "/message/addTagTopic",
          {
            type: "topic",
            session_id: props.externalChatData.chat_session_id,
            topic: topic,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            const updatedexternalChatData = props.externalChatData;
            updatedexternalChatData.topic = topic;
            props.setExternalChatData(updatedexternalChatData);
            handleClose();
          }
          if (res.data.message === "Topic Already added") {
            toast.warning(res.data.message, {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } catch (error) {
      console.log("addTagTopic", error);
    }
  };

  return (
    <Dialog
      open={OpenDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 5,
          padding: 1,
          top: -100,
        },
        backgroundColor: "rgb(0 0 0 / 65%)",
      }}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{ fontSize: "16px", fontWeight: 600 }}
      >
        Add Topic
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Stack spacing={0.5}>
            <input
              className="form-control"
              value={topic}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue) {
                  settopic(inputValue);
                } else {
                  setValidationError("Input cannot be empty");
                }
              }}
              placeholder="Add Topic"
              style={{ height: "40px", width: "450px" }}
            />
            {validationError && (
              <Typography variant="body2" sx={{ color: "red" }}>
                {validationError}
              </Typography>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            getAddTagTopic();
          }}
          variant="contained"
          size="small"
          id="paperplane"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, {
  setExternalChatData,
})(AddTopic);
