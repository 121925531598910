import React, { useEffect, useState } from "react";
import { Badge, Box, Grid, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Chart } from "react-google-charts";
import "./WebStatistic.style.css";
import axios from "axios";
import { BaseUrl, newBaseUrlLang } from "../../container/BaseUrl/BaseUrl";
import { toast } from "react-toastify";
import { RiErrorWarningLine } from "react-icons/ri";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100vh",
  width: "96vw",
  // marginLeft: "45px",
  background:'none'
}));
const Statistic = () => {
  const [skillName, setskillName] = useState([]);
  const [openChart, SetOpenChart] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isData2Available, setIsData2Available] = useState(false);

  const [data, setData] = useState([
    ["Language", "Speakers (in millions)"],
    ["Ready"],
    ["Not Ready"],
  ]);

  const [options, setOptions] = useState({
    legend: "none",
    pieSliceText: "label",
    // title: "Swiss Language Use (Straight Line)",
    pieStartAngle: 0,
    pieEndAngle: 0,
  });

  const [legendData, setLegendData] = useState([
    { color: "#4285F4", text: "Available", fontSize: "11px" },
    { color: "#dc3912", text: "Not Ready", fontSize: "11px" },
  ]);

  const [data2, setData2] = useState([
    ["Language", "Speakers (in millions)"],
    ["90%", 90],
    ["10%", 10],
  ]);
  const [sla,setSla]=useState(0);
  const [options2, setOptions2] = useState({
    legend: "none",
    pieSliceText: "label",
    // title: "Swiss Language Use (Straight Line)",
    pieStartAngle: 0,
    pieEndAngle: 0,
  });

  const [legendData2, setLegendData2] = useState([
    { color: "#4285F4", text: "Offered", fontSize: "10px" },
    { color: "#dc3912", text: "Waiting", fontSize: "10px" },
  ]);

  const webStatisticStatus = async (item) => {
    try {
      const response = await axios.post(
        `${BaseUrl}/dashboard/webStatistic`,
        {
          skillset: item.skillName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );

      if (response.data.status == true) {
         setSla(response.data.slacount?response.data.slacount:0)
        setData([
          ["Language", "Speakers (in millions)"],
          ["Ready", response.data.dataready],
          ["Not Ready", response.data.datanotready],
        ]);
        setData2([
          ["Language", "Speakers (in millions)"],
          ["offered", response.data.chatcompleted],
          ["waiting", response.data.chatincomplete],
        ]);
        setIsDataAvailable(
          response.data.dataready !== 0 || response.data.datanotready !== 0
        );
        setIsData2Available(
          response.data.chatcompleted !== 0 ||
            response.data.chatincomplete !== 0
        );
      } else {
        toast.warn(response.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (skillName.length > 0) {
      webStatisticStatus(skillName[0]);
    }
  }, [skillName]);

  const skillsetListDetails = async () => {
    let data = {
      enabled: true,
    };
    await axios
      .post(newBaseUrlLang + "usermodule/clientMaster/skills/list", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setskillName(res.data.dataList);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    skillsetListDetails();
  }, []);

  return (
    <>
      <StyledPaper elevation={0}>
        <Grid container direction="row">
          <Grid item xs={3} md={3} sm={3}>
            <Stack
              sx={{
                textAlign: "center",
                padding: "5px",
                backgroundColor: "white",
                border:"1px solid lightGrey",
              }}
            >
              <h4 style={{ fontSize: "15px", fontWeight: "500" }}>Skillset</h4>
            </Stack>

            <Stack
              className="statistic-scroll"
              sx={{
                height: "84vh",
                overflowY: "scroll",
                borderRight: "1px solid lightGrey",
              }}
            >
              <Stack
                spacing={0.3}
                sx={{
                  height: "100vh",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                {skillName.length > 0 &&
                  skillName.map((item) => (
                    <Stack
                      key={item.skillId}
                      direction="row"
                      className={`border-bottom ${
                        selectedItem === item.skillName ? "selected-item" : ""
                      }`}
                      onClick={() => {
                        webStatisticStatus(item);
                        SetOpenChart(true);
                        setSelectedItem(item.skillName);
                      }}
                      sx={{
                        height: "auto",
                        width: "100%",
                        backgroundColor: "white",
                        padding: "10px",
                        "&:hover": {
                          backgroundColor: "#D0D3D4",
                          transition: "background-color 0.5s ease-out",
                        },
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                    >
                      <Stack>
                        <Stack direction="row" spacing={1}>
                          <span class="material-symbols-outlined">
                            contrast
                          </span>
                          <Typography variant="subtitle2">
                            {item.skillName}
                          </Typography>
                        </Stack>
                      </Stack>
                      {/* future need */}
                      {/* <Stack
                      sx={{
                        marginLeft: "35px",
                        marginTop: "10px",
                        float: "right",
                      }}
                    >
                      <Badge badgeContent={agentTotalCount} color="primary" />
                    </Stack> */}
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={9} md={9} sm={9} >
            {openChart ? (
              <Box className="statistic-scroll"  sx={{ height: "65%", overflowY:'scroll' }}>
                <Stack
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Arial, sans-serif",
                    marginLeft: "10px",
                  }}
                >
                  <span>Service level: {sla}%</span>
                  <span>Max wait(sec): 0</span>
                </Stack>
           
                  <Stack
                    // className="border-bottom"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Stack>
                      <h5
                        style={{
                          fontSize: "20px",
                          fontFamily: "Arial, sans-serif",
                        }}
                      >
                        Agents
                      </h5>
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {legendData.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: "40px",
                              height: "15px",
                              backgroundColor: item.color,
                              marginRight: "5px",
                            }}
                          ></div>
                          <span>{item.text}</span>
                        </div>
                      ))}
                    </div>
                    <Stack sx={{ height: "400px", width: "100%" }}>
                      {isDataAvailable ? (
                        <Chart
                          chartType="PieChart"
                          data={data}
                          options={options}
                          width={"100%"}
                          height={"350px"}
                        />
                      ) : (
                        <Stack
                          style={{
                            textAlign: "center",
                            marginTop: "150px",
                            fontWeight: "700",
                            color: "grey",
                          }}
                        >
                          <Stack>No Data Found</Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "350px",
                      marginTop: "20px",
                    }}
                  >
                    <Stack>
                      <h5
                        style={{
                          fontSize: "20px",
                          fontFamily: "Arial, sans-serif",
                        }}
                      >
                        Contacts
                      </h5>
                    </Stack>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {legendData2.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            style={{
                              width: "40px",
                              height: "15px",
                              backgroundColor: item.color,
                              marginRight: "5px",
                            }}
                          ></div>
                          <span>{item.text}</span>
                        </div>
                      ))}
                    </div>
                    <Stack sx={{ height: "450px", width: "100%" }}>
                      {isData2Available ? (
                        <Chart
                          chartType="PieChart"
                          data={data2}
                          options={options2}
                          width={"100%"}
                          height={"350px"}
                        />
                      ) : (
                        <Stack
                          style={{
                            textAlign: "center",
                            marginTop: "100px",
                            fontWeight: "700",
                            color: "grey",
                          }}
                        >
                          <Stack>No Data Found</Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
        
              </Box>
            ) : (
              <>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "40%",
                    marginTop:"25%",
                    fontWeight: "700",
                    color: "grey",
                  }}
                >
                  <Stack sx={{ marginLeft: "43px" }}>
                    <RiErrorWarningLine size={30} />
                  </Stack>
                  <Stack>Select the skillset</Stack>
                </Stack>
              </>
            )}
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
};

export default Statistic;
