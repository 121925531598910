import React, { useState, useEffect } from "react";
import "../../assets/assets/assets_newchat/library/bootstrap-5.1.3/css/bootstrap.min.css";
import "../../assets/assets/assets_newchat/assets/fonts/fontawesome-free-6.1.1-web/css/all.css";
import "../../assets/assets/assets_newchat/library/animate/animate.min.css";
import "../../assets/assets/assets_newchat/library/slimselect/slimselect.min.css";
import { Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { logoutApi } from "../../ApiCall/LogoutApi";
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import axios from "axios";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { Pagination, Stack } from "@mui/material";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { Skeleton } from "@mui/material";

// component imported
import Agenttable from "../../components/agent-report-component/agent-table.component";
import Filterbutton from "../../components/agent-report-component/filter-button.component";
import Agentfilter from "../../components/agent-report-component/agent-filter.component";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
}));

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const AgentReportNew = () => {
  const [showfilter, setshowfilter] = useState("sidebar_filter");
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  const [agentdata, setAgentdata] = useState([]);
  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadingAgentreports, setLoadingAgentreports] = useState(false);

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
  useEffect(() => {
    agentReportData();
  }, [page]);

  const errorHandel = async (data) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      errorhandelApi();
      if (data.status) {
        //
      }
    } catch (error) {}
  };

  const logout = () => {
    const access_token = localStorage.getItem("access_token");
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    let userID = data.user_id;
    const id = localStorage.getItem("TenantId");

    if (data) {
      logoutApi().then((res) => {
        //
      });
    }
  };

  const agentReportData = async () => {
    setLoadingAgentreports(true);
    const Credentials = { fromdate, todate, page, defaultValue };
    await axios
      .post(
        BaseUrl + "/dashboard/tasklist",
        {
          from_date: fromdate ? fromdate : defaultValue,
          to_date: todate ? todate : defaultValue,
          offset: page == 1 ? 0 : (page - 1) * 10,
          limit: 10,
          agent_id: basedOnRole.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          setAgentdata(res.data.Data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingAgentreports(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setAgentdata([]);
          setTimeout(() => {
            setLoadingAgentreports(false);
          }, 1000);
        }
      })

      .catch((error) => {
        errorHandel(error, "/dashboard/tasklist/");
        setShow(true);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <StyledPaper elevation={0}>
      <Stack spacing={1}>
        <StyledPaper2 elevation={4}>
          <TableContainer sx={{ height: 400 }} className="CONTACT-LIST-AREA">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {[
                    { label: "Agent Name", minWidth: 40 },
                    { label: "Break Time", minWidth: 40 },
                    { label: "Active Time", minWidth: 40 },
                    { label: "Outbound", minWidth: 40 },
                    { label: "Inbound", minWidth: 40 },
                    { label: "Active Chat", minWidth: 40 },
                    { label: "Date", minWidth: 40 },
                    { label: "Transferred Out", minWidth: 40 },
                    { label: "Transferred In", minWidth: 40 },
                  ].map((item) => (
                    <StyledTableCell style={{ minWidth: item.minWidth }}>
                      {item.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingAgentreports
                  ? [...Array(10)].map((_, rowIndex) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        {[...Array(9)].map((_, colIndex) => (
                          <StyledTableCell2 key={colIndex}>
                            <Skeleton variant="text" width={80} height={30} />
                          </StyledTableCell2>
                        ))}
                      </TableRow>
                    ))
                  : agentdata.length > 0
                  ? agentdata.map((rowData) => {
                      var seconds = rowData.total_break_time;
                      var duration = moment.duration(seconds, "seconds");
                      var total_break_time = moment
                        .utc(duration.asMilliseconds())
                        .format("HH:mm:ss");

                      var seconds_active = rowData.total_active_time;
                      var duration_active = moment.duration(
                        seconds_active,
                        "seconds"
                      );
                      var total_active_time = moment
                        .utc(duration_active.asMilliseconds())
                        .format("HH:mm:ss");
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowData.id}
                        >
                          <StyledTableCell2>
                            {" "}
                            {rowData.agent_id == null ||
                            rowData.agent_id == undefined ||
                            rowData.agent_id == ""
                              ? "--"
                              : rowData.agent_id.username}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {total_break_time}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {total_active_time}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.total_completed_outbound}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.total_completed_inbound}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.total_active_chat_count == null ||
                            rowData.total_active_chat_count == undefined ||
                            rowData.total_active_chat_count == "" ||
                            rowData.total_active_chat_count == 0
                              ? 0
                              : rowData.total_active_chat_count}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {moment(rowData.created_at).format("ll")}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.total_transfered_out == null ||
                            rowData.total_transfered_out == undefined ||
                            rowData.total_transfered_out == "" ||
                            rowData.total_transfered_out == 0
                              ? 0
                              : rowData.total_transfered_out}
                          </StyledTableCell2>
                          <StyledTableCell2>
                            {rowData.total_transfered_in == null ||
                            rowData.total_transfered_in == undefined ||
                            rowData.total_transfered_in == "" ||
                            rowData.total_transfered_in == 0
                              ? 0
                              : rowData.total_transfered_in}
                          </StyledTableCell2>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper2>
        <Stack className="d-flex flex-row" justifyContent={"end"}>
          <Pagination
            variant="outlined"
            shape="rounded"
            color="primary"
            size="small"
            count={Math.ceil(pagination / 10)}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

export default AgentReportNew;
