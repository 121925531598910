import React, { useEffect, useRef, useState } from "react";
import "../../assets/library/bootstrap-5.1.3/css/bootstrap.min.css";
import "../../assets/assets/fonts/fonts.css";
import "./login.css";
import "../../assets/assets/fonts/fontawesome-free-6.1.1-web/css/all.css";
import "../../assets/assets/styles/style.css";
import "../../assets/library/animate/animate.min.css";
import logo from "../../assets/assets/images/Inaipi_Logo.png";
import sms from "../../assets/assets/images/sms.png";
import chat from "../../assets/assets/images/chat.png";
import bell from "../../assets/assets/images/bell.png";
import girl from "../../assets/assets/images/girl_on_smartphone.png";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { browserName } from "react-device-detect";
import { BaseUrl, errorApi } from "../../container/BaseUrl/BaseUrl";
import { logoutApi } from "../../ApiCall/LogoutApi";
import { connect } from "react-redux";
import spinnerimg from "../../assets/assets/images/Inaipi_Logo-1.2.png";

import {
  setinternalchatnotify,
  setchatdata,
  setchatid,
  setrefreshtogglechat,
  setselectedmobile,
  setselectedemail,
  setselectedusername,
  setchattype,
  setsidebarVal,
} from "../../redux/actions/actions";
import axios from "axios";

import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
} from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  const { data } = state;
  return {
    internalchatnotify: data.internalchatnotify,
    setchatid: data.setchatid,
    selectedmobile: data.selectedmobile,
    selectedemail: data.selectedemail,
    selectedusername: data.selectedusername,
    chatType: data.chatType,
  };
};

const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [emailval, setEmailval] = useState(false);
  const [passval, setPassval] = useState(false);
  const [isShown, setIsSHown] = useState(false);
  const [validate, setValidate] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [eye, setEye] = useState(false);
  const [userid, setuserid] = useState("");
  const ref = useRef(null);
  const cookies = new Cookies();
  const [logoutModalDialog, setlogoutModalDialog] = useState(false);

  const getFormattedName = (getDisplayName) => {
    const displayName = getDisplayName;
    const firstSpaceIndex = displayName.indexOf(" ");
    return firstSpaceIndex !== -1 ? displayName.substring(0, firstSpaceIndex) : displayName;
  };

  useEffect(() => {
    const tenantID = new URLSearchParams(window.location.search).get(
      "tenantID"
    );
    localStorage.setItem("TenantId", tenantID);
    localStorage.setItem(
      "notificationMessage",
      JSON.stringify([{ Message: "123" }])
    );

    const { username: userData, password: passwordData } = cookies.getAll();
    setEmail(userData || "");
    setPass(passwordData || "");

    const {
      email,
      password: pass,
      number,
    } = new URLSearchParams(window.location.search);

    if (email && pass) {
      const { data } = axios.post(BaseUrl + "/users/getInfoBasedOnNum", {
        number,
      });
      const user_info = data?.[0];
      if (user_info) {
        props.setinternalchatnotify(false);
        props.setchatdata(user_info);
        props.setchatid(user_info.chat_session_id);
        props.setrefreshtogglechat(true);
        props.setselectedmobile(user_info.unique_id.phonenumber);
        props.setselectedemail(user_info.unique_id.email);
        props.setselectedusername(user_info.unique_id.username);
        props.setchattype("WEBCHAT");
      }

      setSpinner(true);

      const { data: datas } = axios.post(BaseUrl + "/users/login", {
        email,
        pass,
      });

      if (datas.status) {
        const formattedName = getFormattedName(datas.user.username);
        localStorage.setItem("NameDisplay", formattedName)
        localStorage.setItem("emailDisplay", datas.user.email);
        localStorage.setItem("tokenAgent", JSON.stringify(datas.user));
        setSpinner(false);
        setValidate(false);
        navigate("/dashboard");
      } else if (datas.user_id) {
        setValidate(true);
        setSpinner(false);
        setlogoutModalDialog(true);
        setuserid(datas.user_id);
      } else {
        cookies.remove("username");
        cookies.remove("password");
        cookies.remove("boolean");
      }
    }
  }, []);

  const logout = async () => {
    logoutApi(userid)
      .then((res) => {
        if (res.data.success) {
          setlogoutModalDialog(false);
          localStorage.clear();
          window.location.reload();
        } else {
          setlogoutModalDialog(false);
          window.location.reload();
          localStorage.clear();
        }
      })
      .catch((err) => {});
  };

  const login = async () => {
    setSpinner(true);
    const credentials = { email, pass };
    try {
      await axios
        .post(BaseUrl + "/users/login", credentials, {
          headers: {
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        })
        .then((datas) => {
          if (datas.data.status === true) {
            props.setsidebarVal("Dashboard");
            localStorage.setItem("callpresent", "true");
            const formattedName = getFormattedName(datas.data.user.username);
            localStorage.setItem("NameDisplay",formattedName);
            localStorage.setItem("emailDisplay", datas.data.user.email);
            localStorage.setItem(
              "access_token",
              datas.data.access_token_cognicx
            );
            localStorage.setItem("AvcUrl", datas.data.user.voiceRecord.voiceyt);
            localStorage.setItem("tokenAgent", JSON.stringify(datas.data.user));
            localStorage.setItem(
              "AvayaUsername",
              datas.data.user.voiceRecord.avayaUserName
            );
            localStorage.setItem(
              "AvayaPassword",
              datas.data.user.voiceRecord.avayaPassword
            );
            localStorage.setItem(
              "AvayaDomain",
              datas.data.user.voiceRecord.avayaDomain
            );
            localStorage.setItem(
              "permission",
              JSON.stringify(datas.data.user.moduleScreen)
            );
            datas.data.user.moduleScreen.map(async (module) => {
              if (module.moduleName === "Crm") {
                const crmScreen = module.screen.find(
                  (item) =>
                    item.screenId === "CONTACT" && item.read && item.write
                );

                if (crmScreen) {
                  await axios
                    .post(
                      BaseUrl + "/ticket/ticketlogin",
                      { email: datas.data.user.email },
                      {
                        headers: {
                          Authorization:
                            "Bearer " + datas.data.access_token_cognicx,
                          "Content-Type": "application/json",
                          tenantId: localStorage.getItem("TenantId"),
                        },
                      }
                    )
                    .then((res) => {
                      if (res.data.status) {
                        localStorage.setItem(
                          "ticketcrm",
                          JSON.stringify(res.data)
                        );
                      }
                    });
                }
              }
            });
            setSpinner(false);
            setValidate(false);
            if (ref.current.checked) {
              cookies.set("username", email, { path: "/" });
              cookies.set("password", pass, { path: "/" });
              cookies.set("boolean", true, { path: "/" });
            }

            setTimeout(() => {
              const permissionStr = localStorage.getItem("permission");
              const permission = JSON.parse(permissionStr);

              if (
                permission.find((element) => element.moduleName === "Dashboard")
              ) {
                navigate("/main/Dashboard");
              } else if (
                permission.find((element) => element.moduleName === "Chat")
              ) {
                navigate("/main/Chat");
              } else if (
                permission.find((element) => element.moduleName === "Report")
              ) {
                navigate("/main/SessioReports");
              } else if (
                permission.find((element) => element.moduleName === "Calender")
              ) {
                navigate("/main/Calenders");
              } else {
                navigate("/main/Dashboard");
              }
            }, 1000);
          } else {
            if (datas.data.user_id != undefined) {
              setValidate(true);
              setSpinner(false);
              setlogoutModalDialog(true);
              setuserid(datas.data.user_id);
            } else {
              cookies.remove("username");
              cookies.remove("password");
              cookies.remove("boolean");
              setValidate(true);
              setSpinner(false);
              toast.error(datas.data.message, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
              });
            }
          }
        });
    } catch (error) {
      errorHandel(error, "/users/login");
      setSpinner(false);
      toast.warn("Server is down,please try after some time", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const errorHandel = async (error, endpoint) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      const payload = {
        servicename: "DCCCHAT",
        logs: error,
        description: endpoint,
      };

      const header = {
        headers: {
          // Authorization: "Bearer " + token,
          // "Content-Type": "application/json",
          tenantid: tenantID,
        },
      };
      const { data } = await axios.post(
        errorApi + "/UIlogs/createLogs",
        payload,
        header
      );
      if (data.status) {
      }
    } catch (error) {}
  };

  const handleChange = () => {
    if (ref.current.checked == false) {
      cookies.remove("username");
      cookies.remove("password");
      cookies.remove("boolean");
    }
  };

  const handleChangeWEBRTC = (event) => {
    localStorage.setItem("EnableWEBRTC", event.target.checked);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      login();
    }
  };

  const isLogin = true;
  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
    setEye(true);
  };
  const LogoutDialogClose = () => {
    setlogoutModalDialog(false);
  };
  return (
    <>
      <ToastContainer transition={Flip} limit={2} />
      <div className="login">
        <section className="banner set-bg">
          <div className="login-welcome">
            <div className="container-fluid">
              <div className="row p-0 m-0">
                <div className="col-6 p-0 m-0 no-gutters">
                  <div className="hero__caption inaipi-welcome animate__animated animate__fadeInUp">
                    <div>
                      <div className="logo">
                        <img src={logo} alt="" />
                      </div>
                      <div className="welcome">
                        <p style={{ fontSize: 17, fontWeight: 600 }}>
                          <span
                            style={{
                              fontSize: 17,
                              fontWeight: 600,
                              display: "block",
                            }}
                          >
                            {" "}
                            Login
                          </span>
                        </p>
                      </div>

                      <div className="form">
                        {/* <form> */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="username"
                            id="exampleFormControlInput1"
                            onChange={(e) => setEmail(e.target.value)}
                            // value={this.state.username}
                            placeholder="Enter your username"
                            value={email}
                            onKeyPress={handleKeyPress}
                          />

                          {emailval ? (
                            <span className="text-danger mx-3">
                              Username is Required *
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <div
                          className="form-group input-pass"
                          style={{ marginTop: "20px", marginBottom: "0px" }}
                        >
                          <input
                            type={isShown ? "text" : "password"}
                            className="form-control"
                            name="password"
                            id="exampleFormControlInput2"
                            onChange={(e) => setPass(e.target.value)}
                            placeholder="Enter your password"
                            value={pass}
                            onKeyPress={handleKeyPress}
                          />
                          {browserName == "Chrome" ? (
                            <span className="eye_icon">
                              {eye ? (
                                <i
                                  className="fa-solid fa-eye"
                                  id="solid-eye"
                                  onClick={() => {
                                    togglePassword();
                                    setEye(false);
                                  }}
                                ></i>
                              ) : (
                                <i
                                  className="fa-solid fa-eye-slash"
                                  id="solid-eye"
                                  onClick={() => togglePassword()}
                                ></i>
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                          {passval ? (
                            <span className="text-danger mx-3">
                              Password is Required *
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="mx-1">
                            <input
                              type="checkbox"
                              onChange={handleChange}
                              ref={ref}
                              defaultChecked={cookies.get("boolean")}
                              //  checked={cookies.get('boolean')}
                            />
                          </div>
                          <div className="mx-1">Remember Me</div>
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="mx-1">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={handleChangeWEBRTC}
                              />
                            </div>
                          </div>
                          <div>Soft Phone</div>
                        </div>
                        <div
                          className="form-group input-pass"
                          style={{ marginTop: "20px" }}
                        >
                          <button
                            variant="success"
                            id="connectSocket"
                            onClick={(e) => {
                              if (email == "") {
                                setEmailval(true);
                                setPassval(false);
                              } else if (pass == "") {
                                setEmailval(false);
                                setPassval(true);
                              } else {
                                login();
                              }
                            }}
                            className="btn form-control"
                            style={{
                              backgroundColor: "#00498f",
                              color: "white",
                            }}
                          >
                            {/* {spinner ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <div className="login-text">Login</div>
                            )} */}
                            <div className="login-text">Login</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="img-banner-main col-6 p-0 m-0 no-gutters ">
                  <div className="login_img position-relative">
                    <div className="round ">
                      <div className="main_img">
                        <img
                          className="image-1 appear-animate "
                          src={girl}
                          alt=""
                        />
                      </div>
                      <img
                        className="image-2 fly-sm animate__animated animate__pulse"
                        src={bell}
                        alt=""
                      />
                      <img
                        className="image-3 fly-sm animate__animated animate__pulse"
                        src={chat}
                        alt=""
                      />
                      <img
                        className="image-4 fly-sm animate__animated animate__pulse"
                        src={sms}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {spinner ? (
          <>
            <div className="spinnerbody2">
              <div className="spinnerloader">
                <img src={spinnerimg} className="spinnerlogo2" />
                <span className="spinnerspanlogin"></span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        open={logoutModalDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={LogoutDialogClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          CONFIRMATION
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>User Already loggedIn Please SignOut</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="exit-left"
            onClick={() => {
              logout();
            }}
            variant="contained"
            size="small"
          >
            LOGOUT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setinternalchatnotify,
  setchatdata,
  setchatid,
  setrefreshtogglechat,
  setselectedmobile,
  setselectedemail,
  setselectedusername,
  setchattype,
  setsidebarVal,
})(Login);
