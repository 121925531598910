import React, { useEffect, useState } from "react";
import {
  setchatid,
  setchatdata,
  setshowExternalChat,
  endInteraction,
  setVoiceRefresh,
  setcontactlist,
} from "../../../../redux/actions/actions";
import { connect } from "react-redux";
import axios from "axios";
import { BaseUrl, emailservice } from "../../../../container/BaseUrl/BaseUrl";
import { toast } from "react-toastify";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTimer } from "react-timer-hook";

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
    externalChatData: state.data.externalChatData,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EndchatModelForPopCall = (props) => {
  const { chat, OpenDialog, handleClose, value } = props;
  const [reasonerror, setreasonerror] = useState(false);
  const [commentserror, setcommentserror] = useState(false);
  const [comments, setComments] = useState("");
  const [reason, setReason] = useState("");
  const [checkvalue, setCheckvalue] = useState(false);
  const [dispositionlist, setdispositionlist] = useState([]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 15);
  const { seconds, minutes, start, restart, pause } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      let userId = JSON.parse(localStorage.getItem("tokenAgent"));
      let agentId = userId.id;
      let data = {
        chat_session_id: chat.chat_session_id,
        chatendby: "Agent",
        reason: "NoDisposition",
        comment: "Completed",
        agent_id: agentId,
        ticket: checkvalue ? checkvalue : false,
      };
      if (props.externalChatData.channel === "voice") {
        localStorage.setItem("statusValue", "Ready");
        localStorage.setItem("VoiceActivate", true);
        if (
          localStorage.getItem("type") === "sales" ||
          localStorage.getItem("type") === "service"
        ) {
          data = {
            chat_session_id: chat.chat_session_id,
            chatendby: "Agent",
            reason: reason,
            comment: comments,
            agent_id: agentId,
            contact_id: localStorage.getItem("ContactVoiceId"),
            voice_type: "contact_voice",
            contact_type: localStorage.getItem("type"),
          };
        }
      }
      axios
        .post(BaseUrl + "/message/endchat", data, {
          headers: {
            tenantId: localStorage.getItem("TenantId"),
          },
        })
        .then(async (res) => {
          if (res.data.success == true) {
            const datas = JSON.parse(localStorage.getItem("tokenAgent"));
            try {
              await axios
                .post(
                  `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
                  {},
                  {
                    headers: {
                      Authorization:
                        "Bearer " + localStorage.getItem("access_token"),
                      "Content-Type": "application/json",
                      tenantId: localStorage.getItem("TenantId"),
                    },
                  }
                )
                .then((data) => {
                  props.setcontactlist(data.data);
                });
            } catch (error) {}
            localStorage.removeItem("client");
            props.setshowExternalChat(false);
            props.endInteraction();
            toast.success("Interaction Ended Successfully", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            localStorage.removeItem("callActivate");
            localStorage.removeItem("type");
            localStorage.removeItem("ContactVoiceId");
            localStorage.removeItem("Contactreg_no");
            EndChatClearCache();
          }
        })
        .catch((error) => {});
      handleClose();
    },
  });

  const endChat = () => {
    console.log("reason", reason);
    if (reason === "") {
      setreasonerror(true);
      setcommentserror(false);
    } else if (comments === "") {
      setreasonerror(false);
      setcommentserror(true);
    } else {
      let userId = JSON.parse(localStorage.getItem("tokenAgent"));
      let agentId = userId.id;
      let data = {
        chat_session_id: chat.chat_session_id,
        chatendby: "Agent",
        reason: reason,
        comment: comments,
        agent_id: agentId,
        ticket: checkvalue ? checkvalue : false,
      };
      if (props.externalChatData.channel === "voice") {
        localStorage.setItem("statusValue", "Ready");
        localStorage.setItem("VoiceActivate", true);
        if (
          localStorage.getItem("type") === "sales" ||
          localStorage.getItem("type") === "service"
        ) {
          data = {
            chat_session_id: chat.chat_session_id,
            chatendby: "Agent",
            reason: reason,
            comment: comments,
            agent_id: agentId,
            contact_id: localStorage.getItem("ContactVoiceId"),
            voice_type: "contact_voice",
            contact_type: localStorage.getItem("type"),
          };
        }
      }
      axios
        .post(BaseUrl + "/message/endchat", data, {
          headers: {
            tenantId: localStorage.getItem("TenantId"),
          },
        })
        .then(async (res) => {
          if (res.data.success == true) {
            const datas = JSON.parse(localStorage.getItem("tokenAgent"));
            try {
              await axios
                .post(
                  `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
                  {},
                  {
                    headers: {
                      Authorization:
                        "Bearer " + localStorage.getItem("access_token"),
                      "Content-Type": "application/json",
                      tenantId: localStorage.getItem("TenantId"),
                    },
                  }
                )
                .then((data) => {
                  props.setcontactlist(data.data);
                });
            } catch (error) {}
            localStorage.removeItem("client");
            props.setshowExternalChat(false);
            props.endInteraction();
            toast.success("Interaction Ended Successfully", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
            localStorage.removeItem("callActivate");
            localStorage.removeItem("type");
            localStorage.removeItem("ContactVoiceId");
            localStorage.removeItem("Contactreg_no");
            EndChatClearCache();
          }
        })
        .catch((error) => {});
    }
  };

  const closeTicketApi = async (value) => {
    let sessionId = JSON.parse(localStorage.getItem("client"));

    let dataToPass = {
      ticketID: sessionId.ticketID,
      ticketStatus: checkvalue,
    };

    axios
      .post(emailservice + "email/endMailStatusUpdate", dataToPass, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((response) => {})
      .catch((err) => {});
  };

  const Dispositionlist = async () => {
    axios
      .post(
        BaseUrl + "/userstatus/dispositionlist",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setdispositionlist(res.data.data);
        } else {
          setdispositionlist([]);
        }
      })
      .catch((err) => {});
  };

  const EndChatClearCache = () => {
    setComments("");
    setCheckvalue("");
  };

  useEffect(() => {
    Dispositionlist();
    start();
  }, []);

  return (
    <>
      <Dialog
        open={OpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleClose();
          setComments("");
          setCheckvalue("");
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={21}>
            <Typography sx={{ fontWeight: "600" }}>End Interaction</Typography>
            <Typography sx={{ fontWeight: "600" }}>
              {minutes >= 10 ? minutes : "0" + minutes}:
              {seconds >= 10 ? seconds : "0" + seconds}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={1}>
              <Stack direction={"row"}>
                <select
                  className={`form-select ${reasonerror ? "is-invalid" : ""}`}
                  aria-label="Default select example"
                  onChange={(e) => setReason(e.target.value)}
                >
                  {dispositionlist.map((item) => (
                    <option value={item.dispositionName}>
                      {item.dispositionName}
                    </option>
                  ))}
                </select>
              </Stack>
              {reasonerror ? (
                <span className="text-danger">Choose a reason*</span>
              ) : (
                ""
              )}
              <input
                className={`form-control ${commentserror ? "is-invalid" : ""}`}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Enter Comments..."
                id="floatingTextarea2"
                style={{ height: "100px", width: "350px" }}
              ></input>
              {commentserror ? (
                <span className="text-danger">Fill the required field *</span>
              ) : (
                ""
              )}

              {props.externalChatData.channel === "email" ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkvalue}
                      onChange={(e) => setCheckvalue(e.target.checked)}
                    />
                  }
                  label="Close Ticket"
                />
              ) : (
                ""
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (reason == "") {
                setreasonerror(true);
                setcommentserror(false);
              } else if (comments == "") {
                setreasonerror(false);
                setcommentserror(true);
              } else if (props.externalChatData.channel == "email") {
                EndChatClearCache();
                closeTicketApi();
                endChat();
                handleClose();
              } else {
                EndChatClearCache();
                endChat();
                handleClose();
              }
              pause();
              restart();
            }}
            variant="contained"
            size="small"
            id="submit-btn"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setshowExternalChat,
  endInteraction,
  setVoiceRefresh,
  setcontactlist,
})(EndchatModelForPopCall);
