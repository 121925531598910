import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { randomColors } from "../colorpallet";

import { SiMicrosoftteams } from "react-icons/si";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  XCircle,
  MagnifyingGlass,
  IdentificationCard,
  Pencil,
  GearSix,
  UserCircle,
  ChatCentered,
  DeviceMobile,
} from "@phosphor-icons/react";
import { toast } from "react-toastify";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from "@mui/icons-material/Email";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "axios";
import { BaseUrl } from "../../../container/BaseUrl/BaseUrl";
import { errorhandelApi } from "../../../ApiCall/ErrorHandelApi";
import { connect } from "react-redux";
import "./userdetails.css";
import {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setChatRefresh,
  setCustomerDetailsShow,
  setAcceptChatActive,
  setSelectedvip,
  setcontactlist,
  setSelectedColor,
} from "../../../redux/actions/actions";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Badge } from "react-bootstrap";
import { Box, Stack } from "@mui/material";

const mapStateToProps = (state) => {
  const { data, contactListData } = state;
  return {
    selectedusername: data.selectedusername,
    selectedemail: data.selectedemail,
    selectedmobile: data.selectedmobile,
    selectedwhatsapp: data.selectedwhatsapp,
    selectedfacebook: data.selectedfacebook,
    selectedtwitter: data.selectedtwitter,
    selectedteams: data.selectedteams,
    selectedcompany: data.selectedcompany,
    selectedaddress: data.selectedaddress,
    selectedid: data.selectedid,
    externalChatData: data.externalChatData,
    chat: state.data.chat,
    selectedisvip: data.selectedisvip,
    contactList: data.contactList,
    usercolor: data.usercolor,
    index: contactListData.index,
  };
};

const UserDetails = (props) => {
  const [editUserModal, setEditUserModal] = useState(false);
  const [editemail, seteditemail] = useState(props.selectedemail);
  const [editusername, seteditusername] = useState(props.selectedusername);
  const [editmobile, seteditmobile] = useState(props.selectedmobile);
  const [editwhatsapp, seteditwhatsapp] = useState(props.selectedwhatsapp);
  const [editfacebook, seteditfacebook] = useState(props.selectedfacebook);
  const [edittwitter, setedittwitter] = useState(props.selectedtwitter);
  const [editteams, seteditteams] = useState(props.selectedteams);
  const [editcompany, seteditcompany] = useState(props.selectedcompany);
  const [editaddress, seteditaddress] = useState(props.selectedaddress);
  const [editisvip, setEditisvip] = useState(props.selectedisvip);

  const [validateemail, setValidateemail] = useState(false);
  const [mailvalidity, setMailvalidity] = useState(false);

  const [Numbercopyed, setNumbercopyed] = useState("Copy");
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const updateEditvalueModel = () => {
    setEditUserModal(!editUserModal);
  };

  const getDataOnload = async () => {
    try {
      const datas = JSON.parse(localStorage.getItem("tokenAgent"));
      await axios
        .post(
          `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            props.setcontactlist(res.data);
            props.setExternalChatData(res.data);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const updateEditvalue = async () => {
    const tokenAgent = JSON.parse(localStorage.getItem("tokenAgent"));
    const agent_ID = tokenAgent.id;
    const access_token = localStorage.getItem("access_token");
    let body = {
      username: editusername,
      email: editemail == "Empty" ? "" : editemail,
      phonenumber: editmobile == "Empty" ? "" : editmobile,
      whatsappnumber: editwhatsapp == "Empty" ? "" : editwhatsapp,
      facebookId: editfacebook == "Empty" ? "" : editfacebook,
      twitterId: edittwitter == "Empty" ? "" : edittwitter,
      teamsId: editteams == "Empty" ? "" : editteams,
      address: editaddress == "Empty" ? "" : editaddress,
      company: editcompany == "Empty" ? "" : editcompany,
      id: props.selectedid,
      isvip: editisvip,
      agent_id: agent_ID,
      chat_session_id: props.externalChatData.chat_session_id
        ? props.externalChatData.chat_session_id
        : "",
    };

    axios
      .post(BaseUrl + "/users/updateClient", body, {
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        updateEditvalueModel();
        // props.setChatRefresh(true);
        getDataOnload();
        // props.setAcceptChatActive(true);
        props.setselectedemail(editemail);
        props.setselectedusername(editusername);
        props.setselectedmobile(editmobile);
        props.setselectedwhatsapp(editwhatsapp);
        props.setselectedfacebook(editfacebook);
        props.setselectedtwitter(edittwitter);
        props.setselectedteams(editteams);
        props.setselectedcompany(editcompany);
        props.setselectedadress(editaddress);
        props.setSelectedvip(editisvip);
        // getDataOnload()
        // props.setChatRefresh(true);
        toast.success("Client details Updated Successfully", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })

      .catch((error) => {
        errorhandelApi(error, "/users/updateClient");
      });
  };

  useEffect(() => {
    seteditemail(props.selectedemail);
    seteditusername(props.selectedusername);
    seteditmobile(props.selectedmobile);
    seteditwhatsapp(props.selectedwhatsapp);
    seteditfacebook(props.selectedfacebook);
    setedittwitter(props.selectedtwitter);
    seteditteams(props.selectedteams);
    seteditcompany(props.selectedcompany);
    seteditaddress(props.selectedaddress);
    setEditisvip(props.selectedisvip);
  }, [props.externalChatData.id]);

  const CrmScreenpopupApi = async () => {
    let body;
    if (
      props.chat.channel == "voice" &&
      (localStorage.getItem("type") === "sales" ||
        localStorage.getItem("type") === "service")
    ) {
      body = {
        phonenumber: props.chat.phonenumber,
        skillset: props.chat.skillset,
        channel: props.chat.channel,
        type: localStorage.getItem("type"),
        reg_no: localStorage.getItem("Contactreg_no"),
        contact_id: localStorage.getItem("ContactVoiceId"),
      };
    } else {
      body = {
        phonenumber: props.chat.phonenumber,
        skillset: props.chat.skillset,
        channel: props.chat.channel,
      };
    }
    await axios
      .post(BaseUrl + "/ticket/crmscreenpop", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        window.open(res.data.data);
        toast.success("screen pop url generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("screen pop url not generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleOpenCrmUrl = () => {
    const randomNumber =
      Math.floor(Math.random() * (10000000 - 9999999 + 1)) + 9999999;
    // const url = `https://cms.inaipiapp.com/helpdesk/screenpopup?number=${props.chat.phonenumber}&type=${props.chat.channel}&direction=incoming&callid=${randomNumber}`;
    const url = `https://inaipi.ae/v2/screenpopup?number=${props.chat.phonenumber}&skillset=${props.chat.skillset}&callid=${randomNumber}&survey_opt=1&type=${props.chat.channel}&direction=INBOUND`;
    window.open(url, "_blank");
  };

  const smallButtonEditStyle = {
    fontSize: "10px",
  };
  const smallButtonEditIconStyle = {
    fontSize: "15px",
    cursor: "pointer",
  };

  const smallButtonEditStyleEmail = {
    fontSize: "13px",
  };

  const smallButtonEditStyleEmailuser = {
    fontSize: "15px",
  };

  return (
    <>
      <Box
        sx={{
          height: "130px",
          width: "100%",
          background: "#F6F7F9",
          borderRadius: "12px",
          boxShadow: "inset 3px 4px 5px #949495, inset -5px -5px 5px #ffffff",
        }}
        p={1}
      >
        <Stack
          sx={{ height: "100%", width: "100%" }}
          direction={"row"}
          spacing={1}
        >
          <Stack spacing={0.1} alignItems={"center"} justifyContent={"center"}>
            <div
              style={{
                width: "80px",
                height: "80px",
                background: "#0B3363",
                display: "block",
                borderRadius: "50%",
                border: "2px solid white",
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 8px 6px -3px inset",
              }}
            >
              <p
                className="chat_name my-auto h-100"
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <b
                  className="h-100 my-auto"
                  style={{
                    color: "#ffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "26px",
                  }}
                >
                  {props.selectedusername
                    ? props.selectedusername.slice(0, 2).toUpperCase()
                    : ""}
                </b>
              </p>
            </div>

            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <BootstrapTooltip title={"Edit"} arrow placement="top">
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    cursor: "pointer",
                    transition: "background-color 0.5s, color 0.5s, fill 0.5s",
                    "&:hover": {
                      background: "#595858e8",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    updateEditvalueModel();
                  }}
                >
                  <Pencil size={14} cursor={"pointer"} weight="bold" />
                </Box>
              </BootstrapTooltip>

              <BootstrapTooltip title={"Customer Search"} arrow placement="top">
                <Box
                  sx={{
                    width: "25px",
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    cursor: "pointer",
                    transition: "background-color 0.5s, color 0.5s, fill 0.5s",
                    "&:hover": {
                      background: "#595858e8",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    props.setCustomerDetailsShow(true);
                  }}
                >
                  <MagnifyingGlass size={14} cursor={"pointer"} weight="bold" />
                </Box>
              </BootstrapTooltip>

              {JSON.parse(localStorage.getItem("permission")).map((module) => {
                if (module.moduleName === "Crm") {
                  const crmScreen = module.screen.find(
                    (item) => item.screenId === "CRM" && item.read && item.write
                  );

                  if (crmScreen) {
                    return (
                      <BootstrapTooltip title={"CRM"} arrow placement="top">
                        <Box
                          sx={{
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition:
                              "background-color 0.5s, color 0.5s, fill 0.5s",
                            "&:hover": {
                              background: "#595858e8",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            CrmScreenpopupApi();
                          }}
                        >
                          <GearSix size={14} cursor={"pointer"} weight="bold" />
                        </Box>
                      </BootstrapTooltip>
                    );
                  } else {
                    return null;
                  }
                }
                return null;
              })}
            </Stack>
          </Stack>

          <Stack spacing={0.5} sx={{ width: "100%" }}>
            <Stack direction={"row"} justifyContent={"end"}>
              {props.selectedisvip ? (
                <span
                  className="badge badge-pill bg-primary"
                  style={{ width: "30px" }}
                >
                  VIP
                </span>
              ) : (
                <span
                  className="badge badge-pill bg-primary"
                  style={{ width: "70px" }}
                >
                  Normal
                </span>
              )}
            </Stack>
            <div
              className="CONTACT-LIST-AREA"
              style={{ width: "100%", overflowY: "scroll", cursor: "pointer" }}
            >
              <div className="d-flex">
                <div>
                  <UserCircle size={18} cursor={"pointer"} weight="bold" />
                </div>
                <div>
                  <b className="mt-0 m-b-0 mx-1">
                    {props.selectedusername === "" ||
                    props.selectedusername === "Empty"
                      ? ""
                      : props.selectedusername}
                  </b>
                </div>
              </div>
              <div className="w-100 d-flex">
                <span className="">
                  <ChatCentered size={18} cursor={"pointer"} weight="bold" />
                </span>
                <span
                  className="mx-1 text-truncate w-100"
                  style={{ fontSize: "12px" }}
                >
                  {props.selectedemail === "" || props.selectedemail === "Empty"
                    ? ""
                    : props.selectedemail}
                </span>
              </div>
              {props.selectedmobile ? (
                <div className="w-100 d-flex">
                  <span className="">
                    <DeviceMobile size={18} cursor={"pointer"} weight="bold" />
                  </span>
                  <span className="mx-1 text-truncate">
                    <BootstrapTooltip
                      title={Numbercopyed}
                      arrow
                      placement="right"
                    >
                      <p
                        className="w-100 text-break"
                        onClick={() => {
                          navigator.clipboard.writeText(props.selectedmobile);
                          setNumbercopyed("Copied");
                          setTimeout(() => {
                            setNumbercopyed("Copy");
                          }, 3000);
                        }}
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        {" "}
                        {props.selectedmobile === "" ||
                        props.selectedmobile === "Empty"
                          ? " "
                          : props.selectedmobile}
                      </p>
                    </BootstrapTooltip>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </Stack>
        </Stack>
      </Box>
      {editUserModal && (
        <Draggable handle=".handle">
          <div
            className="card"
            style={{
              position: "absolute",
              width: "47%",
              zIndex: "999",
              backgroundColor: "white",
              borderRadius: "5px",
              boxShadow: "2px 2px 20px grey",
              top: "50px",
              right: "300px",
            }}
          >
            <div
              className="card-header handle"
              style={{ backgroundColor: "#0b3363" }}
            >
              <h4
                style={{
                  fontSize: "16px",
                  color: "white",
                  fontFamily: "monospace",
                }}
              >
                <strong>EDIT USER</strong>
              </h4>
              <AiOutlineCloseCircle
                id="close_edituser"
                onClick={() => {
                  setEditUserModal(false);
                }}
                size="20px"
                style={{
                  float: "right",
                  marginTop: "-25px",
                  color: "white",
                  cursor: "pointer",
                }}
              />
            </div>

            <div className="card-body">
              <div className="row g-2">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editusername}
                      onChange={(e) => seteditusername(e.target.value)}
                    />
                    <label for="floatingInputGrid">Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editemail === "Empty" ? "" : editemail}
                      onChange={(e) => seteditemail(e.target.value)}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    />
                    <label for="floatingInputGrid">Email address</label>
                  </div>
                  {validateemail ? (
                    <span
                      className="text-danger fw-bold "
                      style={{ fontSize: "10px" }}
                    >
                      Fill The Email Field*
                    </span>
                  ) : (
                    ""
                  )}
                  {mailvalidity ? (
                    <span
                      className="text-danger fw-bold "
                      style={{ fontSize: "10px" }}
                    >
                      Provide a Valid Email*
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editmobile === "Empty" ? "" : editmobile}
                      onChange={(e) =>
                        seteditmobile(e.target.value.replace(/[^\d]/, ""))
                      }
                    />
                    <label for="floatingInputGrid">Phone</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editwhatsapp === "Empty" ? "" : editwhatsapp}
                      onChange={(e) =>
                        seteditwhatsapp(e.target.value.replace(/[^\d]/, ""))
                      }
                    />
                    <label for="floatingInputGrid">Whatsapp</label>
                  </div>
                </div>

                {/* Comment For Requirment For Du_Server */}

                {/* <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editfacebook === "Empty" ? "" : editfacebook}
                      onChange={(e) => seteditfacebook(e.target.value)}
                    />
                    <label for="floatingInputGrid">facebook</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInputGrid"
                      value={edittwitter === "Empty" ? "" : edittwitter}
                      onChange={(e) => setedittwitter(e.target.value)}
                    />
                    <label for="floatingInputGrid">Twitter</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editteams === "Empty" ? "" : editteams}
                      onChange={(e) => seteditteams(e.target.value)}
                    />
                    <label for="floatingInputGrid">Teams</label>
                  </div>
                </div> */}

                {/* Comment For Requirment For Du_Server */}

                {/* <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="checkbox"
                      
                      id="floatingInputGrid"
                      value={editteams === "Empty" ? "" : editteams}
                      onChange={(e) => seteditteams(e.target.value)}
                    />
                    <label for="floatingInputGrid">Is VIP</label>
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editcompany === "Empty" ? "" : editcompany}
                      onChange={(e) => seteditcompany(e.target.value)}
                    />
                    <label for="floatingInputGrid">Company</label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGrid"
                      value={editaddress === "Empty" ? "" : editaddress}
                      onChange={(e) => seteditaddress(e.target.value)}
                    />
                    <label for="floatingInputGrid">Address</label>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="custom-control custom-checkbox my-1">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={editisvip}
                      id="customCheck1"
                      onChange={(e) => setEditisvip(e.target.checked)}
                    />
                    <label
                      className="custom-control-label mx-2"
                      for="customCheck1"
                    >
                      Is VIP
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <button
                  style={{ fontFamily: "monospace" }}
                  id="update-btn"
                  className="btn btn-primary btn-sm float-end mt-3"
                  onClick={() => {
                    if (editemail.length <= 0) {
                      setValidateemail(true);
                      setMailvalidity(false);
                    } else if (!editemail.includes("@")) {
                      setMailvalidity(true);
                      setValidateemail(false);
                    } else {
                      updateEditvalue();
                    }
                  }}
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
  setselectedemail,
  setselectedmobile,
  setselectedwhatsapp,
  setselectedfacebook,
  setselectedtwitter,
  setselectedteams,
  setselectedcompany,
  setselectedadress,
  setselectedid,
  setChatRefresh,
  setCustomerDetailsShow,
  setAcceptChatActive,
  setSelectedvip,
  setcontactlist,
  setSelectedColor,
})(UserDetails);
