import React, { useState } from "react";
import { connect } from "react-redux";
import { setselectedmobile } from "../../../../redux/actions/actions";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
} from "@mui/material";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  const { data } = state;

  return {
    selectedmobile: data.selectedmobile,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Notification = (props) => {
  const [notificationphoneNum, setNotificationPhoneNum] = useState(
    props.selectedmobile
  );
  const { show, close, chatid, chat, OpenDialog, handleClose } = props;
  const [channelvalid, setChannelvalid] = useState(false);
  const [phonenumvalid, setPhonenumvalid] = useState(false);
  const [commentsvalid, setcommentsvalid] = useState(false);

  const [sendmessage, setSendmessage] = useState("");
  const [selectedchanneldata, setSelectedchanneldata] = useState("");

  const sendnotificationApi = () => {
    let data = {
      phoneNumber: notificationphoneNum ? notificationphoneNum : "",
      message: sendmessage ? sendmessage : "",
      channel: selectedchanneldata ? selectedchanneldata : "",
    };

    axios
      .post(BaseUrl + "/users/sendnotification", data, {
        headers: {
          tenantid: localStorage.getItem("TenantId"),
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(res.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Dialog
        open={OpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          Notification
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={2}>
              <Stack direction={"row"}>
                <select
                  aria-label="Default select example"
                  className={`form-select form-select-sm ${
                    channelvalid ? "is-invalid" : ""
                  }`}
                  onChange={(e) => {
                    setSelectedchanneldata(e.target.value);
                    setChannelvalid(false);
                  }}
                >
                  <option selected>Select channel</option>

                  <option value="whatsapp">Whatsapp</option>
                  <option value="sms">Sms</option>
                </select>
                <input
                  className="form-control mx-2"
                  type="number"
                  placeholder="Enter Phone Number"
                  value={notificationphoneNum}
                  onChange={(e) => {
                    setNotificationPhoneNum(e.target.value);
                  }}
                />
              </Stack>
              <input
                className="form-control"
                placeholder="Enter Message..."
                id="floatingTextarea2"
                style={{ height: "100px" }}
                onChange={(e) => setSendmessage(e.target.value)}
              ></input>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              sendnotificationApi();
              handleClose();
            }}
            variant="contained"
            size="small"
            id="submit-btn"
          >
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedmobile,
})(Notification);
