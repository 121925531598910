import io from "socket.io-client";
import { SocketUrl } from "./container/BaseUrl/BaseUrl";

let socket;

const connectSocket = () => {
  socket = io(SocketUrl + `/${localStorage.getItem("TenantId")}`);
};

export { socket, connectSocket };
