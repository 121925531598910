import {
  SEND_MESSAGE,
  TOGGLE_RECORDING,
  SET_UPLOADED_FILE,
  SET_SELECTED_EMAIL,
  SET_SELECTED_MOBILE,
  SET_CHAT_ID,
  SET_CHAT_DURATION,
  SET_SELECTED_USERIMAGE,
  TOOGLE_CHAT,
  REFRESH_TOOGLE_CHAT,
  CONF_CHAT_REFRESH,
  INTERNAL_CHAT_REFRESH,
  CHAT_DATA,
  CHAT_DATA_FOR_INTERNAL,
  INTERNAL_CHAT_NOTIFY,
  TYPE_CHAT,
  CHAT_MESSAGE,
  SET_CONTACT_LIST,
  SET_AGENT_LIST,
  SET_CONTACT_LIST1,
  SET_INTERNAL_EXTERNAL,
  SET_CONF_NOTIFI,
  SET_CONF_NOTIFI_VAL,
  ONLOAD_CONTACT_REFRESH,
  SET_CONTACT_LIST2,
  SET_AGENT_AVAIL,
  SET_SELECTED_COLOR,
  SET_LOADMORE,
  SET_SELECTED_USERNAME,
  SET_ADD_TOPIC_INPUT,
  SET_ADD_TOPIC_TOGGLE,
  SET_SELECTED_WHATSAPP,
  SET_SELECTED_FACEBOOK,
  SET_SELECTED_TWITTER,
  SET_SELECTED_TEAMS,
  SET_SELECTED_COMPANY,
  SET_SELECTED_ADDRESS,
  SET_SELECTED_ID,
  SET_EXTERNAL_CHAT_DATA,
  SET_MESSAGE,
  SET_CUSTOMER_TYPING,
  SELECTED_CHANNEL_DATA,
  UPDATED_CHANNEL_DATA,
  REFRESH_CONTACT_LIST,
  SET_INTENAL_CHAT_ACTIVE,
  SET_EXTERNAL_CHAT_ACTIVE,
  SET_CONTACT_LISTUPDATED,
  SET_CONTACT_LIST_UPDATED1,
  FORWARD_MAIL,
  SET_CHAT_SPINNER,
  SET_INTERNAL_CHAT_DATA,
  SET_SHOW_EXTENAL_CHAT,
  SET_SHOW_INTENAL_CHAT,
  SET_SEARCH_TERM,
  SET_CHAT_REFRESH,
  SET_MSG_TONE,
  SET_MSG_TONE2,
  SET_SHOW_CARD,
  SET_ACCEPT_CHAT_ACTIVE,
  SET_USER_CHAT_ID,
  SET_SKILLSET,
  SET_DIALED_NUMBER_INC,
  SET_AGENT_DIALED_NUMBER,
  SET_SHOW_DIALED_NUMBER,
  SET_SHOW_DIALED_NUMBER2,
  SET_SHOW_DIALED_NUMBER3,
  SET_SHOW_DIALED_NUMBER4,
  SET_SHOW_INCOMING_SCREEN,
  SET_CALL_SCREEN,
  SET_SHOW_INCOMING_NXT_SCREEN,
  SET_CALLED_AGENT_SCREEN,
  SET_CONFERANCE_SCREEN,
  SET_SHOW_MUTE,
  SET_SHOW_UNMUTE,
  SET_AGENT_SHOW_MUTE,
  SET_AGENT_SHOW_UNMUTE,
  SET_SHOW_HOLD,
  SET_SHOW_UNHOLD,
  SET_AGENT_CALL_HOLD,
  SET_AGENT_CALL_UNHOLD,
  SET_TRANSFER_CALL,
  SET_MAKING_TRANSFER_CALL,
  SET_CONFERANCE_CALL,
  SET_MERGE_CALL,
  SET_CALL_CONTACTID,
  SET_CONSULTED_CONTACT_ID,
  SET_CALL_ID,
  SET_VOICE_SECONDS,
  SET_VOICE_MINUTES,
  SET_VOICE_HOURS,
  SET_LOADMORE_USER,
  SET_VOICE_SESSION_ID,
  SET_SELETED_VOICE_SESSION_ID,
  SET_SIDEBAR_VAL,
  SET_NOTIFICATION,
  SET_SHOW_USER_PROFILE,
  SET_PULL_CONTACT,
  SET_SHOW_VOICE_CALL,
  SET_CANNEDMESSAGE_DIV,
  SET_CUSTOMER_DETAILS_SHOW,
  SET_INCOMING_REQUEST_REFRESH,
  SET_ISWRITE,
  SET_COUPLE_EMAIL,
  SET_LOAD_MORE_SPINNER,
  SET_VOICE_REFRESH,
  SET_SELECTED_CONTACT_ID,
  SET_USERLIST_SHOW,
  SET_IS_VIP,
  SET_VALUE,
  SET_SUPERVISOR_LIST_REFRESH,
  SET_UPDATE_AGENT_STATUS_LIST,
  SET_SEND_FILE_FROM_FILES,
  SET_SEND_FILE_FROM_FILES_TYPE,
  SET_SEND_FILE_FROM_FILES_NAME,
  SET_SHOWING_STATUS_CLASS,
  SET_AGENTSTATUSLIST,
  SET_AGENTSTATUS_REFREASH,
  SET_SHOWING_STATUS,
} from "../../redux/actions/type";

export const initialState = {
  agentStatusListInSupervisor: [],
  agentStatusrefreash: "false",
  showingStatus: "Ready",
  value: 0,
  userlistshow: false,
  VoiceRefresh: false,
  loadmoreSpinner: false,
  incomingRequestRefresh: false,
  CustomerDetailsShow: false,
  pullcontact: false,
  userChatID: "",
  loadmoreUser: "",
  acceptChatActive: false,
  showCard: false,
  msgTone: false,
  msgTone2: false,
  chatRefresh: true,
  searchterm: "",
  showExternalChat: false,
  showInternalChat: false,
  internalChatData: [],
  externalChatData: [],
  spaceContent: [],
  availAgent: [],
  conferenceType: "",
  client: null,
  selecteduserimage: "",
  uploadedFile: [],
  chatid: "",
  chatduration: "",
  cannedMessage: [],
  showErrorAlert: false,
  errorMessage: "",
  errorData: "",
  showvideocallModal: false,
  internalchatnotify: false,
  onloadContactRefresh: true,
  recording: false,
  togglechat: false,
  refreshtogglechat: false,
  confchatrefresh: false,
  internalchatrefresh: false,
  chat: [],
  chatdataforinternal: [],
  chatType: "",
  chatMessage: [],

  internalExternal: 0,
  contactList2: [],

  agentList: [],

  conferenceNotification: false,
  conferenceNotificationVal: "",
  usercolor: "",
  loadmore: false,
  addTopicInput: "",
  addTopicToggle: false,

  internalChatActive: "",
  externalChatActive: "",
  selectedusername: "",
  selectedemail: "",
  selectedmobile: "",
  selectedwhatsapp: "",
  selectedfacebook: "",
  selectedtwitter: "",
  selectedteams: "",
  selectedcompany: "",
  selectedaddress: "",
  selectedid: "",
  selectedContactId: "",
  selectedisvip: "",

  cannedmessagediv: false,

  /** Chat Component Redux Variable **/
  message: [],
  customerTyping: false,
  /** Message **/

  /**Email Channel Reducers Variables */
  emaiIsPending: false,
  emailShowMessage: false,
  emailMessage: "",
  emailShowColor: "",
  InboxDataList: [],
  InboxEmailContent: {},
  InboxEmailBody: "",
  NewEmailDataList: [],
  SentItemList: [],
  /***************** */
  loadmoretoggle: false,
  selectedchanneldata: [],
  updatechanneldata: [],
  refreshContactlist: false,
  forwardmail: false,
  chatspinner: false,
  iswrite: false,
  couplemail: true,

  // SIDEBAR AND NOTIFICATIONBAR------------------------------
  showNotification: false,
  showUserProfile: false,

  // SDK-----------------------------------
  skillset: "",
  dailedNumberInc: "",
  agentDialedNumber: "",
  showphonedialer: false,
  showphonedialer2: false,
  showphonedialer3: false,
  showphonedialer4: false,

  showIncommingscreen: false,
  callscreen: false,
  showIncommingNxtscreen: false,
  calledagentscreen: false,
  confrencescreen: false,
  showMute: false,
  showUnMute: false,
  agentshowMute: false,
  agentshowUnMute: false,
  showHold: false,
  showUnHold: false,
  agentCallHold: false,
  agentCallUnHold: false,
  transferCall: false,
  makingTransferCall: false,
  confrence: false,
  mergecall: false,
  contactId: "",
  consultedContactId: "",
  callId: "",
  voiceSessionId: "",
  seletedVoiceSessionId: "",
  voiceseconds: "",
  voiceminutes: "",
  voicehours: "",
  showVoiceCall: false,
  sendfilefromfiles: "",
  sendfilefromfilesType: "",
  sendfilefromfilesName: "",
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENTSTATUSLIST: {
      return {
        ...state,
        agentStatusListInSupervisor: action.val,
      };
    }
    case SET_ISWRITE: {
      return {
        ...state,
        iswrite: action.val,
      };
    }
    case SET_SEND_FILE_FROM_FILES: {
      return {
        ...state,
        sendfilefromfiles: action.val,
      };
    }

    case SET_SEND_FILE_FROM_FILES_TYPE: {
      return {
        ...state,
        sendfilefromfilesType: action.val,
      };
    }
    case SET_SEND_FILE_FROM_FILES_NAME: {
      return {
        ...state,
        sendfilefromfilesName: action.val,
      };
    }

    case SET_AGENTSTATUS_REFREASH: {
      return {
        ...state,
        agentStatusrefreash: action.val,
      };
    }
 
    case SET_VALUE: {
      return {
        ...state,
        value: action.val,
      };
    }
    case SET_VOICE_REFRESH: {
      return {
        ...state,
        VoiceRefresh: action.val,
      };
    }

    case SET_UPDATE_AGENT_STATUS_LIST: {
      return {
        ...state,
        teamactivitydashboardcount: action.val,
      };
    }

    case SET_SHOWING_STATUS: {
      return {
        ...state,
        showingStatus: action.val,
      };
    }
    case SET_SHOWING_STATUS_CLASS: {
      return {
        ...state,
        showingStatusClass: action.val,
      };
    }

    case SET_SUPERVISOR_LIST_REFRESH: {
      return {
        ...state,
        supervisorlistrefresh: action.val,
      };
    }

    case SET_USERLIST_SHOW: {
      return {
        ...state,
        userlistshow: action.val,
      };
    }

    case SET_LOAD_MORE_SPINNER: {
      return {
        ...state,
        loadmoreSpinner: action.val,
      };
    }

    case SET_IS_VIP: {
      return {
        ...state,
        selectedisvip: action.val,
      };
    }

    case SET_COUPLE_EMAIL: {
      return {
        ...state,
        couplemail: action.val,
      };
    }

    case SET_COUPLE_EMAIL: {
      return {
        ...state,
        couplemail: action.val,
      };
    }

    case SET_INCOMING_REQUEST_REFRESH: {
      return {
        ...state,
        incomingRequestRefresh: action.val,
      };
    }
    case SET_CUSTOMER_DETAILS_SHOW: {
      return {
        ...state,
        CustomerDetailsShow: action.val,
      };
    }
    case SET_SHOW_VOICE_CALL: {
      return {
        ...state,
        showVoiceCall: action.val,
      };
    }
    case SET_NOTIFICATION: {
      return {
        ...state,
        showNotification: action.val,
      };
    }

    case SET_CANNEDMESSAGE_DIV: {
      return {
        ...state,
        cannedmessagediv: action.val,
      };
    }

    case SET_PULL_CONTACT: {
      return {
        ...state,
        setPullContact: action.val,
      };
    }

    case SET_SHOW_USER_PROFILE: {
      return {
        ...state,
        showUserProfile: action.val,
      };
    }
    case SET_SELETED_VOICE_SESSION_ID: {
      return {
        ...state,
        seletedVoiceSessionId: action.val,
      };
    }
    case SET_VOICE_SECONDS: {
      return {
        ...state,
        voiceseconds: action.val,
      };
    }
    case SET_VOICE_MINUTES: {
      return {
        ...state,
        voiceminutes: action.val,
      };
    }
    case SET_VOICE_HOURS: {
      return {
        ...state,
        voicehours: action.val,
      };
    }
    case SET_SKILLSET: {
      return {
        ...state,
        skillset: action.val,
      };
    }
    case SET_DIALED_NUMBER_INC: {
      return {
        ...state,
        dailedNumberInc: action.val,
      };
    }
    case SET_AGENT_DIALED_NUMBER: {
      return {
        ...state,
        agentDialedNumber: action.val,
      };
    }
    case SET_SHOW_DIALED_NUMBER: {
      return {
        ...state,
        showphonedialer: action.val,
      };
    }
    case SET_SHOW_DIALED_NUMBER2: {
      return {
        ...state,
        showphonedialer2: action.val,
      };
    }
    case SET_SHOW_DIALED_NUMBER3: {
      return {
        ...state,
        showphonedialer3: action.val,
      };
    }
    case SET_SHOW_DIALED_NUMBER4: {
      return {
        ...state,
        showphonedialer4: action.val,
      };
    }
    case SET_SHOW_INCOMING_SCREEN: {
      return {
        ...state,
        showIncommingscreen: action.val,
      };
    }
    case SET_CALL_SCREEN: {
      return {
        ...state,
        callscreen: action.val,
      };
    }
    case SET_SHOW_INCOMING_NXT_SCREEN: {
      return {
        ...state,
        showIncommingNxtscreen: action.val,
      };
    }
    case SET_CALLED_AGENT_SCREEN: {
      return {
        ...state,
        calledagentscreen: action.val,
      };
    }
    case SET_CONFERANCE_SCREEN: {
      return {
        ...state,
        confrencescreen: action.val,
      };
    }
    case SET_SHOW_MUTE: {
      return {
        ...state,
        showMute: action.val,
      };
    }
    case SET_SHOW_UNMUTE: {
      return {
        ...state,
        showUnMute: action.val,
      };
    }
    case SET_AGENT_SHOW_MUTE: {
      return {
        ...state,
        agentshowMute: action.val,
      };
    }
    case SET_AGENT_SHOW_UNMUTE: {
      return {
        ...state,
        agentshowUnMute: action.val,
      };
    }
    case SET_SHOW_HOLD: {
      return {
        ...state,
        showHold: action.val,
      };
    }
    case SET_SHOW_UNHOLD: {
      return {
        ...state,
        showUnHold: action.val,
      };
    }
    case SET_AGENT_CALL_HOLD: {
      return {
        ...state,
        agentCallHold: action.val,
      };
    }
    case SET_AGENT_CALL_UNHOLD: {
      return {
        ...state,
        agentCallUnHold: action.val,
      };
    }
    case SET_TRANSFER_CALL: {
      return {
        ...state,
        transferCall: action.val,
      };
    }
    case SET_MAKING_TRANSFER_CALL: {
      return {
        ...state,
        makingTransferCall: action.val,
      };
    }
    case SET_CONFERANCE_CALL: {
      return {
        ...state,
        confrence: action.val,
      };
    }
    case SET_MERGE_CALL: {
      return {
        ...state,
        mergecall: action.val,
      };
    }
    case SET_CALL_CONTACTID: {
      return {
        ...state,
        contactId: action.val,
      };
    }
    case SET_CONSULTED_CONTACT_ID: {
      return {
        ...state,
        consultedContactId: action.val,
      };
    }
    case SET_CALL_ID: {
      return {
        ...state,
        callId: action.val,
      };
    }
    case SET_VOICE_SESSION_ID: {
      return {
        ...state,
        voiceSessionId: action.val,
      };
    }
    // SDK-----------------------------------------------------------------------

    case SET_USER_CHAT_ID: {
      return {
        ...state,
        userChatID: action.val,
      };
    }
    case SET_LOADMORE_USER: {
      return {
        ...state,
        loadmoreUser: action.val,
      };
    }
    case SET_ACCEPT_CHAT_ACTIVE: {
      return {
        ...state,
        acceptChatActive: action.val,
      };
    }
    case SET_SHOW_CARD: {
      return {
        ...state,
        showCard: action.val,
      };
    }
    case SET_MSG_TONE: {
      return {
        ...state,
        msgTone: action.val,
      };
    }
    case SET_MSG_TONE2: {
      return {
        ...state,
        msgTone2: action.val,
      };
    }
    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchterm: action.val,
      };
    }
    case SET_CHAT_REFRESH: {
      return {
        ...state,
        chatRefresh: action.val,
      };
    }
    case SET_SHOW_EXTENAL_CHAT: {
      return {
        ...state,
        showExternalChat: action.val,
      };
    }
    case SET_SHOW_INTENAL_CHAT: {
      return {
        ...state,
        showInternalChat: action.val,
      };
    }
    case SET_CHAT_SPINNER: {
      return {
        ...state,
        chatspinner: action.val,
      };
    }
    case SET_INTENAL_CHAT_ACTIVE: {
      return {
        ...state,
        internalChatActive: action.val,
      };
    }
    case SET_EXTERNAL_CHAT_ACTIVE: {
      return {
        ...state,
        externalChatActive: action.val,
      };
    }
    case SET_LOADMORE: {
      return {
        ...state,
        loadmoretoggle: action.val,
      };
    }
    case FORWARD_MAIL: {
      return {
        ...state,
        forwardmail: action.val,
      };
    }
    case REFRESH_CONTACT_LIST:
      return {
        ...state,
        refreshContactlist: action.val,
      };
    case SELECTED_CHANNEL_DATA:
      return {
        ...state,
        selectedchanneldata: action.val,
      };
    case UPDATED_CHANNEL_DATA:
      return {
        ...state,
        updatechanneldata: action.val,
      };
    case SEND_MESSAGE:
      return {
        ...state,
        cannedMessage: action.val,
      };
    case CHAT_DATA:
      return {
        ...state,
        chat: action.val,
      };
    case SET_AGENT_AVAIL:
      return {
        ...state,
        availAgent: action.val,
      };
    case CHAT_DATA_FOR_INTERNAL:
      return {
        ...state,
        chatdataforinternal: action.val,
      };
    case INTERNAL_CHAT_NOTIFY:
      return {
        ...state,
        internalchatnotify: action.val,
      };

    case ONLOAD_CONTACT_REFRESH:
      return {
        ...state,
        onloadContactRefresh: action.val,
      };
    case TYPE_CHAT:
      return {
        ...state,
        chatType: action.val,
      };

    case SET_AGENT_LIST:
      return {
        ...state,
        agentList: action.val,
      };
    case SET_CONF_NOTIFI:
      return {
        ...state,
        conferenceNotification: action.val,
      };
    case SET_CONF_NOTIFI_VAL:
      return {
        ...state,
        conferenceNotificationVal: action.val,
      };
    case SET_INTERNAL_EXTERNAL:
      return {
        ...state,
        internalExternal: action.val,
      };
    case SET_CONTACT_LIST2:
      return {
        ...state,
        contactList2: action.val,
      };
    case CHAT_MESSAGE:
      return {
        ...state,
        chatMessage: action.val,
      };
    case SET_SELECTED_EMAIL:
      return {
        ...state,
        selectedemail: action.val,
      };
    case SET_SELECTED_MOBILE:
      return {
        ...state,
        selectedmobile: action.val,
      };
    case SET_CHAT_ID:
      return {
        ...state,
        chatid: action.val,
      };
    case SET_CHAT_DURATION:
      return {
        ...state,
        chatduration: action.val,
      };
    case SET_SELECTED_USERIMAGE:
      return {
        ...state,
        selecteduserimage: action.val,
      };
    case TOGGLE_RECORDING: {
      return {
        ...state,
        recording: action.val,
      };
    }
    case SET_UPLOADED_FILE: {
      return {
        ...state,
        uploadedFile: [action.val],
      };
    }
    case TOOGLE_CHAT: {
      return {
        ...state,
        togglechat: action.val,
      };
    }
    case SET_LOADMORE: {
      return {
        ...state,
        loadmore: action.val,
      };
    }
    case SET_SELECTED_USERNAME:
      return {
        ...state,
        selectedusername: action.val,
      };

    case REFRESH_TOOGLE_CHAT: {
      return {
        ...state,
        refreshtogglechat: action.val,
      };
    }
    case CONF_CHAT_REFRESH: {
      return {
        ...state,
        confchatrefresh: action.val,
      };
    }
    case INTERNAL_CHAT_REFRESH: {
      return {
        ...state,
        internalchatrefresh: action.val,
      };
    }
    case SET_SELECTED_COLOR: {
      return {
        ...state,
        usercolor: action.val,
      };
    }
    case SET_ADD_TOPIC_INPUT: {
      return {
        ...state,
        addTopicInput: action.val,
      };
    }
    case SET_ADD_TOPIC_TOGGLE: {
      return {
        ...state,
        addTopicToggle: action.val,
      };
    }
    case SET_SELECTED_WHATSAPP: {
      return {
        ...state,
        selectedwhatsapp: action.val,
      };
    }
    case SET_SELECTED_FACEBOOK: {
      return {
        ...state,
        selectedfacebook: action.val,
      };
    }
    case SET_SELECTED_TWITTER: {
      return {
        ...state,
        selectedtwitter: action.val,
      };
    }
    case SET_SELECTED_TEAMS: {
      return {
        ...state,
        selectedteams: action.val,
      };
    }
    case SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedcompany: action.val,
      };
    }
    case SET_SELECTED_ADDRESS: {
      return {
        ...state,
        selectedaddress: action.val,
      };
    }
    case SET_SELECTED_ID: {
      return {
        ...state,
        selectedid: action.val,
      };
    }

    case SET_SELECTED_CONTACT_ID: {
      return {
        ...state,
        selectedContactId: action.val,
      };
    }

    case SET_EXTERNAL_CHAT_DATA: {

      return {
        ...state,
        externalChatData: action.val,
      };
    }
    case SET_INTERNAL_CHAT_DATA: {
      return {
        ...state,
        internalChatData: action.val,
      };
    }
    /** Chat Component Redux Variable **/
    case SET_CUSTOMER_TYPING: {
      return {
        ...state,
        customerTyping: action.val,
      };
    }
    case SET_MESSAGE: {
      return {
        ...state,
        message: action.val,
      };
    }

    /**Email Channel Reucer case */
    case "INBOX_PENDING":
      return Object.assign({}, state, {
        emaiIsPending: action.emaiIsPending,
      });
    case "INBOX_MAIL_LIST_GET_SUCCESS":
      return Object.assign({}, state, {
        InboxDataList: action.InboxDataList,
        emaiIsPending: action.emaiIsPending,
      });
    case "INBOX_MAIL_COMPLETE_SUCCESS":
      return Object.assign({}, state, {
        completedDataList: action.completedDataList,
        emaiIsPending: action.emaiIsPending,
      });
    case "INBOX_MAIL_CONTENT_SUCCESS":
      return Object.assign({}, state, {
        InboxEmailContent: action.InboxEmailContent,
        InboxEmailBody: action.InboxEmailBody,
        emaiIsPending: action.emaiIsPending,
      });
    case "INBOX_WRITE_MAIL":
      return Object.assign({}, state, {
        writeEmail: action.writeEmail,
        isReplyEmail: action.isReplyEmail,
      });
    case "INBOX_FAILED":
      return Object.assign({}, state, {
        emailMessage: action.emailMessage,
        emailShowMessage: action.emailShowMessage,
        emaiIsPending: action.emaiIsPending,
        emailShowColor: action.emailShowColor,
      });
    case "INBOX_FAILED_CLOSE":
      return Object.assign({}, state, {
        emailShowMessage: action.emailShowMessage,
        emailMessage: action.emailMessage,
      });
    /***************************** */

    default:
      return state;
  }
};
