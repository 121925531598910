import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
// import MultimediaFilter from "../../components/multimediaComponent/MultimediaFilter";
import "../../assets/assets/assets_newchat/library/bootstrap-5.1.3/css/bootstrap.min.css";
import "../../assets/assets/assets_newchat/assets/fonts/fontawesome-free-6.1.1-web/css/all.css";
import "../../assets/assets/assets_newchat/library/animate/animate.min.css";
import "../../assets/assets/assets_newchat/library/slimselect/slimselect.min.css";
import MultimediaFilter from "../../components/multimediaComponent/MultimediaFilter";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Pagination, Skeleton } from "@mui/material";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import facebook from "../../assets/assets/images/facebook.png";
import whatsapp from "../../assets/assets/images/whatsapp.png";
import webchat from "../../assets/assets/images/chat-icon.png";
import twitter from "../../assets/assets/images/twitter.png";
import email from "../../assets/assets/images/email.png";
import voice from "../../assets/assets/images/voice.png";
import "./multimedia.css";
import MultimediaFilterButton from "../../components/agent-report-component/multimediaFilterButton";
import { useNavigate } from "react-router-dom";

import {
  setPullContact,
  setChatRefresh,
  setsidebarVal,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    pullcontact: state.data.pullcontact,
  };
};

const MultimediaSearch = (props) => {
  const navigate = useNavigate();
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  var agentRole = JSON.parse(localStorage.getItem("tokenAgent"));
  var roleOfUser = agentRole.role;
  const [showfilter, setshowfilter] = useState("sidebar_filter");
  const [mediaSearchDatas, setMediaSearchDatas] = useState([]);
  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [name, setName] = useState("");
  const [emails, setEmail] = useState("");
  const [searchbysubject, setsearchBySubject] = useState("");
  const [searchbybody, setsearchByBody] = useState("");
  const [channel,setChannel]=useState("webchat")
  const [status,setStatus]=useState("")
  const [agent, seAagent] = useState('')


  const [skillSet, setSkillset] = useState("");
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingMultimedia, setloadingMultimedia] = useState(false);


  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const multiMediaSearch = () => {
    setloadingMultimedia(true);
    const requestData = {
      customerName: name ? name : "",
      email: emails ? emails : "",
      fromDate: fromdate ? fromdate : defaultValue,
      toDate: todate ? todate : defaultValue,
      category: skillSet ? skillSet : "",
      limit: 10,
      offset: page == 1 ? 0 : (page - 1) * 10,
      subject: searchbysubject ? searchbysubject : "",
      email_body: searchbybody ? searchbybody : "",
      agent: roleOfUser == "Supervisor" ? agent : agentRole.agent_id,
      channel:channel,
      status:status
    };

    const headers = {
      "Content-Type": "application/json",
      Tenantid: localStorage.getItem("TenantId"),
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    axios
      .post(BaseUrl + "/reports/multiMediaSearch", requestData, { headers })
      .then((response) => {
        console.log("multiMediaSearch", response);
        if (response.data.status == true) {
          setMediaSearchDatas(response.data.Data);
          setPagination(response.data.count);
          setTimeout(() => {
            setloadingMultimedia(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setMediaSearchDatas([]);
          setTimeout(() => {
            setloadingMultimedia(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const PullContactApi = (item) => {
    const tokenAgentValue = JSON.parse(localStorage.getItem("tokenAgent"));
    const clientValue = JSON.parse(localStorage.getItem("client"));
    const requestData = {
      chat_session_id: item.chat_session_id,
      agent_id: tokenAgentValue.agent_id,
      user_id: tokenAgentValue.user_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Tenantid: localStorage.getItem("TenantId"),
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    axios
      .post(BaseUrl + "/email/emailpullcontact", requestData, { headers })
      .then((response) => {
        if (response.status) {
          toast.success("Email Pull Successfully", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            let permission = JSON.parse(localStorage.getItem("permission"));
            props.setsidebarVal("Chat");
            props.setChatRefresh(true);
            navigate("/main/Chat");
            permission.find((element, index) => {
              if (element.moduleName == "Chat")
                localStorage.setItem("indexOf", index);
            });
          }, 2000);
        } else {
          props.setPullContact(false);
        }
        //
        // multiMediaSearch()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    multiMediaSearch();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <MultimediaFilter
        multiMediaSearch={multiMediaSearch}
        showfilter={showfilter}
        setshowfilter={setshowfilter}
        setName={setName}
        setEmail={setEmail}
        setSkillset={setSkillset}
        fromdate={fromdate}
        setFromdate={setFromdate}
        todate={todate}
        setTodate={setTodate}
        setPage={setPage}
        setsearchBySubject={setsearchBySubject}
        setsearchByBody={setsearchByBody}
        setChannel={setChannel}
        setStatus={setStatus}
        seAagent={seAagent}
      />

      <MultimediaFilterButton setshowfilter={setshowfilter} />

      <div className="multimediatable container-fluid">
        <div className="card fixed-table-card vh-79 multimediacard">
          <div className="card-body multimediabody">
            <div className="table-responsive vhm-68">
              <table className="table table-sm table-hover align-middle table-fixeded">
                <thead>
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Channel</th>
                    <th scope="col">Status</th>
                    <th scope="col">Skillset</th>
                    <th scope="col">Language</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingMultimedia ? (
                    [...Array(8)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {[...Array(8)].map((_, colIndex) => (
                          <td key={colIndex}>
                            <Skeleton variant="text" width={90} height={30} />
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : Array.isArray(mediaSearchDatas) &&
                    mediaSearchDatas.length > 0 ? (
                    mediaSearchDatas.map((rowData, rowIndex) => (
                      <tr key={rowIndex._id} className="text-break">
                        <td>{rowData.unique_id? rowData.unique_id.username:''}</td>
                        <td>574589399994</td>
                        <td>{rowData.email}</td>
                        <td>
                          {rowData.channel == "webchat" ? (
                            <img
                              className="icons-size"
                              src={webchat}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_whatsapp" ? (
                            <img
                              className="icons-size"
                              src={whatsapp}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_twitter" ? (
                            <img
                              className="icons-size"
                              src={twitter}
                              alt="webchat"
                            />
                          ) : rowData.channel == "voice" ? (
                            <img
                              className="icons-size"
                              src={voice}
                              alt="webchat"
                            />
                          ) : rowData.channel == "from_facebook" ? (
                            (
                              <img
                                className="icons-size"
                                src={facebook}
                                alt="webchat"
                              />
                            ) || rowData.channel == "from_teams"
                          ) : (
                            <img
                              className="icons-size"
                              src={email}
                              alt="webchat"
                            />
                          )}
                        </td>
                        <td>{rowData.status}</td>
                        <td>{rowData.skillset}</td>
                        <td>{rowData.language}</td>
                        <td>
                          <div>
                            {rowData.channel=='email'?
                            
                            <BootstrapTooltip
                              title={"Pull"}
                              arrow
                              placement="top"
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="contact-mail"
                                onClick={() => {
                                  PullContactApi(rowData);
                                }}
                              >
                                <i
                                  className="fa-solid fa-code-pull-request"
                                  color="blue"
                                ></i>
                              </button>
                            </BootstrapTooltip>:''
                          }
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="15">
                        <div
                          className="text-muted"
                          style={{
                            flexDirection: "column",
                            marginTop: "20%",
                            display: "flex",
                            position: "absolute",
                            left: "45%",
                      
                            top: "-10%",
              
                          }}
                        >
                          <span className="mx-auto">
                            <RiErrorWarningLine size={30} />
                          </span>
                          <span className="mx-auto">
                            <h5
                              className="text-muted mt-2"
                              style={{ fontFamily: "poppins" }}
                            >
                              {Array.isArray(mediaSearchDatas)
                                ? "No Data Found"
                                : "No Data Found"}
                            </h5>
                          </span>
                        </div>
                      </td>
                    </tr>
          
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
            <Pagination
              count={Math.ceil(pagination / 10)}
              page={page}
              color="primary"
              size="small"
              style={{ float: "right" }}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setPullContact,
  setChatRefresh,
  setsidebarVal,
})(MultimediaSearch);
