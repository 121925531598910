import React, { useEffect, useRef } from "react";
import chatIcon from "../../assets/assets/images/chat-icon.png";
import chatIconWhatsapp from "../../assets/assets/images/whatsapp.png";
import chatIconfacebook from "../../assets/assets/images/facebook.png";
import chatIcontwitter from "../../assets/assets/images/twitter.png";
import chatIconteams from "../../assets/assets/images/teams.png";
import emailIcon from "../../assets/assets/images/email.png";
import videochat from "../../assets/assets/images/videochat.png";
import chatbot from "../../assets/assets/images/chatbot.png";
import { BaseUrl, emailservice } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  setcontactlist2,
  setcontactlist,
  setChatRefresh,
  setExternalChatData,
  setshowExternalChat,
  setAcceptChatActive,
  setsidebarVal,
  setIncomingRequestRefresh,
  setIswrite,
  setcouplemail,
  setUserlistShow,
} from "../../redux/actions/actions";

import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    contactList2: data.contactList2,
    incomingRequestRefresh: data.incomingRequestRefresh,
    chat: state.data.chat,
    userlistshow: state.data.userlistshow,
  };
};

const NewIncomingRequest = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [props.incomingRequestRefresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkArrivalTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [props.contactList2]);

  const checkArrivalTime = () => {
    const currentTime = moment();
    props.contactList2.forEach((client) => {

      const arrivalTime = moment(client.arrival_at);
      const duration = moment.duration(currentTime.diff(arrivalTime));
      const seconds = duration.asSeconds();

      if (seconds > 30) {
        if (client.channel == "email") {
          rejectEmail(client.chat_session_id, client.emailId);
        } else {
          rejectchat(
            client.chat_session_id,
            client.agent,
            client.id,
            client.skillset,
            client.language,
            client.phonenumber,
            client.channel
          );
        }
      }
    });
  };

  const acceptClient = async (client) => {
    const datas = await JSON.parse(localStorage.getItem("tokenAgent"));
    let filteredArray = props.contactList2.filter((item) => {
      return item.id !== client.id;
    });
    props.setUserlistShow(true);
    props.setcontactlist2(filteredArray);
    await axios
      .post(
        `${BaseUrl}/users/agentConfirmation?agent_id=${datas.id}&client_id=${client.id}&status=Accept&user_id=${datas.user_id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then(async (res) => {
        if (res.data.status) {
          const datas = JSON.parse(localStorage.getItem("tokenAgent"));
          try {
            await axios
              .post(
                `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
                {},
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantId: localStorage.getItem("TenantId"),
                  },
                }
              )
              .then((data) => {
                navigate("/main/Chat");
                props.setIswrite(false);
                props.setcouplemail(true);
                props.setcontactlist(data.data);
                props.setsidebarVal("Chat");
                props.setAcceptChatActive(true);
                props.setshowExternalChat(true);
                props.setExternalChatData(client);
              })
              .catch((err) => {});
          } catch (error) {}
        }
      })
      .catch((err) => {});
  };

  const rejectEmail = async (chat_session_id, emailId) => {
    const access_token = localStorage.getItem("access_token");
    let data = {
      email_id: emailId,
      session_id: chat_session_id,
    };
    await axios
      .post(emailservice + "email/routingReject", data, {
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })

      .then((res) => {})

      .catch((error) => {
        errorHandel(error, "/users/rejectchat");

        if (error.status == 401) {
          window.location.href = "/";
        }
      });
  };
  const rejectchat = async (
    chat_session_id,
    agentid,
    id,
    skillset,
    language,
    phone,
    channel
  ) => {
    let filteredArray = props.contactList2.filter((item) => {
      return item.id !== id;
    });
    props.setcontactlist2(filteredArray);
    let userId = JSON.parse(localStorage.getItem("tokenAgent"));
    await axios
      .post(
        BaseUrl + "/users/rejectchat",
        {
          chat_session_id: chat_session_id,
          userID: userId.user_id,
          agentID: agentid,
          skillset: skillset,
          language: language,
          phone: phone,
          channel: channel,
          session_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        window.location.reload(true);
        if (err.status == 401) {
          window.location.href = "/";
        }
      });
  };

  const getData = async () => {
    const datas = JSON.parse(localStorage.getItem("tokenAgent"));
    await axios
      .post(
        `${BaseUrl}/users/getIncomingUsers/${datas.id}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          props.setcontactlist2(res.data.data);
          props.setIncomingRequestRefresh(false);
        }
      })
      .catch((err) => {
        //
      });

    var value = true;
  };

  const newincomingCrmpopupApi = async (client) => {
    let body = {
      phonenumber: client.phonenumber,
      skillset: client.skillset,
      channel: client.channel,
    };
    await axios
      .post(BaseUrl + "/ticket/crmscreenpop", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("newincomingCrmpopupApi", res);
        window.open(res.data.data);
        toast.success("screen pop url generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast.warning("screen pop url not generated", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="incomecall_details">
        {props.contactList2
          .filter((val) => val.id !== props.contactList2.id)
          .map((client, index) => {
            return (
              <div id="incomming-user-card" className="p-2" key={client.id}>
                <div className="image-paragraph">
                  <div
                    className=" d-flex justify-content-center align-items-center"
                    style={{
                      // background: "#fdfdfd",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      color: "aliceblue",
                      fontSize: "18px",
                      fontWeight: "bold",
                      position: "relative",
                      boxShadow:
                        "rgb(0 0 0 / 0%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 2px",
                    }}
                  >
                    <img
                      src={`${
                        client.channel == "webchat"
                          ? chatIcon
                          : client.channel == "from_whatsapp"
                          ? chatIconWhatsapp
                          : client.channel == "from_facebook"
                          ? chatIconfacebook
                          : client.channel == "from_twitter"
                          ? chatIcontwitter
                          : client.channel == "from_teams"
                          ? chatIconteams
                          : client.channel == "videochat"
                          ? videochat
                          : client.channel == "from_purple"
                          ? chatbot
                          : emailIcon
                      }`}
                      style={{
                        height: "23px",
                        width: "23px",
                        margin: "auto",
                      }}
                    />
                  </div>

                  <p>
                    {client.channel == "email"
                      ? `Email Request From ${client.email}`
                      : client.channel == "videochat"
                      ? `Video chat Request From ${client.email}`
                      : client.transferred
                      ? `Chat transferred from ${client.previous_agent.username}`
                      : `Chat Request From ${
                          client.unique_id.username
                            ? client.unique_id.username
                            : client.unique_id.email
                        }`}
                  </p>
                </div>

                <div className="row mt-2 list-details">
                  <div className="col-6">
                    <p>Skillset :</p>
                  </div>
                  <div className="col-6">
                    <p>{client.skillset}</p>
                  </div>
                  <div className="col-6">
                    <p>Language :</p>
                  </div>
                  <div className="col-6">
                    <p>{client.language}</p>
                  </div>
                  {client.channel == "email" ? (
                    <>
                      <div className="col-6">
                        <p>Sentiment :</p>
                      </div>
                      <div className="col-6">
                        <p>{client.sentiment}</p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {client.channel == "email" ? (
                    <>
                      <div className="col-6">
                        <p>Catagory :</p>
                      </div>
                      <div className="col-6">
                        <p>{client.category}</p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {client.transferred ? (
                    <>
                      <div className="col-6">
                        <p>Customer Name :</p>
                      </div>
                      <div className="col-6">
                        <p>
                          {client.unique_id.username
                            ? client.unique_id.username
                            : client.unique_id.email}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-6">
                        <p>Wait Time :</p>
                      </div>
                      <div className="col-6">
                        <p>{moment(client.arrival_at).fromNow()}</p>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {client.channel == "email" ? (
                    <div>{client.catagory}</div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="request-button mt-2">
                  <button
                    type="button "
                    className="btn btn-success button"
                    id="accept_client_chat"
                    onClick={() => {
                      acceptClient(client, index);
                    }}
                    style={{ width: "70px" }}
                  >
                    <p style={{ color: "#ffff" }}>Accept</p>
                  </button>

                  {client.channel == "email" ? (
                    <button
                      type="button"
                      className="btn btn-danger button me-2"
                      id="reject_client_chat"
                      onClick={() =>
                        rejectEmail(client.chat_session_id, client.emailId)
                      }
                      style={{ width: "70px", marginLeft: "10px" }}
                    >
                      <p style={{ color: "#ffff" }}>Reject</p>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-danger button me-2"
                      id="reject_client_chat"
                      onClick={() =>
                        rejectchat(
                          client.chat_session_id,

                          client.agent,

                          client.id,

                          client.skillset,

                          client.language,

                          client.phonenumber,

                          client.channel
                        )
                      }
                      style={{ width: "70px", marginLeft: "10px" }}
                    >
                      <p style={{ color: "#ffff" }}>Reject</p>
                    </button>
                  )}

                  {JSON.parse(localStorage.getItem("permission")).map(
                    (module) => {
                      if (module.moduleName === "Crm") {
                        const crmScreen = module.screen.find(
                          (item) =>
                            item.screenId === "CRM" && item.read && item.write
                        );

                        if (crmScreen) {
                          return (
                            <button
                              type="button"
                              id="success-btn"
                              className="btn btn-success button"
                              style={{ width: "70px" }}
                              onClick={() => {
                                // handleOpenNewincomingCRM(
                                //   client,
                                //   crmScreen.screenId
                                // );
                                newincomingCrmpopupApi(client);
                                acceptClient(client, crmScreen.screenId);
                              }}
                            >
                              <p style={{ color: "#ffff" }}>CRM</p>
                            </button>
                          );
                        } else {
                          return null;
                        }
                      }
                      return null;
                    }
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist2,
  setcontactlist,
  setChatRefresh,
  setExternalChatData,
  setshowExternalChat,
  setAcceptChatActive,
  setsidebarVal,
  setIncomingRequestRefresh,
  setIswrite,
  setcouplemail,
  setUserlistShow,
})(NewIncomingRequest);
