import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../src/Page/login/Login";
import Dashboard from "../src/Page/Dashboard/Dashboard";
import Chat from "../src/Page/Chat/Chat";
import SessioReports from "../src/Page/SessionReports/SessionReports";
import AgentReports from "../src/Page/AgentReports/AgentReport";
import Calenders from "../src/Page/Calenders/Calender";
import Contacts from "../src/Page/Contacts/Contacts";
import DashboardContainer from "./Page/EmailComponents/Dashboard/DashboardContainer";
import InboxContainer from "./Page/EmailComponents/Inbox/InboxContainer";
import NewEmailContainer from "./Page/EmailComponents/Inbox/NewEmailContainer";
import SentItemsContainer from "./Page/EmailComponents/Inbox/SentItemsContainer";
import CompletedContainer from "./Page/EmailComponents/Inbox/CompletedContainer";
import Main from "./Page/Main/Main";
import PowerBi from "./Page/PowerBi/PowerBi";
import PowerBiV2 from "./Page/powerBiv2/powerBi";
import PowerBiDashboard from "./Page/PowerBi Dashboard/PowerBiDashboard";
import Bank from "../src/Page/BankPage/Bank";
import ChatForClient from "../src/components/clientChat/index";
import ChatIndex from "../src/components/clientChat/chatindex";
import MultimediaSearch from "./Page/Multimedia/MultimediaSearch";
import MultimediaSearchNew from "./Page/Multimedia/MultimediaSearchNew";
import Cms from "./Page/CMS/Cms";
import AgentContact from "./Page/AgentContact/AgentContact";
import SupervisorContact from "./Page/SupervisorContact/SupervisorContact";
import Statistic from "./components/WebStatistic-component/WebStatistic";
import ViewAllAgentDialogBox from "./Page/Dashboard/ViewAllAgentDialogBox";
import QueueChat from "./Page/QueueChat/QueueChat";
import NewMain from "./Page/Main/NewMain";
import DashboardNew from "./Page/Dashboard/DashboardNew";
import SessionReportsNew from "./Page/SessionReports/SessionReportsNew";
import AgentReportNew from "./Page/AgentReports/AgentReportNew";
import AgentContactNew from "./Page/AgentContact/AgentContactNew";
import CmsNew from "./Page/CMS/CmsNew";
import ChatNew from "./Page/Chat/ChatNew";
import ContactsNew from "./Page/Contacts/ContactsNew";
import AgentStatusUpdate from "./components/agent-StatusUpdate/agentStatusUpdate";
import SupervisorContactNew from "./Page/SupervisorContact/SupervisorContactNew";


import  Reportmainpage  from "./components/Reports/Reportmainage";
import Calldetailsreports from "./components/Reports/Calldetailsreports";
import Agentskillsetreports from "./components/Reports/Agentskillsetreports";
import Agentlogin from "./components/Reports/AgentLogin";
import Outbounddetailedreport from "./components/Reports/Outbounddetailedreport";
import Outboundsummaryreport from "./components/Reports/Outboundsummaryreport";
import Servicereport from "./components/Reports/Servicereport";
import Yearlyreport from "./components/Reports/Yearlyreport";
import Skillsetsummary from "./components/Reports/Skillsetsummary";
import Weeklyreport from "./components/Reports/Weeklyreport";
import Monthlyreport from "./components/Reports/Monthlyreport";
import Quaterlyreport from "./components/Reports/Quaterlyreport";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="customerPortal" element={<Bank />} />
      <Route path="ChatForClient" element={<ChatForClient />} />
      <Route path="ChatIndex" element={<ChatIndex />} />
      <Route path="Agent-List" element={<ViewAllAgentDialogBox />} />

      <Route path="/main" element={<Main />}>
        {/* <Route path="/main" element={<NewMain />}> */}


        <Route path="Dashboard" element={<Dashboard />} />
        {/* <Route path="Dashboard" element={<DashboardNew />} /> */}

        <Route path="Chat" element={<Chat />} />
        {/* <Route path="Chat" element={<ChatNew />} /> */}

        <Route path="SessioReports" element={<SessioReports />} />
        {/* <Route path="SessioReports" element={<SessionReportsNew />} /> */}

        <Route path="AgentReports" element={<AgentReports />} />
        {/* <Route path="AgentReports" element={<AgentReportNew />} /> */}

        <Route path="Agent-Contact" element={<AgentContact />} />
        {/* <Route path="Agent-Contact" element={<AgentContactNew />} /> */}

        <Route path="multimediasearch" element={<MultimediaSearch />} />
        {/* <Route path="multimediasearch" element={<MultimediaSearchNew />} /> */}

        <Route path="CMS" element={<Cms />} />
        {/* <Route path="Cms" element={<CmsNew />} /> */}

        <Route path="Contacts" element={<Contacts />} />
        {/* <Route path="Contacts" element={<ContactsNew />} /> */}

        <Route path="supervisorContact" element={<SupervisorContact />} />
        {/* <Route path="supervisorContact" element={<SupervisorContactNew />} /> */}

        <Route path="Queue-Chat" element={<QueueChat />} />
        <Route path="Calenders" element={<Calenders />} />
        <Route path="mail" element={<DashboardContainer />} />
        <Route path="newemail" element={<NewEmailContainer />} />
        <Route path="inbox" element={<InboxContainer />} />
        <Route path="completeemail" element={<CompletedContainer />} />
        <Route path="sentitems" element={<SentItemsContainer />} />
        <Route path="powerBi" element={<PowerBi />} />
        <Route path="powerBiv2" element={<PowerBiV2 />} />
        <Route path="powerBiDashboard" element={<PowerBiDashboard />} />
        <Route path="supervisor" element={<MultimediaSearch />} />
        <Route path="statistic" element={<Statistic />} />
        <Route path="AgentStatus" element={<AgentStatusUpdate />} />

        <Route path="reports" element={<Reportmainpage />} />

      </Route>

      <Route path="/main/reports/calldetailsreports" element={<Calldetailsreports />} />
        <Route path="/main/reports/agentskillsetreports" element={<Agentskillsetreports />} />
        <Route path="/main/reports/agentlogin" element={<Agentlogin />} />
        <Route path="/main/reports/outbounddetailedreport" element={<Outbounddetailedreport />} />
        <Route path="/main/reports/outboundsummaryreport" element={<Outboundsummaryreport/>} />
        <Route path="/main/reports/servicereport" element={<Servicereport/>} />
        <Route path="/main/reports/yearlyreport" element={<Yearlyreport/>} />
        <Route path="/main/reports/skillsetsummary" element={<Skillsetsummary/>} />
        <Route path="/main/reports/weeklyreport" element={<Weeklyreport/>} />
        <Route path="/main/reports/monthlyreport" element={<Monthlyreport/>} />
        <Route path="/main/reports/quaterlyreport" element={<Quaterlyreport/>} />
    </Routes>
  );
};

export default App;
