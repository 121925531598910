import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import logo from "../../assets/assets/images/Inaipi_Logo-1.2.png";
import { AppBar, Badge, Box, Stack, Toolbar } from "@mui/material";
import { connect } from "react-redux";
import {
  clearnotify,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setshowExternalChat,
  setExternalChatData,
  setExternalChatActive,
  setshowphonedialer,
} from "../../redux/actions/actions";

import {
  AddressBook,
  Aperture,
  Atom,
  Calendar,
  ChatTeardropDots,
  FileMagnifyingGlass,
  FileText,
  MapTrifold,
  Queue,
  SquaresFour,
  Swap,
  User,
  WebhooksLogo,
} from "@phosphor-icons/react";

const mapStateToProps = (state) => {
  return {
    notification: state.notification.notification,
    sidebarVal: state.contactListData.sidebarVal,
  };
};

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
  transition: "transform 0.3s ease",
  "&:hover": {
    background: "#347EDD",
  },
  "&:active": {
    background: "#347EDD",
    transform: "scale(0.69)",
  },
}));

const NewSidebar = (props) => {
  let agentRole = JSON.parse(localStorage.getItem("tokenAgent"));
  let permission = JSON.parse(localStorage.getItem("permission"));
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  return (
    <>
      <AppBar
        position="static"
        sx={{ background: "#0B3363", boxShadow: "none" }}
      >
        <Toolbar
          sx={{
            "@media screen and (min-width: 600px)": {
              padding: 0,
            },
            height: "100vh",
            width: "100%",
          }}
        >
          <Stack
            alignItems={"center"}
            sx={{ height: "100%", width: "100%", marginTop: "8px" }}
            spacing={4}
          >
            <Stack>
              <Link
                id="logo-aside"
                to={"/main/Dashboard"}
                onClick={() => {
                  props.setsidebarVal("Dashboard");
                  props.setShowNotification(false);
                  props.setShowUserProfile(false);
                  props.setshowExternalChat(false);
                  props.clearnotify();
                  props.setshowphonedialer(false);
                }}
              >
                <img src={logo} alt="" style={{ width: "40px" }} />
              </Link>
            </Stack>
            <Box
              className="CONTACT-LIST-AREA"
              sx={{ height: "80%", overflowY: "scroll" }}
            >
              {agentRole.role === "Agent" ? (
                <Stack spacing={1.5}>
                  {permission.find(
                    (element) => element.moduleName === "Dashboard"
                  ) ? (
                    <Link
                      to={"/main/Dashboard"}
                      onClick={() => {
                        props.setsidebarVal("Dashboard");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setExternalChatActive("");
                        props.setshowphonedialer(false);
                      }}
                    >
                      <BootstrapTooltip
                        title={"Dashboard"}
                        arrow
                        placement="right"
                      >
                        
                        <StyledBox
                          sx={{
                            background:props.sidebarVal == "Dashboard" ? "#347EDD" : "",
                          }}
                        >
                          <SquaresFour size={22} weight="bold" color="white" />
                        </StyledBox>
                      </BootstrapTooltip>
                    </Link>
                  ) : null}
                  {permission.find(
                    (element) => element.moduleName === "Chat"
                  ) ? (
                    <BootstrapTooltip
                      title={"Interaction"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/Chat"}
                        onClick={() => {
                          props.setsidebarVal("Chat");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          document.title = "Inaipi Digital • Chat";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Chat" ? "#347EDD" : "",
                          }}
                        >
                          <ChatTeardropDots
                            size={22}
                            weight="bold"
                            color="white"
                          />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.find(
                    (element) => element.moduleName === "Report"
                  ) ? (
                    <BootstrapTooltip
                      title={"Session Report"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/SessioReports"}
                        onClick={() => {
                          props.setsidebarVal("Sessionreport");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Sessionreport"
                                ? "#347EDD"
                                : "",
                          }}
                        >
                          <FileText size={22} weight="bold" color="white" />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.map((module) => {
                    if (module.moduleName === "Crm") {
                      const crmScreen = module.screen.find(
                        (item) =>
                          item.screenId === "CONTACT" && item.read && item.write
                      );

                      if (crmScreen) {
                        return (
                          <BootstrapTooltip
                            title={"Cms"}
                            arrow
                            placement="right"
                          >
                            <Link
                              to={"/main/Cms"}
                              onClick={() => {
                                props.setsidebarVal("CMS");
                                props.setShowNotification(false);
                                props.setShowUserProfile(false);
                                props.setshowExternalChat(false);
                                props.setExternalChatData("");
                                props.setExternalChatActive("");
                                props.setshowphonedialer(false);
                                document.title = "Inaipi Digital • CMS";
                              }}
                            >
                              <StyledBox
                                sx={{
                                  background:
                                    props.sidebarVal == "CMS" ? "#347EDD" : "",
                                }}
                              >
                                <Atom size={22} weight="bold" color="white" />
                              </StyledBox>
                            </Link>
                          </BootstrapTooltip>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}

                  {permission.map((module) => {
                    if (module.moduleName === "Crm") {
                      const crmScreen = module.screen.find(
                        (item) =>
                          item.screenId === "CONTACTSALES" &&
                          item.read &&
                          item.write
                      );

                      if (crmScreen) {
                        return (
                          <>
                            {" "}
                            <BootstrapTooltip
                              title={"Contact"}
                              arrow
                              placement="right"
                            >
                              <Link
                                to={"/main/Agent-Contact"}
                                onClick={() => {
                                  props.setsidebarVal("AgentContact");
                                  props.setShowNotification(false);
                                  props.setShowUserProfile(false);
                                  props.setshowExternalChat(false);
                                  props.setExternalChatData("");
                                  props.setExternalChatActive("");
                                  props.setshowphonedialer(false);
                                  document.title = "Inaipi Digital • Contact";
                                }}
                              >
                                <StyledBox
                                  sx={{
                                    background:
                                      props.sidebarVal == "AgentContact"
                                        ? "#347EDD"
                                        : "",
                                  }}
                                >
                                  <User size={22} weight="bold" color="white" />
                                </StyledBox>
                              </Link>
                            </BootstrapTooltip>
                          </>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}

                  {permission.find(
                    (element) => element.moduleName === "Multimedia"
                  ) ? (
                    <>
                      <BootstrapTooltip
                        title={"Multimedia Search"}
                        arrow
                        placement="right"
                      >
                        <Link
                          to={"/main/multimediasearch"}
                          onClick={() => {
                            props.setsidebarVal("Multimedia");
                            document.title = "Inaipi Digital • Multimedia";
                          }}
                        >
                          <StyledBox
                            sx={{
                              background:
                                props.sidebarVal == "Multimedia"
                                  ? "#347EDD"
                                  : "",
                            }}
                          >
                            <Aperture size={22} weight="bold" color="white" />
                          </StyledBox>
                        </Link>
                      </BootstrapTooltip>
                    </>
                  ) : (
                    <></>
                  )}

                  {permission.find(
                    (element) => element.moduleName === "Calender"
                  ) ? (
                    <>
                      <BootstrapTooltip
                        title={"Calender"}
                        arrow
                        placement="right"
                      >
                        <Link
                          to={"/main/Calenders"}
                          onClick={() => {
                            props.setsidebarVal("Calender");
                            props.setShowNotification(false);
                            props.setShowUserProfile(false);
                            props.setshowExternalChat(false);
                            props.setExternalChatData("");
                            props.setExternalChatActive("");
                            props.setshowphonedialer(false);
                            document.title = "Inaipi Digital • Calender";
                          }}
                        >
                          <StyledBox
                            sx={{
                              background:
                                props.sidebarVal == "Calender" ? "#347EDD" : "",
                            }}
                          >
                            <Calendar size={22} weight="bold" color="white" />
                          </StyledBox>
                        </Link>
                      </BootstrapTooltip>
                    </>
                  ) : (
                    <></>
                  )}

                  <BootstrapTooltip
                    title={"Web-Statistic"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/statistic"}
                      onClick={() => {
                        props.setsidebarVal("Statistic");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setExternalChatActive("");
                        props.setshowphonedialer(false);
                        document.title = "Inaipi Digital • Web Statistic";
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "Statistic" ? "#347EDD" : "",
                        }}
                      >
                        <WebhooksLogo size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>

                  <BootstrapTooltip
                    title={"CMS Contacts"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/Contacts"}
                      onClick={() => {
                        props.setsidebarVal("Contacts");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setExternalChatActive("");
                        props.setshowphonedialer(false);
                        document.title = "Inaipi Digital • Contacts";
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "Contacts" ? "#347EDD" : "",
                        }}
                      >
                        <AddressBook size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>
                </Stack>
              ) : (
                <Stack spacing={1.5}>
                  {permission.find(
                    (element) => element.moduleName === "Dashboard"
                  ) ? (
                    <Link
                      to={"/main/Dashboard"}
                      onClick={() => {
                        props.setsidebarVal("Dashboard");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.clearnotify();
                        props.setshowphonedialer(false);
                      }}
                    >
                      <BootstrapTooltip
                        title={"Dashboard"}
                        arrow
                        placement="right"
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Dashboard" ? "#347EDD" : "",
                          }}
                        >
                          <SquaresFour size={22} weight="bold" color="white" />
                        </StyledBox>
                      </BootstrapTooltip>
                    </Link>
                  ) : null}
                  {permission.find(
                    (element) => element.moduleName === "Chat"
                  ) ? (
                    <BootstrapTooltip
                      title={"Interaction"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/Chat"}
                        onClick={() => {
                          props.setsidebarVal("Chat");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          document.title = "Inaipi Digital • Chat";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Chat" ? "#347EDD" : "",
                          }}
                        >
                          <ChatTeardropDots
                            size={22}
                            weight="bold"
                            color="white"
                          />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.find(
                    (element) => element.moduleName === "Report"
                  ) ? (
                    <BootstrapTooltip
                      title={"Session Report"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/SessioReports"}
                        onClick={() => {
                          props.setsidebarVal("Sessionreport");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Sessionreport"
                                ? "#347EDD"
                                : "",
                          }}
                        >
                          <FileText size={22} weight="bold" color="white" />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.find(
                    (element) => element.moduleName === "Report"
                  ) ? (
                    <BootstrapTooltip
                      title={"Agent Report"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/AgentReports"}
                        onClick={() => {
                          props.setsidebarVal("Agentreport");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Reports";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Agentreport"
                                ? "#347EDD"
                                : "",
                          }}
                        >
                          <FileMagnifyingGlass
                            size={22}
                            weight="bold"
                            color="white"
                          />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.map((module) => {
                    if (module.moduleName === "Crm") {
                      const crmScreen = module.screen.find(
                        (item) =>
                          item.screenId === "CONTACT" && item.read && item.write
                      );

                      if (crmScreen) {
                        return (
                          <BootstrapTooltip
                            title={"CMS"}
                            arrow
                            placement="right"
                          >
                            <Link
                              to={"/main/CMS"}
                              onClick={() => {
                                props.setsidebarVal("CMS");
                                props.setShowNotification(false);
                                props.setShowUserProfile(false);
                                props.setshowExternalChat(false);
                                props.setExternalChatData("");
                                props.setExternalChatActive("");
                                props.setshowphonedialer(false);
                                document.title = "Inaipi Digital • Contact";
                              }}
                            >
                              <StyledBox
                                sx={{
                                  background:
                                    props.sidebarVal == "CMS" ? "#347EDD" : "",
                                }}
                              >
                                <Atom size={22} weight="bold" color="white" />
                              </StyledBox>
                            </Link>
                          </BootstrapTooltip>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}

                  {permission.map((module) => {
                    if (module.moduleName === "Crm") {
                      const crmScreen = module.screen.find(
                        (item) =>
                          item.screenId === "CONTACTSALES" &&
                          item.read &&
                          item.write
                      );

                      if (crmScreen) {
                        return (
                          <BootstrapTooltip
                            title={"Supervisor-Contact"}
                            arrow
                            placement="right"
                          >
                            <Link
                              to={"/main/supervisorContact"}
                              onClick={() => {
                                props.setsidebarVal("Contact");
                                props.setShowNotification(false);
                                props.setShowUserProfile(false);
                                props.setshowExternalChat(false);
                                props.setExternalChatData("");
                                props.setshowphonedialer(false);
                                document.title =
                                  "Inaipi Digital • Supervisor-Contact";
                              }}
                            >
                              <StyledBox
                                sx={{
                                  background:
                                    props.sidebarVal == "Contact"
                                      ? "#347EDD"
                                      : "",
                                }}
                              >
                                <User size={22} weight="bold" color="white" />
                              </StyledBox>
                            </Link>
                          </BootstrapTooltip>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}

                  {permission.find(
                    (element) => element.moduleName === "Multimedia"
                  ) ? (
                    <BootstrapTooltip
                      title={"Multimedia Search"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/multimediasearch"}
                        onClick={() => {
                          props.setsidebarVal("Multimedia");
                          document.title = "Inaipi Digital • Multimedia";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Multimedia" ? "#347EDD" : "",
                          }}
                        >
                          <Aperture size={22} weight="bold" color="white" />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  {permission.find(
                    (element) => element.moduleName === "Calender"
                  ) ? (
                    <BootstrapTooltip
                      title={"Calender"}
                      arrow
                      placement="right"
                    >
                      <Link
                        to={"/main/Calenders"}
                        onClick={() => {
                          props.setsidebarVal("Calender");
                          props.setShowNotification(false);
                          props.setShowUserProfile(false);
                          props.setshowExternalChat(false);
                          props.setExternalChatData("");
                          props.setshowphonedialer(false);
                          document.title = "Inaipi Digital • Calender";
                        }}
                      >
                        <StyledBox
                          sx={{
                            background:
                              props.sidebarVal == "Calender" ? "#347EDD" : "",
                          }}
                        >
                          <Calendar size={22} weight="bold" color="white" />
                        </StyledBox>
                      </Link>
                    </BootstrapTooltip>
                  ) : null}

                  <BootstrapTooltip
                    title={"Web-Statistic"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/statistic"}
                      onClick={() => {
                        props.setsidebarVal("Statistic");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setExternalChatActive("");
                        props.setshowphonedialer(false);
                        document.title = "Inaipi Digital • Web-Statistic";
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "Statistic" ? "#347EDD" : "",
                        }}
                      >
                        <WebhooksLogo size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>

                  <BootstrapTooltip
                    title={"CMS Contacts"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/Contacts"}
                      onClick={() => {
                        props.setsidebarVal("Contacts");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setExternalChatActive("");
                        props.setshowphonedialer(false);
                        document.title = "Inaipi Digital • Contacts";
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "Contacts" ? "#347EDD" : "",
                        }}
                      >
                        <AddressBook size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>

                  <BootstrapTooltip
                    title={"powerBi v1"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/powerBi"}
                      onClick={() => {
                        props.setsidebarVal("powerBiv1");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setshowphonedialer(false);
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "powerBiv1" ? "#347EDD" : "",
                        }}
                      >
                        <MapTrifold size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>
                  <BootstrapTooltip
                    title={"powerBi v2"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/powerBiv2"}
                      onClick={() => {
                        props.setsidebarVal("powerBiv2");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setshowphonedialer(false);
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "powerBiv2" ? "#347EDD" : "",
                        }}
                      >
                        <MapTrifold size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>

                  <BootstrapTooltip
                    title={"Queue Chat"}
                    arrow
                    placement="right"
                  >
                    <Link
                      to={"/main/Queue-Chat"}
                      onClick={() => {
                        props.setsidebarVal("QueueChat");
                        props.setShowNotification(false);
                        props.setShowUserProfile(false);
                        props.setshowExternalChat(false);
                        props.setExternalChatData("");
                        props.setshowphonedialer(false);
                      }}
                    >
                      <StyledBox
                        sx={{
                          background:
                            props.sidebarVal == "QueueChat" ? "#347EDD" : "",
                        }}
                      >
                        <Queue size={22} weight="bold" color="white" />
                      </StyledBox>
                    </Link>
                  </BootstrapTooltip>
                </Stack>
              )}
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default connect(mapStateToProps, {
  clearnotify,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setshowExternalChat,
  setExternalChatData,
  setExternalChatActive,
  setshowphonedialer,
})(NewSidebar);
