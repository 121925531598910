import { Badge, Stack, Typography, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import ForumIcon from "@mui/icons-material/Forum";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import OutboundIcon from '@mui/icons-material/Outbound';
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import {
  setsidebarVal,
} from "../../redux/actions/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
   
  };
};

const ViewAllAgentDialogBox = () => {
  const navigate = useNavigate();
  const [teamactivitydashboardcount, setTeamactivitydashboardcount] = useState(
    []
  );
  const [OngoingInteractionList, setOngoingInteractionList] = useState([]);
  const [OngoingInteraction, setOngoingInteraction] = useState(false);
  const [Interactionpage, setInteractionpage] = useState(1);
  const [Interactionpagepagination, setInteractionpagePagination] = useState(0);
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const SupervisorObserver = async (el) => {
    localStorage.removeItem("client");
    try {
      const access_token = localStorage.getItem("access_token");
      let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));

      if (
        el.channel == "webchat" ||
        el.channel == "from_whatsapp" ||
        el.channel == "from_facebook" ||
        el.channel == "from_purple"
      ) {
        let dataToPass = {
          sessionId: el.chat_session_id,
          agentId: old_agent_id.agent_id,
          oldAgentId: el.agent,
        };

        const data = await axios.post(
          BaseUrl + "/users/conferenceAgent",
          dataToPass,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );

        if (data.data.status) {
          navigate("/main/Chat");
          props.setsidebarVal("Chat");
          localStorage.setItem("indexOf", 2);
          toast.success("Conferenced to supervisor success", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warn(data.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }

        // }
      } else if (el.channel == "voice") {
        console.log("Current Voice", el);
        try {
          let ssToken = localStorage.getItem("ssoToken");
          await axios
            .post(
              AvcUrl +
                `/voice/terminal/${localStorage.getItem(
                  "agentLoginTerminalId"
                )}/observe/sip:${localStorage.getItem(
                  "agentLoginTerminalId"
                )}@demoaccs.com/${el.voice_contactId}`,
              {},
              {
                headers: {
                  ssoToken: ssToken,
                },
              }
            )
            .then((res) => {
              console.log("SupervisorObserver", res);
            })
            .catch((err) => {});
        } catch (error) {
          console.log("voice", error);
        }
      }
    } catch (error) {
      console.log("SupervisorObserver", error);
    }
  };

  const agentActivityCount = async () => {
    const agentId = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      await axios
        .post(
          BaseUrl + "/dashboard/chatCount",
          {
            from_date: defaultValue,
            to_date: defaultValue,
            offset: 0,
            limit: 100,
            agent_id: agentId.agent_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setTeamactivitydashboardcount(res.data.Data);
          } else {
            setTeamactivitydashboardcount([]);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    agentActivityCount();
  }, []);

  const openOngoingInteractionApi = (agentID) => {
    console.log("openOngoingInteractionApi",agentID);
    let body = {
      agentId: agentID,
      offset: Interactionpage == 1 ? 0 : (Interactionpage - 1) * 5,
      limit: 5,
    };
    axios
      .post(BaseUrl + "/users/agentActiveChat", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setOngoingInteractionList(res.data.data);
        setInteractionpagePagination(res.count);
      })

      .catch((error) => {
        setOngoingInteractionList([]);
      });
  };

  const openOngoingInteractionDialogBox = (value) => {
    openOngoingInteractionApi(value);
    setOngoingInteraction(true);
  };
  const OngoingInteractionDialogBoxclose = () => {
    setOngoingInteraction(false);
  };
  const InteractionhandleChange = (event, value) => {
    setInteractionpage(value);
  };
  useEffect(() => {
    openOngoingInteractionApi();
  }, [Interactionpage]);



  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div
            className="card"
            style={{ height: "100vh", overflowY: "scroll" }}
          >
            <div className="card-body">
              <Stack direction={"row"} sx={{ display: "flex" }}>
                <Typography>Team Activity</Typography>
              </Stack>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="display expandable-table"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th className="dashboard-heading-table">
                            Agent Name
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Status
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Ongoing Interaction
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Presented
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Answered
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Abandoned
                          </th>
                          <th className="text-center dashboard-heading-table">
                            Transferred
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {teamactivitydashboardcount.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className=""
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                {item.agent_id == null ||
                                item.agent_id == undefined ||
                                item.agent_id == ""
                                  ? "--"
                                  : item.agent_id.username}
                              </td>

                              <td className="text-center">
                                {item.agent_id == null ||
                                item.agent_id == undefined ||
                                item.agent_id == "" ? (
                                  "--"
                                ) : item.agent_id.agent_status_real ==
                                    "Available" ||
                                  item.agent_id.agent_status_real == "Ready" ? (
                                  <Badge
                                    color="success"
                                    badgeContent={
                                      item.agent_id.agent_status_real
                                    }
                                    // style={{width:100}}
                                  ></Badge>
                                ) : item.agent_id.agent_status_real ==
                                  "Connected" ? (
                                  <Badge
                                    color="info"
                                    badgeContent={
                                      item.agent_id.agent_status_real
                                    }
                                    // style={{width:100}}
                                  ></Badge>
                                ) : (
                                  <Badge
                                    color="warning"
                                    badgeContent={
                                      item.agent_id.agent_status_real
                                    }
                                    style={{
                                      width: 100,
                                      marginRight: "100px",
                                    }}
                                    sx={{ zIndex: "0" }}
                                  ></Badge>
                                )}
                              </td>

                              <td
                                className="text-center"
                                id="interaction-chat"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  openOngoingInteractionDialogBox(
                                    item.agent_id.id
                                  );
                                }}
                              >
                                <Badge
                                  badgeContent={item.total_active}
                                  color="warning"
                                  sx={{ zIndex: "0" }}
                                >
                                  <QuickreplyIcon color="primary" />
                                </Badge>
                              </td>

                              <td className="text-center">
                                <Badge
                                  badgeContent={item.presented_count}
                                  color="warning"
                                  sx={{ zIndex: "0" }}
                                >
                                  <ForumIcon color="primary" />
                                </Badge>
                              </td>
                              <td className="text-center">
                                <Badge
                                  badgeContent={item.total_active}
                                  color="warning"
                                  sx={{ zIndex: "0" }}
                                >
                                  <PermPhoneMsgIcon color="primary" />
                                </Badge>
                              </td>
                              <td className="text-center">
                                <Badge
                                  badgeContent={item.abadoned_count}
                                  color="warning"
                                  sx={{ zIndex: "0" }}
                                >
                                   <OutboundIcon color="primary" />
                                </Badge>
                              </td>
                              <td className="text-center">
                                <Badge
                                  badgeContent={item.total_transferred_out}
                                  color="warning"
                                  sx={{ zIndex: "0" }}
                                >
                                  <SyncAltIcon  color="primary" />
                                </Badge>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={OngoingInteraction}
        size="md"
        onHide={OngoingInteractionDialogBoxclose}
      >
        <Modal.Header style={{ backgroundColor: "#0b3363", color: "#fff" }}>
          <Modal.Title
            style={{
              fontSize: 15,
              margin: "6px 0 0 0",
              textTransform: "capitalize",
            }}
          >
            Ongoing Interaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-sm table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Customer Name
                  </th>
                  <th scope="col" className="text-center">
                    Arival Time
                  </th>
                  <th scope="col" className="text-center">
                    Channel
                  </th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(OngoingInteractionList) ? (
                  OngoingInteractionList.map((item) => {
                    return (
                      <tr key={item.unique_id.id}>
                        <td className="text-center">
                          {item.unique_id.username == ""
                            ? item.unique_id.phonenumber
                            : item.unique_id.username}
                        </td>
                        <td className="text-center">{item.arrival_at}</td>
                        <td className="text-center">
                        {item.channel == "webchat" ? (     
                          <i className="fa-brands fa-rocketchat text-primary"></i>
                      ) : item.channel == "from_whatsapp" ? (
                          <i className="fa-brands fa-whatsapp text-primary"></i>
                      ) : item.channel == "from_facebook" ? (                     
                          <i className="fa-brands fa-facebook-f text-primary"></i>  
                      ) : item.channel == "from_twitter" ? (              
                          <i className="fa-brands fa-twitter text-primary"></i>            
                      ) : item.channel == "from_teams" ? (               
                          <i className="fa-brands fa-teams text-primary"></i>                       
                      ) : item.channel == "videochat" ? (          
                          <i className="fa-solid fa-video text-primary"></i>          
                      ) : item.channel == "voice" ? (     
                          <i className="fa-solid fa-phone text-primary"></i>                 
                      ) : item.channel == "from_purple" ? (
                          <i className="fa-solid fa-robot"></i>
                      ) : (        
                          <i className="fa-solid fa-envelope text-primary"></i>    
                      )}
                        </td>
                        <td className="text-center">
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <Tooltip title={"Observer"} arrow placement="top">
                              <button
                                type="button"
                                id="agent-transfer"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  SupervisorObserver(item);
                                }}
                                disabled={item.channel == "email"}
                              >
                                <i className="fa-solid fa-code-pull-request"></i>
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            <div>
              <div className="mt-2 mb-3">
                <Pagination
                  color="primary"
                  size="small"
                  style={{ float: "right" }}
                  onChange={InteractionhandleChange}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px" }}></Modal.Footer>
      </Modal>

    </>
    
  );
};

export default connect(mapStateToProps, {
  setsidebarVal,
})(ViewAllAgentDialogBox);
