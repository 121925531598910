import React, { useState, useRef, useEffect } from "react";
import { CSpinner } from "@coreui/react";
import { useSelector } from "react-redux";
import axios from "axios";
import { socket, connectSocket } from "../../../Socket";
import moment from "moment";
import InternetError from "../../InternetError-component/useOnlineStatus ";
import {
  BaseUrl,
  frontendBaseurl,
} from "../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import {
  setcontactlist,
  setcontactlist1,
  setcontactlist2,
  setrefreshtogglechat,
  setconfchatrefresh,
  setAgentList,
  setinternalchatrefresh,
  setchatdata,
  setchatid,
  setselectedmobile,
  setselectedemail,
  setselectedusername,
  setchattype,
  setinternalchatnotify,
  setchatdataforinternal,
  setConferenceNotification,
  setavailagent,
  setloadmore,
  setMessage,
  setExternalChatData,
  updateNotification,
  setChatSpinner,
  setMsgTone,
  setMsgTone2,
  setChatRefresh,
  setUserChatID,
  setloadmoreUser,
  setSelectedchanneldata,
  setUpdatechanneldata,
  endInteraction,
  setIncomingRequestRefresh,
  setShowCard,
  setLoadmoreSpinner,
  setDialedNumberInc,
  setUserlistShow,
  setSupervisorlistrefresh,
  setTeamactivitydashboardcount,
  setShowingStatus,
  setShowingStatusClass,
  setsendfilefromfiles,
  setAgentStatusListInSupervisor,
  setAgentStatusRefreash,
} from "../../../redux/actions/actions";
import ChatInput from "./ChatInput.component";
import MessageComponent from "./Msg.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Bookmark, X } from "@phosphor-icons/react";
import { de } from "date-fns/locale";

const mapStateToProps = (state) => {
  return {
    spaceContent: state.data.spaceContent,
    togglechat: state.data.togglechat,
    refreshtogglechat: state.data.refreshtogglechat,
    chat: state.data.chat,
    chatType: state.data.chatType,
    chatMessage: state.data.chatMessage,
    contactList: state.contactListData.contactList,
    notification: state.data.notification,
    contactList2: state.data.contactList2,
    chatid: state.data.chatid,
    confchatrefresh: state.data.confchatrefresh,
    agentList: state.data.agentList,
    internalchatrefresh: state.data.internalchatrefresh,
    internalchatnotify: state.data.internalchatnotify,
    chatdataforinternal: state.data.chatdataforinternal,
    conferenceNotification: state.data.conferenceNotification,
    conferenceNotificationVal: state.data.conferenceNotificationVal,
    availAgent: state.data.availAgent,
    loadmore: state.data.loadmore,
    addTopicInput: state.data.addTopicInput,
    addTopicToggle: state.data.addTopicToggle,
    message: state.data.message,
    externalChatData: state.data.externalChatData,
    loadmoretoggle: state.data.loadmoretoggle,
    chatspinner: state.data.chatspinner,
    userChatID: state.data.userChatID,
    selectedchanneldata: state.data.selectedchanneldata,
    updatechanneldata: state.data.updatechanneldata,
    loadmoreSpinner: state.data.loadmoreSpinner,
    userlistshow: state.data.userlistshow,
    value: state.data.value,
    supervisorlistrefresh: state.data.supervisorlistrefresh,
    teamactivitydashboardcount: state.data.teamactivitydashboardcount,
    sendfilefromfiles: state.data.sendfilefromfiles,
    sendfilefromfilesType: state.data.sendfilefromfilesType,
    sendfilefromfilesName: state.data.sendfilefromfilesName,
    agentStatusListInSupervisor: state.data.agentStatusListInSupervisor,
    storeTopicValue: state.data.storeTopicValue,
    agentStatusrefreash: state.data.agentStatusrefreash,
    agentStatusListInSupervisor: state.data.agentStatusListInSupervisor,
  };
};

const Message = (props) => {
  const { chat, chatid, userChatID } = props;
  let tenantId = localStorage.getItem("TenantId");
  const contentRef = useRef();
  const { spaceContent } = props;
  const navigate = useNavigate();
  const [currentUserName, setCurrentUserName] = useState("");
  const [currentUserid, setCurrentUserid] = useState("");
  const [chatEmail, setChatEmail] = useState("");
  const [message, setMessage] = useState([]);
  const [loadmoredata, setLoadmoredata] = useState([]);
  const [session_id, setsession_id] = useState("");
  const [sender_id, setsender_id] = useState("");
  const [reciver_id, setreciver_id] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [lastmessage, setLastmessage] = useState([]);
  const [CustomerTyping, setCustomerTyping] = useState(false);
  const [showTagDiv, setShowTagDiv] = useState(false);
  const isOnline = InternetError();
  const handleCloseTagDiv = () => {
    setShowTagDiv(false);
  };
  const handleShowTagDiv = () => setShowTagDiv(props.externalChatData.topic);

  useEffect(() => {
    handleShowTagDiv();
  }, [props.externalChatData.topic]);

  const contactListReduxValue = useSelector(
    (state) => state.contactListData.contactList
  );
  const contactListReduxValueRef = useRef(contactListReduxValue);

  const SelectedChatReduxValue = useSelector((state) => state.data.chat);
  const SelectedChatReduxValueRef = useRef(SelectedChatReduxValue);

  const agentStatusListInSupervisorReduxValue = useSelector(
    (state) => state.data.agentStatusListInSupervisor
  );
  const agentStatusListInSupervisorReduxValueRef = useRef(
    agentStatusListInSupervisorReduxValue
  );

  useEffect(() => {
    contactListReduxValueRef.current = contactListReduxValue;
    SelectedChatReduxValueRef.current = SelectedChatReduxValue;
    agentStatusListInSupervisorReduxValueRef.current =
      agentStatusListInSupervisorReduxValue;
  }, [
    contactListReduxValue,
    SelectedChatReduxValue,
    agentStatusListInSupervisorReduxValue,
  ]);

  const handleTyping = async () => {
    const data = await JSON.parse(localStorage.getItem("tokenAgent"));
    if (chat.chat_type == "internal") {
      let sender_id = chat.senderDetails[0]._id;
      let current_agent_id = data.id;
      if (sender_id == current_agent_id) {
        socket.emit("typing-send-msg", {
          to: chat.id,
          from: data.id,
          senderName: data.username,
          chatType: "outbound",
          chatdetails: chat,
        });
      } else {
        socket.emit("typing-send-msg", {
          to: chat.agent,
          from: data.id,
          senderName: data.username,
          chatType: "inbound",
          chatdetails: chat,
        });
      }
    } else {
      socket.emit("typing-send-msg", {
        to: chat.id,
        from: data.id,
        senderName: data.username,
        chatType: "outbound",
        chatdetails: chat,
      });
    }
  };

  const handleSendMsg = async (msg, msg_type, fileNameForDoc) => {
    try {
      if (chat.chat_type == "internal") {
        var contactlist1 = props.agentList;
        var newcontactlist1 = [];
        var i = 0;
        var find = 0;

        contactlist1.map((client1, index) => {
          if (client1.id == chat.id) {
            find = i;
            client1.lastmessage = msg;
            client1.lastmessagetime = moment().format("h:mm A");
            client1.currentChat = client1.id == msg.from ? true : false;
            client1.unreadcount = 0;
          }
          newcontactlist1.push(client1);
          i++;
        });

        let newagent = insertAndShift(newcontactlist1, find, 0);
        props.setAgentList(newagent);
      } else {
        console.log("handleSendMsg", msg);
        var contactlist = props.contactList;
        var newcontactlist = [];
        var i = 0;
        var find = 0;
        contactlist.map((client, index) => {
          if (client.id == chat.id) {
            find = i;
            client.lastmessage = msg;
            client.lastmessagetime = moment().format("h:mm A");
            client.lastmessageUpdatedat = moment();
            client.currentChat = client.id == msg.from ? true : false;
            client.unreadcount = 0;
          }
          newcontactlist.push(client);
          i++;
        });
        let newexternalcontact = insertAndShift(newcontactlist, find, 0);
        props.setcontactlist(newexternalcontact);
      }

      const data = await JSON.parse(localStorage.getItem("tokenAgent"));
      if (chat.chat_type == "internal") {
        let sender_id = chat.senderDetails[0]._id;
        let current_agent_id = data.id;
        if (sender_id == current_agent_id) {
          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: data.id,
              to: chat.id,
              message: msg,
              senderName: data.username,
              receiverName: chat.username,
              messageFrom: "fromAgent",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: msg_type,
              chatdetails: chat,
              file_name: fileNameForDoc,
              captions: "",
            },
            {
              headers: {
                tenantId: tenantId,
              },
            }
          );

          socket.emit("send-msg", {
            to: chat.id,
            session_id: chat.chat_session_id,
            from: data.id,
            senderName: data.username,
            chatType: "outbound",
            msg,
            msgType: "web",
            userType: chat.chat_type,
            msg_sent_type: msg_type,
            chatdetails: chat,
            file_name: fileNameForDoc,
            captions: "",
          });

          socket.emit("last-msg-send", {
            to: chat.id,
            from: data.id,
            chatType: "outbound",
            msg,
            senderName: data.username,
            userType: chat.chat_type,
            chatdetails: chat,
            file_name: fileNameForDoc,
            captions: "",
          });
        } else {
          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: chat.id,
              to: chat.agent,
              message: msg,
              senderName: data.username,
              receiverName: chat.username,
              messageFrom: "fromClient",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: msg_type,
              chatdetails: chat,
              file_name: fileNameForDoc,
              captions: "",
            },
            {
              headers: {
                tenantId: tenantId,
              },
            }
          );

          socket.emit("send-msg", {
            to: chat.agent,
            session_id: chat.chat_session_id,
            from: chat.id,
            senderName: data.username,
            chatType: "inbound",
            msg,
            msgType: "web",
            userType: chat.chat_type,
            msg_sent_type: msg_type,
            chatdetails: chat,
            file_name: fileNameForDoc,
            captions: "",
          });

          socket.emit("last-msg-send", {
            to: chat.agent,
            from: chat.id,
            chatType: "inbound",
            msg,
            senderName: data.username,
            userType: chat.chat_type,
            chatdetails: chat,
          });
        }

        setCustomerTyping(false);
      } else {
        await axios
          .post(
            BaseUrl + "/message/addMessage",
            {
              from: data.id,
              to: chat.id,
              message: msg,
              senderName: data.username,
              receiverName: chat.username,
              messageFrom: "fromAgent",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: msg_type,
              file_name: fileNameForDoc,
              chatdetails: chat,
              captions: "",
            },
            {
              headers: {
                tenantId: tenantId,
              },
            }
          )
          .then((res) => {})
          .catch((err) => {});

        socket.emit("send-msg", {
          to: chat.id,
          from: data.id,
          session_id: chat.chat_session_id,
          senderName: data.username,
          chatType: "outbound",
          msg,
          msgType: "web",
          userType: chat.chat_type,
          msg_sent_type: msg_type,
          chatdetails: chat,
          file_name: fileNameForDoc,
          captions: "",
          time: moment().format("MMM DD, YYYY h:mm A"),
        });

        socket.emit("last-msg-send", {
          to: chat.id,
          from: data.id,
          chatType: "outbound",
          msg,
          senderName: data.username,
          userType: chat.chat_type,
          chatdetails: chat,
        });
      }

      const msgs = [...message];
      msgs.push({
        fromSelf: true,
        message: msg,
        session_id: chat.chat_session_id,
        senderName: data.username,
        receiverName: chat.username,
        to: chat.id,
        sender: data.id,
        time: moment().format(),
        msg_sent_type: msg_type,
        file_name: fileNameForDoc,
        captions: "",
      });

      setMessage(msgs);
      props.setsendfilefromfiles("");
    } catch (error) {
      toast.error("Internet connection is lost!", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (props.cobrowserdata) {
      let msg = props.cobrowserdata;
      handleSendMsg(msg, "TEXT", "");
    }

    if (props.videoCalldata) {
      let msg = props.videoCalldata;
      handleSendMsg(msg, "TEXT", "");
    }
  }, [props.cobrowserdata, props.videoCalldata]);

  useEffect(() => {
    if (props.sendfilefromfiles) {
      let msg = props.sendfilefromfiles;
      let file = props.sendfilefromfilesType;
      let name = props.sendfilefromfilesName;
      console.log("filetype", props.sendfilefromfilesType);
      handleSendMsg(msg, file, name);
    }
  }, [props.sendfilefromfiles]);

  function insertAndShift(arr, from, to) {
    let cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
    return arr;
  }

  const showLastMsgExternal = (msg) => {
    var contactlist = contactListReduxValueRef.current;
    var newcontactlist = [];
    var newcontactlist1 = [];
    var contactList1 = [];
    var unread_count = 0;
    var i = 0;
    var find = 0;
    contactlist.map((client) => {
      if (client.id == msg.from) {
        find = i;

        if (SelectedChatReduxValueRef.current.id !== client.id) {
          console.log("hello1");
          if (client.unreadcount) {
            unread_count = client.unreadcount + 1;
          } else {
            unread_count = 1;
          }
          console.log("hello2");
          var newNotification = {
            id: msg.from,
            username: msg.senderName,
            client_msg: msg.msg,
            from_id: msg.from,
            chatType: msg.chatType,
          };
          props.updateNotification(newNotification);
        }

        client.lastmessage = msg.msg;
        client.lastmessagetime = moment().format("h:mm A");
        client.lastmessageUpdatedat = moment();
        client.unreadcount = unread_count;
        client.currentChat = client.id == msg.from ? true : false;

        updateLastMes(client.id, msg, moment().format("h:mm A"), unread_count);

        newcontactlist1 = {
          username: msg.senderName,
          client_msg: msg.msg,
          msg_time: moment().format("lll"),
          from_id: msg.from,
        };
        contactList1.push(newcontactlist1);
      }

      if (
        (msg.conference && client.id == msg.conference_id) ||
        (msg.conference && client.id == msg.to)
      ) {
        if (client.unreadcount) {
          unread_count = client.unreadcount + 1;
        } else {
          unread_count = 1;
        }

        client.lastmessage = msg.msg;
        client.lastmessagetime = moment().format("h:mm A");
        client.unreadcount = unread_count;
        client.currentChat = client.id == msg.from ? true : false;

        updateLastMes(client.id, msg, moment().format("h:mm A"), unread_count);

        newcontactlist1 = {
          username: msg.senderName,
          client_msg: msg.msg,
          msg_time: moment().format("lll"),
          from_id: msg.from,
        };
        contactList1.push(newcontactlist1);
      }

      newcontactlist.push(client);
      i++;
    });
    let newupdatedlist = insertAndShift(newcontactlist, find, 0);

    props.setcontactlist(newupdatedlist);
  };

  const showLastMsgInternal = (msg) => {
    let count = 1;
    props.agentList.forEach(function (item, i) {
      if (msg.chatType == "inbound") {
        if (item._id === msg.from) {
          props.agentList.splice(i, 1);
          props.agentList.unshift(item);
        }
      } else {
        if (item.agent === msg.from) {
          props.agentList.splice(i, 1);
          props.agentList.unshift(item);
        }
      }
    });

    count++;
    var contactlist = props.agentList;
    var newcontactlist = [];
    var unread_count = 0;

    props.agentList.map((client, index) => {
      if (msg.chatType == "inbound") {
        if (client.id == msg.from) {
          if (chat.id == msg.from) {
          } else {
            if (client.unreadcount) {
              unread_count = client.unreadcount + 1;
            } else {
              unread_count = 1;
            }
          }

          client.lastmessage = msg.msg;
          client.lastmessagetime = moment().format("h:mm A");
          client.unreadcount = unread_count;
          client.currentChat = client.id == msg.from ? true : false;

          updateLastMes(
            client.id,
            msg,
            moment().format("h:mm A"),
            unread_count
          );
        }

        newcontactlist.push(client);
      } else {
        if (client.agent == msg.from) {
          if (client.unreadcount) {
            unread_count = client.unreadcount + 1;
          } else {
            unread_count = 1;
          }

          client.lastmessage = msg.msg;
          client.lastmessagetime = moment().format("h:mm A");
          client.unreadcount = unread_count;
          client.currentChat = client.id == msg.from ? true : false;

          updateLastMes(
            client.id,
            msg,
            moment().format("h:mm A"),
            unread_count
          );
        }

        newcontactlist.push(client);
      }
    });
    props.setAgentList(newcontactlist);
  };

  const updateLastMes = async (id, msg, date, count) => {
    let data_to_pass = {
      id: id,
      lastMsg: msg.msg,
      time: date,
      count: count,
    };
    const token = localStorage.getItem("access_token");

    const header = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        tenantId: tenantId,
      },
    };
    await axios.post(
      BaseUrl + "/message/updateLastMessage",
      data_to_pass,
      header
    );
  };

  useEffect(() => {
    if (chat && arrivalMessage) {
      if (chat.chat_session_id == arrivalMessage.session_id) {
        arrivalMessage && setMessage((prev) => [...prev, arrivalMessage]);
      }
    }
  }, [arrivalMessage]);

  useEffect(() => {
    if (contentRef.current) {
      const scrollOptions = {
        top: contentRef.current.scrollHeight - contentRef.current.clientHeight,
        behavior: "smooth",
      };
      contentRef.current.scrollTo(scrollOptions);
    }
  }, [message, CustomerTyping]);

  const makeCall = (calledNo) => {
    localStorage.setItem("callActivate", "true");
    let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");
    props.setDialedNumberInc(calledNo);
    axios
      .post(
        AvcUrl +
          `/voice/terminal/${agentLoginTerminalId}/createContact/sip:${agentLoginTerminalId}@demoaccs.com/sip:${calledNo}@${localStorage.getItem(
            "AvayaDomain"
          )}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 500) {
          toast.warn(res.data.status, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else if (res.data.status == "OK") {
        }
      })
      .catch((err) => {});
  };

 

  const addUserToAgentStatusList = (newUser) => {
    const isUserInList = agentStatusListInSupervisorReduxValueRef.current.some(
      (agent) => agent.agent_id.user_id === newUser.user_id
    );

    if (!isUserInList) {
      console.log("Adding new user to the list:", newUser);
      const newUserWithAgentId = {
        ...newUser,
        agent_id: {
          CTIagentLoginTerminalId: newUser.CTIagentLoginTerminalId,
          CTIagentUsername: newUser.CTIagentUsername,
          agent_status_real: newUser.agent_status_real,
          id: newUser.id,
          user_id: newUser.user_id,
          username: newUser.username,
        },
      };
      props.setAgentStatusListInSupervisor([
        ...agentStatusListInSupervisorReduxValueRef.current,
        newUserWithAgentId,
      ]);
    }
  };

  
  const updateAgentStatusInSupervisor = (existingList, updatedUser) => {
    const updatedList = existingList.map((agent) => {
      if (agent.agent_id.user_id === updatedUser.user_id) {
        return {
          ...agent,
          agent_id: {
            ...agent.agent_id,
            agent_status_real: updatedUser.status,
          },
        };
      }
      return agent;
    });

    return updatedList;
  };

  // *SOCKET CONNECTION
  useEffect(() => {
    connectSocket();
    socket.on("connect", async () => {
      console.log("connect");
      const datas = await JSON.parse(localStorage.getItem("tokenAgent"));
      if (SelectedChatReduxValueRef.current.chat_type == "internal") {
        let sender_id = SelectedChatReduxValueRef.current.senderDetails[0]._id;
        let current_agent_id = datas.id;
        if (sender_id == current_agent_id) {
          socket.emit("add-user", datas.id);
          console.log("Socket => Connected=>Internal");
        } else {
          socket.emit("add-user", SelectedChatReduxValueRef.current.id);
          console.log("Socket => Connected=>Internal");
        }
      } else {
        socket.emit("add-user", datas.id);
        console.log("Socket => Connected=>External");
      }
    });
    socket.on("msg-receive", async (msg) => {
      const datas = await JSON.parse(localStorage.getItem("tokenAgent"));
      console.log("msg-receive");
      if (chat.chat_type == "internal") {
        props.setMsgTone2(true);
        setsession_id("");
        setsender_id("");
        setreciver_id("");

        if (msg.chatType == "inbound") {
          var i = 0;
          let setActive = props.agentList.filter((lis) => {
            i = i + 1;
            return lis._id == msg.from;
          });

          if (chat.id == msg.from) {
            props.setinternalchatnotify(true);
            setCustomerTyping(false);
            setArrivalMessage({
              fromSelf: false,
              session_id: msg.session_id,
              message: msg.msg,
              senderName: msg.senderName,
              sender: msg.to,
              receiver: msg.from,
              time: moment().format(),
              msg_sent_type: msg.msg_sent_type,
              file_name: msg.file_name,
              captions: msg.captions,
            });
          }
        } else {
          let setActive = props.agentList.filter((lis) => {
            return lis.agent == msg.from;
          });

          if (msg.from == chat.agent) {
            props.setinternalchatnotify(true);
            setCustomerTyping(false);
            setArrivalMessage({
              fromSelf: false,
              session_id: msg.session_id,
              message: msg.msg,
              senderName: msg.senderName,
              sender: msg.to,
              receiver: msg.from,
              time: moment().format(),
              msg_sent_type: msg.msg_sent_type,
              file_name: msg.file_name,
              captions: msg.captions,
            });
          }
        }
      } else {
        var contactlist = contactListReduxValueRef.current;
        const existingUser = contactlist.find((user) => {
          return user.chat_session_id == msg.session_id;
        });
        if (existingUser) {
          props.setMsgTone2(true);
          setLastmessage(msg);

          if (SelectedChatReduxValueRef.current.id == msg.from) {
            setArrivalMessage({
              fromSelf: false,
              message: msg.msg,
              session_id: msg.session_id,
              senderName: msg.senderName,
              sender: msg.from,
              receiver: msg.to,
              time: moment().format(),
              msg_sent_type: msg.msg_sent_type,
              file_name: msg.file_name,
              captions: msg.captions,
            });
          }

          if (
            msg.conference &&
            SelectedChatReduxValueRef.current.id == msg.to &&
            msg.from !== datas.agent_id
          ) {
            setCustomerTyping(false);
            setArrivalMessage({
              fromSelf: true,
              message: msg.msg,
              session_id: msg.session_id,
              senderName: msg.senderName,
              sender: msg.from,
              receiver: msg.to,
              msg_sent_type: msg.msg_sent_type,
              file_name: msg.file_name,
              captions: msg.captions,
            });
          }
        } else {
          return;
        }
      }
      setCustomerTyping(false);
    });
    socket.on("typing-msg-receive", (msg) => {
      console.log("typing-msg-receive");

      if (msg.chatdetails.chat_type == "internal") {
        setsession_id(msg.chatdetails.chat_session_id);
        setsender_id(msg.chatdetails.senderDetails[0]._id);
        setreciver_id(msg.chatdetails.senderDetails[0]._id);
        setCustomerTyping(true);
      } else {
        var contactlist = contactListReduxValueRef.current;
        const existingUser = contactlist.find((user) => {
          return user.id == msg.from;
        });

        if (existingUser) {
          if (SelectedChatReduxValueRef.current.id == msg.from) {
            setCustomerTyping(true);
          }
        } else {
          return;
        }
      }
    });
    socket.on("update-agent-status-recv", async (data) => {
      const UserExist = await props.agentList.find((user) => {
        if (
          user.reciverDetails[0].user_id == data.user_id ||
          user.senderDetails[0].user_id == data.user_id
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (UserExist) {
        const updatedAgentList = props.agentList.map((client) => {
          if (client.reciverDetails[0].user_id === data.user_id) {
            return {
              ...client,
              reciverDetails: [
                {
                  ...client.reciverDetails[0],
                  agent_status: data.status,
                },
              ],
            };
          } else if (client.senderDetails[0].user_id === data.user_id) {
            return {
              ...client,
              senderDetails: [
                {
                  ...client.senderDetails[0],
                  agent_status: data.status,
                },
              ],
            };
          }
          return client;
        });
        props.setAgentList(updatedAgentList);
      } else {
        return;
      }
    });
    socket.on("customer-end-chat", async (data) => {
      var contactlist = contactListReduxValueRef.current;

      const existingUser = contactlist.find((user) => {
        return user.chat_session_id == data.chat_session_id;
      });

      if (existingUser) {
        let updatedChat = SelectedChatReduxValueRef.current;
        if (
          SelectedChatReduxValueRef.current.chat_session_id ===
          data.chat_session_id
        ) {
          updatedChat.is_customer_disconnected = true;
          props.setchatdata(updatedChat);
        }
        var contactlist = contactListReduxValueRef.current;

        const updatedContactList = contactlist.map((client) => {
          if (client.chat_session_id === data.chat_session_id) {
            return {
              ...client,
              is_customer_disconnected: true,
            };
          }
          return client;
        });
        props.setcontactlist(updatedContactList);
      } else {
        return;
      }
    });
    socket.on("get-new-req", async (newUser) => {
      console.log("get-new-req", newUser);
      const datas = await JSON.parse(localStorage.getItem("tokenAgent"));

      const newReqAgent = [];
      let listrequest = {};

      if (datas.id === newUser.agent) {
        listrequest = newUser;

        if (props.contactList2.length > 0) {
          const updatedIncomingUser = props.contactList2.filter(
            (item) => item.id !== listrequest.id
          );
          newReqAgent.push(updatedIncomingUser);
          props.setcontactlist2(newReqAgent);
          props.setMsgTone(true);
          props.setIncomingRequestRefresh(true);
        } else {
          const updatedIncomingUser = props.contactList2.filter(
            (item) => item.id !== listrequest.id
          );
          newReqAgent.push(updatedIncomingUser);
          props.setcontactlist2(newReqAgent);
          props.setMsgTone(true);
          props.setIncomingRequestRefresh(true);
        }
      }
    });
    socket.on("recv-internal", async (newUser) => {
      let lastElement = newUser.pop();
      var oldReqAgent = props.agentList;
      props.setinternalchatrefresh(true);

      oldReqAgent = oldReqAgent.filter((item) => {
        return item.user_id != lastElement.user_id;
      });
      var listrequest = [];
      props.setAgentList(oldReqAgent);
      if (lastElement) {
        if (datas.id == lastElement.reciverDetails[0]._id) {
          listrequest = lastElement;
          oldReqAgent.push(listrequest);
          props.setchatdata(listrequest);
        }
      }
    });
    socket.on("get-new-req-conf", async () => {
      props.setChatRefresh(true);
    });
    socket.on("email-receive", async (data) => {
      props.setSelectedchanneldata(data.Data);
      props.setUpdatechanneldata(data.EmailBody);
    });
    socket.on("last-msg-receive", async (msg) => {
      if (msg.userType === "internal") {
        showLastMsgInternal(msg);
      } else {
        var contactlist = contactListReduxValueRef.current;
        const existingUser = contactlist.find((user) => {
          return user.id == msg.from;
        });

        if (existingUser) {
          showLastMsgExternal(msg);
        } else {
          return;
        }
      }
    });
    socket.on("logout-agent-receive", async (data) => {
      const agent = JSON.parse(localStorage.getItem("tokenagent"));
      if (agent.user_id == data.user_id) {
        localStorage.removeItem("AvayaUsername");
        localStorage.removeItem("tab");
        localStorage.removeItem("timer_connect_sec");
        localStorage.removeItem("AvayaPassword");
        localStorage.removeItem("AvayaDomain");
        localStorage.removeItem("client");
        localStorage.removeItem("statusValue");
        localStorage.removeItem("emailDisplay");
        localStorage.removeItem("timer_connect_min");
        localStorage.removeItem("tokenAgent");
        localStorage.removeItem("timer_status");
        localStorage.removeItem("NameDisplay");
        localStorage.removeItem("access_token");
        localStorage.removeItem("timer_connect_hour");
        localStorage.removeItem("EnableWEBRTC");
        localStorage.removeItem("ticketcrm");
        if (!localStorage.getItem("TenantId")) {
          window.location.href = "error.html";
        } else {
          const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
            localStorage.getItem("TenantId")
          )}`;
          window.location.href = loginUrl;
        }
        navigate("/");
      }
    });

    socket.on("make-call-recv", async (newcall) => {
      console.log("make-call-recv");
    });

    switch (JSON.parse(localStorage.getItem("tokenAgent")).role) {
      case "Supervisor":
        console.log("Supervisor");
        socket.on("login-user", async (loginUser) => {
          console.log("new-login-user", loginUser);
          const baseRole = JSON.parse(localStorage.getItem("tokenAgent"));
          if (baseRole.role == "Supervisor") {
            addUserToAgentStatusList(loginUser);
          }
        });

        // If agent update their status in superviouser here it will relfect in its dashboard
        socket.on("update-changestatus-agent", async (agentStatusUpdate) => {
          console.log("updateChangestatusAgent", agentStatusUpdate);
          var agentStatusListInSupervisor =
            agentStatusListInSupervisorReduxValueRef.current;
          const updatedList = updateAgentStatusInSupervisor(
            agentStatusListInSupervisor,
            agentStatusUpdate
          );
          props.setAgentStatusListInSupervisor(updatedList);
        });

        // If Agent will logout in superivsor dashboard it needs to update this should reflect in supervisor agent list portal
        socket.on("update-agent-logout", async (updateAgentLogout) => {
          console.log("updateAgentLogout", updateAgentLogout);
          var agentStatusListInSupervisor =
            agentStatusListInSupervisorReduxValueRef.current;
          const updatedAgentList = agentStatusListInSupervisor
            .map((agent) => {
              if (agent.agent_id.user_id === updateAgentLogout.user_id) {
                return {
                  ...agent,
                  agent_id: {
                    ...agent.agent_id,
                    agent_status_real: updateAgentLogout.status,
                  },
                };
              }
              return agent;
            })
            .filter(
              (agent) => agent.agent_id.user_id !== updateAgentLogout.user_id
            );
          props.setAgentStatusListInSupervisor(updatedAgentList);
        });
        break;
      case "Agent":
        console.log("Agent");

        socket.on(
          "update-changestatus-supervisor",
          async (agentStatusSupervisor) => {
            console.log("updateChangestatusSupervisor", agentStatusSupervisor);
            const datas = JSON.parse(localStorage.getItem("tokenAgent"));
            if (datas.user_id == agentStatusSupervisor.user_id) {
              props.setAgentStatusRefreash(true);
            }
          }
        );

        // Supervisor will force logout this should reflect in agent portal
        socket.on("update-force-logout", async (updateForceLogout) => {
          console.log("updateForceLogout", updateForceLogout);
          const agent = JSON.parse(localStorage.getItem("tokenAgent"));
          if (agent.user_id == updateForceLogout.user_id) {
            localStorage.removeItem("AvayaUsername");
            localStorage.removeItem("tab");
            localStorage.removeItem("timer_connect_sec");
            localStorage.removeItem("AvayaPassword");
            localStorage.removeItem("AvayaDomain");
            localStorage.removeItem("client");
            localStorage.removeItem("statusValue");
            localStorage.removeItem("emailDisplay");
            localStorage.removeItem("timer_connect_min");
            localStorage.removeItem("tokenAgent");
            localStorage.removeItem("timer_status");
            localStorage.removeItem("NameDisplay");
            localStorage.removeItem("access_token");
            localStorage.removeItem("timer_connect_hour");
            localStorage.removeItem("EnableWEBRTC");
            localStorage.removeItem("ticketcrm");
            localStorage.removeItem("agentLoginTerminalId");
            localStorage.removeItem("storeTopicValue");
            if (!localStorage.getItem("TenantId")) {
              window.location.href = "error.html";
            } else {
              const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
                localStorage.getItem("TenantId")
              )}`;
              window.location.href = loginUrl;
            }
            navigate("/");
          }
        });

        break;
      default:
        break;
    }

    return () => {
      socket.disconnect();
      socket.off("msg-receive");
      socket.off("typing-msg-receive");
      socket.off("update-agent-status-recv");
      socket.off("customer-end-chat");
      socket.off("recv-internal");
      socket.off("recv-get-new-req-conf");
      socket.off("recv-email-receive");
      socket.off("recv-last-msg-receive");
      socket.off("recv-logout-agent-receive");
      socket.off("make-call-recvl");
    };
  }, [SelectedChatReduxValue]);

  useEffect(() => {
    if (userChatID) {
      async function getData() {
        const datas = await JSON.parse(localStorage.getItem("tokenAgent"));
        setCurrentUserid(datas.id);
        setCurrentUserName(datas.username);
        try {
          const response = await axios.post(
            BaseUrl + "/message/getCurrentChatMessage",
            {
              sessionId: userChatID,
            },
            {
              headers: {
                tenantId: tenantId,
              },
            }
          );

          if (chat.chat_type == "internal") {
            let sender_id = chat.senderDetails[0]._id;
            let current_agent_id = datas.id;
            if (sender_id != current_agent_id) {
              if (response.data.data) {
                setTimeout(() => {
                  props.setChatSpinner(false);
                  setMessage(response.data.data);
                }, 1000);
              } else {
                setTimeout(() => {
                  props.setChatSpinner(false);
                  setMessage([]);
                }, 1000);
              }
            } else {
              if (response.data.data) {
                setTimeout(() => {
                  props.setChatSpinner(false);
                  setMessage(response.data.data);
                }, 1000);
              } else {
                setTimeout(() => {
                  props.setChatSpinner(false);

                  setMessage([]);
                }, 1000);
              }
            }
          } else {
            if (response.data.data) {
              setTimeout(() => {
                props.setChatSpinner(false);
                setMessage(response.data.data);
              }, 1000);
            } else {
              setTimeout(() => {
                props.setChatSpinner(false);
                setMessage([]);
              }, 1000);
            }
          }
        } catch (error) {
          setTimeout(() => {
            props.setChatSpinner(false);
            setMessage([]);
          }, 1000);
        }
      }
      getData();
    }
    props.setUserChatID("");
    setCustomerTyping(false);
  }, [userChatID]);

  const loadmore = async () => {
    props.setLoadmoreSpinner(true);
    if (chat.chat_type == "external") {
      var payload = {
        messager_id: chat.unique_id.id,
        session_id: chat.chat_session_id,
        agent: chat.agent,
        offset: 0,
        limit: 1000,
      };
      var url = "/message/listmessagepagination";
    } else if (chat.chat_type == "internal") {
      var payload = {
        sender_id: chat.senderDetails[0]._id,
        receiver_id: chat.reciverDetails[0]._id,
        session_id: chat.chat_session_id,
        offset: 0,
        limit: 1000,
      };
      var url = "/message/listInternalMessage";
    }

    const token = localStorage.getItem("access_token");

    const header = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        tenantId: tenantId,
      },
    };

    const { data } = await axios.post(BaseUrl + url, payload, header);

    if (data.status == true) {
      setLoadmoredata(data.data);
      props.setloadmore(true);
      props.setrefreshtogglechat(false);
      setTimeout(() => {
        props.setLoadmoreSpinner(false);
      }, 1000);
    } else {
      setLoadmoredata([]);
      props.setloadmore(true);
      props.setrefreshtogglechat(false);
      setTimeout(() => {
        props.setLoadmoreSpinner(false);
      }, 1000);
    }
  };


  return (
    <>
      <div className="chat-boxs p-2">
        {showTagDiv && (
          <div
            className={
              props.userlistshow ? "col-md-12 tag_div" : "col-md-12 tag_div"
            }
          >
            <div className="ms-1">
              <span>
                <Bookmark size={20} />
              </span>
            </div>
            <div className="ms-1">
              <span>{props.externalChatData.topic}</span>
              <span></span>
            </div>
            <div
              onClick={handleCloseTagDiv}
              className="close-add-Topic ms-auto"
            >
              <span>
                <X size={18} />
              </span>
            </div>
          </div>
        )}
        <div className="chat-box-body">
          <div className="chat-logs">
            <div className="messagess" id="chat" ref={contentRef}>
              {props.chatspinner && (
                <>
                  <div
                    className="d-flex justify-content-center position-absolute h-100"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "15px",
                      color: "#0b3363",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      bottom: "0",
                      fontFamily: "monospace",
                      color: "#0b3363",
                    }}
                  >
                    LOADING...
                  </span>
        
              </>
            )}

            {!props.chatspinner && (
              <>
                {" "}
                {chat.chat_type == "external" &&
                !props.loadmoretoggle &&
                props.contactList.length > 0 ? (
                  <div>
                    <div
                      className="agentSideLoad "
                      id="loadmore-chat"
                      onClick={() => {
                        loadmore();
                      }}
                      style={{
                        marginLeft: props.userlistshow ? "-3rem" : "8rem",
                      }}
                    >
                      <span>
                        {props.loadmoreSpinner ? (
                          <CSpinner
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "15px",
                              color: "#0b3363",
                            }}
                          />
                        ) : (
                          "load more"
                        )}
                      </span>
                    </div>
                  </div>
                ) : chat.chat_type == "internal" && !props.loadmoretoggle ? (
                  <div
                    className="agentSideLoad "
                    id="loadmore-chat"
                    onClick={() => {
                      loadmore();
                    }}
                    style={{
                      marginLeft: props.loadmoreUser ? "-3rem" : "8rem",
                    }}
                  >
                    <span>
                      {props.loadmoreSpinner ? (
                        <CSpinner
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "15px",
                            color: "#0b3363",
                          }}
                        />
                      ) : (
                        "load more"
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {props.loadmoretoggle &&
                  loadmoredata.map((item, index) => (
                    <MessageComponent key={index} message={item} />
                  ))}
                {!isOnline ? (
                  message.map((item, index) => (
                    <MessageComponent key={index} message={item} />
                  ))
                ) : (
                  <div>
                    {message.map((item, index) => (
                      <MessageComponent
                        key={index}
                        message={item}
                        index={index}
                      />
                    ))}
                  </div>
                )}
                {chat &&
                session_id == chat.chat_session_id &&
                chat.chat_type == "internal" &&
                sender_id == chat.senderDetails[0]._id &&
                sender_id != "" &&
                CustomerTyping ? (
                  <div className="chat-l">
                    <div className="bot-chat-main d-flex justify-content-start">
                      <div className="d-flex justify-content-start flex-column align-items-start">
                        <div className="bot-chats text-break">
                          <div className="typing-main">
                            <div className="typing typing-1"></div>
                            <div className="typing typing-2"></div>
                            <div className="typing typing-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {chat && chat.chat_type != "internal" && CustomerTyping ? (
                  <div className="chat-l">
                    <div className="bot-chat-main d-flex justify-content-start">
                      <div className="d-flex justify-content-start flex-column align-items-start">
                        <div className="bot-chats text-break">
                          <div className="typing-main">
                            <div className="typing typing-1"></div>
                            <div className="typing typing-2"></div>
                            <div className="typing typing-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </div>

          {props.value == 1 ? (
            <ChatInput
              handleTyping={handleTyping}
              handleSendMsg={handleSendMsg}
              data={props}
              disabled={props.externalChatData.is_customer_disconnected}
            />
          ) : props.externalChatData.is_customer_disconnected == true ||
            props.hideInput == true ? (
            <></>
          ) : (
            <ChatInput
              handleTyping={handleTyping}
              handleSendMsg={handleSendMsg}
              data={props}
              disabled={props.externalChatData.is_customer_disconnected}
            />
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist,
  setcontactlist1,
  setcontactlist2,
  setconfchatrefresh,
  setAgentList,
  setinternalchatrefresh,
  setchatdata,
  setchatid,
  setrefreshtogglechat,
  setselectedmobile,
  setselectedemail,
  setselectedusername,
  setchattype,
  setinternalchatnotify,
  setchatdataforinternal,
  setConferenceNotification,
  setavailagent,
  setloadmore,
  setMessage,
  setExternalChatData,
  updateNotification,
  setChatSpinner,
  setMsgTone,
  setMsgTone2,
  setChatRefresh,
  setUserChatID,
  setloadmoreUser,
  setSelectedchanneldata,
  setUpdatechanneldata,
  endInteraction,
  setIncomingRequestRefresh,
  setShowCard,
  setLoadmoreSpinner,
  setDialedNumberInc,
  setUserlistShow,
  setSupervisorlistrefresh,
  setTeamactivitydashboardcount,
  setShowingStatus,
  setShowingStatusClass,
  setsendfilefromfiles,
  setAgentStatusListInSupervisor,
  setAgentStatusRefreash,
})(Message);
