import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { setselectedemail } from "../../../../redux/actions/actions";
import { connect } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineCallMerge } from "react-icons/md";
import { motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import { ArrowsLeftRight, ArrowsMerge } from "@phosphor-icons/react";
const mapStateToProps = (state) => {
  return {
    chat: state.data.chat,
    selectedemail: state.data.selectedemail,
  };
};

const Sessiontab = (props) => {
  const [userchathistory, setUserchathistory] = useState([]);

  const getChatHistoryDetails = async () => {
    try {
      let session_id = props.chat.chat_session_id;
      const sessions = await axios.post(
        BaseUrl + "/users/listchatHistory",
        {
          session_id: session_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );
      if (sessions.data.status) {
        setUserchathistory(sessions.data.data);
      } else {
        setUserchathistory([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (props.selectedemail) {
      getChatHistoryDetails();
    }
  }, [props.selectedemail]);

  return (
    <>
      <motion.div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="m-2 file-main">
          <div className="chat-r-file">
            <div className="tab_content_userinfo">
              {userchathistory.length > 0 ? (
                userchathistory.map((item) => {
                  return (
                    <div
                      className="row m-2 border p-2 justify-content-center align-items-center bg-white"
                      style={{ borderRadius: "8px" }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        {item.type == "Transfer" ? (
                          <ArrowsLeftRight size={30} cursor={"pointer"} />
                        ) : (
                          <ArrowsMerge size={30} cursor={"pointer"} />
                        )}
                        <Stack sx={{ flex: 1 }}>
                          <Typography variant="subtitle2">
                            {item.type == "Transfer" ? (
                              <>
                                Interaction Transferred From {item.agent} to{" "}
                                {item.destinationAgent}
                              </>
                            ) : (
                              <>
                                {item.agent} conferenced with{" "}
                                {item.destinationAgent}
                              </>
                            )}
                          </Typography>
                          <Typography variant="caption">
                            {moment(item.time).format("LLL")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <div
                    className="text-muted d-flex"
                    style={{
                      flexDirection: "column",
                      marginTop: "20%",
                      width: "400px",
                      height: "100px",
                    }}
                  >
                    <span className="mx-auto">
                      <RiErrorWarningLine size={30} />
                    </span>
                    <span className="mx-auto">
                      <h6
                        className="text-muted mt-2"
                        style={{ fontFamily: "poppins" }}
                      >
                        No Session History Found
                      </h6>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedemail,
})(Sessiontab);
