import React from "react";

const SupervisorContactfilter = (props) => {
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const isToDateValid = () => {
    return new Date(props.todate) >= new Date(props.fromdate);
  };

  return (
    <>
      <nav style={{ top: "59px" }} className={props.showSupervisorfilter}>
        <div className="filter col-md-12 shadow p-4 ">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">Filters</p>
            <div className="dismiss">
              <span
                id="session_filter_close_btn"
                className="material-symbols-outlined"
                onClick={() => {
                  props.setShowSupervisorfilter("sidebar_filter");
                }}
              >
                cancel
              </span>
            </div>
          </div>

          <div className="filter_form">
            <form className=" fillter_form2 " style={{ height: "68vh" }}>
              <div className="custom_div">
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="fromdate" className="form-label">
                    From
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    aria-describedby="date"
                    value={props.fromdate}
                    onChange={(e) => props.setFromdate(e.target.value)}
                    max={defaultValue}
                  />
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="todate" className="form-label">
                    To
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    aria-describedby="date"
                    value={props.todate}
                    onChange={(e) => props.setTodate(e.target.value)}
                    max={defaultValue}
                  />
                  {!isToDateValid() && (
                    <span
                      className="text-danger fw-bold"
                      style={{ fontSize: "10px" }}
                    >
                      To Date cannot be less than From Date*
                    </span>
                  )}
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="mobilenumber" className="form-label">
                    Enter Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id=""
                    aria-describedby="number"
                    value={props.mobilenumber}
                    onChange={(e) => props.setmobilenumber(e.target.value)}
                  />
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="todate" className="form-label">
                    Select Agent
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => props.setAgentid(e.target.value)}
                  >
                    <option value="">Select Agent Name</option>
                    {props.SupervisorAgentName.length > 0 &&
                      props.SupervisorAgentName.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.username}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </form>
            <div className="clearbutton d-flex justify-content-end align-items-end">
              <button type="button" className="btn btn-outlined" id="session-filter-clear-btn" onClick={() => {
                props.clearFilter();
              }}>
                <span>clear</span>
              </button>
            </div>

            <div className="filter_submit">
              <div className="d-flex">
                <div className="col-md-12 ps-2">
                  <button
                    type="button"
                    id="session_filter_Submit"
                    className="btn btn-primary w-100 text-white"
                    onClick={() => {
                      props.setShowSupervisorfilter("sidebar_filter");
                      props.fetchData();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SupervisorContactfilter;
