import React, { useEffect, useState, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import { useNavigate } from "react-router-dom";
import "./callmoduleassets/styles/dialer2.css";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import { configuration } from "./web";
import SockJS from "sockjs-client";
import { motion, useDragControls } from "framer-motion";
import Stomp from "stompjs";
import axios from "axios";
import voiceIcon from "./callmoduleassets/images/voice.png";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import soundfile from "./callmoduleassets/ringsound/telephone_ring.mp3";
import "./callmoduleassets/AWL-min/awl.min.js";
import { connect } from "react-redux";
import {
  setSkillSet,
  setDialedNumberInc,
  setagentDialedNumber,
  setshowphonedialer,
  setShowphonedialer2,
  setShowphonedialer3,
  setshowIncommingscreen,
  setcallscreen,
  setshowIncommingNxtscreen,
  setcalledagentscreen,
  setconfrencescreen,
  setShowMute,
  setShowUnMute,
  setAgentShowMute,
  setAgentShowUnMute,
  setShowHold,
  setShowUnHold,
  setagentCallHold,
  setagentCallUnHold,
  setTransferCall,
  setmakingTransferCall,
  setConfrence,
  setMergeCall,
  setcontactId,
  setconsultedContactId,
  setCallId,
  setAcceptChatActive,
  setExternalChatData,
  setVoiceSessionId,
  setvoiceseconds,
  setvoiceminutes,
  setvoicehours,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setChatRefresh,
  setShowVoiceCall,
  setcontactlist,
  setShowingStatusClass,
  setShowingStatus,
} from "../../redux/actions/actions";
import EndChatModel from "../chat-components/Chat-component/chat-component-model/End-chat-model.js";
import EndChatModelForPopCall from "../chat-components/Chat-component/chat-component-model/End-chat-model-for-popCall.jsx";

const mapStateToProps = (state) => {
  return {
    skillset: state.data.skillset,
    dailedNumberInc: state.data.dailedNumberInc,
    agentDialedNumber: state.data.agentDialedNumber,
    showphonedialer: state.data.showphonedialer,
    showphonedialer2: state.data.showphonedialer2,
    showphonedialer3: state.data.showphonedialer3,
    showIncommingscreen: state.data.showIncommingscreen,
    callscreen: state.data.callscreen,
    showIncommingNxtscreen: state.data.showIncommingNxtscreen,
    calledagentscreen: state.data.calledagentscreen,
    confrencescreen: state.data.confrencescreen,
    showMute: state.data.showMute,
    showUnMute: state.data.showUnMute,
    agentshowMute: state.data.agentshowMute,
    agentshowUnMute: state.data.agentshowUnMute,
    showHold: state.data.showHold,
    showUnHold: state.data.showUnHold,
    agentCallHold: state.data.agentCallHold,
    agentCallUnHold: state.data.agentCallUnHold,
    transferCall: state.data.transferCall,
    makingTransferCall: state.data.makingTransferCall,
    confrence: state.data.confrence,
    mergecall: state.data.mergecall,
    contactId: state.data.contactId,
    consultedContactId: state.data.consultedContactId,
    callId: state.data.callId,
    showStatusChannel: state.data.showStatusChannel,
    voiceseconds: state.data.voiceseconds,
    voiceminutes: state.data.voiceminutes,
    voicehours: state.data.voicehours,
    showIncommingscreen: state.data.showIncommingscreen,
    skillset: state.data.skillset,
    chat: state.data.chat,
    voiceseconds: state.data.voiceseconds,
    voiceminutes: state.data.voiceminutes,
    voicehours: state.data.voicehours,
    showingStatus: state.data.showingStatus,
    showingStatusClass: state.data.showingStatusClass,
  };
};

const Sdk = (props) => {
  const {
    skillset,
    dailedNumberInc,
    agentDialedNumber,
    showphonedialer,
    showIncommingscreen,
    showphonedialer2,
    showphonedialer3,
    callscreen,
    showIncommingNxtscreen,
    calledagentscreen,
    confrencescreen,
    showMute,
    showUnMute,
    showHold,
    showUnHold,
    agentCallHold,
    agentCallUnHold,
    transferCall,
    makingTransferCall,
    confrence,
    mergecall,
    contactId,
    consultedContactId,
    callId,
    voiceseconds,
    voiceminutes,
    voicehours,

    setSkillSet,
    setDialedNumberInc,
    setshowphonedialer,
    setShowphonedialer2,
    setShowphonedialer3,
    setshowIncommingscreen,
    setcallscreen,
    setshowIncommingNxtscreen,
    setcalledagentscreen,
    setconfrencescreen,
    setShowMute,
    setShowUnMute,
    setShowHold,
    setShowUnHold,
    setagentCallHold,
    setagentCallUnHold,
    setTransferCall,
    setmakingTransferCall,
    setConfrence,
    setMergeCall,
    setcontactId,
    setconsultedContactId,
    setCallId,
    setShowVoiceCall,
  } = props;

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  // 10 minutes timer

  props.setvoiceseconds(seconds);
  props.setvoiceminutes(minutes);
  props.setvoicehours(hours);

  localStorage.setItem("callduration", `${hours}:${minutes}:${seconds}`);
  const controls = useDragControls();
  const [spinner, setSpinner] = useState(false);
  const [dailedNumber, setDialedNumber] = useState("");
  const AvcUrl = localStorage.getItem("AvcUrl");
  const [externalContactId, setexternalContactId] = useState("");
  const [calledDeviceID, setcalledDeviceID] = useState("");
  const [callingAgentName, setcallingAgentName] = useState("");
  const avayaplatform = "AACC";
  const navigate = useNavigate();
  let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");
  const [endchatModalDialog, setendchatModalDialog] = useState(false);

  const myRef = useRef();

  const startAudio = () => {
    myRef.current.play();
  };
  const pauseAudio = () => {
    myRef.current.pause();
  };

  useEffect(() => {
    initiateWebSocket();
    if (localStorage.getItem("EnableWEBRTC")) {
      configuration();
    }
  }, []);

  var stompClient;
  const initiateWebSocket = () => {
    let ssToken = localStorage.getItem("ssoToken");
    const customHeaders = {
      terminal: ssToken, //ssoToken
    };

    let socket = new SockJS(AvcUrl + "/ws");
    stompClient = Stomp.over(socket);

    stompClient.connect(customHeaders, onConnected, onError);
  };
  var i = 0;
  const onConnected = () => {
    stompClient.subscribe("/user/terminal/messages", (payload) => {
      const responseData = JSON.parse(payload.body);

      if (responseData.event == "RINGING") {
        console.log("RINGING", responseData);
        if (responseData.callType == "Inbound") {
          localStorage.setItem("callActivate", "true");
          startAudio();
          setshowphonedialer(false);
          localStorage.setItem("callAttend", "false");
          setDialedNumberInc(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          setSkillSet(responseData.skillSet);
          CustomerNameApi(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          localStorage.setItem("contactId", responseData.contactId);
          setcontactId(responseData.contactId);
          setshowIncommingscreen(true);
          setshowphonedialer(false);
          setexternalContactId(responseData.externalContactId);
          setcalledDeviceID(responseData.calledDeviceID);
          setcallingAgentName(responseData.callingDeviceID);
        } else if (responseData.callType == "Outbound") {
          localStorage.setItem("callActivate", "true");
          startAudio();
          setshowphonedialer(false);
          localStorage.setItem("callType", responseData.callType);
          localStorage.setItem("callAttend", "false");
          setDialedNumberInc(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          CustomerNameApi(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          setcontactId(responseData.contactId);
          localStorage.setItem("contactId", responseData.contactId);
          setSkillSet(responseData.skillSet);
          setshowIncommingscreen(true);
          setshowphonedialer(false);
          // startAudio();
          setexternalContactId(responseData.externalContactId);
          setcalledDeviceID(responseData.calledDeviceID);
          setcallingAgentName(responseData.callingDeviceID);
        } else if (responseData.callType == "ConsultConference") {
          localStorage.setItem("callActivate", "true");
          startAudio();
          localStorage.setItem("callAttend", "false");
          setshowphonedialer(false);
          setDialedNumberInc(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          CustomerNameApi(
            responseData.callingDeviceID != null
              ? responseData.callingDeviceID
              : responseData.callerId
          );
          setcontactId(responseData.contactId);
          localStorage.setItem("agenttransConf", true);
          localStorage.setItem("contactId", responseData.contactId);
          localStorage.setItem("transfercontactId", responseData.contactId);
          localStorage.setItem("mainContactId", responseData.mainContactId);
          setSkillSet(responseData.skillSet);
          setshowIncommingscreen(true);
          setshowphonedialer(false);
          // startAudio();

          // this is value will be pass in create session api calls
          setexternalContactId(responseData.externalContactId);
          setcalledDeviceID(responseData.calledDeviceID);
          setcallingAgentName(responseData.callingDeviceID);
        }
      }
      if (responseData.event == "ACTIVE") {
        if (responseData.callType == "Inbound") {
          console.log("responseData", responseData);
          if (responseData.reason == "CONFERENCE_COMPLETE") {
            setshowIncommingscreen(false);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setcalledagentscreen(false);
            setconfrencescreen(false);
            setDialedNumberInc(responseData.callerId);
            tranferConferanceUpdate(responseData.callerId);
          } else if (responseData.reason == "TRANSFER_COMPLETE") {
            setshowIncommingscreen(false);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setcalledagentscreen(false);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setDialedNumberInc(responseData.callerId);
            tranferConferanceUpdate(responseData.callerId);
          } else {
            if (responseData.uuiData == "Transfer") {
              if (localStorage.getItem("agentcallType") == "transfer") {
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setcalledagentscreen(true);
                setShowHold(true);
                setShowUnHold(false);
                setTransferCall(false);
                setmakingTransferCall(true);
                setConfrence(false);
                setMergeCall(false);
              } else if (
                localStorage.getItem("agentcallType") == "conferance"
              ) {
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setcalledagentscreen(true);
                setShowHold(true);
                setShowUnHold(false);
                setTransferCall(false);
                setmakingTransferCall(false);
                setConfrence(false);
                setMergeCall(true);
              } else {
                // setDialedNumberInc(responseData.callingDeviceID)
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setShowMute(true);
                setShowUnMute(false);
                setShowHold(true);
                setShowUnHold(false);
                setagentCallUnHold(false);
                setTransferCall(true);
                setmakingTransferCall(false);
                setConfrence(true);
                setMergeCall(false);
                setcalledagentscreen(false);
                setconfrencescreen(false);
              }
            } else {
              setSpinner(false);
              setShowVoiceCall(true);
              setshowphonedialer(false);
              setcontactId(responseData.contactId);
              localStorage.setItem("contactId", responseData.contactId);
              setshowIncommingscreen(false);
              setcallscreen(true);
              setshowIncommingNxtscreen(true);
              setShowMute(true);
              setShowUnMute(false);
              setShowHold(true);
              setShowUnHold(false);
              setagentCallUnHold(false);
              setTransferCall(true);
              setmakingTransferCall(false);
              setConfrence(true);
              setMergeCall(false);
              setcalledagentscreen(false);
              setconfrencescreen(false);
              if (localStorage.getItem("callActivate") == "true") {
                props.updateStatus("InCall"); //dcc api
                props.setShowingStatus("InCall");
                props.setShowingStatusClass("showStatusRed");
                localStorage.setItem("statusValue", "InCall");
                createVoiceSession(responseData);
                localStorage.setItem("callAttend", "true");
                localStorage.setItem("callActivate", "false");
                pauseAudio();
                reset();
                start();
                CallArrivaltime();
              }
            }
          }
        } else if (responseData.callType == "Outbound") {
          if (localStorage.getItem("callpresent") == "true") {
            localStorage.setItem("callActivate", "true");
          }
          console.log("responseData", responseData);
          if (responseData.reason == "CONFERENCE_COMPLETE") {
            setshowIncommingscreen(false);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setcalledagentscreen(false);
            setconfrencescreen(false);
            setDialedNumberInc(responseData.callerId);
            tranferConferanceUpdate(responseData.callerId);
          } else if (responseData.reason == "TRANSFER_COMPLETE") {
            setshowIncommingscreen(false);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setcalledagentscreen(false);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setDialedNumberInc(responseData.callerId);
            tranferConferanceUpdate(responseData.callerId);
          } else {
            if (responseData.uuiData == "Transfer") {
              if (localStorage.getItem("agentcallType") == "transfer") {
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setcalledagentscreen(true);
                setShowHold(true);
                setShowUnHold(false);
                setTransferCall(false);
                setmakingTransferCall(true);
                setConfrence(false);
                setMergeCall(false);
              } else if (
                localStorage.getItem("agentcallType") == "conferance"
              ) {
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setcalledagentscreen(true);
                setShowHold(true);
                setShowUnHold(false);
                setTransferCall(false);
                setmakingTransferCall(false);
                setConfrence(false);
                setMergeCall(true);
              } else {
                // setDialedNumberInc(responseData.callingDeviceID)
                setcallscreen(true);
                setshowIncommingNxtscreen(true);
                setShowMute(true);
                setShowUnMute(false);
                setShowHold(true);
                setShowUnHold(false);
                setagentCallUnHold(false);
                setTransferCall(true);
                setmakingTransferCall(false);
                setConfrence(true);
                setMergeCall(false);
                setcalledagentscreen(false);
                setconfrencescreen(false);
              }
            } else {
              setSpinner(false);
              setShowVoiceCall(true);
              setshowphonedialer(false);
              setcontactId(responseData.contactId);
              setSkillSet(responseData.skillSet);
              localStorage.setItem("contactId", responseData.contactId);
              setshowIncommingscreen(false);
              setcallscreen(true);
              setshowIncommingNxtscreen(true);
              setShowMute(true);
              setShowUnMute(false);
              setShowHold(true);
              setShowUnHold(false);
              setagentCallUnHold(false);
              setTransferCall(true);
              setmakingTransferCall(false);
              setConfrence(true);
              setMergeCall(false);
              setcalledagentscreen(false);
              setconfrencescreen(false);
              setDialedNumberInc(
                localStorage.getItem("agentLoginTerminalId") ==
                  responseData.calledDeviceID
                  ? responseData.callingDeviceID
                  : responseData.calledDeviceID
              );

              if (localStorage.getItem("callActivate") == "true") {
                props.updateStatus("InCall"); //dcc api
                props.setShowingStatus("InCall");
                props.setShowingStatusClass("showStatusRed");
                localStorage.setItem("statusValue", "InCall");
                localStorage.setItem("callAttend", "true");
                localStorage.setItem("callActivate", "false");
                localStorage.setItem("callpresent", "false");
                pauseAudio();
                createVoiceSession(responseData);
                reset();
                start();
                CallArrivaltime();
              }
            }
          }
        } else if (responseData.callType == "ConsultConference") {
          if (localStorage.getItem("agenttransConf")) {
            setSpinner(false);
            setshowIncommingscreen(false);
            setShowVoiceCall(true);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setcalledagentscreen(false);
            setconfrencescreen(false);
            if (localStorage.getItem("callAttend") == "false") {
              props.updateStatus("InCall"); //dcc api
              props.setShowingStatus("InCall");
              props.setShowingStatusClass("showStatusRed");
              localStorage.setItem("statusValue", "InCall");
              localStorage.setItem("callAttend", "true");
              createVoiceSession(responseData);
              pauseAudio();
              reset();
              start();
            }
          } else {
            if (localStorage.getItem("agentcallType") == "transfer") {
              localStorage.setItem("agentcontactid", responseData.contactId);
              setconsultedContactId(responseData.contactId);
              setShowphonedialer2(false);
              setShowphonedialer3(false);
              setcallscreen(true);
              setshowIncommingNxtscreen(true);
              setcalledagentscreen(true);
              setShowMute(true);
              setShowUnMute(false);
              setShowHold(false);
              setShowUnHold(true);
              setagentCallHold(true);
              setagentCallUnHold(false);
              setTransferCall(false);
              setmakingTransferCall(true);
              setConfrence(false);
              setMergeCall(false);
            } else {
              localStorage.setItem("agentcontactid", responseData.contactId);
              setconsultedContactId(responseData.contactId);
              setShowphonedialer2(false);
              setShowphonedialer3(false);
              setcallscreen(true);
              setshowIncommingNxtscreen(true);
              setcalledagentscreen(true);
              setShowMute(true);
              setShowUnMute(false);
              setShowHold(false);
              setShowUnHold(true);
              setagentCallHold(true);
              setagentCallUnHold(false);
              setTransferCall(false);
              setmakingTransferCall(false);
              setConfrence(false);
              setMergeCall(true);
            }
          }
        }
      }
      if (responseData.event == "HELD") {
        if (responseData.callType == "Inbound") {
          setDialedNumberInc(responseData.callingDeviceID);
          localStorage.getItem("contactId");
          setcontactId(responseData.contactId);
          setcallscreen(true);
          setshowIncommingNxtscreen(true);
          setShowHold(false);
          setShowUnHold(true);
          setagentCallHold(true);
          setagentCallUnHold(false);
        } else if (responseData.callType == "Outbound") {
          if (localStorage.getItem("contactId") == responseData.contactId) {
            setShowHold(false);
            setShowUnHold(true);
          }
        } else if (responseData.callType == "ConsultConference") {
          setShowHold(false);
          setShowUnHold(true);
          setagentCallHold(false);
          setagentCallUnHold(true);
        }
      }
      if (responseData.event == "DROPPED") {
        console.log("DROPPED", responseData);
        pauseAudio();
        setshowIncommingscreen(false);
        if (
          responseData.reason == "DEFAULT" ||
          responseData.reason == "OBSERVE"
        ) {
          if (responseData.contactId == localStorage.getItem("contactId")) {
            localStorage.removeItem("contactId");
            localStorage.removeItem("callType");
            localStorage.removeItem("agenttransConf");
            localStorage.removeItem("transfercontactId");
            localStorage.removeItem("ClickToCall");
            localStorage.removeItem("ClickeToCallPhoneNum");
            localStorage.setItem("callpresent", "true");
            setcontactId("");
            setshowIncommingscreen(false);
            setShowphonedialer2(false);
            setShowphonedialer3(false);
            setcallscreen(false);
            setshowIncommingNxtscreen(false);
            setconfrencescreen(false);
            setShowMute(false);
            setShowUnMute(false);
            setShowHold(false);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(false);
            setmakingTransferCall(false);
            setConfrence(false);
            setMergeCall(false);
            pause();
            CallEndedtime();
            setShowVoiceCall(false);
            // endInteraction model will show
            if (localStorage.getItem("callAttend") == "true") {
              setendchatModalDialog(true);
            }

            props.updateStatus("ACW"); //dcc api
            localStorage.setItem("statusValue", "ACW");
            props.setShowingStatus("ACW");
            props.setShowingStatusClass("showStatusRed");
            props.changeStatusnew("Not Ready"); //voice api

            if (localStorage.getItem("callAttend") == "false") {
              props.updateStatus("ACW"); //dcc api
              props.setShowingStatus("ACW");
              localStorage.setItem("statusValue", "ACW");
              props.setShowingStatusClass("showStatusRed");
              props.changeStatusnew("Not Ready"); //voice api
              localStorage.removeItem("callAttend");
              localStorage.removeItem("callActivate");
              createVoiceSessionForNotAnsCall(responseData);
            }
          } else if (
            responseData.contactId == localStorage.getItem("agentcontactid")
          ) {
            localStorage.removeItem("agentcontactid");
            localStorage.removeItem("agentcallType");
            setshowIncommingscreen(false);
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setcalledagentscreen(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setShowphonedialer2(false);
            setShowphonedialer3(false);
          }
        } else if (responseData.reason == "TRANSFER_COMPLETE") {
          if (localStorage.getItem("agenttransConf")) {
            // incoming tranfer call

            localStorage.removeItem("contactId");
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setcalledagentscreen(false);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);
            setcontactId(localStorage.getItem("mainContactId"));
            localStorage.setItem(
              "contactId",
              localStorage.getItem("mainContactId")
            );
            setShowphonedialer2(false);
            setShowphonedialer3(false);
            localStorage.removeItem("mainContactId");
          } else {
            localStorage.removeItem("contactId");
            localStorage.removeItem("callType");
            localStorage.removeItem("mainContactId");
            localStorage.removeItem("agentcontactid");
            localStorage.removeItem("agenttransConf");
            localStorage.removeItem("transfercontactId");
            localStorage.removeItem("agentcallType");
            setcontactId("");
            setshowIncommingscreen(false);
            setShowphonedialer2(false);
            setShowphonedialer3(false);
            setcallscreen(false);
            setshowIncommingNxtscreen(false);
            setconfrencescreen(false);
            setShowMute(false);
            setShowUnMute(false);
            setShowHold(false);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(false);
            setmakingTransferCall(false);
            setConfrence(false);
            setMergeCall(false);
            pause();
            setShowVoiceCall(false);
            // endInteraction model will show
            setendchatModalDialog(true);
            props.setShowingStatus("Not Ready");
            props.setShowingStatusClass("showStatusRed");
            localStorage.setItem("statusValue", "Not Ready");
            props.updateStatus("Not Ready"); //dcc api
            props.changeStatusnew("Not Ready"); //voice api
          }
        } else if (responseData.reason == "CONFERENCE_COMPLETE") {
          if (localStorage.getItem("agenttransConf")) {
            localStorage.removeItem("contactId");
            setcallscreen(true);
            setshowIncommingNxtscreen(true);
            setcalledagentscreen(false);
            setShowMute(true);
            setShowUnMute(false);
            setShowHold(true);
            setShowUnHold(false);
            setagentCallUnHold(false);
            setTransferCall(true);
            setmakingTransferCall(false);
            setConfrence(true);
            setMergeCall(false);

            setcontactId(localStorage.getItem("mainContactId"));
            localStorage.setItem(
              "contactId",
              localStorage.getItem("mainContactId")
            );
            setShowphonedialer2(false);
            setShowphonedialer3(false);
          } else {
            localStorage.setItem("InConferance", true);

            localStorage.removeItem("agentcallType");
            localStorage.removeItem("agentcontactid");
            setconfrencescreen(true);
            setshowIncommingNxtscreen(false);
            setcalledagentscreen(false);
            setShowHold(false);
            setShowUnHold(false);
            setTransferCall(false);
            setmakingTransferCall(false);
            setConfrence(false);
            setMergeCall(false);
            setShowphonedialer2(false);
            setShowphonedialer3(false);
          }
        }
      }
    });
  };

  const CallArrivaltime = () => {
    var currentDate = new Date();
    var arrivaltime = currentDate.toLocaleTimeString();
    console.log("CallArrivaltime time: " + arrivaltime);
  };
  const CallEndedtime = () => {
    var currentDate = new Date();
    var CallEndedtime = currentDate.toLocaleTimeString();
    console.log("CallEndedtime time: " + CallEndedtime);
  };

  const CustomerNameApi = async (phonenumber) => {
    let datas = {
      phone: phonenumber,
    };
    await axios
      .post(BaseUrl + "/users/customername", datas, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantid: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setDialedNumberInc(res.data.data);
      })
      .catch((err) => {});
  };
  const tranferConferanceUpdate = async (phonenumber) => {
    let datas = {
      voice_contactId: localStorage.getItem("transfercontactId"),
      phonenumber,
    };
    await axios
      .post(BaseUrl + "/users/voicetransferupdate", datas, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantid: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        props.setChatRefresh(true);
        localStorage.removeItem("transfercontactId");
      });
  };
  const handleOpenCrmUrl = () => {
    const randomNumber =
      Math.floor(Math.random() * (10000000 - 9999999 + 1)) + 9999999;
    const url = `https://inaipi.ae/v2/screenpopup?number=${dailedNumberInc}&skillset=${
      skillset ? skillset : ""
    }&callid=${
      contactId ? contactId : ""
    }&survey_opt=${randomNumber}&type=voice&direction=INBOUND`;
    window.open(url, "_blank");
    answerIncCall();
  };
  const onError = (error) => {
    console.log("error", error);
  };
  const onDisconnectWebSocket = () => {
    stompClient.disconnect();
  };
  const redialtheNumber = () => {
    setDialedNumber(localStorage.getItem("redialedNumber"));
  };
  const makeCall = () => {
    localStorage.setItem("callActivate", "true");
    if (agentLoginTerminalId == dailedNumber)
      return alert("Don't Enter Same Extension Number You LoggedIn With");
    if (dailedNumber == "") return alert("Please enter a valid Number");

    setDialedNumberInc(dailedNumber);
    localStorage.setItem("redialedNumber", dailedNumber);
    localStorage.setItem("dailedNumber", dailedNumber);

    let ssToken = localStorage.getItem("ssoToken");
    axios
      .post(
        AvcUrl +
          `/voice/terminal/${agentLoginTerminalId}/createContact/sip:${agentLoginTerminalId}@demoaccs.com/sip:${dailedNumber}@${localStorage.getItem(
            "AvayaDomain"
          )}`,
        {},
        {
          headers: {
            ssoToken: ssToken,
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode == 500) {
          toast.warn(res.data.status, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else if (res.data.status == "OK") {
        }
      })
      .catch((err) => {});
  };
  const rejectIncCall = () => {
    if (avayaplatform == "AACC") {
      let ssToken = localStorage.getItem("ssoToken");
      axios
        .post(
          AvcUrl +
            `/voice/terminal/${agentLoginTerminalId}/disconnect/${contactId}`,
          {},
          {
            headers: {
              ssoToken: ssToken,
            },
          }
        )
        .then((res) => {})
        .catch((err) => {});
    }
  };
  const answerIncCall = async (e) => {
    if (avayaplatform == "AACC") {
      setSpinner(true);
      let ssToken = localStorage.getItem("ssoToken");
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/answer/${contactId}`,
          {},
          {
            headers: {
              ssoToken: ssToken,
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            setSpinner(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setSpinner(false);
        });
    }
  };
  const createVoiceSession = async (value) => {
    let tokenagent = await JSON.parse(localStorage.getItem("tokenAgent"));
    var agent_extension = localStorage.getItem("AvayaUsername");
    let datas = {
      name: "",
      email_id: "",
      phone:
        localStorage.getItem("agentLoginTerminalId") == value.calledDeviceID
          ? value.callingDeviceID
          : value.calledDeviceID,
      channel: "voice",
      latitude: "",
      longitute: "",
      skillset: value.skillSet,
      language: "English",
      complaint: "",
      agent_id: tokenagent.id,
      agent_extension: agent_extension,
      direction: value.callType,
      contactId: value.contactId,
      UUID_contactId: value.contactId,
      UUID_externalContactId: value.externalContactId,
      UUID_calledDeviceID: value.calledDeviceID,
      UUID_callingDeviceID: value.UUID_callingDeviceID,
      UUID_callingAgentName: value.callingAgentName,
      UUID_skillSet: value.skillSet,
    };
    await axios
      .post(BaseUrl + "/users/callcreateSession", datas, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantid: localStorage.getItem("TenantId"),
        },
      })
      .then(async (res) => {
        if (res.data.status == true) {
          let voiceuser = res.data.data[0];
          const datas = JSON.parse(localStorage.getItem("tokenAgent"));
          try {
            await axios
              .post(
                `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
                {},
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantId: localStorage.getItem("TenantId"),
                  },
                }
              )
              .then(async (res) => {
                props.setcontactlist(res.data);
                props.setVoiceSessionId(voiceuser.chat_session_id);
                props.setExternalChatData(voiceuser);
                props.setAcceptChatActive(true);
                props.setsidebarVal("Chat");
                navigate("/main/Chat");

                if (localStorage.getItem("ClickToCall") == "true") {
                  try {
                    let body = {
                      phonenumber: localStorage.getItem("ClickeToCallPhoneNum"),
                      skillset: "customer service",
                      channel: "voice",
                      type: localStorage.getItem("type"),
                      reg_no: localStorage.getItem("Contactreg_no"),
                      contact_id: localStorage.getItem("ContactVoiceId"),
                    };
                    await axios
                      .post(BaseUrl + "/ticket/crmscreenpop", body, {
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("access_token"),
                          "Content-Type": "application/json",
                          tenantId: localStorage.getItem("TenantId"),
                        },
                      })
                      .then((res) => {
                        window.open(res.data.data);
                        toast.success("screen pop url generated", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: true,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                          theme: "light",
                        });
                      })
                      .catch((error) => {
                        toast.warning("screen pop url not generated", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: true,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                          theme: "light",
                        });
                      });
                  } catch (error) {}
                }
              })
              .catch((err) => {});
          } catch (error) {}
        }
      });
  };

  const createVoiceSessionForNotAnsCall = async (value) => {
    let tokenagent = await JSON.parse(localStorage.getItem("tokenAgent"));
    var agent_extension = localStorage.getItem("AvayaUsername");
    let datas = {
      name: "",
      email_id: "",
      phone: value.calledDeviceID,
      channel: "voice",
      latitude: "",
      longitute: "",
      skillset: value.skillSet,
      language: "English",
      complaint: "",
      agent_id: tokenagent.id,
      agent_extension: agent_extension,
      direction: value.callType,
      contactId: value.contactId,
      UUID_contactId: value.contactId,
      UUID_externalContactId: value.externalContactId,
      UUID_calledDeviceID: value.calledDeviceID,
      UUID_callingDeviceID: value.UUID_callingDeviceID,
      UUID_callingAgentName: value.callingAgentName,
      UUID_skillSet: value.skillSet,
      isabandoned: true,
    };
    await axios
      .post(BaseUrl + "/users/callcreateSession", datas, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantid: localStorage.getItem("TenantId"),
        },
      })
      .then(async (res) => {
        if (res.data.status == true) {
          let voiceuser = res.data.data[0];
          const datas = JSON.parse(localStorage.getItem("tokenAgent"));
          try {
            await axios
              .post(
                `${BaseUrl}/users/allUsers?agent_id=${datas.id}`,
                {},
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantId: localStorage.getItem("TenantId"),
                  },
                }
              )
              .then(async (res) => {
                props.setcontactlist(res.data);
                props.setVoiceSessionId(voiceuser.chat_session_id);
                props.setExternalChatData(voiceuser);
                props.setAcceptChatActive(true);
                props.setsidebarVal("Chat");
                navigate("/main/Chat");

                if (localStorage.getItem("ClickToCall") == "true") {
                  try {
                    let body = {
                      phonenumber: localStorage.getItem("ClickeToCallPhoneNum"),
                      skillset: "customer service",
                      channel: "voice",
                      type: localStorage.getItem("type"),
                      reg_no: localStorage.getItem("Contactreg_no"),
                      contact_id: localStorage.getItem("ContactVoiceId"),
                    };
                    await axios
                      .post(BaseUrl + "/ticket/crmscreenpop", body, {
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("access_token"),
                          "Content-Type": "application/json",
                          tenantId: localStorage.getItem("TenantId"),
                        },
                      })
                      .then((res) => {
                        window.open(res.data.data);
                        toast.success("screen pop url generated", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: true,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                          theme: "light",
                        });
                      })
                      .catch((error) => {
                        toast.warning("screen pop url not generated", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: true,
                          closeOnClick: false,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                          theme: "light",
                        });
                      });
                  } catch (error) {}
                }
              })
              .catch((err) => {});
          } catch (error) {}
        }
      });
  };

  const toggleclose = () => {
    props.setshowphonedialer(false);
  };

  const [popuptimer, setpopuptimer] = useState(true);

  return (
    <>
      <audio ref={myRef} src={soundfile} />

      <motion.nav
        drag
        dragControls={controls}
        dragConstraints={props.referance}
        className="dialer-main"
        style={{
          display: showphonedialer ? "block" : "none",
        }}
      >
        <div className="filter shadow">
          <div className="icons-dialer">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="user-dial position-relative handle">
                <p style={{ fontSize: "13px", cursor: "pointer" }}>
                  Ext:{localStorage.getItem("agentLoginTerminalId")}
                </p>
              </div>
              <div className="dismiss ">
                <span
                  className="material-symbols-outlined"
                  id="toggle-close"
                  onClick={() => {
                    toggleclose();
                  }}
                  style={{ fontSize: "20px" }}
                >
                  cancel
                </span>
              </div>
            </div>
          </div>
          <div className="dialer-sub">
            <div className="inputHolder d-flex align-items-center">
              <input
                className="form-control dialerInput"
                type="text"
                id=""
                value={dailedNumber}
                onChange={(e) =>
                  setDialedNumber(e.target.value.replace(/[^\d]/, ""))
                }
                onKeyDown={(event) => {
                  return /[0-9]/i.test(event.key);
                }}
              />

              <button
                className="dial-bck"
                id="number"
                onClick={() =>
                  setDialedNumber((dailedNumber) =>
                    dailedNumber.slice(0, dailedNumber.length - 1)
                  )
                }
              >
                <i
                  className="fa-solid fa-delete-left"
                  style={{ fontSize: "21px" }}
                ></i>
              </button>
              <i
                className="fa-solid fa-tty mx-2"
                id="number"
                style={{ fontSize: "19px", color: "#707070" }}
                onClick={() => {
                  redialtheNumber();
                }}
              ></i>
            </div>
            <div className="dial-pad">
              <div className="dial-pad-sub">
                <ul className="icons p-0 m-0">
                  <li
                    id="one"
                    value="1"
                    className="num"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}1`)
                    }
                  >
                    1
                  </li>
                  <li
                    id="two"
                    value="2"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}2`)
                    }
                  >
                    2
                  </li>
                  <li
                    id="three"
                    value="3"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}3`)
                    }
                  >
                    3
                  </li>
                  <li
                    id="four"
                    value="4"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}4`)
                    }
                  >
                    4
                  </li>
                  <li
                    id="five"
                    value="5"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}5`)
                    }
                  >
                    5
                  </li>
                  <li
                    id="six"
                    value="6"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}6`)
                    }
                  >
                    6
                  </li>
                  <li
                    id="seven"
                    value="7"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}7`)
                    }
                  >
                    7
                  </li>
                  <li
                    id="eight"
                    value="8"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}8`)
                    }
                  >
                    8
                  </li>
                  <li
                    id="nine"
                    value="9"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}9`)
                    }
                  >
                    9
                  </li>
                  <li
                    className="star2"
                    id="*"
                    value="astrick"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}*`)
                    }
                  >
                    *
                  </li>
                  <li
                    className="d-flex flex-column"
                    id="zero"
                    value="0"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}0`)
                    }
                  >
                    0
                  </li>
                  <li
                    className="hash0"
                    id="hash"
                    value="#"
                    onClick={() =>
                      setDialedNumber((dailedNumber) => `${dailedNumber}#`)
                    }
                  >
                    #
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <nav className="d-flex justify-content-center dial-nav">
            <button
              className="dialer-btn-main"
              id="call"
              onClick={() => {
                makeCall("makeCall");
              }}
            >
              <i className="fa-solid fa-phone" style={{ fontSize: "17px" }}></i>
            </button>
          </nav>
        </div>
      </motion.nav>

      {/* incoming call screen */}
      <motion.div
        drag
        dragControls={controls}
        dragConstraints={props.referance}
        className="incomecall-phone  p-2"
        style={{ display: showIncommingscreen ? "block" : "none" }}
      >
        <div className="image-paragraph">
          <div
            className=" d-flex justify-content-center align-items-center"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              color: "aliceblue",
              fontSize: "18px",
              fontWeight: "bold",
              position: "relative",
              boxShadow:
                "rgb(0 0 0 / 0%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 2px",
            }}
          >
            <img
              src={voiceIcon}
              style={{
                height: "23px",
                width: "23px",
                margin: "auto",
              }}
            />
          </div>
          <p>Incoming Call from {dailedNumberInc}</p>
        </div>
        <div className="row mt-2 mb-1 list-details">
          <div className="col-6">
            <p>Skillset :</p>
          </div>
          <div className="col-6">
            <p>{skillset ? skillset : ""}</p>
          </div>
          <div className="col-6">
            <p>Language :</p>
          </div>
          <div className="col-6">
            <p>{"Avaya SkillSet"}</p>
          </div>
          <div className="col-6">
            <p>Wait Time :</p>
          </div>
          <div className="col-6">
            <p>{"00:00:00"}</p>
          </div>
        </div>
        <div className="request-button">
          <button
            onClick={() => answerIncCall()}
            type="button "
            className="btn btn-success button mx-3"
            id="accept_client_chat"
            style={{ width: "50px" }}
          >
            {spinner ? (
              <div
                className="spinner-border"
                role="status"
                style={{ height: "1rem", width: "1rem" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <p style={{ color: "#ffff" }}>
                <i className="fa-solid fa-phone"></i>
              </p>
            )}
          </button>

          <button
            onClick={() => rejectIncCall()}
            type="button"
            className="btn btn-danger button"
            id="reject_client_chat"
            style={{ width: "50px" }}
          >
            <p style={{ color: "#ffff" }}>
              <i className="fa-solid fa-phone-slash"></i>
            </p>
          </button>

          {JSON.parse(localStorage.getItem("permission")).map((module) => {
            if (module.moduleName === "Crm") {
              const crmScreen = module.screen.find(
                (item) => item.screenId === "CRM" && item.read && item.write
              );

              if (crmScreen) {
                return (
                  <button
                    type="button "
                    className="btn btn-success button mx-3"
                    id="accept_client_chat"
                    style={{ width: "50px" }}
                    onClick={() => {
                      handleOpenCrmUrl();
                    }}
                  >
                    <p style={{ color: "#ffff" }}>CRM</p>
                  </button>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })}
        </div>
      </motion.div>

      {JSON.parse(localStorage.getItem("permission")).map((module) => {
        if (module.moduleName === "vioce") {
          const crmScreen = module.screen.find(
            (item) =>
              item.screenId === "AUTOWRAPUP" &&
              item.read == true &&
              item.write == true
          );

          if (crmScreen) {
            return (
              <>
                {endchatModalDialog && (
                  <EndChatModelForPopCall
                    OpenDialog={endchatModalDialog}
                    handleClose={() => setendchatModalDialog(false)}
                  />
                )}
              </>
            );
          } else {
            return (
              <>
                {endchatModalDialog && (
                  <EndChatModel
                    OpenDialog={endchatModalDialog}
                    handleClose={() => setendchatModalDialog(false)}
                  />
                )}
              </>
            );
          }
        }
      })}
    </>
  );
};

export default connect(mapStateToProps, {
  setSkillSet,
  setDialedNumberInc,
  setagentDialedNumber,
  setshowphonedialer,
  setShowphonedialer2,
  setShowphonedialer3,
  setshowIncommingscreen,
  setcallscreen,
  setshowIncommingNxtscreen,
  setcalledagentscreen,
  setconfrencescreen,
  setShowMute,
  setShowUnMute,
  setAgentShowMute,
  setAgentShowUnMute,
  setShowHold,
  setShowUnHold,
  setagentCallHold,
  setagentCallUnHold,
  setTransferCall,
  setmakingTransferCall,
  setConfrence,
  setMergeCall,
  setcontactId,
  setconsultedContactId,
  setCallId,
  setAcceptChatActive,
  setExternalChatData,
  setVoiceSessionId,
  setvoiceseconds,
  setvoiceminutes,
  setvoicehours,
  setsidebarVal,
  setShowNotification,
  setShowUserProfile,
  setChatRefresh,
  setShowVoiceCall,
  setcontactlist,
  setShowingStatusClass,
  setShowingStatus,
})(Sdk);
