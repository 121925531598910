import React, { useEffect, useRef, useState } from 'react'
// import Sidebar from '../components/Sidebar'
import { BaseUrlReports } from '../../container/BaseUrl/BaseUrl'
import axios from 'axios'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Sidebar from '../sidebar-components/Sidebar'
import { NavLink } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { TiWarningOutline } from 'react-icons/ti'

const Yearlyreport = () => {


    const selectAgentRef = useRef(null);
    const selectSkillsetRef = useRef(null);
    const selectDispositionRef = useRef(null);


    var someDate = new Date();
    var date = someDate.setDate(someDate.getDate());
    var defaultValue = new Date(date).getFullYear();
    console.log(defaultValue);


    const [fromYear, setFromYear] = useState(defaultValue);


    const animatedComponents = makeAnimated();
    const [apiCallsMade, setApiCallsMade] = useState(false);
    const [showfilter, setShowFilter] = useState(false)
    const [agentlistdata, setAgentlistdata] = useState([])
    const [skillsetdata, setSkillsetdata] = useState([])
    const [disposition, setDisposition] = useState([])
    const [fromdate, setFromdate] = useState(defaultValue)
    const [todate, setTodate] = useState(defaultValue)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState(0)
    const [agentspinner, setagentspinner] = useState(false)

    const [offsetValue, setOffsetValue] = useState(0);

    const [resetPage, setResetPage] = useState(false);



    const [agentlogindatalist, setAgentLoginDataList] = useState([])




    const [selectedagent, setSelectedagent] = useState([])



    const handleYearChange = (e) => {
        console.log('year', e.target.value)
        setFromYear(e.target.value);
    };


    const yearOptions = [];
    for (let year = new Date().getFullYear(); year >= 1900; year--) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    const handelShowFilter = () => {
        setShowFilter(true)
    }
    const handelCloseFilter = () => {
        setShowFilter(false)
    }

    const optionsAgent = agentlistdata.map((agent) => ({
        value: agent.agent_id,
        label: agent.agent_name,
    }));


    // const optionsAgent = agentlistdata.map((option)=>{
    //     console.log(option)
    // })
    const optionsSkillset = skillsetdata.map((skillset) => ({
        value: skillset,
        label: skillset,
    }));


    const optionsDisposition = disposition.map((dispos) => ({
        value: dispos,
        label: dispos,
    }));






    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
    };


    // useEffect(() => {
    //     if (!apiCallsMade) {
    //         agentCallDetailsReportDataAgentList();
    //         setApiCallsMade(true)
    //     }

    // }, [apiCallsMade]);


    useEffect(() => {
        YearlyReport();
    }, [page])







    const YearlyReport = async () => {
        setApiCallsMade(true)
        setagentspinner(true)
  
        await axios
            .post(
                BaseUrlReports + "api/yearly_report",
                {
                    year: fromYear ? fromYear : defaultValue,
                    agent_id: selectedagent,
                    // tenent_id: localStorage.getItem('TenantId'),
                    tenent_id: 'a3dc14bd-fe70-4120-8572-461b0dc866b5',
                    offset:page === 1 ? 0 : (page - 1) * 10,
                    limit: 10,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                console.log('res', res)
                if (res.data) {
                    setagentspinner(false)
                    console.log(res.data.callsCountPerAgentPerDate)
                    setAgentLoginDataList(res.data.data)
                    setPagination(res.data.num)


                }
            })

            .catch((error) => {
                setagentspinner(false)
                console.log(error)
            }


            );
    };




    const resetButtonnew = () => {
        selectAgentRef.current.clearValue();
        handelCloseFilter();
        setResetPage(true); // Set resetPage to true when the reset button is clicked
    }



    const handleChange = (event, value) => {
        console.log(value)
        setPage(value);


    };


    const agentCallDetailsReportDataAgentList = async () => {
        setApiCallsMade(true)
        await axios
            .post(
                BaseUrlReports + "api/agents",
                {
                    // tenent_id: localStorage.getItem('TenantId'),
                    tenent_id: 'a3dc14bd-fe70-4120-8572-461b0dc866b5'
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {

                if (res.data) {
                    setAgentlistdata(res.data.agents)
                }
            })

            .catch((error) => {
                console.log(error)
            });
    };

    const handleSelectAgentChange = (selectedOptions) => {
        const value = selectedOptions.map(option => option.value);
        setSelectedagent(value);
    };

    const resetButton = () => {
        selectAgentRef.current.clearValue();
        setTodate('');
        setFromdate('');
        handelCloseFilter();
    }




    return (
        <>
            <Sidebar />

            <div class={showfilter ? "offcanvas offcanvas-end show" : "offcanvas offcanvas-end"} tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasRightLabel">Filter</h5>
                    <button onClick={handelCloseFilter} type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">

                    <label htmlFor="exampleInputfromdate" className="form-label">Select Year <small className="text-danger">*</small></label>
                    <select
                        value={fromYear}
                        onChange={handleYearChange}
                        className="form-control form-control-lg"
                        id="exampleInputfromdate"
                    >
                        <option value="">--Select Year--</option>
                        {yearOptions}
                    </select>




                    <div class="mb-3">
                        <label for="exampleInputfromdate" class="form-label">Agent <small
                            class="text-danger">*</small></label>
                        <Select
                            ref={selectAgentRef}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={optionsAgent}
                            placeholder="Select Agent"
                            styles={customStyles}
                            onChange={handleSelectAgentChange}
                        />

                    </div>

                </div>


                <div class="offcanvas-sub-btn d-flex justify-content-between mb-1">
                    <button onClick={handelCloseFilter} type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                        class="btn btn-secondary btn-sm mx-1 flex-fill">Close</button>
                    <button onClick={resetButton} type="button" class="btn btn-sm btn-warning mx-1  flex-fill" >Reset</button>
                    <button onClick={() => {
                        setPage(1);
                        YearlyReport(); 
                        handelCloseFilter(); 
                      
                    }}
                        type="button"
                        class=" mx-1 btn-sm btn btn-primary flex-fill">Submit</button>
                </div>

            </div>


            <div class="sub-wapper bg-light" id="mainid">




                <div class="container-fluid">
                    <div class="pt-3">

                        <div class="sub-main card border-0 rounded-0" id="menu1" style={{ height: '98vh' }}>



                            <div
                                class="card-header rounded-0 shadow-sm border-0 bg-white d-flex justify-content-between align-content-center">
                                <span className='fw-bold'>
                                    Yearly Wise Perfomance Reports
                                </span>
                                <div>

                                    <button type="button" class="btn btn-outline-primary rounded-0 fs-13 me-2">
                                        <i class="fa-solid fa-cloud-arrow-down mx-1"></i>
                                        Download Report</button>
                                    <button onClick={handelShowFilter} type="button" class="btn btn-primary rounded-0 fs-13">
                                        <i class="fa-solid fa-filter mx-1"></i>

                                        Filter</button>
                                    <NavLink to='/main/reports'>

                                        <button type="button" class="btn btn-outline-primary mx-2 rounded-0 fs-13 me-2">
                                            <i class="fa-solid fa-arrow-left mx-1"></i>

                                            Back</button>
                                    </NavLink>

                                </div>


                            </div>
                            <div class="card-body px-1" style={{ minWidth: '100%', overflowX: 'auto', overflowY: 'clip' }}>
                                <div>
                                    <div class="table-responsive h-75vh">
                                        <table class="table table-hover" style={{ fontSize: '14px' }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Agent Name</th>
                                                    <th scope="col">Answered Count</th>
                                                    <th scope="col">Maximum Answered Time</th>
                                                    <th scope="col">Total Answered Time</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {agentlogindatalist.length > 0 ? agentlogindatalist.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.Agent_name ? item.Agent_name : '--'}</td>
                                                            <td>{item.Answered_count ? item.Answered_count : '--'}</td>
                                                            <td>{item.Maximum_answered_time ? item.Maximum_answered_time : '--'}</td>
                                                            <td>{item.Total_answered_time ? item.Total_answered_time : '--'}</td>



                                                        </tr>
                                                    )
                                                }) :
                                                    <div className="d-flex justify-content-center align-items-center position-absolute" style={{ minHeight: '400px', left: '50%' }}>
                                                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                            {agentspinner ?
                                                                <>
                                                                    <Spinner animation="border" variant="primary" />
                                                                    <span className='position-relative mt-2' style={{ left: '-10px' }}>Loading...</span>
                                                                </> :
                                                                <>
                                                                    <TiWarningOutline size={30} className='mx-auto text-muted' />
                                                                    <span className='fw-bold text-muted'>
                                                                        No Data Found
                                                                    </span>
                                                                </>

                                                            }
                                                        </div>
                                                    </div>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer rounded-0  bg-white d-flex justify-content-end align-content-center">
                                <nav aria-label="Page navigation example">
                                    <Stack spacing={2}>
                                        <Pagination
                                            onChange={handleChange}
                                            count={Math.ceil(pagination / 10)}
                                            page={page}
                                            color='primary'
                                            variant="outlined"
                                            shape="rounded"
                                        />

                                    </Stack>
                                </nav>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Yearlyreport