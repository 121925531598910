import React, { useEffect, useRef } from "react";
import Navbar from "../../components/navbar-components/Navbar";
import Sidebar from "../../components/sidebar-components/Sidebar";
import { Outlet } from "react-router-dom";
import NewIncomingRequest from "../../components/New-incoming-request-component/New-incoming-request.component";

import { connect } from "react-redux";
import { setMsgTone, setMsgTone2 } from "../../redux/actions/actions";
import audiosound from "../../assets/IncomingMsgTone/msgtone.wav";
import audiosound2 from "../../assets/IncomingMsgTone/watsapp_notif.mp3";
import { ToastContainer, Flip } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    msgTone: state.data.msgTone,
    msgTone2: state.data.msgTone2,
    chat: state.data.chat,
  };
};

const Main = (props) => {
  useEffect(() => {
    if (props.msgTone) {
      playRingSound();
      props.setMsgTone(false);
    }
  }, [props.msgTone]);

  const audioRef = useRef(null);

  const playRingSound = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    if (props.msgTone2) {
      playRingSound2();
      props.setMsgTone2(false);
    }
  }, [props.msgTone2]);

  const audioRef2 = useRef(null);

  const playRingSound2 = () => {
    audioRef2.current.play();
  };
  const myref = useRef(null);
  return (
    <>
      <div ref={myref}>
        <ToastContainer transition={Flip} limit={2} />
        <NewIncomingRequest />
        <audio ref={audioRef} src={audiosound} />
        <audio ref={audioRef2} src={audiosound2} />
        <Navbar referance={myref}  />
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setMsgTone,
  setMsgTone2,
})(Main);
