import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import axios from "axios";
import moment from "moment";
import {
  setselectedemail,
  setsendfilefromfiles,
  setsendfilefromfilesType,
  setsendfilefromfilesName,
} from "../../../../redux/actions/actions";
import { RiErrorWarningLine } from "react-icons/ri";
import {
  DownloadSimple,
  Export,
  File,
  Image,
  Waveform,
} from "@phosphor-icons/react";
import { Box, Stack, Typography } from "@mui/material";
const mapStateToProps = (state) => {
  return {
    chat: state.data.chat,
    selectedemail: state.data.selectedemail,
  };
};

const FilesTab = (props) => {
  const [filename, setFilename] = useState([]);

  const allfile = () => {
    let data = {
      userId: props.customerDetailSearch ? props.userId : props.chat.unique_id,
    };
    axios
      .post("https://qacc.inaipi.ae/v1/fileServer/getMediaUserId", data, {
        headers: {
          TenantId: "123456",
        },
      })
      .then((res) => {
        setFilename(res.data.data);
        console.log("Allfile", res.data.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (props.chat.unique_id) {
      allfile();
    }
  }, [props.selectedemail]);

  const handleDownload = (url) => {
    console.log(url);
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", "file");
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <>
      <motion.div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="m-2 file-main">
          <div className="chat-r-file">
            {filename.length ? (
              filename.map((item) => {
                return (
                  <div
                    className="row m-2 border p-2 justify-content-center align-items-center bg-white"
                    style={{ borderRadius: "8px" }}
                  >
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                      {item.extension == "png" ? (
                        <Image size={40} cursor={"pointer"} />
                      ) : item.extension == "wav" ? (
                        <Waveform size={40} cursor={"pointer"} />
                      ) : (
                        <File size={40} cursor={"pointer"} />
                      )}

                      <Stack sx={{ flex: 1 }}>
                        <Typography variant="subtitle2">
                          {item.originalName}
                        </Typography>
                        <Typography variant="caption">
                          {moment(item.createdDate).format("l   hh:mm:s A")}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={0.5}>
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition:
                              "background-color 0.5s, color 0.5s, fill 0.5s",
                            "&:hover": {
                              background: "#595858e8",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            handleDownload(item.signedUrl);
                          }}
                        >
                          <DownloadSimple
                            size={20}
                            cursor={"pointer"}
                            weight="bold"
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition:
                              "background-color 0.5s, color 0.5s, fill 0.5s",
                            "&:hover": {
                              background: "#595858e8",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            console.log("item", item);
                            props.setsendfilefromfiles(item.signedUrl);
                            props.setsendfilefromfilesType(
                              item.extension == "png"
                                ? "IMAGE"
                                : item.extension == "wav"
                                ? "AUDIO"
                                : item.extension == "pdf"
                                ? "APPLICATION"
                                : ""
                                
                            );
                            props.setsendfilefromfilesName(item.originalName);
                          }}
                        >
                          <Export size={20} weight="bold" />
                        </Box>
                      </Stack>
                    </Stack>
                  </div>
                );
              })
            ) : (
              <div>
                <div
                  className="text-muted d-flex"
                  style={{
                    flexDirection: "column",
                    marginTop: "20%",
                  }}
                >
                  <span className="mx-auto">
                    <RiErrorWarningLine size={30} />
                  </span>
                  <span className="mx-auto">
                    <h6
                      className="text-muted mt-2"
                      style={{ fontFamily: "poppins" }}
                    >
                      No Files Found
                    </h6>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedemail,
  setsendfilefromfiles,
  setsendfilefromfilesType,
  setsendfilefromfilesName,
})(FilesTab);
