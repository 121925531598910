export const SET_UPLOADED_FILE = "SET_UPLOADED_FILE";
export const SET_CONFERENCE_CALL = "SET_CONFERENCE_CALL";
export const SET_SPACE_MESSAGES = "SET_SPACE_MESSAGES";
export const SET_ERROR_DATA = "SET_ERROR_DATA";
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const SET_CHAT_ID = "SET_CHAT_ID";
export const SET_CHAT_DURATION = "SET_CHAT_DURATION";
export const SET_SELECTED_USERIMAGE = "SET_SELECTED_USERIMAGE";
export const TOGGLE_RECORDING = "TOGGLE_RECORDING";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const TOOGLE_CHAT = "TOOGLE_CHAT";
export const REFRESH_TOOGLE_CHAT = "REFRESH_TOOGLE_CHAT";
export const CONF_CHAT_REFRESH = "CONF_CHAT_REFRESH";
export const INTERNAL_CHAT_REFRESH = "INTERNAL_CHAT_REFRESH";
export const CHAT_DATA = "CHAT_DATA";
export const CHAT_DATA_FOR_INTERNAL = "CHAT_DATA_FOR_INTERNAL";
export const ONLOAD_CONTACT_REFRESH = "ONLOAD_CONTACT_REFRESH";
export const TYPE_CHAT = "TYPE_CHAT ";
export const INTERNAL_CHAT_NOTIFY = "INTERNAL_CHAT_NOTIFY";
export const CHAT_MESSAGE = "CHAT_MESSAGE";
export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_CONTACT_LIST1 = "SET_CONTACT_LIST1";
export const SET_CONTACT_LIST2 = "SET_CONTACT_LIST2";
export const SET_INTERNAL_EXTERNAL = "SET_INTERNAL_EXTERNAL";
export const SET_AGENT_LIST = "SET_AGENT_LIST";
export const SET_CONF_NOTIFI = "SET_CONF_NOTIFI";
export const SET_CONF_NOTIFI_VAL = "SET_CONF_NOTIFI_VAL";
export const SET_AGENT_AVAIL = "SET_AGENT_AVAIL";
export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
export const SET_LOADMORE = "SET_LOADMORE";
export const SET_ADD_TOPIC_INPUT = "SET_ADD_TOPIC_INPUT";
export const SET_ADD_TOPIC_TOGGLE = "SET_ADD_TOPIC_TOGGLE";
export const SET_SELECTED_USERNAME = "SET_SELECTED_USERNAME";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const SET_SELECTED_WHATSAPP = "SET_SELECTED_WHATSAPP";
export const SET_SELECTED_FACEBOOK = "SET_SELECTED_FACEBOOK";
export const SET_SELECTED_TWITTER = "SET_SELECTED_TWITTER";
export const SET_SELECTED_TEAMS = "SET_SELECTED_TEAMS";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const SET_SELECTED_ID = "SET_SELECTED_ID";
export const SET_SELECTED_MOBILE = "SET_SELECTED_MOBILE";
export const SET_EXTERNAL_CHAT_DATA = "SET_EXTERNAL_CHAT_DATA";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_CUSTOMER_TYPING = "SET_CUSTOMER_TYPING";

export const SELECTED_CHANNEL_DATA = "SELECTED_CHANNEL_DATA";
export const UPDATED_CHANNEL_DATA = "UPDATED_CHANNEL_DATA";

export const REFRESH_CONTACT_LIST = "REFRESH_CONTACT_LIST";
export const SET_INTENAL_CHAT_ACTIVE = "SET_INTENAL_CHAT_ACTIVE";
export const SET_CONTACT_LISTUPDATED = "SET_CONTACT_LISTUPDATED";

export const SET_CONTACT_LIST_UPDATED1 = "SET_CONTACT_LIST_UPDATED1";
export const FORWARD_MAIL = "FORWARD_MAIL";
export const CLEAR_ALL_NOTIFICATION = "CLEAR_ALL_NOTIFICATION";

export const SET_CHAT_SPINNER = "SET_CHAT_SPINNER";
export const SET_INTERNAL_CHAT_DATA = "SET_INTERNAL_CHAT_DATA";

export const SET_SHOW_EXTENAL_CHAT = "SET_SHOW_EXTENAL_CHAT";
export const SET_SHOW_INTENAL_CHAT = "SET_SHOW_INTENAL_CHAT";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

export const SET_CHAT_REFRESH = "SET_CHAT_REFRESH";

export const SET_MSG_TONE = "SET_MSG_TONE";
export const SET_MSG_TONE2 = "SET_MSG_TONE2";
export const SET_SHOW_CARD = "SET_SHOW_CARD";
export const SET_ACCEPT_CHAT_ACTIVE = "SET_ACCEPT_CHAT_ACTIVE";
export const SET_USER_CHAT_ID = "SET_USER_CHAT_ID";
export const SET_LOADMORE_USER = "SET_LOADMORE_USER";
export const SET_ISWRITE = "SET_ISWRITE";
export const SET_COUPLE_EMAIL = "SET_COUPLE_EMAIL";

// SDK---------------------------------------------------------
export const SET_SKILLSET = "SET_SKILLSET";
export const SET_DIALED_NUMBER_INC = "SET_DIALED_NUMBER_INC";
export const SET_AGENT_DIALED_NUMBER = "SET_AGENT_DIALED_NUMBER";
export const SET_SHOW_DIALED_NUMBER = "SET_SHOW_DIALED_NUMBER";
export const SET_SHOW_DIALED_NUMBER2 = "SET_SHOW_DIALED_NUMBER2";
export const SET_SHOW_DIALED_NUMBER3 = "SET_SHOW_DIALED_NUMBER3";
export const SET_SHOW_DIALED_NUMBER4 = "SET_SHOW_DIALED_NUMBER4";

export const SET_SHOW_INCOMING_SCREEN = "SET_SHOW_INCOMING_SCREEN";
export const SET_CALL_SCREEN = "SET_CALL_SCREEN";
export const SET_SHOW_INCOMING_NXT_SCREEN = "SET_SHOW_INCOMING_NXT_SCREEN";
export const SET_CALLED_AGENT_SCREEN = "SET_CALLED_AGENT_SCREEN";
export const SET_CONFERANCE_SCREEN = "SET_CONFERANCE_SCREEN";
export const SET_SHOW_MUTE = "SET_SHOW_MUTE";
export const SET_SHOW_UNMUTE = "SET_SHOW_UNMUTE";
export const SET_AGENT_SHOW_MUTE = "SET_AGENT_SHOW_MUTE";
export const SET_AGENT_SHOW_UNMUTE = "SET_AGENT_SHOW_UNMUTE";
export const SET_SHOW_HOLD = "SET_SHOW_HOLD";
export const SET_SHOW_UNHOLD = "SET_SHOW_UNHOLD";
export const SET_AGENT_CALL_HOLD = "SET_AGENT_CALL_HOLD";
export const SET_AGENT_CALL_UNHOLD = "SET_AGENT_CALL_UNHOLD";
export const SET_TRANSFER_CALL = "SET_TRANSFER_CALL";
export const SET_MAKING_TRANSFER_CALL = "SET_MAKING_TRANSFER_CALL";
export const SET_CONFERANCE_CALL = "SET_CONFERANCE_CALL";
export const SET_MERGE_CALL = "SET_MERGE_CALL";
export const SET_CALL_CONTACTID = "SET_CALL_CONTACTID";
export const SET_CONSULTED_CONTACT_ID = "SET_CONSULTED_CONTACT_ID";
export const SET_CALL_ID = "SET_CALL_ID";
export const SET_VOICE_SESSION_ID = "SET_VOICE_SESSION_ID";

export const SET_VOICE_SECONDS = "SET_VOICE_SECONDS";
export const SET_VOICE_MINUTES = "SET_VOICE_MINUTES";
export const SET_VOICE_HOURS = "SET_VOICE_HOURS";

export const SET_SELETED_VOICE_SESSION_ID = "SET_SELETED_VOICE_SESSION_ID";
export const SET_SIDEBAR_VAL = "SET_SIDEBAR_VAL";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_SHOW_USER_PROFILE = "SET_SHOW_USER_PROFILE";

export const SET_EXTERNAL_CHAT_ACTIVE = "SET_EXTERNAL_CHAT_ACTIVE";
export const SET_PULL_CONTACT = "SET_PULL_CONTACT";

export const SET_SHOW_VOICE_CALL = "SET_SHOW_VOICE_CALL";

export const SET_CANNEDMESSAGE_DIV = "SET_CANNEDMESSAGE_DIV";
export const SET_CUSTOMER_DETAILS_SHOW = "SET_CUSTOMER_DETAILS_SHOW";

export const SET_INCOMING_REQUEST_REFRESH = "SET_INCOMING_REQUEST_REFRESH";
export const SET_LOAD_MORE_SPINNER = "SET_LOAD_MORE_SPINNER";
export const SET_VOICE_REFRESH = "SET_VOICE_REFRESH";

export const SET_SELECTED_CONTACT_ID = "SET_SELECTED_CONTACT_ID";
export const SET_USERLIST_SHOW = "SET_USERLIST_SHOW";

export const SET_IS_VIP = "SET_IS_VIP";
export const SET_VALUE = "SET_VALUE";
export const SET_INDEX = "SET_INDEX";
export const SET_INDEX2 = "SET_INDEX2";

export const SET_SUPERVISOR_LIST_REFRESH = "SET_SUPERVISOR_LIST_REFRESH";
export const SET_UPDATE_AGENT_STATUS_LIST = "SET_UPDATE_AGENT_STATUS_LIST";
export const SET_SHOWING_STATUS = "SET_SHOWING_STATUS";
export const SET_SHOWING_STATUS_CLASS = "SET_SHOWING_STATUS_CLASS";
export const SET_SEND_FILE_FROM_FILES = "SET_SEND_FILE_FROM_FILES";
export const SET_SEND_FILE_FROM_FILES_TYPE = "SET_SEND_FILE_FROM_FILES_TYPE";
export const SET_SEND_FILE_FROM_FILES_NAME = "SET_SEND_FILE_FROM_FILES_NAME";
export const SET_AGENTSTATUSLIST = "SET_AGENTSTATUSLIST";
export const SET_AGENTSTATUS_REFREASH = "SET_AGENTSTATUS_REFREASH";









