import React from "react";

const MultimediaFilterButton = (props) => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="w-100">
          <div className="d-flex" style={{float:"right"}}>
           
              <button
                type="button"
                id="seesionreports_filter_btn"
                className="btn btn-primary-1 me-2 d-flex-p btn-sm open-filter"
                style={{
                  background: "#1473e6",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  props.setshowfilter("sidebar_filter active-r");
                }}
              >
                <span className="material-symbols-outlined me-1">
                  {" "}
                  filter_list
                </span>
                Filters
              </button>
            
          </div>
        </div>
      </nav>
    </>
  );
};

export default MultimediaFilterButton;
