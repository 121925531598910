import React, { useEffect, useState } from "react";
import { BaseUrl, newBaseUrlLang } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setrefreshContactlist,
  setChatRefresh,
  setshowExternalChat,
  endInteraction,
} from "../../../../redux/actions/actions";

import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
} from "@mui/material";
import { PaperPlaneTilt } from "@phosphor-icons/react";

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Transferagentmodel = (props) => {
  const { show, close, chatid, chat, OpenDialog, handleClose } = props;
  const [skilldropdown, setSkilldropdown] = useState([]);
  const [languagedropdown, setLanguagedropdown] = useState([]);
  const [skilldropdownid, setSkilldropdownid] = useState("");
  const [languagedropdownid, setLanguagedropdownid] = useState("");
  const [agentbasedonfilter, setAgentbasedonfilter] = useState([]);
  const [agentTransferId, setAgentTransferId] = useState("agent");
  const [checkWhichCall, setCheckWhichCall] = useState("");
  const [showTransferMod, setShowTransferMod] = useState(false);

  const [skillvalid, setskillvalid] = useState(false);
  const [languagevalid, setLanguagevalid] = useState(false);
  const [agentvalid, setAgentvalid] = useState(false);

  const [age, setAge] = useState("");

  const handleChangeSkillset = (event) => {
    setSkilldropdown(event.target.value);
  };

  const handelAllErrorMessage = () => {
    setskillvalid(false);
    setLanguagevalid(false);
    setAgentvalid(false);
  };

  const handleTransferClose = () => {
    setAgentTransferId("agent");
    setShowTransferMod(false);
  };

  const transferAgent = (val) => {
    setCheckWhichCall(val);
    setShowTransferMod(true);
  };

  const skillsetDropdown = () => {
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/skills/list", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setSkilldropdown(res.data.dataList);
      })
      .catch((error) => {});
  };

  const sortedItemsSkillset = skilldropdown.slice().sort();
  sortedItemsSkillset.sort((a, b) => a.skillName.localeCompare(b.skillName));

  const languageDropdownList = () => {
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/languages/list", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //
        setLanguagedropdown(res.data.dataList);
      })
      .catch((error) => {
        // errorHandel(error, "/languages/list")
      });
  };

  const sortedItemsLanguage = languagedropdown.slice().sort();
  sortedItemsLanguage.sort((a, b) =>
    a.languageDesc.localeCompare(b.languageDesc)
  );

  const userBasedonLanguageSkillset = (skillId, languageid) => {
    let userId = JSON.parse(localStorage.getItem("tokenAgent"));

    let data = {
      language_id: languageid ? languageid : languagedropdownid,
      skillset_id: skillId ? skillId : skilldropdownid,
      agent_id: userId.id,
    };
    axios
      .post(BaseUrl + "/users/listUserBySkillsetIdLanguageId", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        if (res.data.status == true) {
          setAgentbasedonfilter(res.data.data);
        }
      })
      .catch((error) => {
        // errorHandel(error, "/users/listUserBySkillsetIdLanguageId")
        // toast.warn('Server is down,please try after some time', {
        //   position: "top-right",
        //   autoClose: 1000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        //   if (err.response.status == 401) {
        //     window.location.href = "/";
        //   }
      });
  };

  const sortedItemsAgentList = agentbasedonfilter.slice().sort();
  sortedItemsAgentList.sort((a, b) => a.username.localeCompare(b.username));

  const transferAgentSubmit = async () => {
    localStorage.removeItem("client");
    try {
      if (chat.is_customer_disconnected) {
        toast.error("Customer is disconnected", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        const access_token = localStorage.getItem("access_token");
        let selected_name =
          agentTransferId.options[agentTransferId.selectedIndex].text;
        let selected_val = agentTransferId.value;

        if (selected_name === "agent") {
          alert("select agent");
          return false;
        }

        let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));

        let dataToPass = {
          sessionId: chatid,
          agentId: selected_val,
          oldAgentId: old_agent_id.id,
        };

        let message_to_pass;
        message_to_pass =
          "Interaction Transferred from " +
          old_agent_id.username +
          " to " +
          selected_name;
        const data = await axios.post(
          BaseUrl + "/users/transferAgent",
          dataToPass,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );

        if (data.data.status) {
          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: old_agent_id.id,
              to: chat.id,
              message: message_to_pass,
              senderName: old_agent_id.username,
              receiverName: chat.username,
              messageFrom: "fromAgent",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: "NOTIFICATIONS",
            },
            {
              headers: {
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          );

          toast.success("Transferred to Agent success", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          props.setChatRefresh(true);
          props.setshowExternalChat(false);
          props.endInteraction();
          handleTransferClose();
        } else {
          toast.warn(data.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      // errorHandel(error, "/users/conferenceAgent")
    }
  };

  useEffect(() => {
    languageDropdownList();
    skillsetDropdown();
  }, []);

  return (
    <>
      <Dialog
        open={OpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          Transfer To Agent
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack>
              <Stack direction={"row"} spacing={1}>
                <select
                  className={`form-select form-select-sm ${skillvalid}`}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSkilldropdownid(e.target.value);
                    userBasedonLanguageSkillset(e.target.value, "");
                    setskillvalid(false);
                  }}
                >
                  <option selected disabled value="">
                    Please select skillset
                  </option>
                  {skilldropdown.length > 0 ? (
                    skilldropdown.map((item) => {
                      return (
                        <option id={item.skillId} value={item.skillId}>
                          {item.skillName}
                        </option>
                      );
                    })
                  ) : (
                    <option>No Skillset</option>
                  )}
                </select>

                <select
                  className={`form-select form-select-sm ${languagevalid}`}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setLanguagedropdownid(e.target.value);
                    userBasedonLanguageSkillset("", e.target.value);
                    setLanguagevalid(false);
                  }}
                >
                  <option selected disabled value="">
                    Please select language
                  </option>
                  {sortedItemsLanguage.length > 0 ? (
                    sortedItemsLanguage.map((item, id) => {
                      return (
                        <option id={item.languageId} value={item.languageId}>
                          {item.languageDesc}
                        </option>
                      );
                    })
                  ) : (
                    <option>No Language</option>
                  )}
                </select>
              </Stack>

              <select
                className={`form-select form-select-sm mt-4 ${
                  agentvalid ? "is-invalid" : ""
                }`}
                name="availableAgent"
                onChange={(e) => {
                  setAgentTransferId(e.target);
                  setAgentvalid(false);
                }}
              >
                <option value="" selected disabled>
                  Available Agent
                </option>

                {agentbasedonfilter.map((item) => {
                  return (
                    <option id={item.id} value={item.id}>
                      {item.username}
                    </option>
                  );
                })}

                {/* agentbasedonfilter */}
              </select>
              {agentvalid && (
                <div className="invalid-feedback">Please select an Agent</div>
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (agentTransferId === "agent") {
                setAgentvalid(true);
              } else {
                transferAgentSubmit();
                handleClose();
              }
            }}
            variant="contained"
            size="small"
            id="agent-transfer"
            endIcon={<PaperPlaneTilt size={15} />}
          >
            {checkWhichCall}
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setrefreshContactlist,
  setChatRefresh,
  setshowExternalChat,
  endInteraction,
})(Transferagentmodel);
