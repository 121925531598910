import React, { useState } from "react";
import { FaArrowUp, FaBusinessTime } from "react-icons/fa";
import { TbHandOff } from "react-icons/tb";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Foroutsideclick from "../../outside-click/Foroutsideclick";
import axios from "axios";
import {
  BaseUrl,
  emailservice,
  emailservicem,
} from "../../../container/BaseUrl/BaseUrl";
import { connect } from "react-redux";
import { setExternalChatData,setUserlistShow } from "../../../redux/actions/actions";

// Component
import Endchatmodel from "./chat-component-model/End-chat-model";
import Escalationmodel from "./chat-component-model/Escalation-modal";
import { RiMailForbidLine } from "react-icons/ri";
import TransferSkillset from "./chat-component-model/TransferSkillset";
import { BiTransfer } from "react-icons/bi";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    externalChatData: data.externalChatData,
    selectedchanneldata: data.selectedchanneldata,
    userlistshow:data.userlistshow

  };
};
const EmailMenu = (props) => {
  const { externalChatData, toggleCard, OpenDialog, handleClose } = props;
  const [escalationmodal, setescalationmodal] = useState(false);
  const [transferskillsetmodal, setTransferskillsetmodal] = useState(false);
  const [endchatModalDialog, setendchatModalDialog] = useState(false);

  // Tooltip
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const cannotHandel = () => {
    let userId = JSON.parse(localStorage.getItem("tokenAgent"));
    let sessionId = JSON.parse(localStorage.getItem("client"));

    let agentId = userId.id;
    let datas = {
      chat_session_id: sessionId.chat_session_id,
      chatendby: "Agent",
      reason: "Cannot Handle",
      comment: "good",
      agent_id: agentId,
      ticket: true,
    };
    axios
      .post(BaseUrl + "/message/endchat", datas, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        props.contactList();
        window.location.reload(true);
      })
      .catch((error) => {
        // errorHandel(error, "/message/endchat");
      });

    let data = {
      chatendby: "Agent",
      reason: "disconnected",
      comment: "Completed",
    };
    axios
      .put(
        emailservice +
          `email/reassignEmails/${
            props.selectedchanneldata[0].Email
              ? props.selectedchanneldata[0].Email._id
              : ""
          }`,
        data
      )
      .then((response) => {
        if (response) {
          window.location.reload(true);
          props.contactList();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const completedEmail = () => {
    let userId = JSON.parse(localStorage.getItem("tokenAgent"));
    let sessionId = JSON.parse(localStorage.getItem("client"));
    let agentId = userId.id;
    let datas = {
      chat_session_id: sessionId.chat_session_id,
      chatendby: "Agent",
      reason: "NetworkIssue",
      comment: "Completed",
      agent_id: agentId,
      ticket: true,
    };
    axios
      .post(BaseUrl + "/message/endchat", datas, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        props.contactList();
        window.location.reload(true);
      })
      .catch((error) => {
        // errorHandel(error, "/message/endchat");
      });
    const data = {
      id: props.selectedchanneldata[0].Email._id,
      status: "completed",
      requestID: props.selectedchanneldata[0].Email.requestID,
      comment: "Completed",
      reason: "disconnected",
    };
    axios
      .post(emailservice + "email/completedEmails", data)
      .then((response) => {
        if (response) {
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EndChatDialogClose = () => {
    setendchatModalDialog(true);
  };

  const Escalation = () => {
    setescalationmodal(true);
  };
  const TransferSkillsetmodalforAgent = () => {
    setTransferskillsetmodal(true);
  };

  return (
    <>
      <Foroutsideclick id="outside-click" onClickOutside={toggleCard}>      
        <div
          className="menucard2"
          style={{
            right: props.userlistshow ? "33rem" : "5rem",
            width: "235px",
          }}
        >
          <BootstrapTooltip title="Close Menu" arrow placement="top">
            <button
              type="button"
              id="close-toggle"
              className="btn border-0 btn-sm closeMenucard2"
              data-tippy-content=" Close Menucard"
              onClick={toggleCard}
            ></button>
          </BootstrapTooltip>
          <BootstrapTooltip title="Cannot Handle" arrow placement="bottom">
            <button
              type="button"
              id="cannot-handle"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                cannotHandel();
              }}
            >
              <TbHandOff className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip title="Escalation" arrow placement="bottom">
            <button
              type="button"
              id="escalate"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                Escalation();
              }}
            >
              <FaArrowUp className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip title="No Action Email" arrow placement="bottom">
            <button
              type="button"
              id="no-email-action"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                completedEmail();
              }}
            >
              <RiMailForbidLine className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>



          <BootstrapTooltip title="Transfer To Agent" arrow placement="bottom">
            <button
              type="button"
              id="agent-transfer"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                TransferSkillsetmodalforAgent();
              }}
            >
              <BiTransfer className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>

          <BootstrapTooltip title="End Interaction" arrow placement="bottom">
            <button
              type="button"
              id="end-interaction"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                EndChatDialogClose();
              }}
            >
              <AiOutlineCloseCircle className="icon_top-btn_small" size={20} />
            </button>
          </BootstrapTooltip>
        </div>
      </Foroutsideclick>
    
      {/* All Modal */}

      <Escalationmodel
        show={escalationmodal}
        close={() => setescalationmodal(false)}
      />
      <TransferSkillset
        OpenDialog={transferskillsetmodal}
        handleClose={() => setTransferskillsetmodal(false)}
      />

      <Endchatmodel
        OpenDialog={endchatModalDialog}
        handleClose={() => setendchatModalDialog(false)}
      />
    </>
  );
};

export default connect(mapStateToProps, {
  setExternalChatData,
  setUserlistShow
})(EmailMenu);
