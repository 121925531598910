import React, { useState } from "react";
import "./Model-Style/modal.css";
import Modal from "react-bootstrap/Modal";
import { BaseUrl } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { errorhandelApi } from "../../../../ApiCall/ErrorHandelApi";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  setchatdata,
  setchatid,
  setChatRefresh,
  setshowExternalChat,
  setselectedwhatsapp,
} from "../../../../redux/actions/actions";
import { MdModeEditOutline } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { PaperPlaneTilt } from "@phosphor-icons/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
    selectedwhatsapp: state.data.selectedwhatsapp,
  };
};

const WhatsappModal = (props) => {
  const { show, close, chatid, chat, OpenDialog, handleClose } = props;
  const [phonenumber, setPhonenumber] = useState(props.selectedwhatsapp);
  const [phonenumbervalidation, setPhonenumbervalidation] = useState(false);

  // Tooltip
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleTransferWhatsapp = async () => {
    let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));
    if (chat.is_customer_disconnected) {
      toast.error("Customer is disconnected", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
      const access_token = localStorage.getItem("access_token");
      try {
        let dataToPass = {
          chat_session_id: chatid,
          phonenumber: phonenumber,
        };

        const data = await axios.post(
          BaseUrl + "/users/transfertoWhatsapp",
          dataToPass,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );
        // if (confirm("Chat Transferred success")) {
        let message_to_pass;
        if (data.data.status) {
          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: old_agent_id.id,
              to: chat.id,
              message: message_to_pass,
              senderName: old_agent_id.username,
              receiverName: chat.username,
              messageFrom: "fromAgent",
              userType: chat.chat_type,
              session: chat.chat_session_id,
              msg_sent_type: "NOTIFICATIONS",
            },
            {
              headers: {
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          );
          setPhonenumbervalidation(false);
          toast.success("Chat Transferred to whatsapp sucessfully", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setPhonenumber("");
        } else {
          setPhonenumbervalidation(true);
          toast.error("Enter a Valid Number", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setPhonenumber("");
          props.setChatRefresh(true);
          props.setshowExternalChat(false);
        }
      } catch (error) {
        toast.warn("Something Went Wrong", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          close();
        }, 1000);
        errorhandelApi(error, "/users/transfertoWhatsapp");
      }
    }
  };

  return (
    <>
      <Dialog
        open={OpenDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          Transfer To WhatsApp
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack>
              <Stack direction={"row"}>
                <FormControl
                  sx={{ m: 1, minWidth: 170 }}
                  size="small"
                ></FormControl>
                <FormControl
                  sx={{ m: 1, minWidth: 180 }}
                  size="small"
                ></FormControl>
              </Stack>
              <input
                className="form-control"
                type="number"
                placeholder="Enter your phone number"
                value={phonenumber}
                onChange={(e) =>
                  setPhonenumber(e.target.value.replace(/[^\d]/, ""))
                }
              ></input>
              <BootstrapTooltip title="Edit Number">
                <MdModeEditOutline size={20} className="whatsappEdit-icon" />
              </BootstrapTooltip>
              {phonenumbervalidation ? (
                <span
                  className="text-danger fw-bold"
                  style={{ fontSize: "10px" }}
                >
                  Please fill in the phone number field*
                </span>
              ) : (
                ""
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (phonenumber === "") {
                setPhonenumbervalidation(true);
              } else {
                setPhonenumbervalidation(false);
                handleTransferWhatsapp();
                // handleClose()
              }
            }}
            size="sm"
            className="mb-2 float-end"
            id="agent-num-transfer"
            variant="contained"
            endIcon={<PaperPlaneTilt size={15} />}
          >
            Transfer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setChatRefresh,
  setshowExternalChat,
  setselectedwhatsapp,
})(WhatsappModal);
