import { connect } from "react-redux";
import Inbox from "./Inbox";
import { InboxList, InboxMailShow, DownloadAttachment, SendEmail, openWriteEmail, openReplyEmail, closeWriteEmail, InboxErrorMessageClose } from "../../../redux/actions/actions";

const mapStateToProps = (state) => {
    return {

        emailShowMessage: state.data.emailShowMessage,
        emaiIsPending: state.data.emaiIsPending,
        emailMessage: state.data.emailMessage,
        emailShowColor: state.data.emailShowColor,
        InboxDataList: state.data.InboxDataList,
        InboxEmailContent: state.data.InboxEmailContent,
        InboxEmailBody: state.data.InboxEmailBody,
        writeEmail: state.data.writeEmail,
        isReplyEmail: state.data.isReplyEmail,
    }
}

const mapDispatchToProps = (dispatch) => ({
    InboxList: (a) => dispatch(InboxList(a)),
    InboxMailShow: (a) => dispatch(InboxMailShow(a)),
    DownloadAttachment: (a, b) => dispatch(DownloadAttachment(a, b)),
    SendEmail:(a) => dispatch(SendEmail(a)),
    openWriteEmail:() => dispatch(openWriteEmail()),
    openReplyEmail:() => dispatch(openReplyEmail()),
    closeWriteEmail:() => dispatch(closeWriteEmail()),
    InboxErrorMessageClose: () => dispatch(InboxErrorMessageClose())
})

const InboxContainer = connect(mapStateToProps, mapDispatchToProps)(Inbox)

export default InboxContainer