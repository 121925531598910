import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BaseUrl, emailservice } from "../../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setChatRefresh,
  setshowExternalChat,
} from "../../../../redux/actions/actions";

const mapStateToProps = (state) => {
  return {
    chat: state.data.chat,
    chatid: state.data.chatid,
    selectedchanneldata: state.data.selectedchanneldata,
  };
};

const Transfersupervisormodel = (props) => {
  const { show, close } = props;
  const [availableAgent, setAvailableAgent] = useState([]);
  const [agentTransferId, setAgentTransferId] = useState("agent");
  const [showTransferMod, setShowTransferMod] = useState(false);
  const [checkWhichCall, setCheckWhichCall] = useState("");
  const [value, setSelectedValue] = useState("");
  const [showfiltersupervisor, setShowfiltersupervisor] = useState(false);

  const handleTransferClose = () => {
    setAgentTransferId("agent");
    setShowTransferMod(false);
  };

  const transferAgent = (val) => {
    setCheckWhichCall(val);
    setShowTransferMod(true);
  };

  const handleTransfersupervisorClose = () => setShowfiltersupervisor(false);
  const handleTransfersupervisorOpen = (val) => {
    setShowfiltersupervisor(true);
    setCheckWhichCall(val);
  };

  useEffect(() => {
    getAvailableAgents();
  }, []);

  const getAvailableAgents = async () => {
    const access_token = localStorage.getItem("access_token");

    try {
      let userId = JSON.parse(localStorage.getItem("tokenAgent"));
      let passUserId = userId.user_id;
      const data = await axios.post(
        BaseUrl + "/users/listSupervisor",
        {},
        {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );
      if (data.data.status) {
        // props.setavailagent(data.data.data);
        setAvailableAgent(data.data.data);
      }
    } catch (error) {
      // errorHandel(error, "/users/listSupervisor")
      
    }
  };

  const escalationSubmitButton = async (value) => {
    let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));
    let sessionId = JSON.parse(localStorage.getItem("client"));
    let dataToPass = {
      sessionId: sessionId.chat_session_id,
      agentId: value,
      oldAgentId: old_agent_id.id,
    };
    axios
      .post(BaseUrl + "/users/transferAgent", dataToPass, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((response) => {
        if (response.data.status == true) {
          props.setChatRefresh(true);
          props.setshowExternalChat(false);
          toast.success("Email Transfered To Supervisor Successfully", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      });
    const data = {
      agentID: value,
    };
    axios
      .put(
        emailservice +
          `email/escalate/${
            props.selectedchanneldata[0].Email
              ? props.selectedchanneldata[0].Email._id
              : ""
          }`,
        data
      )
      .then((response) => {
        if (response) {
          // window.location.reload(true);
          // handleTransfersupervisorClose();
          close;
          props.contactList();
          
          handleCloseEscalationModal();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>

      <Modal show={show} size="md" onHide={close}>
        <Modal.Header style={{ backgroundColor: "#0b3363", color: "#fff" }}>
          <Modal.Title
            style={{
              fontSize: 15,
              margin: "6px 0 0 0",
              textTransform: "capitalize",
            }}
          >
            Reassign To Supervisor
          </Modal.Title>
          <AiOutlineCloseCircle id="close-circle" onClick={close} style={{ cursor: "pointer" }} />
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select form-select-sm"
            name="availableAgent"
            value={value}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <option value="agent" selected>
              Available Supervisor
            </option>
            {availableAgent.map((agents) => {
              return (
                <option value={agents.agent_id}>
                  {agents.username ? agents.username : agents.firstName}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px" }}>
          <Button
            variant="secondary"
            id="close-btn"
            onClick={close}
            style={{ fontFamily: "monospace" }}
          >
            CLOSE
          </Button>
          <Button
            variant="primary"
            id="agent-transfer"
            onClick={() => escalationSubmitButton(value)}
            style={{ fontFamily: "monospace" }}
          >
            {checkWhichCall}
            TRANSFER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setConferenceNotificationVal,
  setConferenceNotification,
  setChatRefresh,
  setshowExternalChat,
})(Transfersupervisormodel);
