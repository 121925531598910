import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Box, OutlinedInput, Pagination, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import moment from "moment";
import { RiErrorWarningLine } from "react-icons/ri";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding:'4px',
  background: "none",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: "#FFFF",
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const QueueChat = () => {
  const [queueList, setqueueList] = useState([]);
  const [agentlist, setAgentlist] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(0);
  const [selectedAgents, setSelectedAgents] = useState({});

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    axios
      .post(
        BaseUrl + "/users/listqueuedsession",
        {
          limit: 8,
          offset: page == 1 ? 0 : (page - 1) * 8,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setqueueList(res.data.data);
          setPagination(res.data.count);
        }
      })
      .catch((err) => {});
  }, [page]);

  useEffect(() => {
    agentListdropdown();
  }, []);

  const agentListdropdown = async () => {
    const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
    await axios
      .post(
        BaseUrl + "/users/listagentList",
        {
          user_id: basedOnRole.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        setAgentlist(res.data.data);
      })
      .catch((err) => {
        setShow(true);
      });
  };

  const updateAgent = async (value, value2) => {
    await axios
      .post(
        BaseUrl + "/users/updateAgent",
        {
          chat_session_id: value,
          agentId: value2,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        setShow(true);
      });
  };

  return (
    <StyledPaper elevation={0}>
      <Stack spacing={1}>
        <Paper>
          <TableContainer sx={{ height: 480 }} className="CONTACT-LIST-AREA">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Phone Number</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Channel</StyledTableCell>
                  <StyledTableCell>Skillset</StyledTableCell>
                  <StyledTableCell>Arrival At</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queueList.length > 0 ? (
                  queueList.map((item) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                      <StyledTableCell2>
                        {item.unique_id.username}
                      </StyledTableCell2>
                      <StyledTableCell2>{item.phonenumber}</StyledTableCell2>
                      <StyledTableCell2>{item.email}</StyledTableCell2>
                      <StyledTableCell2>{item.channel}</StyledTableCell2>
                      <StyledTableCell2>{item.skillset}</StyledTableCell2>
                      <StyledTableCell2>
                        {moment(item.createdAt).format("L hh:mm:ss A")}
                      </StyledTableCell2>
                      <StyledTableCell2>
                        <FormControl sx={{ width: 140 }} size="small">
                          <InputLabel
                            id={`select-agent-label-${item.chat_session_id}`}
                          >
                            Select Agent
                          </InputLabel>

                          <Select
                            labelId={`select-agent-label-${item.chat_session_id}`}
                            id={`select-agent-${item.chat_session_id}`}
                            value={selectedAgents[item.chat_session_id] || ""}
                            onChange={(event) => {
                              const { value } = event.target;
                              setSelectedAgents((prevState) => ({
                                ...prevState,
                                [item.chat_session_id]: value,
                              }));
                              updateAgent(item.chat_session_id, value);
                            }}
                            input={<OutlinedInput label="Select Agent" />}
                            MenuProps={{
                              style: {
                                top: "0px",
                                maxHeight: "350px",
                              },
                            }}
                          >
                            {agentlist.length > 0 ? (
                              agentlist.map((agent) => (
                                <MenuItem value={agent.id} key={agent.id}>
                                  {agent.username}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>{"No Data"}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </StyledTableCell2>
                    </TableRow>
                  ))
                ) : (
                  <div
                    className="text-muted"
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      position: "absolute",
                      left: "50%",
                      bottom: "50%",
                    }}
                  >
                    <span className="mx-auto">
                      <RiErrorWarningLine size={30} />
                    </span>
                    <span className="mx-auto">
                      <h5
                        className="text-muted mt-2"
                        style={{ fontFamily: "poppins" }}
                      >
                        No Data Found
                      </h5>
                    </span>
                  </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack direction={"row"} justifyContent={"end"}>
          <Pagination
            count={Math.ceil(pagination / 8)}
            page={page}
            size="small"
            variant="outlined"
            shape="rounded"
            color="primary"
            style={{ float: "right" }}
            onChange={handleChange}
          />
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

export default QueueChat;
