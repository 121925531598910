import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { setDialedNumberInc } from "../../redux/actions/actions";
import voice from "../../assets/assets/images/voice.png";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl, excelDownloadUrl } from "../../container/BaseUrl/BaseUrl";
import { Pagination, Skeleton, Stack } from "@mui/material";
import { RiErrorWarningLine } from "react-icons/ri";
import { Check, DownloadSimple } from "@phosphor-icons/react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Chip } from "@mui/material";
import { Eye } from "@phosphor-icons/react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100vh",
  width: "96vw",
  padding: "10px",
  background: "none",
}));

const StyledPaper2 = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: "100%",
  width: "100%",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: theme.palette.common.black,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: "7px 10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const mapStateToProps = (state) => {
  return { skillset: state.data.skillset };
};

const SupervisorContactNew = (props) => {
  const agent = JSON.parse(localStorage.getItem("tokenAgent"));
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const [showSupervisorfilter, setShowSupervisorfilter] =
    useState("sidebar_filter");
  const [showSupervisorAgentreports, setShowSupervisorAgentreports] =
    useState("sidebar_filter");
  const [SupervisorAgentName, setSupervisorAgentName] = useState([]);
  const [Agentid, setAgentid] = useState();
  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [fileuploadPopup, setfileuploadPopup] = useState(false);
  const [ServiceButton, setServiceButton] = useState("contained");
  const [SalesButton, setSalesButton] = useState("outlined");
  const [agentButton, setagentButton] = useState("outlined");

  const [pagination, setPagination] = useState(0); //service
  const [page, setPage] = useState(1);
  const [pagination2, setPagination2] = useState(0); //sales
  const [page2, setPage2] = useState(1);
  const [pagination3, setPagination3] = useState(0); //agent
  const [page3, setPage3] = useState(1);

  const [supervisorSalesContactList, setsupervisorSalesContactList] = useState(
    []
  );
  const [supervisorServiceContactList, setsupervisorServiceContactList] =
    useState([]);
  const [agentCount, setagentCount] = useState([]);
  const [mobilenumber, setmobilenumber] = useState("");
  const [tableType, settableType] = useState("Sales");
  const [filterType, setfilterType] = useState("");
  const [file, setfile] = useState();
  const [loadingSales, setLoadingSales] = useState(false);
  const [loadingService, setLoadingService] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(false);

  const [ListOfAgent, setListOfAgent] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [Salespreview, setSalesPreview] = useState(false);
  const [Servicepreview, setServicePreview] = useState(false);
  const [selectedSalesfile, setSelectedSalesfile] = useState(false);
  const [selectedServicefile, setSelectedServicefile] = useState(false);

  const [tableListType, settableListType] = useState("serviceUpload");

  const [selectedAgents, setSelectedAgents] = useState([]);
  const [uploadButton, setUploadButton] = useState(true);
  const [uploadButton2, setUploadButton2] = useState(true);
  const clearFilterCache = () => {
    setFromdate(defaultValue);
    setTodate(defaultValue);
    setmobilenumber("");
    setSupervisorAgentName("");
  };

  const clearFilter = () => {
    setFromdate("");
    setTodate("");
    setmobilenumber("");
    setSupervisorAgentName("");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleChange2 = (event, value) => {
    setPage2(value);
  };

  const handleChange3 = (event, value) => {
    setPage3(value);
  };

  const hiddenFileInput = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const ServicehandleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const SaleshandleClick2 = (event) => {
    hiddenFileInput2.current.click();
  };
  const handleClose = () => {
    setfileuploadPopup(false);
  };

  const handleClosePreview = () => {
    setSalesPreview(false);
    setSelectedSalesfile(null);
    setSelectedAgents([]);
    if (hiddenFileInput2.current) {
      hiddenFileInput2.current.value = "";
    }
  };

  const handleClosePreview2 = () => {
    setServicePreview(false);
    setSelectedServicefile(null);
    setSelectedAgents([]);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  const handleConfirmSalesPreview = (event) => {
    const dataFile = event.target.files[0];
    setSelectedSalesfile(dataFile);
    setSalesPreview(true);
    if (selectedAgents.length > 0 && dataFile) {
      setUploadButton(false);
    } else {
      setUploadButton(true);
    }
  };

  const handleConfirmServerPreview = (event) => {
    const dataFile = event.target.files[0];
    setSelectedServicefile(dataFile);
    setServicePreview(true);
    if (selectedAgents.length > 0 && dataFile) {
      setUploadButton(false);
    } else {
      setUploadButton(true);
    }
  };

  //SALESUPLOADAPI//
  const SaleshandleChangeFile = async (file) => {
    if (file) {
      let agent = JSON.stringify(selectedAgents);
      console.log("agent", agent);
      const dataArray = new FormData();
      dataArray.append("file", file);
      dataArray.append("agentList", agent);
      setSpinner(true);

      try {
        // Make a POST request using Axios
        const res = await axios.post(
          BaseUrl + "/users/createExcelSheetUsers",
          dataArray,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "multipart/form-data",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );

        // Display success toast message
        toast.success("sales excel uploaded to database");

        // Set spinner to false after 2000 milliseconds (2 seconds)
        setTimeout(() => {
          setSpinner(false);
        }, 2000);

        // Call the listSalesreport function
        listSalesreport();

        // Update state variables
        setSalesButton("outlined");
        setServiceButton("contained");
        settableType("Sales");
        handleClosePreview();
      } catch (error) {
        // Display error toast message
        toast.error("sales excel not uploaded");
      }
    }
  };

  //SERVICEUPLOADAPI//
  const ServicehandleChangeFile = async (file) => {
    if (file) {
      let agent = JSON.stringify(selectedAgents);

      const dataArray = new FormData();
      dataArray.append("file", file);
      dataArray.append("agentList", agent);
      setSpinner(true);
      try {
        const res = await axios.post(
          BaseUrl + "/users/createExcelServiceUsers",
          dataArray,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "multipart/form-data",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );
        toast.success("service excel uploaded to database");
        setTimeout(() => {
          setSpinner(false);
        }, 2000);
        listServicereport();
        setSalesButton("contained");
        setServiceButton("outlined");
        settableType("Service");
        handleClosePreview2();
      } catch (error) {
        toast.success("service excel not uploaded");
      }
    }
  };

  const AgentList = async () => {
    await axios
      .post(
        BaseUrl + "/users/listagentList",
        {
          user_id: agent.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        setSupervisorAgentName(res.data.data);
        setListOfAgent(res.data.data);
      })
      .catch((error) => {});
  };

  const listSalesreport = async () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const isCurrentDate = fromdate === currentDate && todate === currentDate;
    setLoadingSales(true);
    let data = {
      agent_id: Agentid,
      offset: page2 == 1 ? 0 : (page2 - 1) * 10,
      limit: 10,
      from_date: fromdate ? fromdate : defaultValue,
      to_date: todate ? todate : defaultValue,
      mobilenumber: mobilenumber,
    };
    await axios
      .post(BaseUrl + "/contact/listSupervisorreport", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("listSalesreport", res);
        if (res.data.status == true) {
          setsupervisorSalesContactList(res.data.data);
          setPagination2(res.data.count);
          setTimeout(() => {
            setLoadingSales(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setsupervisorSalesContactList([]);
          setPagination2(0);
          setTimeout(() => {
            setLoadingSales(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setTimeout(() => {
          setLoadingSales(false);
        }, 1000);
      });
  };

  const listServicereport = async () => {
    setLoadingService(true);
    let body = {
      agent_id: Agentid,
      offset: page == 1 ? 0 : (page - 1) * 10,
      limit: 10,
      from_date: fromdate ? fromdate : defaultValue,
      to_date: todate ? todate : defaultValue,
      mobilenumber: mobilenumber,
    };
    await axios
      .post(BaseUrl + "/contact/listSupervisorservicereport", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("listServicereport", res);

        if (res.data.status == true) {
          setsupervisorServiceContactList(res.data.data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingService(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setsupervisorServiceContactList([]);
          setPagination(0);
          setTimeout(() => {
            setLoadingService(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setTimeout(() => {
          setLoadingService(false);
        }, 1000);
      });
  };

  const agentCallcount = async () => {
    setLoadingAgent(true);
    let body = {
      offset: page3 == 1 ? 0 : (page3 - 1) * 10,
      limit: 10,
    };
    await axios
      .post(BaseUrl + "/contact/callcontactcount", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("agentCallcount", res);
        if (res.data.success == true) {
          setagentCount(res.data.Data);
          setPagination3(res.data.count);
          setTimeout(() => {
            setLoadingAgent(false);
          }, 1000);
        } else {
          setagentCount([]);
          setPagination3(0);
          setTimeout(() => {
            setLoadingAgent(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    listServicereport();
  }, [page]);

  useEffect(() => {
    listSalesreport();
  }, [page2]);

  useEffect(() => {
    agentCallcount();
  }, [page3]);

  const fetchData = () => {
    if (tableType === "Sales") {
      listSalesreport();
    } else {
      listServicereport();
    }
  };

  const handleChangeAgent = (event) => {
    setSelectedAgents(event.target.value);
    if (selectedSalesfile && event.target.value.length > 0) {
      setUploadButton(false);
    } else {
      setUploadButton(true);
    }
  };

  const handleChangeAgent2 = (event) => {
    setSelectedAgents(event.target.value);
    if (selectedServicefile && event.target.value.length > 0) {
      setUploadButton2(false);
    } else {
      setUploadButton2(true);
    }
  };

  return (
    <>
      <StyledPaper elevation={0}>
        <Stack spacing={1}>
          <Stack direction={"row"}>
            <Stack direction={"row"} spacing={1} sx={{ flexGrow: 1 }}>
              <Button
                size="small"
                id="primary-btn"
                variant={ServiceButton}
                onClick={() => {
                  setShowSupervisorfilter("sidebar_filter");
                  setSalesButton("outlined");
                  setServiceButton("contained");
                  setagentButton("outlined");
                  settableType("Sales");
                  clearFilterCache();
                  setfilterType("");
                  settableListType("serviceUpload");
                }}
              >
                Service
              </Button>
              <Button
                size="small"
                id="primary-btn"
                variant={SalesButton}
                onClick={() => {
                  setShowSupervisorfilter("sidebar_filter");
                  setSalesButton("contained");
                  setServiceButton("outlined");
                  setagentButton("outlined");
                  settableType("Service");
                  clearFilterCache();
                  setfilterType("");
                  settableListType("salesUpload");
                }}
              >
                Sales
              </Button>
              <Button
                size="small"
                id="agent-primary-btn"
                variant={agentButton}
                onClick={() => {
                  setSalesButton("outlined");
                  setServiceButton("outlined");
                  setagentButton("contained");
                  settableType("Service");
                  clearFilterCache();
                  setfilterType("Agent");
                  settableListType("agentUpload");
                }}
              >
                Agent Report
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <Button
                size="small"
                id="sales-primary-btn"
                variant="contained"
                onClick={() => {
                  setShowSupervisorfilter("sidebar_filter");
                  setSalesPreview(true);
                  AgentList();
                }}
              >
                Sales Upload
                <input
                  className="form-control"
                  id="form-control-file"
                  type="file"
                  ref={hiddenFileInput2}
                  onChange={handleConfirmSalesPreview}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Button>

              <Button
                size="small"
                id="service-primary-btn"
                variant="contained"
                // className="servicebutton"
                onClick={() => {
                  setShowSupervisorfilter("sidebar_filter");
                  AgentList();
                  setServicePreview(true);
                }}
              >
                Service Upload
                <input
                  className="form-control"
                  id="form-control-file"
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleConfirmServerPreview}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </Button>

              <Button
                variant="contained"
                id="filter-primary-btn"
                size="small"
                onClick={() => {
                  if (filterType == "Agent") {
                    setShowSupervisorAgentreports("sidebar_filter active-r");
                  } else {
                    setShowSupervisorfilter("sidebar_filter active-r");
                    AgentList();
                  }
                }}
              >
                Filter
              </Button>
            </Stack>
          </Stack>

          {(() => {
            switch (tableListType) {
              case "serviceUpload":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Agent Name", minWidth: 100 },
                                { label: "Customer Name", minWidth: 130 },
                                { label: "Mobile", minWidth: 100 },
                                { label: "Registration No", minWidth: 120 },
                                { label: "Model Description", minWidth: 140 },
                                { label: "Chassis No", minWidth: 100 },
                                { label: "Job Card", minWidth: 100 },
                                { label: "Email", minWidth: 100 },
                                { label: "Address2", minWidth: 140 },
                                { label: "Brand", minWidth: 50 },
                                { label: "Location", minWidth: 50 },
                                { label: "Invoice No", minWidth: 90 },
                                { label: "Invoice Date", minWidth: 100 },
                                { label: "Year", minWidth: 40 },
                                { label: "External Color", minWidth: 140 },
                                { label: "Customer Category", minWidth: 150 },
                                { label: "Service Advisor", minWidth: 140 },
                                { label: "Mileage", minWidth: 40 },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingService
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(18)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={60}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : supervisorServiceContactList.length > 0
                              ? supervisorServiceContactList.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowData.id}
                                  >
                                    <StyledTableCell2>
                                      {rowData.agent_id.username}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CUST_NAME}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.MOBILE}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.REG_NO}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Model_Description}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CHASSIS_NO}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Job_Card}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.EMAIL}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Address2}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Brand}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Location}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_Date}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.YEAR}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.External_Color}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.CUSTOMER_CATEGORY}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.ADVISOR}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.MILEAGE}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        size="small"
                        count={Math.ceil(pagination / 10)}
                        page={page}
                        onChange={handleChange}
                      />
                    </Stack>
                  </>
                );
              case "salesUpload":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Agent Name", minWidth: 100 },
                                { label: "Customer Name", minWidth: 130 },
                                { label: "Mobile", minWidth: 100 },
                                { label: "Registration No", minWidth: 130 },
                                { label: "Model Description", minWidth: 140 },
                                { label: "Chassis No", minWidth: 100 },
                                { label: "Email", minWidth: 120 },
                                { label: "Address2", minWidth: 40 },
                                { label: "Brand", minWidth: 40 },
                                { label: "Location", minWidth: 40 },
                                { label: "Invoice Branch", minWidth: 130 },
                                { label: "Invoice No", minWidth: 100 },
                                { label: "Invoice Date", minWidth: 100 },
                                { label: "Year", minWidth: 40 },
                                { label: "External Color", minWidth: 140 },
                                { label: "Customer Category", minWidth: 150 },
                                { label: "Salesman", minWidth: 40 },
                                { label: "Delivary Date", minWidth: 130 },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingSales
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(18)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={60}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : supervisorSalesContactList.length > 0
                              ? supervisorSalesContactList.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowData.id}
                                  >
                                    <StyledTableCell2>
                                      {rowData.agent_id.username}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Customer_Name}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Mobile}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Registration_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Model_Description}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Chassis_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Email}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.Address2}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Brand}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Location}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Inv_Brch}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Invoice_No}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Inv_Date}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Year}
                                    </StyledTableCell2>

                                    <StyledTableCell2>
                                      {rowData.External_Color}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Customer_Category}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Salesman}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.Delivery_Date}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        size="small"
                        count={Math.ceil(pagination2 / 10)}
                        page={page2}
                        onChange={handleChange2}
                      />
                    </Stack>
                  </>
                );
              case "agentUpload":
                return (
                  <>
                    <StyledPaper2 elevation={4}>
                      <TableContainer
                        sx={{ height: 400 }}
                        className="CONTACT-LIST-AREA"
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {[
                                { label: "Agent Name" },
                                { label: "Total Assign Call To Agent" },
                                { label: "Pending" },
                                { label: "Complete" },
                              ].map((item) => (
                                <StyledTableCell
                                  style={{ minWidth: item.minWidth }}
                                >
                                  {item.label}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {loadingAgent
                              ? [...Array(10)].map((_, rowIndex) => (
                                  <TableRow hover role="checkbox" tabIndex={-1}>
                                    {[...Array(4)].map((_, colIndex) => (
                                      <StyledTableCell2 key={colIndex}>
                                        <Skeleton
                                          variant="text"
                                          width={100}
                                          height={30}
                                        />
                                      </StyledTableCell2>
                                    ))}
                                  </TableRow>
                                ))
                              : agentCount.length > 0
                              ? agentCount.map((rowData) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={rowData._id}
                                  >
                                    <StyledTableCell2>
                                      {rowData.users.username}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.total}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.totalpendingcall}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {rowData.totalcompletecall}
                                    </StyledTableCell2>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledPaper2>
                    <Stack className="d-flex flex-row" justifyContent={"end"}>
                      <Pagination
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        size="small"
                        count={Math.ceil(pagination3 / 10)}
                        page={page3}
                        onChange={handleChange3}
                      />
                    </Stack>
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </Stack>
      </StyledPaper>

      {/* FILE UPLOAD */}
      <Dialog
        open={fileuploadPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          FILE UPLOAD
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack>{file ? file.name : file}</Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            id="upload-primary-btn"
            size="small"
            onClick={() => {
              UploadFile();
            }}
          >
            upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* SALESMODAL */}
      <Dialog
        open={Salespreview}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePreview}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Button
              variant="contained"
              id="file-btn"
              size="small"
              endIcon={<DownloadSimple size={15} />}
              onClick={() => {
                SaleshandleClick2();
              }}
            >
              Choose file
              <input
                type="file"
                ref={hiddenFileInput2}
                onChange={(event) => {
                  handleConfirmSalesPreview(event);
                }}
                style={{ display: "none" }}
              />
            </Button>

            <Box sx={{ width: "320px" }}>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-multiple-name-label">
                  Select Agent
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={selectedAgents} // Make sure selectedAgents is an array
                  onChange={handleChangeAgent} // Provide a function to handle changes
                  input={<OutlinedInput label="Select Agent" />}
                  MenuProps={{
                    style: {
                      top: "0px",
                      maxHeight: "350px",
                    },
                  }}
                >
                  {ListOfAgent.length > 0 ? (
                    ListOfAgent.map((Agent) => (
                      <MenuItem value={Agent.id} key={Agent.id}>
                        {Agent.username}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{"No Data"}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={0.5} sx={{ height: "40px", width: "450px" }}>
              <label
                htmlFor="formFileSm"
                className="form-label col-form-label-sm"
              >
                <div style={{ fontSize: "12px" }}>
                  {" "}
                  {selectedSalesfile ? selectedSalesfile.name : ""}
                </div>

                <p>
                  Please Download Sample Sheet{" "}
                  <a href={excelDownloadUrl + "/uploads/salessample.xlsx"}>
                    Link
                  </a>{" "}
                  Here
                </p>
              </label>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {spinner ? (
            <span
              className="spinner-border spinner-border-sm text-primary"
              role="status"
              aria-hidden="true"
              style={{
                flexDirection: "column",
                marginTop: "20%",
                display: "flex",
                position: "absolute",
                left: "45%",
                bottom: "30%",
              }}
            ></span>
          ) : (
            <Button
              variant="contained"
              id="upload-primary-btn"
              size="small"
              endIcon={<DownloadSimple size={15} />}
              onClick={() => {
                if (selectedSalesfile) {
                  SaleshandleChangeFile(selectedSalesfile);
                  setSelectedSalesfile(null);
                }
              }}
              disabled={uploadButton}
            >
              Upload
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* SERVICEMODAL */}
      <Dialog
        open={Servicepreview}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosePreview2}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 5,
            padding: 1,
            top: -100,
          },

          backgroundColor: "rgb(0 0 0 / 65%)",
        }}
      >
        <DialogTitle
          variant="subtitle2"
          sx={{ fontSize: "16px", fontWeight: 600 }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Button
              variant="contained"
              id="file-btn"
              size="small"
              endIcon={<DownloadSimple size={15} />}
              onClick={() => {
                ServicehandleClick();
              }}
            >
              Choose file
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleConfirmServerPreview}
                style={{ display: "none" }}
              />
            </Button>
            <Box sx={{ width: "320px" }}>
              <FormControl sx={{ m: 1, width: 300 }} size="small">
                <InputLabel id="demo-multiple-name-label">
                  Select Agent
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={selectedAgents} // Make sure selectedAgents is an array
                  onChange={handleChangeAgent2} // Provide a function to handle changes
                  input={<OutlinedInput label="Select Agent" />}
                  MenuProps={{
                    style: {
                      top: "0px",
                      maxHeight: "350px",
                    },
                  }}
                >
                  {ListOfAgent.length > 0 ? (
                    ListOfAgent.map((Agent) => (
                      <MenuItem value={Agent.id} key={Agent.id}>
                        {Agent.username}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{"No Data"}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={0.5} sx={{ height: "40px", width: "450px" }}>
              <label for="formFileSm" className="form-label col-form-label-sm">
                <div style={{ fontSize: "12px" }}>
                  {" "}
                  {selectedServicefile ? selectedServicefile.name : ""}
                </div>

                <p>
                  Please Download Sample Sheet{" "}
                  <a href={excelDownloadUrl + "/uploads/servicesample.xlsx"}>
                    Link
                  </a>{" "}
                  Here
                </p>
              </label>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {spinner ? (
            <span
              className="spinner-border spinner-border-sm text-primary"
              role="status"
              aria-hidden="true"
              style={{
                flexDirection: "column",
                marginTop: "20%",
                display: "flex",
                position: "absolute",
                left: "45%",
                bottom: "30%",
              }}
            ></span>
          ) : (
            <Button
              variant="contained"
              id="upload-primary-btn"
              size="small"
              endIcon={<DownloadSimple size={15} />}
              onClick={() => {
                if (selectedServicefile) {
                  ServicehandleChangeFile(selectedServicefile);
                  setSelectedServicefile(null);
                }
              }}
              disabled={uploadButton2}
            >
              Upload
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, { setDialedNumberInc })(
  SupervisorContactNew
);
