import { toast } from "react-toastify";
import {
  setCallId,
  setShowMute,
  setShowUnMute,
} from "../../redux/actions/actions";
import "./callmoduleassets/AWL-min/awl.min.js";
import { store } from "../../redux/store";

const reduxstate = store.getState();

var callObj1 = null,
  callObj2 = null;
var callmap = {};
var cli;
cli = new AWL.client();
cli.enableLogging();

export const configuration = () => {
  var appInstanceId = cli.generateAppInstanceID();

  var cfg = {
    serviceType: "phone",
    enableVideo: true,
    Gateway: { ip: "192.168.53.29", port: "5060" },

    Stunserver: { ip: "", port: "3478" },
    Turnserver: { ip: "", port: "3478", user: "5104", pwd: "Avaya1234$" },
    AppData: {
      applicationID: "xxxx12345",
      applicationUA: "sdktestclient-3.0.0",
      appInstanceID: appInstanceId,
    },
    disableResiliency: false,
  };

  var tags = {
    localVideo: "lclVideo",
    remoteVideo: "rmtVideo",
  };
  var onCallListener = new CallListener();
  function onAuthTokenRenewed(resp) {
    if (resp.result == "AWL_MSG_TOKEN_RENEW_SUCCESS") {
    } else {
      console.log(
        "\n onAuthTokenRenewed:: Token renewal failed. reason: " + resp.reason
      );
    }
  }

  function onConfigChanged(resp) {}

  function onRegistrationStateChanged(resp) {
    if (resp.result === "AWL_MSG_LOGIN_SUCCESS") {
      toast.success("Avaya Login Sucess", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      var alternateServer = cli.getAlternateServerConfig();

      if (alternateServer !== null) {
        console.log(
          "Alternate Server:: IP : " +
            alternateServer.address +
            "\tDomain: " +
            alternateServer.domain +
            "\tPort: " +
            alternateServer.port +
            "\tServer Type: " +
            alternateServer.serverType
        );
      } else {
        console.log(
          "Resiliency is either not supported or not enabled at the server"
        );
      }
    } else if (resp.result == "AWL_MSG_LOGIN_FAILED") {
      toast.error("Extension Already In Use", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }

  if (
    cli.setConfiguration(
      cfg,
      onConfigChanged,
      onRegistrationStateChanged,
      onCallListener,
      onAuthTokenRenewed
    ) === "AWL_MSG_SETCONFIG_SUCCESS"
  ) {
  }

  if (cli.setDomElements(tags) === "AWL_MSG_SETDOM_FAILED") {
  } else {
  }

  cli.logIn(
    localStorage.getItem("agentLoginTerminalId")
      ? localStorage.getItem("agentLoginTerminalId")
      : "",
    "222444666",
    "false"
  );
};

var CallListener = function () {
  var _onNewIncomingCall = function (callId, callObj, autoAnswer) {
    console.log(
      "onNewIncomingCall : getFarEndNumber = " + callObj.getFarEndNumber()
    );
    console.log(
      "onNewIncomingCall : getFarEndName = " + callObj.getFarEndName()
    );
    console.log("onNewIncomingCall : getSipUri = " + callObj.getSipUri());

    localStorage.setItem("callId", callObj.getCallId());
    // store.dispatch(setCallId(callObj.getCallId()));

    if (typeof callmap[callId] === "undefined") {
      if (callObj1 === null) {
        callObj1 = callObj;
        callmap[callObj1.getCallId()] = callObj1;
      } else {
      }
    }
  };

  var _onCallStateChange = function (callId, callObj, event) {
    console.log("\nSDK TEST: call Id " + callObj.getCallId());
    for (var key in callmap) {
    }

    if (callObj1 != null && callObj.getCallId() === callObj1.getCallId()) {
      console.log("\n callObj1: callstate: " + callObj1.getCallState());
      switch (callObj1.getCallState()) {
        case "AWL_MSG_CALL_IDLE":
          break;
        case "AWL_MSG_CALL_CONNECTED":
          break;
        case "AWL_MSG_CALL_RINGING":
        case "AWL_MSG_CALL_DISCONNECTED":
          break;
        case "AWL_MSG_CALL_FAILED":
          break;
        case "AWL_MSG_CALL_INCOMING":
          break;
        case "AWL_MSG_CALL_HELD":
          break;
        case "AWL_MSG_CALL_FAREND_UPDATE":
          break;
        default:
      }
    }
    console.log(
      "\nonCallStateChanged: Total Calls = " + Object.keys(callmap).length
    );
  };

  var _onCallTerminate = function (callId, reason) {
    if (callObj1 != null && callObj1.getCallId() === callId) {
      delete callmap[callObj1.getCallId()];
      callObj1 = null;
    } else {
    }
  };

  return {
    onNewIncomingCall: _onNewIncomingCall,
    onCallStateChange: _onCallStateChange,
    onCallTerminate: _onCallTerminate,
  };
};

export const mutecall = () => {
  let callid = localStorage.getItem("callId");
  store.dispatch(setShowMute(false));
  store.dispatch(setShowUnMute(true));
  if (cli.doMute(callid)) {
  }
};

export const unmutecall = () => {
  let callid = localStorage.getItem("callId");
  store.dispatch(setShowMute(true));
  store.dispatch(setShowUnMute(false));
  if (cli.doUnMute(callid)) {
  }
};

export const unhold_call = () => {
  cli.doUnHold(callId);
};

export const hold_call = () => {
  cli.doHold(callId);
};

export const end_call = (event) => {
  event.preventDefault();
  cli.dropCall(callId);
  window.location.reload();
};

export const answerIncCall = (e) => {
  pauseAudio();
  cli.answerCall(callId);
};

export const make_transfer_call = () => {
  cli.transferCall(dailedNumber, callId, "unAttended");
};

export const rejectInCall = () => {
  pauseAudio();
  cli.dropCall(callId);
};
