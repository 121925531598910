import React, { useEffect, useRef } from "react";

import { BaseUrl } from "../../container/BaseUrl/BaseUrl";

import * as pbi from "powerbi-client";

import axios from "axios";

const Powerbi = () => {
  const reportContainerRef = useRef(null);

  const fetchEmbedData = async () => {
    const access_token = localStorage.getItem("access_token");

    const tenantId = localStorage.getItem("TenantId");

    try {
      const response = await axios.get(
        BaseUrl + "/powerBi/getEmbedTokenV2",

        {
          headers: {
            Authorization: "Bearer " + access_token,

            "Content-Type": "application/json",

            tenantId: tenantId,
          },
        }
      );

      const embedData = response;

      const models = pbi.models;

      const reportLoadConfig = {
        type: "report",

        tokenType: models.TokenType.Embed,

        accessToken: embedData.data.accessToken,

        embedUrl: embedData.data.embedUrl[0].embedUrl,
      };

      const report = window.powerbi.embed(
        reportContainerRef.current,

        reportLoadConfig
      );

      report.off("loaded");

      report.on("loaded", () => {});

      report.off("rendered");

      report.on("rendered", () => {});

      report.off("error");

      report.on("error", (event) => {
        const errorMsg = event.detail;

        return;
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchEmbedData();
  }, []);

  return (
    <>
      <div
        ref={reportContainerRef}
        id="report-container"
        style={{
          width: "96%",

          height: "97vh",

          position: "relative",

          top: "-40px",

          left: "50px",
        }}
      />
    </>
  );
};

export default Powerbi;
