import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

const Cms = () => {
  const [currentUrl, setcurrentUrl] = useState("");
  const uid = uuidv4();

  useEffect(() => {
    document.cookie = `our_cookie_name=${uid}`;
    const iframe = document.getElementById("CMS");
    if (iframe) {
      iframe.contentWindow.postMessage({ cookieData: document.cookie }, "*");
    }
    getIframeUrl();
  }, []);
  const getIframeUrl = async () => {
    const ticketcrm=JSON.parse(localStorage.getItem("ticketcrm"));
    if (ticketcrm) {
      setcurrentUrl(ticketcrm.data);
      document.cookie = `uid=${ticketcrm.uid}`;
      const iframe = document.getElementById("CMS");
      if (iframe) {
        iframe.contentWindow.postMessage({ cookieData: document.cookie }, "*");
      }
    }
  };

  return (
    <div
      className="d-flex justify-content-center h-100"
      style={{ width: "97%", marginLeft: "46px" }}
    >
      <iframe
        id="CMS"
        src={currentUrl}
        style={{ width: "99vw", height: "94vh" }}
      ></iframe>
    </div>
  );
};

export default Cms;
