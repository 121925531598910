import { combineReducers } from "redux";
import { appReducer } from "./app/reducer";
import { notificationReducer } from "./app/notification.reducer";
import { contactListReducer } from "./app/contactList.reducer";


export const rootReducer = combineReducers({
  data: appReducer,
  notification: notificationReducer,
  contactListData: contactListReducer,
});
