import React from "react";
import { Badge } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { connect } from "react-redux";
import {
  setCannedmessagediv,
  setCustomerDetailsShow,
} from "../../../../redux/actions/actions";
import { randomColors } from "../../colorpallet";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    internalChatActive: data.internalChatActive,
    searchterm: data.searchterm,
    agentList: state.data.agentList,
  };
};
const InternalAgentList = (props) => {
  const {
    onclickhandlechangeinternalContact,
    createInternalChat,
    onclickhandlechangeinternalContactCreateSession,
  } = props;

  const agentId = JSON.parse(localStorage.getItem("tokenAgent"));

  return (
    <>
      {props.agentList.length > 0 ? (
        props.agentList
          .filter((val) => {
            if (props.searchterm == "") {
              return val;
            } else if (val.reciverDetails) {
              var result = val.reciverDetails[0].username
                .toLowerCase()
                .includes(props.searchterm.toLowerCase());
              if (result === true && agentId.id !== val.reciverDetails[0]._id) {
                return val;
              } else {
                var result = val.senderDetails[0].username
                  .toLowerCase()
                  .includes(props.searchterm.toLowerCase());
                if (
                  result === true &&
                  agentId.id !== val.senderDetails[0]._id
                ) {
                  return val;
                }
              }
            } else if (
              val.username
                .toLowerCase()
                .includes(props.searchterm.toLowerCase())
            ) {
              return val;
            }
          })
          .map((val, index) => {
            return (
              <div
                key={index}
                id="pill-tabs"
                className={
                  props.internalChatActive === val.id
                    ? "chat-contact d-flex p-1 justify-content-evenly align-items-center chat-active "
                    : "chat-contact d-flex p-1 justify-content-evenly align-items-center border-bottom"
                }
                onClick={() => {
                  if (val.chat_session_id) {
                    props.setCustomerDetailsShow(false);
                    props.setCannedmessagediv(false);
                    onclickhandlechangeinternalContact(val, index);
                  } else {
                    createInternalChat(val.user_id);
                    onclickhandlechangeinternalContactCreateSession(val, index);
                  }
                }}
                style={{ borderRadius: "10px" }}
              >
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex me-1">
                    <div
                      className="col-2 me-2 photo position-relative"
                      style={{
                        background: randomColors[index],
                      }}
                    >
                      <p
                        className=" chat_name"
                        style={{
                          color: "#7e7272",
                          textAlign: "center",
                          fontSize: "16px",
                          marginTop: "10px",
                        }}
                      >
                        <b
                          className="h-100"
                          style={{
                            color: "#FFFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {val.reciverDetails
                            ? val.reciverDetails[0]._id !== agentId.id
                              ? val.reciverDetails[0].username
                                  .slice(0, 2)
                                  .toUpperCase()
                              : val.senderDetails[0].username
                                  .slice(0, 2)
                                  .toUpperCase()
                            : ""}
                        </b>
                      </p>
                      {val.unreadcount != 0 && (
                        <Badge
                          bg="danger"
                          className="mt-1"
                          style={{
                            borderRadius: "50%",
                            fontSize: "8px",
                            position: "relative",
                            bottom: "5px",
                          }}
                        >
                          {val.unreadcount}
                        </Badge>
                      )}
                    </div>
                    <div className="chat-content-main d-flex">
                      <div className="chat-details me-1">
                        <div className="d-flex align-items-center">
                          <div className="name d-inline-block text-truncate me-1" style={{maxWidth:'150px'}}>
                            {val.reciverDetails
                              ? val.reciverDetails[0]._id !== agentId.id
                                ? val.reciverDetails[0].username
                                : val.senderDetails[0].username
                              : val.username}
                          </div>

                          <div
                            className={`${
                              val.reciverDetails
                                ? val.reciverDetails[0]._id != agentId.id
                                  ? val.reciverDetails[0].agent_status ===
                                    "Available"
                                    ? "online"
                                    : val.reciverDetails[0].agent_status ===
                                      "Connected"
                                    ? "offline"
                                    : val.reciverDetails[0].agent_status ===
                                      "Not Available"
                                    ? "discont"
                                    : ""
                                  : val.senderDetails[0].agent_status ===
                                    "Available"
                                  ? "online"
                                  : val.senderDetails[0].agent_status ===
                                      "Connected" ||
                                    val.senderDetails[0].agent_status === "Busy"
                                  ? "offline"
                                  : val.senderDetails[0].agent_status ===
                                    "Not Available"
                                  ? "discont"
                                  : ""
                                : val.agent_status === "Available"
                                ? "online"
                                : val.agent_status === "Connected" ||
                                  val.agent_status === "Busy"
                                ? "offline"
                                : val.agent_status === "Not Available"
                                ? "discont"
                                : ""
                            }`}
                          ></div>
                        </div>
                        <p className="d-inline-block text-truncate mb-0">
                          {val.lastmessage}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="chat-time d-flex justify-content-start flex-column align-items-end">
                    <p className="mb-0">{val.lastmessagetime}</p>
                  </div>
                </div>
              </div>
            );
          })
      ) : (
        <>
          <div
            className="text-muted d-flex justify-content-center"
            style={{ flexDirection: "column", marginTop: "170px" }}
          >
            <span className="mx-auto">
              <RiErrorWarningLine size={30} />
            </span>
            <span className="mx-auto">
              <h6 className="text-muted mt-2" style={{ fontFamily: "poppins" }}>
                No interaction activities
              </h6>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setCannedmessagediv,
  setCustomerDetailsShow,
})(InternalAgentList);
