import React, { useEffect, useState } from "react";

import { setExternalChatData } from "../../redux/actions/actions";
import { connect } from "react-redux";
import { BaseUrl, newBaseUrlLang } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";

const mapStateToProps = (state) => {
  console.log("state.data.externalChatData", state.data);
  return {
    externalChatData: state.data.externalChatData,
  };
};

const MultimediaFilter = (props) => {
  const [basedonchannel, setBasedonchannel] = useState("");
  const [skilldropdown, setSkilldropdown] = useState([]);
  const [agentlist, setAgentlist] = useState([]);

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const isToDateValid = () => {
    return new Date(props.todate) >= new Date(props.fromdate);
  };

  useEffect(() => {
    skillsetDropdown();
    if (basedOnRole.role == "Supervisor") {
      agentListdropdown();
    }
  }, []);

  const skillsetDropdown = () => {
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/skills/list", data, {
        headers: {
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        console.log("res");
        setSkilldropdown(res.data.dataList);
      })
      .catch((error) => {
        // errorHandel(error, "/skills/list")
      });
  };

  const agentListdropdown = async () => {
    await axios
      .post(
        BaseUrl + "/users/listagentList",
        {
          user_id: basedOnRole.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        //
        //
        setAgentlist(res.data.data);
      })
      .catch((err) => {
        setShow(true);
      });
  };

  return (
    <>
      <nav className={props.showfilter} style={{ top: "55px" }}>
        <div className="filter col-md-12 shadow p-4 ">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="mb-0">Filters</p>
            <div className="dismiss ">
              <span
                id="session_filter_close_btn"
                className="material-symbols-outlined"
                onClick={() => {
                  props.setshowfilter("sidebar_filter");
                }}
              >
                cancel
              </span>
            </div>
          </div>

          <div className="filter_form">
            <form className=" fillter_form2 " style={{ height: "68vh" }}>
              <div className="custom_div">
                {basedOnRole.role == "Supervisor" ? (
                  <div className="mb-3 d-flex flex-column text-start">
                    <label htmlFor="Select" className="form-label">
                      Agent
                    </label>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      onChange={(e) => props.seAagent(e.target.value)}
                    >
                      {agentlist.length > 0
                        ? agentlist.map((item) => {
                            console.log("list", item);
                            return (
                              <option value={item.username}>
                                {item.username}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                <div className="mb-3 d-flex flex-column text-start">
                  <label>Name</label>
                  <input
                    type="text"
                    value={props.name}
                    onChange={(e) => props.setName(e.target.value)}
                    className="form-control form-select-sm"
                  />
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label>Email</label>
                  <input
                    type="text"
                    value={props.email}
                    onChange={(e) => props.setEmail(e.target.value)}
                    className="form-control form-select-sm"
                  />
                </div>
                {/* 
                <div className="mb-3 d-flex flex-column text-start">
                    <label>Mail To</label>
                    <input type="text"  className="form-control form-select-sm"/>
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                    <label>Mail From</label>
                    <input type="text"  className="form-control form-select-sm"/>
                </div> */}

                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="fromdate" className="form-label">
                    From
                  </label>
                  <input
                    type="date"
                    className="form-control form-select-sm"
                    id=""
                    aria-describedby="date"
                    value={props.fromdate}
                    onChange={(e) => props.setFromdate(e.target.value)}
                    max={defaultValue}
                  />
                </div>
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="todate" className="form-label">
                    To
                  </label>
                  <input
                    type="date"
                    className="form-control form-select-sm"
                    id=""
                    aria-describedby="date"
                    value={props.todate}
                    onChange={(e) => props.setTodate(e.target.value)}
                    max={defaultValue}
                  />
                  {!isToDateValid() && (
                    <span
                      className="text-danger fw-bold"
                      style={{ fontSize: "10px" }}
                    >
                      To Date cannot be less than From Date*
                    </span>
                  )}
                </div>
              </div>

              <div className="mb-3 d-flex flex-column text-start">
                <label htmlFor="Select" className="form-label">
                  Channel
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setBasedonchannel(e.target.value);
                    props.setChannel(e.target.value);
                  }}
                >
                  <option value="">All Channel</option>
                  <option value="webchat">Webchat</option>
                  <option value="email">Email</option>
                  <option value="videochat">Videochat</option>
                  <option value="voice">Voice</option>
                  <option value="from_whatsapp">Whatsapp</option>
                </select>
              </div>

              {basedonchannel == "email" ? (
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="Select" className="form-label">
                    Catagory
                  </label>
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    onChange={(e) => props.setSkillset(e.target.value)}
                  >
                    <option value="">Select Skillset</option>
                    <option value="Toner Issue">Toner Issue</option>
                    <option value="Network Problems"> Network Problems</option>
                    <option value="Network Resolved"> Network Resolved</option>
                    <option value="Password Reset"> Password Reset</option>
                    <option value="Software Installation">
                      Software Installation
                    </option>
                    <option value="Hardware Issues">Hardware Issues</option>
                    <option value="Communication">Communication</option>
                    <option value="Customer-care">CustomerCare</option>
                  </select>
                </div>
              ) : (
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="Select" className="form-label">
                    Catagory
                  </label>
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    onChange={(e) => props.setSkillset(e.target.value)}
                  >
                    {skilldropdown.map((item) => {
                      return (
                        <>
                          {/* <option value=""></option> */}
                          <option value={item.skillName}>
                            {item.skillName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="mb-3 d-flex flex-column text-start">
                <label htmlFor="Select" className="form-label">
                  Status
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  onChange={(e) => props.setStatus(e.target.value)}
                >
                  <option value="">Status</option>
                  <option value="Accept">Accept</option>
                  <option value="chatEnded"> ChatEnded</option>
                  <option value="newjoin"> New Join</option>
                </select>
              </div>

              {basedonchannel == "email" ? (
                <div className="mb-3 d-flex flex-column text-start">
                  <label htmlFor="Select" className="form-label">
                    Search
                  </label>

                  <div className="mt-2 d-flex flex-column text-start">
                    <input
                      type="text"
                      onChange={(e) => {
                        props.setsearchBySubject(e.target.value);
                      }}
                      className="form-control form-control-sm"
                      placeholder="Enter Subject..."
                    />
                  </div>

                  <div className="mt-2 d-flex flex-column text-start">
                    <input
                      type="text"
                      onChange={(e) => props.setsearchByBody(e.target.value)}
                      className="form-control form-control-sm"
                      placeholder="Search By Keywords..."
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
            <div className="filter_submit">
              <div className="d-flex">
                <div className="col-md-12 ps-2">
                  <button
                    type="button"
                    id="session_filter_Submit"
                    className="btn btn-primary w-100 text-white"
                    onClick={() => {
                      if (isToDateValid()) {
                        props.setPage(1);
                        props.multiMediaSearch();
                        props.setshowfilter("sidebar_filter");
                      }
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default connect(mapStateToProps, {
  setExternalChatData,
})(MultimediaFilter);
