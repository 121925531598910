import React, { useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { setExternalChatData,setUserlistShow} from "../../../redux/actions/actions";
import Foroutsideclick from "../../outside-click/Foroutsideclick";

// Component
import WhatsappModal from "./chat-component-model/Whatsapp-model";
import Endchatmodel from "./chat-component-model/End-chat-model";
import Notification from "./chat-component-model/Notification";
import { MdNotificationsNone } from "react-icons/md";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    externalChatData: data.externalChatData,
    userlistshow:data.userlistshow

  };
};
const VoiceMenu = (props) => {
  const { toggleCard, OpenDialog, handleClose } = props;
  const [whatsappModalDialogOpen, setwhatsappModalDialogOpen] = useState(false);
  const [notificationDialog, setnotificationDialog] = useState(false);
  const [endchatModalDialog, setendchatModalDialog] = useState(false);

  // Tooltip
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const transferToWhatsappDialogClose = () => {
    setwhatsappModalDialogOpen(true);
  };

  const NotificationHandleDialogClose = () => {
    setnotificationDialog(true);
  };

  const EndChatDialogClose = () => {
    setendchatModalDialog(true);
  };
  return (
    <>
      <Foroutsideclick id="outside-click" onClickOutside={toggleCard}>
        <div
          className="menucard3"
          style={{ right: props.userlistshow ? "29.7rem" : "4rem" }}
        >
          <BootstrapTooltip title="Close Menu" arrow placement="top">
            <button
              type="button"
              id="close-menu-toggle"
              className="btn border-0 btn-sm closeMenucard3"
              data-tippy-content=" Close Menucard"
              onClick={toggleCard}
            ></button>
          </BootstrapTooltip>
          {JSON.parse(localStorage.getItem("permission")).find(
            (module) =>
              module.moduleName === "vioce" &&
              module.screen.find(
                (element) =>
                  element.screenId === "TRANSFER_WHATSAPP" &&
                  element.read === true &&
                  element.write === true
              )
          ) ? (
            <BootstrapTooltip
              title="Transfer to Whatsapp"
              arrow
              placement="bottom"
            >
              <button
                type="button"
                id="transfer-whatsapp"
                className="btn btn-outline-secondary border-0 btn-sm"
                data-tippy-content=" Screen Share"
                onClick={() => {
                  transferToWhatsappDialogClose();
                }}
              >
                <BsWhatsapp className="icon_top-btn_small" size={15} />
              </button>
            </BootstrapTooltip>
          ) : (
            ""
          )}

          {JSON.parse(localStorage.getItem("permission")).find(
            (module) =>
              module.moduleName === "vioce" &&
              module.screen.find(
                (element) =>
                  element.screenId === "NOTIFICATION" &&
                  element.read === true &&
                  element.write === true
              )
          ) ? (
            <BootstrapTooltip title="Notification" arrow placement="bottom">
              <button
                type="button"
                id="notification-tab"
                className="btn btn-outline-secondary border-0 btn-sm"
                data-tippy-content=" Screen Share"
                onClick={() => {
                  setnotificationDialog(true);
                }}
              >
                <MdNotificationsNone className="icon_top-btn_small" size={20} />
              </button>
            </BootstrapTooltip>
          ) : (
            ""
          )}

          <BootstrapTooltip title="End Interaction" arrow placement="bottom">
            <button
              type="button"
              id="endchat_dialog"
              className="btn btn-outline-secondary border-0 btn-sm"
              data-tippy-content=" Screen Share"
              onClick={() => {
                EndChatDialogClose();
              }}
            >
              <AiOutlineCloseCircle className="icon_top-btn_small" size={18} />
            </button>
          </BootstrapTooltip>
        </div>
      </Foroutsideclick>
      {/* All Modal */}
      <WhatsappModal
        OpenDialog={whatsappModalDialogOpen}
        handleClose={() => setwhatsappModalDialogOpen(false)}
      />

      <Endchatmodel
        OpenDialog={endchatModalDialog}
        handleClose={() => setendchatModalDialog(false)}
      />

      <Notification
        OpenDialog={notificationDialog}
        handleClose={() => setnotificationDialog(false)}
      />
    </>
  );
};

export default connect(mapStateToProps, {
  setExternalChatData,
  setUserlistShow
})(VoiceMenu);
