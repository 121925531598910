import React, { Component } from "react";
import _ from "lodash";
import {
  Grid,
  Paper,
  IconButton,
  Divider,
  Typography,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import avatar from "../Resources/images/imageicon1.png";
import reply from "../Resources/images/reply2.png";
import replyall from "../Resources/images/replyall.png";
import forward from "../Resources/images/forward.png";
import pdf from "../Resources/images/pdf.png";
import txt from "../Resources/images/txt.png";
import word from "../Resources/images/word.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import parse from "html-react-parser";
import "../Resources/css/email.scss";
import "../Resources/css/images.scss";
import "../Resources/css/util.css";
import { IoMdOpen } from "react-icons/io";
import { HiOutlineTicket } from "react-icons/hi";
import { FiCheckCircle } from "react-icons/fi";
import SidebarComp from "./SidebarComp";

import Draggable from "react-draggable";
import { MdOutlineClose } from "react-icons/md";
import { FaArrowUp } from "react-icons/fa";
import { BsJournalCheck } from "react-icons/bs";
import { RiMailForbidLine } from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BaseUrl } from "../../../container/BaseUrl/BaseUrl";
import NewEmail from "./NewEmail";

const agentId = JSON.parse(localStorage.getItem("tokenAgent"));

export default class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameObj: { first: "Belvin" },
      name: "Bell ",
      inboxContent: {},
      inboxContentAttchment: props.InboxEmailContent,
      inboxContentBody: props.InboxEmailBody,
      inboxDataList: [],
      isOpen: false,
      escalationModal: false,
      id: [],
      supervisorList: [],
      inputValue: "",
      selectedValue: "",
      toSendlist: [],
      toCCSendlist: [],
      toBCCSendlist: [],
      emailSubject: "",
    };
  }

  componentDidMount() {
    this.props.InboxList();
    this.listOfSupervisor();

  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.InboxEmailContent, this.props.InboxEmailContent)) {
      this.setState({ inboxContentAttchment: this.props.InboxEmailContent });
    }
    if (!_.isEqual(prevProps.InboxEmailBody, this.props.InboxEmailBody)) {
      this.setState({ inboxContentBody: this.props.InboxEmailBody });
    }
  }

  handleClick = (value) => {
    
    if (!_.isEmpty(value)) {
      let obj = {
        id: value._id,
      };
      this.setState({ inboxContent: value, id: value });
      // this.setState({ inboxContent: value})
      this.props.InboxMailShow(obj);
      this.props.closeWriteEmail();
    }
    // Moment.globalFormat.titleFormat="D MMM YYYY";
  };

  openActionView = (e, value) => {
    this.setState({
      actionView: true,
      anchorEl: e.currentTarget,
      valueIndex: value,
    });
  };
  closeActionView = () => {
    this.setState({ actionView: false, anchorEl: null, valueIndex: {} });
  };

  downloadDoc = () => {
    const { valueIndex } = this.state;
    if (!_.isEmpty(valueIndex)) {
      let obj = {
        url: valueIndex.url,
        extension: valueIndex.extension,
      };
      this.props.DownloadAttachment(obj, valueIndex.filename);

      // let file = 'https://gway.release.inaipi.ae/email/files/download/file_53264.xlsx'
      // FileSaver.saveAs(file,'name.txt');

      // const link = document.createElement('a');
      // link.href = 'https://gway.release.inaipi.ae/email/files/download/file_53264.xlsx';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      // const url = 'https://gway.release.inaipi.ae/email/files/download/file_53264.xlsx'
      // url.blob().then(function (myBlob) {
      //     window.URL = window.webkitURL || window.URL;
      //     var a = document.createElement('a');
      //     a.href = window.URL.createObjectURL(myBlob);
      //     a.download = "name.xlsx"
      //     a.click();
      //  });
    }
  };
  openDocument = () => {
    const { valueIndex } = this.state;
    if (!_.isEmpty(valueIndex)) {
      // window.open('https://www.codexworld.com', '_blank');
      window.open(valueIndex.url, "_blank");
    }
  };

  onClickReply = () => {
    const { inboxContent } = this.state;
    const temp = [];
    const tempCC = [];
    const tempBCC = [];
    const subject = "";
    if (!_.isEmpty(inboxContent)) {
      let tempStr = inboxContent.fromList;
      let tempArr = tempStr.split(",");
      _.map(tempArr, (val, i) => {
        const fromEmail = val.slice(val.indexOf("<") + 1, val.lastIndexOf(">"));
        temp.push(fromEmail);
      });
    }
    
    this.setState({
      toSendlist: temp,
      toCCSendlist: tempCC,
      toBCCSendlist: tempBCC,
      emailSubject: inboxContent.subject,
    });
    this.props.openReplyEmail();
    this.handleCloseInboxDetailsDiv();
  };

  onClickReplyAll = () => {
    const { inboxContent } = this.state;
    const temp = [];
    const tempCC = [];
    const tempBCC = [];
    if (!_.isEmpty(inboxContent)) {
      let tempStr = inboxContent.fromList;
      let tempStrcc = inboxContent.fromList;
      let tempStrbcc = inboxContent.fromList;
      let tempArr = tempStr.split(",");
      let tempArrcc = tempStrcc.split(",");
      let tempArrbcc = tempStrbcc.split(",");
      _.map(tempArr, (val, i) => {
        const fromEmail = val.slice(val.indexOf("<") + 1, val.lastIndexOf(">"));
        temp.push(fromEmail);
      });
      _.map(tempArrcc, (val, i) => {
        const fromEmail = val.slice(val.indexOf("<") + 1, val.lastIndexOf(">"));
        tempCC.push(fromEmail);
      });
      _.map(tempArrbcc, (val, i) => {
        const fromEmail = val.slice(val.indexOf("<") + 1, val.lastIndexOf(">"));
        tempBCC.push(fromEmail);
      });
    }
    this.setState({
      toSendlist: temp,
      toCCSendlist: tempCC,
      toBCCSendlist: tempBCC,
    });
    this.props.openReplyEmail();
    this.handleCloseInboxDetailsDiv();
  };

  EmailAttachmentContent(data) {
    const imageFormat = [
      "png",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "svg",
      "gif",
    ];
    if (!_.isEmpty(data.attachments)) {
      return (
        <>
          <Grid container spacing={1}>
            {_.map(
              _.filter(data.attachments, { location: "attachment" }),
              (val, i) => {
                if (imageFormat.includes(val.extension)) {
                  return (
                    <Grid item xs={3}>
                      <div
                        className="attachment_container"
                        id="mail"
                        onClick={(e) => this.openActionView(e, val)}
                      >
                        <img
                          src={val.url}
                          alt="Avatar"
                          className="image_attachment"
                        />
                        <div className="attachment_overlay">
                          <div className="attachment_text">
                            {val.filename}
                            {/* <span className='float-r'> <IconButton className='text-white' onClick={(e)=>this.openActionView(e,val)} > <ExpandMoreIcon /> </IconButton>  </span> */}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                }
              }
            )}
          </Grid>

          <Grid container spacing={1}>
            {_.map(
              _.filter(data.attachments, { location: "attachment" }),
              (val, i) => {
                if (!imageFormat.includes(val.extension)) {
                  return (
                    <Grid item xs={4}>
                      <Paper>
                        <MenuItem>
                          <ListItemIcon className="attachment_Preview_image">
                            <img
                              src={
                                val.extension === "pdf"
                                  ? pdf
                                  : val.extension === "docx"
                                  ? word
                                  : val.extension === "xls"
                                  ? word
                                  : txt
                              }
                              height="20px"
                              weight="20px"
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <span className="attachment_el_text">
                              {val.filename}
                            </span>
                          </ListItemText>
                          <Typography variant="body2" color="text.secondary">
                            <IconButton
                            id="mail"
                              onClick={(e) => this.openActionView(e, val)}
                            >
                              {" "}
                              <ExpandMoreIcon />{" "}
                            </IconButton>
                          </Typography>
                        </MenuItem>
                      </Paper>
                    </Grid>
                  );
                }
              }
            )}
          </Grid>
        </>
      );
    }
  }

  handleOpenInboxDetailsDiv = () => {
    
    this.setState({ isOpen: true });
    // this.setState({id:val})
  };

  handleCloseInboxDetailsDiv = () => {
    this.setState({ isOpen: false });
  };

  handleOpenEscalationModal = () => {
    
    this.setState({ escalationModal: true });
    // this.setState({id:val})
  };

  handleCloseEscalationModal = () => {
    this.setState({ escalationModal: false });
  };

  completedEmail = () => {
    const data = {
      id: this.state.id._id,
      status: "completed",
      requestID: this.state.id.uid,
    };
    axios
      .post("https://gway.release.inaipi.ae/email/email/completedEmails", data)
      .then((response) => {
        if (response) {
          this.props.InboxList();
          this.setState({ inboxContentBody: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          
          this.handleCloseInboxDetailsDiv();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  listOfSupervisor = async () => {
    const tenantId = localStorage.getItem("TenantId");

    try {
      let userId = JSON.parse(localStorage.getItem("tokenAgent"));
      let passUserId = userId.user_id;
      const data = await axios.post(
        BaseUrl + "/users/listSupervisor",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: tenantId,
          },
        }
      );
      if (data.data.status) {
        // props.setavailagent(data.data.data);
        
        this.setState({ supervisorList: data.data.data });
      }
    } catch (error) {
      // errorHandel(error, "/users/listSupervisor")
      
    }
  };

  escalationSubmitButton = () => {
    const data = {
      agentID: this.state.selectedValue,
    };
    axios
      .put(
        `https://gway.release.inaipi.ae/email/email/escalate/${
          this.state.id._id ? this.state.id._id : ""
        }`,
        data
      )
      .then((response) => {
        if (response) {
          this.props.InboxList();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          
          this.handleCloseInboxDetailsDiv();
          this.handleCloseEscalationModal();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  cannotHandel = () => {
    axios
      .put(
        `https://gway.release.inaipi.ae/email/email/reassignEmails/${this.state.id._id}`
      )
      .then((response) => {
        if (response) {
          this.props.InboxList();
          this.setState({ inboxContentBody: "" });
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          
          this.handleCloseInboxDetailsDiv();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleChangeSupervisorList = (event) => {
    
    this.setState({ selectedValue: event.target.value });
  };

  render() {
    const { InboxDataList, writeEmail, isReplyEmail } = this.props;
    const {
      inboxContent,
      actionView,
      anchorEl,
      inboxContentAttchment,
      inboxContentBody,
      toSendlist,
      toCCSendlist,
      toBCCSendlist,
      isOpen,
      escalationModal,
      id,
      selectedValue,
      supervisorList,
      inputValue,
    } = this.state;
    let processedHtml = "";
    if (inboxContentBody) {
      processedHtml = inboxContentBody.replace(/\s+/g, " ").trim();
    }

    
    

    return (
      <>

        <div className="row mx-5">
          <div className="col-md-1">
            <SidebarComp
              openWriteEmail={this.props.openWriteEmail}
              closeWriteEmail={this.props.closeWriteEmail}
            />
          </div>

          <div className="col-md-11">
            <div className="email-app card-margin">
              <div className="rowtwo" id="rowtwo">
                <div className="email-list-wrapper" style={{ width: "20rem" }}>
                  <div
                    id="email-app-body"
                    className="email-list-scroll-container ps ps--active-y"
                  >
                    <div className="focused">
                      <div className="circle">
                        <div className="checkmark"></div>
                      </div>
                      <div>
                        <h6>Focused &nbsp; &nbsp;</h6>
                      </div>
                      <div>
                        <h6>Other &nbsp; &nbsp;</h6>
                      </div>
                      <div>
                        <h6>Filter &nbsp; &nbsp;</h6>
                      </div>
                    </div>
                    <div className="email-list-cont">
                      {!_.isEmpty(InboxDataList) ? (
                        <ul className="email-list">
                          {_.map(InboxDataList, (val, i) => {
                            return (
                              <li
                                key={i}
                                id="mail"
                                className="email-list-item"
                                onClick={() => this.handleClick(val)}
                              >
                                <img src={avatar} alt="Profile Picture" />
                                <div className="email-content">
                                  <div className="recipient">
                                    <div className="recipient-name">
                                      {val.fromList}
                                    </div>
                                  </div>
                                  <div href="#" className="email-subject">
                                    {val.subject}
                                  </div>
                                </div>
                                <div className="email-footer">
                                  <span>
                                    <IoMdOpen
                                    id="mail"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.handleOpenInboxDetailsDiv}
                                    />
                                  </span>
                                  <span className="email-time">
                                    {moment(val.receivedTime).format(
                                      "M/D/YYYY h:mm A"
                                    )}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>

                {writeEmail ? (
                  <NewEmail
                    action={this.props}
                    inboxContent={isReplyEmail ? inboxContent : null}
                    agentId={agentId}
                    toSendlist={isReplyEmail ? toSendlist : []}
                    toCCSendlist={isReplyEmail ? toCCSendlist : []}
                    toBCCSendlist={isReplyEmail ? toBCCSendlist : []}
                  />
                ) : (
                  <>
                    {!_.isEmpty(inboxContent) ? (
                      <div
                        className="email-desc-wrapper"
                        style={{ minWidth: "55rem" }}
                      >
                        <div className="title-subject">
                          <h6>{inboxContent.subject}</h6>
                        </div>
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <div className="email-header">
                                <img src={avatar} alt="Profile Picture" />
                                <div className="address">
                                  <p className="recipient" id="from">
                                    {" "}
                                    {inboxContent.fromList}{" "}
                                  </p>
                                  <p className="recipient" id="to">
                                    <span>To:{inboxContent.toList}</span>
                                  </p>
                                  {inboxContent.ccList ? (
                                    <p className="recipient" id="cc">
                                      <span>Cc:{inboxContent.ccList}</span>
                                    </p>
                                  ) : null}
                                  <p className="recipient" id="date">
                                    <span className="time_format">
                                      {moment(inboxContent.receivedTime).format(
                                        "MMMM Do YYYY, h:mm A"
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {!_.isEmpty(inboxContentAttchment)
                              ? this.EmailAttachmentContent(
                                  inboxContentAttchment
                                )
                              : null}
                            <Typography>
                              <div className="mailcontent">
                                <div className="email-body">
                                  {parse(processedHtml)}
                                </div>
                                <div className="email-action">
                                  <button
                                  id="mail"
                                    className="btn btn-base"
                                    onClick={this.onClickReply}
                                  >
                                    {" "}
                                    <img src={reply} /> Reply &nbsp;
                                  </button>
                                  <button
                                  id="mail"
                                    className="btn btn-base"
                                    onClick={this.onClickReplyAll}
                                  >
                                    {" "}
                                    <img src={replyall} /> Reply All &nbsp;
                                  </button>
                                  <button className="btn btn-base">
                                    {" "}
                                    {/* <img src={forward} /> Forward */}
                                  </button>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ) : null}
                  </>
                )}

                {/* open div onClick */}
                {isOpen && (
                  <Draggable>
                    <div
                      className="card"
                      style={{
                        height: "30rem",
                        width: "35rem",
                        position: "absolute",
                        left: "10rem",
                        top: "3rem",
                        zIndex: 1,
                      }}
                    >
                      <div className="header p-1 d-flex justify-content-between align-items-center">
                        <div>{/* <h1>{this.state.id}</h1> */}</div>
                        <div className="" style={{ paddingTop: "10px" }}>
                          <MdOutlineClose
                            style={{ cursor: "pointer" }}
                            id="mail"
                            onClick={this.handleCloseInboxDetailsDiv}
                          />
                        </div>
                      </div>
                      <hr />
                      <div
                        className="sub_header d-flex w-100 justify-content-around align-items-center"
                        style={{ height: "40px", width: "100vw" }}
                      >
                        <div>Add-Ins</div>

                        <div>
                          <div
                            className="d-flex"
                            style={{ width: "15rem", height: "50px" }}
                          >
                            <table style={{ border: "1px solid black" }}>
                              <tr style={{ border: "1px solid black" }}>
                                <td className="popup_div">
                                  <FaArrowUp
                                    size={18}
                                    id="mail"
                                    onClick={this.handleOpenEscalationModal}
                                    className="d-flex mx-auto"
                                  />
                                  <div
                                    style={{ marginTop: "3px" }}
                                    className="table_data"
                                  >
                                    Escalation
                                  </div>
                                </td>

                                <td
                                  className="popup_div"
                                  id="mail"
                                  onClick={this.cannotHandel}
                                >
                                  <DoNotTouchIcon
                                    size={10}
                                    className="d-flex mx-auto"
                                  />
                                  <div className="table_data">
                                    Cannot Handle
                                  </div>
                                </td>

                                <td
                                  className="popup_div"
                                  id="mail"
                                  onClick={this.completedEmail}
                                >
                                  <RiMailForbidLine
                                    size={20}
                                    className="d-flex mx-auto"
                                  />
                                  <div
                                    className="table_data"
                                    style={{ marginTop: "3px" }}
                                  >
                                    NoAction Email
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        className=""
                        style={{
                          height: "90vh",
                          overflowY: "auto",
                          marginBottom: "",
                        }}
                      >
                        <div className="p-3">
                          <div>
                            {!_.isEmpty(inboxContentAttchment)
                              ? this.EmailAttachmentContent(
                                  inboxContentAttchment
                                )
                              : null}

                            <div className="mailcontent">
                              <div className="email-body">
                                {parse(processedHtml)}
                              </div>
                              <div className="email-action">
                                <button
                                id="primary-btn"
                                  className="btn btn-base"
                                  onClick={this.onClickReply}
                                >
                                  {" "}
                                  <img src={reply} /> Reply &nbsp;
                                </button>
                                <button
                                id="primary-btn"
                                  className="btn btn-base"
                                  onClick={this.onClickReplyAll}
                                >
                                  {" "}
                                  <img src={replyall} /> Reply All &nbsp;
                                </button>
                                <button className="btn btn-base">
                                  {" "}
                                  {/* <img src={forward} /> Forward */}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Draggable>
                )}
                {/* open div onClick */}

                {/* Transfer to Supervisor for Escalation */}

                <Modal
                  show={escalationModal}
                  className="transferCall-modal"
                  onHide={this.handleCloseEscalationModal}
                  size="md"
                >
                  <Modal.Header
                    style={{
                      padding: "6px 12px",
                      margin: 0,
                      fontSize: "12px",
                      height: "45px",
                      backgroundColor: "#294e9f",
                      color: "white",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div>
                        <Modal.Title
                          style={{
                            fontSize: 15,
                            margin: "6px 0 0 0",
                            textTransform: "capitalize",
                          }}
                        >
                          Reassign To Supervisor
                        </Modal.Title>
                      </div>
                      <div>
                        <AiOutlineCloseCircle
                        id="close-circle"
                          onClick={this.handleCloseEscalationModal}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    {" "}
                    <div className="d-flex justify-content-between"></div>
                    <select
                      className="form-select form-select-sm mt-2"
                      name="availableAgent"
                      value={selectedValue}
                      onChange={this.handleChangeSupervisorList}
                    >
                      <option value="" selected disabled>
                        Available Supervisor
                      </option>

                      {supervisorList.map((item) => {
                        return (
                          <option id={item.id} value={item.user_id}>
                            {item.username}
                          </option>
                        );
                      })}
                    </select>
                  </Modal.Body>

                  <div className="transferOkDiv">
                    <Button
                      variant="primary"
                      className="transferOkBtn"
                      id="escalate-btn"
                      onClick={this.escalationSubmitButton}
                    >
                      Reassign To Supervisor
                    </Button>
                  </div>
                </Modal>

                {/* Transfer to Supervisor for Escalation */}
              </div>
            </div>
          </div>

          <div className="email-desc-wrapper">
            <div className="title-subject">
              <h6>{inboxContent.subject}</h6>
            </div>
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="email-header">
                    <img src={avatar} alt="Profile Picture" />
                    <div className="address">
                      <p className="recipient" id="from">
                        {" "}
                        {inboxContent.fromList}{" "}
                      </p>
                      <p className="recipient" id="to">
                        <span>To:{inboxContent.toList}</span>
                      </p>
                      {inboxContent.ccList ? (
                        <p className="recipient" id="cc">
                          <span>Cc:{inboxContent.ccList}</span>
                        </p>
                      ) : null}
                      <p className="recipient" id="date">
                        <span>
                          {moment(inboxContent.receivedTime).format(
                            "MMMM Do YYYY, h:mm A"
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!_.isEmpty(inboxContentAttchment)
                  ? this.EmailAttachmentContent(inboxContentAttchment)
                  : null}
                <Typography>
                  <div
                    className="mailcontent"
                    style={{
                      width: "100vw",
                      overflowX: "none",
                      marginBottom: "10rem",
                    }}
                  >
                    <div
                      className="email-body"
                      style={{ width: "58vw", overflowX: "none" }}
                    >
                      {parse(processedHtml)}
                    </div>
                    <div className="email-action">
                      <button className="btn btn-base">
                        {" "}
                        <img src={reply} /> Reply &nbsp;
                      </button>
                      <button className="btn btn-base">
                        {" "}
                        <img src={replyall} /> Reply All &nbsp;
                      </button>
                      <button className="btn btn-base">
                        {" "}
                        {/* <img src={forward} /> Forward */}
                      </button>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <Popover
            id="simple-popover"
            open={actionView}
            anchorEl={anchorEl}
            onClose={this.closeActionView}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper sx={{ width: 200, maxWidth: "100%" }}>
              <MenuList>
                <MenuItem  id="open-doc" onClick={this.openDocument}>
                  <ListItemIcon>
                    <VisibilityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Preview</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem id="open-doc" onClick={this.downloadDoc}>
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Download</ListItemText>
                </MenuItem>
                <Divider />
                {/* <MenuItem>
                                    <ListItemIcon>
                                        <ClearIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Remove Attachment</ListItemText>
                                    </MenuItem> */}
              </MenuList>
            </Paper>
          </Popover>
        </div>
      </>
    );
  }
}
