import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import img from "./images/people.svg";
import { Modal } from "react-bootstrap";
import spinnerimg from "./images/Inaipi_Logo-1.2.png";
import "./Dashboard2.css";
import Badge from "@mui/material/Badge";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import ForumIcon from "@mui/icons-material/Forum";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import OutboundIcon from "@mui/icons-material/Outbound";
import GroupIcon from '@mui/icons-material/Group';
import moment from "moment";
import { AvcUrl, BaseUrl } from "../../container/BaseUrl/BaseUrl";
import axios from "axios";
import {
  setchatdata,
  setchatid,
  setChatRefresh,
  endInteraction,
  setsidebarVal,
  setAgentStatusRefreash,
  setShowingStatus,
} from "../../redux/actions/actions";
import { Slide, Typography, Box, Pagination, Stack } from "@mui/material";

// component import
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FrameCorners } from "@phosphor-icons/react";

const api = {
  key: "d5636778a0512845243f75043eb65a3d",
  base: "https://api.openweathermap.org/data/2.5/",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
  return {
    chatid: state.data.chatid,
    chat: state.data.chat,
    agentStatusrefreash: state.data.agentStatusrefreash,
  };
};

const Dashboard = (props) => {
  const navigate = useNavigate();

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));
  const userName = JSON.parse(localStorage.getItem("tokenAgent"));
  const [dashboardcount, setDashboardcount] = useState([]);
  const [teamactivitydashboardcount, setTeamactivitydashboardcount] = useState(
    []
  );
  const [dashboardcountagent, setDashboardcountagent] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [spinner, setspinner] = useState(false);
  const [weather, setWeather] = useState();

  const SupervisorObserver = async (el) => {
    localStorage.removeItem("client");
    try {
      const access_token = localStorage.getItem("access_token");
      let old_agent_id = JSON.parse(localStorage.getItem("tokenAgent"));

      if (
        el.channel == "webchat" ||
        el.channel == "from_whatsapp" ||
        el.channel == "from_facebook" ||
        el.channel == "from_purple"
      ) {
        let dataToPass = {
          sessionId: el.chat_session_id,
          agentId: old_agent_id.agent_id,
          oldAgentId: el.agent,
        };

        const data = await axios.post(
          BaseUrl + "/users/conferenceAgent",
          dataToPass,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        );

        if (data.data.status) {
          navigate("/main/Chat");
          props.setsidebarVal("Chat");
          localStorage.setItem("indexOf", 2);
          toast.success("Conferenced to supervisor success", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warn(data.data.message, {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }

        // }
      } else if (el.channel == "voice") {
        console.log("Current Voice", el);
        try {
          let ssToken = localStorage.getItem("ssoToken");
          await axios
            .post(
              AvcUrl +
                `/voice/terminal/${localStorage.getItem(
                  "agentLoginTerminalId"
                )}/observe/sip:${localStorage.getItem(
                  "agentLoginTerminalId"
                )}@demoaccs.com/${el.voice_contactId}`,
              {},
              {
                headers: {
                  ssoToken: ssToken,
                },
              }
            )
            .then((res) => {
              console.log("SupervisorObserver", res);
            })
            .catch((err) => {});
        } catch (error) {
          console.log("voice", error);
        }
      }
    } catch (error) {
      console.log("SupervisorObserver", error);
    }
  };

  const searchWeather = async () => {
    await axios
      .get(`${api.base}weather?q=${"Bangalore"}&units=metric&APPID=${api.key}`)

      .then((res) => {
        setWeather(res.data);
      });
  };

  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  const agentId = JSON.parse(localStorage.getItem("tokenAgent"));

  const handleRotate = () => {
    if (basedOnRole.role == "Supervisor") {
      setspinner(true);
      dashboardCountData();
      agentActivityCount();
      searchWeather();
    } else {
      setspinner(true);
      dashboardCountDataAgent();
      searchWeather();
    }
  };

  useEffect(() => {
    if (basedOnRole.role == "Supervisor") {
      agentActivityCount();
      dashboardCountData();
      searchWeather();
    } else {
      dashboardCountDataAgent();
      searchWeather();
    }
  }, [page, localStorage.getItem("statusValue")]);

  //  CURRENT TIME FUNCTION
  var time = new Date().toLocaleTimeString();
  const [ctime, setCtime] = useState(time);
  const updateTime = () => {
    var time = new Date().toLocaleTimeString();
    setCtime(time);
  };
  setInterval(updateTime, 1000);
  var m_names = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var d = new Date();
  var month = m_names[d.getMonth()];

  //Supervisor
  const dashboardCountData = async () => {
    try {
      await axios
        .post(
          BaseUrl + "/dashboard/dashboardCount",
          { agent_id: agentId.agent_id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          setDashboardcount(res.data);
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const agentActivityCount = async () => {
    try {
      await axios
        .post(
          BaseUrl + "/dashboard/chatCount",
          {
            from_date: defaultValue,
            to_date: defaultValue,
            offset: page == 1 ? 0 : (page - 1) * 2,
            limit: 2,
            agent_id: agentId.agent_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setTeamactivitydashboardcount(res.data.Data);
            setPagination(res.data.count);
            setTimeout(() => {
              setspinner(false);
            }, 1000);
          } else {
            setTeamactivitydashboardcount([]);
            setTimeout(() => {
              setspinner(false);
            }, 1000);
          }
        });
    } catch (error) {
      errorhandelApi(error, "/dashboard/chatCount");
      setTimeout(() => {
        setspinner(false);
      }, 2000);
    }
  };

  //Agent
  const dashboardCountDataAgent = async () => {
    props.setAgentStatusRefreash(false);
    const agentId = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      await axios
        .post(
          BaseUrl + "/dashboard/taskCountByAgentId",
          {
            agent_id: agentId.id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            props.setShowingStatus(res.data.user.agent_status_real);
            localStorage.setItem(
              "statusValue",
              res.data.user.agent_status_real
            );
            setDashboardcountagent(res.data);
            setTimeout(() => {
              setspinner(false);
            }, 1000);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            setspinner(false);
          }, 1000);
        });
    } catch (error) {
      errorhandelApi(error, "/dashboard/taskCountByAgentId");
      setTimeout(() => {
        setspinner(false);
      }, 2000);
    }
  };

  useEffect(() => {
    dashboardCountDataAgent();
  }, [props.agentStatusrefreash]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  var seconds = dashboardcountagent.TotalBreakTime;
  var duration = moment.duration(seconds, "seconds");
  var dashboardcountagentTotalBreakTime = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm:ss");
  var seconds = dashboardcountagent.TotalActiveTime;
  var duration = moment.duration(seconds, "seconds");
  var dashboardcountagentTotalActiveTime = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm:ss");

  const [OngoingInteractionList, setOngoingInteractionList] = useState([]);
  const [OngoingInteraction, setOngoingInteraction] = useState(false);

  const [Interactionpage, setInteractionpage] = useState(1);
  const [Interactionpagepagination, setInteractionpagePagination] = useState(0);

  const openOngoingInteractionApi = (agentID) => {
    let body = {
      agentId: agentID,
      offset: Interactionpage == 1 ? 0 : (Interactionpage - 1) * 5,
      limit: 5,
    };
    axios
      .post(BaseUrl + "/users/agentActiveChat", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
          tenantId: localStorage.getItem("TenantId"),
        },
      })
      .then((res) => {
        setOngoingInteractionList(res.data.data);
        setInteractionpagePagination(res.count);
      })

      .catch((error) => {
        setOngoingInteractionList([]);
      });
  };

  const openOngoingInteractionDialogBox = (value) => {
    openOngoingInteractionApi(value);
    setOngoingInteraction(true);
  };
  const OngoingInteractionDialogBoxclose = () => {
    setOngoingInteraction(false);
  };

  const InteractionhandleChange = (event, value) => {
    setInteractionpage(value);
  };
  useEffect(() => {
    openOngoingInteractionApi();
  }, [Interactionpage]);

  return (
    <>
      {basedOnRole.role == "Supervisor" ? (
        <div className="container2">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row">
                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                      <h4 className="font-weight-bold text-truncate">
                        Welcome
                        <span className="text-primary mx-1">
                          {userName.username ? userName.username : ""}
                        </span>
                      </h4>
                      <h6
                        className="font-weight-normal mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        Last Logged In :{" "}
                        {moment(basedOnRole.updatedAt).format("L")}
                        <span className="text-primary mx-1">
                          {moment(basedOnRole.updatedAt).format("hh:mm:ss A")}
                        </span>
                      </h6>
                    </div>

                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="">
                          <button
                            className="btn btn-sm btn-light bg-white"
                            type="button"
                            id="dropdownMenuDate2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="mdi mdi-calendar"></i> Today (
                            {defaultValue}) : {ctime}
                          </button>
                          <button
                            className="btn btn-sm btn-light bg-white mx-2"
                            id="refresh-chat"
                            type="button"
                            onClick={() => {
                              handleRotate();
                            }}
                            style={{ fontSize: "12px" }}
                          >
                            Refresh
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin transparent">
                  <div className="row">
                    <div
                      className="col-md-3 mb-4 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-tale">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Agents - LoggedIn
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcount.AgentLoggedIn == null ||
                            dashboardcount.AgentLoggedIn == undefined
                              ? 0
                              : dashboardcount.AgentLoggedIn}
                          </p>
                          <p className="className="></p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 mb-4 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-dark-blue">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Agents - Active
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcount.AgentActive == null ||
                            dashboardcount.AgentActive == undefined
                              ? 0
                              : dashboardcount.AgentActive}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 mb-4 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-dark-blue2">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Agents - Offline
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>
                            {dashboardcount.AgentOffline == null ||
                            dashboardcount.AgentOffline == null
                              ? 0
                              : dashboardcount.AgentOffline}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-light-danger2">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Abandoned
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>

                            {dashboardcount.abadoned_count == null ||
                            dashboardcount.abadoned_count == null
                              ? 0
                              : dashboardcount.abadoned_count}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 mb-4 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-light-danger2">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Current Wait
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>

                            {dashboardcount.currentwaitlist == null ||
                            dashboardcount.currentwaitlist === 0
                              ? "00:00:00"
                              : moment(dashboardcount.currentwaitlist).format(
                                  "HH:mm:ss"
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 mb-4 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-dark-blue2">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Longest Wait
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>
                            {dashboardcount.longwait == null ||
                            dashboardcount.longwait === 0
                              ? "00:00:00"
                              : moment(dashboardcount.longwait).format(
                                  "HH:mm:ss"
                                )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 mb-4 mb-lg-0 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-light-blue">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Agents Not Ready
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcount.AgentNotReady == null ||
                            dashboardcount.AgentNotReady == undefined
                              ? 0
                              : dashboardcount.AgentNotReady}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 stretch-card transparent"
                      style={{ height: "70px" }}
                    >
                      <div className="card card-light-danger">
                        <div className="card-body">
                          <p className="mb-1 text-white dashboard-heading-fonts">
                            Queued Interaction Count
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "17px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcount.QueuedChat == null ||
                            dashboardcount.QueuedChat == undefined
                              ? 0
                              : dashboardcount.QueuedChat}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ height: "250px" }}>
                    <div className="card-body">
                      <Stack direction={"row"} sx={{ display: "flex" }}>
                        <Typography>Team Activity</Typography>
                        <Box
                          sx={{
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition:
                              "background-color 0.5s, color 0.5s, fill 0.5s",
                            "&:hover": {
                              background: "#595858e8",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            window.open("/Agent-List", "_blank");
                          }}
                        >
                          <FrameCorners
                            size={22}
                            cursor={"pointer"}
                            weight="bold"
                          />
                        </Box>
                      </Stack>

                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table
                              id="example"
                              className="display expandable-table"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th className="dashboard-heading-table">
                                    Agent Name
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Status
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Ongoing Interaction
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Presented
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Answered
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Abandoned
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Transferred
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {teamactivitydashboardcount.map(
                                  (item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td
                                          className=""
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {item.agent_id == null ||
                                          item.agent_id == undefined ||
                                          item.agent_id == ""
                                            ? "--"
                                            : item.agent_id.username}
                                        </td>

                                        <td className="text-center">
                                          {item.agent_id == null ||
                                          item.agent_id == undefined ||
                                          item.agent_id == "" ? (
                                            "--"
                                          ) : item.agent_id.agent_status_real ==
                                              "Available" ||
                                            item.agent_id.agent_status_real ==
                                              "Ready" ? (
                                            <Badge
                                              color="success"
                                              badgeContent={
                                                item.agent_id.agent_status_real
                                              }
                                              // style={{width:100}}
                                            ></Badge>
                                          ) : item.agent_id.agent_status_real ==
                                            "Connected" ? (
                                            <Badge
                                              color="info"
                                              badgeContent={
                                                item.agent_id.agent_status_real
                                              }
                                            ></Badge>
                                          ) : (
                                            <Badge
                                              color="warning"
                                              badgeContent={
                                                item.agent_id.agent_status_real
                                              }
                                              style={{
                                                width: 100,
                                                marginRight: "100px",
                                              }}
                                              sx={{ zIndex: "0" }}
                                            ></Badge>
                                          )}
                                        </td>

                                        <td
                                          className="text-center"
                                          id="interaction-chat"
                                        >
                                          <Badge
                                            badgeContent={item.total_active}
                                            color="warning"
                                            sx={{ zIndex: "0" }}
                                          >
                                            <QuickreplyIcon
                                              color="primary"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                openOngoingInteractionDialogBox(
                                                  item.agent_id.id
                                                );
                                              }}
                                            />
                                          </Badge>
                                        </td>

                                        <td className="text-center">
                                          <Badge
                                             badgeContent={item.presented_count}
                                            color="warning"
                                            sx={{ zIndex: "0" }}
                                          >
                                            <ForumIcon color="primary" />
                                          </Badge>
                                        </td>
                                        <td className="text-center">
                                          <Badge
                                            badgeContent={
                                              item.total_active
                                            }
                                            color="warning"
                                            sx={{ zIndex: "0" }}
                                          >
                                            <PermPhoneMsgIcon color="primary" />
                                          </Badge>
                                        </td>
                                        <td className="text-center">
                                          <Badge
                                            badgeContent={
                                              item.abadoned_count
                                            }
                                            color="warning"
                                            sx={{ zIndex: "0" }}
                                          >
                                            <OutboundIcon color="primary" />
                                          </Badge>
                                        </td>
                                        <td className="text-center">
                                          <Badge
                                            badgeContent={
                                              item.total_transferred_out
                                            }
                                            color="warning"
                                            sx={{ zIndex: "0" }}
                                          >
                                            <SyncAltIcon color="primary" />
                                          </Badge>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-3">
                      <Pagination
                        count={Math.ceil(pagination / 2)}
                        page={page}
                        color="primary"
                        size="small"
                        style={{ float: "right" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {spinner ? (
            <>
              <div className="body2">
                <div className="loader">
                  <img src={spinnerimg} className="logo2" />
                  <span className="spinnerspan"></span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="container2">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 grid-margin">
                  <div className="row">
                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                      <h4 className="font-weight-bold text-truncate">
                        Welcome
                        <span className="text-primary mx-1">
                          {userName.username ? userName.username : ""}
                        </span>
                      </h4>
                      <h6
                        className="font-weight-normal mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        Last Logged In :{" "}
                        {moment(basedOnRole.updatedAt).format("L")}
                        <span className="text-primary mx-1">
                          {moment(basedOnRole.updatedAt).format("hh:mm:ss A")}
                        </span>
                      </h6>
                    </div>

                    <div className="col-12 col-xl-4">
                      <div className="justify-content-end d-flex">
                        <div className="">
                          <button
                            className="btn btn-sm btn-light bg-white"
                            style={{ fontSize: "12px" }}
                          >
                            <i className="mdi mdi-calendar"></i> Today (
                            {defaultValue}) : {ctime}
                          </button>
                        </div>

                        <div className="mx-2">
                          <button
                            className="btn btn-sm btn-light bg-white"
                            id="refresh-refresh"
                            style={{ fontSize: "12px" }}
                            type="button"
                            onClick={() => {
                              handleRotate();
                            }}
                          >
                            Refresh
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 grid-margin stretch-card">
                  <div className="card tale-bg">
                    <div className="card-people">
                      <img
                        src={img}
                        alt="people"
                        style={{ height: "200px", width: "100%" }}
                      />
                      <div className="weather-info">
                        <div className="d-flex">
                          <div>
                            <h4 className="mb-0 font-weight-normal ">
                              <i className="icon-sun mr-2"></i>
                              {weather ? weather.main.temp : ""}
                              <sup>C</sup>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 grid-margin transparent">
                  <div className="row">
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-tale">
                        <div className="card-body">
                          <p className="mb-3 text-white dashboard-heading-fonts">
                            Completed Interaction
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcountagent.completedTask == null ||
                            dashboardcountagent.completedTask == undefined
                              ? 0
                              : dashboardcountagent.completedTask}
                          </p>
                          <p className="className="></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4 stretch-card transparent">
                      <div className="card card-dark-blue">
                        <div className="card-body">
                          <p className="mb-3 text-white dashboard-heading-fonts">
                            Total Break Time
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcountagentTotalBreakTime}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                      <div className="card card-light-blue">
                        <div className="card-body">
                          <p className="mb-3 text-white dashboard-heading-fonts">
                            Total Active Time
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcountagentTotalActiveTime}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 stretch-card transparent">
                      <div className="card card-light-danger">
                        <div className="card-body">
                          <p className="mb-3 text-white dashboard-heading-fonts">
                            Customer Disconnected
                          </p>
                          <p
                            className="fs-30 mb-2 text-white"
                            style={{ fontSize: "18px" }}
                          >
                            <i className="fa-solid fa-arrow-turn-up"></i>{" "}
                            {dashboardcountagent.customerDisconnected == null ||
                            dashboardcountagent.customerDisconnected ==
                              undefined
                              ? 0
                              : dashboardcountagent.customerDisconnected}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card" style={{ height: "200px" }}>
                    <div className="card-body">
                      <p className="card-title">My Activity</p>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table
                              id="example"
                              className="display expandable-table"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th className="dashboard-heading-table">
                                    Agent Name
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Status
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Total Ongoing Interaction
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Presented
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Abandoned
                                  </th>
                                  <th className="text-center dashboard-heading-table">
                                    Transferred
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className=""
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {dashboardcountagent.user == null ||
                                    dashboardcountagent.user == undefined ||
                                    dashboardcountagent.user == ""
                                      ? "--"
                                      : dashboardcountagent.user.username}
                                  </td>
                                  <td className="text-center">
                                    {dashboardcountagent.user == null ||
                                    dashboardcountagent.user == undefined ||
                                    dashboardcountagent.user == "" ? (
                                      "--"
                                    ) : dashboardcountagent.user
                                        .agent_status_real ==
                                      "Not Available" ? (
                                      <Badge
                                        color="success"
                                        sx={{ zIndex: "0" }}
                                        badgeContent={
                                          dashboardcountagent.user
                                            .agent_status_real
                                        }
                                      ></Badge>
                                    ) : dashboardcountagent.user
                                        .agent_status_real == "Ready" ||
                                      dashboardcountagent.user
                                        .agent_status_real == "Available" ? (
                                      <Badge
                                        color="success"
                                        sx={{ zIndex: "0" }}
                                        badgeContent={
                                          dashboardcountagent.user
                                            .agent_status_real
                                        }
                                      ></Badge>
                                    ) : dashboardcountagent.user
                                        .agent_status_real == "Connected" ? (
                                      <Badge
                                        color="info"
                                        sx={{ zIndex: "0" }}
                                        badgeContent={
                                          dashboardcountagent.user
                                            .agent_status_real
                                        }
                                      ></Badge>
                                    ) : (
                                      <Badge
                                        sx={{ zIndex: "0" }}
                                        color="warning"
                                        badgeContent={
                                          dashboardcountagent.user
                                            .agent_status_real
                                        }
                                      ></Badge>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <Badge
                                      badgeContent={
                                        dashboardcountagent
                                          ? dashboardcountagent.totalongoingcount
                                          : null
                                      }
                                      color="warning"
                                      sx={{ zIndex: "0" }}
                                    >
                                      <GroupIcon color="primary" />
                                    </Badge>
                                  </td>

                                  <td className="text-center">
                                    <Badge
                                      badgeContent={
                                        dashboardcountagent
                                          ? dashboardcountagent.presentedcount
                                          : null
                                      }
                                      color="warning"
                                      sx={{ zIndex: "0" }}
                                    >
                                      <ForumIcon color="primary" />
                                    </Badge>
                                  </td>
                                  <td className="text-center">
                                    <Badge
                                      badgeContent={
                                        dashboardcountagent
                                          ? dashboardcountagent.abadonedcount
                                          : null
                                      }
                                      color="warning"
                                      sx={{ zIndex: "0" }}
                                    >
                                      <OutboundIcon color="primary" />
                                    </Badge>
                                  </td>
                                  <td className="text-center">
                                    <Badge
                                      badgeContent={
                                        dashboardcountagent
                                          ? dashboardcountagent.TotalTransferedOut
                                          : null
                                      }
                                      color="warning"
                                      sx={{ zIndex: "0" }}
                                    >
                                      <SyncAltIcon color="primary" />
                                    </Badge>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {spinner ? (
            <>
              <div className="body2">
                <div className="loader">
                  <img src={spinnerimg} className="logo2" />
                  <span className="spinnerspan"></span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}

      <Modal
        show={OngoingInteraction}
        size="md"
        onHide={OngoingInteractionDialogBoxclose}
      >
        <Modal.Header style={{ backgroundColor: "#0b3363", color: "#fff" }}>
          <Modal.Title
            style={{
              fontSize: 15,
              margin: "6px 0 0 0",
              textTransform: "capitalize",
            }}
          >
            Ongoing Interaction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-sm table-hover align-middle">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    Customer Name
                  </th>
                  <th scope="col" className="text-center">
                    Arival Time
                  </th>
                  <th scope="col" className="text-center">
                    Channel
                  </th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(OngoingInteractionList) ? (
                  OngoingInteractionList.map((item) => {
                    return (
                      <tr key={item.unique_id.id}>
                        <td className="text-center">
                          {item.unique_id.username == ""
                            ? item.unique_id.phonenumber
                            : item.unique_id.username}
                        </td>
                        <td className="text-center">{item.arrival_at}</td>
                        <td className="text-center">
                          {item.channel == "webchat" ? (
                            <i className="fa-brands fa-rocketchat text-primary"></i>
                          ) : item.channel == "from_whatsapp" ? (
                            <i className="fa-brands fa-whatsapp text-primary"></i>
                          ) : item.channel == "from_facebook" ? (
                            <i className="fa-brands fa-facebook-f text-primary"></i>
                          ) : item.channel == "from_twitter" ? (
                            <i className="fa-brands fa-twitter text-primary"></i>
                          ) : item.channel == "from_teams" ? (
                            <i className="fa-brands fa-teams text-primary"></i>
                          ) : item.channel == "videochat" ? (
                            <i className="fa-solid fa-video text-primary"></i>
                          ) : item.channel == "voice" ? (
                            <i className="fa-solid fa-phone text-primary"></i>
                          ) : item.channel == "from_purple" ? (
                            <i className="fa-solid fa-robot"></i>
                          ) : (
                            <i className="fa-solid fa-envelope text-primary"></i>
                          )}
                        </td>
                        <td className="text-center">
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <Tooltip title={"Observer"} arrow placement="top">
                              <button
                                type="button"
                                id="agent-transfer"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  SupervisorObserver(item);
                                }}
                                disabled={item.channel == "email"}
                              >
                                <i className="fa-solid fa-code-pull-request"></i>
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            <div>
              <div className="mt-2 mb-3">
                <Pagination
                  color="primary"
                  size="small"
                  style={{ float: "right" }}
                  onChange={InteractionhandleChange}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px" }}></Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, {
  setchatdata,
  setchatid,
  setsidebarVal,
  setChatRefresh,
  endInteraction,
  setAgentStatusRefreash,
  setShowingStatus,
})(Dashboard);
