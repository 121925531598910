import React, { Component } from "react";
import { Link } from "react-router-dom";
// import shortid from "shortid";
import moment from "moment";
import Button from "react-bootstrap/Button";
import SplitButton from "react-bootstrap/SplitButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import parse from "html-react-parser";
// import excel from "../../../Page/EmailComponents/Resources/images/excel.png";
import excel from "../Resources/images/excel.png"


import {
  Grid,
  Container,
  Box,
  Tabs,
  Tab,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Autocomplete,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Chip,
  Popover,
  TextField,
  Stack,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Fade,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  ToggleButton,
  ListItem,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Email from "../Resources/images/firstemail.png";
import Mail_write from "../Resources/images/new_email.png";
import inbox from "../Resources/images/inbox_second.png";
import drafts from "../Resources/images/draft_new.jpg";
import sentitems from "../Resources/images/dm.png";
import deleteitems from "../Resources/images/trash.png";
import junkemail from "../Resources/images/error.png";
import bold from "../Resources/images/bold1.png";
import italic from "../Resources/images/italic.png";
import underline from "../Resources/images/line.png";
import avatar from "../Resources/images/imageicon1.png";
import highlight from "../Resources/images/highlight.png";
import fontcolour from "../Resources/images/fontcolour.png";
import bullets from "../Resources/images/bullet.png";
import numberBullets from "../Resources/images/number.png";
import leftIndent from "../Resources/images/leftindent.png";
import rightIndent from "../Resources/images/rightindent.png";
import moreOptions from "../Resources/images/moreoptions.png";
import search from "../Resources/images/search.png";
import reply from "../Resources/images/reply2.png";
import replyall from "../Resources/images/replyall.png";
import forward from "../Resources/images/forward.png";
import pdf from "../Resources/images/pdf.png";
import txt from "../Resources/images/txt.png";
import word from "../Resources/images/word.png";
import "../Resources/css/email.scss";
import { CTooltip } from "@coreui/react";
import SidebarComp from "./SidebarComp";

import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  convertFromRaw,
  ContentState,
  AtomicBlockUtils,
  convertFromHTML,
} from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { ChromePicker } from "react-color";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import {
  BaseUrl,
  emailservice,
  fileservice,
  ticketUrl,
} from "../../../container/BaseUrl/BaseUrl";
import axios from "axios";
import { event } from "jquery";
import { MdEditNotifications } from "react-icons/md";
import { AiFillCloseCircle, AiFillCloseSquare } from "react-icons/ai";

export default class NewEmail extends Component {
  constructor(props) {
    console.log("NewEmail117", props);
    super(props);
    const bodyEmailMessage = props.emailbodymsg || ""; // Default to an empty string if it's not provided

    // Parse HTML to ContentState
    const blocksFromHTML = convertFromHTML(bodyEmailMessage);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.state = {
      nameObj: { first: "Belvin" },
      name: "Bell ",
      emails: [],
      from: { mailId: "Acct.user@gmail.com" },
      to: props.toSendlist ? props.toSendlist : [],
      toInputValue: "",
      cc: props.toCCSendlist ? props.toCCSendlist : [],
      ccInputValue: "",
      bcc: props.toBCCSendlist ? props.toBCCSendlist : [],
      bccInputValue: "",
      emailSubject: props.emailSubject,
      toError: false,
      ccError: false,
      bccError: false,
      inputText: "",
      formattedText: "",
      color: "#000000",
      iscolor: false,
      // editorState: EditorState.createEmpty(),
      editorState: EditorState.createWithContent(contentState),
      selectedtempFile: [],
      editAttachment: props.attachment ? props.attachment : [],
      selectedFile: [],
      emailSubject: props.inboxContent ? props.inboxContent.subject : "",
      templateDropdownList: [],
      selectedTemplateMessage: "",
      subjectErrorMessage: false,
      showOptions: true,
      forwardEmailforForward: props.forwardemailtoggle,
      toIsEmpty: false,
      showPopup: false,
      selectedUser: null,
      filterText: "",
      showEmailSuggestions: false,
      selectedEmail: null,
      responseData: null,
      error: null,
      filteredEmails: [],
      optioned: [],
      originalSuggestions: [
        "Warm regards",
        "Looking forward to connecting",
        "Best wishes",
        "With gratitude",
        "Wishing you the best",
        "Awaiting your response",
        "Eager to hear from you",
        "Stay well",
        "Until next time",
        "Sending my regards",
        "Take care",
        "Here for any further assistance",
        "anticipation of your reply",
        "With appreciation",
        "Respectfully",
        "Best regards, [Your Name]",
        "Hoping for a swift resolution",
        "With warmest regards",
        "forward to your feedback",
        "With kindest regards",
      ],
      autoSuggestions: [],
      suggestionPosition: { top: 343, left: 365 },
      bodyEmailMessage: props.emailbodymsg || "",
      selectedChanneldata: props.selectedChanneldata,
      uploadMediaurl: [],
      // myTogglechat: props.action.togglechat
    };

    this.inputRef = React.createRef();

    // this.editorState=EditorState.createEmpty();
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  handleChangeColor = (newColor) => {
    this.setState({ color: newColor.hex });

    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "BACKGROUND-COLOR",
        newColor.hex
      ),
    });
  };

  handleTextColorChange = (color) => {
    this.setState({ color: color.hex });
    const selection = this.state.editorState.getSelection();
    const contentState = this.state.editorState.getCurrentContent();

    // Apply the text color to the selected text
    const contentStateWithColor = Modifier.applyInlineStyle(
      contentState,
      selection,
      "red"
    );

    // Update the editor state with the new content
    const newEditorState = EditorState.push(
      this.state.editorState,
      contentStateWithColor,
      "change-inline-style"
    );

    this.setState({ editorState: newEditorState });
  };

  handleFontSizeChange = (fontSize) => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const nextContentState = RichUtils.toggleInlineStyle(
      editorState.getCurrentContent(),
      `FONT-SIZE-${fontSize}`
    );
    const nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );
    this.setState({
      editorState: RichUtils.toggleInlineStyle(nextEditorState, fontSize),
    });
  };

  handleEditorChange = (newEditorState) => {
    this.setState({ editorState: newEditorState });
  };

  handleBoldClick = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(this.state.editorState, "BOLD"),
    });
    console.log(
      "EditorState",
      RichUtils.toggleInlineStyle(this.state.editorState, "BOLD")
    );
  };

  handleItalicClick = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "ITALIC"
      ),
    });
  };

  handlePasetImage = (files) => {
    const file = files[0];
    const reader = new FileReader();
    const { editorState } = this.state;
    reader.onload = (e) => {
      const contentState = editorState.getCurrentContent();

      const contentStateWithEntity = contentState.createEntity(
        "IMAGE",
        "IMMUTABLE",
        { src: e.target.result }
      );

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = AtomicBlockUtils.insertAtomicBlock(
        contentStateWithEntity,
        entityKey,
        " "
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );
      this.handleEditorChange(newEditorState);
    };
    reader.readAsDataURL(file);
  };
  insertImage = (url) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, "");
  };

  handleButtonClickUnderline = () => {
    this.setState({
      editorState: RichUtils.toggleInlineStyle(
        this.state.editorState,
        "UNDERLINE"
      ),
    });
  };
  onToChange = (e, value) => {
    console.log("foewardvalue", e, value);
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ toInputValue: errorEmail, toError: true });
    } else {
      this.setState({ toError: false });
    }

    this.setState({ to: value.filter((email) => regex.test(email)) });
  };
  onCcChange = (e, value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ ccInputValue: errorEmail, ccError: true });
    } else {
      this.setState({ ccError: false });
    }
    // this.setState({ cc: value.filter((email) => regex.test(email)) });
    this.setState({ cc: ccInputValue });
  };
  onBccChange = (e, value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      this.setState({ bccInputValue: errorEmail, bccError: true });
    } else {
      this.setState({ bccError: false });
    }
    //this.setState({ bcc: value.filter((email) => regex.test(email)) });
    this.setState({ bcc: bccInputValue });
  };

  onToInputChange = (e) => {
    console.log("typingValue", e.target.value);
    this.setState({ toInputValue: e.target.value });
  };

  // editDoc = (selectedFile,editAttachment) => {
  //   this.setState({ editAttachment: [] });
  //   console.log('clickededitAttachment',this.state.editAttachment)
  // };

  editDoc = (selectedFile) => {
    this.setState({ selectedFile: [] }, () => {
      console.log("clickededitAttachment", this.state.selectedFile);
    });
  };

  onCcInputChange = (event, newInputValue) => {
    this.setState({ ccInputValue: newInputValue });
  };

  onBccInputChange = (event, newInputValue) => {
    this.setState({ bccInputValue: newInputValue });
  };

  handleEmailSubject = (e) => {
    this.setState({ emailSubject: this.props.inboxContent.subject });
  };

  validateField = () => {
    const { emailSubject } = this.state;
    if (emailSubject.trim() === "") {
      this.setState({ subjectErrorMessage: "Input field is required" });
      return false;
    }
    this.setState({ subjectErrorMessage: "" });
    return true;
  };

  openActionView = (e, value) => {
    this.setState({
      actionView: true,
      anchorEl: e.currentTarget,
      valueIndex: value,
    });
  };
  closeActionView = () => {
    this.setState({ actionView: false, anchorEl: null, valueIndex: {} });
  };

  filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  FileInputChange = async (e) => {
    let tempDoc = this.state.selectedtempFile;
    _.map(e.target.files, async (val) => {
      let reader = new FileReader();
      let file = val;
      console.log("dsssssssssssssssssssssssss", file);
      // Uploading file to obtain signed URL
      const signedUrl = await this.uploadFileAndGetUrl(file);

      reader.onloadend = () => {
        const fileType = val.type;
        const allowedFormats = [
          "image/jpeg",
          "image/png",
          "video/mp4",
          "video/webmp",
          "audio/mpeg",
          "audio/.wav",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

        if (val.size > 3000000) {
          toast.warn("Choose File Below 3mb", {
            position: "top-right",
            autoClose: 1000,
            zIndex: 9999,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (allowedFormats.includes(fileType)) {
          var tempValue = {
            filename: val.name,
            filetype: fileType,
            fileimage: reader.result,
            datetime: val.lastModifiedDate.toLocaleString("en-IN"),
            filesize: this.filesizes(val.size),
            attachmentUrl: signedUrl,
          };

          tempDoc.push(tempValue);
          this.setState({ selectedtempFile: tempDoc });
          this.setState({ selectedFile: e.target.files });
          // Optionally set other state values (e.g., fileNameForDoc, fileTypeStore, etc.)
          // based on your requirements
          // this.handleShow();
        } else {
          toast.warn("Invalid File Format", {
            position: "top-right",
            autoClose: 1000,
            zIndex: 9999,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };

      if (val) {
        reader.readAsDataURL(file);
      }
    });
  };

  // Function to upload file and get signed URL
  uploadFileAndGetUrl = async (file) => {
    try {
      const url = fileservice + "/fileServer/uploadMedia";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("sentBy", "Customer");
      formData.append("userID", "657c13a130135506d79a2ec3");
      formData.append("clientApp", "InapiWebchat");
      formData.append("channel", "webchat");
      formData.append("sessionId", "sessionfb557c4e14246");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          tenantId: "123456",
        },
      };

      const response = await axios.post(url, formData, config);
      if (response.data.status) {
        console.log("responseresponseresponse", response);
        return response.data.data;
      } else {
        toast.error(
          "Sorry, the file you are trying to upload is too big (maximum size is 3072KB)",
          toastOptions1
        );
        return null;
      }
    } catch (error) {
      // Handle errors appropriately
      toast.warn("Server is down, please try after some time", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return null;
    }
  };

  DeleteSelectFile = (data, i) => {
    console.log("DeleteSelectFile", data);
    const updatedFiles = this.state.selectedtempFile.filter(
      (file, index) => index !== i
    );
    this.setState({ selectedtempFile: updatedFiles });
  };

  SelectTemplate = () => {
    const Username = "imperium";
    const Password = "Indesk@$123#";

    const credentials = btoa(`${Username}:${Password}`);

    let data = {
      organization_id: "13",
      userid: 505,
      pagename: "Masters_viewmasters",
      role_id: 66,
    };

    let payload = {
      method: "post",
      maxBodyLength: Infinity,
      url: ticketUrl + "/api_new/index.php/emailtemplatelist",
      headers: {
        Authorization: `Basic ${credentials}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(payload)
      .then((response) => {
        this.setState({
          templateDropdownList: response.data.data.emailtp_list,
        });
      })
      .catch((error) => {});
  };

  handleSendEmail = () => {
    const clientJSON = localStorage.getItem("client");
    const clientObj = JSON.parse(clientJSON);
    const { selectedFile, editorState, to, cc, bcc, emailSubject, selectedtempFile, toInputValue, bccInputValue, ccInputValue } = this.state;
    console.log('selectedtempFileselectedtempFile', selectedtempFile)
    const { inboxContent, agentId } = this.props;
    console.log("sddddddddddddddddddddddddddd", this.props);
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    let requestID = "";
    let uid = 0;
    if (inboxContent) {
      let tempStr = inboxContent.subject;
      requestID = tempStr.slice(
        tempStr.indexOf("-") + 1,
        tempStr.lastIndexOf("]")
      );
      uid = inboxContent.uid;
    }
    if (html) {
      console.log("Nodata");
      if (html.trim() == "<p></p>" && !selectedFile) {
        toast.success("Please Enter The Data", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      let formdata = new FormData();

      const attachmentUrlsArray = selectedtempFile.map((file) => ({
        url: file.attachmentUrl.signedUrl,
        download: file.attachmentUrl.path,
      }));
      formdata.append("attachmentUrls", JSON.stringify(attachmentUrlsArray))
      formdata.append("email", toInputValue ? toInputValue : to);
      formdata.append("subject", emailSubject);
      // formdata.append(
      //   "ccData",
      //   this.state.ccInputValue ? this.state.ccInputValue : cc.toString()
      // );
      // formdata.append(
      //   "bccData",
      //   this.state.bccInputValue ? this.state.bccInputValue : bcc.toString()
      // );

      formdata.append("ccData", ccInputValue ? ccInputValue : cc)

      formdata.append("bccData", bccInputValue ? bccInputValue : bcc)
      formdata.append("html", html);
      formdata.append("requestID", requestID);
      const { inboxContent, agentId } = this.props;
      formdata.append("agentID", clientObj.user_id);
      // formdata.append("agentID", "654365396fd4d3aa2c03b6d5");

      formdata.append("receivedTime", moment().format());
      formdata.append("uid", uid);
      formdata.append("ticketID", inboxContent.ticketID);

      // this.props.action.SendEmail(formdata)
      // this.props.action.closeWriteEmail()
      this.props.sendMail(formdata);
      this.props.closeReplaypage();
    }
  };

  handleSendEmailSupervisor = () => {
    const {
      selectedFile,
      editorState,
      to,
      cc,
      bcc,
      emailSubject,
      editAttachment,
      selectedtempFile,
    } = this.state;

    const { inboxContent, agentId } = this.props;
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    let requestID = "";
    let uid = 0;
    if (inboxContent) {
      let tempStr = inboxContent.subject;
      requestID = tempStr.slice(
        tempStr.indexOf("-") + 1,
        tempStr.lastIndexOf("]")
      );
      uid = inboxContent.uid;
    }
    if (html) {
      if (html.trim() === "<p></p>") {
        toast.success("Please Enter The Data", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      let formdata = new FormData();
      // for (let i = 0; i < selectedFile.length; i++) {
      //   formdata.append("files", selectedFile[i]);
      // }
      // formdata.append("files", editAttachment);
      const attachmentUrlsArray = selectedtempFile.map((file) => ({
        url: file.attachmentUrl.signedUrl,
        download: file.attachmentUrl.path,
      }));
      formdata.append("attachmentUrls", JSON.stringify(attachmentUrlsArray));
      formdata.append("email", to.toString());
      formdata.append("subject", emailSubject);
      formdata.append(
        "ccData",
        this.state.ccInputValue ? this.state.ccInputValue : cc.toString()
      );
      formdata.append(
        "bccData",
        this.state.bccInputValue ? this.state.bccInputValue : bcc.toString()
      );
      formdata.append("html", html);
      formdata.append("requestID", requestID);
      formdata.append("agentID", agentId);
      formdata.append("receivedTime", moment().format());
      formdata.append("uid", uid);
      formdata.append("ticketID", inboxContent.ticketID);

      // this.props.action.SendEmail(formdata)
      // this.props.action.closeWriteEmail()
      this.props.SendEmailSupervisor(formdata);
      this.props.closeReplaypage();
    }
  };

  handleForwardEmail = () => {

    const { selectedFile, editorState, to, cc, bcc, emailSubject, toIsEmpty, selectedtempFile, toInputValue, ccInputValue, bccInputValue } =
      this.state;
    // if(to.length === 0){
    //   this.setState({ toIsEmpty: true });
    //   return;
    // }
    const { inboxContent, agentId } = this.props;
    console.log("sdddddddddddddddddddddddddddForward", this.props);
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    let requestID = "";
    let uid = 0;
    if (inboxContent) {
      let tempStr = inboxContent.subject;
      requestID = tempStr.slice(
        tempStr.indexOf("-") + 1,
        tempStr.lastIndexOf("]")
      );
      uid = inboxContent.uid;
    }
    if (html) {
      if (html.trim() === "<p></p>" && !selectedFile) {
        toast.warning("Please Enter The Data", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      // if (this.state.toInputValue.length == 0) {
      //   toast.warning("Please Enter Recipient Email Field ", {
      //     position: "top-right",
      //     autoClose: 1000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light",
      //   });
      //   return;
      // }

      let formdata = new FormData();
      const attachmentUrlsArray = selectedtempFile.map((file) => ({
        url: file.attachmentUrl.signedUrl,
        download: file.attachmentUrl.path,
      }));
      formdata.append("attachmentUrls", JSON.stringify(attachmentUrlsArray));
      // formdata.append("files", selectedFile);
      formdata.append("recipientEmail", toInputValue ? toInputValue : to);
      formdata.append("originalEmail", JSON.stringify(this.props.forwardmaillistdata));
      formdata.append("forwardedSubject", emailSubject);
      // formdata.append(
      //   "ccdata",
      //   this.state.ccInputValue ? this.state.ccInputValue : ""
      // );

      formdata.append("ccdata", ccInputValue ? ccInputValue : cc)
      // formdata.append("bccData", bcc.toString());
      formdata.append("bccData", bccInputValue ? bccInputValue : bcc);

      formdata.append("forwardedText", html);
      formdata.append("requestID", requestID);
      formdata.append("agentID", agentId.user_id);
      formdata.append("receivedTime", moment().format());
      formdata.append("uid", uid);
      formdata.append("ticketID", inboxContent.ticketID);

      // this.props.action.SendEmail(formdata)
      // this.props.action.closeWriteEmail()
      this.props.forwardMail(formdata);
      this.props.closeReplaypage();
      this.setState({ toIsEmpty: true });
    }
  };

  onCcChange = (event, newValues) => {
    this.setState({ cc: newValues }, () => {
      this.handleSendEmail(newValues);
    });
  };
  onBccChange = (event, newValues) => {
    this.setState({ bcc: newValues }, () => {
      this.handleSendEmail(newValues);
    });
  };
  handelChangeTemplate = (event) => {
    this.setState({ selectedTemplateMessage: event.target.value });
    const htmlContent = "<p>This is <b>HTML</b> content.</p>";
    const contentState = stateFromHTML(event.target.value);
    const newEditorState = EditorState.createWithContent(contentState);

    this.setState({ editorState: newEditorState });
  };

  componentDidMount = () => {
    this.filterEmails();
    this.SelectTemplate();
  };

  handleEditorChange = (newEditorState) => {
    const selection = newEditorState.getSelection();
    const blockKey = selection.getStartKey();
    const blockText = newEditorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getText();

    const charBeforeCursor = blockText.charAt(selection.getStartOffset() - 1);

    if (charBeforeCursor === "@") {
      const filterText = blockText.substring(
        blockText.lastIndexOf("@") + 1,
        selection.getStartOffset()
      );
      this.setState({ showPopup: true, filterText });
      this.filterEmails(filterText);
    } else if (this.state.showPopup && charBeforeCursor === " ") {
      this.setState({ showPopup: false, filterText: "" });
    } else if (this.state.showPopup) {
      const filterText = blockText.substring(blockText.lastIndexOf("@") + 1);
      this.setState({ filterText });
      this.filterEmails(filterText);
    }

    if (this.state.showPopup) {
      const suggestionPosition = this.calculateSuggestionPosition();
      console.log("suggestionPosition", suggestionPosition);
      this.setState({ editorState: newEditorState, suggestionPosition });
    } else {
      this.setState({ editorState: newEditorState });
    }
  };

  filterEmails = (filterText) => {
    const TenantID = localStorage.getItem("TenantId");
    const access_token = localStorage.getItem("access_token");
    const requestData = {
      emailId: filterText,
    };

    const config = {
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        tenantId: TenantID,
      },
    };

    axios
      .post(
        "https://gateway.inaipi.ae/v1/users/getagentaddressbook",
        requestData,
        config
      )
      .then((response) => {
        if (response.data.data) {
          const emailLists = response.data.data;
          const showPopupList = emailLists.filter((item) =>
            item.email
              .toLowerCase()
              .includes(this.state.filterText.toLowerCase())
          );
          this.setState({
            filteredEmails: showPopupList,
            optioned: emailLists.map((option) => option.email),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleEmailItemClick = (email) => {
    const { editorState, filterText } = this.state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const emailText = email.email;

    const newContentState = Modifier.replaceText(
      contentState,
      selection.merge({
        anchorOffset: selection.getStartOffset() - filterText.length,
        focusOffset: selection.getStartOffset(),
      }),
      emailText
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "replace-text"
    );

    this.setState({
      editorState: newEditorState,
      showPopup: false,
      filterText: "",
    });
  };

  // auto suggestion //
  handleKeyDown = (e) => {
    const { autoSuggestions } = this.state;
    if (e.key === "Tab" && autoSuggestions.length > 0) {
      e.preventDefault();
      const selectedSuggestion = autoSuggestions[0];
      this.handleSuggestionClick(selectedSuggestion);
    } else if (e.key === "Backspace") {
      this.setState({
        autoSuggestions: [],
        showPopup: false,
      });
    }
  };

  onBeforeInput = (chars) => {
    const { editorState, originalSuggestions } = this.state;
    const currentContentState = editorState.getCurrentContent();
    const currentSelectionState = editorState.getSelection();
    const startKey = currentSelectionState.getStartKey();
    const currentBlock = currentContentState.getBlockForKey(startKey);
    const currentText = currentBlock.getText();
    const currentOffset = currentSelectionState.getStartOffset();

    let startOfCurrentWord = currentOffset;
    while (
      startOfCurrentWord > 0 &&
      currentText[startOfCurrentWord - 1] !== " "
    ) {
      startOfCurrentWord--;
    }

    const userInput =
      currentText.slice(startOfCurrentWord, currentOffset) + chars;

      if (userInput.trim().replace(/\s/g, "").length >= 2 || (chars === " " && userInput.trim().length >= 1)) {
      const autoSuggestions = originalSuggestions
        .filter((suggestion) => {
          const lowerCaseSuggestion = suggestion.toLowerCase();
          const lowerCaseUserInput = userInput.trim().toLowerCase();
          return lowerCaseSuggestion.startsWith(lowerCaseUserInput);
        })
        .map((suggestion) => {
          const slicedSuggestion = suggestion.slice(userInput.length);
          return slicedSuggestion.length > 0 ? slicedSuggestion : suggestion;
        });

      this.setState({ autoSuggestions }, () => {
        const suggestionPosition = this.calculateSuggestionPosition();
        this.setState({ suggestionPosition });
      });
    } else if (this.state.autoSuggestions.length > 0) {
      const autoSuggestions = this.state.autoSuggestions.map((suggestion) => {
        return suggestion.slice(1);
      });

      this.setState({ autoSuggestions }, () => {
        const suggestionPosition = this.calculateSuggestionPosition();
        this.setState({ suggestionPosition });
      });
    }
  };

  handleSuggestionClick = (suggestion) => {
    const { editorState } = this.state;
    const currentSelectionState = editorState.getSelection();
    const currentContentState = editorState.getCurrentContent();
    const currentContentBlock = currentContentState.getBlockForKey(
      currentSelectionState.getStartKey()
    );

    const startOffset = currentSelectionState.getStartOffset();
    const endOffset = currentSelectionState.getEndOffset();

    let wordStart = startOffset;
    while (
      wordStart > 0 &&
      !/\s/.test(currentContentBlock.getText().charAt(wordStart - 1))
    ) {
      wordStart--;
    }

    let wordEnd = endOffset;
    while (
      wordEnd < currentContentBlock.getText().length &&
      !/\s/.test(currentContentBlock.getText().charAt(wordEnd))
    ) {
      wordEnd++;
    }

    const currentCursorPosition = currentSelectionState.getStartOffset();

    const newSelection = currentSelectionState.merge({
      anchorOffset: wordStart,
      focusOffset: wordEnd,
    });

    const newText =
      currentContentBlock.getText().slice(wordStart, wordEnd) + suggestion;

    const newContentState = Modifier.replaceText(
      currentContentState,
      newSelection,
      newText
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "replace-text"
    );

    const updatedSelection = newSelection.merge({
      anchorOffset: currentCursorPosition + suggestion.length,
      focusOffset: currentCursorPosition + suggestion.length,
    });

    const finalEditorState = EditorState.forceSelection(
      newEditorState,
      updatedSelection
    );

    this.setState({
      editorState: finalEditorState,
      autoSuggestions: [],
    });
  };
  // auto suggestion //

  calculateSuggestionPosition = () => {
    const selectionRect = window
      .getSelection()
      .getRangeAt(0)
      .getBoundingClientRect();
    const suggestionPosition = {
      top: selectionRect.bottom + window.scrollY,
      left: selectionRect.left + window.scrollX,
    };

    return suggestionPosition;
  };

  removeAttachment = (index) => {
    console.log("index", index);
    const editedAttachments = [...this.state.editAttachment];
    editedAttachments.splice(index, 1);
    this.setState({ editAttachment: editedAttachments });
  };

  removeSelectedFile = (index) => {
    const updatedFiles = [...this.state.selectedtempFile];
    updatedFiles.splice(index, 1);
    this.setState({ selectedtempFile: updatedFiles });
  };

  EmailAttachmentContentForupload(data) {
    console.log("datadatadata", data);
    const imageFormat = [
      "png",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "svg",
      "gif",
    ];
    if (!_.isEmpty(data)) {
      return (
        <>
          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              console.log("valvalimage", val);
              if (val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <Grid item xs={3}>
                    <div className="d-flex">
                      <div
                        className="attachment_container"
                        id="open-mail"
                        onClick={(e) => this.openActionView(e, val)}
                      >
                        <img
                          src={val.fileimage}
                          alt="Avatar"
                          className="image_attachment"
                        />

                        <div className="attachment_overlay">
                          <div className="attachment_text">{val.filename}</div>
                        </div>
                      </div>
                      <div>
                        <AiFillCloseCircle
                          style={{ cursor: "pointer" }}
                          id="select-file"
                          onClick={() => this.DeleteSelectFile(data, i)}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              }
            })}
          </Grid>

          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              if (!val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <Grid item xs={3}>
                    <Paper>
                      <MenuItem>
                        <ListItemIcon className="attachment_Preview_image">
                          <img
                            src={
                              val.filename.match(/.(pdf)$/i)
                                ? pdf
                                : val.filename.match(/.(docx)$/i)
                                  ? word
                                  : val.filename.match(/.(xlsx)$/i)
                                    ? excel
                                    : txt
                            }
                            height="20px"
                            weight="20px"
                          />
                        </ListItemIcon>
                        <ListItemText className="w-100 text-truncate">
                          <span className="attachment_el_text">
                            {val.filename}
                          </span>
                        </ListItemText>

                        <Typography variant="body2" color="text.secondary">
                          <IconButton
                            id="action-view"
                            onClick={(e) => this.openActionView(e, val)}
                          >
                            {" "}
                            <ExpandMoreIcon />{" "}
                          </IconButton>
                        </Typography>
                      </MenuItem>
                    </Paper>
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      );
    }
  }

  EmailAttachmentContentForEdit(data) {
    console.log("datadatadata", data);
    const imageFormat = [
      "png",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "svg",
      "gif",
    ];
    if (!_.isEmpty(data)) {
      return (
        <>
          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              console.log("valvalimage", val);
              if (val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <>
                    <Grid item xs={3}>
                      <div className="d-flex">
                        <div
                          className="attachment_container"
                          id="action-view"
                          onClick={(e) => this.openActionView(e, val)}
                        >
                          <img
                            src={val.url}
                            alt="Avatar"
                            className="image_attachment"
                          />
                          <div className="attachment_overlay">
                            <div className="attachment_text">
                              {val.filename}
                            </div>
                          </div>
                        </div>
                        {/* <div><AiFillCloseCircle style={{cursor:'pointer'}} onClick={() => this.setState({ editAttachment: [] })}/></div> */}
                        <div>
                          <AiFillCloseCircle
                            style={{ cursor: "pointer" }}
                            id="mail"
                            onClick={() => this.removeAttachment(i)}
                          />
                        </div>
                      </div>
                    </Grid>
                  </>
                );
              }
            })}
          </Grid>

          <Grid container spacing={1}>
            {_.map(data, (val, i) => {
              if (!val.filename.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
                return (
                  <Grid item xs={4}>
                    <Paper>
                      <MenuItem>
                        <ListItemIcon className="attachment_Preview_image">
                          <img
                            src={
                              val.filename.match(/.(pdf)$/i)
                                ? pdf
                                : val.filename.match(/.(docx)$/i)
                                ? word
                                : val.filename.match(/.(xlsx)$/i)
                                ? word
                                : txt
                            }
                            height="20px"
                            weight="20px"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <span className="attachment_el_text">
                            {val.filename}
                          </span>
                        </ListItemText>
                        <Typography variant="body2" color="text.secondary">
                          <IconButton
                            id="open-action"
                            onClick={(e) => this.openActionView(e, val)}
                          >
                            {" "}
                            <ExpandMoreIcon />{" "}
                          </IconButton>
                        </Typography>
                      </MenuItem>
                    </Paper>
                  </Grid>
                );
              }
            })}
          </Grid>
        </>
      );
    }
  }

  handleBulletPointsToggle = () => {
    // alert("work");
    this.setState((prevState) => ({
      editorState: RichUtils.toggleBlockType(
        prevState.editorState,
        "unordered-list-item"
      ),
    }));
  };

  handleNumberedListToggle = () => {
    this.setState((prevState) => ({
      editorState: RichUtils.toggleBlockType(
        prevState.editorState,
        "ordered-list-item"
      ),
    }));
  };

  handleDiscard = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };

  // Assuming 'selectedchanneldata' is your array

  endSupervisorMailApi = () => {
    console.log("sasssssssssssssssssssssssssssss", this.props.action);
    console.log("endSupervisorMailApiendSupervisorMailApi");
    const clientJSON = localStorage.getItem("tokenAgent");
    const clientObj = JSON.parse(clientJSON);
    let lastObject;
    let ticketIdforApi;
    let idOfmail;

    if (this.props.action.selectedchanneldata) {
      const lastObject =
        this.props.action.selectedchanneldata[
          this.props.action.selectedchanneldata.length - 1
        ];
      if (lastObject && lastObject.Email && clientObj.role == "Supervisor") {
        ticketIdforApi = lastObject.Email.ticketID;
        idOfmail = lastObject.Email._id;
      }
    }

    axios
      .post(
        BaseUrl + "/email/endSupervisorMail",
        {
          ticketID: ticketIdforApi,
          emailId: idOfmail,
          user_id: clientObj.user_id,
          agent_id: clientObj.agent_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          // props.setSelectedchanneldata(response.data.Data);
          // props.setUpdatechanneldata(response.data.EmailBody);
          localStorage.removeItem("client");
          this.props.action.setcontactlist(response.data.data);
          this.props.action.setshowExternalChat(false);
          this.props.action.setcontactlist(response.data.data);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const {
      from,
      to,
      toInputValue,
      toError,
      cc,
      ccInputValue,
      ccError,
      bcc,
      bccInputValue,
      bccError,
      selectedFile,
      selectedtempFile,
      actionView,
      anchorEl,
      emailSubject,
      templateDropdownList,
      subjectErrorMessage,
      toIsEmpty,
      bodyEmailMessage,
      editAttachment,
    } = this.state;
    const mailAddress = [
      {
        title: "rangitha.s@cognicx.com",
        mailid: "rangitha.s@cognicx.com",
      },
      { title: "belvin.d@cognicx.com" },
      { title: "vinoth.s@cognicx.com" },
      { title: "test@gmail.com" },
      { title: "nandhini.e@cognicx.com" },
      { title: "indumathi.p@cognicx.com" },
      { title: "hinduja.d@cognicx.com" },
    ];

    console.log("This props<<<<<<<<>>>>>.After render", this.props.attachment);

    const parsedEmails = ccInputValue
      .split(",")
      .map((value) => value.trim())
      .filter((value) => value !== "");
    const parsedBccEmails = bccInputValue
      .split(",")
      .map((value) => value.trim())
      .filter((value) => value !== "");
    // const parsedToEmails = toInputValue
    //   .split(",")
    //   .map((value) => value.trim())
    //   .filter((value) => value !== "");

    const LoaclValue = JSON.parse(localStorage.getItem("tokenAgent"));
    console.log("LoaclValue", LoaclValue);

    return (
      <>
        <div className="email-desc-wrapper ">
          <div className="mailcontent" style={{ width: "95%" }}>
            <>
              <InputGroup size="sm" className="mb-3" style={{ height: "30px" }}>
                <InputGroup.Text id="inputGroup-sizing-sm">To</InputGroup.Text>

                <Stack spacing={{}} sx={{ width: 740 }}>
                  <Autocomplete
                    multiple
                    size="small"
                    value={to}
                    inputValue={toInputValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ toInputValue: newInputValue });
                    }}
                    options={[...this.state.optioned]}
                    onChange={(event, newValue) => {
                      const selectedValue =
                        newValue.length > 0 ? newValue : [toInputValue];
                      console.log("selectedValue", selectedValue);
                      this.setState({ to: selectedValue });
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="ccAddr"
                        variant="standard"
                        type="email"
                      />
                    )}
                  />

                  {/* New To Value */}

                  {toIsEmpty && (
                    <p style={{ color: "red" }}>
                      Please fill in the "To" field
                    </p>
                  )}
                </Stack>
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">Cc</InputGroup.Text>

                <Stack spacing={{}} sx={{ width: 740 }}>
                  {/* <Autocomplete
                    multiple
                    size="small"
                    value={cc}
                    inputValue={ccInputValue}
                    // onChange={this.onCcChange}
                    // onChange={(e)=>{this.state.ccInputValue}}
                    onInputChange={this.onCcInputChange}
                    options={parsedEmails}
                    // options={this.state.optioned}
                    // onChange={(event, newValue) => {
                    //   console.log('newValuenewValue', newValue)
                    //   this.setState({ cc: newValue });
                    // }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="ccAddr"
                        variant="standard"
                        type="email"
                      />
                    )}
                  /> */}


                  {/* New CC */}

              


                  {/* <Autocomplete
                    multiple
                    size="small"
                    value={console.log('ccccccccccccccccccccccccccccc',cc)}
                    inputValue={ccInputValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ ccInputValue: newInputValue });
                    }}
                    options={[...this.state.optioned]}
                    onChange={(event, newValue) => {
                      // Filter out duplicate values
                      const uniqueValues = Array.from(new Set(newValue));

                      // Update the state with unique values
                      this.setState({ cc: uniqueValues });
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="ccAddr"
                        variant="standard"
                        type="email"
                      />
                    )}
                  /> */}



<Autocomplete
  multiple
  size="small"
  value={cc}
  inputValue={ccInputValue}
  onInputChange={(event, newInputValue) => {
    this.setState({ ccInputValue: newInputValue });
  }}
  options={[...this.state.optioned]}
  onChange={(event, newValue) => {
    // Filter out duplicate values
    const uniqueValues = Array.from(new Set(newValue));

    // Update the state with unique values
    this.setState({ cc: uniqueValues });
  }}
  freeSolo
  renderInput={(params) => (
    <TextField
      {...params}
      name="ccAddr"
      variant="standard"
      type="email"
      InputProps={{
        ...params.InputProps,
        value: cc.join(', '), // Join emails with commas for display
      }}
    />
  )}
/>


                  {/* New CC */}



                </Stack>
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-sm">Bcc</InputGroup.Text>

                <Stack spacing={{}} sx={{ width: 740 }}>
                  {/* <Autocomplete
                    multiple
                    size="small"
                    // onChange={this.onBccChange}
                    value={bcc}
                    inputValue={bccInputValue}
                    onInputChange={this.onBccInputChange}
                    options={parsedBccEmails}
                    //options={mailAddress.map((option) => option.title)}
                    // options={this.state.optioned}
                    // onChange={(event, newValue) => {
                    //   console.log('newValuenewValueBcc', newValue)
                    //   this.setState({ bcc: newValue });
                    // }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="bccAddr"
                        variant="standard"
                        type="email"
                        error={bccError}
                      />
                    )}
                  /> */}

                  {/* New Bcc */}

                  <Autocomplete
                    multiple
                    size="small"
                    value={bcc}
                    inputValue={bccInputValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ bccInputValue: newInputValue });
                    }}
                    options={[...this.state.optioned]}
                    onChange={(event, newValue) => {
                      const selectedValue =
                        newValue.length > 0 ? newValue : [bccInputValue];
                      console.log("selectedValue", selectedValue);
                      this.setState({ bcc: selectedValue });
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="ccAddr"
                        variant="standard"
                        type="email"
                      />
                    )}
                  />

                  {/* New Bcc */}
                </Stack>
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Add a Subject"
                  aria-label="Add a Subject"
                  aria-describedby="basic-addon2"
                  value={
                    this.props.inboxContent
                      ? this.props.inboxContent.subject
                      : "subject"
                  }
                  onChange={this.handleEmailSubject}
                  disabled
                />
                {/* {subjectErrorMessage ? <p style={{ color: 'red' }}>Fill The Field</p>:''} */}
              </InputGroup>

              {this.props.userlistshow ? (
                <select
                  className="form-select form-select-sm w-50"
                  aria-label="Default select example"
                  onChange={this.handelChangeTemplate}
                >
                  <option selected value="">
                    Select Template
                  </option>
                  {this.state.templateDropdownList.map((item) => {
                    return (
                      <option value={item.description}>
                        {item.emailtemplate_name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  className="form-select form-select-sm w-25"
                  aria-label="Default select example"
                  onChange={this.handelChangeTemplate}
                >
                  <option selected value="">
                    Select Template
                  </option>
                  {this.state.templateDropdownList.map((item) => {
                    return (
                      <option id={item.id} value={item.description}>
                        {item.emailtemplate_name}
                      </option>
                    );
                  })}
                </select>
              )}
              <br />
            </>

            <div
              className="email-body-content-write"
              style={{ height: this.props.userlistshow ? "130px" : "150px" }}
            >
              <div
                className="email-body-content-write-inside"
                style={{ height: this.props.userlistshow ? "120px" : "140px" }}
                onKeyDown={this.handleKeyDown}
              >
                {!_.isEmpty(selectedtempFile)
                  ? this.EmailAttachmentContentForupload(selectedtempFile)
                  : null}

                {!_.isEmpty(editAttachment)
                  ? this.EmailAttachmentContentForEdit(editAttachment)
                  : null}

                <Editor
                  ref={(editor) => (this.editorRef = editor)}
                  editorState={this.state.editorState}
                  onChange={this.handleEditorChange}
                  customStyleMap={customStyleMap}
                  handleBeforeInput={this.onBeforeInput}
                  handlePastedFiles={this.handlePasetImage}
                />

                {this.props.forwardmaillistdata.map((mail) => {
                  return (
                    // console.log('itemitemForwardMAil',item)
                    <div className="p-1 ">
                      <div style={{ width: "100%" }}>
                        {mail.length != 0 ? (
                          <div className="email-desc-wrapper">
                            <div className="title-subject">
                              <h5>{mail.subject}</h5>
                            </div>
                            <Accordion
                              style={{
                                marginTop: "15px",
                              }}
                            >
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  <div className="email-header d-flex w-100">
                                    <div
                                      className="avatar-initials d-flex my-2"
                                      style={{
                                        // background: randomColors[index],
                                        width: "38px",
                                        height: "38px",
                                        borderRadius: "50%",
                                        color: "gray",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        position: "relative",
                                        boxShadow:
                                          "rgb(0 0 0 / 0%) 0px 1px 3px, rgb(0 0 0 / 14%) 0px 1px 2px",
                                      }}
                                    >
                                      <span className="d-flex justify-content-center align-items-center mx-auto">
                                        {mail.fromList
                                          .substring(0, 2)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                    {/* <img
                                      src={avatar}
                                      alt="Profile Picture"
                                    /> */}
                                    <div className="address mx-2 my-2 ">
                                      <p className="recipient" id="from"></p>
                                      <p className="recipient fs-6" id="to">
                                        <span>
                                          To:
                                          {mail.toList}
                                        </span>
                                      </p>
                                      {mail.ccList ? (
                                        <p className="recipient fs-6" id="cc">
                                          <span>
                                            Cc:
                                            {mail.ccList}
                                          </span>
                                        </p>
                                      ) : null}
                                      <p className="recipient fs-6" id="date">
                                        <span>
                                          {moment(mail.receivedTime).format(
                                            "MMMM Do YYYY, h:mm A"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {mail.attachments.length > 0 ? (
                                  <>
                                    {mail.attachments.map((item, index) => (
                                      // console.log('insideinput',item)
                                      <div key={index}>
                                        {item.filename.includes(".pdf") && (
                                          // Design for PDF attachment
                                          <div className="card d-flex w-25">
                                            <div className="card d-flex">
                                              <a href={item.url}>
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={pdf}
                                                    height="25px"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                  <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  ></a>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                        {item.filename.includes(".xlsx") && (
                                          <div className="card d-flex w-25">
                                            <div className="card d-flex">
                                              <a href={item.url}>
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={excel}
                                                    height="25px"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                  <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  ></a>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        )}

                                        {(item.filename.includes(".docx") ||
                                          item.filename.includes(".doc")) && (
                                          <div className="card d-flex w-25">
                                            <div className="card d-flex">
                                              <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <div className="card-body d-flex">
                                                  <img
                                                    src={word}
                                                    height="25px"
                                                    alt=".docx or .doc"
                                                  />
                                                  <b className="text-truncate mx-1">
                                                    {item.filename}
                                                  </b>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        )}

                                        {item.filename.includes(".png") && (
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                            />
                                          </a>
                                        )}
                                        {item.filename.includes(".jpg") && (
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                            />
                                          </a>
                                        )}
                                        {item.filename.includes(".jpeg") && (
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={item.url}
                                              height="80px"
                                              width="auto"
                                            />
                                          </a>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}

                                <Typography>
                                  <div className="mailcontent">
                                    <div className="email-body">
                                      {/* {mail.emailBody} */}
                                      {mail.emailBody
                                        ? parse(
                                            mail.emailBody
                                              .replace(/\s+/g, " ")
                                              .trim()
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ) : (
                          <div
                            className="recive-hed"
                            style={{ width: "100%" }}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}

                {/* </Form.Group> */}
                {/* <Editor
                  ref={(editor) => (this.editorRef = editor)}
                  editorState={this.state.editorState}
                  onChange={this.handleEditorChange}
                  customStyleMap={customStyleMap}
                  handleBeforeInput={this.onBeforeInput}
                  handlePastedFiles={this.handlePasetImage}
                /> */}
                {this.state.autoSuggestions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: this.state.suggestionPosition.top,
                      left: this.state.suggestionPosition.left,
                    }}
                  >
                    {this.state.autoSuggestions.map((suggestion, index) => (
                <div
                  className="email-auto-correction"
                  key={index}
                  onClick={() => this.handleSuggestionClick(suggestion)}
                >
                  <div className="d-flex">
                    {suggestion}
                    <div className="tabForEmailSuggestion mx-2">
                      <div style={{ fontSize: "10px" }}>Tab</div>
                    </div>
                  </div>
                </div>
              ))}
                  </div>
                )}


                {this.state.showPopup && (
                  <div
                    style={{
                      position: "absolute",
                      top: this.state.suggestionPosition.top,
                      left: this.state.suggestionPosition.left,
                    }}
                  >
                    <div className="popup-email-open">
                      <div className="popup-content">
                        {this.state.filteredEmails.map((email, index) => (
                          <div
                            className="popup-email-items"
                            id="click-mail"
                            onClick={() => this.handleEmailItemClick(email)}
                            key={index}
                          >
                            <div className="d-flex">
                              <div className="email-profile-lists">
                                <div className="email-profile-twoLetter">
                                  {email.email
                                    ? email.email.slice(0, 2).toUpperCase()
                                    : ""}
                                </div>
                              </div>
                              <div className="email-profile-emailsID ms-1">
                                {email.email}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="email-action-newEmail">
              {this.props.userlistshow ? (
                <>
                  <div className="d-flex">
                    <SplitButton
                      id="userlist-open"
                      onClick={() => {
                        console.log(
                          "this.props.action.selectedChanneldata",
                          this.props.action
                        );
                        if (this.props.action.selectedchanneldata.length > 0) {
                          const lastObject =
                            this.props.action.selectedchanneldata[
                              this.props.action.selectedchanneldata.length - 1
                            ];
                          if (
                            lastObject &&
                            lastObject.Email &&
                            lastObject.Email.supervisorFlag == true
                          ) {
                            this.handleSendEmailSupervisor();
                            this.endSupervisorMailApi();
                          } else if (
                            lastObject &&
                            lastObject.Email &&
                            lastObject.Email.supervisorFlag == false &&
                            this.props.action.forwardmail == false
                          ) {
                            this.handleSendEmail();
                          } else if (this.props.action.forwardmail === true) {
                            this.handleForwardEmail();
                          } 
                        } else {
                          console.log(
                            "The array contains only one or zero values."
                          );
                        }

                        //OLD Code

                        // if (this.state.forwardEmailforForward == true) {
                        //   this.handleForwardEmail()
                        // } else {
                        //   this.handleSendEmail()

                        // }
                      }}
                      variant="primary"
                      title="Send"
                      id="segmented-button-dropdown-1"
                    >
                      <Dropdown.Item href="#">Send</Dropdown.Item>
                      <Dropdown.Item href="#">Schedule Send</Dropdown.Item>
                    </SplitButton>
                    &nbsp;
                    <button
                      variant="light"
                      id="btn-discard"
                      className="btn btn-warning btn-sm"
                      onClick={this.handleDiscard}
                    >
                      Discard
                    </button>
                    &nbsp;
                  </div>

                  {/* <div className="d-flex">
                    <div className="palette-option">
                      <label className="attachment">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={this.FileInputChange}
                          multiple
                        />
                        <AttachFileIcon />
                      </label>
                    </div>
                    &nbsp;
                    <div className="palette-option">
                      <label className="attachment">
                        <input type="file" />
                        <InsertPhotoIcon />
                      </label>
                    </div>
                    <div className="palette-option1">
                      <select className="font-list">
                        <option>Arial</option>
                        <option>Calibri</option>
                        <option>Times New Roman</option>
                      </select>
                    </div>
                    <div className="palette-option1">
                      <select className="font-list">
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </div>
                  </div> */}

                  <div>
                    <button
                      id="btn-small"
                      onClick={() => this.handleFontSizeChange("30")}
                    >
                      Small
                    </button>
                    <div className="palette-option1">
                      <ToggleButton
                        value="bold"
                        aria-label="bold"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatBoldIcon
                          id="btn-small"
                          onClick={this.handleBoldClick}
                        />
                      </ToggleButton>
                    </div>
                    <div className="palette-option1">
                      <ToggleButton
                        value="italic"
                        aria-label="italic"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatItalicIcon
                          id="btn-small"
                          onClick={this.handleItalicClick}
                        />
                      </ToggleButton>
                    </div>
                    <div className="palette-option1">
                      <ToggleButton
                        value="underlined"
                        aria-label="underlined"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatUnderlinedIcon
                          id="btn-small"
                          onClick={this.handleButtonClickUnderline}
                        />
                      </ToggleButton>
                    </div>
                    {this.state.iscolor ? (
                      <ChromePicker
                        color={this.state.color}
                        onChange={this.handleTextColorChange}
                      />
                    ) : (
                      ""
                    )}
                    <div className="palette-option1">
                      <ToggleButton
                        value="highlight"
                        aria-label="highlight"
                        size="small"
                        id="fnt-style"
                      >
                        <BorderColorIcon
                          id="btn-small"
                          onClick={() => {
                            this.setState({ iscolor: !this.state.iscolor });
                          }}
                        />
                      </ToggleButton>
                    </div>
                    <div className="palette-option1">
                      <ToggleButton
                        value="colortext"
                        aria-label="colortext"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatColorTextIcon
                          id="btn-small"
                          onClick={() => {
                            this.setState({ iscolor: !this.state.iscolor });
                          }}
                        />
                      </ToggleButton>
                    </div>
                    <div className="palette-option1">
                      <ToggleButton
                        value="listbulleted"
                        aria-label="listbulleted"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatListBulletedIcon
                          id="bullet-icon"
                          onClick={this.handleBulletPointsToggle}
                        />
                      </ToggleButton>
                    </div>
                    <div className="palette-option1">
                      <ToggleButton
                        value="listnumbered"
                        aria-label="listnumbered"
                        size="small"
                        id="fnt-style"
                      >
                        <FormatListNumberedIcon
                          id="btn-number"
                          onClick={this.handleNumberedListToggle}
                        />
                      </ToggleButton>
                    </div>
                    {/* <div className="palette-option1">
                    <ToggleButton
                      value="indentincrease"
                      aria-label="indentincrease"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatIndentIncreaseIcon />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="indentdecrease"
                      aria-label="indentdecrease"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatIndentDecreaseIcon />
                    </ToggleButton>
                  </div> */}
                  </div>
                </>
              ) : (
                <>
                  <SplitButton
                    id="btn-small"
                    onClick={() => {
                      if (this.props.action.selectedchanneldata.length > 0) {
                        const lastObject =
                          this.props.action.selectedchanneldata[
                            this.props.action.selectedchanneldata.length - 1
                          ];
                        if (
                          lastObject &&
                          lastObject.Email &&
                          LoaclValue.role == "Supervisor"
                        ) {
                          this.handleSendEmailSupervisor();
                          this.endSupervisorMailApi();
                        } else if (
                          lastObject &&
                          lastObject.Email &&
                          this.props.action.forwardmail == false &&
                          LoaclValue.role == "Agent"
                        ) {
                          this.handleSendEmail();
                        } else if (this.props.action.forwardmail === true) {
                          this.handleForwardEmail();
                        } else {
                        }
                      } else {
                        console.log(
                          "The array contains only one or zero values."
                        );
                      }

                      //   if (this.props.action.selectedchanneldata.length > 0) {
                      //   const lastObject =
                      //     this.props.action.selectedchanneldata[
                      //     this.props.action.selectedchanneldata.length - 1
                      //     ];
                      //   if (  lastObject &&
                      //     lastObject.Email &&
                      //     this.props.action.forwardmail == false && LoaclValue.role=='Agent'

                      //   ) {
                      //     this.handleSendEmail();

                      //   } else if (
                      //     this.props.action.forwardmail === true
                      //   ) {
                      //     this.handleForwardEmail();

                      //   } else if (   lastObject &&
                      //        lastObject.Email &&
                      //        LoaclValue.role=='Supervisor'){

                      //         this.handleSendEmailSupervisor();
                      //         this.endSupervisorMailApi();

                      //   } else {

                      //   }
                      // } else {
                      //   console.log(
                      //     "The array contains only one or zero values."
                      //   );
                      // }

                      // if (this.props.selectedChanneldata.length > 0) {
                      //   const lastObject = this.props.selectedChanneldata[this.props.selectedChanneldata.length - 1];
                      //   if (lastObject && lastObject.Email && lastObject.Email.supervisorFlag == true) {
                      //     this.handleSendEmailSupervisor();
                      //     this.endSupervisorMailApi();
                      //   } else if (lastObject && lastObject.Email && lastObject.Email.supervisorFlag == false) {
                      //     this.handleSendEmail();
                      //   } else if (this.state.forwardEmailforForward === true) {
                      //     this.handleForwardEmail();
                      //   } else {
                      //
                      //   }
                      // } else {
                      //
                      // }

                      //   if (this.state.forwardEmailforForward == true) {
                      //   this.handleForwardEmail()
                      // } else {
                      //   this.handleSendEmail()
                      //   this.handleSendEmailSupervisor()
                      //   this.endSupervisorMailApi()
                      // }
                    }}
                    variant="primary"
                    title="Send"
                  >
                    <Dropdown.Item href="#">Send</Dropdown.Item>
                    <Dropdown.Item href="#">Schedule Send</Dropdown.Item>
                  </SplitButton>
                  &nbsp;
                  <button
                    variant="light"
                    id="segmented-button-dropdown-1"
                    className="btn btn-warning"
                    onClick={this.handleDiscard}
                  >
                    Discard
                  </button>
                  &nbsp;
                  <div className="palette-option">
                    <label className="attachment">
                      <input
                        type="file"
                        id="fileupload"
                        className="file-upload-input"
                        onChange={this.FileInputChange}
                        multiple
                      />
                      <AttachFileIcon />
                    </label>
                  </div>
                  &nbsp;
                  <div className="palette-option">
                    <label className="attachment">
                      <input type="file" />
                      <InsertPhotoIcon />
                    </label>
                  </div>
                  {/* <div className="palette-option1">
                    <select className="font-list">
                      <option>Arial</option>
                      <option>Calibri</option>
                      <option>Times New Roman</option>
                    </select>
                  </div> */}
                  {/* <div className="palette-option1">
                    <select className="font-list">
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div> */}
                  {/* <button onClick={() => this.handleFontSizeChange("30")}>
                    Small
                  </button> */}
                  <div className="palette-option1">
                    <ToggleButton
                      value="bold"
                      aria-label="bold"
                      size="small"
                      id="fnt-style"
                    >
                    
                      <FormatBoldIcon  onClick={this.handleBoldClick} />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="italic"
                      aria-label="italic"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatItalicIcon onClick={this.handleItalicClick} />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="underlined"
                      aria-label="underlined"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatUnderlinedIcon
                        onClick={this.handleButtonClickUnderline}
                      />
                    </ToggleButton>
                  </div>
                  {this.state.iscolor ? (
                    <ChromePicker
                      color={this.state.color}
                      onChange={this.handleTextColorChange}
                    />
                  ) : (
                    ""
                  )}
                  <div className="palette-option1">
                    <ToggleButton
                      value="highlight"
                      aria-label="highlight"
                      size="small"
                      id="fnt-style"
                    >
                      <BorderColorIcon
                        onClick={() => {
                          this.setState({ iscolor: !this.state.iscolor });
                        }}
                      />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="colortext"
                      aria-label="colortext"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatColorTextIcon
                        onClick={() => {
                          this.setState({ iscolor: !this.state.iscolor });
                        }}
                      />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="listbulleted"
                      aria-label="listbulleted"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatListBulletedIcon
                        onClick={this.handleBulletPointsToggle}
                      />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="listnumbered"
                      aria-label="listnumbered"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatListNumberedIcon
                        onClick={this.handleNumberedListToggle}
                      />
                    </ToggleButton>
                  </div>
                  {/* <div className="palette-option1">
                    <ToggleButton
                      value="indentincrease"
                      aria-label="indentincrease"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatIndentIncreaseIcon />
                    </ToggleButton>
                  </div>
                  <div className="palette-option1">
                    <ToggleButton
                      value="indentdecrease"
                      aria-label="indentdecrease"
                      size="small"
                      id="fnt-style"
                    >
                      <FormatIndentDecreaseIcon />
                    </ToggleButton>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>

        {/* <Popover
          id="simple-popover"
          open={actionView}
          anchorEl={anchorEl}
          onClose={this.closeActionView}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper sx={{ width: 200, maxWidth: "100%" }}>
            <MenuList>
              <MenuItem id="open-doc" onClick={this.openDocument}>
                <ListItemIcon>
                  <VisibilityIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Preview</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem id="open-doc" onClick={this.downloadDoc}>
                <ListItemIcon>
                  <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Download</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem
              id="open-doc"
                onClick={() => {
                  this.editDoc(selectedFile);
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>      
            </MenuList>
          </Paper>
        </Popover> */}
      </>
    );
  }
}
const customStyleMap = {
  FONT_SIZE_40: {
    fontSize: "40px",
  },
};
