import React from "react";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { RiErrorWarningLine } from "react-icons/ri";
import {
  setIswrite,
  setcouplemail,
  setCannedmessagediv,
  setCustomerDetailsShow,
  setUserlistShow,
} from "../../../../redux/actions/actions";
import { randomColors } from "../../colorpallet";
const mapStateToProps = (state) => {
  const { data } = state;
  return {
    externalChatActive: data.externalChatActive,
    contactList: state.contactListData.contactList,
    cannedmessagediv: data.cannedmessagediv,
  };
};
const ExternalContactList = (props) => {
  const { onclickhandlechangeexternalContact } = props;

  const formatTime = (lastmessagetime) => {
    if (lastmessagetime) {
      const messageTime = moment(lastmessagetime, "hh:mm:A");
      const currentTime = moment();
      const diffMinutes = currentTime.diff(messageTime, "minutes");

      if (diffMinutes < 1) {
        return "just now";
      } else if (diffMinutes < 60) {
        return `${diffMinutes} min ago`;
      } else if (diffMinutes < 120) {
        const diffHours = Math.floor(diffMinutes / 60);
        const remainingMinutes = diffMinutes % 60;
        return `${diffHours}hr ${remainingMinutes}min ago`;
      } else if (diffMinutes < 1440) {
        const diffHours = Math.floor(diffMinutes / 60);
        return `${diffHours} ${diffHours === 1 ? "hour" : "hours"} ago`;
      } else if (diffMinutes < 2880) {
        return "yesterday";
      } else {
        const diffDays = Math.floor(diffMinutes / 1440);
        return `${diffDays} ${diffDays === 1 ? "day" : "days"} ago`;
      }
    } else {
      return "";
    }
  };
  return (
    <>
      {props.contactList.length > 0 ? (
        props.contactList.map((contact, index) =>
          contact != undefined ? (
            <div
              key={index}
              id="pills-tabs"
              className={
                contact != undefined
                  ? props.externalChatActive == contact.id
                    ? "chat-contact d-flex p-1 justify-content-evenly align-items-center chat-active"
                    : "chat-contact d-flex p-1 justify-content-evenly align-items-center border-bottom"
                  : ""
              }
              onClick={() => {
                props.setIswrite(false);
                props.setcouplemail(true);
                props.setCannedmessagediv(false);
                props.setCustomerDetailsShow(false);
                onclickhandlechangeexternalContact(contact, index);
              }}
              style={{ borderRadius: "10px" }}
            >
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex me-1">
                  <div
                    className="col-2 me-2 photo position-relative"
                    style={{
                      background: randomColors[index],
                    }}
                  >
                    <p
                      className=" chat_name"
                      style={{
                        color: "#7e7272",
                        textAlign: "center",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    >
                      <b
                        className="h-100"
                        style={{
                          color: "#FFFF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {contact.channel == "voice"
                          ? contact.unique_id.phonenumber.slice(0, 2)
                          : contact.unique_id.username
                              .slice(0, 2)
                              .toUpperCase()}
                      </b>
                    </p>

                    {/* social media icons */}
                    {contact.channel === "webchat" ? (
                      <div className="twit-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-brands fa-weixin"></i>
                      </div>
                    ) : contact.channel === "instagram" ? (
                      <div className="inst-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-brands fa-instagram"></i>
                      </div>
                    ) : contact.channel === "from_whatsapp" ? (
                      <div className="what-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-brands fa-whatsapp"></i>
                      </div>
                    ) : contact.channel === "voice" ? (
                      <div className="what-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-solid fa-phone"></i>
                      </div>
                    ) : contact.channel === "from_facebook" ? (
                      <div className="fb-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-brands fa-facebook-f"></i>
                      </div>
                    ) : contact.channel === "from_twitter" ? (
                      <div className="twit-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-brands fa-twitter"></i>
                      </div>
                    ) : contact.channel === "email" ? (
                      <div
                        className="twit-bg chat-social d-flex justify-content-center align-items-center"
                        style={{ background: "" }}
                      >
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                    ) : contact.channel === "from_purple" ? (
                      <div className="twit-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-solid fa-robot"></i>
                      </div>
                    ) : (
                      <div className="twit-bg chat-social d-flex justify-content-center align-items-center">
                        <i className="fa-solid fa-video-camera"></i>
                      </div>
                    )}
                    {contact.unreadcount != 0 && (
                      <Badge
                        bg="danger"
                        className="mt-1"
                        style={{
                          borderRadius: "50%",
                          fontSize: "8px",
                          position: "relative",
                          bottom: "5px",
                        }}
                      >
                        {contact.unreadcount}
                      </Badge>
                    )}
                  </div>
                  <div className="chat-content-main d-flex">
                    <div className="chat-details me-1">
                      <div className="d-flex align-items-center">
                        {/* User Name */}
                        <div className="name d-inline-block text-truncate me-1" style={{maxWidth:'150px'}}>
                          {contact.channel === "email"
                            ? contact.email
                            : contact.unique_id.username
                            ? contact.unique_id.username
                            : contact.unique_id.phonenumber}
                        </div>

                        {/* online offline icon */}

                        {contact.is_customer_disconnected === true ? (
                          <div className="discont"></div>
                        ) : (
                          <div className="online"></div>
                        )}
                      </div>

                      {/* last message */}
                      <p className="d-inline-block text-truncate w-50 mb-0">
                        {contact.lastmessage}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Last Seen */}
                <div className="chat-time d-flex justify-content-start flex-column align-items-end">
                  <p className="mb-0" style={{ fontSize: "7px" }}>
                    {formatTime(contact.lastmessagetime)}
                  </p>

                  <span className="d-flex " style={{ flexDirection: "column" }}>
                    {contact.conference ? (
                      <span
                        className="chat_type"
                        style={{
                          fontSize: 7,
                          fontWeight: "bold",
                          color: "#5e5e5e",
                        }}
                      >
                        Conference
                      </span>
                    ) : (
                      ""
                    )}
                    {contact.transferred ? (
                      <span
                        className="chat_type"
                        style={{
                          fontSize: 7,
                          fontWeight: "bold",
                          color: "#5e5e5e",
                        }}
                      >
                        Transferred
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )
        )
      ) : (
        <>
          <div
            className="text-muted d-flex justify-content-center"
            style={{ flexDirection: "column", marginTop: "170px" }}
          >
            <span className="mx-auto">
              <RiErrorWarningLine size={30} />
            </span>
            <span className="mx-auto">
              <h6 className="text-muted mt-2" style={{ fontFamily: "poppins" }}>
                No interaction activities
              </h6>
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setIswrite,
  setcouplemail,
  setCannedmessagediv,
  setCustomerDetailsShow,
  setUserlistShow,
})(ExternalContactList);
