import {
  SET_CONTACT_LIST1,
  SET_CONTACT_LIST_UPDATED1,
  SET_CONTACT_LISTUPDATED,
} from "../../redux/actions/type";
const initialState1 = {
  notification: [],
};


export const notificationReducer = (state = initialState1, action) => {
  switch (action.type) {
    case SET_CONTACT_LIST1:
      return {
        ...state,
        notification: [...state.notification, action.val],
      };
    case SET_CONTACT_LIST_UPDATED1:
      return {
        ...state,
        notification: [],
      };
    case SET_CONTACT_LISTUPDATED:
      return {
        ...state,
        notification: action.val,
      };
    default:
      return state;
  }
};
