import React, { useState, useRef, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import AudioPlayer from "material-ui-audio-player";
import axios from "axios";
import "./Chat-Style/chat.css";
import { ImCross } from "react-icons/im";
import { AiOutlineCloseCircle, AiOutlineDelete } from "react-icons/ai";
import { AudioRecorder } from "react-audio-voice-recorder";
import { toast } from "react-toastify";
import { BaseUrl, errorApi } from "../../../container/BaseUrl/BaseUrl";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import {
  settogglechat,
  setSearchterm,
  setCannedmessagediv,
} from "../../../redux/actions/actions";
import { AiOutlinePlus } from "react-icons/ai";
import Emoji from "./Emoji.component";
import Foroutsideclick from "../../outside-click/Foroutsideclick";
import { RiDeleteBin6Line, RiErrorWarningFill } from "react-icons/ri";

const mapStateToProps = (state) => {
  const { data, contactListData } = state;
  return {
    contactList: contactListData.contactList,
    chat: data.chat,
    togglechat: data.togglechat,
    cannedmessagediv: data.cannedmessagediv,
  };
};
const ChatInput = (props) => {
  const muiTheme = createMuiTheme({});
  const datas = JSON.parse(localStorage.getItem("tokenAgent"));
  const tenantId = localStorage.getItem("TenantId");
  const [msg, setMsg] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [file, setFile] = useState("");
  const [show, setShow] = useState(false);
  const [audioUrl, setaudioUrl] = useState([]);
  const [fileTypeStore, setFileTypeStore] = useState("");
  const [fileUploadName, setfileUploadName] = useState("");
  const [fileSendName, setfileSendName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showcannedmodal, setShowcannedmodal] = useState(false);
  const [showMessage, setShowMessage] = useState([]);
  const [createMsg, setCreateMsg] = useState("");
  const [voiceRecord, setVoiceRecord] = useState(true);
  const [recorddata, setRecorddata] = useState("");
  const [preview, setPreview] = useState(true);
  const [src, setSrc] = useState("");
  const [cannemessagestore, setCannemessagestore] = useState("");
  const handleClosecannedmessage = () => {
    setShowcannedmodal(false), setSubmitClicked(""), setCreateMsg("");
  };
  const handleShowcannedmessage = () => setShowcannedmodal(true);
  const [sendButtonVisible, setSendButtonVisible] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // ! This Section is for emoji///////////////////////////////////////////////////////////////////
  const inputRef = createRef();
  const [showPicker, setShowPicker] = useState(false);
  const [cursorPosition, setcursorPosition] = useState();
  const onEmojiClick = (e) => {
    setShowPicker(!showPicker);
    const ref = inputRef.current;
    ref.focus();
    const start = msg.substring(0, ref.selectionStart);
    const end = msg.substring(ref.selectionEnd);
    const text = start + e.emoji + end;
    setMsg(text);
    setcursorPosition(start.length + e.emoji.length);
  };
  useEffect(() => {
    inputRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);
  // ! This Section is for emoji/////////////////////////////////////////////////////////////////

  // * audio functionality/////////////////////////////////////////////////////////////////////
  const [showaudiopreview, setshowaudiopreview] = useState(false);
  const [recorderVoice, setrecorderVoice] = useState();

  const addAudioElement = (blob) => {
    setRecorddata(blob);
    setshowaudiopreview(true);
    const url = URL.createObjectURL(blob);
    setrecorderVoice(url);
  };

  // * audio functionality/////////////////////////////////////////

  useEffect(() => {
    if (props.chat) {
      retrieveScannedMsg();
    }
  }, []);

  const showuserNotification = () => {
    props.setCannedmessagediv(!props.cannedmessagediv);
  };
  const closeuserNotification = () => {
    props.setCannedmessagediv(false);
  };
  const closecannedmessage = () => {
    props.setCannedmessagediv(false);
  };
  const errorHandel = async (error, endpoint) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      const payload = {
        servicename: "DCCCHAT",
        logs: error,
        description: endpoint,
      };

      const header = {
        headers: {
          tenantid: tenantID,
        },
      };
      const { data } = await axios.post(
        errorApi + "/UIlogs/createLogs",
        payload,
        header
      );
      if (data.status) {
        //
      }
    } catch (error) {
      //
    }
  };

  const handleClose = () => {
    setShow(false);
    setFileTypeStore("");
  };
  const handleShow = () => setShow(true);

  const sendChat = (event) => {
    if (
      (event.key === "Enter" && !event.shiftKey) ||
      event._reactName === "onClick"
    ) {
      event.preventDefault();
      if (msg.length >= 1000) {
        event.preventDefault();
        toast.error("More than 1000 Character Not Allowed", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      event.preventDefault();
      if (props.data.chat.is_customer_disconnected) {
        props.cobrowserdata("");
      } else {
        if (msg.length > 0 && msg.length < 1000) {
          props.handleSendMsg(msg, "TEXT", "");
          props.setSearchterm("");
          setMsg("");
        } else if (msg.length > 1000) {
          toast.error(
            "You'll need to shorten your message to send it",
            toastOptions
          );
        }
      }
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMsg(msg + "\n");
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    console.log("fileUploaded", fileUploaded);
    const fileType = fileUploaded.type;

    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "video/mp4",
      "video/webmp",
      "audio/mpeg",
      "audio/.wav",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];

    if (fileUploaded.size > 3000000) {
      toast.warn("Choose File Below 3mb", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else if (allowedFormats.includes(fileType)) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setFile(fileUploaded);
      };

      reader.readAsDataURL(fileUploaded);
      let split_fileType = event.target.files[0].type.split("/");
      setFileTypeStore(split_fileType[0]);
      setfileUploadName(fileUploaded);
      setfileSendName(event.target.files[0].name);
      handleShow();
    } else {
      toast.warn("Invalid File Format", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const retrieveScannedMsg = () => {
    let data = {
      offset: "0",
      limit: "10",
      agent_id: datas.agent_id,
    };
    const access_token = localStorage.getItem("access_token");
    axios
      .post(BaseUrl + "/scannedMessage/listMessage", data, {
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          tenantId: tenantId,
        },
      })
      .then((res) => {
        if (res.data.status == true) {
          // console.log('ressssssssssssss',res.data.data)
          // if (res.data.data) {
          //   let resp = res.data.data;
          setShowMessage(res.data.data);

          // }
        } else {
          setShowMessage([]);
        }
      })
      .catch((error) => {
        errorHandel(error, "/scannedMessage/listMessage");
      });
  };

  const deleteScannedMessage = (id) => {
    let data = {
      id: id,
    };
    const access_token = localStorage.getItem("access_token");
    axios
      .post(BaseUrl + "/scannedMessage/deleteScannedMessage", data, {
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          tenantId: tenantId,
        },
      })
      .then((res) => {
        if (res.status) {
          retrieveScannedMsg();
          toast.success("Canned Message Deleted", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {
        errorHandel(error, "/scannedMessage/deleteScannedMessage");
      });
  };

  const create_contact = () => {
    const access_token = localStorage.getItem("access_token");
    if (createMsg === "") {
      setSubmitClicked("Please create a canned message.");
      toast.warning("Message is missing", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }

    let data = {
      message: createMsg,
      agent_id: datas.agent_id,
    };
    axios
      .post(BaseUrl + "/scannedMessage/createMessage", data, {
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
          tenantId: tenantId,
        },
      })
      .then((res) => {
        if (res.status) {
          toast.success("Canned Message Added", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            retrieveScannedMsg();
            setShowcannedmodal(false);
            setCreateMsg("");
            setSubmitClicked("");
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response.data.message == "Message Already exist") {
          toast.warning("Message Already exist", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
        errorHandel(error, "/scannedMessage/createMessage");
      });
  };

  const sendFile = () => {
    let send_file;
    if (fileUploadName.name) {
      send_file = fileUploadName;
      setLoading(true);
    } else {
      send_file = file;
    }
    const url = "https://qacc.inaipi.ae/v1/fileServer/uploadMedia";
    const formData = new FormData();
    formData.append("file", send_file);
    formData.append(
      "userID",
      props.data.chat.unique_id
        ? props.data.chat.unique_id.id
        : props.data.chat.senderDetails[0]._id
    );
    formData.append("clientApp", "InapiWebchat");
    formData.append("channel", "webchat");
    formData.append("sessionId", props.data.chat.chat_session_id);
    formData.append("sentBy", "Agent");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        tenantId: "123456",
      },
    };

    axios
      .post(url, formData, config)
      // setLoading(true)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          let pic_url = response.data.data.signedUrl;
          let mediaType = response.data.data.mediaType.toUpperCase();
          props.handleSendMsg(pic_url, mediaType, fileSendName);
          setFile("");
          setFileTypeStore("");
          setImagePreviewUrl("");
        }
      })
      .catch((error) => {
        errorHandel(error, "/fileServer/uploadMedia");
        toast.error(
          "Sorry,the file you are trying to upload is too big(maximum size is 3072KB)",
          toastOptions
        );
      });
    handleClose();
  };

  const toastOptions = {
    position: "top-right",
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
  };

  const getId = (item) => {
    setMsg(item.message);
    setCannemessagestore(item.message);
  };

  return (
    <>
      <div
        className={
          "chatbox-popup__footer d-flex align-items-center mt-1 justify-content-evenly"
        }
      >
        {/* Emoji Button */}
        <div className="smiley btn-sm border-0 me-2 ">
          <i
            className="fa-regular fa-face-smile"
            id="smiley"
            onClick={() => {
              inputRef.current.focus();
              setShowPicker(!showPicker);
            }}
          ></i>
          {showPicker && <Emoji onEmojiClick={onEmojiClick} />}
        </div>

        {/* Input Button */}
        <div className="chat-textarea">
          <textarea
            type="text"
            spellCheck="true"
            ref={inputRef}
            value={msg}
            onKeyDown={(e) => {
              sendChat(e);
              if (e.key === " " && e.target.value.trim() === "") {
                e.preventDefault();
                setSendButtonVisible(false);
              } else {
                setSendButtonVisible(true);
              }
            }}
            onChange={(e) => {
              props.handleTyping();
              setMsg(e.target.value);
            }}
            placeholder="Enter your message..."
          ></textarea>
        </div>

        {/* Mic Button */}

        {props.chat.channel == "webchat" ||
        props.chat.chat_type == "internal" ? (
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
          />
        ) : (
          <></>
        )}

        {/* File Button */}
        <div className="smiley">
          <label className="attch-label btn-sm border-0" htmlFor="attach">
            <i
              className="fas fa-paperclip"
              id="paperclip"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            ></i>
          </label>
          <input
            className="form-control"
            type="file"
            id="file-input"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>

        {/* Canned Message */}
        <div className="smiley">
          <div
            className="attch-label btn-sm border-0"
            id="smiley"
            onClick={showuserNotification}
          >
            <i className="fa-regular fa-message"></i>
          </div>
        </div>

        {/* Send Message Button */}
        {sendButtonVisible && (
          <div className="smiley chat-sent">
            <button
              type="button"
              id="smiley"
              onClick={sendChat}
              className="btn btn-chatsent d-flex justify-content-center align-items-center btn-sm"
            >
              <span className="material-symbols-outlined">send</span>
            </button>
          </div>
        )}
      </div>

      {props.cannedmessagediv && (
        <Foroutsideclick id="outside-click" onClickOutside={closecannedmessage}>
          <div className="card canned_div position-relative d-flex float-end bg-light">
            <div className="card-header d-flex justify-content-between">
              <h5 className="mt-2" style={{ fontSize: "1rem" }}>
                Canned Message
              </h5>
              {/* <Button
                className="mr-2 ms-3"
                style={{ fontSize: "10px", backgroundColor: "#00498f" }}
                onClick={handleShowcannedmessage}
              >
                <AiOutlinePlus />
              </Button> */}
              <div className="ms-auto">
                <AiOutlineCloseCircle
                  id="close-circle"
                  size={18}
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={closecannedmessage}
                  color="white"
                  className="mt-2"
                />
              </div>
            </div>

            <div
              className="card-body canned_card"
              style={{
                height: "15rem",
                width: "17rem",
                overflowX: "scroll",
              }}
            >
              <Button
                className="w-100"
                id="canned-messages"
                style={{ fontSize: "10px", backgroundColor: "#00498f" }}
                onClick={handleShowcannedmessage}
              >
                <AiOutlinePlus size="13" className="mx-1" />
                Add Canned Message
                {/* <AiOutlinePlus  size='18'/> */}
              </Button>
              {showMessage.length > 0 ? (
                showMessage.map((item) => (
                  <>
                    <div className="d-flex ">
                      <Card
                        id="close-circle"
                        onClick={() => {
                          getId(item);
                          closeuserNotification();
                        }}
                        style={{
                          width: "100%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        className="mt-2"
                      >
                        <div className="text-center d-flex justify-content-around my-auto">
                          <p style={{ fontWeight: "bold", padding: "8px 0px" }}>
                            {item.message}
                          </p>
                        </div>
                      </Card>
                      <div className="d-flex justify-content-center align-items-center">
                        <BootstrapTooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                        >
                          <button
                            className="btn btn-danger btn-sm mt-2 mx-1"
                            id="delete-canned"
                            onClick={() => {
                              deleteScannedMessage(item.id);
                            }}
                          >
                            <RiDeleteBin6Line
                              className=""
                              size="15px"
                              color=""
                            />
                          </button>
                        </BootstrapTooltip>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center h-100 text-muted">
                    <div>
                      <div className="">
                        <RiErrorWarningFill
                          size="25px"
                          className="d-flex mx-auto"
                        />
                      </div>
                      <div>No Canned Message</div>
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-center align-items-center h-100 text-muted">
                
                No Canned Message
                </div> */}
                </>
              )}
            </div>
          </div>
        </Foroutsideclick>
      )}

      {/* canned message modal */}
      <Modal
        show={showcannedmodal}
        onHide={handleClosecannedmessage}
        className="cannded_msg"
      >
        <Modal.Header
          style={{
            backgroundColor: "#294e9f",
            color: "white",
            marginTop: "-1px",
          }}
        >
          <Modal.Title>Canned Message</Modal.Title>
          <span className="mt-2">
            <AiOutlineCloseCircle
              onClick={() => {
                handleClosecannedmessage();
              }}
              style={{ cursor: "pointer" }}
              size="20px"
              id="close-circle"
            />
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Canned Message</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Message"
                value={createMsg}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 1000) {
                    setCreateMsg(inputValue);
                    setSubmitClicked("");
                  } else {
                    setCreateMsg(inputValue.slice(0, 1000));
                    setSubmitClicked("Maximum character limit (1000) reached.");
                  }
                }}
              />
              {submitClicked && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {submitClicked}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <div className="d-flex p-2 justify-content-end">
          <Button variant="primary" id="submit-btn" onClick={create_contact}>
            Submit
          </Button>
        </div>
      </Modal>

      {/* File Preview */}
      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header
          style={{
            padding: "10px 0 0 10px",
            margin: 0,
            height: "42px",
            backgroundColor: "#2951cf",
          }}
        >
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between align-items-center text-dark">
              <span className="mb-3 text-white" style={{ fontSize: "16px" }}>
                Preview
              </span>
              <AiOutlineCloseCircle
                size={15}
                id="close-circle"
                className="me-2 mb-2"
                style={{ cursor: "pointer" }}
                onClick={handleClose}
                color="white"
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        {loading ? (
          <div
            className="spinner-border  d-flex justify-content-center align-items-center mx-auto"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "12rem",
            }}
          >
            {fileTypeStore == "image" && (
              <img
                style={{ width: "100%", height: "10rem" }}
                src={imagePreviewUrl}
              />
            )}
            {fileTypeStore == "audio" && (
              <audio className="preview_audio" controls src={audioUrl}></audio>
            )}
            {fileTypeStore == "video" && (
              <video width="220" height="150" controls muted onloadstart={true}>
                <source src={imagePreviewUrl} type="video/mp4"></source>
              </video>
            )}
            {fileTypeStore == "application" && (
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <a
                  href={imagePreviewUrl}
                  target="_blank"
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    pointerEvents: "none",
                  }}
                >
                  {fileSendName}
                </a>
              </div>
            )}
          </Modal.Body>
        )}
        <Modal.Footer className="imgPreview" style={{ padding: 4 }}>
          <div>
            <Button
              onClick={handleClose}
              className="closeBtnImg"
              size="sm"
              id="close-circle"
              variant="secondary"
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              onClick={sendFile}
              className="saveButtonImg"
              id="file-tab"
              size="sm"
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* VoiceRecorder Preview Model */}
      <Modal show={showaudiopreview} onHide={handleClose} size="sm">
        <Modal.Header
          style={{
            padding: "10px 0 0 10px",
            margin: 0,
            height: "42px",
            backgroundColor: "#2951cf",
          }}
        >
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between align-items-center text-dark">
              <span className="mb-3 text-white " style={{ fontSize: "16px" }}>
                Audio Preview
              </span>
              <AiOutlineCloseCircle
                size={15}
                id="audio-file"
                className="me-2 mb-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setshowaudiopreview(false);
                }}
                color="white"
              />
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "12rem",
          }}
        >
          {recorderVoice && (
            <ThemeProvider theme={muiTheme}>
              <AudioPlayer
                elevation={1}
                width="100%"
                variation="default"
                spacing={1}
                order="standart"
                preload="auto"
                src={recorderVoice}
              />
            </ThemeProvider>
          )}
        </Modal.Body>

        <Modal.Footer className="imgPreview" style={{ padding: 3 }}>
          <div>
            {" "}
            <Button
              onClick={(e) => {
                e.preventDefault();
                setrecorderVoice([]);
                setshowaudiopreview(false);
                setRecorddata("");
              }}
              className="closeBtnImg"
              size="sm"
              id="close-circle"
              variant="secondary"
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setrecorderVoice([]);
                setshowaudiopreview(false);
                sendChatButton();
                setRecorddata("");
              }}
              className="saveButtonImg me-2"
              size="sm"
              id="file-tab"
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default connect(mapStateToProps, {
  settogglechat,
  setSearchterm,
  setCannedmessagediv,
})(ChatInput);
