
import React from "react";
import "../../assets/chatasset/assets/styles/chat.css";
import "../../assets/chatasset/assets/styles/style.css";
import { MdChat } from "react-icons/md";
// Component
import Contactlist from "../../components/chat-components/Contact-list-component/Contact-list.component";
import { connect } from "react-redux";
import { setselectedusername } from "../../redux/actions/actions";
import CustomerDetailSearch from "../../components/chat-components/User-list-component/Customer-Detail-Search/Customer-Detail-Search";
import ChatComponent from "../../components/chat-components/Chat-component/Chat.component";
import UserListComponent from "../../components/chat-components/User-list-component/User-list.component";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Grid, Slide } from "@mui/material";
const StyledPaper = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  // color: theme.palette.text.secondary,
  height: "96vh",
  width: "96vw",
  padding: "5px",
  zIndex: 0,
  background: "none",
  marginLeft: "45px",
}));
const mapStateToProps = (state) => {
  return {
    chat: state.data.chat,
    CustomerDetailsShow: state.data.CustomerDetailsShow,
    userlistshow: state.data.userlistshow,
    showExternalChat: state.data.showExternalChat,
  };
};

const ChatNew = (props) => {
  const { CustomerDetailsShow } = props;

  return (
    <>
      <StyledPaper elevation={0}>
        <Grid container direction="row" >
          <Grid item xs={3} md={3} sx={{ borderRight: "3px solid #dedede" }}>
            <Contactlist />
          </Grid>
          <ChatComponent />
          
            <Grid
              item
              xs={props.userlistshow ? 4 : 0}
              md={props.userlistshow ? 4 : 0}
            >
              {(() => {
                switch (props.userlistshow && props.showExternalChat) {
                  case true:
                    return <UserListComponent />;
                  default:
                    return <></>;
                }
              })()}
            </Grid>
        </Grid>
      </StyledPaper>
      {CustomerDetailsShow && <CustomerDetailSearch />}
    </>
  );
};

export default connect(mapStateToProps, {
  setselectedusername,
})(ChatNew);
