import React, { useEffect, useState } from "react";
import { Pagination, Skeleton } from "@mui/material";
import axios from "axios";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import { AvcUrl } from "../../container/BaseUrl/BaseUrl";
import { toast } from "react-toastify";
import { errorHandel } from "../../ApiCall/LogoutApi";
import "./agentStatusUpdate.css";
import { RiErrorWarningLine } from "react-icons/ri";
import {
  setAgentStatusListInSupervisor,
  setAgentStatusRefreash,
} from "../../redux/actions/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    agentStatusListInSupervisor:state.data.agentStatusListInSupervisor,
    agentStatusrefreash: state.data.agentStatusrefreash,
  };
};

const AgentStatusUpdate = (props) => {
  const { agentStatusListInSupervisor } = props;
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  const agentId = JSON.parse(localStorage.getItem("tokenAgent"));
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loadingAgentStatus, setLoadingAgentStatus] = useState(false);
  const [userstatus, setUserstatus] = useState([]);
  const agentActivityCount = async () => {
    setLoadingAgentStatus(true);
    try {
      await axios
        .post(
          BaseUrl + "/dashboard/chatCount",
          {
            from_date: defaultValue,
            to_date: defaultValue,
            offset: page == 1 ? 0 : (page - 1) * 10,
            limit: 10,
            agent_id: agentId.agent_id,
            type: "webstatics",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            props.setAgentStatusListInSupervisor(res.data.Data);
            setPagination(res.data.count);
            setTimeout(() => {
              setLoadingAgentStatus(false);
            }, 1000);
          } else {
            setTimeout(() => {
              setLoadingAgentStatus(false);
            }, 1000);
          }
        });
    } catch (error) {
      errorhandelApi(error, "/dashboard/chatCount");
      setTimeout(() => {
        setLoadingAgentStatus(false);
      }, 2000);
    }
  };

  useEffect(() => {
    agentActivityCount();
  }, [page]);

  const userStatus = async () => {
    try {
      await axios
        .post(
          BaseUrl + "/userstatus/userstatuslist",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then(function (response) {
          const responseData = response.data.data;
          if (Array.isArray(responseData)) {
            setUserstatus(responseData);
          }
        });
    } catch (error) {
      errorHandel(error, "/userstatus/userstatuslist");
    }
  };

  useEffect(() => {
    userStatus();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const toggleDropdown = (agentId) => {
    setDropdownVisible((prevVisible) => !prevVisible && agentId);
  };

  const updateStatus = (status, agent) => {
    let buttonColor = "btn-warning";
    if (status === "Ready") {
      buttonColor = "btn-success";
    }
    setSelectedStatus((prevSelectedStatus) => ({
      ...prevSelectedStatus,
      [agent.user_id]: status,
    }));

    const buttonElement = document.getElementById(`button-${agent.user_id}`);
    if (buttonElement) {
      buttonElement.classList.remove("btn-success", "btn-warning");
      buttonElement.classList.add(buttonColor);
    }
  };

  const changeStatusnew = async (status, CTIagentLoginTerminalId, ssoToken) => {
    //! Voice Status Update Api
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${CTIagentLoginTerminalId}/status?status=${status}`,
        {},
        {
          headers: {
            ssoToken: ssoToken ? ssoToken : "",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status == "OK") {
          if (status == "Ready") {
            props.setVoiceRefresh(false);
            localStorage.removeItem("VoiceActivate");
          }
        }
      })
      .catch((error) => {
        errorHandel(error, "agent/status");
        toast.error("Avaya Status Change Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const updateStatusApi = async (value, agentId) => {
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      if (data) {
        await axios
          .post(
            BaseUrl + "/users/updateAgentStatus/",
            {
              status: value,
              userId: agentId.user_id,
              type: "statuschange",
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
                "Content-Type": "application/json",
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          )
          .then((res) => {
            try {
              const updatedData = props.agentStatusListInSupervisor.map(
                (agent) => {
                  if (agent.agent_id.user_id === agentId.user_id) {
                    return {
                      ...agent,
                      agent_id: {
                        ...agent.agent_id,
                        agent_status_real: value,
                      },
                    };
                  }
                  return agent;
                }
              );
              props.setAgentStatusListInSupervisor(updatedData);
            } catch (error) {
              console.error("Error updating agent status:", error);
            }
          });
      }
    } catch (error) {}
  };

  const updateStatusWithReasonCode = async (status, CTIagentLoginTerminalId, ssoToken) => {
    if (!status) return;
    await axios
      .post(
        AvcUrl +
          `/voice/cct/changeAgentStatusReasonCode?agentId=${CTIagentLoginTerminalId}&status=Not Ready&reason=${
            status.statusCode == null ? "" : status.statusCode
          }`,
        {},
        {
          headers: {
            ssoToken: ssoToken,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const LogoutAgent = (userid) => {
    try {
      axios
        .post(
          BaseUrl + `/users/logout/${userid}`,
          {
            type: "forcelogout",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          console.log("LogoutAgent", res);
        })
        .catch((err) => {
          console.log("LogoutAgent", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container-fluid p-2">
        <div className="card fixed-table-card vh-79 agentStatus">
          <div className="card-body">
            <div>
              <p className="card-title">RealTime Agent</p>
            </div>

            <div className="table-responsive" style={{ height: "100%" }}>
              <table className="table table-sm table-hover align-middle table-fixeded">
                <thead>
                  <tr>
                    <th scope="col">Agent Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Logout</th>
                  </tr>
                </thead>
                <tbody>
                  {loadingAgentStatus ? (
                    [...Array(8)].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {[...Array(3)].map((_, colIndex) => (
                          <td key={colIndex}>
                            <Skeleton variant="text" width={150} height={30} />
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : Array.isArray(agentStatusListInSupervisor) &&
                    agentStatusListInSupervisor.length > 0 ? (
                    agentStatusListInSupervisor.map((rowData) => {
                      return (
                        <tr
                          key={rowData.agent_id.user_id}
                          className="text-break"
                        >
                          <td>
                            {rowData.agent_id == null ||
                            rowData.agent_id == undefined ||
                            rowData.agent_id == ""
                              ? "--"
                              : rowData.agent_id.username}
                          </td>
                          <td>
                            <div className="">
                              <button
                                id={`button-${rowData.agent_id.user_id}`}
                                className={`agentStatus-btn-dropdown dropdown-toggle ${
                                  rowData.agent_id.agent_status_real ===
                                    "Available" ||
                                  rowData.agent_id.agent_status_real ===
                                    "Ready" ||
                                  rowData.agent_id.agent_status_real === "Ready"
                                    ? "btn-success"
                                    : rowData.agent_id.agent_status_real ===
                                      "Connected"
                                    ? "btn-info"
                                    : "btn-warning"
                                }`}
                                type="button"
                                disabled={
                                  rowData.agent_id.agent_status_real ===
                                  "Is LoggedOut"
                                }
                                onClick={() => {
                                  toggleDropdown(rowData.agent_id.user_id);
                                }}
                              >
                                {rowData.agent_id.agent_status_real}
                              </button>
                              <div
                                className="nav__item dropdown"
                                id="myDropdown"
                              >
                                {isDropdownVisible ===
                                  rowData.agent_id.user_id && (
                                  <div className="Status_dropdown__list">
                                    <div className="agentStatus-dropdown__item border-bottom">
                                      <div
                                        className="dropdown__link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          updateStatusWithReasonCode(
                                            "Ready",
                                            rowData.agent_id
                                              .CTIagentLoginTerminalId,
                                            rowData.agent_id.ssoToken
                                          )
                                          changeStatusnew(
                                            "Ready",
                                            rowData.agent_id
                                              .CTIagentLoginTerminalId,
                                            rowData.agent_id.ssoToken
                                          );
                                          updateStatus(
                                            "Ready",
                                            rowData.agent_id
                                          );
                                          updateStatusApi(
                                            "Ready",
                                            rowData.agent_id
                                          );
                                          setDropdownVisible();
                                        }}
                                      >
                                        Ready
                                      </div>
                                    </div>

                                    <div className="agentStatus-dropdown__item Status_submenu">
                                      <div
                                        className="dropdown__link"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          updateStatusWithReasonCode(
                                            "Not Ready",
                                            rowData.agent_id
                                              .CTIagentLoginTerminalId,
                                            rowData.agent_id.ssoToken
                                          )
                                          changeStatusnew(
                                            "Not Ready",
                                            rowData.agent_id
                                              .CTIagentLoginTerminalId,
                                            rowData.agent_id.ssoToken
                                          );
                                          updateStatus(
                                            "Not Ready",
                                            rowData.agent_id
                                          );
                                          updateStatusApi(
                                            "Not Ready",
                                            rowData.agent_id
                                          );
                                          setDropdownVisible();
                                        }}
                                      >
                                        Not Ready &#x25B8;
                                      </div>
                                      <div className="Status_submenu__list">
                                        {Array.isArray(userstatus) &&
                                          userstatus.length > 0 &&
                                          userstatus.map(
                                            (statusData, index) => (
                                              <div
                                                key={index}
                                                className="Status_submenu__item border-bottom"
                                              >
                                                <div
                                                  className="submenu__link"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    updateStatusWithReasonCode(
                                                      statusData.statusName,
                                                      rowData.agent_id
                                                        .CTIagentLoginTerminalId,
                                                      rowData.agent_id.ssoToken
                                                    );
                                                    changeStatusnew(
                                                      statusData.statusName,
                                                      rowData.agent_id
                                                        .CTIagentLoginTerminalId,
                                                      rowData.agent_id.ssoToken
                                                    );
                                                    updateStatus(
                                                      statusData.statusName,
                                                      rowData.agent_id
                                                    );

                                                    updateStatusApi(
                                                      statusData.statusName,
                                                      rowData.agent_id
                                                    );
                                                    setDropdownVisible();
                                                  }}
                                                >
                                                  {statusData.statusName}
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                LogoutAgent(rowData.agent_id.user_id);
                              }}
                            >
                              <i className="fa-solid fa-right-from-bracket"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr key="">
                      <td colSpan="15">
                        <div
                          className="text-muted"
                          style={{
                            flexDirection: "column",
                            marginTop: "20%",
                            display: "flex",
                            position: "absolute",
                            left: "45%",
                            bottom: "40%",
                          }}
                        >
                          <span className="mx-auto">
                            <RiErrorWarningLine size={30} />
                          </span>
                          <span className="mx-auto">
                            <h5
                              className="text-muted mt-2"
                              style={{
                                fontFamily: "poppins",
                              }}
                            >
                              No Data Found
                            </h5>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer rounded-0 d-flex align-item-center justify-content-end align-items-center">
        <Pagination
          count={Math.ceil(pagination / 10)}
          page={page}
          color="primary"
          size="small"
          style={{ float: "right" }}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setAgentStatusListInSupervisor,
  setAgentStatusRefreash,
})(AgentStatusUpdate);
