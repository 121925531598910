

export const randomColors = [
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
    "#402B3A",
    "#86A7FC",
    "#3468C0",
    "#FF9843",
    "#332941",
    "#3B3486",
    "#864AF9",
    "#F8E559",
    "#1D2B53",
    "#7E2553",
    "#FF004D",
    "#FAEF5D",
    "#365486",
    "#0F1035",
    "#2D3250",
    "#424769",
    "#7077A1",
    "#525CEB",
    "#3D3B40",
    "#B4D4FF",
    "#B4D4FF",
    "#86B6F6",
    "#176B87",
    "#4F6F52",
    "#739072",
    "#52D3D8",
    "#52D3D8",
    "#9BB8CD",
    "#092635",
    "#1B4242",
    "#5C8374",
    "#9EC8B9",
    "#191919",
    "#750E21",
    "#E3651D",
    "#BED754",
    "#DF826C",
    "#161A30",
    "#31304D",
    "#B6BBC4",
    "#FED9ED",
    "#E7BCDE",
  ];