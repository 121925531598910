import React, { useState, useEffect, useRef } from "react";
import "@avaya/neo-react/avaya-neo-react.css";
import Sdk from "../DialerComponent/Sdk";
import "../../assets/assets/dist/css/neo/neo.css";
import "./navbar.css";
import DialpadIcon from "@mui/icons-material/Dialpad";
import MailIcon from "@mui/icons-material/Mail";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setcontactlist,
  setcontactlist1,
  closeNotify,
  clearnotifyAll,
  setShowNotification,
  setShowUserProfile,
  setshowphonedialer,
  setVoiceRefresh,
  setShowingStatus,
  setShowingStatusClass,
  setsidebarVal,
} from "../../redux/actions/actions";
import {
  BaseUrl,
  frontendBaseurl,
  errorApi,
} from "../../container/BaseUrl/BaseUrl";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Bell,
  CaretRight,
  CheckCircle,
  MinusCircle,
  Polygon,
  SealCheck,
  SignOut,
  WifiHigh,
  WifiX,
  XCircle,
} from "@phosphor-icons/react";
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import InternetError from "../InternetError-component/useOnlineStatus ";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Badge,
  Popover,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "13px",
  },
}));

const theme = createTheme({
  typography: {
    button: {
      fontSize: "12px",
      fontWeight: 600,
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  lineHeight: "20px",
  padding: "5px 10px",
  marginBottom: "2px",
  background: "#dcdcdc",
  boxShadow: "none",
  transition: "transform 0.3s ease",
  "&.MuiPaper-root": {
    borderRadius: "5px",
    "&:hover": {
      background: "#4f5b4f",
      cursor: "pointer",
      color: "white",
      transform: "scale(1.05)",
    },
    "&:active": {
      background: "#4f5b4f",
      cursor: "pointer",
      color: "white",
      transform: "scale(0.99)",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    contactList: state.contactListData.contactList,
    notification: state.notification.notification,
    showNotification: state.data.showNotification,
    showUserProfile: state.data.showUserProfile,
    showphonedialer: state.data.showphonedialer,
    VoiceRefresh: state.data.VoiceRefresh,
    showingStatus: state.data.showingStatus,
    showingStatusClass: state.data.showingStatusClass,
  };
};

const NewNavbar = (props) => {
  const { referance } = props;
  const AvcUrl = localStorage.getItem("AvcUrl");
  const [showReadyButton, setReadyButton] = useState(true);
  const [showFinishButton, setFinishButton] = useState(false);
  const [showConnectedStatus, setConnectedStatus] = useState(true);
  const [showReadyStatus, setReadyStatus] = useState(false);
  const [hideLogout, setHideLogout] = useState(true);
  const [rmState, setRmState] = useState(true);
  const [showStatusChannel, setShowStatusChannel] = useState("");
  const [voiceEnable, setvoiceEnable] = useState(false);
  const [userstatus, setUserstatus] = useState([]);
  const [channel, setchannel] = useState(
    JSON.parse(localStorage.getItem("tokenAgent"))
  );

  const [showReasonCode, setShowReasonCode] = useState();
  const filteredChannels = channel.channel.filter(
    (item) => !item.parentClientChannelId
  );
  const navigate = useNavigate();

  const { isOnline, showNotification } = InternetError();

  useEffect(() => {
    if (localStorage.getItem("statusValue") == "Ready") {
      goReady("Ready");
      updateStatus("Ready");
      setShowStatusChannel("Ready");
      localStorage.setItem("statusValue", "Ready");
    }
    filteredChannels.map((item) => {
      if (item.channelName == "Voice") {
        setvoiceEnable(true);
      }
    });
  }, [localStorage.getItem("statusValue")]);

  useEffect(() => {
    if (localStorage.getItem("VoiceActivate")) {
      changeStatusnew("Ready");
    }
  }, [localStorage.getItem("VoiceActivate")]);

  const [isActive, setIsActive] = useState(true);

  // useEffect(() => {
  //   let timeout;
  //   const resetTimeout = () => {
  //     if (timeout) {
  //       clearTimeout(timeout);
  //     }
  //     timeout = setTimeout(() => {
  //       setIsActive(false);
  //     }, 360000);
  //   };

  //   const handleUserActivity = () => {
  //     setIsActive(true);
  //     resetTimeout();
  //   };

  //   const handleBeforeUnload = (event) => {
  //     console.log("Browser is closing");
  //     event.returnValue = "";
  //   };

  //   const handleUnload = (event) => {
  //     console.log("Browser is closed", event);
  //     forceLogout();
  //   };

  //   resetTimeout();
  //   document.addEventListener("mousemove", handleUserActivity);
  //   document.addEventListener("keydown", handleUserActivity);
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("unload", handleUnload);

  //   return () => {
  //     clearTimeout(timeout);
  //     document.removeEventListener("mousemove", handleUserActivity);
  //     document.removeEventListener("keydown", handleUserActivity);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("unload", handleUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!isActive) {
  //     forceLogout();
  //   }
  // }, [isActive]);

  const show_notification = () => {
    props.setShowNotification(!props.showNotification);
    props.setShowUserProfile(false);
    props.setshowphonedialer(false);
  };

  const close_notification = () => {
    props.setShowNotification(false);
  };

  const show_userprofile = () => {
    props.setShowUserProfile(!props.showUserProfile);
    props.setShowNotification(false);
  };

  const close_userprofile = () => {
    props.setShowUserProfile(false);
  };

  const errorHandel = async (data) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      errorhandelApi();
      if (data.status) {
      }
    } catch (error) {}
  };

  //complete
  const updateStatus = async (value) => {
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      if (data) {
        await axios
          .post(
            BaseUrl + "/users/updateAgentStatus/",
            {
              status: value,
              userId: data.user_id,
              ssoToken: localStorage.getItem("ssoToken"),
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
                "Content-Type": "application/json",
                tenantId: localStorage.getItem("TenantId"),
              },
            }
          )
          .then((res) => {});
      }
    } catch (error) {
      errorHandel(error, "/users/updateAgentStatus/");
    }
  };

  const goReady = async (val) => {
    if (val == "Ready") {
      navigate("/main/Chat");
      props.setsidebarVal("Chat");
    }
    setFinishButton(true);
    setReadyButton(false);
    setReadyStatus(true);
    setConnectedStatus(false);
    setHideLogout(false);
    props.setShowingStatusClass("showStatus");
    props.setShowingStatus(val);
  };

  const goFinish = () => {
    setShowStatusChannel("Logged Out");
    updateStatus("Connected");
    localStorage.removeItem("readystatus");
    setHideLogout(true);
    setTimeout(() => {
      setFinishButton(false);
      setReadyButton(true);
      setReadyStatus(false);
      setConnectedStatus(true);
    }, 1000);
  };

  let name = localStorage.getItem("NameDisplay");
  let email = localStorage.getItem("emailDisplay");
  let firstTwoletter;
  if (name) {
    firstTwoletter = name.substring(0, 2).toLocaleUpperCase();
  }

  const logout = async () => {
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    let userID = data.user_id;
    if (data) {
      await axios
        .post(
          BaseUrl + "/users/logoutnew/" + userID,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            localStorage.removeItem("AvayaUsername");
            localStorage.removeItem("tab");
            localStorage.removeItem("timer_connect_sec");
            localStorage.removeItem("AvayaPassword");
            localStorage.removeItem("AvayaDomain");
            localStorage.removeItem("client");
            localStorage.removeItem("statusValue");
            localStorage.removeItem("emailDisplay");
            localStorage.removeItem("timer_connect_min");
            localStorage.removeItem("tokenAgent");
            localStorage.removeItem("timer_status");
            localStorage.removeItem("NameDisplay");
            localStorage.removeItem("access_token");
            localStorage.removeItem("timer_connect_hour");
            localStorage.removeItem("EnableWEBRTC");
            localStorage.removeItem("ticketcrm");
            localStorage.removeItem("agentLoginTerminalId");
            if (!localStorage.getItem("TenantId")) {
              window.location.href = "error.html";
            } else {
              const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
                localStorage.getItem("TenantId")
              )}`;
              window.location.href = loginUrl;
            }
            navigate("/");
          } else {
            toast.warn("Please Close all Active Sessions", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {});
    }
  };

  //completed

  const loginbyDomain = async () => {
    if (voiceEnable) {
      await axios
        .post(
          AvcUrl +
            `/voice/cct/login?username=${localStorage.getItem(
              "AvayaUsername"
            )}&password=${localStorage.getItem(
              "AvayaPassword"
            )}&domain=${localStorage.getItem("AvayaDomain")}`,
          {},
          {
            headers: {
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            localStorage.setItem("ssoToken", res.data.data.ssoToken);
            localStorage.setItem(
              "agentLoginTerminalId",
              res.data.data.agents[0].agentLoginId
            );
            localStorage.setItem(
              "agentUsername",
              res.data.data.agents[0].agentUsername
            );

            // DCC Agent Status Update API
            let data = JSON.parse(localStorage.getItem("tokenAgent"));
            await axios
              .post(
                BaseUrl + "/users/updateAgentStatus/",
                {
                  status: "Ready",
                  userId: data.user_id,
                  agentLoginTerminalId: localStorage.getItem(
                    "agentLoginTerminalId"
                  )
                    ? localStorage.getItem("agentLoginTerminalId")
                    : "",
                  agentUsername: localStorage.getItem("agentUsername")
                    ? localStorage.getItem("agentUsername")
                    : "",
                },
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantId: localStorage.getItem("TenantId"),
                  },
                }
              )
              .then((res) => {})
              .catch((err) => {});
            // DCC Agent Status Update API

            if (res.data.data.agents[0].loggedIn == true) {
            } else {
              loginbyDomainforAgent();
            }
          }
        })
        .catch((res) => {
          // if ((res.data.status = "INTERNAL_SERVER_ERROR")) {
          toast.error("Avaya CCT Login Error", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          // }
        });
    }
  };
  const loginbyDomainforAgent = async () => {
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${localStorage.getItem(
            "agentLoginTerminalId"
          )}/login/${localStorage.getItem(
            "agentLoginTerminalId"
          )}?status=Ready`,
        {},

        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if ((res.data.status = "OK")) {
          window.location.reload(true);
        }
      })
      .catch((error) => {
        errorHandel(error, "agent/login/status");
        toast.error("Avaya Status Change Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const logoutByDomainFirst = async () => {
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${localStorage.getItem(
            "agentLoginTerminalId"
          )}/logout/${localStorage.getItem("agentLoginTerminalId")}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.statusCode == 500) {
            toast.warn(res.data.status, {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          } else {
            logoutByDomainSecond();
          }
        }
      })
      .catch((error) => {
        errorHandel(error, "/cct/agent/logout");
        toast.error("Avaya Logout Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const logoutByDomainSecond = async () => {
    const agentUsername = localStorage.getItem("agentUsername");
    await axios
      .post(
        AvcUrl + "/voice/cct/logout?username=" + agentUsername,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken"),
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if ((res.data.status = "OK")) {
        }
      })
      .catch((err) => {
        toast.error("Avaya Logout Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const changeStatusnew = async (status) => {
    //! Voice Status Update Api
    await axios
      .post(
        AvcUrl +
          `/voice/cct/agent/${localStorage.getItem(
            "agentLoginTerminalId"
          )}/status?status=${status}`,
        {},
        {
          headers: {
            ssoToken: localStorage.getItem("ssoToken")
              ? localStorage.getItem("ssoToken")
              : "",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.status == "OK") {
          if (status == "Ready") {
            props.setVoiceRefresh(false);
            localStorage.removeItem("VoiceActivate");
          }
        }
      })
      .catch((error) => {
        errorHandel(error, "agent/status");
        toast.error("Avaya Status Change Error", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const showBreaks = () => {
    setRmState(false);
  };

  const showOldScreen = () => {
    setRmState(true);
  };

  const mealUpdate = (item) => {
    goReady(item);
    props.setShowingStatusClass("showStatusRed");
    updateStatus(item);
    setRmState(true);
  };

  const userStatus = async () => {
    try {
      await axios
        .post(
          BaseUrl + "/userstatus/userstatuslist",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((response) => {
          setUserstatus(response.data.data);
        });
    } catch (error) {
      errorHandel(error, "/userstatus/userstatuslist");
    }
  };

  const toggle = () => {
    props.setshowphonedialer(!props.showphonedialer);
    props.setShowNotification(false);
    props.setShowUserProfile(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const forceLogout = () => {
    const agent = JSON.parse(localStorage.getItem("tokenAgent"));
    try {
      axios
        .post(
          BaseUrl + `/users/logout/${agent.user_id}`,
          {
            type: "forcelogout",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantId: localStorage.getItem("TenantId"),
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            localStorage.removeItem("AvayaUsername");
            localStorage.removeItem("tab");
            localStorage.removeItem("timer_connect_sec");
            localStorage.removeItem("AvayaPassword");
            localStorage.removeItem("AvayaDomain");
            localStorage.removeItem("client");
            localStorage.removeItem("statusValue");
            localStorage.removeItem("emailDisplay");
            localStorage.removeItem("timer_connect_min");
            localStorage.removeItem("tokenAgent");
            localStorage.removeItem("timer_status");
            localStorage.removeItem("NameDisplay");
            localStorage.removeItem("access_token");
            localStorage.removeItem("timer_connect_hour");
            localStorage.removeItem("EnableWEBRTC");
            localStorage.removeItem("ticketcrm");
            localStorage.removeItem("agentLoginTerminalId");
            if (!localStorage.getItem("TenantId")) {
              window.location.href = "error.html";
            } else {
              const loginUrl = `${frontendBaseurl}/?tenantID=${encodeURIComponent(
                localStorage.getItem("TenantId")
              )}`;
              window.location.href = loginUrl;
            }
            navigate("/");
          } else {
            toast.warn("Please Close all Active Sessions", {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log("LogoutAgent", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AppBar position="static" sx={{ background: "#FFFF" }}>
        <Toolbar
          sx={{
            "@media screen and (min-width: 600px)": {
              minHeight: "50px",
              padding: 0,
            },
            padding: 0,
          }}
        >
          <Box sx={{ flexGrow: 1, width: "60%" }}></Box>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {voiceEnable ? (
              <IconButton
                sx={{ color: "black" }}
                onClick={() => {
                  toggle();
                }}
              >
                <DialpadIcon color="action" fontSize="small" />
              </IconButton>
            ) : null}

            <>
              <IconButton
                sx={{ color: "black" }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <StyledBadge badgeContent={4} color="primary" invisible={true}>
                  <Bell color="#707070" />
                </StyledBadge>
              </IconButton>

              <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  ".MuiPopover-root": {
                    borderRadius: "20px",
                  },
                }}
              >
                <Box sx={{ maxHeight: "400px", width: "300px" }} p={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                      Notification
                    </Typography>
                    <IconButton sx={{ padding: "5px" }} onClick={handleClose}>
                      <XCircle size={18} />
                    </IconButton>
                  </Stack>
                  <Box
                    sx={{
                      maxHeight: "86%",
                      width: "100%",
                      overflowY: "scroll",
                      padding: "8px 8px 8px 0px",
                    }}
                  >
                    {props.notification.length > 0 ? (
                      props.notification
                        .slice(0)
                        .reverse()
                        .map((data, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                height: 54,
                                borderRadius: 1,
                                borderBottom: "2px #d4d4d4 solid",
                                cursor: "pointer",
                                marginBottom: "4px",
                              }}
                              p={1}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={0.8}
                                >
                                  <Avatar
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      height: "33px",
                                      width: "33px",
                                      background: "#0B3363",
                                    }}
                                  >
                                    {data.username.slice(0, 2).toUpperCase()}
                                  </Avatar>

                                  <Stack spacing={0.1}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        noWrap
                                        variant="subtitle2"
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "13px",
                                          width: "150px",
                                        }}
                                      >
                                        {data.username} {`(${data.chatType})`}
                                      </Typography>
                                    </Stack>
                                    <Stack>
                                      <Typography
                                        noWrap
                                        variant="caption"
                                        sx={{
                                          fontSize: "9px",
                                          width: "160px",
                                        }}
                                      >
                                        {data.client_msg}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                                <Stack
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "7px",
                                    }}
                                    variant="caption"
                                  >
                                    {data.msg_time}
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      props.closeNotify(
                                        data.id,
                                        props.notification
                                      );
                                    }}
                                  >
                                    <XCircle size={15} />
                                  </IconButton>
                                </Stack>
                              </Stack>
                            </Box>
                          );
                        })
                    ) : (
                      <Box
                        sx={{
                          borderRadius: 1,
                          cursor: "pointer",
                          width: "100%",
                        }}
                        p={1}
                      >
                        <Typography>No Message Found</Typography>
                      </Box>
                    )}
                  </Box>
                  <Stack sx={{ paddingTop: "5px" }}>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="text"
                        onClick={() => {
                          props.clearnotifyAll(data.id, props.notification);
                        }}
                      >
                        CLEAR ALL
                      </Button>
                    </ThemeProvider>
                  </Stack>
                </Box>
              </StyledPopover>
            </>

            <>
              <Box
                sx={{ background: "#F1F1F1", cursor: "pointer" }}
                p={1}
                aria-describedby={id2}
                onClick={handleClick2}
              >
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack justifyContent="center" sx={{ width: "140px" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: 600,
                        // width: "200px",
                      }}
                      noWrap
                    >
                      {localStorage.getItem("NameDisplay")}
                    </Typography>
                    <Chip
                      size="small"
                      label={props.showingStatus}
                      color={
                        props.showingStatus == "Ready"
                          ? "success"
                          : props.showingStatus == "Connected"
                          ? "default"
                          : "error"
                      }
                      sx={{
                        height: "15px",
                        fontSize: "10px",
                        width: "90px",
                      }}
                    />
                  </Stack>

                  <Avatar
                    sx={{
                      background: "#0B3363",
                      height: "35px",
                      width: "35px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  ></Avatar>
                </Stack>
              </Box>

              <StyledPopover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {!showReasonCode && (
                  <Slide in={!showReasonCode} direction={"right"}>
                    <Box sx={{ height: "500px", width: "350px" }} p={2}>
                      <Stack sx={{ height: "100%" }} spacing={1}>
                        <Stack spacing={2} sx={{ height: "90%" }}>
                          <Box
                            sx={{
                              height: "content",
                              width: "100%",
                              borderRadius: "10px",
                              background: "rgb(238,174,202)",
                              background:
                                "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)",
                            }}
                            p={1}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                alignItems={"center"}
                                spacing={1}
                                direction={"row"}
                              >
                                <Avatar
                                  sx={{
                                    background: "rgb(2,0,36)",
                                    background:
                                      "linear-gradient(36deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 26%, rgba(0,212,255,1) 100%)",
                                    height: "50px",
                                    width: "50px",
                                    fontSize: "18px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {localStorage
                                    .getItem("NameDisplay")
                                    .slice(0, 2)
                                    .toUpperCase()}
                                </Avatar>
                                <Stack alignItems={"center"} spacing={0.5}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "10px",
                                      }}
                                      spacing={1}
                                    >
                                      Agent Id :
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 400,
                                        fontSize: "12px",
                                      }}
                                    >
                                      {email}
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"} spacing={1}>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "10px",
                                      }}
                                    >
                                      Status :
                                    </Typography>

                                    <Chip
                                      size="small"
                                      label={props.showingStatus}
                                      color={
                                        props.showingStatus == "Ready"
                                          ? "success"
                                          : props.showingStatus == "Connected"
                                          ? "default"
                                          : "error"
                                      }
                                      sx={{
                                        height: "15px",
                                        fontSize: "10px",
                                        width: "90px",
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </Stack>
                              <IconButton
                                sx={{ padding: "5px" }}
                                onClick={handleClose2}
                              >
                                <XCircle size={18} />
                              </IconButton>
                            </Stack>
                          </Box>

                          {showFinishButton && (
                            <>
                              <Button
                                variant="contained"
                                startIcon={<Polygon size={22} />}
                                onClick={() => {
                                  logoutByDomainFirst();
                                  goFinish();
                                  setShowStatusChannel("Connected");
                                  localStorage.setItem(
                                    "statusValue",
                                    "Connected"
                                  );
                                }}
                              >
                                Finish Work
                              </Button>
                              <Box
                                sx={{
                                  height: "290px",
                                }}
                              >
                                <Stack spacing={1}>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: "16px" }}
                                  >
                                    Status
                                  </Typography>
                                  <Item
                                    onClick={() => {
                                      setShowReasonCode(true);
                                      userStatus();
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={1}
                                      >
                                        <MinusCircle size={22} weight="bold" />
                                        <Typography
                                          sx={{
                                            fontSize: "0.875rem",
                                            color: "rgba(0, 0, 0, 0.6)",
                                          }}
                                        >
                                          More...
                                        </Typography>
                                      </Stack>
                                      <IconButton
                                        sx={{
                                          padding: "3px",
                                          "&:hover": {
                                            color: "white",
                                          },
                                        }}
                                      >
                                        <CaretRight size={18} weight="bold" />
                                      </IconButton>
                                    </Stack>
                                  </Item>
                                  <Item
                                    onClick={() => {
                                      handleClose2();
                                      goReady("Ready");
                                      updateStatus("Ready");
                                      setShowStatusChannel("Ready");
                                      changeStatusnew("Ready");
                                      localStorage.setItem(
                                        "statusValue",
                                        "Ready"
                                      );
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={1}
                                      >
                                        <CheckCircle size={22} weight="bold" />
                                        <Typography
                                          sx={{
                                            fontSize: "0.875rem",
                                            color: "rgba(0, 0, 0, 0.6)",
                                          }}
                                        >
                                          Go Ready
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Item>
                                  <Item
                                    onClick={() => {
                                      handleClose2();
                                      mealUpdate("Not Ready");
                                      localStorage.setItem(
                                        "statusValue",
                                        "Not Ready"
                                      );
                                      changeStatusnew("Not Ready");
                                    }}
                                  >
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={1}
                                      >
                                        <CheckCircle size={22} weight="bold" />
                                        <Typography
                                          sx={{
                                            fontSize: "0.875rem",
                                            color: "rgba(0, 0, 0, 0.6)",
                                          }}
                                        >
                                          Go Not Ready
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Item>
                                </Stack>
                              </Box>
                              <Box
                                sx={{
                                  height: "290px",
                                }}
                              >
                                <Stack spacing={1}>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: "16px" }}
                                  >
                                    Channel
                                  </Typography>
                                  {filteredChannels.map((items) => {
                                    return (
                                      <Item key={items.channelId}>{`${
                                        items.channelName
                                      } - ${localStorage.getItem(
                                        "statusValue"
                                      )}`}</Item>
                                    );
                                  })}
                                </Stack>
                              </Box>
                            </>
                          )}
                          {!showFinishButton && (
                            <>
                              <Button
                                variant="contained"
                                color="success"
                                startIcon={<Polygon size={22} />}
                                onClick={() => {
                                  goReady("Ready");
                                  setShowStatusChannel("Ready");
                                  localStorage.setItem("statusValue", "Ready");
                                  loginbyDomain();
                                  setTimeout(() => {
                                    updateStatus("Ready");
                                  }, 1000);
                                }}
                              >
                                GO READY
                              </Button>
                              <Box
                                sx={{
                                  height: "290px",
                                }}
                              >
                                <Stack spacing={1}>
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: "16px" }}
                                  >
                                    Channel
                                  </Typography>
                                  {filteredChannels.map((items) => {
                                    return (
                                      <Item key={items.channelId}>{`${
                                        items.channelName
                                      } - ${localStorage.getItem(
                                        "statusValue"
                                      )}`}</Item>
                                    );
                                  })}
                                </Stack>
                              </Box>
                              <Stack>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  startIcon={<SignOut size={22} />}
                                  onClick={logout}
                                >
                                  SIGN OUT
                                </Button>
                              </Stack>
                            </>
                          )}
                        </Stack>

                        <Button
                          disabled
                          variant="text"
                          startIcon={<SealCheck size={22} />}
                        >
                          {"Version : 1.6"}
                        </Button>
                      </Stack>
                    </Box>
                  </Slide>
                )}
                {showReasonCode && (
                  <Slide in={showReasonCode} direction="left">
                    <Box sx={{ height: "500px", width: "350px" }} p={2}>
                      <Stack spacing={1}>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Typography
                            sx={{
                              flexGrow: 1,
                              fontSize: "0.999rem",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                            variant="subtitle1"
                          >
                            Reason Code
                          </Typography>
                          <IconButton
                            sx={{ padding: "5px" }}
                            onClick={() => setShowReasonCode(false)}
                          >
                            <XCircle size={18} />
                          </IconButton>
                        </Stack>

                        <Box
                          className="CONTACT-LIST-AREA"
                          sx={{
                            height: "400px",
                            overflowY: "scroll",
                          }}
                          p={1}
                        >
                          <Stack spacing={0.5}>
                            {userstatus.map((item, index) => (
                              <Item
                                key={index}
                                onClick={() => {
                                  setShowReasonCode(false);
                                  changeStatusnew("Not Ready");
                                  mealUpdate(item.statusName);
                                  localStorage.setItem(
                                    "statusValue",
                                    item.statusName
                                  );
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={1}
                                  >
                                    <CheckCircle size={22} weight="bold" />
                                    <Typography
                                      sx={{
                                        fontSize: "0.875rem",
                                        color: "rgba(0, 0, 0, 0.6)",
                                      }}
                                    >
                                      {item.statusName}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Item>
                            ))}
                          </Stack>
                        </Box>
                      </Stack>
                    </Box>
                  </Slide>
                )}
              </StyledPopover>
            </>
          </Stack>
        </Toolbar>
      </AppBar>

      {voiceEnable && (
        <Sdk
          showStatusChannel={showStatusChannel}
          setShowingStatus={setShowingStatus}
          setShowingStatusClass={setShowingStatusClass}
          updateStatus={updateStatus}
          changeStatusnew={changeStatusnew}
          referance={referance}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist,
  setcontactlist1,
  closeNotify,
  clearnotifyAll,
  setShowNotification,
  setShowUserProfile,
  setshowphonedialer,
  setVoiceRefresh,
  setShowingStatusClass,
  setShowingStatus,
  setsidebarVal,
})(NewNavbar);
