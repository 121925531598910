import {
  SET_CONTACT_LIST,
  SET_SIDEBAR_VAL,
  SET_INDEX,
  SET_INDEX2,
} from "../../redux/actions/type";
const initialState1 = {
  contactList: [],
  sidebarVal: "Dashboard",
  index: 0,
  index2: 0,
};

export const contactListReducer = (state = initialState1, action) => {
  switch (action.type) {
    case SET_CONTACT_LIST:
      return {
        ...state,
        contactList: action.val,
      };
    case SET_SIDEBAR_VAL: {
      return {
        ...state,
        sidebarVal: action.val,
      };
    }
    case SET_INDEX: {
      return {
        ...state,
        index: action.val,
      };
    }
    case SET_INDEX2: {
      return {
        ...state,
        index2: action.val,
      };
    }
    default:
      return state;
  }
};
