import React from 'react'
// import Sidebar from '../components/Sidebar'
import './ReportStyle/Global.css'
import './ReportStyle/reports.css'
import '../../assets/assets/fonts/fonts.css'
import '../../assets/library/bootstrap-5.3.2/css/bootstrap.min.css'
import CallIcon from '@mui/icons-material/Call';
import PsychologyIcon from '@mui/icons-material/Psychology';
import OutboundIcon from '@mui/icons-material/Outbound';
import LoginIcon from '@mui/icons-material/Login';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import HistoryIcon from '@mui/icons-material/History';
import { NavLink } from 'react-router-dom'


const Reportmainpage = () => {
    return (
        <>
            <div class="cust_content overflow-hidden h-100" >
                <div class="container p-0">
                    <div class="row row-cols-4 mt-3 mb-3 ">
                          <NavLink to='/main/reports/calldetailsreports'>

                        <div class="col mb-1  ">
                            <div class=" shadow d-flex justify-content-center align-items-center col-2  sub-master  ">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                
                                <CallIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master">
                                    <p>Call Details Report</p>

                                </div>
                            </div>
                        </div>
                          </NavLink>


                          <NavLink to='/main/reports/agentskillsetreports'>

                        <div class="col mb-1">
                            <div class=" shadow d-flex col-2  sub-master d-flex justify-content-center align-items-center "
                               >
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                  

                                <PsychologyIcon color='primary' className='mt-2' style={{fontSize:'28px'}}/>
                                </div>
                                <div class="name-master">
                                    <p>Agent Skillset Report</p>

                                </div>
                            </div>
                        </div>
                            </NavLink>


                            <NavLink to='/main/reports/servicereport'>

                        <div class="col mb-1">
                            <div class="shadow d-flex col-2  sub-master d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <OutboundIcon color='primary' className='mt-2'  />
                                </div>
                                <div class="name-master">
                                    <p>Service Level Report</p>
                                   
                                </div>
                            </div>
                        </div>
                            </NavLink>


                        <NavLink to='/main/reports/outbounddetailedreport'>
                        <div class="col mb-1">
                            <div class=" shadow d-flex col-2  sub-master d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                            <PhoneForwardedIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master">
                                    <p>Outbound Detailed Report</p>
                                </div>
                            </div>
                        </div>

                        </NavLink>
                     
                     <NavLink to='/main/reports/agentlogin'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <LoginIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Agent Login</p>
                                </div>
                            </div>
                        </div>
                     </NavLink>
                        <NavLink to='/main/reports/skillsetsummary'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <ListAltIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Skillset Summary</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>

                         <NavLink to='/main/reports/outboundsummaryreport'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <WifiCallingIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Outbound Summary Report</p>
                                </div>
                            </div>
                        </div>
                         </NavLink>


                        <NavLink to='/main/reports/weeklyreport'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <CalendarMonthIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Weekly Wise Perfomance Report</p>
                                </div>
                            </div>
                        </div>
                        </NavLink>

                       <NavLink to='/main/reports/monthlyreport'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <PieChartOutlineIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Monthly Wise Perfomance Report</p>
                                </div>
                            </div>
                        </div>
                       </NavLink>



                          <NavLink to='/main/reports/yearlyreport'>

                        <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <EqualizerIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p>Yearly Wise Perfomance Report</p>
                                </div>
                            </div>
                        </div>
                          </NavLink>





                          <NavLink to='/main/reports/quaterlyreport'>

<div class="col mb-1 mt-3">
    <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
        onclick="location.href='user_master'">
        <div class="me-3 icon-master d-flex justify-content-center align-items-center">
           <MoreTimeIcon color='primary' className='mt-2'/>
        </div>
        <div class="name-master w-50">
            <p>Quarterly Wise Perfomance Report</p>
        </div>
    </div>
</div>
  </NavLink>






                         



                        {/* <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <MoreTimeIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p> Agent Intervel Time(15mins)</p>
                                </div>
                            </div>
                        </div> */}




                        {/* <div class="col mb-1 mt-3">
                            <div class=" shadow  d-flex col-2  sub-master  d-flex justify-content-center align-items-center "
                                onclick="location.href='user_master'">
                                <div class="me-3 icon-master d-flex justify-content-center align-items-center">
                                   <HistoryIcon color='primary' className='mt-2'/>
                                </div>
                                <div class="name-master w-50">
                                    <p> Skillset Intervel Time(15mins)</p>
                                </div>
                            </div>
                        </div> */}







                        

                       




                     


                       
                      

                    </div>
                </div>
            </div>
        </>
    )
}

export default Reportmainpage