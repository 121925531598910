import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Chip, Pagination, Stack } from "@mui/material";
import { Eye } from "@phosphor-icons/react";
const TicketTable = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      backgroundColor: "#e9e9e9",
      color: theme.palette.common.black,
      padding: "7px 10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  return (
    <div
      className="tab-pane fade show active"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Complaint</StyledTableCell>
                <StyledTableCell>Facility/Location</StyledTableCell>
                <StyledTableCell style={{ minWidth: 200 }}>
                  Department/Business Unit
                </StyledTableCell>
                <StyledTableCell>TicketID</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Priority</StyledTableCell>
                <StyledTableCell>Comments</StyledTableCell>
                <StyledTableCell style={{ minWidth: 120 }}>
                  Created Time
                </StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <StyledTableCell2 style={{ minWidth: 120 }}>
                    Firewall issue
                  </StyledTableCell2>
                  <StyledTableCell2>Dubai</StyledTableCell2>
                  <StyledTableCell2 align="center">Technical</StyledTableCell2>
                  <StyledTableCell2>Inc0014</StyledTableCell2>
                  <StyledTableCell2>
                    <Chip
                      sx={{ height: "18px" }}
                      label="open"
                      color="success"
                    />
                  </StyledTableCell2>
                  <StyledTableCell2>
                    <Chip sx={{ height: "18px" }} label="low" color="success" />
                  </StyledTableCell2>
                  <StyledTableCell2>asdsad</StyledTableCell2>
                  <StyledTableCell2>time</StyledTableCell2>
                  <StyledTableCell2>
                    <Eye size={22} />
                  </StyledTableCell2>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction={"row"} justifyContent={"end"}>
          <Pagination
            count={10}
            variant="outlined"
            shape="rounded"
            color="primary"
            size="small"
          />
        </Stack>
      </Paper>
    </div>
  );
};

export default TicketTable;
