import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { AiFillInteraction } from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { TbListDetails } from "react-icons/tb";
import { AiOutlineFile } from "react-icons/ai";
import { HiOutlineTicket } from "react-icons/hi";
import { AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { setcontactlist } from "../../../redux/actions/actions";
// Component
import UserDetails from "./User-Details.component";
import Interactiontab from "./user-list-child-component/Interaction-tab.component";
import Sessiontab from "./user-list-child-component/Session-tab.component";
import SmartXTab from "./user-list-child-component/SmartX-tab.component";
import Tickethistory from "./user-list-child-component/Ticket-history.component";
import FilesTab from "./user-list-child-component/Files-tab.component";
import SurveyTab from "./user-list-child-component/Survey-tab.component";
import { RiSurveyLine } from "react-icons/ri";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    showExternalChat: state.data.showExternalChat,
  };
};
const UserlistComponent = (props) => {
  const { customerdetailshow } = props;
  let permission = JSON.parse(localStorage.getItem("permission"));
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [InteractionHistory, setInteractionHistory] =
    useState("nav-link active");
  const [SessionHistory, setSessionHistory] = useState("nav-link");
  const [SmartX, setSmartX] = useState("nav-link");
  const [Files, setFiles] = useState("nav-link");
  const [Ticket, setTicket] = useState("nav-link");
  const [sms, setSms] = useState("nav-link");
  const [survey, setSurvey] = useState("nav-link");
  const [tabType, setTabType] = useState("InteractionHistory");

  const tabonchangehandler = (value) => {
    switch (value) {
      case "InteractionHistory":
        setInteractionHistory("nav-link active");
        setSessionHistory("nav-link");
        setSmartX("nav-link");
        setFiles("nav-link");
        setTicket("nav-link");
        setSurvey("nav-link");
        break;
      case "SessionHistory":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link active");
        setSmartX("nav-link");
        setFiles("nav-link");
        setTicket("nav-link");
        setSurvey("nav-link  ");

        break;
      case "SmartX":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link");
        setSmartX("nav-link active");
        setFiles("nav-link");
        setTicket("nav-link");
        setSurvey("nav-link  ");
        break;
      case "Files":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link");
        setSmartX("nav-link ");
        setFiles("nav-link active");
        setTicket("nav-link ");
        setSurvey("nav-link  ");
        break;
      case "Ticket":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link");
        setSmartX("nav-link ");
        setFiles("nav-link");
        setTicket("nav-link active");
        setSurvey("nav-link  ");
        break;
      case "sms":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link");
        setSmartX("nav-link ");
        setFiles("nav-link");
        setTicket("nav-link ");
        setSurvey("nav-link ");
        break;
      case "survey":
        setInteractionHistory("nav-link ");
        setSessionHistory("nav-link");
        setSmartX("nav-link ");
        setFiles("nav-link");
        setTicket("nav-link ");
        setSurvey("nav-link active ");
        break;
      default:
        setInteractionHistory("nav-link active");
        setSessionHistory("nav-link");
        setSmartX("nav-link");
        setFiles("nav-link ");
        setTicket("nav-link");
        setSurvey("nav-link  ");
    }
  };

  return (
    <>
      <div className="details-user-chat borde r-bottom p-0">
        <div className="chat-profile-userlist d-flex justify-content-between align-items-center p-2">
          <UserDetails />
        </div>
      </div>

      <div className="chat-tabs">
        <ul
          className="nav nav-pills border-bottom border-top p-2 "
          id="pills-tab"
          role="tablist"
          style={{ justifyContent: "space-around" }}
        >
          <BootstrapTooltip title="Interaction History" arrow placement="top">
            <li className="nav-item" role="presentation">
              <button
                className={InteractionHistory}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => {
                  tabonchangehandler("InteractionHistory");
                  setTabType("InteractionHistory");
                }}
                style={{
                  boxShadow:
                    tabType == "InteractionHistory"
                      ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                      : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                  fontWeight: 600,
                }}
              >
                <AiFillInteraction size={17} />
              </button>
            </li>
          </BootstrapTooltip>

          <BootstrapTooltip title="Session History" arrow placement="top">
            <li className="nav-item" role="presentation">
              <button
                className={SessionHistory}
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => {
                  tabonchangehandler("SessionHistory");
                  setTabType("SessionHistory");
                }}
                style={{
                  boxShadow:
                    tabType == "SessionHistory"
                      ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                      : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                  fontWeight: 600,
                }}
              >
                <BiHistory size={17} />
              </button>
            </li>
          </BootstrapTooltip>

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "SMART_X" && item.read && item.write
              );
              if (value) {
                return (
                  <>
                    {" "}
                    <BootstrapTooltip title="SmartX" arrow placement="top">
                      <li className="nav-item" role="presentation">
                        <button
                          className={SmartX}
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={() => {
                            tabonchangehandler("SmartX");
                            setTabType("SmartX");
                          }}
                          style={{
                            boxShadow:
                              tabType == "SmartX"
                                ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                                : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                            fontWeight: 600,
                          }}
                        >
                          <TbListDetails size={17} />
                        </button>
                      </li>
                    </BootstrapTooltip>
                  </>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "FILES" && item.read && item.write
              );
              if (value) {
                return (
                  <BootstrapTooltip title="Files" arrow placement="top">
                    <li className="nav-item" role="presentation">
                      <button
                        className={Files}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => {
                          tabonchangehandler("Files");
                          setTabType("Files");
                        }}
                        style={{
                          boxShadow:
                            tabType == "Files"
                              ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                              : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                          fontWeight: 600,
                        }}
                      >
                        <AiOutlineFile size={17} />
                      </button>
                    </li>
                  </BootstrapTooltip>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "TICKET" && item.read && item.write
              );
              if (value) {
                return (
                  <BootstrapTooltip title="Ticket" arrow placement="top">
                    <li className="nav-item" role="presentation">
                      <button
                        className={Ticket}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => {
                          tabonchangehandler("Ticket");
                          setTabType("Ticket");
                        }}
                        style={{
                          boxShadow:
                            tabType == "Ticket"
                              ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                              : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                          fontWeight: 600,
                        }}
                      >
                        <HiOutlineTicket size={17} />
                      </button>
                    </li>
                  </BootstrapTooltip>
                );
              } else {
                return <></>;
              }
            }
          })}

          {permission.map((module) => {
            if (module.moduleName === "Chat") {
              const value = module.screen.find(
                (item) => item.screenId === "SURVEY" && item.read && item.write
              );
              if (value) {
                return (
                  <BootstrapTooltip title="Survey" arrow placement="top">
                    <li className="nav-item" role="presentation">
                      <button
                        className={survey}
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => {
                          tabonchangehandler("survey");
                          setTabType("survey");
                        }}
                        style={{
                          boxShadow:
                            tabType == "survey"
                              ? "inset 5px 5px 6px #245793, inset -5px -5px 6px #2da3ff"
                              : "rgb(189, 190, 192) 3px 4px 4px, rgb(255, 255, 255) -5px -5px 6px",
                          fontWeight: 600,
                        }}
                      >
                        <RiSurveyLine size={17} />
                      </button>
                    </li>
                  </BootstrapTooltip>
                );
              } else {
                return <></>;
              }
            }
          })}
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <AnimatePresence mode="wait">
            {(() => {
              switch (tabType) {
                case "InteractionHistory":
                  return <Interactiontab />;
                case "SessionHistory":
                  return <Sessiontab />;
                case "SmartX":
                  return <SmartXTab />;
                case "Files":
                  return <FilesTab />;
                case "Ticket":
                  return <Tickethistory />;
                case "survey":
                  return <SurveyTab />;
                default:
                  return <></>;
              }
            })()}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist,
})(UserlistComponent);
