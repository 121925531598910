import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import PersonIcon from "./callmoduleassets/images/person.png";
import { BaseUrl } from "../../container/BaseUrl/BaseUrl";
import { mutecall, unmutecall } from "./web";
import { useStopwatch } from "react-timer-hook";

import axios from "axios";
import {
  X,
  Microphone,
  Pause,
  PhoneOutgoing,
  PhonePlus,
  Phone,
  PauseCircle,
  MicrophoneSlash,
  ArrowsMerge,
  XCircle,
} from "@phosphor-icons/react";
import { connect } from "react-redux";
import {
  setSkillSet,
  setDialedNumberInc,
  setagentDialedNumber,
  setshowphonedialer,
  setShowphonedialer2,
  setShowphonedialer3,
  setShowphonedialer4,
  setshowIncommingscreen,
  setcallscreen,
  setshowIncommingNxtscreen,
  setcalledagentscreen,
  setconfrencescreen,
  setShowMute,
  setShowUnMute,
  setAgentShowMute,
  setAgentShowUnMute,
  setShowHold,
  setShowUnHold,
  setagentCallHold,
  setagentCallUnHold,
  setTransferCall,
  setmakingTransferCall,
  setConfrence,
  setMergeCall,
  setcontactId,
  setconsultedContactId,
  setCallId,
  setAcceptChatActive,
  setExternalChatData,
  setUserlistShow,
} from "../../redux/actions/actions";

const mapStateToProps = (state) => {
  return {
    skillset: state.data.skillset,
    dailedNumberInc: state.data.dailedNumberInc,
    agentDialedNumber: state.data.agentDialedNumber,
    showphonedialer: state.data.showphonedialer,
    showphonedialer2: state.data.showphonedialer2,
    showphonedialer3: state.data.showphonedialer3,
    showphonedialer4: state.data.showphonedialer4,
    showIncommingscreen: state.data.showIncommingscreen,
    callscreen: state.data.callscreen,
    showIncommingNxtscreen: state.data.showIncommingNxtscreen,
    calledagentscreen: state.data.calledagentscreen,
    confrencescreen: state.data.confrencescreen,
    showMute: state.data.showMute,
    showUnMute: state.data.showUnMute,
    agentshowMute: state.data.agentshowMute,
    agentshowUnMute: state.data.agentshowUnMute,
    showHold: state.data.showHold,
    showUnHold: state.data.showUnHold,
    agentCallHold: state.data.agentCallHold,
    agentCallUnHold: state.data.agentCallUnHold,
    transferCall: state.data.transferCall,
    makingTransferCall: state.data.makingTransferCall,
    confrence: state.data.confrence,
    mergecall: state.data.mergecall,
    contactId: state.data.contactId,
    consultedContactId: state.data.consultedContactId,
    callId: state.data.callId,
    showStatusChannel: state.data.showStatusChannel,
    voiceseconds: state.data.voiceseconds,
    voiceminutes: state.data.voiceminutes,
    voicehours: state.data.voicehours,
    selectedmobile: state.data.selectedmobile,
    userlistshow: state.data.userlistshow,
  };
};

const SdkCallScreen = (props) => {
  const {
    dailedNumberInc,
    agentDialedNumber,
    showphonedialer2,
    showphonedialer3,
    showphonedialer4,
    callscreen,
    showIncommingNxtscreen,
    calledagentscreen,
    confrencescreen,
    showMute,
    showUnMute,
    showHold,
    showUnHold,
    agentCallHold,
    agentCallUnHold,
    transferCall,
    makingTransferCall,
    confrence,
    mergecall,
    contactId,
    consultedContactId,
    callId,
    voiceseconds,
    voiceminutes,
    voicehours,
    setagentDialedNumber,
    setShowphonedialer2,
    setShowphonedialer3,
    setShowphonedialer4,
    setShowMute,
    setShowUnMute,
    setconsultedContactId,
  } = props;
  const [Numbercopyed, setNumbercopyed] = useState("Copy");
  const [availableSupervisor, setAvailableSupervisor] = useState([]);
  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));

  const { seconds, minutes, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  let avayaplatform = "AACC";
  const AvcUrl = localStorage.getItem("AvcUrl");
  let agentLoginTerminalId = localStorage.getItem("agentLoginTerminalId");

  const unhold_call = (value) => {
    if (avayaplatform == "AACC") {
      if (value == "transferhold") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/unhold/` +
              consultedContactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then(async (res) => {
            if ((res.data.status = "OK")) {
            }
          })
          .catch((err) => {
            console.error(err);
          });
        axios.post(
          BaseUrl + "/reports/dummyreport",
          {
            type: "unhold",
            phonenumber: agentDialedNumber,
            contactId: contactId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantid: localStorage.getItem("TenantId"),
            },
          }
        );
      } else if (value == "incomingcallhold") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/unhold/` +
              contactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then(async (res) => {
            if ((res.data.status = "OK")) {
            }
          })
          .catch((err) => {
            console.error(err);
          });
        axios.post(
          BaseUrl + "/reports/dummyreport",
          {
            type: "unhold",
            phonenumber: dailedNumberInc,
            contactId: contactId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantid: localStorage.getItem("TenantId"),
            },
          }
        );
      }
    }
  };
  const hold_call = (value) => {
    if (avayaplatform == "AACC") {
      if (value == "transferhold") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/hold/` +
              consultedContactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then(async (res) => {
            if ((res.data.status = "OK")) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        axios.post(
          BaseUrl + "/reports/dummyreport",
          {
            type: "hold",
            phonenumber: agentDialedNumber,
            contactId: contactId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantid: localStorage.getItem("TenantId"),
            },
          }
        );
      } else if (value == "incomingcallhold") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/hold/` +
              contactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then(async (res) => {
            if ((res.data.status = "OK")) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        axios.post(
          BaseUrl + "/reports/dummyreport",
          {
            type: "hold",
            phonenumber: dailedNumberInc,
            contactId: contactId,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
              "Content-Type": "application/json",
              tenantid: localStorage.getItem("TenantId"),
            },
          }
        );
      }
    }
  };
  const end_call = (value) => {
    if (avayaplatform == "AACC") {
      if (value == "transercallcut") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/disconnect/` +
              consultedContactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then((res) => {
            if ((res.data.status = "OK")) {
              if (res.data.statusCode == 500) {
                toast.warn(res.data.status, {
                  position: "top-right",
                  autoClose: 500,
                  hideProgressBar: true,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                pause();
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (value == "contactId") {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/disconnect/` +
              contactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then((res) => {
            if ((res.data.status = "OK")) {
              axios.post(
                BaseUrl + "/reports/dummyreport",
                {
                  type: "endcall",
                  contactId: contactId,
                },
                {
                  headers: {
                    Authorization:
                      "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                    tenantid: localStorage.getItem("TenantId"),
                  },
                }
              );
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        axios
          .post(
            AvcUrl +
              `/voice/terminal/${agentLoginTerminalId}/disconnect/` +
              consultedContactId,
            {},
            {
              headers: {
                ssoToken: localStorage.getItem("ssoToken"),
              },
            }
          )
          .then((res) => {
            axios
              .post(
                AvcUrl +
                  `/voice/terminal/${agentLoginTerminalId}/disconnect/` +
                  contactId,
                {},
                {
                  headers: {
                    ssoToken: localStorage.getItem("ssoToken"),
                  },
                }
              )
              .then((res) => {
                pause();
              });
          });
      }
    }
  };
  const transfer_call = () => {
    localStorage.removeItem("agenttransConf");
    localStorage.setItem("agentcallType", "transfer");
    setShowphonedialer2(!showphonedialer2);
    setShowphonedialer3(false);
    setShowphonedialer4(false);
  };
  const making_transfer_call = () => {
    if (agentLoginTerminalId == agentDialedNumber) {
      return alert("Don't Enter Same Extension Number You LoggedIn With");
    }
    if (agentDialedNumber == "") {
      return alert("Please Enter Valied Number");
    }

    if (avayaplatform == "AACC") {
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/transfer/${agentDialedNumber}/${contactId}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            localStorage.setItem("agentcontactid", res.data.data.contactId);
            setconsultedContactId(res.data.data.contactId);
            reset();
            start();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const making_transfer_call2 = (calledNumber) => {
    console.log("incoming", calledNumber);
    if (agentLoginTerminalId == calledNumber) {
      return alert("Don't Enter Same Extension Number You LoggedIn With");
    }
    if (calledNumber == "") {
      return alert("Please Enter Valied Number");
    }

    if (avayaplatform == "AACC") {
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/transfer/${calledNumber}/${contactId}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            localStorage.setItem("agentcontactid", res.data.data.contactId);
            setconsultedContactId(res.data.data.contactId);
            setShowphonedialer4(false);
            reset();
            start();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const makeTransferCall = () => {
    if (avayaplatform == "AACC") {
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/transfer/complete/${consultedContactId}/${contactId}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
            },
          }
        )
        .then((res) => {
          if ((res.data.status = "OK")) {
          }
        })
        .catch((err) => {
          console.error(err);
        });
      axios.post(
        BaseUrl + "/reports/dummyreport",
        {
          type: "transfer",
          phonenumber: dailedNumberInc,
          transfer_to: consultedContactId,
          transfer_from: agentLoginTerminalId,
          contactId: contactId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantid: localStorage.getItem("TenantId"),
          },
        }
      );
    }
  };
  const confrenceCall = () => {
    localStorage.removeItem("agenttransConf");
    localStorage.setItem("agentcallType", "conferance");
    setShowphonedialer2(false);
    setShowphonedialer3(!showphonedialer3);
    setShowphonedialer4(false);
  };
  const make_Confrence_Call = () => {
    if (agentLoginTerminalId == agentDialedNumber) {
      return alert("Don't Enter Same Extension Number You LoggedIn With");
    }
    if (agentDialedNumber == "") {
      return alert("Please Enter Valied Number");
    }
    if (avayaplatform == "AACC") {
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/conference/${agentDialedNumber}/${contactId}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
            localStorage.setItem("agentcontactid", res.data.data.contactId);
            setconsultedContactId(res.data.data.contactId);
            reset();
            start();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const mergeCall = () => {
    if (avayaplatform == "AACC") {
      axios
        .post(
          `${AvcUrl}/voice/terminal/${agentLoginTerminalId}/conference/complete/${consultedContactId}/${contactId}`,
          {},
          {
            headers: {
              ssoToken: localStorage.getItem("ssoToken"),
            },
          }
        )
        .then(async (res) => {
          if ((res.data.status = "OK")) {
          }
        })
        .catch((err) => {
          console.error(err);
        });

      axios.post(
        BaseUrl + "/reports/dummyreport",
        {
          type: "conference",
          phonenumber: dailedNumberInc,
          confrence_number: consultedContactId,
          contactId: contactId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantid: localStorage.getItem("TenantId"),
          },
        }
      );
    }
  };
  const transfer_call2 = () => {
    localStorage.removeItem("agenttransConf");
    localStorage.setItem("agentcallType", "transfer");
    setShowphonedialer4(!showphonedialer4);
    setShowphonedialer2(false);
    setShowphonedialer3(false);
  };

  const barginCall = async () => {
    try {
      let ssToken = localStorage.getItem("ssoToken");
      await axios
        .post(
          AvcUrl +
            `/voice/terminal/${localStorage.getItem(
              "agentLoginTerminalId"
            )}/bargein/sip:${localStorage.getItem(
              "agentLoginTerminalId"
            )}@demoaccs.com/${localStorage.getItem("contactId")}`,
          {},
          {
            headers: {
              ssoToken: ssToken,
            },
          }
        )
        .then((res) => {
          console.log("SupervisorObserver", res);
        })
        .catch((err) => {});
    } catch (error) {
      console.log("voice", error);
    }
  };
  const getAvailableSupervisor = async () => {
    const access_token = localStorage.getItem("access_token");
    try {
      const data = await axios.post(
        BaseUrl + "/users/listSupervisor",
        {},
        {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      );
      if (data.data.status) {
        setAvailableSupervisor(data.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAvailableSupervisor();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: callscreen ? "block" : "none",
        }}
      >
        <Stack
          sx={{
            flexDirection: props.userlistshow ? "" : "row",
          }}
        >
          <Box
            sx={{
              border: "1px  gray",
              width: "max-content",
              height: 60,
              background: "#ffff",
              borderRadius: "8px",
              display: showIncommingNxtscreen ? "block" : "none",
              boxShadow:
                "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
            }}
            p={1.2}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1.5}
              >
                <Stack>
                  <Avatar src={PersonIcon} sx={{ width: 35, height: 35 }} />
                </Stack>
                <Stack>
                  <Tooltip title={Numbercopyed} arrow placement="top">
                    <Typography
                      sx={{ fontWeight: "600", cursor: "pointer" }}
                      variant="subtitle2"
                      id="number"
                      onClick={() => {
                        navigator.clipboard.writeText(props.selectedmobile);
                        setNumbercopyed("Copied");
                        setTimeout(() => {
                          setNumbercopyed("Copy");
                        }, 3000);
                      }}
                    >
                      {dailedNumberInc}
                    </Typography>
                  </Tooltip>

                  <Typography variant="caption">
                    {props.selectedmobile}
                  </Typography>
                </Stack>
              </Stack>

              <Stack>
                <Box
                  sx={{
                    width: "100%",
                    background: "#F2F2F2",
                    borderRadius: 2,
                  }}
                  p={1}
                >
                  <Stack direction="row" spacing={1.5}>
                    <Stack direction="row">
                      <Tooltip title="End Call" arrow placement="top">
                        <button
                          type="button"
                          id="call"
                          className="call-end d-flex justify-content-center align-items-center"
                          onClick={() => {
                            end_call("contactId");
                          }}
                        >
                          <X size={13} />
                        </button>
                      </Tooltip>
                    </Stack>
                    <Stack direction="row" spacing={2.5}>
                      <Stack direction="row" spacing={0.5}>
                        {showMute && (
                          <Tooltip title="mute" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end2 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                mutecall();
                              }}
                            >
                              <Microphone size={13} color="#267bc9" />
                            </button>
                          </Tooltip>
                        )}

                        {showUnMute && (
                          <Tooltip title="Unmute" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end3 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                unmutecall();
                              }}
                            >
                              <MicrophoneSlash size={13} color="#ffff" />
                            </button>
                          </Tooltip>
                        )}

                        {showHold && (
                          <Tooltip title="hold" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end2 border-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                hold_call("incomingcallhold");
                              }}
                            >
                              <Pause size={13} color="#267bc9" />
                            </button>
                          </Tooltip>
                        )}

                        {showUnHold && (
                          <Tooltip title="Unhold" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end3 border-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                unhold_call("incomingcallhold");
                              }}
                            >
                              <PauseCircle size={13} color="#ffff" />
                            </button>
                          </Tooltip>
                        )}

                        {transferCall && (
                          <Tooltip
                            title="Consult & transfer"
                            arrow
                            placement="top"
                          >
                            {basedOnRole.role == "Supervisor" ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                id="call"
                                className="call-end2 border-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  transfer_call();
                                }}
                              >
                                <PhoneOutgoing size={13} color="#267bc9" />
                              </button>
                            )}
                          </Tooltip>
                        )}

                        {makingTransferCall && (
                          <Tooltip title="transfer" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end2 border-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                makeTransferCall();
                              }}
                            >
                              <PhoneOutgoing size={13} color="#267bc9" />
                            </button>
                          </Tooltip>
                        )}

                        {transferCall && (
                          <Tooltip
                            title="Assit Supervisor"
                            arrow
                            placement="top"
                          >
                            {basedOnRole.role == "Supervisor" ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                id="call"
                                className="call-end2 border-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  transfer_call2();
                                }}
                              >
                                <PhoneOutgoing size={13} color="#267bc9" />
                              </button>
                            )}
                          </Tooltip>
                        )}

                        {basedOnRole.role == "Supervisor" ? (
                          <Tooltip title="Bargin" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end2 border-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                barginCall();
                              }}
                            >
                              <PhoneOutgoing size={13} color="#267bc9" />
                            </button>
                          </Tooltip>
                        ) : (
                          ""
                        )}

                        {confrence && (
                          <Tooltip title="Conferance" arrow placement="top">
                            {basedOnRole.role == "Supervisor" ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                id="call"
                                className="call-end2 border-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  confrenceCall();
                                }}
                              >
                                <PhonePlus size={13} color="#267bc9" />
                              </button>
                            )}
                          </Tooltip>
                        )}

                        {mergecall && (
                          <Tooltip title="Merge" arrow placement="top">
                            <button
                              type="button"
                              id="call"
                              className="call-end2 border-0 d-flex justify-content-center align-items-center"
                              onClick={() => {
                                mergeCall();
                              }}
                            >
                              <ArrowsMerge size={13} color="#267bc9" />
                            </button>
                          </Tooltip>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontWeight: "700" }}>
                          {voicehours >= 10 ? voicehours : "0" + voicehours}:
                          {voiceminutes >= 10
                            ? voiceminutes
                            : "0" + voiceminutes}
                          :
                          {voiceseconds >= 10
                            ? voiceseconds
                            : "0" + voiceseconds}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              border: "1px  gray",
              width: "max-content",
              height: 60,
              background: "#ffff",
              borderRadius: "8px",
              marginTop: props.userlistshow ? "5px" : "",
              marginLeft: props.userlistshow ? "" : "5px",
              display: calledagentscreen ? "block" : "none",
              boxShadow:
                "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
            }}
            p={1.2}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1.5}
              >
                <Stack>
                  <Avatar src={PersonIcon} sx={{ width: 35, height: 35 }} />
                </Stack>
                <Stack>
                  <Typography variant="subtitle2" sx={{ fontWeight: "700" }}>
                    {agentDialedNumber}
                  </Typography>
                  <Typography variant="caption">{agentDialedNumber}</Typography>
                </Stack>
              </Stack>

              <Stack>
                <Box
                  sx={{
                    width: "100%",
                    background: "#F2F2F2",
                    borderRadius: 2,
                  }}
                  p={1}
                >
                  <Stack direction="row" spacing={1.5}>
                    <Stack direction="row">
                      <button
                        type="button"
                        id="call"
                        className="call-end d-flex justify-content-center align-items-center"
                        onClick={() => {
                          end_call("transercallcut");
                        }}
                      >
                        <X size={13} />
                      </button>
                    </Stack>
                    <Stack direction="row" spacing={2.5}>
                      <Stack direction="row" spacing={0.5}>
                        {agentCallHold && (
                          <button
                            type="button"
                            id="call"
                            className="call-end2 border-0 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              hold_call("transferhold");
                            }}
                          >
                            <Pause size={13} color="#267bc9" />
                          </button>
                        )}

                        {agentCallUnHold && (
                          <button
                            type="button"
                            id="call"
                            className="call-end3 border-0 d-flex justify-content-center align-items-center"
                            onClick={() => {
                              unhold_call("transferhold");
                            }}
                          >
                            <PauseCircle size={13} color="#ffff" />
                          </button>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ fontWeight: "700" }}>
                          {minutes >= 10 ? minutes : "0" + minutes}:
                          {seconds >= 10 ? seconds : "0" + seconds}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {/* Conferance Screen */}
      <Box
        sx={{
          border: "1px  gray",
          width: "100%",
          height: 60,
          background: "#ffff",
          borderRadius: "8px",
          display: confrencescreen ? "block" : "none",
          boxShadow:
            "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
        }}
        p={1.2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" justifyContent="space-between" spacing={1.5}>
            <Stack>
              <Avatar src={PersonIcon} sx={{ width: 35, height: 35 }} />
            </Stack>
            <Stack>
              <Typography variant="subtitle2">Conference...</Typography>
              <Typography variant="caption">{dailedNumberInc}</Typography>
            </Stack>
          </Stack>

          <Stack>
            <Box
              sx={{
                width: "100%",
                background: "#F2F2F2",
                borderRadius: 2,
              }}
              p={1}
            >
              <Stack direction="row" spacing={1.5}>
                <Stack direction="row">
                  <button
                    type="button"
                    id="call"
                    className="call-end d-flex justify-content-center align-items-center"
                    onClick={() => {
                      end_call("contactId");
                    }}
                  >
                    <X size={13} />
                  </button>
                </Stack>
                <Stack direction="row" spacing={2.5}>
                  <Stack direction="row" spacing={0.5}>
                    {showMute && (
                      <button
                        type="button"
                        id="call"
                        className="call-end2 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          mutecall();
                        }}
                      >
                        <Microphone size={13} color="#267bc9" />
                      </button>
                    )}

                    {showUnMute && (
                      <button
                        type="button"
                        id="call"
                        className="call-end3 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          unmutecall();
                        }}
                      >
                        <MicrophoneSlash size={13} color="#ffff" />
                      </button>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontWeight: "700" }}>
                      {voiceminutes >= 10 ? voiceminutes : "0" + voiceminutes}:
                      {voiceseconds >= 10 ? voiceseconds : "0" + voiceseconds}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>

      {/* DialPad for transfer */}
      <Box
        sx={{
          width: "46%",
          background: "#F2F2F2",
          borderRadius: 0,
          borderRadius: "8px",

          boxShadow:
            "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
          position: "relative",
          left: "12em",
          bottom: "10px",
          display: "block",
          display: showphonedialer2 ? "block" : "none",
          zIndex: 9,
          height: "200px",
        }}
        p={1}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ height: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <input
              type="text"
              className="no-outline"
              placeholder="Enter Transfer Number"
              style={{
                width: "120px",
                border: "1.5px solid #267bc9",
                paddingLeft: "5px",
                fontSize: "11px",
                fontStyle: "italic",
                outline: "unset",
                outlineOffset: "unset",
              }}
              onChange={(e) => {
                setagentDialedNumber(e.target.value);
              }}
            />
            <Phone
              size={22}
              id="call"
              color="#267bc9"
              style={{ cursor: "pointer" }}
              onClick={making_transfer_call}
            />
          </Stack>
          <Box
            className="CONTACT-LIST-AREA"
            sx={{
              overflow: "scroll",
              background: "#ffff",
              maxHeight: "200px",
              width: "100%",
            }}
            p={0.5}
          >
            <Stack spacing={1}>
              <Stack alignItems={"center"} spacing={0.5}>
                {availableSupervisor ? (
                  [0, 1, 2, 3, 4].map((item) => (
                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                      <Box
                        sx={{
                          background: "lightblue",
                          display: "block",
                          width: "35px",
                          height: "35px",
                          borderRadius: "100%",
                          border: "2px solid white",
                        }}
                      >
                        <p
                          className="chat_name"
                          style={{
                            color: "#7e7272",
                            textAlign: "center",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <b
                            className="h-100"
                            style={{
                              color: "#7e7272",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {"SY"}
                          </b>
                        </p>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "gray",
                          },
                        }}
                        id="call"
                        onClick={() => {
                          // making_transfer_call2(item.CTIagentLoginTerminalId);
                          // console.log("item", item.CTIagentLoginTerminalId);
                        }}
                      >
                        Sylvester
                        {/* {item.username ? item.username : item.firstName} */}
                      </Typography>
                      <div className="online"></div>
                    </Stack>
                  ))
                ) : (
                  <>
                    <Typography variant="caption">No Data</Typography>
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {/* DialPad for Conferance */}
      <Box
        sx={{
          width: "46%",
          background: "#F2F2F2",
          borderRadius: 0,
          borderRadius: "8px",

          boxShadow:
            "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
          position: "relative",
          left: "12em",
          bottom: "10px",
          display: showphonedialer3 ? "block" : "none",
          zIndex: 9,
        }}
        p={1}
      >
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <input
              type="text"
              className="no-outline"
              placeholder="Enter Conferance Number"
              style={{
                width: "120px",
                border: "1.5px solid #267bc9",
                paddingLeft: "5px",
                fontSize: "11px",
                fontStyle: "italic",
                outline: "unset",
                outlineOffset: "unset",
              }}
              onChange={(e) => {
                setagentDialedNumber(e.target.value);
              }}
            />
            <Phone
              size={22}
              id="call"
              color="#267bc9"
              style={{ cursor: "pointer" }}
              onClick={make_Confrence_Call}
            />
          </Stack>
        </Stack>
      </Box>

      {/* DialPad for Supervioser transfer */}
      <Box
        sx={{
          width: "46%",
          borderRadius: "8px",
          background: "#F2F2F2",

          boxShadow:
            "0px 0px 2px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
          position: "relative",
          left: "12em",
          bottom: "10px",
          display: "block",
          display: showphonedialer4 ? "block" : "none",
          zIndex: 9,
        }}
        p={1}
      >
        <Box
          className="CONTACT-LIST-AREA"
          sx={{ overflow: "scroll", background: "#ffff", maxHeight: "200px" }}
          p={0.5}
        >
          <Stack spacing={1}>
            <Stack alignItems={"center"} spacing={0.5}>
              {availableSupervisor ? (
                availableSupervisor.map((item) => (
                  <>
                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                      <Box
                        sx={{
                          background: "lightblue",
                          display: "block",
                          width: "35px",
                          height: "35px",
                          borderRadius: "100%",
                          border: "2px solid white",
                        }}
                      >
                        <p
                          className="chat_name"
                          style={{
                            color: "#7e7272",
                            textAlign: "center",
                            fontSize: "16px",
                            marginTop: "5px",
                          }}
                        >
                          <b
                            className="h-100"
                            style={{
                              color: "#7e7272",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item.username.slice(0, 2).toUpperCase()}
                          </b>
                        </p>
                      </Box>
                      <Typography
                        variant="caption"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "gray",
                          },
                        }}
                        id="call"
                        onClick={() => {
                          making_transfer_call2(item.CTIagentLoginTerminalId);
                          // console.log("item", item.CTIagentLoginTerminalId);
                        }}
                      >
                        {item.username ? item.username : item.firstName}
                      </Typography>
                      <div className="online"></div>
                    </Stack>
                  </>
                ))
              ) : (
                <>
                  <Typography variant="caption">No Data</Typography>
                </>
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default connect(mapStateToProps, {
  setSkillSet,
  setDialedNumberInc,
  setagentDialedNumber,
  setshowphonedialer,
  setShowphonedialer2,
  setShowphonedialer3,
  setShowphonedialer4,
  setshowIncommingscreen,
  setcallscreen,
  setshowIncommingNxtscreen,
  setcalledagentscreen,
  setconfrencescreen,
  setShowMute,
  setShowUnMute,
  setAgentShowMute,
  setAgentShowUnMute,
  setShowHold,
  setShowUnHold,
  setagentCallHold,
  setagentCallUnHold,
  setTransferCall,
  setmakingTransferCall,
  setConfrence,
  setMergeCall,
  setcontactId,
  setconsultedContactId,
  setCallId,
  setAcceptChatActive,
  setExternalChatData,
  setUserlistShow,
})(SdkCallScreen);
