import React, { useState, useEffect } from "react";
import "../../assets/assets/assets_newchat/library/bootstrap-5.1.3/css/bootstrap.min.css";
import "../../assets/assets/assets_newchat/assets/fonts/fontawesome-free-6.1.1-web/css/all.css";
import "../../assets/assets/assets_newchat/library/animate/animate.min.css";
import "../../assets/assets/assets_newchat/library/slimselect/slimselect.min.css";
import { BaseUrl, excelDownloadUrl } from "../../container/BaseUrl/BaseUrl";
import FileSaver from "file-saver";
import axios from "axios";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { logoutApi } from "../../ApiCall/LogoutApi";
import { errorhandelApi } from "../../ApiCall/ErrorHandelApi";
import { Pagination } from "@mui/material";

// Component Imported
import Filterbutton from "../../components/agent-report-component/filter-button.component";
import Sessiontable from "../../components/session-report-component/session-table.component";
import Sessionfilter from "../../components/session-report-component/session-filter.component";

const Sessionreport = () => {
  const [showfilter, setshowfilter] = useState("sidebar_filter");
  const tenantId = localStorage.getItem("TenantId");
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];

  const [fromdate, setFromdate] = useState(defaultValue);
  const [todate, setTodate] = useState(defaultValue);
  const [status, setStatus] = useState("");
  const [agentid, setAgentid] = useState("");
  const [sessionreport, setSessionreport] = useState([]);
  const [agentlist, setAgentlist] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loadingreports, setLoadingreports] = useState(false);

  const basedOnRole = JSON.parse(localStorage.getItem("tokenAgent"));

  useEffect(() => {
    if (basedOnRole.role == "Supervisor") {
      agentListdropdown();
    }
  }, []);

  useEffect(() => {
    sessionReports();
  }, [page]);

  const errorHandel = async (data) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      errorhandelApi();
      if (data.status) {
        //
      }
    } catch (error) {}
  };

  const logout = () => {
    const access_token = localStorage.getItem("access_token");
    let data = JSON.parse(localStorage.getItem("tokenAgent"));
    let userID = data.user_id;
    const id = localStorage.getItem("TenantId");

    if (data) {
      logoutApi().then((res) => {
        //
      });
    }
  };

  const getId = (item) => {
    //
    sessionReports(item.id);
  };

  const sessionReports = async () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const isCurrentDate = fromdate === currentDate && todate === currentDate;
    setLoadingreports(true);
    await axios
      .post(
        BaseUrl + "/reports/listDetails",
        {
          agent_id: basedOnRole.role == "Supervisor" ? agentid : basedOnRole.id,
          agent_supervisor: basedOnRole.id,
          status: status,
          from_date: fromdate ? fromdate : "",
          to_date: todate ? todate : "",
          limit: 10,
          offset: page == 1 ? 0 : (page - 1) * 10,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          setSessionreport(res.data.Data);
          setPagination(res.data.count);
          setTimeout(() => {
            setLoadingreports(false);
          }, 1000);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          setSessionreport([]);
          setPagination(0);
          setTimeout(() => {
            setLoadingreports(false);
          }, 1000);
        }
      })
      .catch((error) => {
        errorHandel(error, "/reports/listDetails/");
        setShow(true);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const agentListdropdown = async () => {
    await axios
      .post(
        BaseUrl + "/users/listagentList",
        {
          user_id: basedOnRole.user_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        }
      )
      .then((res) => {
        //
        //
        setAgentlist(res.data.data);
      })
      .catch((err) => {
        setShow(true);
      });
  };

  const DownloadSessionReports = () => {
    axios
      .post(
        BaseUrl + "/reports/listDetailsExcel",
        {
          agent_id: basedOnRole.role == "Supervisor" ? agentid : basedOnRole.id,
          agent_supervisor: basedOnRole.id,
          status: status,
          from_date: fromdate,
          to_date: todate,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
            "Content-Type": "application/json",
            tenantId: localStorage.getItem("TenantId"),
          },
        },
        {}
      )
      .then((res) => {
        if (res.data.status == true) {
          let file = excelDownloadUrl + res.data.message;
          FileSaver.saveAs(file);
        } else {
          toast.warn("No Data Found", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="sessiontable" style={{ overflow: "hidden" }}>
      <Sessionfilter
        showfilter={showfilter}
        setshowfilter={setshowfilter}
        fromdate={fromdate}
        setFromdate={setFromdate}
        todate={todate}
        setTodate={setTodate}
        setStatus={setStatus}
        setAgentid={setAgentid}
        agentlist={agentlist}
        sessionReports={sessionReports}
        setPage={setPage}
      />
      <Filterbutton
        setshowfilter={setshowfilter}
        DownloadSessionReports={DownloadSessionReports}
      />

      <section className="dashboard mt-1">
        <div className="container-fluid" style={{ paddingLeft: "1px" }}>
          <div className="wapper_sub">
            <div className="sub_wapper">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-md-12 p-0 animate__animated animate__fadeInUp all-ticket">
                    <div
                      className="tickt-table container-fluid"
                      style={{ height: "80vh" }}
                    >
                      <Sessiontable
                        sessionreport={sessionreport}
                        loadingreports={loadingreports}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "20px",
                        }}
                      >
                        <div className="mt-2 mb-3">
                          <Pagination
                            count={Math.ceil(pagination / 10)}
                            page={page}
                            color="primary"
                            size="small"
                            style={{ float: "right" }}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sessionreport;
