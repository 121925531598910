import { connect } from "react-redux";
import '../Resources/css/email.scss'
import '../Resources/css/images.scss';
import NewEmail from "./NewEmail_old";
import { InboxList, InboxMailShow, DownloadAttachment, SendEmail, InboxErrorMessageClose } from "../../../redux/actions/actions";

const mapStateToProps = (state) => {
    return {
        NewEmailDataList:state.data.NewEmailDataList,
    }
}

const mapDispatchToProps = (dispatch) => ({
    InboxList: () => dispatch(InboxList()),
    InboxMailShow: (a) => dispatch(InboxMailShow(a)),
    DownloadAttachment: (a, b) => dispatch(DownloadAttachment(a, b)),
    SendEmail:(a) => dispatch(SendEmail(a)),
    InboxErrorMessageClose: () => dispatch(InboxErrorMessageClose())
})

const NewEmailContainer = connect(mapStateToProps, mapDispatchToProps)(NewEmail)

export default NewEmailContainer