import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

function CreateTicket(props) {
  

  return (
    <>
      {props.opensidebarticket && (
        <nav
          className="sidebar_filter"
          style={{
            position: "absolute",
            zIndex: "1",
            left: "14rem",
            width: "66rem",
            top: "48px",
          }}
        >
          <div className="filter col-md-3 shadow p-2">
            <div className="d-flex justify-content-end align-item-end text-end">
              <p className="mb-0"><IoCloseCircleOutline id="close_circle" onClick={props.closeopenSidebar}/></p>
            </div>
            {/* {props.iframeUrl && (
              <iframe
                target="_blank"
                src={props.iframeUrl}
                width="100%"
                height="100%"
                title="Embedded Content"
              />
            )} */}
          </div>
        </nav>
      )}
    </>
  );
}

export default CreateTicket;
