import React from "react";
import {
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Stack,
  Button,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { PaperPlaneTilt } from "@phosphor-icons/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Cobrowser = (props) => {
  const {
    OpenDialog,
    handleClose,
    CobrosweData,
    setCobrowserInput,
    setUrlValidationError,
    UrlvalidationError,
  } = props;
  return (
    <Dialog
      open={OpenDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 5,
          padding: 1,
          top: -100,
        },

        backgroundColor: "rgb(0 0 0 / 65%)",
      }}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{ fontSize: "16px", fontWeight: 600 }}
      >
        Request Co-Browser
      </DialogTitle>

      <DialogContent>
  <DialogContentText id="alert-dialog-slide-description">
    <Stack spacing={0.5}>
      <input
        className="form-control"
        onChange={(e) => {
          const coBrowvalue = e.target.value;
          if (coBrowvalue.length === 0 || coBrowvalue.trim() !== '') {
            setCobrowserInput(coBrowvalue);
            setUrlValidationError("");
          }
        }}
        placeholder="Ex: www.setUrl.com"
        style={{ height: "40px", width: "450px" }}
      />
      {UrlvalidationError && (
        <Typography variant="body2" sx={{ color: "red" }}>
          {UrlvalidationError}
        </Typography>
      )}
    </Stack>
  </DialogContentText>
</DialogContent>


      <DialogActions>
        <Button
          onClick={CobrosweData}
          variant="contained"
          size="small"
          id="paperplane"
          endIcon={<PaperPlaneTilt size={15} />}
        >
          Yes Request Co-Browser
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Cobrowser;
