import React, { useState, useEffect, useRef } from "react";
import "../../assets/assets-chat/fonts/fonts.css";
import "../../assets/assets-chat/fonts/fontawesome-free-6.1.1-web/css/all.css";
import iniapiLogo from "../../assets/assets-chat/images/chat-logo-1.png";
import "./chatClient.css";
import bot from "../../assets/assets-chat/images/bot.png";
import "../../assets/assets-chat/styles/style.css";
import "../../assets/assets-chat/images/favicon.ico";
import "../../assets/library/bootstrap-5.1.3/css/bootstrap.min.css";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {  BaseUrl, newBaseUrlLang, SocketUrl,errorApi, fileservice} from "../../container/BaseUrl";
// import Contacts from "../../containers/Contacts";
// import ChatContainer from "../../containers/ChatContainerClient";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import moment from "moment";
import InputEmoji from "react-input-emoji";
import {
  setcontactlist2,
  setrefreshtogglechat,
} from "../../redux/actions/actions";
import { connect } from "react-redux";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  BaseUrl,
  newBaseUrlLang,
  SocketUrl,
  errorApi,
  fileservice,
} from "../../container/BaseUrl/BaseUrl";
import InternetError from "../InternetError-component/useOnlineStatus ";
import { WifiHigh, WifiX } from "@phosphor-icons/react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const mapStateToProps = (state) => {
  const { data } = state;
  return {
    contactList2: data.contactList2,
    refreshtogglechat: state.data.refreshtogglechat,
    chat: state.data.chat,
  };
};

const ChatForClient = (props) => {
  const { refreshtogglechat } = props;
  const socket = useRef();
  const socket1 = useRef();
  const [showChatInput, setshowChatInput] = useState(true);
  const [openChat, setOpenChat] = useState(false);
  const [chatbot, setChatbot] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showOpenIcon, setShowOpenIcon] = useState(true);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [activeUser, setActiveUser] = useState(undefined);
  const [userName, setUserName] = useState("");
  const [loadMore, setLoadMore] = useState(true);
  const [count, setcount] = useState(0);
  const [fileTypeStore, setFileTypeStore] = useState("");
  const [loading, setLoading] = useState(false);
  const [languagedropdown, setLanguagedropdown] = useState([]);
  const [skilldropdown, setSkilldropdown] = useState([]);
  const [complaint, setComplaint] = useState("");
  const [customerSpinner, setCustomerSpinner] = useState(false);
  const [customerSpinnerVideo, setCustomerSpinnerVideo] = useState(false);
  const [contacts, setContacts] = useState([
    {
      username: "",
      email: "",
      password: "",
      is_available: "",
      id: "",
      isTransferred: false,
      isConference: false,
    },
  ]);
  const [chatDisable, setChatDisable] = useState("disabled");
  const [messages, setMessages] = useState([]);
  const [chatdata, setchatdata] = useState([]);
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [AgentTyping, setAgentTyping] = useState(false);
  const [senderId, setSenderId] = useState(undefined);
  const [show, setShow] = useState(false);
  const [fileNameForDoc, setFileNameForDoc] = useState("");
  const [fileUploadName, setfileUploadName] = useState("");
  const [fileSendName, setfileSendName] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [followerlink, setFollowerlink] = useState("");
  const [videochat, setVideochat] = useState(false);
  const { isOnline, showNotification } = InternetError();
  const [selectedImage, setSelectedImage] = useState(null);

  const openImagePreview = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  const handleImageClick = (event, imageSrc) => {
    event.preventDefault();

    openImagePreview(imageSrc);
  };

  const downloadImage = () => {
    <></>;
  };

  const errorHandel = async (error, endpoint) => {
    const tenantID = localStorage.getItem("TenantId");
    try {
      const payload = {
        servicename: "DCCCHAT",
        logs: error,
        description: endpoint,
      };
      const header = {
        headers: {
          tenantid: tenantID,
        },
      };
      const { data } = await axios.post(
        errorApi + "/UIlogs/createLogs",
        payload,
        header
      );
      if (data.status) {
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setShow(false);
    setFileTypeStore("");
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    let tenantID = window.location.search;
    const urlParams = new URLSearchParams(tenantID);
    const param1 = urlParams.get("tenantID");
    localStorage.setItem("tenantid", param1);
  }, []);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  let tenantid = localStorage.getItem("tenantid");

  const clearChat = async () => {
    let msg = "Customer Ended the Chat";
    let session_id = JSON.parse(localStorage.getItem("responseapi"));
    let chat_session_id = session_id.chat_session_id;
    if (socket.current != undefined) {
      const data1 = await JSON.parse(localStorage.getItem("token"));
      socket.current.emit("customer-end-chat-req", {
        chat_session_id: chat_session_id,
      });
      socket.current.emit("last-msg-send", {
        to: currentChat.id,
        session_id: currentChat.chat_session_id,
        from: senderId,
        chatType: "inbound",
        msg,
        senderName: data1.username,
        chatdetails: chatdata,
      });

      socket.current.emit("send-msg", {
        to: currentChat.id,
        from: senderId,
        chatType: "inbound",
        msg,
        senderName: data1.username,
        msgType: "web",
        msg_sent_type: "NOTIFICATIONS",
        session_id: chatdata.chat_session_id,
        chatdetails: chatdata,
        file_name: fileNameForDoc,
      });

      await axios.post(
        BaseUrl + "/message/addMessage",
        {
          from: senderId,
          to: currentChat.id,
          message: msg,
          senderName: data1.username,
          messageFrom: "fromClient",
          msg_sent_type: "NOTIFICATIONS",
          session_id: currentChat.chat_session_id,
          file_name: fileNameForDoc,
        },

        {
          headers: {
            tenantId: tenantid,
          },
        }
      );

      let data = {
        chat_session_id: chat_session_id,
      };

      axios
        .post(BaseUrl + "/message/chatEndByCustomer", data, {
          headers: {
            tenantId: tenantid,
          },
        })
        .then((res) => {
          localStorage.clear();
          window.location.reload();
        })
        .catch((error) => {
          errorHandel(error, "/message/chatEndByCustomer");
          toast.warn("Server is down,please try after some time", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      let data = {
        chat_session_id: chat_session_id,
      };

      axios
        .post(BaseUrl + "/message/chatEndByCustomer", data, {
          headers: {
            tenantId: tenantid,
          },
        })
        .then((res) => {
          localStorage.clear();
          window.location.reload();
        })
        .catch((error) => {
          errorHandel(error, "/message/chatEndByCustomer");
          toast.warn("Server is down,please try after some time", {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const skillsetDropdown = () => {
    let tenantID = window.location.search;
    const urlParams = new URLSearchParams(tenantID);
    const tenantid = urlParams.get("tenantID");
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/skills/list", data, {
        headers: {
          tenantId: tenantid,
        },
      })
      .then((res) => {
        setSkilldropdown(res.data.dataList);
      })
      .catch((error) => {
        errorHandel(error, "/skills/list");

        toast.warn("Server is down,please try after some time", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const languageDropdownList = () => {
    let data = {
      enabled: true,
    };
    axios
      .post(newBaseUrlLang + "usermodule/clientMaster/languages/list", data, {
        headers: {
          tenantId: localStorage.getItem("tenantid"),
        },
      })
      .then((res) => {
        setLanguagedropdown(res.data.dataList);
      })
      .catch((error) => {
        errorHandel(error, "/languages/list");

        toast.warn("Server is down,please try after some time", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    skillsetDropdown();
    languageDropdownList();
    if (!localStorage.getItem("token")) {
      setCurrentUser(undefined);
    } else {
      setUserName(JSON.parse(localStorage.getItem("token")).username);
      setCurrentUser(JSON.parse(localStorage.getItem("token")));
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });

      if (localStorage.getItem("token")) {
        setData();
      }
    }
  }, []);

  const setData = async () => {
    let tenantID = window.location.search;
    const urlParams = new URLSearchParams(tenantID);
    const tenantid = urlParams.get("tenantID");
    var emaildata = JSON.parse(localStorage.getItem("token"));
    const data = await axios.post(
      BaseUrl + `/users/getId/${emaildata.email}`,
      {},
      {
        headers: {
          tenantId: tenantid,
        },
      }
    );
    setchatdata(data.data.user);
    setSenderId(data.data.user.id);
    const response = await axios.post(
      BaseUrl + "/message/getCurrentChatMessage",
      {
        sessionId: data.data.user.chat_session_id,
      },
      {
        headers: {
          tenantId: tenantid,
        },
      }
    );
    if (response.data.data) {
      setMessages(response.data.data);
    } else {
      setMessages([]);
    }
    setActiveUser(data.data.user);
    if (data.data.user.ischatbot) {
      socket1.current = io(SocketUrl + "/customer");
      if (socket1.current) {
        socket1.current.on("customer message", async function (msg) {
          if (msg == "Connecting to Agent please wait") {
            let datas = {
              session_id: data.data.user.id,
              message: "Hi",
              name: data.data.user.unique_id?.username,
              msg_sent_type: "TEXT",
              chat_session_id: data.data.user.chat_session_id,
              phone: data.data.user.phonenumber,
              language: data.data.user.language,
              skillset: data.data.user.skillset,
            };
            await axios.post(
              BaseUrl + "/users/assignchattoagentfromchatbot",
              datas,
              {
                headers: {
                  tenantId: tenantid,
                },
              }
            );
            setChatbot(false);
            setArrivalMessage({
              fromSelf: true,
              message: msg,
              senderName: "Bot",
              msg_sent_type: "TEXT",
              session_id: data.data.user.chat_session_id,
              file_name: "",
            });
            setTimeout(() => {
              setData();
            }, 10000);
          } else {
            setArrivalMessage({
              fromSelf: true,
              message: msg,
              senderName: "Bot",
              msg_sent_type: "TEXT",
              session_id: "12345",
              file_name: "",
            });
          }
        });
      }
    } else {
      socket.current = io(SocketUrl + "/" + tenantid);
      socket.current.emit("add-user", data.data.user.id);
      if (socket.current) {
        socket.current.on("update-client-recv", (msg) => {
          let tokenValue = JSON.parse(localStorage.getItem("token"));
          if (tokenValue.id === msg.id) {
            localStorage.removeItem("token");
            var arr = {
              id: msg.id,
              phonenumber: msg.phonenumber,
              username: msg.username,
              email: msg.email,
            };
            localStorage.setItem("token", JSON.stringify(arr));
          }
        });
        socket.current.on("msg-receive", (msg) => {
          // console.log("msg-receive", msg);
          scrollRef.current?.scrollIntoView({ behavior: "smooth" });
          setAgentTyping(false);

          setArrivalMessage({
            fromSelf: true,
            message: msg.msg,
            senderName: msg.senderName,
            msg_sent_type: msg.msg_sent_type,
            session_id: msg.session_id,
            file_name: msg.file_name,
            time: msg.time,
          });
          if (msg.chatdetails.status == "chatEnded") {
            setshowChatInput(false);
          }
        });
        socket.current.on("connect", function () {
          socket.current.emit("add-user", data.data.user.id);
        });
        socket.current.on("typing-msg-receive", (msg) => {
          // console.log("typing-msg-receive", msg);
          scrollRef.current?.scrollIntoView({ behavior: "smooth" });
          setAgentTyping(true);
          setChatDisable("");
        });
        socket.current.on("check-agent-recv", async (msg) => {
          setTimeout(() => {
            setData();
          }, 3000);
          setShowContent(true);
          setChatDisable("");
        });
      }
    }
  };

  useEffect(() => {
    if (activeUser) {
      async function getData() {
        const data = await axios.post(
          BaseUrl + `/users/getClients/${activeUser.id}`,
          {
            headers: {
              tenantId: tenantid,
            },
          }
        );
        let agentDetails = data.data.agents;
        let clientDetails = data.data.client;
        setContacts([
          {
            username: agentDetails.username,
            email: agentDetails.email,
            password: agentDetails.password,
            is_available: agentDetails.is_available,
            id: agentDetails.id,
            isTransferred: clientDetails.transferred ? true : false,
            isConference: clientDetails.conference ? true : false,
          },
        ]);

        setCurrentChat({
          username: agentDetails.username,
          email: agentDetails.email,
          password: agentDetails.password,
          is_available: agentDetails.is_available,
          id: agentDetails.id,
        });
      }
      getData();
    }
  }, [activeUser, chatdata]);

  useEffect(() => {
    loadmoreClient();
  }, []);

  const loadmoreClient = async () => {
    if (refreshtogglechat) {
      var emaildata = JSON.parse(localStorage.getItem("token"));
      const data = await axios.post(
        BaseUrl + `/users/getId/${emaildata.email}`,
        {
          headers: {
            tenantId: tenantid,
          },
        }
      );
      // setLoadMore(false);
      const response = await axios.post(
        BaseUrl + "/message/getMessage",
        {
          from: data.data.user.id,
          messageFrom: "fromClient",
          to: data.id,
          send: data.data.user.username,
          receive: data.username,
          msg_sent_type: data.msg_sent_type,
          file_name: data.file_name,
        },
        {
          headers: {
            tenantId: tenantid,
          },
        }
      );

      setMessages(response.data);
      props.setrefreshtogglechat(false);
    }
  };

  const opnChat = () => {
    let check_token = localStorage.getItem("token");

    setOpenChat(true);
    if (check_token) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      setChatDisable("");
      setShowForm(false);
      setShowContent(true);
    } else {
      setShowForm(true);
      setShowContent(false);
    }
    // getAvailableAgents();
    setShowCloseIcon(true);
    setShowOpenIcon(false);
  };

  const closeChat = () => {
    setOpenChat(false);
    setShowCloseIcon(false);
    setShowOpenIcon(true);
    setShowContent(false);
    setShowForm(true);
  };

  const goToNextScreen = () => {
    setShowForm(false);
    setShowContent(true);
  };

  const [values, setValues] = useState({
    username: "",
    phonenumber: "",
    email: "",
    skillset: "",
    language: "",
    complaint: "",
    availableAgent: "agent",
  });

  const toastOptions = {
    position: "top-right",
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const { username, phonenumber, email, availableAgent } = values;
    if (username === "") {
      toast.error("username is required.", toastOptions);
      return false;
    } else if (phonenumber === "") {
      toast.error("phonenumber is required.", toastOptions);
      return false;
    } else if (email === "") {
      toast.error("email is required.", toastOptions);
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { username, phonenumber, email, skillset, language, complaint } =
        values;
      if (selectedOption === "chat") {
        // Handle chat option
        // alert('Chat selected');
        setCustomerSpinner(true);
        var datas = {
          name: username,
          email_id: email,
          phone: phonenumber,
          channel: "webchat",
          latitude: "23°27N",
          longitute: "23.5",
          skillset: skillset,
          language: language,
          complaint: complaint,
        };
        // Add your chat logic here
      } else if (selectedOption === "video") {
        // Handle video chat option
        // alert('Video Chat selected');
        setCustomerSpinnerVideo(true);
        var datas = {
          name: username,
          email_id: email,
          phone: phonenumber,
          channel: "videochat",
          latitude: "23°27N",
          longitute: "23.5",
          skillset: skillset,
          language: language,
          complaint: complaint,
        };
        // Add your video chat logic here
      }

      const { data } = await axios.post(
        BaseUrl + "/users/createsession",
        datas,
        {
          headers: {
            tenantId: tenantid,
          },
        }
      );

      if (data.status === false) {
        toast.error(data.msg, toastOptions);
      }

      if (data.status === true) {
        goToNextScreen();
        setUserName(data.data[0].unique_id.username);
        props.setrefreshtogglechat(true);
        localStorage.setItem("token", JSON.stringify(data.data[0].unique_id));
        localStorage.setItem("responseapi", JSON.stringify(data.data[0]));
        localStorage.setItem("email", data.data[0].unique_id.email);
        localStorage.setItem("phonenumber", data.data[0].unique_id.phonenumber);
        if (data.data[0].channel === "videochat") {
          setFollowerlink(data.data[0].follower_link);
          setVideochat(true);
        }
        setCustomerSpinner(false);
        setCustomerSpinnerVideo(false);
        setTimeout(() => {
          setData();
        }, 2000);
      }
    }
  };
  const [msg, setMsg] = useState("");
  const handleTyping = async () => {
    const data = await JSON.parse(localStorage.getItem("token"));
    //
    if (!chatdata.ischatbot) {
      socket.current.emit("typing-send-msg", {
        to: currentChat.id,
        from: senderId,
        senderName: data.username,
        chatType: "Inbound",
        chatdetails: currentChat,
      });
    }
  };
  const handleSendMsg = async (msag, mediaType, fileNameForDoc) => {
    try {
      const i = count + 1;
      setcount(i);
      if (!chatdata.ischatbot) {
        let msg_type = mediaType;
        let msg = msag;
        if (msg.length > 0) {
          const data = await JSON.parse(localStorage.getItem("token"));

          socket.current.emit("last-msg-send", {
            to: currentChat.id,
            session_id: currentChat.chat_session_id,
            from: senderId,
            chatType: "inbound",
            msg,
            senderName: data.username,
            chatdetails: currentChat,
            count: i,
          });

          socket.current.emit("send-msg", {
            to: currentChat.id,
            from: senderId,
            chatType: "inbound",
            msg,
            senderName: data.username,
            msgType: "web",
            msg_sent_type: msg_type,
            session_id: chatdata.chat_session_id,
            chatdetails: chatdata,
            file_name: fileNameForDoc,
          });

          await axios.post(
            BaseUrl + "/message/addMessage",
            {
              from: senderId,
              to: currentChat.id,
              message: msg,
              senderName: data.username,
              messageFrom: "fromClient",
              msg_sent_type: msg_type,
              session_id: currentChat.chat_session_id,
              file_name: fileNameForDoc,
            },
            {
              headers: {
                tenantId: tenantid,
              },
            }
          );

          const msgs = [...messages];
          msgs.push({
            fromSelf: false,
            message: msg,
            senderName: data.username,
            msg_sent_type: msg_type,
            session_id: currentChat.chat_session_id,
            file_name: fileNameForDoc,
            time: moment().format("MMM DD, YYYY h:mm A"),
          });
          //
          setMessages(msgs);

          setMsg("");
          setFileNameForDoc("");
        }
      } else {
        const data = await JSON.parse(localStorage.getItem("token"));
        socket1.current.emit("customer message", msag);

        await axios.post(
          BaseUrl + "/message/addMessage",
          {
            from: senderId,
            to: chatdata.id,
            message: msag,
            senderName: data.username,
            messageFrom: "fromClient",
            msg_sent_type: "TEXT",
            session_id: chatdata.chat_session_id,
            file_name: fileNameForDoc,
          },
          {
            headers: {
              tenantId: tenantid,
            },
          }
        );

        const msgs = [...messages];
        msgs.push({
          fromSelf: false,
          message: msag,
          senderName: data.username,
          msg_sent_type: "TEXT",
          session_id: chatdata.chat_session_id,
          file_name: fileNameForDoc,
        });
        //
        setMessages(msgs);
      }
    } catch {
      toast.error("Internet connection is lost!", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const keyDownHandler = (event) => {
    handleTyping();
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMsg(msg, "TEXT");
    }
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [file, setFile] = useState("");
  const hiddenFileInput = React.useRef(null);

  const handleChangeImg = (event) => {
    let reader = new FileReader();
    const fileUploaded = event.target.files[0];
    const fileType = fileUploaded.type;
    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "video/mp4",
      "video/webmp",
      "audio/mpeg",
      "audio/.wav",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    if (fileUploaded.size > 3000000) {
      toast.warn("Choose File Below 3mb", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        zIndex: 99999,
      });
    } else if (allowedFormats.includes(fileType)) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setFile(fileUploaded);
      };

      reader.readAsDataURL(fileUploaded);

      setFileNameForDoc(event.target.files[0].name);
      let split_fileType = event.target.files[0].type.split("/");
      setFileTypeStore(split_fileType[0]);
      setfileUploadName(fileUploaded);
      setfileSendName(event.target.files[0].name);
      handleShow();
    } else {
      toast.warn("Invalid File Format", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        zIndex: 9999,
      });
    }
  };

  const toastOptions1 = {
    position: "top-right",
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
  };

  const sendFile = (fileGet, fileName) => {
    let send_file;
    if (fileUploadName.name) {
      setLoading(true);
      send_file = fileUploadName;
    } else {
      send_file = file;
    }
    let session_id = JSON.parse(localStorage.getItem("responseapi"));
    let user_id = JSON.parse(localStorage.getItem("token"));
    let chat_session_id = session_id.chat_session_id;
    const url = fileservice + "/fileServer/uploadMedia";
    const formData = new FormData();
    formData.append("file", send_file);
    formData.append("userID", user_id.id);
    formData.append("clientApp", "InapiWebchat");
    formData.append("channel", "webchat");
    formData.append("sessionId", chat_session_id);
    formData.append("sentBy", "Customer");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        tenantId: "123456",
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          let pic_url = response.data.data.signedUrl;
          let mediaType = response.data.data.mediaType.toUpperCase();
          handleClose();
          handleSendMsg(pic_url, mediaType, fileSendName);
          setFile("");
          setFileTypeStore("");
        } else {
          toast.error(
            "Sorry,the file you are trying to upload is too big(maximum size is 3072KB)",
            toastOptions1
          );
        }
      })
      .catch((error) => {
        errorHandel(error, "fileServer/uploadMedia");
        toast.warn("Server is down,please try after some time", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <div>
        {showOpenIcon && (
          <button className="chatbox-open call-animation" id="open-chatbox" onClick={opnChat}>
            <i className="fas fa-comments" aria-hidden="true"></i>
          </button>
        )}

        {showCloseIcon && (
          <button className="chatbox-close" id="close-chatbox" onClick={closeChat}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        )}

        {/* form block */}
        {openChat && (
          <section className="chatbox-popup">
            <header className="chatbox-popup__header d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="head-logo d-flex justify-content-center align-items-center me-3">
                  <img
                    src={iniapiLogo}
                    className="chat-message__avatar"
                    alt=""
                    srcset=""
                    style={{ height: "23px" }}
                  ></img>
                </div>
                <div className="company-name">
                  <h4>My Bank</h4>
                  {contacts[0].username && (
                    <span>Agent ( {contacts[0].username} ) joined a chat</span>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center closeIconChat">
                <button
                  className="btn btn-outline-primary1 d-flex justify-content-center align-items-center border-0"
                  id="close-chatbox"
                  onClick={() => {
                    clearChat();
                    closeChat();
                  }}
                >
                  <i
                    className="fa fa-close"
                    style={{
                      color: "#fff",
                      fontSize: "13px",
                    }}
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </header>
            {!isOnline && (
              <div className="customer-offline-message">
                <span>
                  <WifiX size={18} />
                </span>
                <span className="ms-2">
                  You're offline, Please check the connection error !
                </span>
              </div>
            )}
            {isOnline && showNotification && (
              <div className="customer-online-message">
                <span>
                  <WifiHigh size={18} />
                </span>
                <span className="ms-2">You're Online! Connection is back.</span>
              </div>
            )}
            <main className="chatbox-popup__main">
              {showForm && (
                <div className="chat-first-form p-4 ">
                  <form onSubmit={(event) => handleSubmit(event)}>
                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter Username"
                        name="username"
                        required="true"
                        onChange={(e) => handleChange(e)}
                      ></input>
                    </div>
                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder="Enter Email"
                        onChange={(e) => handleChange(e)}
                        required
                      ></input>
                    </div>
                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phonenumber"
                        className="form-control"
                        required
                        pattern="[0-9]*"
                        id="exampleFormControlInput3"
                        placeholder="Enter Phone number"
                        onChange={(e) => handleChange(e)}
                      ></input>
                    </div>

                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Select Skill Set
                      </label>

                      <select
                        className="form-select"
                        onChange={(e) => handleChange(e)}
                        name="skillset"
                        required
                        aria-label="Default select example"
                        id="select_skillset"
                      >
                        <option selected disabled value="">
                          Select Skill Set
                        </option>
                        {skilldropdown.map((item) => {
                          return (
                            <option id={item.skillId} value={item.skillName}>
                              {item.skillName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Select Language
                      </label>
                      <select
                        className="form-select"
                        name="language"
                        required
                        aria-label="Default select example"
                        onChange={(e) => handleChange(e)}
                        id="select_language"
                      >
                        <option value="" selected disabled>
                          Select Language
                        </option>

                        {languagedropdown.map((item) => {
                          return (
                            <option
                              id={item.languageId}
                              value={item.languageDesc}
                            >
                              {item.languageDesc}
                            </option>
                          );
                        })}

                        {/* <option value="Tamil">Tamil</option>
                      <option value="English">English</option>
                      <option value="Arabic">Arabic</option> */}
                      </select>
                    </div>
                    <div className="mb-3 d-flex align-items-start flex-column">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Select Complaint Type
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="complaint"
                        required
                        id="select_complain_type"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected disabled value="">
                          Select Complaint Type
                        </option>
                        <option value="Help">Help</option>
                        <option value="Test">Test</option>
                      </select>
                    </div>
                    {/* <div>
                    <button className="btn mb-2 w-100 startChat" type="submit">
                      {customerSpinner ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <div>Chat Now</div>
                      )}
                    </button>
                  </div> */}
                    <div>
                      <button
                        className="btn w-100  startvideo"
                        type="submit"
                        id="video-chat"
                        onClick={() => handleOptionClick("video")}
                      >
                        {customerSpinnerVideo ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <div>Video Chat </div>
                        )}
                      </button>
                      <button
                        className="btn mt-2 w-100 startvideo"
                        type="submit"
                        id="chat-chat"
                        onClick={() => handleOptionClick("chat")}
                      >
                        {customerSpinner ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <div>Chat Now</div>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {showContent && (
                <div>
                  <div className="chat-box-body">
                    <div className="chat-logs ">
                      <div className="messages pb-2 pl-2 pr-2" id="chat">
                        {show && (
                          <div className="imgPreview1">
                            <div>
                              <div className="head">
                                <div className="d-flex  justify-content-between align-items-center me-2">
                                  <span className="mx-2">Preview</span>
                                  <AiOutlineCloseCircle
                                    size={15}
                                    id="close-chatbox"
                                    className="mr-3"
                                    onClick={handleClose}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              {loading ? (
                                <div
                                  className="spinner-border"
                                  style={{ marginTop: "20%" }}
                                  role="status"
                                >
                                  <span className="sr-only h-100 w-100 d-flex justify-content-center align-items-center">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <div className="bodyImg">
                                  {fileTypeStore == "image" && (
                                    <img src={imagePreviewUrl} />
                                  )}
                                  {fileTypeStore == "audio" && (
                                    <audio
                                      style={{
                                        margin: "35px 0",
                                        height: "37px",
                                        width: "15vw",
                                      }}
                                      controls
                                      src={imagePreviewUrl}
                                      onLoadStart="true"
                                      autoPlay={false}
                                    ></audio>
                                  )}
                                  {fileTypeStore == "video" && (
                                    <video
                                      height="100"
                                      style={{ margin: "6px" }}
                                      controls
                                      autoPlay={false}
                                    >
                                      <source
                                        src={imagePreviewUrl}
                                        type="video/mp4"
                                      ></source>
                                    </video>
                                  )}
                                  {fileTypeStore == "application" && (
                                    <div
                                      style={{
                                        // textOverflow: "ellipsis",
                                        // whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        margin: "46px",
                                        width: "10rem",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <a
                                        href={imagePreviewUrl}
                                        target="_blank"
                                        style={{ color: "black" }}
                                      >
                                        {fileSendName}
                                      </a>
                                    </div>
                                  )}
                                  <div className="actBtn py-1">
                                    <button
                                    id="close-chatbox"
                                      onClick={handleClose}
                                      className="closeBtnImg mx-1"
                                    >
                                      Close
                                    </button>
                                    <button
                                    id="send-file"
                                      onClick={sendFile}
                                      className="saveButtonImg"
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {loadMore && (
                          <div
                            className="clientSideLoad"
                            id="chat-loadmore"
                            onClick={loadmoreClient}
                          >
                            load more
                          </div>
                        )}
                        <div>
                          {videochat ? (
                            <div>
                              <iframe
                                src={followerlink}
                                title="My iframe"
                                width="300" // Adjust the width and height as needed
                                height="600"
                                frameBorder="0" // Remove iframe border if not needed
                                //allowFullScreen // Add this attribute if you want to allow full-screen mode
                                allow="camera; microphone"
                              ></iframe>
                            </div>
                          ) : (
                            messages.map((message, fromSelf) => {
                              let new_msg;
                              let messageTxt = message.message;

                              if (message.msg_sent_type == "TEXT") {
                                if (
                                  messageTxt.includes("http://") ||
                                  messageTxt.includes("www.") ||
                                  messageTxt.includes("https://")
                                ) {
                                  const splittedData = messageTxt.split(" ");
                                  for (
                                    let i = 0;
                                    i < splittedData.length;
                                    i++
                                  ) {
                                    if (
                                      splittedData[i].includes("http://") ||
                                      splittedData[i].includes("https://") ||
                                      splittedData[i].includes("www.")
                                    ) {
                                      if (fromSelf) {
                                        splittedData[i] =
                                          "<a  href=" +
                                          splittedData[i] +
                                          " style='color:blue' target='_blank'>" +
                                          splittedData[i] +
                                          "</a>";
                                      } else {
                                        splittedData[i] =
                                          "<a  href=" +
                                          splittedData[i] +
                                          " style='color:blue' target='_blank'>" +
                                          splittedData[i] +
                                          "</a>";
                                      }
                                    }
                                  }
                                  new_msg = splittedData.join(" ");

                                  // new_msg = `<a href='${messageTxt}' style='color:blue' target='_blank'>${messageTxt}</a>`;
                                } else {
                                  new_msg = messageTxt;
                                }
                              } else if (
                                message.msg_sent_type == "APPLICATION"
                              ) {
                                new_msg = `<a href='${messageTxt}' style='color:white' target='_blank'>${message.file_name}</a>`;
                              } else if (message.msg_sent_type == "AUDIO") {
                                // new_msg = `<audio controls src="${messageTxt} style="height:'37px'; width:'15vw'"></audio>`;
                                new_msg = `<audio controls src="${messageTxt}" style="height: 37px; width: 15vw;"></audio>`;
                              } else if (message.msg_sent_type == "VIDEO") {
                                new_msg = `<video width="200" height="180" controls><source src="${messageTxt}" type="video/mp4"></video>`;
                              } else {
                                new_msg = `<a href='${messageTxt}' target='_blank'><img style=' max-width: 200px;height: 120px;' src='${messageTxt}' alt='image expired' ></img></a>`;
                              }
                              return (
                                <div ref={scrollRef}>
                                  {message.msg_sent_type == "NOTIFICATIONS" && (
                                    <div className="msgNotify_client">
                                      <span
                                        style={{
                                          position: "relative",
                                          bottom: "8px",
                                          fontSize: "10px",
                                          border: "1px solid gray",
                                          borderRadius: "10px",
                                          padding: "0px 2px 0px 2px",
                                          backgroundColor: "gray",
                                          color: "white",
                                        }}
                                      >
                                        {message.message}
                                      </span>
                                    </div>
                                  )}
                                  {message.msg_sent_type != "NOTIFICATIONS" && (
                                    <div>
                                      <div
                                        className={`${
                                          message.fromSelf
                                            ? "bot-chat-main d-flex justify-content-start"
                                            : "user-chat-main d-flex justify-content-end"
                                        }`}
                                      >
                                        {message.fromSelf && (
                                          <div>
                                            {/* <div className="position-relative " style={{left:'50px',fontSize:'6px',top:'5px'}}>{message.senderName}</div> */}
                                            <div className="chat-message__avatar-frame">
                                              <img
                                                src={bot}
                                                alt="avatar"
                                                className="chat-message__avatar"
                                              ></img>
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          className={`${
                                            message.fromSelf
                                              ? "bot-chat text-break"
                                              : "user-chat text-break"
                                          }`}
                                          dangerouslySetInnerHTML={{
                                            __html: new_msg,
                                          }}
                                          onClick={(e) =>
                                            handleImageClick(e, message.message)
                                          }
                                        ></div>
                                      </div>
                                      <div
                                        className={`message ${
                                          message.fromSelf
                                            ? "timer_bot_left"
                                            : "timer_bot_right"
                                        }`}
                                        style={{ display: "flex" }}
                                      >
                                        {moment(message.time).format("h:mm A")}
                                        <div
                                          className="mx-1"
                                          style={{ fontSize: "10px" }}
                                        >
                                          {message.senderName}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          )}
                        </div>
                        {AgentTyping && (
                          <div className="d-flex align-items-center">
                            <div className="chat-message__avatar-frame">
                              <img
                                src={bot}
                                alt="avatar"
                                className="chat-message__avatar"
                              ></img>
                            </div>
                            <div className="bot-chat-main d-flex justify-content-start">
                              <div className="bot-chat text-break">
                                <div className="typing typing-1"></div>
                                <div className="typing typing-2"></div>
                                <div className="typing typing-3"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {showChatInput && (
                        <>
                          {" "}
                          <div className="chatbox-popup__footer d-flex">
                            <div
                              style={{
                                flex: 1,
                                color: "#888",
                                textAlign: "center",
                              }}
                            >
                              <label
                                className="attch-label btn"
                                htmlFor="attach"
                              >
                                <i className="fas fa-paperclip"></i>
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                style={{ display: "none", visibility: "none" }}
                                id="attach"
                                ref={hiddenFileInput}
                                onChange={handleChangeImg}
                                onClick={(event) => {
                                  event.target.value = null;
                                }}
                              ></input>
                            </div>
                            <div style={{ flex: 10 }}>
                              <InputEmoji
                                color="FF0000"
                                value={msg}
                                disabled={chatDisable}
                                cleanOnEnter
                                theme="light"
                                onChange={setMsg}
                                onKeyDown={keyDownHandler}
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Enter your message"
                                borderColor="#FF0000"
                              />
                            </div>
                            <div>
                              <button
                                type="button"
                                id="send-file"
                                className="btn btn-grad"
                                onClick={() => {
                                  handleSendMsg(msg, "TEXT");
                                }}
                              >
                                <i className="fas fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </main>
          </section>
        )}
        {selectedImage && (
          <Lightbox
            mainSrc={selectedImage}
            reactModalStyle={{ overlay: { zIndex: 9999 } }}
            onCloseRequest={closeImagePreview}
            toolbarButtons={[
            <button key="download" onClick={downloadImage}>
               Image
              <span className="material-symbols-outlined" style={{ position: 'relative', top: '5px',marginRight:'10px' }}>download</span>
            </button>,
          ]}
          />
        )}
      </div>
      <ToastContainer transition={Flip} limit={2} />
    </>
  );
};

export default connect(mapStateToProps, {
  setcontactlist2,
  setrefreshtogglechat,
})(ChatForClient);
