import React, { useEffect, useRef, useState } from 'react'
// import { BaseUrl, tenantId } from './constants/BaseUrl'
import { BaseUrlReports } from '../../container/BaseUrl/BaseUrl'
import axios from 'axios'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import moment from 'moment'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom'
import Sidebar from '../sidebar-components/Sidebar'
import { Spinner } from 'react-bootstrap'
import { TiWarningOutline } from "react-icons/ti";

const Calldetailsreports = () => {


    const selectAgentRef = useRef(null);
    const selectSkillsetRef = useRef(null);
    const selectDispositionRef = useRef(null);


    var someDate = new Date();
    var date = someDate.setDate(someDate.getDate());
    var defaultValue = new Date(date).toISOString().split("T")[0];


    const animatedComponents = makeAnimated();
    const [apiCallsMade, setApiCallsMade] = useState(false);
    const [showfilter, setShowFilter] = useState(false)
    const [calldetailsreportlist, setCalldetailsreportlist] = useState([])
    const [agentlistdata, setAgentlistdata] = useState([])
    const [skillsetdata, setSkillsetdata] = useState([])
    const [disposition, setDisposition] = useState([])
    const [fromdate, setFromdate] = useState(defaultValue)
    const [todate, setTodate] = useState(defaultValue)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState(0)
    const [calldetailsreportspinner, setCalldetailsreportspinner] = useState(false)




    const [selectedagent, setSelectedagent] = useState([])
    const [selectedskillset, setSelectedskillset] = useState(['Bank'])
    const [selecteddisposition, setSelecteddisposition] = useState(['Disconnected'])


    const handelShowFilter = () => {
        setShowFilter(true)
    }
    const handelCloseFilter = () => {
        setShowFilter(false)
    }

    const optionsAgent = agentlistdata.map((agent) => ({

        value: agent.agent_id,
        label: agent.agent_name,
    }));



    // const optionsAgent = agentlistdata.map((item)=>{
    //     console.log('itemmmmmmmmmmmm',item.agent_name)
    // })



    const optionsSkillset = skillsetdata.map((skillset) => ({
        value: skillset,
        label: skillset,
    }));


    const optionsDisposition = disposition.map((dispos) => ({
        value: dispos,
        label: dispos,
    }));






    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
    };


    useEffect(() => {
        if (!apiCallsMade) {
            agentCallDetailsReportData();
            agentCallDetailsReportDataAgentList();
            agentCallDetailsReportDataSkillsetList();
            agentCallDetailsReportDataDispositionList();
            setApiCallsMade(true)
        }
    }, [apiCallsMade]);


    useEffect(() => {
        agentCallDetailsReportData();
    }, [page])



    const agentCallDetailsReportData = async () => {
        setApiCallsMade(true)
        setCalldetailsreportspinner(true)
        await axios
            .post(
                BaseUrlReports + "api/call_details_report",
                {
                    start_date: fromdate ? fromdate : defaultValue,
                    end_date: todate ? todate : defaultValue,
                    dispositions: selecteddisposition,
                    skill_set_names: selectedskillset,
                    agent_id: selectedagent,
                    tenent_id: localStorage.getItem('TenantId'),
                    offset: page == 1 ? 0 : (page - 1) * 10,
                    limit: 10,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data) {
                    setCalldetailsreportspinner(false)
                    setCalldetailsreportlist(res.data.data)
                    setPagination(res.data.count)
                }
            })

            .catch((error) => {
        setCalldetailsreportspinner(false)
                console.log(error)
            });
    };


    const handleChange = (event, value) => {
        console.log(value)
        setPage(value);
    };


    const agentCallDetailsReportDataAgentList = async () => {
        setApiCallsMade(true)
        await axios
            .post(
                BaseUrlReports + "api/agents",
                {
                    tenent_id: localStorage.getItem('TenantId')
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {

                if (res.data) {
                    setAgentlistdata(res.data.agents)
                }
            })

            .catch((error) => {
                console.log(error)
            });
    };


    const agentCallDetailsReportDataSkillsetList = async () => {
        setApiCallsMade(true)
        await axios
            .post(
                BaseUrlReports + "api/skillsetmasters",
                {
                    tenent_id: localStorage.getItem('TenantId')
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data) {
                    setSkillsetdata(res.data.skillsets)
                }
            })

            .catch((error) => {
                console.log(error)
            });
    };



    const agentCallDetailsReportDataDispositionList = async () => {
        setApiCallsMade(true)
        await axios
            .post(
                BaseUrlReports + "api/disposition",
                {
                    tenent_id: localStorage.getItem('TenantId')
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                if (res.data) {
                    setDisposition(res.data.disposition)
                }
            })

            .catch((error) => {
                console.log(error)
            });
    };



    const handleSelectAgentChange = (selectedOptions) => {
        const value = selectedOptions.map(option => option.value);
        setSelectedagent(value);
    };

    const handleSelectSkillsetChange = (selectedOptions) => {
        const label = selectedOptions.map(option => option.label);
        setSelectedskillset(label);
    };

    const handleSelectDispositionChange = (selectedOptions) => {
        const label = selectedOptions.map(option => option.label);
        setSelecteddisposition(label);
    };



    const resetButton = () => {
        selectAgentRef.current.clearValue();
        selectSkillsetRef.current.clearValue();
        selectDispositionRef.current.clearValue();
        setTodate('');
        setFromdate('');
        handelCloseFilter();
    }




    return (
        <>
            <Sidebar />

            <div class={showfilter ? "offcanvas offcanvas-end show" : "offcanvas offcanvas-end"} tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasRightLabel">Filter</h5>
                    <button onClick={handelCloseFilter} type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">

                    <div class="mb-3">
                        <label for="exampleInputfromdate" class="form-label">From Date <small
                            class="text-danger">*</small></label>
                        <input type="date" value={fromdate} onChange={(e) => { setFromdate(e.target.value) }} class="form-control form-control-lg" id="exampleInputfromdate" />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputtodate" class="form-label">To Date <small
                            class="text-danger">*</small></label>
                        <input value={todate} type="date" onChange={(e) => { setTodate(e.target.value) }} class="form-control form-control-lg" id="exampleInputtodate" />
                    </div>



                    <div class="mb-3">
                        <label for="exampleInputfromdate" class="form-label">Agent <small
                            class="text-danger">*</small></label>
                        <Select
                            ref={selectAgentRef}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={optionsAgent}
                            placeholder="Select Agent"
                            styles={customStyles}
                            onChange={handleSelectAgentChange}
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputtodate" class="form-label">Skillset <small
                            class="text-danger">*</small></label>
                        <Select
                            ref={selectSkillsetRef}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={optionsSkillset}
                            placeholder="Select Skillset"
                            styles={customStyles}
                            onChange={handleSelectSkillsetChange}



                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputfromtime" class="form-label">Disposition
                            <small
                                class="text-danger mx-1">*</small>
                        </label>
                        <Select
                            ref={selectDispositionRef}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isMulti
                            options={optionsDisposition}
                            placeholder="Select Disposition"
                            styles={customStyles}
                            onChange={handleSelectDispositionChange}


                        />
                    </div>




                </div>
                <div class="offcanvas-sub-btn d-flex justify-content-between mb-1">
                    <button onClick={handelCloseFilter} type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                        class="btn btn-secondary mx-1 btn-sm flex-fill">Close</button>
                    <button onClick={resetButton} type="button" class="mx-1 btn btn-warning btn-sm flex-fill" >Reset</button>
                    <button onClick={() => {  setPage(1);agentCallDetailsReportData(); handelCloseFilter(); }} type="button" class="mx-1 btn-sm btn btn-primary flex-fill">Submit</button>
                </div>

            </div>


            <div class="sub-wapper bg-light" id="mainid" >
                <div class="container-fluid">
                    <div class="pt-3">

                        <div class="sub-main card border-0 rounded-0" id="menu1" style={{ height: '98vh' }}>



                            <div
                                class="card-header rounded-0 shadow-sm border-0 bg-white d-flex justify-content-between align-content-center">
                                <span className='fw-bold'>
                                    Call Details Reports
                                </span>
                                <div>

                                    <button type="button" class="btn btn-outline-primary rounded-0 fs-13 me-2">
                                        <i class="fa-solid fa-cloud-arrow-down mx-1"></i>Download Report</button>
                                    <button onClick={handelShowFilter} type="button" class="btn btn-primary rounded-0 fs-13">
                                        <i class="fa-solid fa-filter mx-1"></i>
                                        Filter</button>
                                    <NavLink to='/main/reports'>

                                        <button type="button" class="btn btn-outline-primary mx-2 rounded-0 fs-13 me-2">
                                            <i class="fa-solid fa-arrow-left mx-1"></i>

                                            Back</button>
                                    </NavLink>
                                </div>


                            </div>
                            <div class="card-body px-1 " style={{ minWidth: '100%', overflowX: 'auto', overflowY: 'clip' }}>
                                <div>
                                    <div class="table-responsive h-75vh" style={{ width: '250vw' }} >
                                        <table class="table  table-hover" style={{ fontSize: '14px' }} >
                                            <thead>
                                                <tr>
                                                    <th scope="col">Agent Name</th>
                                                    <th scope="col">Application Name</th>
                                                    <th scope="col">Skillset Name</th>
                                                    <th scope="col">Status Name</th>
                                                    <th scope="col">Customer Contact</th>
                                                    <th scope="col">Disposition</th>
                                                    <th scope="col">Originator Time</th>
                                                    <th scope="col">Connected Time</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Start Time</th>
                                                    <th scope="col">End Time</th>
                                                    <th scope="col">RingTime</th>
                                                    <th scope="col">Hold Duration</th>
                                                    {/* <th scope="col">Agent Extension</th>
                                                    <th scope="col">Skillset Delay</th> */}





                                                </tr>
                                            </thead>
                                            <tbody>

                                                {calldetailsreportlist.length > 0 ? calldetailsreportlist.map((item) => {
                                                    return (

                                                        <tr>
                                                            <th scope="row">{item.DCC_agent_Name?item.DCC_agent_Name:'--'}</th>
                                                            <td>{item.application_name?item.application_name:'--'}</td>
                                                            <td>{item.DCC_Skill_set_name?item.DCC_Skill_set_name:'--'}</td>
                                                            <td>{item.Disconnected_Party == '' ? '--' : item.Disconnected_Party}</td>
                                                            <td>{item.Customer_Contact?item.Customer_Contact:'--'}</td>
                                                            <td>{item.disposition?item.disposition:'--'}</td>
                                                            <td>{moment(item.Originator_time).format('DD-mm-yy hh.mm.ss A')}</td>
                                                            <td>{moment(item.Connected_time).format('DD-mm-yy hh.mm.ss A')}</td>
                                                            <td>{item.duration?item.duration:'--'}</td>
                                                            <td>{item.start_time?moment(item.start_time).format('DD-mm-yy hh.mm.ss A'):'--'}</td>
                                                            <td>{item.end_time?moment(item.end_time).format('DD-mm-yy hh.mm.ss A'):'--'}</td>
                                                            <td>{item.RingTime?item.RingTime:'--'}</td>
                                                            <td>{item.hold_Duration == '' ? '--' : item.hold_Duration}</td>
                                                            <td>{item.agent_extension == '' ? '--' : item.agent_extension}</td>
                                                            <td>{item.Skillset_delay == '' ? '--' : item.Skillset_delay}</td>



                                                        </tr>
                                                    )
                                                }) :
                                                
                                                    <div className="d-flex justify-content-center align-items-center position-absolute" style={{ minHeight: '400px', left: '50%' }}>
                                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                        {calldetailsreportspinner?
                                                        <>
                                                        <Spinner animation="border" variant="primary" />
                                                        <span className='position-relative mt-2' style={{ left: '-10px' }}>Loading...</span>
                                                        </>:
                                                        <>
                                                          <TiWarningOutline size={30} className='mx-auto text-muted'/>
                                                        <span className='fw-bold text-muted'>
                                                        No Data Found
                                                        </span>
                                                        </>
                                                      
                                                        }
                                                    </div>
                                                </div>
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer rounded-0  bg-white d-flex justify-content-end align-content-center">
                                <nav aria-label="Page navigation example">
                                    <Stack spacing={2}>
                                        <Pagination
                                            onChange={handleChange}
                                            count={Math.ceil(pagination / 10)}
                                            page={page}
                                            color='primary'
                                            variant="outlined"
                                            shape="rounded"
                                        />

                                    </Stack>
                                </nav>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Calldetailsreports