import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";

import { connect } from "react-redux";
import { setMsgTone, setMsgTone2 } from "../../redux/actions/actions";
import audiosound from "../../assets/IncomingMsgTone/msgtone.wav";
import audiosound2 from "../../assets/IncomingMsgTone/watsapp_notif.mp3";
import { ToastContainer, Flip } from "react-toastify";
import { Box, Grid } from "@mui/material";

import NewIncomingRequest from "../../components/New-incoming-request-component/New-incoming-request.component";
import NewNavbar from "../../components/navbar-components/NewNavbar";
import NewSidebar from "../../components/sidebar-components/NewSidebar";

import styled from "styled-components";

const ResponsiveBox = styled.div`
  // background: pink;
  width: 95.7vw;
  height: 89.5vh;
  overflow: hidden;
`;
const mapStateToProps = (state) => {
  return {
    msgTone: state.data.msgTone,
    msgTone2: state.data.msgTone2,
    chat: state.data.chat,
  };
};

const NewMain = (props) => {
  useEffect(() => {
    if (props.msgTone) {
      playRingSound();
      props.setMsgTone(false);
    }
  }, [props.msgTone]);

  const audioRef = useRef(null);

  const playRingSound = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    if (props.msgTone2) {
      playRingSound2();
      props.setMsgTone2(false);
    }
  }, [props.msgTone2]);

  const audioRef2 = useRef(null);

  const playRingSound2 = () => {
    audioRef2.current.play();
  };
  const myref = useRef(null);

  return (
    <>
      <audio ref={audioRef} src={audiosound} />
      <audio ref={audioRef2} src={audiosound2} />
      <ToastContainer transition={Flip} limit={2} />
      <NewIncomingRequest />

      <Grid container direction="row" ref={myref}>
        <Grid item xs={0.5} md={0.5} sm={0.5}>
          <NewSidebar />
        </Grid>
        <Grid item xs={11.5} md={11.5} sm={11.5}>
          <Grid container spacing={0.5} direction="column">
            <Grid item xs={12} md={12}>
              <NewNavbar referance={myref} />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <ResponsiveBox>
                <Outlet />
              </ResponsiveBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, {
  setMsgTone,
  setMsgTone2,
})(NewMain);
